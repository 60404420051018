import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {TextureType} from "../ParamTypes/TextureType";
import {BooleanType} from "../ParamTypes/BooleanType";
import {ButtonType} from "../ParamTypes/ButtonType";
import {SliderData, SliderType} from "../ParamTypes/SliderType";
import {InspectorType} from "../ParamTypes/InspectorType";
import {ColorType} from "../ParamTypes/ColorType";
import {TextType} from "../ParamTypes/TextType";

export class Sheen {
    public m_sheenMapShow: InspectorType;
    public m_sheen: InspectorType;
    public m_sheenRoughness: InspectorType;
    public m_sheenColor: InspectorType;
    public m_sheenMap: InspectorType;
    public m_sheenFlipY: InspectorType;
    public m_sheenClear: InspectorType;
    public m_sheenMapTip: InspectorType;
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        //Albedo
        this.m_uiFolder = m_uiFolders = m_tab.pages[1].addFolder({title: 'Sheen (RGBA) [no texture]', expanded: false});
        this.m_sheen = new SliderType(new SliderData(0.0,1.0,0.01), m_matParams, "sheen", m_uiFolders);
        this.m_sheenRoughness = new SliderType(new SliderData(0.0,1.0,0.01), m_matParams, "sheenRoughness", m_uiFolders);
        this.m_sheenColor = new ColorType(m_matParams, "sheenColor", m_uiFolders);
        this.m_sheenMapShow = new BooleanType("Show map on mesh", m_matParams, "sheenmapShowOnMesh", m_uiFolders);
        this.m_sheenMap = new TextureType(MapType.sheenColorMap, m_matParams, "sheenColorMap", m_uiFolders);
        this.m_sheenFlipY = new BooleanType("Flip Y", m_matParams, "sheenColorMapFlipY", m_uiFolders);
        this.m_sheenClear = new ButtonType("Clear map", m_matParams, "", m_uiFolders);
        this.m_sheenMapTip = new TextType("RGB: sheen color\nA: sheen roughness", m_matParams, 'specularMapTip', m_uiFolders);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_sheen.InitType();
        this.m_sheenRoughness.InitType();
        this.m_sheenColor.InitType();
        this.m_sheenMapShow.InitType();
        this.m_sheenMapTip.InitType();
        this.m_sheenMap.InitType();
        this.m_sheenFlipY.InitType();
        this.m_sheenClear.InitType();
    }
}