import Textfield from '../textfield/textfield';
import { useEffect, useState } from 'react';
import { CreateUserType } from './create-user.type';

import UseForm from '../form/form';
import apiClient from '../../api/api-client';
import { GET_ROLES } from '../../api/endpoints/role/role.endpoint';
import { resetCreateNews } from '../../services/reducers/news/news-category-creation.slice';
import { useDispatch, useSelector } from 'react-redux';
import MdfPreconditions from '../../utils/MdfPreconditions';
import MdfApiResponse from '../../domain/common/generic';

import { getResponsibleList, ResponsibleState } from '../../services/reducers/users/users.slice';
import { RootState } from '../../services/root-reducer';
import MainPaper from '../paper/main-paper';

import errorLogo from '../../asset/Layer 2.svg';
import avatarBlanc from '../../asset/Profil-blanc.svg';

import SimpleModal from '../modal/simple-modal/simple-modal';
import './styles.scss';
import { Role } from '../../domain/domain';


type CreateUserFormProps = {
    onSubmit: (form: CreateUserType) => void;

    email: string;
    setEmail: (email: string) => void;

    error: string;
    setError: (error: string) => void;

    isEmailError: boolean;
    setIsEmailError: (isEmailError: boolean) => void;

    emailErrorMessage: string;
    setEmailErrorMessage: (emailErrorMessage: string) => void;
};

const CreateUserForm = ({
                            onSubmit,
                            email,
                            setEmail,

                            error,
                            setError,

                            isEmailError,
                            setIsEmailError,

                            emailErrorMessage,

                            setEmailErrorMessage,
                        }: CreateUserFormProps) => {
    const [firstname, setFirstname] = useState('');
    const [isFirstnameError, setIsFirstnameError] = useState<boolean>(false);
    const [firstnameErrorMessage, setFirstnameErrorMessage] = useState<string>('');

    const [lastname, setName] = useState('');
    const [isLastnameError, setIsLastnameError] = useState<boolean>(false);
    const [lastnameErrorMessage, setLastnameErrorMessage] = useState<string>('');

    const [role, setRole] = useState<string[]>([]);
    const [isRoleError, setIsRoleError] = useState<boolean>(false);
    const [isRoleErrorMessage, setIsRoleErrorMessage] = useState<string>('');

    const [responsible, setResponsible] = useState('');
    const [isResponsableError, setIsResponsableError] = useState<boolean>(false);
    const [responsableErrorMessage, setResponsableErrorMessage] = useState<string>('');

    const [roleList, setRoleList] = useState([]);
    const [openModal, setopenModal] = useState(false);
    const dispatch = useDispatch();

    const { responsibleList } = useSelector<RootState, ResponsibleState>((state) => state.getResponsibleList);
    const checkErrors = () => {
        console.log("sdqqds",role);
        let emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
        if (email.length < 1 || lastname.length < 1 || firstname.length < 1 || responsible.length < 1 || role.length < 1 || role.includes('')) {
            if (role.length < 1) {
                setIsRoleError(true);
                setIsRoleErrorMessage('Le role doit être specifié');
            }
            if (role.includes('')) {
                setIsRoleError(true);
                setIsRoleErrorMessage('Le role doit être specifié');
            }
            if (email.length < 1) {
                setIsEmailError(true);
                setEmailErrorMessage('L\'email doit être specifié');
            }
            if (!emailRegex.test(email) && email.length > 0) {
                setIsEmailError(true);
                setEmailErrorMessage('L\'email n\'est pas valide');
            }
            if (firstname.length < 1) {
                setIsFirstnameError(true);
                setFirstnameErrorMessage('Le Prenom doit être specifié');
            }
            if (lastname.length < 1) {
                setIsLastnameError(true);
                setLastnameErrorMessage('Le Nom doit être specifié');
            }
            if (responsible.length < 1) {
                setIsResponsableError(true);
                setResponsableErrorMessage('Le responsable doit être specifié');
                return false;
            }
            setError('Une erreur inconnue est survenue');
            return false;
        }
        return true;
    };

    const getRoleList = async () => {
        const res = await apiClient(GET_ROLES, undefined, undefined, 'GET');
        MdfPreconditions.continueIfNoErrors(res as MdfApiResponse<any>, [], dispatch, () => {
            setRoleList(res.content);
        });
    };

    useEffect(() => {
        dispatch(getResponsibleList());
        dispatch(resetCreateNews());
        getRoleList();
    }, []);
    const getResponsibleId = () => {
        return responsibleList.content
            ? responsibleList.content.filter((e) => e.firstname + ' ' + e.lastname === responsible)[0] && responsibleList.content.filter((e) => e.firstname + ' ' + e.lastname === responsible)[0].id
            : 0;
    };
    const handleUserSubmit = () => {
        if (checkErrors()) {
            setopenModal(true);
        }
    };
    const submit = () => {
        console.log('iciciicicicici', role);
        onSubmit({
            email: email,
            firstname: firstname,
            lastname: lastname,
            roles: role,
            responsibleId: getResponsibleId(),
        });
        setopenModal(false);
    };
    return (
        <>
            { openModal && (
                <SimpleModal
                    icon={ avatarBlanc }
                    title="Création d'utilisateur"
                    info='Cette action créera un nouvel utilisateur'
                    closeOnclick={ () => setopenModal(false) }
                    actionOnclick={ () => submit() }
                    deleteOrValide={ false }
                    closeLabel='Annuler'
                    actionLabel='Créer'
                />
            ) }

            <MainPaper>
                <div className='user-form-main'>
                    <div className='user-form'>
                        <UseForm>
                            <Textfield
                                errorMessage={ lastnameErrorMessage }
                                isError={ isLastnameError }
                                label='NOM'
                                name='lastname'
                                value={ lastname }
                                placeholder='Entrez un Nom'
                                onChange={ (value) => {
                                    setIsLastnameError(false);
                                    setLastnameErrorMessage('');
                                    setName(value);
                                } } />

                            <Textfield
                                errorMessage={ firstnameErrorMessage }
                                isError={ isFirstnameError }
                                label='PRÉNOM'
                                name=''
                                value={ firstname }
                                placeholder='Entrez un Prénom'
                                onChange={ (value) => {
                                    setFirstnameErrorMessage('');
                                    setIsFirstnameError(false);
                                    setFirstname(value);
                                } }
                            />

                            <Textfield
                                errorMessage={ emailErrorMessage }
                                isError={ isEmailError }
                                label='EMAIL'
                                name='email'
                                value={ email }
                                placeholder='Entrer un email'
                                onChange={ (value) => {
                                    setEmailErrorMessage('');
                                    setIsEmailError(false);
                                    setEmail(value);
                                } }
                            />
                            <div className='user-form-select'>
                                <label htmlFor='responsable'>RESPONSABLE</label>
                                <select
                                    className={ isResponsableError ? 'select-error' : '' }
                                    id='responsable'
                                    onChange={ (e) => {
                                        setIsResponsableError(false);
                                        setResponsableErrorMessage('');
                                        setResponsible(e.target.value);
                                    } }
                                    value={ responsible }
                                >
                                    <option value=''>Responsable</option>
                                    { responsibleList.content.map((el) => (
                                        <option key={ el.id }>{ el.firstname + ' ' + el.lastname }</option>
                                    )) }
                                </select>
                                { responsableErrorMessage && <span>{ responsableErrorMessage }</span> }
                            </div>

                            <div className='user-form-select'>
                                <label>RÔLE</label>
                                <select
                                    className={ isRoleError ? 'select-error' : '' }
                                    onChange={ (e) => {
                                        setIsRoleErrorMessage('');
                                        setIsRoleError(false);
                                        setRole([e.target.value]);
                                    } }
                                >
                                    <option value=''>Role</option>
                                    { roleList.map((el: Role) => {
                                        return (
                                            <option value={ el.name } key={ el.id }>{ el.label }</option>
                                        );
                                    }) }
                                </select>
                                { isRoleErrorMessage && <span> { isRoleErrorMessage } </span> }
                            </div>
                        </UseForm>
                    </div>
                    <div className='user-form-info'>
                        <div className='user-error-message'>
                            { error && (
                                <div className='error-banner'>
                                    <img alt='logo erreur' src={ errorLogo } />
                                    <div>{ error }</div>
                                </div>
                            ) }
                        </div>

                        <div className='user-submit'>
                            <button type='button' className='btn btn-primary' onClick={ () => handleUserSubmit() }>
                                Créer un utilisateur
                            </button>
                        </div>
                    </div>
                </div>
            </MainPaper>
        </>
    );
};

export default CreateUserForm;
