import './product-rules-table.scss';
import trash from '../../../asset/poubelle-red.svg';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import SimpleModal from '../../../uicomponents/modal/simple-modal/simple-modal';
import { ConstructionRule } from '../../../domain/domain';
import AssociateRules from '../../modals/rules/associate-rules/associate-rules';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Params } from '../../../domain/Params/params-interface';
import {
    ConstructionRuleListState, ConstructionRuleState, dissociateConstructionRule,
    findConstructionRulesByCompoRef, resetDissociateConstructionRule,
} from '../../../services/reducers/product/rules/construction-rules.reducers';
import { RootState } from '../../../services/root-reducer';


type ConstructionRuleProps = {
    setOpenAssociateRule: Dispatch<SetStateAction<boolean>>
    openAssociateRule: boolean
};
const ConstructionRuleP = (props: ConstructionRuleProps) => {
    const dispatch = useDispatch();
    const { productReference } = useParams<Params>();

    const constructionRules = useSelector<RootState, ConstructionRuleListState>(state => state.findConstructionRuleByCompoRef);
    const dissociateRulesRes = useSelector<RootState, ConstructionRuleState>(state => state.dissociateConstructionRule);

    const [mandatoryRules, setMandatoryRules] = useState<ConstructionRule[]>([]);
    const [forbiddenRules, setForbiddenRules] = useState<ConstructionRule[]>([]);

    const [, setRuleId] = useState(0);
    const [id, setId] = useState(0);
    const [isMount, setIsMount] = useState(false);
    const [openM, setOpenM] = useState(false);

    useEffect(() => {
        productReference && !isMount && dispatch(findConstructionRulesByCompoRef(productReference));
        return (() => setIsMount(true));
    }, []);
    useEffect(() => {
        if (constructionRules.payload.content && constructionRules.payload.errors.length === 0) {
            const rules = constructionRules.payload.content;
            const mRules = rules.filter((r) => r.type === 'MANDATORY');
            const fRules = rules.filter((r) => r.type === 'IMPOSSIBLE');
            setForbiddenRules(fRules);
            setMandatoryRules(mRules);
        }
    }, [constructionRules]);
    useEffect(() => {
        if (dissociateRulesRes.payload.errors.length === 0 && dissociateRulesRes.payload.content.id !== 0) {
            productReference && dispatch(findConstructionRulesByCompoRef(productReference));
            setOpenM(false);
            dispatch(resetDissociateConstructionRule());
        }
    }, [dissociateRulesRes]);

    return (
        <div className='construction-main'>
            { openM && <SimpleModal
                icon={ trash }
                title='Dissocier cette règle'
                info='Cette action dissociera la règle ainsi que toutes ses dépendances'
                closeOnclick={ () => {
                    setOpenM(false);
                    setId(0);
                } }
                actionOnclick={ () => productReference && dispatch(dissociateConstructionRule({
                    compositionRef: productReference,
                    ruleId: id,
                })) }
                deleteOrValide={ true }
                closeLabel='Annuler'
                actionLabel='Dissocier' />
            }
            { props.openAssociateRule && <AssociateRules
                currentRules={ constructionRules.payload.content }
                setOpenAssociateRule={ props.setOpenAssociateRule } /> }
            <div className='top'>
                <div>RÈGLES D'OBLIGATIONS</div>
                <div>RÈGLES D'INTERDICTIONS</div>
            </div>
            <div className='middle'>
                <div className='r-list'>{
                    mandatoryRules.map((a) => {
                        return (
                            <div onClick={ () => {
                                setRuleId(a.id);
                            } }
                                 className='a-rule' key={ a.id }>
                                <span> { a.name }</span>
                                <img
                                    onClick={ () => {
                                        setOpenM(true);
                                        setId(a.id);
                                    } }
                                    alt='supprimer' src={ trash } />
                            </div>
                        );
                    })
                }</div>
                <div className='r-list'>
                    {
                        forbiddenRules.map((a) => {
                            return (
                                <div
                                    onClick={ () => {
                                        setRuleId(a.id);
                                    } }
                                    className='a-rule' key={ a.id }>
                                    <span> { a.name }</span>
                                    <img
                                        onClick={ () => {
                                            setOpenM(true);
                                            setId(a.id);
                                        } }
                                        alt='supprimer' src={ trash } />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};
export default ConstructionRuleP;