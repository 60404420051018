import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './rich-editor.scss';

export type RichEditorProps = {
    isError?: boolean;
    setIsError?: (isError: boolean) => void;
    errorMessage?: string;
    setErrorMessage?: (errorMessage: string) => void;
    text: string;
    label: string;
    setText: (text: string) => void;
};

const RichEditor = ({ errorMessage, setErrorMessage, text, setText, isError, setIsError, label }: RichEditorProps) => {
    const Editor = {
        toolbar:
            {
                container: [
                    ['bold', 'italic', 'underline'],
                    ['image', 'video'],
                    [
                        { align: '' },
                        { align: 'center' },
                        { align: 'right' },
                    ],
                ],
            }
    };
    const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'image', 'video', 'align'];

    return (
        <>
            <div className='editor-main'>
                <label className='label'>{label}</label>
                {/*@ts-ignore*/}
                <ReactQuill
                    className={isError ? 'error-editor' : 'rich-editor'}
                    theme='snow'
                    onChange={(e) => {
                        setText(e);
                        setErrorMessage && setErrorMessage('');
                        setIsError && setIsError(false);
                    }}
                    value={text}
                    modules={Editor}
                    formats={formats}
                    placeholder='Créer votre publication' />
            </div>
            <div>
                {errorMessage ? <p className='errorMess'> {errorMessage}</p> : <p />}
            </div>
        </>
    );
};
export default RichEditor;
