import Inspector from "../Inspector";
import {MeshPhysicalMaterial} from "three";
import * as THREE from "three";
import {MapType} from "../MapType";

export function ClearcoatBind(this: Inspector) {
    let inspectorParameters = this.m_materialEditorParameters;
    const self: any = this;
    this.m_clearcoatSlide?.m_clearcoatMapShow.BindType( function (ev: any) {
        //self.showOnMesh('clearcoatMap', self.m_currentEditedMaterial.clearcoatMap, ev.value);
        if (!self.m_currentEditedMaterial) return;

        let Mat = self.m_materialEditor.showOnMesh(self.m_currentEditedMaterial, inspectorParameters, 'clearcoatMap', ev.value);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
    });
    this.m_clearcoatSlide?.m_clearcoat.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.clearcoat = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });
    this.m_clearcoatSlide?.m_clearcoatMap.BindType(function () {
        if (self.m_materialEditorParameters.clearcoatMap.src) {
            self.ToggleSlotDirty();
            self.UpdateMaterialFromUI(MapType.clearCoatMap, self.m_materialEditorParameters.clearcoatMap, self.m_materialEditorParameters.clearcoatMapFlipY, self.m_materialEditorParameters.clearcoatMapEncodingDefault);
            self.UpdateInspectorSlotTitle(MapType.clearCoatMap, self.m_clearcoatSlide?.m_uiFolder);
        } else {
            let folder = self.m_clearcoatSlide?.m_uiFolder;
            folder.title = self.m_titlesLabel["clearcoatMap"] + " [no texture]";
        }
    }); // strange fact : sometimes we receive map as its data, not img...
    this.m_clearcoatSlide?.m_clearcoatFlipY.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        let Mat;
        if (self.m_currentEditedMaterial) {
            Mat = self.m_materialEditor.FlipYTexture(['clearcoatMap'], ev.value);
        }
        if (self.m_materialUpdatedCallback && Mat) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();

    });
    this.m_clearcoatSlide?.m_clearcoatClear.BindType(() => {
        if (!self.m_currentEditedMaterial) return;
        let texSlot = self.GetTextureFromSlot(MapType.clearCoatMap);
        if (!texSlot) return;
        let Mat = self.m_materialEditor.ClearMap(this.m_materialEditorParameters, [texSlot.slotName]);
        if (this.m_savedMaterial && self.m_currentEditedMaterial && self.m_dirtyGLTFCallback) self.m_dirtyGLTFCallback(this.m_savedMaterial, self.m_currentEditedMaterial);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat.clone() as MeshPhysicalMaterial, true);
        let folder = self.m_clearcoatSlide?.m_uiFolder;
        folder.title = this.m_titlesLabel[texSlot.slotName] + " [no texture]";
        this.m_pane.refresh();
    });

    this.m_clearcoatNormalSlide?.m_clearcoatNormalMapShow.BindType(function (ev: any) {
        //self.showOnMesh('clearcoatNormalMap', self.m_currentEditedMaterial.clearcoatNormalMap, ev.value);
        if (!self.m_currentEditedMaterial) return;

        let Mat = self.m_materialEditor.showOnMesh(self.m_currentEditedMaterial, inspectorParameters, 'clearcoatNormalMap', ev.value);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
    });
    this.m_clearcoatNormalSlide?.m_clearcoatNormal.BindType( function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.clearcoatNormalScale = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });
    this.m_clearcoatNormalSlide?.m_clearcoatNormalMap.BindType(function () {
        if (self.m_materialEditorParameters.clearcoatNormalMap.src) {
            self.ToggleSlotDirty();
            self.UpdateMaterialFromUI(MapType.clearCoatNormalMap, self.m_materialEditorParameters.clearcoatNormalMap, self.m_materialEditorParameters.clearcoatNormalMapFlipY, self.m_materialEditorParameters.clearcoatNormalMapEncodingDefault);
            self.UpdateInspectorSlotTitle(MapType.clearCoatNormalMap, self.m_clearcoatNormalSlide?.m_uiFolder);
        } else {
            let folder = self.m_clearcoatNormalSlide?.m_uiFolder;
            folder.title = self.m_titlesLabel["clearcoatNormalMap"] + " [no texture]";
        }
    }); // strange fact : sometimes we receive map as its data, not img...
    this.m_clearcoatNormalSlide?.m_clearcoatNormalFlipY.BindType( function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        let Mat;
        if (self.m_currentEditedMaterial) {
            Mat = self.m_materialEditor.FlipYTexture(['clearcoatNormalMap'], ev.value);
        }
        if (self.m_materialUpdatedCallback && Mat) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });

    this.m_clearcoatNormalSlide?.m_clearcoatNormalClear.BindType( () => {
        if (!self.m_currentEditedMaterial) return;
        let texSlot = self.GetTextureFromSlot(MapType.clearCoatNormalMap);
        if (!texSlot) return;
        let Mat = self.m_materialEditor.ClearMap(this.m_materialEditorParameters, [texSlot.slotName]);
        if (this.m_savedMaterial && self.m_currentEditedMaterial && self.m_dirtyGLTFCallback) self.m_dirtyGLTFCallback(this.m_savedMaterial, self.m_currentEditedMaterial);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat.clone() as MeshPhysicalMaterial, true);
        let folder = self.m_clearcoatNormalSlide?.m_uiFolder;
        folder.title= this.m_titlesLabel[texSlot.slotName] + " [no texture]";
        this.m_pane.refresh();
    });

    this.m_clearcoatRoughnessSlide?.m_clearcoatRoughnessMapShow.BindType( function (ev: any) {
        if (!self.m_currentEditedMaterial) return;

        let Mat = self.m_materialEditor.showOnMesh(self.m_currentEditedMaterial, inspectorParameters, 'clearcoatRoughnessMap', ev.value);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
    });
    this.m_clearcoatRoughnessSlide?.m_clearcoatRoughness.BindType( function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.clearcoatRoughness = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();

    });
    this.m_clearcoatRoughnessSlide?.m_clearcoatRoughnessMap.BindType(function () {
        if (self.m_materialEditorParameters.clearcoatRoughnessMap.src) {
            self.ToggleSlotDirty();
            self.UpdateMaterialFromUI(MapType.clearCoatRoughnessMap, self.m_materialEditorParameters.clearcoatRoughnessMap, self.m_materialEditorParameters.clearcoatRoughnessMapFlipY, self.m_materialEditorParameters.clearcoatRoughnessMapEncodingDefault);
            self.UpdateInspectorSlotTitle(MapType.clearCoatRoughnessMap, self.m_clearcoatRoughnessSlide?.m_uiFolder);
        } else {
            let folder = self.m_clearcoatRoughnessSlide?.m_uiFolder;
            folder.title = self.m_titlesLabel["clearcoatRoughnessMap"] + " [no texture]";
        }
    }); // strange fact : sometimes we receive map as its data, not img...
    this.m_clearcoatRoughnessSlide?.m_clearcoatRoughnessFlipY.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        let Mat;
        if (self.m_currentEditedMaterial) {
            Mat = self.m_materialEditor.FlipYTexture(['clearcoatRoughnessMap'], ev.value);
        }
        if (self.m_materialUpdatedCallback && Mat) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });

    this.m_clearcoatRoughnessSlide?.m_clearcoatRoughnessClear.BindType(() => {
        if (!self.m_currentEditedMaterial) return;
        let texSlot = self.GetTextureFromSlot(MapType.clearCoatRoughnessMap);
        if (!texSlot) return;
        let Mat = self.m_materialEditor.ClearMap(this.m_materialEditorParameters, [texSlot.slotName]);
        if (this.m_savedMaterial && self.m_currentEditedMaterial && self.m_dirtyGLTFCallback) self.m_dirtyGLTFCallback(this.m_savedMaterial, self.m_currentEditedMaterial);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat.clone() as MeshPhysicalMaterial, true);
        let folder = self.m_clearcoatRoughnessSlide?.m_uiFolder;
        folder.title = this.m_titlesLabel[texSlot.slotName] + " [no texture]";
        this.m_pane.refresh();
    });

}