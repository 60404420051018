import {InspectorType} from "./InspectorType";
import MaterialEditorParameters from "../MaterialEditorParameters";

export class ButtonType extends InspectorType {
    private m_label: string;

    constructor(label:string, matParam: MaterialEditorParameters, paramName: string, uiFolder: any) {
        super(matParam, paramName, uiFolder);
        this.m_label = label;
    }
    public InitType(){
        this.m_uiFolder[this.m_paramName] = this.m_uiFolder.addButton({
            label: ' ',
            title: this.m_label,
        });
    }

    public override BindType(p_callback: (ev: any) => void): void {
        this.m_uiFolder[this.m_paramName].on('click', p_callback);
    };

}
