import './products.scss';
import ProductsTable from '../../../../../components/table/products-table/products-table';


type ProductsProps = {
    openModal: boolean;
    setOpenModal: (openModal: boolean) => void;
    setProductReference: (productReference: string) => void;
    setOpenDeleteProductModal: (openDeleteModal: boolean) => void;
};
const Products = (props: ProductsProps) => {
    return (
        <div className='products-main'>
            <ProductsTable
                setProductReference={ props.setProductReference }
                setOpenDeleteProductModal={ props.setOpenDeleteProductModal }
                setOpenModal={ props.setOpenModal } />
        </div>
    );
};
export default Products;
