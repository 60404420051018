import React, { ChangeEvent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formErrors } from '../../../utils/form-errors';
import close from '../../../asset/CloseWindow.svg';
import { useDispatch } from 'react-redux';
import { createProduct } from '../../../services/reducers/product/product.reducers';
import { ProductType } from '../../../domain/domain';
import './create-product.scss';
import { submitOnKeyDown } from '../../../utils/actionOnkeyDown';
import { returnadvice } from '../mapping-zoning/mapping/create-mapping';

type CreateProductsProps = {
    setOpenModal: (openModal: boolean) => void;
    collectionReference: string;
};

type UpdateCreateProductInput = {
    name: string;
    type: string;
};

const CreateProducts = (props: CreateProductsProps) => {
    const dispatch = useDispatch();
    const [checkedValue, setCheckedValue] = useState<number>(1);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCheckedValue(parseInt(e.target.value));
    };

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdateCreateProductInput>({});

    const onSubmit: SubmitHandler<UpdateCreateProductInput> = (data) => {
        const checkType = (num: string) => {
            let type: ProductType = 'CONFIGURABLE';
            if (parseInt(num) === 1) {
                type = 'CONFIGURABLE';
            } else if (parseInt(num) === 2) {
                type = 'SIMPLE';
            } else if (parseInt(num) === 3) {
                type = 'COMPOSITION';
            }

            return type;
        };
        dispatch(
            createProduct({
                productType: checkType(data.type),
                name: data.name,

                collectionReference: props.collectionReference,
            })
        );
    };
    const checkRadio = (value: number) => {
        return value === checkedValue;
    };

    return (
        <div className="create-product-main">
            <div className="create-product-paper">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e) => {
                        submitOnKeyDown(e, handleSubmit, onSubmit);
                    }}
                >
                    <div className="create-product-title">
                        <img onClick={() => props.setOpenModal(false)} alt="fermer" src={close} />
                        <span>Créer un produit</span>
                    </div>
                    <div className="create-product-input">
                        <label>Nom</label>
                        <input
                            {...register('name', {
                                required: true,
                                maxLength: 24,
                            })}
                            placeholder="Nom du produit"
                            type="text"
                        />
                        <div> {returnadvice(watch('name'), 24)}</div>
                        {errors && formErrors(errors.name, 24)}
                    </div>
                    <div className="create-product-checkbox">
                        <label>Type de produit</label>
                        <div className="checkbox-container">
                            <div className="checkbox">
                                <input
                                    {...register('type', {
                                        required: true,
                                    })}
                                    checked={checkRadio(1)}
                                    type="radio"
                                    name="type"
                                    value={1}
                                    id="1"
                                    onChange={(e) => onChange(e)}
                                />
                                <span>Configurable</span>
                            </div>
                            <div className="checkbox">
                                <input
                                    {...register('type', {
                                        required: true,
                                    })}
                                    checked={checkRadio(2)}
                                    type="radio"
                                    name="type"
                                    value={2}
                                    id="2"
                                    onChange={(e) => onChange(e)}
                                />
                                <span>Simple</span>
                            </div>
                            <div className="checkbox">
                                <input
                                    {...register('type', {
                                        required: true,
                                    })}
                                    checked={checkRadio(3)}
                                    type="radio"
                                    name="type"
                                    value={3}
                                    id="3"
                                    onChange={(e) => onChange(e)}
                                />
                                <span>Composition</span>
                            </div>
                        </div>
                    </div>
                    <div className="create-product-btn-box">
                        <button className="btn-close" onClick={() => props.setOpenModal(false)}>
                            Annuler
                        </button>
                        <button className="btn-action">Créer</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateProducts;
