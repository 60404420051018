import React, { useEffect, useState } from 'react';
import { Store, StoreByUser, User } from '../../domain/domain';
import DateFormatter from '../../utils/date-formatter';

import editer from '../../asset/Editer.svg';
import editerBleu from '../../asset/EditerBleu.svg';
import keygrey from '../../asset/key-grey.svg';
import keyBleu from '../../asset/Key-bleu.svg';
import supprimer from '../../asset/poubelle-red.svg';
import disabledTrash from '../../asset/poubelle-disabled.svg';
import trash from '../../asset/poubelle-disabled.svg';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import { deleteUser } from '../../services/reducers/delete-user/delete-user.slice';
import { setToasterError } from '../../services/reducers/toaster-reducer/toaster.slice';
import { getMe, GetMeState, getResponsibleList, ResponsibleState } from '../../services/reducers/users/users.slice';

import HoverIcon from '../hover-icon/hover-icon';
import SimpleModal from '../modal/simple-modal/simple-modal';

import './user-table.scss';
import { getUserStoreMap, StoreUserMapState } from '../../services/reducers/store/store.slice';


type UsersTableProps = {
    headers: string[];
    users?: User[];
    onResetPasswordUser: () => void;
    setUserToResetPasswordSelected: (id: number) => void;
    openM: boolean;
    setOpenM: (openM: boolean) => void;
};

const UsersTable = ({
                        headers,
                        users,
                        onResetPasswordUser,
                        setUserToResetPasswordSelected,
                        setOpenM,
                        openM,
                    }: UsersTableProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [id, setId] = useState<number>(0);
    const [isMount, setIsMOunt] = useState(false);
    const [userStoreMap, setUserStoreMap] = useState<StoreByUser[]>([]);

    const { responsibleList } = useSelector<RootState, ResponsibleState>((state) => state.getResponsibleList);

    const { payload } = useSelector<RootState, GetMeState>((state) => state.getMe);

    const storeUserMapRes = useSelector<RootState, StoreUserMapState>((state) => state.storeUserMap);

    useEffect(() => {
        !isMount && dispatch(getResponsibleList());
        !isMount && dispatch(getMe());

        return () => setIsMOunt(true);
    }, []);

    useEffect(() => {
        if (users && users.length !== 0) {
            const userIdList: number[] = [];
            users.forEach((el) => {
                userIdList.push(el.id);
            });
            dispatch(getUserStoreMap({ userIdList: userIdList }));
        }
    }, [users]);

    useEffect(() => {
        if (storeUserMapRes.payload && storeUserMapRes.payload.content && storeUserMapRes.payload.errors.length === 0) {
            setUserStoreMap(storeUserMapRes.payload.content);
        }
    }, [storeUserMapRes]);

    const onMouseEnter = (isDisabled: boolean) => {
        if (isDisabled) {
            setShowTooltip(true);
            setMessage('Vous ne pouvez pas supprimer votre propre compte');
        } else {
            setShowTooltip(false);
            setMessage('');
        }
    };
    const getResponsible = (responsibleId: number) => {
        const firstname = responsibleList.content && responsibleList.content.filter((e) => e.id === responsibleId)[0] && responsibleList.content.filter((e) => e.id === responsibleId)[0].firstname;
        const lastname = responsibleList.content && responsibleList.content.filter((e) => e.id === responsibleId)[0] && responsibleList.content.filter((e) => e.id === responsibleId)[0].lastname;

        return firstname && lastname ? lastname + ' ' + firstname : ' ';
    };

    const onDeleteUser = (userId: number) => {
        dispatch(deleteUser({ userId: userId }));
        dispatch(
            setToasterError({
                message: 'l\'utilisateur à bien été supprimé',
                title: 'Suppression d\'utilisateurs',
            }),
        );
        setOpenM(false);
    };
    const capitalized = (firstname: string) => firstname.charAt(0).toUpperCase() + firstname.slice(1);

    const displayUserStores = (userId: number): Store[] => {
        const found = userStoreMap.find((el) => el.userId === userId);
        return !found ? [] : found.storeList;
    };

    return (
        <table className='user-table-main'>
            { openM && (
                <SimpleModal
                    icon={ trash }
                    title='Supprimer un utilisateur'
                    info='Si vous validez, cet utilisateur sera définitivement supprimé'
                    closeOnclick={ () => setOpenM(false) }
                    actionOnclick={ () => onDeleteUser(id) }
                    deleteOrValide={ true }
                    closeLabel='Annuler'
                    actionLabel='Supprimer'
                />
            ) }
            <thead className='user-table-head'>
            <tr>
                { headers.map((header, i) => {
                    return (
                        <th scope='col' key={ i }>
                            { header }
                        </th>
                    );
                }) }
            </tr>
            </thead>
            <tbody>
            { users?.map((el) => {
                return (
                    <tr key={ el.id }>
                        <td className='name'> { el.lastname.toUpperCase() + ' ' + capitalized(el.firstname) }</td>
                        <td className='email'> { el.email }</td>
                        <td className='role'>
                            { el.roles.map((e) => {
                                return <span key={ e.id }>{ e.id !== 1 && e.label + ', ' }</span>;
                            }) }
                        </td>
                        <td className='lastConnection'>{ DateFormatter.dateTimeStampToDateTime(el.connectionAt) }</td>
                        <td className='rattachment'>{ displayUserStores(el.id)
                            .map((store) => {
                                return (<span key={ store.id }>{ store.name }</span>);
                            }) }</td>
                        <td>{ getResponsible(el.responsibleId) }</td>
                        <td>
                        <div className='action-main'>
                            <div onClick={ () => history.push(`/update-user-by-admin/${ el.id }`) }>
                                <HoverIcon icon={ editer } iconHover={ editerBleu } title='modifier' />
                            </div>
                            <div
                                className='key'
                                onClick={ () => {
                                    setUserToResetPasswordSelected(el.id);
                                    onResetPasswordUser();
                                } }
                            >
                                <HoverIcon icon={ keygrey } iconHover={ keyBleu }
                                           title='Réinitialiser le mot de passe ' />
                            </div>
                            <div
                                className='user-table__btn-delete-user'
                                onClick={ () => {
                                    setOpenM(true);
                                    setId(el.id);
                                } }
                                onMouseEnter={ () => onMouseEnter(payload.content?.user.id === el.id) }
                                onMouseLeave={ () => setShowTooltip(false) }
                            >
                                { payload.content?.user.id === el.id ? (
                                    <HoverIcon icon={ disabledTrash } iconHover={ disabledTrash }
                                               title='Vous ne pouvez pas supprimer votre propre compte' />
                                ) : (
                                    <HoverIcon icon={ supprimer } iconHover={ supprimer } title='supprimer' />
                                ) }

                                { showTooltip && payload.content?.user.id === el.id &&
                                    <div className='user-table__tooltip'>{ message }</div> }
                            </div>
                        </div>

                        </td>
                    </tr>
                );
            }) }
            </tbody>
        </table>
    );
};

export default UsersTable;
