import Loader from '../../uicomponents/loader/loader';
import { resetCreateNews } from '../../services/reducers/create-news/create-news.slice';
import { Pagination } from '../../uicomponents/pagination/pagination';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { News, NewsCategory, NewsCriticality, NewsStatus } from '../../domain/domain';
import { RootState } from '../../services/root-reducer';
import { deleteNews, DeleteNewsState, getNewsList, getNewsStatusCount, NewsState, NewsStatusCountState } from '../../services/reducers/news/news.slice';
import { getNewsCategoryList, NewsCategoryState } from '../../services/reducers/news/news-cateogory-list.slice';
import moment from 'moment';
import { setToasterError, setToasterVisible } from '../../services/reducers/toaster-reducer/toaster.slice';
import { SingleNewsState, updateNewsStatus } from '../../services/reducers/archive-news/archive-news.slice';
import MdfPreconditions from '../../utils/MdfPreconditions';
import Layout from '../../uicomponents/layout/layout';
import UiTable from '../../uicomponents/table/ui-table/ui-table';
import MainPaper from '../../uicomponents/paper/main-paper';
import FilterBlock from '../../uicomponents/flilter-block/filter-block';

import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

import '../../uicomponents/preview/preview.scss';
import { useTranslation } from 'react-i18next';
import { Params } from '../../domain/Params/params-interface';

const NewsList = () => {
    const { t } = useTranslation();
    const headers = [t('Ta.news.H.title'), t('Ta.news.H.published'), t('Ta.news.H.expires'), t('Ta.news.H.category'), t('Ta.news.H.action')];
    const history = useHistory();
    const dispatch = useDispatch();
    const [newsList, setNewsList] = useState<News[]>([]);
    const [imgUrl, setImgUrl] = useState('');
    const [openImg, setOpenImg] = useState(false);
    const [status, setStatus] = useState<NewsStatus | undefined>('PUBLISHED');
    const [criticality] = useState<NewsCriticality | undefined>(undefined);
    const [filteredvalue] = useState<News[]>([]);
    const [preview, setPreview] = useState(false);
    // @ts-ignore
    const [newsPreview, setNewsPreview] = useState<News>(undefined);
    const { payload, isLoading } = useSelector<RootState, NewsState>((state: RootState) => state.news);
    const updatedNewsResponse = useSelector<RootState, SingleNewsState>((state: RootState) => state.updateNewsStatus);
    const categoryListResponse = useSelector<RootState, NewsCategoryState>((state: RootState) => state.categoryList);
    const newsStatusCountResponse = useSelector<RootState, NewsStatusCountState>((state) => state.newsStatusCount);
    const deleteNewsResponse = useSelector<RootState, DeleteNewsState>((state) => state.deleteNews);

    const { statusParam } = useParams<Params>();

    const getCategoryName = (id: number): NewsCategory | undefined => {
        const categoryFound = categoryListResponse.payload.content.find((el) => el.id === id);
        return categoryFound;
    };

    useEffect(() => {
        if (statusParam !== undefined) {
            switch (statusParam) {
                case 'published':
                    setStatus('PUBLISHED');
                    break;
                case 'scheduled':
                    setStatus('SCHEDULED');
                    break;
                case 'draft':
                    setStatus('DRAFT');
                    break;
                case 'archived':
                    setStatus('ARCHIVED');
                    break;
                default:
                    setStatus('PUBLISHED');
                    break;
            }
        } else {
            history.push('/home/news/published');
        }
    }, [statusParam]);

    useEffect(() => {
        dispatch(getNewsList({ status: status, criticality: undefined }));
    }, [status]);

    useEffect(() => {
        const { content } = payload;
        MdfPreconditions.continueIfNoErrors(payload, [], dispatch, () => {
            const sorted = [...content.content].sort((news1, news2) => {
                const date1 = moment(news1.updatedAt);
                const date2 = moment(news2.updatedAt);
                return date2.diff(date1);
            });
            setNewsList(sorted);
        });
        dispatch(getNewsStatusCount());
        dispatch(getNewsCategoryList());
    }, [payload]);

    useEffect(() => {
        if (updatedNewsResponse.payload.errors.length !== 0) {
            dispatch(
                setToasterError({
                    message: `L'action n'a pas pu être finalisée`,
                    title: 'Article non modifiée',
                })
            );
        } else {
            dispatch(getNewsList({ status: status, criticality: criticality }));
        }
    }, [updatedNewsResponse]);

    useEffect(() => {
        const { successMessage, errorMessage } = deleteNewsResponse;
        if (deleteNewsResponse.payload.errors.length !== 0) {
            dispatch(setToasterError({ message: errorMessage, title: 'Erreur de suppression News' }));
        } else if (successMessage.length > 0) {
            dispatch(setToasterVisible({ message: successMessage, title: 'News supprimée' }));
            dispatch(getNewsList({ status: status }));
        }
    }, [deleteNewsResponse]);

    const onArchiveNews = (newsId: number, newsStatus: NewsStatus): void => {
        dispatch(updateNewsStatus({ newsId: newsId, status: newsStatus }));
    };

    const onDeleteNews = (newsId: number): void => {
        dispatch(deleteNews({ newsId: newsId }));
    };

    const paginationChange = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, pageClicked: number) => {
        dispatch(getNewsList({ status: undefined, criticality: undefined, page: pageClicked }));
    };

    const findStatusCount = (statusToFind: NewsStatus) => {
        const foundStatusCount = newsStatusCountResponse.payload.content && newsStatusCountResponse.payload.content.find((el) => el.status === statusToFind);
        if (!foundStatusCount) {
            return 0;
        }
        return foundStatusCount.count;
    };

    const countTotalNews = () => {
        let total = 0;
        newsStatusCountResponse.payload.content &&
            newsStatusCountResponse.payload.content.forEach((el) => {
                total += el.count;
            });
        return total;
    };
    return (
        <>
            {openImg && (
                <div className="img-preview" onClick={() => setOpenImg(false)}>
                    <DocViewer pluginRenderers={DocViewerRenderers} documents={[{ uri: imgUrl }]} />
                </div>
            )}
            <Layout
                title={t('P.T.news')}
                labelBack=""
                setOpen={setOpenImg}
                open={openImg}
                setImgUrl={setImgUrl}
                imageFile={newsPreview && newsPreview.documentUrls}
                getCategoryName={getCategoryName}
                setPreview={setPreview}
                preview={preview}
                data={newsPreview}
            >
                <Loader isLoading={isLoading} />
                <FilterBlock
                    selectedTab={status}
                    itemsTotalCount={countTotalNews()}
                    label="+ Créer une publication"
                    findCountByStatus={findStatusCount}
                    news={true}
                    clearFilter={() => {
                        history.push('/home/news');
                    }}
                    pubOnclick={() => history.push('/home/news/published')}
                    proOnclick={() => history.push('/home/news/scheduled')}
                    drafOnclick={() => history.push('/home/news/draft')}
                    archOnclick={() => history.push('/home/news/archived')}
                    onClickfunc={() => {
                        dispatch(resetCreateNews());
                        history.push('/create-news');
                    }}
                />
                <MainPaper>
                    {/*<SearchBar setFilterValue={setFilteredValue} />*/}
                    {filteredvalue.length !== 0 ? (
                        <UiTable setNewsPreview={setNewsPreview} setPreview={setPreview} headers={headers} list={filteredvalue} getCategoryName={getCategoryName} onArchiveNews={onArchiveNews} />
                    ) : (
                        <UiTable
                            setNewsPreview={setNewsPreview}
                            setPreview={setPreview}
                            headers={headers}
                            list={newsList}
                            getCategoryName={getCategoryName}
                            onArchiveNews={onArchiveNews}
                            onDeleteNews={onDeleteNews}
                        />
                    )}
                    <div>{payload.content && <Pagination page={payload.content} action={paginationChange} />}</div>
                </MainPaper>
            </Layout>
        </>
    );
};

export default NewsList;
