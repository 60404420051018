import React, { useEffect } from 'react';
import { formErrors } from '../../../../utils/form-errors';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../services/root-reducer';
import { getFinish, GetFinishState, updateFinish } from '../../../../services/reducers/suppliers/finish/finishes.slice';
import { Tag, VignetteEnum } from '../../../../domain/domain';

type UpdateFinitionProps = {
    setOpenUpdateFinitionModal: (openFinitionModal: boolean) => void;
    finishId: number;
};
type Inputs = {
    name: string;
    reference: string;
    typeVignette: VignetteEnum;
    technicalDescription: string;
    commercialDescription: string;
    tags: Tag[];
};

const UpdateFinition = ({ setOpenUpdateFinitionModal, finishId }: UpdateFinitionProps) => {
    const dispatch = useDispatch();
    const { payload } = useSelector<RootState, GetFinishState>((state) => state.getFinish);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<Inputs>();

    useEffect(() => {
        dispatch(getFinish(finishId));
    }, []);

    useEffect(() => {
        if (payload.content) {
            payload && setValue('name', payload.content.name, {});
            payload && setValue('reference', payload.content.reference, {});
            payload && setValue('typeVignette', payload.content.typeVignette, {});
            payload && setValue('tags', payload.content.tags, {});
            payload && setValue('commercialDescription', payload.content.businessDescription, {});
            payload && setValue('technicalDescription', payload.content.technicalDescription, {});
        }
    }, [payload]);

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        dispatch(
            updateFinish({
                finishId: finishId,
                finish: {
                    name: data.name,
                    typeVignette: data.typeVignette,
                    reference: data.reference,
                    tagIdList: [],
                    tagIdListPrix: [],
                    technicalDescription: data.technicalDescription,
                    businessDescription: data.commercialDescription,
                    logoUrl: '',
                },
            })
        );
        setOpenUpdateFinitionModal(false);
    };

    return (
        <div className="create-finition-main">
            <div className="create-finition-paper">
                <div className="create-finition-top">
                    <div className="finition-name"> Update Finition</div>
                    <div onClick={() => setOpenUpdateFinitionModal(false)} className="finition-close">
                        X
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="create-finition-form">
                    <div className="form-left">
                        <h6>INFORMATIONS GÉNÉRALES</h6>
                        <label htmlFor="name">Nom</label>
                        <input placeholder="Nom de la finition" {...register('name')} />
                        {errors && formErrors(errors.name)}

                        <label>Type de vignette</label>
                        <select defaultValue={payload.content && payload.content.typeVignette} {...register('typeVignette', { required: true })}>
                            <option value="A_PLAT">Aplat</option>
                            <option value="TISSUS">Tissus</option>
                            <option value='SPHERE'>Sphère</option>
                        </select>
                        {errors && formErrors(errors.typeVignette)}

                        <label htmlFor="Reference">Réference</label>
                        <input readOnly={true} placeholder="Réference de la finition" {...register('reference')} />
                        {errors && formErrors(errors.reference)}

                        <label htmlFor="Tags">Tags</label>
                        <textarea placeholder="Ajouter des tags" {...register('tags')} />
                        {errors && formErrors(errors.tags)}
                    </div>

                    <div className="form-right">
                        <h6>DESCRIPTIONS</h6>
                        <>
                            <label htmlFor="commercialDescription">Déscription commerciale</label>
                            <textarea value={payload.content && payload.content.businessDescription} placeholder="Description commerciale" {...register('commercialDescription')} />
                            {errors && formErrors(errors.commercialDescription)}
                        </>
                        <>
                            <label htmlFor="technicalDescription">Déscription téchnique</label>
                            <textarea defaultValue={payload.content && payload.content.technicalDescription} placeholder="Description technique" {...register('technicalDescription')} />
                            {errors && formErrors(errors.technicalDescription)}
                        </>
                        <div className="btn-box">
                            <button onClick={() => setOpenUpdateFinitionModal(false)} className="btn-canceled">
                                Annuler
                            </button>
                            <button className="btn-submit" type="submit">
                                Sauvegarder
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpdateFinition;
