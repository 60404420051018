import React, { useState } from 'react';
import { News, NewsCategory } from '../../domain/domain';
import Tag from '../tag/tag';
import strikeEye from '../../asset/Groupe 1388.svg';
import Eye from '../../asset/Apercu.svg';
import Download from '../../asset/Telecharger.svg';

import fileDownload from 'js-file-download';

import DateFormatter from '../../utils/date-formatter';
import axios from 'axios';

import 'react-quill/dist/quill.core.css';
import './preview.scss';

type PreviewProps = {
    setPreview: (preview: boolean) => void;
    data: News;
    getCategoryName?: (id: number) => NewsCategory | undefined;
    imageFile?: string[];
    color?: string;
    backGroundColor?: string;
    setImgUrl?: (imgUrl: string) => void;
    setOpen?: (open: boolean) => void;
    open?: boolean;
};

const Preview = ({ setPreview, data, imageFile, setImgUrl, setOpen, open }: PreviewProps) => {
    const [categoryName] = useState<string | undefined>('');

    const multipleOnclick = (e: string) => {
        setImgUrl && setImgUrl(e);
        setOpen && setOpen(!open);
    };

    return (
        <div className="main-news">
            <div className="close-container">
                <Tag title={categoryName} newsCategoryId={data.newsCategoryId} width={200} />

                <div onClick={() => setPreview(false)} className="close-button">
                    <img alt="logo oeil" src={strikeEye} />
                    Quitter l'aperçu
                </div>
            </div>
            <div className="news-body">
                <div className="preview-title">{data && data.title}</div>
                <div className="news-date">{DateFormatter.dateTimeStampToDateTime(data && data.createdAt)}</div>
                <div className="news-content" dangerouslySetInnerHTML={{ __html: data.content }} />
            </div>
            <div className="prewiew-footer">
                <div className="news-join">Pieces Jointes</div>
                <div className="upload-bloc-container">
                    {imageFile &&
                        imageFile.map((e: any, i: number) => {
                            const downloadDocument = () => {
                                let filePath = e;
                                axios
                                    .get(`${filePath}`, {
                                        responseType: 'blob',
                                    })
                                    .then((res) => {
                                        let filename = filePath.replace(/^.*[\\/]/, '');
                                        let fileExtension;
                                        fileExtension = filePath.split('.');
                                        fileExtension = fileExtension[fileExtension.length - 1];
                                        fileDownload(res.data, `${filename}.${fileExtension}`);
                                    });
                            };

                            let filename;
                            let title;
                            let fileExt;

                            let dataFileName = '';
                            let dataFileExt;
                            if (typeof e !== 'string') {
                                filename = e.name;
                                const split = filename.split('.');
                                title = split[0];
                                dataFileExt = split[1];
                            } else {
                                fileExt = e.split('.').pop();
                                const n = e.lastIndexOf('/');
                                const result = e.substring(n + 1);

                                const n2 = result.indexOf('-');
                                const result2 = result.substring(n2 + 1);
                                const extensionname = result2.split('.', 1);
                                dataFileName = extensionname[0];
                            }

                            const message = title || dataFileName;
                            const cutFileName = message.substring(0, 25);

                            return (
                                <div key={i} className="bloc">
                                    <div className="upload-title">
                                        <div style={{ color: 'black', fontWeight: 'bold' }}>{cutFileName}...</div>
                                        {typeof e !== 'string' ? <div>{dataFileExt}</div> : <div>{fileExt}</div>}
                                    </div>

                                    <div className="preview-join-icon-container">
                                        {typeof e !== 'object' ? (
                                            fileExt !== 'docx' ? (
                                                <>
                                                    <img onClick={() => downloadDocument()} alt="logo telechargement" src={Download} />
                                                    <img alt="logo oeil" src={Eye} onClick={() => multipleOnclick(e)} />
                                                </>
                                            ) : (
                                                <img onClick={() => downloadDocument()} alt="logo telechargement" src={Download} />
                                            )
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};
export default Preview;
