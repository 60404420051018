import './hamburger.scss';
import React, { useEffect, useState } from 'react';
import { Squash as Hamburger } from 'hamburger-react';
import { useHistory } from 'react-router-dom';
import envoyer from '../../asset/Envoyer.svg';
import utilisateur from '../../asset/Profil.svg';
import base from '../../asset/menubackoffice - Base produit.svg';

import { useDispatch } from 'react-redux';
import { getMe } from '../../services/reducers/users/users.slice';
import { Role, RolePermissionType } from '../../domain/domain';


const HamburgerMenu = () => {
    const [open, setOpen] = useState(false);
    const [menuDetail, setMenuDetail] = useState({ open: false, menu: 0 });
    const [ismount, setIsMount] = useState(false);
    const [peList, setPelist] = useState<RolePermissionType[]>([]);
    const [roList, setRolist] = useState<string[]>([]);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        !ismount && dispatch(getMe());
        const l = localStorage.getItem('user');
        const pList: RolePermissionType[] = [];
        const rList: string[] = [];
        if (l !== null) {
            const user = JSON.parse(l);
            user.roles.forEach((r: Role) => rList.push(r.name));
            user.roles.forEach((r: Role) => r.permissions.forEach((p) => {
                pList.push(p.name);
                return p.name;
            }));
        }
        !ismount && setRolist(rList);
        !ismount && setPelist(pList);
    }, []);
    useEffect(() => {
        //console.log('plist=>', peList[0]);
        //!ismount && peList[0] === ('ADMINISTRATION') && history.push('home/users');
        return () => setIsMount(true);
    }, [peList]);
    useEffect(() => {
        !open && setMenuDetail({ open: false, menu: 0 });
    }, [open]);

    const checkPermission = (list: any[]) => {
        return peList.some(p => list.includes(p));
    };
    const onMouseEnter = (key: number, menu: number, src: string, label: string, permList: string[]) => {
        return (
            <li key={ key }
                onMouseEnter={ () => {
                    checkPermission(permList)
                        ? setMenuDetail({ open: true, menu: menu })
                        : setMenuDetail({ open: false, menu: 0 });
                } }>
                { checkPermission(permList) ? <img alt='news logo' title='news' src={ src } /> : <></> }
                <span className={ checkPermission(permList) ? '' : 'disable' }>{ label }</span>
            </li>
        );
    };
    const rightMenuLi = (path: string, label: string, permList: RolePermissionType[]) => {
        return (
            <li className={ checkPermission(permList) ? '' : 'disable' }
                onClick={ () => !checkPermission(permList) ? console.log('') : history.push(path) }>
                { label }
            </li>
        );
    };
    const returnStorePerm = () => {
        if (roList.includes('ROLE_STORE_MANAGER')) {
            return 'Mes magasins';
        } else {
            return 'Magasins';
        }
    };

    const leftMenu = [
        { menu: 1, src: utilisateur, label: 'Administration', permList: ['ADMINISTRATION', 'STORE_MANAGER'] },
        { menu: 2, src: envoyer, label: 'News', permList: ['NEWS_BACKOFFICE'] },
        { menu: 3, src: base, label: 'Base Produit', permList: ['BASE_PRODUIT'] },
    ];

    return (
        <div aria-label='hamburger-menu'>
            <div className='hamburger'>
                <Hamburger
                    rounded duration={ 0.8 }
                    toggled={ open }
                    toggle={ setOpen }
                    color='black'
                    aria-label='hamburger-Menu' />
            </div>
            { open && (
                <div className='glob' onClick={ () => setOpen(!open) }>
                        <ul className="u">
                            { leftMenu.map((item, i) => {
                                return onMouseEnter(i, item.menu, item.src, item.label, item.permList);
                            }) }
                        </ul>
                    {
                        menuDetail.open && <div className='glob-detail'>
                            <div className='sub-modal'>
                                { menuDetail.menu === 1 &&
                                    <ul>
                                        { rightMenuLi('/home/users', 'Utilisateurs', ['ADMINISTRATION']) }
                                        { rightMenuLi('/home/roles', 'Rôles', ['ADMINISTRATION']) }
                                        { rightMenuLi('/stores', returnStorePerm(), ['ADMINISTRATION', 'STORE_MANAGER']) }
                                    </ul> }
                                { menuDetail.menu === 2 &&
                                    <ul>
                                        { rightMenuLi('/home/news', 'Publications', ['ADMINISTRATION', 'NEWS_BACKOFFICE']) }
                                        { rightMenuLi('/home/news-categories', 'Catégories', ['ADMINISTRATION', 'NEWS_BACKOFFICE']) }
                                    </ul> }
                                { menuDetail.menu === 3 &&
                                    <ul>
                                        { rightMenuLi('/home/suppliers', 'Fournisseurs', ['ADMINISTRATION', 'BASE_PRODUIT']) }
                                        { rightMenuLi('/catalogs', 'Catalogues', ['ADMINISTRATION', 'BASE_PRODUIT']) }
                                    </ul> }
                            </div>
                        </div>
                    }
                </div>
            ) }
        </div>
    );
};

export default HamburgerMenu;
