import './create-product-toast.scss';
import validation from '../../../asset/Validation-vert.svg';


const CreateProductToast = () => {
    return (
        <div className='create-product-toast-main'>
            <img alt='valider' src={validation} />
            <span> Le produit a bien été sauvegardé</span>
        </div>
    );
};
export default CreateProductToast;
