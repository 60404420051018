import './create-sub-section.scss';
import close from '../../../../asset/CloseWindow.svg';
import { useEffect, useState } from 'react';
import { Section, SubSection, TreeCatalog } from '../../../../domain/domain';
import { useDispatch, useSelector } from 'react-redux';
import {
    createSubSection,
    getAllSubSections,
    resetUpdateSubSection,
    SubSectionState,
} from '../../../../services/reducers/catalog/sub-section.slice';
import { RootState } from '../../../../services/root-reducer';


type CreateSubSectionProps = {
    setOpenModal: (openModal: boolean) => void;
    subSection: SubSection | undefined;
    section: Section | undefined;
    setSubSection: (subSection: SubSection | undefined) => void;
    treeCalalog: TreeCatalog
};


const CreateSubSection = ({ setOpenModal, subSection, setSubSection, section }: CreateSubSectionProps) => {
    const dispatch = useDispatch();
    const [nameErr, setNameErr] = useState('');
    const [name, setName] = useState<string>('');
    const [mount, setMount] = useState(false);

    const createSubSectionRes = useSelector<RootState, SubSectionState>((state) => state.createSubSection);
    const updateSubSectionRes = useSelector<RootState, SubSectionState>((state) => state.updateSubSection);

    useEffect(() => {
        !mount && subSection && setName(subSection.name);
        return () => setMount(false);
    }, [subSection]);
    useEffect(() => {
        if (createSubSectionRes.payload.content && createSubSectionRes.payload.content.id !== 0 && createSubSectionRes.payload.errors.length === 0) {
            cancel();
        } else if (createSubSectionRes.payload.errors.length > 0) {
        }
    }, [createSubSectionRes]);
    useEffect(() => {
        if (updateSubSectionRes.payload.content && updateSubSectionRes.payload.content.id !== 0 && updateSubSectionRes.payload.errors.length === 0) {
            section && dispatch(getAllSubSections(section.id));
            cancel();
            dispatch(resetUpdateSubSection());
        }
    }, [updateSubSectionRes]);

    const cancel = () => {
        setOpenModal(false);
        setSubSection(undefined);
        setName('');
        setNameErr('');
    };
    const OnUpdate = () => {
        if (subSection?.name === name) {
            setOpenModal(false);
        } else {
            if (name.length === 0) {
                setNameErr('Le nom doit être spécifié');
            } else {
                subSection && section && subSection.id !== null &&
                dispatch(createSubSection({
                    sectionId: section?.id,
                    createAndUpdateSubSectionForm: {
                        id: subSection.id,
                        name: name,
                        version: subSection.version,
                        reference: subSection.reference,
                        section: {
                            reference: '',
                            id: 0,
                            name: '',
                            version: 0,
                            logo: '',
                            catalogId: 0,
                        },
                    },
                }));
            }
        }
    };
    const OnSubmit = () => {
        if (name.length === 0) {
            setNameErr('Le nom doit être spécifié');
        } else {
            section &&
            dispatch(
                createSubSection({
                    sectionId: section?.id,
                    createAndUpdateSubSectionForm: { name: name },
                }),
            );
        }
    };

    return (
        <div className='create-sub-section-main'>
            <div className='create-sub-section-paper'>
                <div className='create-sub-section-top'>
                    <span>{subSection ? 'Renommer une sous rubrique' : 'Créer une nouvelle sous rubrique'}</span>
                    <img onClick={cancel} alt='fermer la modal' src={close} />
                </div>
                <div className='create-sub-section-body'>
                    <label>Nom</label>
                    <input
                        type='text'
                        placeholder='Nom de la sous rubrique'
                        defaultValue={subSection && subSection.name}
                        onChange={(e) => {
                            setNameErr('');
                            setName(e.target.value);
                        }}
                    />
                    <span>{nameErr}</span>
                </div>
                <div className='create-section-btn-box'>
                    <button onClick={cancel} className='btn-close'>
                        Annuler
                    </button>
                    <button onClick={subSection ? OnUpdate : OnSubmit} className='btn-action'>
                        {subSection ? 'Modifier' : 'Créer'}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default CreateSubSection;
