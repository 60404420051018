import apiClient from '../../api-client';
import {
    CREATE_USER,
    DELETE_USER, GET_ACTIVE_USER,
    GET_ME,
    GET_RESPONSIBLE_LIST,
    GET_USER,
    GET_USER_STATUS_COUNT,
    GET_USERS,
    GET_USERS_BY_STATUS,
    RESET_PASSWORD_USER,
    UPDATE_USER,
} from '../../endpoints/user/user.endpoints';
import { CreateUserType } from '../../../uicomponents/create-user-form/create-user.type';
import { StatusType } from '../../../domain/domain';

const UserService = {
    createUser: async (createUserForm: CreateUserType) => apiClient(CREATE_USER, createUserForm, undefined, 'POST'),
    getUserList: async (status: StatusType | undefined, role: string | undefined, page: number | undefined) => apiClient(GET_USERS, undefined, {
        status,
        role,
        page,
    }, 'GET'),
    getUserByStatus: async () => apiClient(GET_USERS_BY_STATUS, undefined, undefined, 'GET'),
    deleteUser: async (userId: number) => apiClient(DELETE_USER(userId), undefined, undefined, 'DELETE'),
    resetPasswordUserByAdmin: async (userId: number) => apiClient(RESET_PASSWORD_USER(userId), undefined, undefined, 'PUT'),
    getMe: async () => apiClient(GET_ME, undefined, undefined, 'GET'),
    updateUser: async (userId: number, createUserForm: CreateUserType) => apiClient(UPDATE_USER(userId), createUserForm, undefined, 'PUT'),
    getUser: async (userId: number) => apiClient(GET_USER(userId), undefined, undefined, 'GET'),
    geActiveUsers: async () => apiClient(GET_ACTIVE_USER(), undefined, undefined, 'GET'),
    getUserStatusCount: async () => apiClient(GET_USER_STATUS_COUNT, undefined, undefined, 'GET'),
    getResponsibleList: async () => apiClient(GET_RESPONSIBLE_LIST, undefined, undefined, 'GET'),
};

export default UserService;
