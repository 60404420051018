import MdfApiResponse from '../../../domain/common/generic';
import { SubSection } from '../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import subSectionService, { CreateAndUpdateSubSectionForm } from '../../../api/services/catalog/sub-section.service';

//#region state
export type SubSectionState = {
    isLoading: boolean;
    success: boolean;
    payload: MdfApiResponse<SubSection>;
};
export type SubSectionListState = {
    isLoading: boolean;
    payload: MdfApiResponse<SubSection[]>;
};
const initialSubSectionState: SubSectionState = {
    isLoading: false,
    success: false,
    payload: {
        content: {
            reference: '',
            name: '',
            id: 0,
            version: 0,
            section: { id: 0, version: 0, name: '', logo: '', catalogId: 0, reference: '' },
        },
        errors: [],
        warnings: [],
    },
};
const initialSubSectionListState: SubSectionListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};
//#endregion

//#region get sub-Section by section
export const getAllSubSections = createAsyncThunk('get/subSections', async (sectionId: number) => {
    const res = await subSectionService.getSubSectionsBySection(sectionId);
    return res;
});

export const getAllSubSectionsSlice = createSlice({
    name: 'getAllSubSections',
    initialState: initialSubSectionListState,
    reducers: {
        resetGetAllSubSection: (state) => {
            state = initialSubSectionListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllSubSections.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllSubSections.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllSubSections.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetAllSubSection } = getAllSubSectionsSlice.actions;
//#endregion

//#region get subsection by ID
export const getSubSectionById = createAsyncThunk('get/subSectionsById', async (subSectionId: number) => {
    const res = await subSectionService.getSubSectionById(subSectionId);
    return res;
});

export const getSubSectionByIdSlice = createSlice({
    name: 'getSubSectionById',
    initialState: initialSubSectionState,
    reducers: {
        resetGetSubSectionByID: (state) => {
            state = initialSubSectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSubSectionById.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getSubSectionById.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, success: true };
            return state;
        });
        builder.addCase(getSubSectionById.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetSubSectionByID } = getSubSectionByIdSlice.actions;
//#endregion

//#region get subsection by Product ID
export const getSubSectionByProductId = createAsyncThunk('get/subSectionsByProductId', async (productId: number) => {
    const res = await subSectionService.findSubsectionsByProductId(productId);
    return res;
});

export const getSubSectionByProductIdSlice = createSlice({
    name: 'getSubSectionById',
    initialState: initialSubSectionListState,
    reducers: {
        resetGetSubSectionByProductID: (state) => {
            state = initialSubSectionListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSubSectionByProductId.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getSubSectionByProductId.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getSubSectionByProductId.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetSubSectionByProductID } = getSubSectionByProductIdSlice.actions;
//#endregion

//#region create subsection
export const createSubSection = createAsyncThunk('create/subSection', async (data: {
    sectionId: number;
    createAndUpdateSubSectionForm: CreateAndUpdateSubSectionForm
}) => {
    const res = await subSectionService.createSubSection(data.sectionId, data.createAndUpdateSubSectionForm);
    return res;
});

export const createSubSectionSlice = createSlice({
    name: 'createSubSectionById',
    initialState: initialSubSectionState,
    reducers: {
        resetCreateSubSection: (state) => {
            state = initialSubSectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createSubSection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createSubSection.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, success: true };
            return state;
        });
        builder.addCase(createSubSection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetCreateSubSection } = createSubSectionSlice.actions;
//#endregion

//#region update subsection
export const updateSubSection = createAsyncThunk('update/subSection', async (data: {
    subsectionId: number;
    subsection: SubSection
}) => {
    const res = await subSectionService.updateSubSection(data.subsectionId, data.subsection);
    return res;
});

export const updateSubSectionSlice = createSlice({
    name: 'updateSubSectionById',
    initialState: initialSubSectionState,
    reducers: {
        resetUpdateSubSection: (state) => {
            state = initialSubSectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateSubSection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateSubSection.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, success: true };
            return state;
        });
        builder.addCase(updateSubSection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetUpdateSubSection } = updateSubSectionSlice.actions;
//#endregion

//#region delete subsection
export const deleteSubSection = createAsyncThunk('delete/subSections', async (subSectionId: number) => {
    const res = await subSectionService.deleteSubSection(subSectionId);
    return res;
});

export const deleteSubSectionSlice = createSlice({
    name: 'deleteSubSection',
    initialState: initialSubSectionState,
    reducers: {
        resetDeleteSubSection: (state) => {
            state = initialSubSectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteSubSection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteSubSection.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, success: true };
            return state;
        });
        builder.addCase(deleteSubSection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetDeleteSubSection } = deleteSubSectionSlice.actions;
//#endregion

//#region link preset to subsection
export type LinkState = { isLoading: boolean; success: boolean };
const initialLinkState: LinkState = { isLoading: false, success: false };
export const linkPresetToSubSection = createAsyncThunk('link preset to/subSections', async (data: {
    catalogId: number,
    subSectionId: number,
    productId: number
}) => {
    const res = await subSectionService.linkPresetToSubSection(data.catalogId, data.subSectionId, data.productId);
    return res;
});

export const linkPresetToSubSectionSlice = createSlice({
    name: 'linkPresetToSubSection',
    initialState: initialLinkState,
    reducers: {
        resetLinkPresetToSubSection: (state) => {
            state = initialLinkState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(linkPresetToSubSection.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(linkPresetToSubSection.fulfilled, (state, { payload }) => {
            state = { isLoading: false, success: true };
            return state;
        });
        builder.addCase(linkPresetToSubSection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetLinkPresetToSubSection } = linkPresetToSubSectionSlice.actions;
//#endregion

//#region unLink preset to subsection

export const unLinkPresetToSubSection = createAsyncThunk('unLink preset to/subSections', async (data: {
    catalogId: number,
    subSectionId: number,
    productId: number
}) => {
    const res = await subSectionService.unLinkPresetToSubSection(data.catalogId, data.subSectionId, data.productId);
    return res;
});

export const unLinkPresetToSubSectionSlice = createSlice({
    name: 'unLinkPresetToSubSection',
    initialState: initialLinkState,
    reducers: {
        resetUnLinkPresetToSubSection: (state) => {
            state = initialLinkState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(unLinkPresetToSubSection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(unLinkPresetToSubSection.fulfilled, (state, { payload }) => {
            state = { isLoading: false, success: true };
            return state;
        });
        builder.addCase(unLinkPresetToSubSection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetUnLinkPresetToSubSection } = unLinkPresetToSubSectionSlice.actions;
//#endregion
