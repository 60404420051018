import {InspectorType} from "./InspectorType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {Vector2} from "three";

export class VectorData {
    get High(): Vector2 {
        return this.m_high;
    }
    get Low(): Vector2 {
        return this.m_low;
    }
    
    private m_low: Vector2;
    private m_high: Vector2;
    constructor(low: Vector2, high: Vector2) {
        this.m_low = low;
        this.m_high = high;
    }
}

export class VectorType extends InspectorType {
    
    private m_sliderData: VectorData;
    
    constructor(sliderParam: VectorData, matParam: MaterialEditorParameters, paramName: string, uiFolder: any) {
        super(matParam, paramName, uiFolder);
        this.m_sliderData = sliderParam;
    }
    public InitType(){
        this.m_uiFolder[this.m_paramName] = this.m_uiFolder.addInput(this.m_materialEditorParam, this.m_paramName, {
            picker: 'inline',
            x: {min: this.m_sliderData.Low.x, max: this.m_sliderData.High.x},
            y: {min: this.m_sliderData.Low.y, max: this.m_sliderData.High.y},
        });
    }

}
