import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapType } from '../../../components/material-editor/Scripts/Inspector/MapType';
import { Viewer3D } from '../../../components/material-editor/Scripts/Viewer3D/Viewer3D';
import { ExitType } from '../../../components/material-editor/Scripts/Inspector/ExitType';
import { Material } from 'three';

export type EditorState = {
    currentSlotRef: MapType | undefined; //Joza useless, permet de savoir quel node est éditée
    currentTexture: string | undefined; //URL ca permet de savoir quelle texture est appliquée a la slot ref déclarée au dessus
    game: Viewer3D | undefined;
    editorDirty: boolean; //useless
    exitStatus: ExitType;
    savedMat: Material | undefined;
};

const EditorInitialState: EditorState = {
    currentSlotRef: undefined,
    currentTexture: undefined,
    game: undefined,
    editorDirty: false,
    exitStatus: ExitType.Default,
    savedMat: undefined,
};

const EditorSlice = createSlice({
    name: 'Editor',
    initialState: EditorInitialState,
    //reducers : define how the state can be updated
    reducers: {
        SetCurrentSlotRef: (state, action: PayloadAction<MapType | undefined>) => {
            if (action.payload !== undefined) {
                if (action.payload != null) {
                    state.currentSlotRef = action.payload;
                }
            } else {
                state.currentSlotRef = undefined;
            }
        },
        SetCurrentTexture: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload != null) state.currentTexture = action.payload;
        },
        ClearCurrentTexture: (state) => {
            state.currentTexture = undefined;
        },
        SetEditorDirty: (state, action: PayloadAction<boolean>) => {
            if (action.payload != null) state.editorDirty = action.payload;
        },
        SetGame: (state, action: PayloadAction<Viewer3D>) => {
            if (action.payload != null) state.game = action.payload;
        },
        ClearGame: (state) => {
            state.game = undefined;
        },
        SetExitStatus: (state, action: PayloadAction<ExitType>) => {
            if (action.payload != null) state.exitStatus = action.payload;
        },
        SetSavedMat: (state, action: PayloadAction<Material>) => {
            if (action.payload != null) state.savedMat = action.payload;
        },
    },
});

export const { SetCurrentSlotRef, SetCurrentTexture, SetEditorDirty, ClearCurrentTexture, SetGame, ClearGame, SetExitStatus, SetSavedMat } = EditorSlice.actions;
export default EditorSlice;
