const GET_COMPOSITION_MODULES = (compositionReference: string) => `v1/composition-modules/composition/${ compositionReference }`;
const ADD_MODULES_TO_COMPOSITION = (compositionReference: string) => `v1/composition-modules/composition/${ compositionReference }/add-modules`;
const REMOVE_MODULE_FROM_COMPOSITION = (compositionReference: string, moduleReference: string) => `v1/composition-modules/composition/${ compositionReference }/module/${ moduleReference }`;

const SAVE_METADATA_TO_COMPOSITION_MODULE = `v1/composition-modules/composition/metadata`;

export {
    GET_COMPOSITION_MODULES,
    SAVE_METADATA_TO_COMPOSITION_MODULE,
    ADD_MODULES_TO_COMPOSITION,
    REMOVE_MODULE_FROM_COMPOSITION,
};
