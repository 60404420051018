import './zoning.scss';
import plus from '../../../../../asset/plus-circle.svg';
import { ChangeEvent, useEffect, useState } from 'react';
import CreateZoning from '../../../../../components/modals/mapping-zoning/zoning/create-zoning';
import { Colori, GroupMapping, Mapping, Zone } from '../../../../../domain/domain';
import update from '../../../../../asset/Editer.svg';
import trash from '../../../../../asset/poubelle.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../services/root-reducer';
import {
    deleteZoning,
    resetCreateZoning,
    resetDeleteZoning,
    resetUpdateZoning,
    updateZoning,
    UpdateZoningApp,
    UpdateZoningIDs,
    ZoningState,
    ZoningState2,
} from '../../../../../services/reducers/mapping-zoning/zoning.reducer';
import { getAllGroups } from '../../../../../services/reducers/mapping-zoning/group-mapping.reducer';
import SimpleModal from '../../../../../uicomponents/modal/simple-modal/simple-modal';
import whiteTrash from '../../../../../asset/poubelle-disabled.svg';
import UpdateZoning from '../../../../../components/modals/mapping-zoning/zoning/update-zoning';
import ApplicableColoriModal
    from '../../../../../components/modals/mapping-zoning/zoning/applicable-coloris-modal/applicable-colori-modal';
import Toaster from '../../../../../uicomponents/toaster/toaster';

import { initialMapping, initialZone } from '../mappings';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../domain/Params/params-interface';
import {
    ColorisFromCommonAndSupplierIdState,
    getColoriFromSupplierAndCommon,
    getColoris,
    GetColorisState,
} from '../../../../../services/reducers/suppliers/finish/coloris/coloris.slice';
import {
    getMapping,
    MappingState,
    resetGetMapping,
} from '../../../../../services/reducers/mapping-zoning/mapping.reducer';


type ZoningProps = {
    currentGroup: GroupMapping;
    setCurrentGroup: (currentGroup: GroupMapping) => void
    currenMapping: Mapping;
};
const Zoning = (props: ZoningProps) => {
    const { collectionReference, supplierReference } = useParams<Params>();
    const dispatch = useDispatch();
    const checkList = ['M_1', 'M_2', 'M_3', 'M_4', 'M_5', 'M_6', 'M_7', 'M_8', 'M_9', 'M_10', 'M_11', 'M_12', 'M_13', 'M_14', 'M_15'];

    //#region state
    const [openAddZoning, setOpenAddZoning] = useState(false);
    const [openUpdateZoning, setOpenUpdateZoning] = useState(false);
    const [openUpdateSucess, setOpenUpdateSucess] = useState(false);
    const [openDeleteZoning, setOpenDeleteZoning] = useState(false);
    const [openApplicableIdModal, setOpenApplicableModal] = useState(false);
    const [nonConf, setNonConf] = useState<boolean>(false);
    const [colorMarked, setColorMarked] = useState<Colori[]>([]);
    const [colorCommunMarked, setColorCommunMarked] = useState<Colori[]>([]);
    const [defaultColoriId, setDefaultColoriId] = useState<number>(0);
    const [checked, setChecked] = useState<string[]>([]);
    const [finishFocus, setFinishFocus] = useState<number>();
    const [cm, setCm] = useState<Mapping>(initialMapping);
    const [currentZone, setCurrentZone] = useState<Zone>(initialZone);
    const [currentZoneColori, setCurrentZoneColori] = useState<{
        zoneUuid: string,
        appColoriLenght: number,
        defaultColoriId: number
    }>(
        {
            zoneUuid: '',
            appColoriLenght: 0,
            defaultColoriId: -1,
        });
    //#endregion
    //#region useSelector
    const getMappingRes = useSelector<RootState, MappingState>(state => state.getMapping);
    const createZoningRes = useSelector<RootState, ZoningState>((state) => state.createZoning);
    const updateZoningRes = useSelector<RootState, ZoningState2>((state) => state.updateZoning);
    const deleteZoningRes = useSelector<RootState, ZoningState>((state) => state.deleteZoning);
    const getColoriRes = useSelector<RootState, GetColorisState>(state => state.getColoris);
    const colorisFromSupplierAndCommon = useSelector<RootState, ColorisFromCommonAndSupplierIdState>((state) => state.fetchAllColorisFromSupplierAndCommonRes);
    const [coloriList, setColoriList] = useState<Colori[]>([]);
    //#endregion
    //#region useEffect
    useEffect(() => {
        currentZoneColori.defaultColoriId !== -1 && currentZoneColori.defaultColoriId !== 0 && dispatch(getColoris(currentZoneColori.defaultColoriId));
    }, [currentZoneColori]);
    useEffect(() => {
        props.currenMapping.id === 0 ? dispatch(resetGetMapping()) : dispatch(getMapping(props.currenMapping.id));
        if (props.currenMapping.zoningMetadata.zoneList.length > 0) {
            setCurrentZone(props.currenMapping.zoningMetadata.zoneList[0]);
        }
    }, [props.currenMapping]);
    useEffect(() => {
        setChecked([]);
        if (getMappingRes.payload.content && getMappingRes.payload.errors.length === 0) {
            setCm(getMappingRes.payload.content);
            const cz = getMappingRes.payload.content.zoningMetadata.zoneList.find((z) => z.uuid === currentZone.uuid);
            if (cz) {
                cz.nonConfigurableZone ? setNonConf(true) : setNonConf(false);
                setChecked(cz.availableIds);
                cz.defaultColoriId ? setDefaultColoriId(cz.defaultColoriId) : setDefaultColoriId(-1);
            }
        }
    }, [getMappingRes, currentZone, props.currenMapping]);
    useEffect(() => {
        if (currentZone) {
            supplierReference && dispatch(getColoriFromSupplierAndCommon(supplierReference));
            dispatch(UpdateZoningApp(currentZone));
            setCurrentZoneColori({
                zoneUuid: currentZone.uuid,
                appColoriLenght: currentZone.applicableColoriIds.length,
                defaultColoriId: currentZone.defaultColoriId,
            });
        }
        if (checked)
            dispatch(UpdateZoningIDs(checked));
    }, [currentZone, checked, props.currenMapping]);
    useEffect(() => {
        if (colorisFromSupplierAndCommon && colorisFromSupplierAndCommon.payload && colorisFromSupplierAndCommon.payload.content) {
            setColoriList(colorisFromSupplierAndCommon.payload.content);
        }
    }, [colorisFromSupplierAndCommon]);
    useEffect(() => {
        const selectedColoris = coloriList && coloriList.filter((cfs) => currentZone && currentZone.applicableColoriIds.includes(cfs.id));
        const selectedColorisFromCommun = selectedColoris.filter((c) => c.finish.supplierReference === 'COMMUN');
        const selectedColorisFromSupplier = selectedColoris.filter((c) => c.finish.supplierReference !== 'COMMUN');
        setColorMarked(selectedColorisFromSupplier);
        setColorCommunMarked(selectedColorisFromCommun);
    }, [coloriList]);
    useEffect(() => {
        if (createZoningRes.payload.content && createZoningRes.payload.errors.length === 0) {
            setChecked([]);
            collectionReference && dispatch(getAllGroups(collectionReference));
            dispatch(getMapping(props.currenMapping.id));
            const zoneList = createZoningRes.payload.content.zoningMetadata.zoneList;
            setCurrentZone(zoneList[zoneList.length - 1]);
            dispatch(resetCreateZoning());
            setOpenAddZoning(false);
        }
    }, [createZoningRes]);
    useEffect(() => {
        if (updateZoningRes.payload.content && updateZoningRes.payload.errors.length === 0) {
            // @ts-ignore
            updateZoningRes.payload.content.zoningMetadata.zoneList.forEach((z: Zone) => {
                z.uuid === currentZone.uuid && setCurrentZone(z);
            });
            setOpenUpdateZoning(false);
            dispatch(getMapping(props.currenMapping.id));
            setOpenApplicableModal(false);
            setOpenUpdateSucess(true);
            setTimeout(() => {
                setOpenUpdateSucess(false);
            }, 2000);
            dispatch(resetUpdateZoning());
        }
    }, [updateZoningRes]);
    useEffect(() => {
        if (deleteZoningRes.payload.content && deleteZoningRes.payload.errors.length === 0) {
            props.currenMapping.zoningMetadata.zoneList.length === 0 && setCurrentZone(initialZone);
            dispatch(getMapping(props.currenMapping.id));
            collectionReference && dispatch(getAllGroups(collectionReference));
            if (deleteZoningRes.payload.content.zoningMetadata.zoneList.length != 0) {
                setCurrentZone(deleteZoningRes.payload.content.zoningMetadata.zoneList[0]);
            }
            setOpenDeleteZoning(false);
            dispatch(resetDeleteZoning());

        }
    }, [deleteZoningRes]);
    useEffect(() => {
        cm.zoningMetadata.zoneList.length === 1 && setCurrentZone(cm.zoningMetadata.zoneList[0]);
    }, [cm]);
    //#endregion
    //#region method
    const handleCheck = (event: ChangeEvent<HTMLInputElement>, chekedList: string[]) => {
        let updatedList = [...chekedList];
        if (event.target.checked) {
            updatedList = [...chekedList, event.target.value];
        } else {
            updatedList.splice(chekedList.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };
    const dynamiqueInputCheck = (item: string, index: number) => {
        if (checked.length > 0) {
            let check = false;
            checked.map((el: string) => {
                if (el === item) {
                    check = true;
                }
                return check;
            });

            return <input
                checked={ check }
                key={ index }
                value={ item }
                type='checkbox'
                onChange={ (e) => handleCheck(e, checked) } />;
        } else {
            return <input
                checked={ false }
                key={ index }
                value={ item }
                type='checkbox'
                onChange={ (e) => handleCheck(e, checked) } />;
        }
    };
    const onSubmit = () => {
        dispatch(
            updateZoning({
                mappingId: props.currenMapping.id,
                zoneUuid: currentZone.uuid,
                zone: {
                    name: currentZone.name,
                    defaultColoriId: defaultColoriId,
                    uuid: currentZone.uuid,
                    nonConfigurableZone: nonConf,
                    availableIds: checked,
                    applicableColoriIds: [...colorCommunMarked, ...colorMarked].map(colori => colori.id),
                },
            }),
        );
        dispatch(UpdateZoningApp(undefined));
    };
    //endregion

    return (
        <div className='zoning-main'>
            <>
                { openUpdateSucess &&
                    <Toaster
                        validate={ true }
                        visibility={ true }
                        title='Sauvegarde de la zone'
                        message='La zone à bien été sauvegardé '
                        onClose={ () => setOpenUpdateSucess(false) } /> }
                { openApplicableIdModal && (
                    <ApplicableColoriModal
                        currentZone={ currentZone }
                        selectedZone={ currentZone }
                        onSubmit={ onSubmit }
                        setDefaultId={ setDefaultColoriId }
                        defaultId={ defaultColoriId }
                        setOpenModal={ setOpenApplicableModal }
                        colorMarked={ colorMarked }
                        setColorMarked={ setColorMarked }
                        finishFocus={ finishFocus }
                        setFinishFocus={ setFinishFocus }
                        colorCommunMarked={ colorCommunMarked }
                        setColorCommunMarked={ setColorCommunMarked }
                    />
                ) }
                { openAddZoning &&
                    <CreateZoning
                        mappingId={ props.currenMapping.id }
                        groupId={ props.currentGroup.id }
                        setOpenModal={ setOpenAddZoning } /> }
                { openDeleteZoning && (
                    <SimpleModal
                        icon={ whiteTrash }
                        title='Supprimer une zone ?'
                        info='La zone ainsi que toutes ces dépendances seront supprimés.'
                        closeOnclick={ () => setOpenDeleteZoning(false) }
                        actionOnclick={ () => {
                            dispatch(
                                deleteZoning({
                                    groupId: props.currentGroup.id,
                                    mappingId: props.currenMapping.id,
                                    zoneUuid: currentZone.uuid,
                                }),
                            );
                            setOpenDeleteZoning(false);
                        } }
                        deleteOrValide={ true }
                        closeLabel='Annuler'
                        actionLabel='Supprimer'
                    />
                ) }
                {
                    openUpdateZoning &&
                    <UpdateZoning
                        setOpenModal={ setOpenUpdateZoning }
                        groupId={ props.currentGroup.id }
                        mappingId={ props.currenMapping.id }
                        zone={ currentZone } />
                }
            </>
            <div className='zoning-head'>
                ZONES
                { props.currenMapping.id !== 0 && (
                    <div className='zoning-create'
                         onClick={ () => {
                             setOpenAddZoning(true);
                         } }
                    >
                        <img alt='plus' src={ plus } />
                        <span>Zone</span>
                    </div>
                ) }
            </div>
            <div className='zone-bloc'>
                {
                    props.currenMapping.id !== 0 && cm.zoningMetadata.zoneList.map((zone: Zone) => {
                        return (
                            <div
                                key={ zone.uuid }
                                className={ currentZone.uuid === zone.uuid ? 'zone focus' : 'zone' }
                                onClick={ () => {
                                    setCurrentZone(zone);
                                } }>
                                <span>{ zone.name.length < 20 ? zone.name : `${ zone.name.substring(0, 19) }...` }</span>
                                <div className='right'>
                                    <div>
                                        <img
                                            onClick={ () => {
                                                setCurrentZone(zone);
                                                setOpenUpdateZoning(true);
                                            } }
                                            alt='fleche ouvrir'
                                            src={ update }
                                        />
                                        <img
                                            onClick={ () => {
                                                setCurrentZone(zone);
                                                setOpenDeleteZoning(true);
                                            } }
                                            alt='dossier'
                                            src={ trash }
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    }) }
            </div>
            { cm.zoningMetadata.zoneList.length !== 0 && props.currenMapping.id !== 0 &&
                <div className='inspector'>
                    <div className='inspector-top'>
                        <div>
                            <span>{ `${ currentZoneColori.zoneUuid === currentZone.uuid && currentZoneColori.appColoriLenght }` } </span>COLORIS<br />
                            DEFAUT: <span> { `${ currentZoneColori.defaultColoriId !== -1 ? getColoriRes.payload.content?.name.toUpperCase() : '' }`
                        }
                                </span>
                        </div>
                        <div onClick={ () => {
                            setOpenApplicableModal(true);
                        } } className='inspector-asso'>
                            Associer
                        </div>
                    </div>
                    <div className='inspector-checklist'>
                        <span> ID DISPONIBLES</span>
                        <div className='checklist'>
                            { checkList.map((item: string, index: number) => {
                                return (
                                    <div className='item-box' key={ index }>
                                        { dynamiqueInputCheck(item, index) }
                                        <span>{ item }</span>
                                    </div>
                                );
                            }) }
                        </div>
                    </div>
                    <div className='inspector-conf'>
                        <span>ZONE NON CONFIGURABLE</span>
                        <div className='inspector-toogle'>
                            <label className='toggle-switch'>
                                <input type='checkbox'
                                       checked={ nonConf }
                                       onChange={ () => setNonConf(!nonConf) } />
                                <span className='switch' />
                            </label>
                        </div>
                    </div>
                    <div className='inspector-btn-box'>
                        <div onClick={ onSubmit } className='inspector-button'>
                            Sauvegarder
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
export default Zoning;
