import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../services/root-reducer";
import {ThumbnailState} from "../../../services/reducers/material-editor/ThumbnailReducer";
import {EditorState} from "../../../services/reducers/material-editor/EditorReducer";
import {EntityState, RemoveEntityWithID} from "../../../services/reducers/material-editor/EntitiesReducer";
import {Guid} from "guid-typescript";
import {ColorState} from "../../../services/reducers/material-editor/ColorReducer";

const CurrentSupport = () =>
{
    const {currentType} = useSelector<RootState, ThumbnailState>((state) => state.ThumbnailReducer);
    const {game} = useSelector<RootState, EditorState>((state) => state.EditorReducer);
    const {entityIds} = useSelector<RootState, EntityState>((state) => state.EntitiesReducer);
    const {material} = useSelector<RootState, ColorState>((state) => state.ColorReducer);

    const dispatch = useDispatch();
    
    useEffect(() =>
    {
        if (currentType != null)
        {
            let GUID = entityIds.find((obj) =>
            {
                return obj.thumbnailRef === currentType;
            })?.entityId;
            let allEntites: Guid[] = [];
            entityIds.forEach((obj) =>
            {
                allEntites.push(obj.entityId);
            });
            try
            {
                if(currentType !== "Box"){
                if (GUID) game?.TargetMeshService?.SetCurrentMeshType(GUID, allEntites, (p_id)=>{
                    dispatch(RemoveEntityWithID(p_id));
                });
                }else {
                    if (GUID) game?.TargetMeshService?.SetCurrentMeshType(GUID, allEntites, (p_id) => {
                        dispatch(RemoveEntityWithID(p_id));
                    }, true, 1.8);
                }
            }
            catch (e)
            {
                console.warn(e);
            }
        }
    }, [currentType, entityIds]);
    
    useEffect(()=>{
        if (entityIds != null)
        {
            if(material && entityIds && entityIds.length > 0) game?.TargetMeshService.SetMaterialOnEntities([entityIds[entityIds.length-1].entityId], material);
        }
    }, [entityIds]);
    
    return <></>;
}

export default CurrentSupport;