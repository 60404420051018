import apiClient from '../../api-client';
import {
    CREATE_RULE,
    DELETE_RULE,
    EXECUTE_RULES,
    GET_RULES,
    SWITCH_CONF_RULE_TYPE
} from '../../endpoints/rules/rules.endpoint';
import { Rule, RuleType } from '../../../domain/domain';

const RulesService = {
    getRules: async (productRef: string) => apiClient(GET_RULES(productRef), undefined, undefined, 'GET'),
    switchConfRuleType: async (productRef: string, newRuleType: RuleType) => apiClient(SWITCH_CONF_RULE_TYPE(productRef, newRuleType), undefined, undefined, 'POST'),
    createRule: async (productRef: string, rule: Rule) => apiClient(CREATE_RULE(productRef), rule, undefined, 'POST'),
    deleteRule: async (productRef: string, ruleRef: string) => apiClient(DELETE_RULE(productRef, ruleRef), undefined, undefined, 'DELETE'),
    executeRules: async (productReference: string, configuration: string[]) => apiClient(EXECUTE_RULES(productReference), configuration, undefined, 'POST'),
};
export default RulesService;
