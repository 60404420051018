import { useDispatch, useSelector } from 'react-redux';

import { ProductStudioState } from '../../../../../../../../../application-3d/application3D-common/Redux/Reducers/product-studio/ProductStudioReducer';
import { RootState } from '../../../../../../../../../services/root-reducer';
import { useEffect, useState } from 'react';
import { CheckedCompositionModule, CompositionModuleResponse, CompositionWrapper, CompoundedModule, MdfApiResponse } from '../../../../../../../../../domain/domain';
import ConfiguredProductService from '../../../../../../../../../api/services/products/configured-product/configured-product.service';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../../../../../domain/Params/params-interface';
import ObjectDatas from '../../../../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas';
import { setEditedDatas } from '../../../../../../../../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomStudioReducer';
import InfoComponent
    from '../../../../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent';
import { generateUUID } from 'three/src/math/MathUtils';
import { addModule } from '../../../../../../../../../utils/add-module-to-composition';
import del from '../../../../../../../../../asset/trash-popup.png';
import { ObjectParser } from '../../../../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/ObjectParser';

type BuildCompositionPanelProps = {
    currentPreset: CompoundedModule;
    buildCompositionModules: CompositionModuleResponse[];
    setPossibleCompositionModules: (possibleCompositionModules: CompositionModuleResponse[]) => void;
    possibleCompositionModules: CompositionModuleResponse[];
    setCompositionWrapper: (compositionWrapper: CompositionWrapper | undefined) => void;
    setSelectedModule: (selectedModule: CheckedCompositionModule | undefined) => void;
    selectedModule: CheckedCompositionModule | undefined;
    setData: (ObjectDatas: ObjectDatas | undefined) => void;
    ObjectDatas: ObjectDatas | undefined;
    CompositionWrapper: CompositionWrapper | undefined;
    RemapData: (p_inputData: CompositionWrapper) => ObjectDatas;
    setBuildCompositionModules: (buildCompositionModules: CompositionModuleResponse[]) => void;
    setStep: (step: number) => void
};
const BuildCompositionPanel = (props: BuildCompositionPanelProps) => {
    const { productReference } = useParams<Params>();
    const dispatch = useDispatch();

    const productStudioStates = useSelector<RootState, ProductStudioState>((state) => state.productStudio);
    const { selectedConnector, selectedEntity } = useSelector<RootState, ProductStudioState>((state) => state.productStudio);

    const [ncmList, setNcmList] = useState<CompositionModuleResponse[]>([...props.buildCompositionModules]);

    useEffect(() => {
        if (productStudioStates.selectedEntity && productReference) {
            ConfiguredProductService.applyRulesOnComposition(productReference, props.currentPreset.id, {
                compositionWrapperOptional: undefined,
                compositionModuleResponseList: props.buildCompositionModules,
            }).then((res: MdfApiResponse<CompositionWrapper>) => {
                props.setCompositionWrapper(res.content);
                let selectedEntityInfos = productStudioStates.selectedEntity?.HasComponentOfType(InfoComponent) ? productStudioStates.selectedEntity?.GetComponentOfType(InfoComponent) : undefined;
                const findModule = res.content.checkedCompositionModules
                    // @ts-ignore
                    .find((m: CheckedCompositionModule) => m.module.presetTree.metadata.refOfInstance === selectedEntityInfos?.Info.Name);
                findModule && props.setSelectedModule(findModule);
            });
        }
    }, [productStudioStates.selectedEntity, productStudioStates.selectedConnector]);

    useEffect(() => {
        if (productStudioStates.selectedConnector && props.selectedModule) {
            productStudioStates.selectedConnector === 'RIGHT' && props.setPossibleCompositionModules(props.selectedModule.possibleModulesRight);
            productStudioStates.selectedConnector === 'LEFT' && props.setPossibleCompositionModules(props.selectedModule.possibleModulesLeft);
        } else {
            props.setPossibleCompositionModules([]);
        }
    }, [props.selectedModule, productStudioStates.selectedConnector]);

    useEffect(() => {
        if (!selectedEntity) {
            props.setSelectedModule(undefined);
            return;
        }

        const found = props.CompositionWrapper?.checkedCompositionModules.find(el => el.module.moduleRefOfInstance === selectedEntity?.Name.toString());
        props.setSelectedModule(found);

    }, [selectedEntity]);

    useEffect(() => {
        applyRules();
    }, [ncmList]);

    const addModuleToComposition = (cm: CompositionModuleResponse) => {
        if (!props.selectedModule) return;
        if (!selectedConnector) return;
        cm.moduleRefOfInstance = generateUUID();
        const clickedModuleIndex = ncmList.findIndex((ncm) => ncm.moduleRefOfInstance === selectedEntity?.Name);
        const newNcmList = addModule(ncmList, cm, selectedConnector, clickedModuleIndex);
        setNcmList(newNcmList);
    };
    const update3dComposition = (wrapper: CompositionWrapper) => {
        props.setData(props.RemapData(wrapper));
        props.setBuildCompositionModules(ncmList);
        let datas: ObjectDatas = Object.assign(new ObjectDatas(), props.ObjectDatas);
        dispatch(setEditedDatas(datas));
    };
    const applyRules = () => {
        productReference &&
        ConfiguredProductService.applyRulesOnComposition(productReference, props.currentPreset.id, {
            compositionWrapperOptional: undefined,
            compositionModuleResponseList: ncmList,
        })
            .then((wrapper: MdfApiResponse<CompositionWrapper>) => {
                update3dComposition(wrapper.content);
            })
            .catch((e) => console.log(e));
    };
    const removeModuleFromComposition = (cm: CheckedCompositionModule | undefined) => {
        if (props.CompositionWrapper) {
            if (props.CompositionWrapper.checkedCompositionModules.length > 1) {
                const clickedModuleIndex = ncmList.findIndex((n) => n.moduleRefOfInstance === selectedEntity?.Name);
                const clickedModule = ncmList[clickedModuleIndex];
                const newL = ncmList.filter((c) => c.moduleRefOfInstance !== clickedModule.moduleRefOfInstance);
                setNcmList(newL);
                productReference && ConfiguredProductService.applyRulesOnComposition(productReference, props.currentPreset.id, {
                    compositionWrapperOptional: undefined,
                    compositionModuleResponseList: newL,
                }).then((wrapper: MdfApiResponse<CompositionWrapper>) => {
                    props.setCompositionWrapper(wrapper.content);
                    props.setData(ObjectParser.RemapData(wrapper.content));
                });
            } else {
                //TODO => cler la scene et warpper
                /*
                setNcmList([]);
                props.setData(undefined);
                props.setCompositionWrapper(undefined);
                props.setStep(1);*/
            }
        }
    };


    return (
        <div className='composable-pb-panel'>
            <div className='composable-pb-panel-title'>Créer la configuration</div>
            <div className='composable-pb-panel-modules'>
                {props.possibleCompositionModules.map((cm: CompositionModuleResponse) => {
                    return (
                        <div
                            className='module'
                            key={cm.product.id}
                            onClick={() => {
                                addModuleToComposition(cm);
                            }}
                        >
                            <img alt={`vignette du module ${cm.product.name}`} src={cm.product.thumbnailUrl} />
                            <span> {cm.product.name}</span>
                        </div>
                    );
                })}
            </div>
            <div className='del'>
                {props.selectedModule && props.selectedModule.deletable &&
                    <button onClick={() => removeModuleFromComposition(props.selectedModule)}>Supprimer <img src={del} /></button>}
            </div>
        </div>
    );
};
export default BuildCompositionPanel;
