import './win-table.scss';
import { MdfApiResponse, TarifWin, TarifWinConfiguration, TarifWinField, TarifWinProduct, TarifWinProductDto, WinFilter } from '../../../../../../domain/domain';
import { useEffect, useState } from 'react';
import winService from '../../../../../../api/services/win/win.service';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../../domain/Params/params-interface';
import send from '../../../../../../asset/Envoyer.svg';
import trash from '../../../../../../asset/poubelle-red.svg';
import SimpleModal from '../../../../../../uicomponents/modal/simple-modal/simple-modal';

type WinTableProps = {
    TarifWinList: TarifWin[];
    setSelectedWinKey: (selectedWinKey: string) => void;
    selectedWinKey: string;
    TarifWinProductDtoList: TarifWinProductDto[];
    setTarifWinProductDtoList: (TarifWinProductDtoList: TarifWinProductDto[]) => void;
    tarifWinConfigurationList: TarifWinConfiguration[];
    setTarifWinConfigurationList: (list: TarifWinConfiguration[]) => void;
};
const WinTable = (props: WinTableProps) => {
    const { collectionReference } = useParams<Params>();
    const winTableHead = [
        'Produit',
        'Nom',
        'Fournisseur',
        'Catégorie',
        'Code modèle collection',
        'Description article',
        'Descriptif modèle',
        'Famille',
        'Intitulé modèle',
        'Hauteur',
        'Longueur',
        'Poids',
        'pvHorsEcoCentimes',
        'ecoTTCCentimes',
    ];
    const tarifWinField: TarifWinField[] = ['NOM_FOURNISSEUR', 'CATEGORIE', 'CODE_MODELE_COLLECTION', 'FAMILLE'];

    const [dissociateModal, setDissociateModal] = useState<TarifWin | undefined>(undefined);
    const [option, setOptions] = useState(false);
    const [selectedTarifField, setSelectedTarifField] = useState<TarifWinField | undefined>(undefined);
    const [filterValue, setFilterValue] = useState<string>('');

    const [winFilters, setWinFilters] = useState<WinFilter[]>([]);
    const [winDelete, setWinDelete] = useState<number>(0);

    const [selectedWinFilters, setSelectedWinFilters] = useState<WinFilter[]>([]);
    const [dynamicTarifWinList, setDynamicTarifWinList] = useState<TarifWin[]>([]);

    useEffect(() => {
        findFilters((w) => {
            setWinFilters(w.content);
        });
        props.TarifWinList && setDynamicTarifWinList(props.TarifWinList);
    }, [props.TarifWinList]);

    const returnIfAssociated = (win: TarifWin): string => {
        const find = props.TarifWinProductDtoList.find((p) => p.winKey === win.winKey);
        if (!find) {
            const configuration = props.tarifWinConfigurationList.find((p) => p.winKey === win.winKey);
            return configuration ? 'Configuration' : '';
        }
        return find ? find.product.name : '';
    };
    const isIn = (win: TarifWin): boolean => {
        const find = props.TarifWinProductDtoList.find((p) => p.winKey === win.winKey);
        const find2 = props.tarifWinConfigurationList.find((p) => p.winKey === win.winKey);
        return !!find || !!find2;
    };
    const disociateProduct = () => {
        if (dissociateModal) {
            const find = props.TarifWinProductDtoList.find((p) => p.winKey === dissociateModal.winKey);
            find &&
                winService
                    .winDissociateProduct({
                        productReference: find.product.reference,
                        winKey: dissociateModal.winKey,
                    })
                    .then((res: MdfApiResponse<TarifWinProduct>) => {
                        if (res.errors.length === 0 && res.content) {
                            collectionReference &&
                                winService.winProductsAssociated(collectionReference).then((r: MdfApiResponse<TarifWinProductDto[]>) => {
                                    if (r.errors.length === 0 && r.content) {
                                        props.setTarifWinProductDtoList(r.content);
                                    }
                                });
                            props.setSelectedWinKey('');
                            setDissociateModal(undefined);
                        }
                    });
            const findConf = props.tarifWinConfigurationList.find((p) => p.winKey === dissociateModal.winKey);
            findConf &&
                winService.winDissociateConfiguration(findConf).then((res) => {
                    winService.winConfigurationsAssociated().then((r) => {
                        props.setTarifWinConfigurationList(r.content);
                        props.setSelectedWinKey('');
                        setDissociateModal(undefined);
                    });
                });
        }
    };
    const createWinFilter = () => {
        if (!selectedTarifField || filterValue.length === 0) {
            alert('La valeur et la catégorie sont obligatoire');
        } else {
            collectionReference &&
                winService.createWinFilter(collectionReference, selectedTarifField, filterValue).then((winfilter: MdfApiResponse<WinFilter>) => {
                    if (winfilter.content && winfilter.errors.length === 0) {
                        findFilters((w) => {
                            setFilterValue('');
                            setSelectedTarifField(undefined);
                            setWinFilters(w.content);
                        });
                    }
                });
        }
    };
    const findFilters = (callback: (winFilterList: MdfApiResponse<WinFilter[]>) => void) => {
        collectionReference &&
            winService
                .findWinFilter(collectionReference)
                .then((winFilterList: MdfApiResponse<WinFilter[]>) => {
                    if (winFilterList.errors.length === 0 && winFilterList.content) {
                        callback(winFilterList);
                    }
                })
                .catch((e) => console.log({ errors: e }));
    };
    const deleteFilter = () => {
        winService.deleteWinFilter(winDelete).then((r: MdfApiResponse<boolean>) => {
            if (r.errors.length === 0 && r.content) {
                findFilters((w) => {
                    setWinFilters(w.content);
                    setWinDelete(0);
                    setSelectedWinFilters((prevState) => prevState.filter((el) => el.id !== winDelete));
                });
            }
        });
    };
    const addAndRemoveFromList = (winFilter: WinFilter) => {
        const findInlist = selectedWinFilters.find((w) => w.id === winFilter.id);
        if (findInlist) {
            const filtered = selectedWinFilters.filter((wi) => {
                return wi.id !== winFilter.id;
            });
            setSelectedWinFilters(filtered);
        } else {
            setSelectedWinFilters((prevState) => [...prevState, winFilter]);
        }
    };
    const filterClassname = (winFilterId: number): string => {
        const findInlist = selectedWinFilters.find((w) => w.id === winFilterId);
        return findInlist ? 'filter filterIn' : 'filter';
    };
    const returnFilteredList = (): TarifWin[] => {
        if (selectedWinFilters.length > 0) {
            let filteredList: TarifWin[] = [...dynamicTarifWinList];
            selectedWinFilters.forEach((wf) => {
                const columnName = wf.columnName as keyof TarifWin;
                filteredList = filteredList.filter((tarifWin) => {
                    const propertyValue = tarifWin[columnName]?.toString() || '';
                    return propertyValue.toLowerCase().includes(wf.value.toLowerCase());
                });
            });
            return filteredList;
        }
        return dynamicTarifWinList;
    };

    return (
        <div className="ov">
            {dissociateModal && (
                <div className="dissModal">
                    <div className="dissModalPaper">
                        <div className="dmp"> Dissociation de la clé win</div>
                        <div className="btn-box">
                            <button className="btn-canceled " onClick={() => setDissociateModal(undefined)}>
                                {' '}
                                Annuler
                            </button>
                            <button onClick={() => disociateProduct()} className="btn-submit">
                                Dissocier
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {winDelete > 0 && (
                <SimpleModal
                    icon={trash}
                    title="Supprimer un filtre"
                    info="Cette action supprimera le filtre"
                    closeOnclick={() => setWinDelete(0)}
                    actionOnclick={deleteFilter}
                    deleteOrValide={true}
                    closeLabel="Annuler"
                    actionLabel="Supprimer"
                />
            )}
            <div className="win-filter">
                <div className="b">
                    <div className="label">Label</div>
                    <input value={filterValue} onChange={(e) => setFilterValue(e.target.value)} type="text" />
                </div>
                <div className="b">
                    <div className="label">Colonne</div>
                    <div onClick={() => setOptions(!option)} className="win-select">
                        {selectedTarifField ? selectedTarifField.toLowerCase() : ''}
                        {option && (
                            <div className="win-option">
                                {tarifWinField.map((t: TarifWinField, i: number) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                setSelectedTarifField(t);
                                                setOptions(false);
                                            }}
                                            key={i}
                                        >
                                            {t}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <img onClick={createWinFilter} alt="envoyer" src={send} />
                <div className="filter-list">
                    {winFilters.map((w: WinFilter) => {
                        return (
                            <div className={filterClassname(w.id)} key={w.id}>
                                <span onClick={() => addAndRemoveFromList(w)}>{w.value}</span>
                                <img onClick={() => setWinDelete(w.id)} alt="supprimer cd filtre" src={trash} />
                            </div>
                        );
                    })}
                </div>
            </div>
            <table className="win-table-main">
                <thead>
                    {winTableHead.map((label: string, i: number) => {
                        return (
                            <tr key={i}>
                                <th className={i === 4 || i === 5 ? 'l plus' : 'l'}>{label}</th>
                            </tr>
                        );
                    })}
                </thead>
                <tbody>
                    {returnFilteredList().map((tw: TarifWin, i: number) => {
                        return (
                            <tr
                                onClick={() => {
                                    isIn(tw) ? setDissociateModal(tw) : props.setSelectedWinKey(tw.winKey);
                                }}
                                key={tw.id}
                            >
                                <td className={isIn(tw) ? 'in' : ''}>{returnIfAssociated(tw)}</td>
                                <td className={i === 4 || i === 5 ? 'l plus' : 'l'}>{tw.designationArticle}</td>
                                <td>{tw.nomFournisseur}</td>
                                <td>{tw.categorie}</td>
                                <td>{tw.codeModeleCollection}</td>
                                <td>{tw.descriptifArticle}</td>
                                <td>{tw.descriptifModele}</td>
                                <td>{tw.famille.toUpperCase()}</td>
                                <td>{tw.intituleModeleCollection}</td>
                                <td>{tw.hauteurMm / 1000}</td>
                                <td>{tw.longueurMm / 1000}</td>
                                <td>{tw.poidsG / 1000}</td>
                                <td>{tw.pvHorsEcoCentimes / 100}</td>
                                <td>{tw.ecoTTCCentimes / 100}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
export default WinTable;
