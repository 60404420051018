import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Textfield from '../../uicomponents/textfield/textfield';
import UseForm from '../../uicomponents/form/form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../uicomponents/button/button';
import { AuthState, logIn } from '../../services/reducers/auth-reducer/auth-reducer';
import { RootState } from '../../services/root-reducer';
import Paper from '../../uicomponents/paper/paper';
import JustifyBlock from '../../uicomponents/justify-block/justify-block';
import logo from '../../asset/top.svg';

import Title from '../../uicomponents/title/title';
import UiError from '../../uicomponents/errors/ui-error';

import version from '../../domain/mdf-version.json';
import healthCheckService from '../../api/services/health-check/health-check.service';

type ApiStatus = {
    api3dUp: boolean;
    apiBpUp: boolean;
    appAvailable: boolean;
};

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
    const [isEmailError, setIsEmailError] = useState<boolean>(false);
    const [isPasswordError, setIsPasswordError] = useState<boolean>(false);
    const [serverError, setServerError] = useState('');
    const [appStatus, setAppStatus] = useState<ApiStatus>({
        api3dUp: true,
        apiBpUp: true,
        appAvailable: true,
    });

    const { payload } = useSelector<RootState, AuthState>((state) => state.user);

    const dispatch = useDispatch();
    const history = useHistory();

    const handleEmailChange = (e: string) => {
        setEmail(e);
        setIsEmailError(false);
        setEmailErrorMessage('');
    };
    const handlePasswordChange = (e: string) => {
        setPassword(e);
        setPasswordErrorMessage('');
        setIsPasswordError(false);
    };

    const handleSubmit = () => {
        if (email.length === 0) {
            setEmailErrorMessage('Le mail doit etre renseigné');
            setIsEmailError(true);
        }
        if (password.length === 0) {
            setPasswordErrorMessage('Le mot de passe doit etre renseigné');
            setIsPasswordError(true);
        } else {
            dispatch(logIn({ email: email.toLowerCase(), password }));
        }
    };

    useEffect(() => {
        const { content, errors } = payload;
        if (content === null && errors.length === 0) {
        } else if (content === null && errors.length > 0) {
            const code = payload.errors[0].code;
            switch (code) {
                case 'A0001':
                    setIsEmailError(true);
                    setIsPasswordError(true);
                    setServerError('Une Erreur inconnue est survenue');
                    setPasswordErrorMessage('Identifiants incorrects veuillez les verifier');
                    break;
                case 'A0003':
                    setServerError("Vous n'avez pas les droits requis pour accéder à ce site");
                    break;
                default:
                    setServerError(payload.errors[0].message);
            }
        } else {
            content && localStorage.setItem('mdf-jwt', content.jwt);
            localStorage.setItem('user', JSON.stringify(content?.user));

            history.push('/home/news');
        }
    }, [payload]);

    const checkAppAvailability = async () => {
        const status: ApiStatus = {
            api3dUp: true,
            apiBpUp: true,
            appAvailable: true,
        };
        try {
            await healthCheckService.check3dHealth();
            status.api3dUp = true;
        } catch (e) {
            status.api3dUp = false;
            status.appAvailable = false;
        }

        try {
            await healthCheckService.checkBpHealth();
            status.apiBpUp = true;
        } catch (e) {
            status.apiBpUp = false;
            status.appAvailable = false;
        }
        if (status.api3dUp && status.apiBpUp) {
            status.appAvailable = true;
        }
        setAppStatus(status);
    };

    useEffect(() => {
        checkAppAvailability();
    }, []);

    return (
        <JustifyBlock>
            <div
                style={{
                    height: 150,
                    width: 600,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img style={{ height: 72, marginBottom: 15 }} src={logo} alt="logo mdf" />
                <UiError errorMessage={serverError} />
            </div>
            <div style={{ width: 640 }}>
                <Paper height={500}>
                    <Title title="Connexion" />
                    <div className="formContainer">
                        <UseForm>
                            <Textfield
                                width={500}
                                isError={isEmailError}
                                errorMessage={emailErrorMessage}
                                label="ADRESSE EMAIL PROFESSIONNELLE"
                                validation="email"
                                type="email"
                                name="email"
                                value={email}
                                placeholder="Votre Email"
                                onChange={handleEmailChange}
                            />

                            <Textfield
                                isError={isPasswordError}
                                errorMessage={passwordErrorMessage}
                                label="MOT DE PASSE"
                                validation="required"
                                type="password"
                                secure
                                name="password"
                                value={password}
                                placeholder="Votre mot de passe"
                                onChange={handlePasswordChange}
                            />

                            <Button
                                label="Connexion"
                                size="max"
                                primary
                                onClick={() => {
                                    handleSubmit();
                                }}
                            />
                            <div style={{ marginTop: 22, borderTop: 'thin solid #E3E7ED', padding: 28 }}>
                                <a style={{ color: '#89A1DF' }} href="/first-user">
                                    Mot de passe oublié
                                </a>
                            </div>
                            <div className="version">{version.version}</div>
                            <div className="version">{version.buildTime}</div>
                            <div>
                                <div className="version">3D API is {appStatus.api3dUp ? 'up' : 'down'}</div>
                                <div className="version">BP API is {appStatus.apiBpUp ? 'up' : 'down'}</div>
                            </div>
                        </UseForm>
                    </div>
                </Paper>
            </div>
        </JustifyBlock>
    );
};
export default Login;
