import close from '../../../../asset/CloseWindow.svg';
import { formErrors } from '../../../../utils/form-errors';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createGroup } from '../../../../services/reducers/mapping-zoning/group-mapping.reducer';
import '../create-group-mapping.scss';
import { returnadvice } from '../mapping/create-mapping';

type CreateGroupeProps = {
    setOpenModal: (openModal: boolean) => void;
    collectionReference: string;
    groupId?: number;
};

type AddGroupInput = {
    name: string;
};

const CreateGroupMapping = (props: CreateGroupeProps) => {
    const dispatch = useDispatch();

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<AddGroupInput>({});

    const onSubmit: SubmitHandler<AddGroupInput> = (data) => {
        props.collectionReference &&
        dispatch(
            createGroup({
                collectionReference: props.collectionReference,
                data: { name: data.name },
            }),
        );
    };

    const keyDownHandler = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    };

    return (
        <div className='create-group-main'>
            <div className='create-group-paper'>
                <div className='create-group-top'>
                    <img
                        onClick={() => {
                            props.setOpenModal(false);
                        }}
                        alt='fermer la modal '
                        src={close}
                    />
                    <div className='create-group-title'>Créer un groupe de mapping</div>
                </div>
                <div className='create-group-body'>
                    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={keyDownHandler}>
                        <div className='input-container'>
                            <label htmlFor='name'>Nom</label>
                            <input
                                placeholder='Nom du groupe'
                                {...register('name', {
                                    required: true,
                                })}
                            />
                            <> {returnadvice(watch('name'), 24)}</>
                            <div className='err-box'>{errors && formErrors(errors.name)}</div>
                        </div>
                        <div className='btn-box'>
                            <button
                                onClick={() => {
                                    props.setOpenModal(false);
                                }}
                                className='btn-close'
                            >
                                Annuler
                            </button>
                            <button className='btn-action'>Créer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default CreateGroupMapping;
