import { CompositionModule3DInfo, CompositionModuleResponse, MdfApiResponse } from '../../../domain/domain';
import apiClient from '../../api-client';
import {
    ADD_MODULES_TO_COMPOSITION,
    GET_COMPOSITION_MODULES,
    REMOVE_MODULE_FROM_COMPOSITION,
    SAVE_METADATA_TO_COMPOSITION_MODULE,
} from '../../endpoints/composition/composition';


const compositionModuleService = {
    getCompositionModuleList: async (compositionReference: string): Promise<MdfApiResponse<CompositionModuleResponse[]>> => apiClient(GET_COMPOSITION_MODULES(compositionReference), undefined, undefined, 'GET'),
    addModulesToComposition: async (compositionReference: string, moduleReferences: string[]): Promise<MdfApiResponse<CompositionModuleResponse[]>> => apiClient(ADD_MODULES_TO_COMPOSITION(compositionReference), moduleReferences, undefined, 'POST'),
    removeModuleFromComposition: async (compositionReference: string, moduleReference: string) => apiClient(REMOVE_MODULE_FROM_COMPOSITION(compositionReference, moduleReference), undefined, undefined, 'DELETE'),
    save3dInfoToCompositionModule: async (compositionModule3dInfo: CompositionModule3DInfo) => apiClient(SAVE_METADATA_TO_COMPOSITION_MODULE, compositionModule3dInfo, undefined, 'POST'),
};
export default compositionModuleService;
