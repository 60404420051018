import apiClient from '../../api-client';
import {
    CHECK_EXISTS,
    CREATE_MAPPINGS,
    DELETE_MAPPING,
    GET_ALL_MAPPINGS,
    GET_MAPPING,
    UPDATE_MAPPING,
} from '../../endpoints/mapping-zoning/mapping.endpoint';
import { ZoningMetadata } from '../../../domain/domain';

const mappingService = {
    createMapping: async (groupId: number, data: { name: string, zoningMetadata: ZoningMetadata }) => apiClient(CREATE_MAPPINGS(groupId), data, undefined, 'POST'),
    getAllMappings: async () => apiClient(GET_ALL_MAPPINGS(), undefined, undefined, 'GET'),
    getMapping: async (mappingId: number) => apiClient(GET_MAPPING(mappingId), undefined, undefined, 'GET'),
    updateMapping: async (groupId: number, mappingId: number, data: { name: string, zoningMetadata: ZoningMetadata }) => apiClient(UPDATE_MAPPING(groupId, mappingId), data, undefined, 'PUT'),
    deleteMapping: async (groupId: number, mappingId: number) => apiClient(DELETE_MAPPING(groupId, mappingId), undefined, undefined, 'DELETE'),
    checkIfExists: async (mappingId: number) => apiClient(CHECK_EXISTS(mappingId), undefined, undefined, 'GET'),
};

export default mappingService;
