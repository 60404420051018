import { useState } from 'react';
import Layout from '../../../uicomponents/layout/layout';
import MainPaper from '../../../uicomponents/paper/main-paper';
import ModeleTabs from './modele-tabs';
import Products from './products/products-list/products';
import Mappings from './mappings-zoning/mappings';
import { useLocation, useParams } from 'react-router-dom';
import { Params } from '../../../domain/Params/params-interface';
import CreateProducts from '../../../components/modals/product/create-products';
import SimpleModal from '../../../uicomponents/modal/simple-modal/simple-modal';
import Trash from '../../../asset/poubelle.svg';
import { useDispatch } from 'react-redux';
import { deleteProduct } from '../../../services/reducers/product/product.reducers';
import Composition from './composition/composition/composition';
import { ConstructionRule } from '../../../domain/domain';
import AssociationWin from './composition/win/association-win';


const Modele = () => {
    const { collectionReference } = useParams<Params>();
    const location = useLocation();
    const dispatch = useDispatch();

    const [, setFocus] = useState(0);
    const [productReference, setProductReference] = useState<string | undefined>(undefined);
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [ruleId, setRuleId] = useState(0);
    const [currentRule, setCurrentRule] = useState<ConstructionRule>({
        id: 0,
        version: 0,
        type: 'MANDATORY',
        name: '',
        tagList: [],
        collectionReference: '',
        updatedAt: new Date(),
        createdAt: new Date(),
    });

    const returnFocus = () => {
        if (location.pathname.includes('products')) {
            return 0;
        } else if (location.pathname.includes('composition')) {
            return 2;
        } else if (location.pathname.includes('win')) {
            return 3;
        } else {
            return 1;
        }
    };

    return (
        <>
            {openModal && collectionReference &&
                <CreateProducts collectionReference={collectionReference} setOpenModal={setOpenModal} />}
            {openDeleteModal && productReference && (
                <SimpleModal
                    icon={Trash}
                    title='Supprimer un produit'
                    info='Cette action supprimera le produit'
                    closeOnclick={() => setOpenDeleteModal(false)}
                    actionOnclick={() => dispatch(deleteProduct(productReference))}
                    deleteOrValide={true}
                    closeLabel='Annuler'
                    actionLabel='Supprimer'
                />
            )}
            <Layout title='' pathUrl='collection' collectionName='collection'>
                <ModeleTabs
                    setOpenModal={setOpenModal}
                    focus={returnFocus()}
                    setFocus={setFocus}
                    setRuleId={setRuleId}
                    ruleId={ruleId}
                />
                <MainPaper className='test'>
                    {
                        location.pathname.includes('products') &&
                        <Products
                            setProductReference={setProductReference}
                            setOpenDeleteProductModal={setOpenDeleteModal}
                            setOpenModal={setOpenModal}
                            openModal={openModal} />
                    }
                    {location.pathname.includes('mapping') && <Mappings />}
                    {location.pathname.includes('composition') &&
                        <Composition
                            setRuleId={setRuleId}
                            ruleId={ruleId}
                            setCurrentRule={setCurrentRule}
                            currentRule={currentRule} />}
                    {location.pathname.includes('win') && <AssociationWin />}
                </MainPaper>
            </Layout>
        </>
    );
};
export default Modele;
