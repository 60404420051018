import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import trash from '../../asset/poubelle.svg';
import close from '../../asset/CloseWindow.svg';
import './file-input.scss';


type FileInputProps = {
    file: File[] | undefined;
    setFile: (file: File[] | undefined) => void;
    err: string;
    setErr: (err: string) => void;
    model3d?: boolean;
    size: number;
    limitSize: number;
    update?: boolean
    url?: string
    setUrl?: (url: string) => void
};

const FileInput = (props: FileInputProps) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (props.model3d) {
            acceptedFiles.length > 0 ? props.setFile(acceptedFiles) : props.setErr('Seul les formats .glb sont acceptés ');
        } else {
            acceptedFiles.length > 0 ? props.setFile(acceptedFiles) : props.setErr('Seul les formats jpeg, jpg et png sont acceptés ');
        }
    }, []);
    const accept = props.model3d ? ['.glb'] : 'image/*';

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: accept,
        onDrop,
    });

    useEffect(() => {
        console.log(props.file);
        props.file && props.setUrl && props.setUrl(URL.createObjectURL(props.file[0]));
    }, [props.file]);

    useEffect(() => {
        props.setErr('');
    }, [props.file]);

    const deleteItem = (name: string) => {
        // @ts-ignore
        props.setFile((current) =>
            current.filter((obj: File) => {
                return obj.name !== name;
            }),
        );
    };

    const closeP = () => {
        props.setFile(undefined);
        props.setUrl && props.setUrl('');
    };

    return (
        <div className='file-input-main'>
            {props.update && props.url && props.url.length > 0
                ? <div className='prev'>
                    <img onClick={closeP} className='c' alt='close' src={close} />
                    <img onClick={closeP} alt='preview' src={props.url} />
                </div>
                : <div className='drop-box' {...getRootProps()}>
                    <>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Déposer le fichier ici ... </p>
                        ) : (
                            <div className='drop-box-spans'>
                                <span className='drop-box-first'>Glisser et déposer vos fichiers ici</span>
                                <br />
                                ou
                                <br />
                                <span className='drop-box-second'> sélectionner un fichier depuis votre ordinateur</span>
                                <div className={props.err ? 'input-err' : ''}>{props.err &&
                                    <span>{props.err}</span>}</div>
                            </div>
                        )}
                    </>
                </div>
            }
            {!props.update && <div className='file-title'>
                <div>Fichiers téléchargés</div>
                <div className='size-count'>
                    {
                        props.size > props.limitSize &&
                        <div className='c'> Il est conseillé de ne pas télécharger plus de {props.limitSize} Mo</div>
                    }

                    <span className={props.size > props.limitSize ? 'c' : ''}>{props.size.toFixed(2)}</span> /
                    <span>{props.limitSize} MO</span>
                </div>
            </div>}
            {!props.update && props.file
                ? (<div className='file-preview'>
                    <div className='file-box'>
                        {props.file.map((el: File, i: number) => {
                            return (
                                <div className='texture-card' key={i}>
                                    {el.name.length < 35 ? el.name : `${el.name.substring(0, 30)}...`}
                                    <img
                                        onClick={() => {
                                            deleteItem(el.name);
                                        }}
                                        alt='poubelle'
                                        src={trash}
                                    />
                                </div>
                            );
                        })}
                    </div>

                </div>)
                : (
                    <div className={!props.update ? 'file-preview-empty' : ''}>{!props.update && 'Les fichiers que vous téléchargerez apparaitront ici'}</div>
                )}
        </div>
    );
};
export default FileInput;
