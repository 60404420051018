import React from 'react';
import Layer from '../../asset/Layer 2.svg';
import './ui-error.scss';

const UiError = ({ errorMessage }: { errorMessage: string }) => {
    return (
        <>
            {errorMessage && (
                <div className="errorMessage-main">
                    <img alt="logo d'erreur" src={Layer} />
                    <span>{errorMessage}</span>
                </div>
            )}
        </>
    );
};

export default UiError;
