import React, { useEffect, useState } from 'react';
import './suppler-header.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import HamburgerMenu from '../../../uicomponents/hamburger/hamburger';

import { resetAuth } from '../../../services/reducers/auth-reducer/auth-reducer';
import Paper from '../../../uicomponents/paper/paper';

import exit from '../../../asset/Deconnection.svg';
import profil from '../../../asset/Profil.svg';
import logoMdf from '../../../asset/mdf.png';
import redDot from '../../../asset/red-dot.png';
import greenDot from '../../../asset/Basic_green_dot.png';
import yellowDot from '../../../asset/yellowDat.png';

import { Params } from '../../../domain/Params/params-interface';
import { getFinish, GetFinishState, resetGetFinish } from '../../../services/reducers/suppliers/finish/finishes.slice';
import { RootState } from '../../../services/root-reducer';
import { ColorState, ToggleGLTFDirty } from '../../../services/reducers/material-editor/ColorReducer';
import SimpleModal from '../../../uicomponents/modal/simple-modal/simple-modal';
import backArrow from '../../../asset/retour.png';
import { CanReturnState, SetCanReturn } from '../../../services/reducers/can-return/can-return.slice';
import { getSupplier, GetSupplierState } from '../../../services/reducers/suppliers/suppliers.slice';
import { CollectionState, getCollection } from '../../../services/reducers/suppliers/collection/collection.slice';
import { getProductById, ProductInfoState } from '../../../services/reducers/product/product.reducers';


type SupplierHeaderProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    finishName?: string;
    setFinishName?: (finishName: string) => void;
};

const SupplierHeader = ({ open = false, setOpen, setFinishName }: SupplierHeaderProps) => {
    const location = useLocation();
    const { finishId, supplierReference, collectionReference, productReference } = useParams<Params>();
    const [openModal, setOpenModal] = useState(false);
    const [historyPath, setHistoryPath] = useState('');

    const finish = useSelector<RootState, GetFinishState>((state) => state.getFinish);
    const supplier = useSelector<RootState, GetSupplierState>(state => state.getSupplier);
    const collection = useSelector<RootState, CollectionState>(state => state.getCollection);
    const product = useSelector<RootState, ProductInfoState>((state) => state.getProductionById);
    const { isGltfDirty } = useSelector<RootState, ColorState>((state) => state.ColorReducer);
    const canReturn = useSelector<RootState, CanReturnState>((state) => state.canReturn);

    const dispatch = useDispatch();
    const history = useHistory();

    const userInfo = localStorage.getItem('user');
    const user = userInfo && JSON.parse(userInfo);

    //#region useEffect
    useEffect(() => {
        finishId && dispatch(getFinish(parseInt(finishId)));
    }, [finishId]);
    useEffect(() => {
        if (finish.payload.content !== undefined && finish.payload.content !== null) {
            setFinishName && setFinishName(finish.payload.content.name);
        }
    }, [finish]);
    useEffect(() => {
        if (location.pathname.includes('colors')) {
            isGltfDirty ? dispatch(SetCanReturn(false)) : dispatch(SetCanReturn(true));
        }
    }, [location, isGltfDirty]);
    useEffect(() => {
        supplierReference && dispatch(getSupplier(supplierReference));
    }, [supplierReference]);
    useEffect(() => {
        collectionReference && dispatch(getCollection(collectionReference));
    }, [collectionReference]);
    useEffect(() => {
        productReference && dispatch(getProductById(productReference));
    }, [productReference, collection]);
    useEffect(() => {
        returnStatusColor();
    }, [product]);

    const confirmReturn = (url: string) => {
        setHistoryPath(url);
        isGltfDirty ? setOpenModal(true) : history.push('/home/suppliers');
    };
//endregion

    const returnStatusColor = () => {
        switch (product.payload.content?.first.completionStatus) {
            case 'CREATING':
                return yellowDot;
            case 'COMPLETE':
                return greenDot;
            case 'INCOMPLETE':
                return redDot;
        }
    };

    return (
        <div className='header-container'>
            {openModal && (
                <SimpleModal
                    icon={backArrow}
                    title='Quitter sans sauvegarder'
                    info='Vous perdrez les changements en cours '
                    closeOnclick={() => setOpenModal(false)}
                    actionOnclick={() => {
                        dispatch(SetCanReturn(true));
                        dispatch(ToggleGLTFDirty(false));
                        history.push(historyPath);
                    }}
                    deleteOrValide={true}
                    closeLabel='Annuler'
                    actionLabel='Quitter'
                />
            )}
            <div className='hamburger'>
                <HamburgerMenu />
            </div>
            <div className='header-main'>
                <img alt='logo mdf' src={logoMdf} />
                <div className='breadcrumb'>
                    <div>
                        <span
                            className={!supplierReference ? 'last' : 'head-supp-name'}
                            onClick={() => {
                                location.pathname.includes('colors')
                                    ? confirmReturn('/home/suppliers')
                                    : history.push('/home/suppliers');
                            }}>Fournisseurs</span>
                        {supplierReference &&
                            <>
                                <span>/</span>
                                <span className={!collectionReference && !finishId ? 'last' : 'head-supp-name'}
                                      onClick={() => {
                                          if (collectionReference || finishId) {
                                              if (canReturn.canReturn) {
                                                  history.push(`/supplier/${supplierReference}/collections`);
                                                  dispatch(resetGetFinish());
                                                  setFinishName && setFinishName('');
                                              } else {
                                                  confirmReturn(`/supplier/${finish.payload.content?.supplierReference}/collections`);
                                              }
                                          }
                                      }}>
                        {`${supplier.payload.content?.name}`}
                            </span>
                            </>}
                        {collectionReference &&
                            <span>
                                <span> /</span>
                                 <span
                                     onClick={() => history.push(`/supplier/${supplierReference}/collections/${collectionReference}/products`)}
                                     className={!productReference ? 'last' : 'head-supp-name'}>{collection.payload.content?.name}</span>
                            </span>}
                        {finishId &&
                            <>
                                <span>/</span>
                                {finishId &&
                                    <span
                                        onClick={() => history.push(`/supplier/${supplierReference}/collections/${collectionReference}/products`)}
                                        className={!productReference ? 'last' : 'head-supp-name'}>
                                        {finish.payload.content?.name}
                                    </span>}
                            </>}
                        {productReference && <span>/</span>}
                        {productReference &&
                            <>
                                <span className='last'>{product.payload.content?.first.name}</span>
                                <img className='status' alt='status' src={returnStatusColor()} />
                            </>
                        }
                    </div>
                </div>
            </div>

            <div className='avatar'>
                <img
                    onClick={() => {
                        setOpen(!open);
                    }}
                    src={profil}
                    alt='avatar logo '
                />
                {open && (
                    <div className='avatar-modal'>
                        <Paper top={20} height={138} size={205}>
                            <div>
                                <p
                                    onClick={() => {
                                        history.push('/update-user');
                                    }}
                                >
                                    <img alt='initial avatar' src={profil} />
                                    {user.firstname} {user.lastname}
                                </p>
                                <p
                                    onClick={() => {
                                        dispatch(resetAuth());
                                        localStorage.removeItem('mdf-jwt');
                                        localStorage.removeItem('user');
                                        history.push('/');
                                    }}
                                >
                                    <img alt='logo deconnect' src={exit} /> Se Déconnecter
                                </p>
                            </div>
                        </Paper>
                    </div>
                )}
            </div>
        </div>
    );
};
export default SupplierHeader;
