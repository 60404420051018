import { CompositionModuleResponse } from '../domain/domain';

export const addModule = (newList: CompositionModuleResponse[], newModule: CompositionModuleResponse, selector: string, index: number) => {
    const newCmList = [...newList];
    if (newCmList.length === 1) {
        selector === 'RIGHT' ? newCmList.push(newModule) : newCmList.unshift(newModule);
    } else {
        selector === 'RIGHT'
            ? newCmList.splice(index + 1, 0, newModule)
            : newCmList.splice(index, 0, newModule);
    }
    return newCmList;
};