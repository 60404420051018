import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Object3D } from "three";
import Asset3D from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D";
import {RootState} from "../../../../services/redux/root-reducers";
import {CharacteristicStudioState} from "../../Redux/Reducers/characteristic-studio/CharacteristicStudioReducer";

export type MeshLoaderManagerProps = {
    previewMesh?: Asset3D
    previewMaterial?: Asset3D
    setObjectCallback: (p_object: Object3D) => void
};

const MeshLoaderManagerProps = (props: MeshLoaderManagerProps) => {
    const { characteristicStudioApp } = useSelector<RootState, CharacteristicStudioState>((state) => state.characteristicStudioReducer);

    useEffect(() => {
        return () => {
            characteristicStudioApp?.Services.MeshService.ClearManager();
        };
    }, []);

    useEffect(() => {
        if (!characteristicStudioApp || !props.previewMesh) return;
        characteristicStudioApp.Services.MeshService.LoadModel(props.previewMesh).then((object3D) => {
            props.setObjectCallback(object3D);
        });
    }, [props.previewMesh, characteristicStudioApp]);

    useEffect(() => {
        if (!characteristicStudioApp || !props.previewMaterial) return;
        characteristicStudioApp.Services.MeshService.LoadMaterialMapping(props.previewMaterial);
    }, [props.previewMaterial, characteristicStudioApp]);

    return <>
    </>;
};

export default MeshLoaderManagerProps;