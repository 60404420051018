import MdfApiResponse from '../../../domain/common/generic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import storeServices from '../../../api/services/store/store.services';
import { Store, StoreByUser, StoreUserList } from '../../../domain/domain';

export type StoreState = {
    isLoading: boolean;
    payload: MdfApiResponse<Store>;
};
export type StoreListState = {
    isLoading: boolean;
    payload: MdfApiResponse<Store[]>;
};

export type StoreUserMapState = {
    isLoading: boolean;
    payload: MdfApiResponse<StoreByUser[]>;
};

const initialStore: StoreState = {
    isLoading: false,
    payload: {
        content: {
            id: 0,
            name: '',
            version: 0,
            reference: '',
            sellerSet: [],
            responsibleSet: [],
            createdAt: new Date(),
            updatedAt: new Date(),
        },
        errors: [],
        warnings: [],
    },
};
const initialStoreList: StoreListState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};

const initialStoreUserMap: StoreUserMapState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};

//region getStore
export const getStores = createAsyncThunk('get/stores', async () => {
    const res = await storeServices.getStores();
    return res;
});

export const getStoreSlice = createSlice({
    name: 'getCollection',
    initialState: initialStoreList,
    reducers: {
        resetGetStores: (state) => {
            state = initialStoreList;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStores.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getStores.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getStores.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetStores } = getStoreSlice.actions;
//endregion
//region createstore
export const createStore = createAsyncThunk('create/store', async (name: string) => {
    const res = await storeServices.createStore(name);
    return res;
});

export const createStoreSlice = createSlice({
    name: 'createStore',
    initialState: initialStore,
    reducers: {
        resetCreateStores: (state) => {
            state = initialStore;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createStore.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createStore.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createStore.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateStores } = createStoreSlice.actions;
//endregion
//region delete store
export const deleteStore = createAsyncThunk('delete/store', async (storeRef: string) => {
    const res = await storeServices.deleteStore(storeRef);
    return res;
});

export const deleteStoreSlice = createSlice({
    name: 'deleteStore',
    initialState: initialStore,
    reducers: {
        resetDeleteStore: (state) => {
            state = initialStore;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteStore.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteStore.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteStore.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteStore } = deleteStoreSlice.actions;
//endregion
//region get store by reference
export const getStoreByReference = createAsyncThunk('get/storeByReference', async (storeRef: string) => {
    const res = await storeServices.getStoreByRef(storeRef);
    return res;
});

export const getStoreByRefSlice = createSlice({
    name: 'getStoreById',
    initialState: initialStore,
    reducers: {
        resetGetStoreByRef: (state) => {
            state = initialStore;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStoreByReference.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getStoreByReference.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getStoreByReference.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetStoreByRef } = getStoreByRefSlice.actions;
//endregion
//region get store by managerId
export const getStoreByManagerId = createAsyncThunk('get/storeByManagerId', async (managerId: number) => {
    const res = await storeServices.getStoreByManagerId(managerId);
    return res;
});

export const getStoreByManagerIdSlice = createSlice({
    name: 'getStoreByManagerId',
    initialState: initialStoreList,
    reducers: {
        resetGetStoreByManagerId: (state) => {
            state = initialStoreList;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStoreByManagerId.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getStoreByManagerId.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getStoreByManagerId.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetStoreByManagerId } = getStoreByManagerIdSlice.actions;
//endregion
//region update-store-name
export const updateStoreName = createAsyncThunk('update/storeName', async (data: { storeRef: string, storeName: string }) => {
    const res = await storeServices.updateStoreName(data.storeRef, data.storeName);
    return res;
});
export const updateStoreNameSlice = createSlice({
    name: 'updateStoreName',
    initialState: initialStore,
    reducers: {
        resetUpdateStoreName: (state) => {
            state = initialStore;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateStoreName.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateStoreName.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateStoreName.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateStoreName } = updateStoreNameSlice.actions;
//endregion

//region add-user-to-store
export const addUserToStore = createAsyncThunk('add/userToStore', async (data: { storeRef: string, roleName: string, userId: number }) => {
    const res = await storeServices.addUserToStore(data.storeRef, data.roleName, data.userId);
    return res;
});
export const addUserToStoreSlice = createSlice({
    name: 'addUserToStore',
    initialState: initialStore,
    reducers: {
        resetAddUserToStore: (state) => {
            state = initialStore;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addUserToStore.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(addUserToStore.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(addUserToStore.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetAddUserToStore } = addUserToStoreSlice.actions;
//endregion


//region user-store-map
export const getUserStoreMap = createAsyncThunk('get/userStoreMap', async (data: StoreUserList) => {
    const res = await storeServices.getStoreUserMap(data);
    return res;
});
export const getUserStoreMapSlice = createSlice({
    name: 'getUserStoreMapSlice',
    initialState: initialStoreUserMap,
    reducers: {
        resetGetUserStoreMapSlice: (state) => {
            state = initialStoreUserMap;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserStoreMap.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getUserStoreMap.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getUserStoreMap.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetUserStoreMapSlice } = getUserStoreMapSlice.actions;
//endregion


//region add-user-to-store
export const removeUserToStore = createAsyncThunk('remove/userToStore', async (data: { storeRef: string, userId: number }) => {
    const res = await storeServices.removeUserToStore(data.storeRef, data.userId);
    return res;
});
export const removeUserToStoreSlice = createSlice({
    name: 'removeUserToStore',
    initialState: initialStore,
    reducers: {
        resetRemoveUserToStore: (state) => {
            state = initialStore;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(removeUserToStore.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(removeUserToStore.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(removeUserToStore.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetRemoveUserToStore } = removeUserToStoreSlice.actions;
//endregion
