import apiClient from './api/api-client';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { GET_LANGAGE } from './api/endpoints/langage/langage.endpoint';
import frenchResources from './langage/fr/translation.json';

const en = async () => {
    try {
        const res = await apiClient(GET_LANGAGE, undefined, undefined, 'GET');

        i18n.addResourceBundle('en', 'translation', { ...res.content.en.translation });
        i18n.addResourceBundle('fr', 'translation', { ...res.content.fr.translation });
    } catch (e) {
        i18n.addResourceBundle('fr', 'translation', { ...frenchResources });
    }
};

const resources = { fr: { translation: frenchResources } };

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'fr',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

en();

export default i18n;