import apiClient from '../../../api-client';
import { CollectionCreationForm } from '../../../../domain/domain';
import { CREATE_COLLECTION, DELETE_COLLECTION, GET_COLLECTION, GET_COLLECTIONS, UPDATE_COLLECTION } from '../../../endpoints/suppliers/collection/collections.endpoint';

const collectionsServices = {
    getCollection: async (collectionReference: string) => apiClient(GET_COLLECTION(collectionReference), undefined, undefined, 'GET'),
    getCollections: async (supplierReference: string) => apiClient(GET_COLLECTIONS(supplierReference), undefined, undefined, 'GET'),
    createCollection: async (supplierReference: string, data: CollectionCreationForm) => apiClient(CREATE_COLLECTION(supplierReference), data, undefined, 'POST'),
    updateCollection: async (collectionReference: string, data: CollectionCreationForm) => apiClient(UPDATE_COLLECTION(collectionReference), data, undefined, 'PUT'),
    deleteCollection: async (collectionReference: string) => apiClient(DELETE_COLLECTION(collectionReference), undefined, undefined, 'DELETE'),
};
export default collectionsServices;
