import './toaster.scss';
import { useEffect } from 'react';
import validation from '../../asset/Validation-vert.svg';
import Layer from '../../asset/Layer 2.svg';

type ToasterProps = {
    visibility: boolean;
    title: string;
    message: string;
    onClose: () => void;
    validate?: boolean;
};

const Toaster = ({ visibility, title, message, onClose, validate }: ToasterProps) => {
    useEffect(() => {
        if (visibility) {
            setTimeout(() => {
                onClose();
            }, 3000);
        }
    }, [visibility]);

    return visibility ? (
        <div className="toaster-main">
            <div onClick={onClose} className="toaster-close">
                {' '}
                <div className="x">X</div>
            </div>
            <div className="toaster-content">
                {validate ? <img alt="logo validation" src={validation} /> : <img alt="logo layer" src={Layer} />}
                <div className="toaster-message-bloc">
                    <div className="toaster-title">{title}</div>
                    {message.length > 1 && <div className="toaster-message">{message}</div>}
                </div>
            </div>
        </div>
    ) : null;
};

export default Toaster;
