import './finishes-table.scss';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFinish } from '../../../services/reducers/suppliers/finish/finishes.slice';
import { RootState } from '../../../services/root-reducer';
import { Params } from '../../../domain/Params/params-interface';

import Loader from '../../../uicomponents/loader/loader';
import { DeleteTextureState, getAllTextures, GetTexturesState, resetUpdateTexture, UpdateTextureState } from '../../../services/reducers/suppliers/finish/texture/texture.slice';
import {
    CreateColorisState,
    DeleteColorisState,
    duplicateColoris,
    DuplicateColorisState,
    getAllColoris,
    GetAllColorisState,
    resetCreateColoris,
    resetDeleteColoris,
    resetDuplicateColoris,
    resetUpdateColoris,
    updateColoris,
    UpdateColorisState,
} from '../../../services/reducers/suppliers/finish/coloris/coloris.slice';

import { Colori, ColoriCreateForm } from '../../../domain/domain';
import { ColorState, SetGLTF, SetUrl } from '../../../services/reducers/material-editor/ColorReducer';
import SimpleModal from '../../../uicomponents/modal/simple-modal/simple-modal';
import CreateColoris from '../../../components/modals/coloris/create-coloris/create-coloris';
import backArrow from '../../../asset/retour.png';
import { ClearImage, ThumbnailState } from '../../../services/reducers/material-editor/ThumbnailReducer';
import axios from 'axios';
import { CanReturnState, ResetCanReturn, SetCanReturn } from '../../../services/reducers/can-return/can-return.slice';
import { getConfig } from '../../../api/config';
import ColorsList from './colors/colors-list';
import TexturesList from './textures/textures-list';

type FinishesProps = {
    tabName: string;
    refreshData: () => void;
    listColoris: GetAllColorisState;
    setDeleteColorisId: (colorisId: number) => void;
    setTextureId: (textureId: number) => void;
    setTextureName: (textureName: string) => void;
    setTextureUrl: (textureUrl: string) => void;
    setTextureThumbnailUrl: (textureThumbnailUrl: string) => void;
    setOpenDeleteColorisModal: (openDeleteColorisModal: boolean) => void;
    setOpenCreateColorisModal: (openCreateColorisModal: boolean) => void;
    setOpenDeleteTextureModal: (openDeleteTextureModal: boolean) => void;
    setOpenUpdateTextureModal: (openUpdateTextureModal: boolean) => void;
    setTextureIsLoading: (openDeleteColorisModal: boolean) => void;
    setFromCrush?: (fromCrush: boolean) => void;
    fromCrush?: boolean;
};

const FinishesTable = (props: FinishesProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const token = localStorage.getItem('mdf-jwt');
    const { finishId } = useParams<Params>();

    //#region useSelector
    const createColorisRes = useSelector<RootState, CreateColorisState>((state) => state.createColoris);
    const duplicateColorisRes = useSelector<RootState, DuplicateColorisState>((state) => state.duplicateColoris);
    const deleteColorisRes = useSelector<RootState, DeleteColorisState>((state) => state.deleteColoris);
    const updateColorisRes = useSelector<RootState, UpdateColorisState>((state) => state.updateColoris);
    const updateTextureRes = useSelector<RootState, UpdateTextureState>((state) => state.updateTextures);
    const deleteTextureRes = useSelector<RootState, DeleteTextureState>((state) => state.deleteTexture);
    const textureRes = useSelector<RootState, GetTexturesState>((state) => state.getAllTextures);
    const canReturn = useSelector<RootState, CanReturnState>((state) => state.canReturn);
    const { gltf } = useSelector<RootState, ColorState>((state) => state.ColorReducer);
    const { image } = useSelector<RootState, ThumbnailState>((state) => state.ThumbnailReducer);
    //endregion

    //#region useState
    const [selectColoris, setSelectColoris] = useState<Colori | undefined>(undefined);
    const [imgHooverUrl, setImgHooverUrl] = useState<string | undefined>(undefined);
    const [openDuplicateColorisModal, setOpenDuplicateColorisModal] = useState(false);
    const [vignette, setVignette] = useState<FormData | undefined>(undefined);
    const [coloris, setColoris] = useState<any | undefined>(undefined);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [colorisId, setColorisId] = useState<number>(0);
    const [openModal, setOpenModal] = useState(false);
    const [imgHooverId, setImgHooverId] = useState(0);
    const [colorSave, setColorSave] = useState(false);
    const [isMount, setIsMount] = useState(false);
    const [, setIsDuplicate] = useState(false);
    const [dupIsClicked, setDupIsClicked] = useState<number[]>([]);
    const [gltfFromRal, setGltfFromRal] = useState();
    const [updateContent, setUpdateContent] = useState<any | undefined>(undefined);
    //#endregion

    //#region useEffect
    useEffect(() => {
        if (finishId) {
            dispatch(getFinish(parseInt(finishId))) && props.refreshData();
            dispatch(getAllTextures({ finishId: parseInt(finishId) }));
            dispatch(getAllColoris(parseInt(finishId)));
            dispatch(SetCanReturn(true));
        }
    }, []);

    //#region coloris
    useEffect(() => {
        const colorisList = props.listColoris.payload.content;
        !isMount && setSelectColoris(colorisList[0]);
        return () => setIsMount(true);
    }, [props.listColoris]);
    useEffect(() => {
        selectColoris !== undefined && dispatch(SetUrl(selectColoris.modelUrl));
        selectColoris && selectColoris != undefined && props.setDeleteColorisId(selectColoris?.id);
    }, [selectColoris]);
    useEffect(() => {
        if (createColorisRes.payload.content?.id !== 0 && createColorisRes.payload.errors.length < 1) {
            finishId && dispatch(getAllColoris(parseInt(finishId)));
            props.setOpenCreateColorisModal(false);
            setSelectColoris(createColorisRes.payload.content);
            dispatch(resetCreateColoris());
        }
    }, [createColorisRes]);
    useEffect(() => {
        if (duplicateColorisRes.isLoading) {
            setOpenDuplicateColorisModal(false);
        }
        if (duplicateColorisRes.payload.content !== undefined && duplicateColorisRes.payload.errors.length < 1) {
            setOpenDuplicateColorisModal(false);
            finishId && dispatch(getAllColoris(parseInt(finishId)));
            dispatch(resetDuplicateColoris());
            setSelectColoris(duplicateColorisRes.payload.content);
            finishId && dispatch(getAllColoris(parseInt(finishId)));
            setIsDuplicate(true);
        }
    }, [duplicateColorisRes]);
    useEffect(() => {
        if (updateColorisRes.payload.content !== undefined && updateColorisRes.payload.errors.length === 0) {
            setColorSave(true);
            setTimeout(() => {
                setColorSave(false);
            }, 3000);
            dispatch(resetUpdateColoris());
            dispatch(ClearImage);
            finishId && dispatch(getAllColoris(parseInt(finishId)));
            dispatch(SetGLTF(undefined));
            setSelectColoris(updateColorisRes.payload.content);
            setOpenUpdateModal(false);
        }
        dispatch(resetUpdateColoris());
        dispatch(SetGLTF(undefined));
    }, [updateColorisRes]);
    useEffect(() => {
        if (deleteColorisRes.payload.content !== undefined && deleteColorisRes.payload.errors.length < 1) {
            props.setOpenDeleteColorisModal(false);
            finishId && dispatch(getAllColoris(parseInt(finishId)));
            dispatch(resetDeleteColoris());
            setSelectColoris(props.listColoris.payload.content[0]);
        }
    }, [deleteColorisRes]);
    useEffect(() => {
        let formData = new FormData();
        if (image !== undefined) {
            formData.append('file', image);
            setVignette(formData);
        }
    }, [image]);
    useEffect(() => {
        if (vignette !== undefined) {
            axios({
                method: 'post',
                url: `${getConfig().urlHostApi}/v1/documents/upload`,
                data: vignette,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(function (res) {
                    selectColoris &&
                        dispatch(
                            updateColoris({
                                colorisId: selectColoris.id,
                                updateColorisForm: {
                                    coloriCode: selectColoris.code,
                                    coloriName: selectColoris.name,
                                    colorisContent: gltfFromRal ? JSON.stringify(gltfFromRal) : JSON.stringify(gltf),
                                    colorisThumbnailUrl: res.data.content,
                                    uploadedThumbnailUrl: selectColoris.uploadedThumbnailUrl,
                                },
                            })
                        );
                    setVignette(undefined);
                    setGltfFromRal(undefined);
                })
                .catch(function (response) {
                    console.log(response);
                });
        }
    }, [gltf, vignette, gltfFromRal]);
    useEffect(() => {
        !canReturn && setOpenModal(true);
    }, [canReturn]);
    //#endregion

    //#region texture
    useEffect(() => {
        if (deleteTextureRes.payload.content !== undefined && deleteTextureRes.payload.errors.length < 1) {
            props.setOpenDeleteTextureModal(false);
            finishId && dispatch(getAllTextures({ finishId: parseInt(finishId) }));
            dispatch(resetDeleteColoris());
        }
    }, [deleteTextureRes]);
    useEffect(() => {
        if (updateTextureRes.payload.content !== undefined && updateTextureRes.payload.errors.length < 1) {
            props.setOpenUpdateTextureModal(false);
            finishId && dispatch(getAllTextures({ finishId: parseInt(finishId) }));
            dispatch(resetUpdateTexture());
        } else {
            if (updateTextureRes.payload.errors.length > 0) {
                alert(updateTextureRes.payload.errors[0].message);
            }
        }
    }, [updateTextureRes]);
    useEffect(() => {
        props.setTextureIsLoading(false);
        if (props.fromCrush) {
            props.setFromCrush && props.setFromCrush(false);
        }
    }, [textureRes]);
    //#endregion

    //#endregion
    const duplicateColorisAction = (colorisCreateForm: ColoriCreateForm) => {
        finishId &&
            dispatch(
                duplicateColoris({
                    finishId: parseInt(finishId),
                    colorisId: colorisId,
                    coloriCreateForm: {
                        coloriName: colorisCreateForm.coloriName,
                        coloriCode: colorisCreateForm.coloriCode,
                        declinaisonRal: false,
                    },
                })
            );
    };

    return (
        <table className="finishes-table-main">
            {duplicateColorisRes.isLoading && <Loader isLoading={duplicateColorisRes.isLoading} />}
            <>
                {openUpdateModal && location.pathname.includes('colors') && canReturn && (
                    <CreateColoris
                        coloris={coloris}
                        update={true}
                        refresh={() => {
                            finishId && dispatch(getAllColoris(parseInt(finishId)));
                        }}
                        setOpenColorModal={setOpenUpdateModal}
                        updateContent={updateContent}
                    />
                )}

                {openDuplicateColorisModal && location.pathname.includes('colors') && canReturn && (
                    <CreateColoris
                        coloris={coloris}
                        update={false}
                        duplicate={true}
                        title="Dupliquer un coloris"
                        duplicateAcion={duplicateColorisAction}
                        refresh={() => {
                            finishId && dispatch(getAllColoris(parseInt(finishId)));
                        }}
                        setOpenColorModal={setOpenDuplicateColorisModal}
                        updateContent={[]}
                    />
                )}
                {openModal && (
                    <SimpleModal
                        icon={backArrow}
                        title="Quitter sans sauvegarder"
                        info="Vous perdrez les changements en cours "
                        closeOnclick={() => {
                            dispatch(ResetCanReturn());
                            setOpenModal(false);
                        }}
                        actionOnclick={() => {
                            dispatch(ResetCanReturn());
                            setSelectColoris(coloris);
                            setOpenModal(false);
                        }}
                        deleteOrValide={true}
                        closeLabel="Annuler"
                        actionLabel="Quitter"
                    />
                )}
            </>
            <TexturesList
                setColorisId={setColorisId}
                setImgHooverId={setImgHooverId}
                setTextureId={props.setTextureId}
                setTextureName={props.setTextureName}
                setTextureUrl={props.setTextureUrl}
                setTextureThumbnailUrl={props.setTextureThumbnailUrl}
                setOpenDeleteColorisModal={props.setOpenDeleteColorisModal}
                setOpenCreateColorisModal={props.setOpenCreateColorisModal}
                setOpenDeleteTextureModal={props.setOpenDeleteTextureModal}
                setOpenUpdateTextureModal={props.setOpenUpdateTextureModal}
                imgHooverId={imgHooverId}
            />
            <ColorsList
                setDupIsClicked={setDupIsClicked}
                dupIsClicked={dupIsClicked}
                setImgHooverId={setImgHooverId}
                gltfFromRal={gltfFromRal}
                setGltfFromRal={setGltfFromRal}
                setUpdateContent={setUpdateContent}
                updateContent={updateContent}
                selectedColoris={selectColoris}
                setSelectColoris={setSelectColoris}
                imgHooverUrl={imgHooverUrl}
                setImgHooverUrl={setImgHooverUrl}
                colorSave={colorSave}
                setColorSave={setColorSave}
                setColorisId={setColorisId}
                setColoris={setColoris}
                listColoris={props.listColoris}
                setOpenModal={setOpenModal}
                setOpenDuplicateColorisModal={setOpenDuplicateColorisModal}
                setOpenUpdateModal={setOpenUpdateModal}
                setOpenDeleteColorisModal={props.setOpenDeleteColorisModal}
            />
        </table>
    );
};
export default FinishesTable;
