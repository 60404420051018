import { TarifWinField } from '../../../domain/domain';

const WIN_ASSOCIATE_PRODUCT = '/v1/win-integration/associate-product';
const WIN_DISSOCIATE_PRODUCT = '/v1/win-integration/dissociate-product';

const WIN_ASSOCIATE_CONFIGURATION = '/v1/win-integration/associate-configuration';
const WIN_DISSOCIATE_CONFIGURATION = '/v1/win-integration/dissociate-configuration';
const WIN_RATES = '/v1/win-integration/win-rates';
const WIN_PRODUCTS_ASSOCIATED = (collectionRef: string) => `/v1/win-integration/collection/${collectionRef}/`;

const WIN_CONFIGURATIONS_ASSOCIATED = `/v1/win-integration/configurations`;
const WIN_CONFIGURATION_BY_WIN_KEY = `/v1/win-integration/configuration`;

// ==> win filter <==//

const FIND_WIN_FILTER = (collectionRef: string) => `/v1/win-filters/collection/${collectionRef}`;
const CREATE_WIN_FILTER = (collectionRef: string, tarifWinField: TarifWinField, value: string) => `/v1/win-filters/collection/${collectionRef}/field/${tarifWinField}/value/${value}/create`;
const DELETE_WIN_FILTER = (winFilterId: number) => `/v1/win-filters/${winFilterId}`;

export {
    WIN_ASSOCIATE_PRODUCT,
    WIN_DISSOCIATE_PRODUCT,
    WIN_RATES,
    WIN_ASSOCIATE_CONFIGURATION,
    WIN_DISSOCIATE_CONFIGURATION,
    WIN_CONFIGURATION_BY_WIN_KEY,
    WIN_CONFIGURATIONS_ASSOCIATED,
    WIN_PRODUCTS_ASSOCIATED,
    FIND_WIN_FILTER,
    DELETE_WIN_FILTER,
    CREATE_WIN_FILTER,
};
