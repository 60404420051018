import close from '../../../../asset/CloseWindow.svg';
import { formErrors } from '../../../../utils/form-errors';
import { useDispatch } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { updateZoning } from '../../../../services/reducers/mapping-zoning/zoning.reducer';
import React, { useEffect } from 'react';
import { Zone } from '../../../../domain/domain';
import { returnadvice } from '../mapping/create-mapping';


type UpdateZoningProps = {
    setOpenModal: (openModal: boolean) => void;
    groupId: number;
    mappingId: number;
    zone: Zone;
};

type UpdateZoningInput = {
    name: string;
};

const UpdateZoning = (props: UpdateZoningProps) => {
    const dispatch = useDispatch();

    const {
        register,
        watch,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<UpdateZoningInput>({});

    const onSubmit: SubmitHandler<UpdateZoningInput> = (data) => {
        dispatch(
            updateZoning({
                mappingId: props.mappingId,
                zoneUuid: props.zone.uuid,
                zone: {
                    uuid: props.zone.uuid,
                    name: data.name,
                    defaultColoriId: props.zone.defaultColoriId,
                    applicableColoriIds: props.zone.applicableColoriIds,
                    availableIds: props.zone.availableIds,
                    nonConfigurableZone: props.zone.nonConfigurableZone,
                },
            }),
        );
    };

    const keyDownHandler = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    };

    useEffect(() => {
        props.zone.name && setValue('name', props.zone.name);
    }, [props.zone.name]);

    return (
        <div className='create-group-main'>
            <div className='create-group-paper'>
                <div className='create-group-top'>
                    <img
                        onClick={() => {
                            props.setOpenModal(false);
                        }}
                        alt='fermer la modal '
                        src={close}
                    />
                    <div className='create-group-title'>Renommer une Zone</div>
                </div>
                <div className='create-group-body'>
                    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={keyDownHandler}>
                        <div className='input-container'>
                            <label htmlFor='name'>Nom</label>
                            <input
                                placeholder='Nom de la zone'
                                {...register('name', {
                                    required: true,
                                })}
                            />
                            <> {returnadvice(watch('name'), 24)}</>
                            <div className='err-box'>{errors && formErrors(errors.name)}</div>
                        </div>
                        <div className='btn-box'>
                            <button
                                onClick={() => {
                                    props.setOpenModal(false);
                                }}
                                className='btn-close'
                            >
                                Annuler
                            </button>
                            <button className='btn-action'>Modifier</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default UpdateZoning;
