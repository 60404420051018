import close from '../../../asset/CloseWindow.svg';
import { formErrors } from '../../../utils/form-errors';
import { useDispatch } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { rename3dCellValueLabel } from '../../../services/reducers/product/3DCells/3DCellValues.reducers';


type Rename3dCellValueLabelProps = {
    setOpenModal: (openModal: boolean) => void;
    atomRef: string;
};

type RenameInput = {
    name: string;
};

const Rename3dCellValueLabelModal = (props: Rename3dCellValueLabelProps) => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RenameInput>({});

    const onSubmit: SubmitHandler<RenameInput> = (data) => {
        dispatch(
            rename3dCellValueLabel({ atomref: props.atomRef, label: data.name }),
        );
    };
    const keyDownHandler = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    };
    return (
        <div className='create-group-main'>
            <div className='create-group-paper'>
                <div className='create-group-top'>
                    <img
                        onClick={ () => {
                            props.setOpenModal(false);
                        } }
                        alt='fermer la modal '
                        src={ close }
                    />
                    <div className='create-group-title'>Modifier le label de la caractéristique</div>
                </div>
                <div className='create-group-body'>
                    <form onSubmit={ handleSubmit(onSubmit) } onKeyDown={ keyDownHandler }>
                        <div className='input-container'>
                            <label htmlFor='name'>Label</label>
                            <input
                                placeholder='Label'
                                { ...register('name', {
                                    required: true,
                                }) }
                            />
                            <div className='err-box'>{ errors && formErrors(errors.name) }</div>
                        </div>
                        <div className='btn-box'>
                            <button
                                onClick={ () => {
                                    props.setOpenModal(false);
                                } }
                                className='btn-close'
                            >
                                Annuler
                            </button>
                            <button className='btn-action'>Créer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default Rename3dCellValueLabelModal;