import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {TextureType} from "../ParamTypes/TextureType";
import {BooleanType} from "../ParamTypes/BooleanType";
import {ButtonType} from "../ParamTypes/ButtonType";
import {SliderData, SliderType} from "../ParamTypes/SliderType";
import {InspectorType} from "../ParamTypes/InspectorType";
import {ColorType} from "../ParamTypes/ColorType";

export class Emissive {
    public m_emissiveMapShow: InspectorType;
    public m_emissive: InspectorType;
    public m_emissiveColor: InspectorType;
    public m_emissiveMap: InspectorType;
    public m_emissiveFlipY: InspectorType;
    public m_emissiveClear: InspectorType;
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        //Albedo
        this.m_uiFolder = m_uiFolders = m_tab.pages[0].addFolder({title: 'Emissive (RGB) [no texture]', expanded: false});
        this.m_emissive = new SliderType(new SliderData(0.0,4.0,0.01), m_matParams, "emissiveIntensity", m_uiFolders);
        this.m_emissiveColor = new ColorType(m_matParams, "emissive", m_uiFolders);
        this.m_emissiveMapShow = new BooleanType("Show map on mesh", m_matParams, "emissiveMapShowOnMesh", m_uiFolders);
        this.m_emissiveMap = new TextureType(MapType.emissiveMap, m_matParams, "emissiveMap", m_uiFolders);
        this.m_emissiveFlipY = new BooleanType("Flip Y", m_matParams, "emissiveMapFlipY", m_uiFolders);
        this.m_emissiveClear = new ButtonType("Clear map", m_matParams, "", m_uiFolders);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_emissive.InitType();
        this.m_emissiveColor.InitType();
        this.m_emissiveMapShow.InitType();
        this.m_emissiveMap.InitType();
        this.m_emissiveFlipY.InitType();
        this.m_emissiveClear.InitType();
    }
}