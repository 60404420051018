import './composable-module.scss';
import trash from '../../../../../../../asset/Dettach.svg';
import { useEffect, useState } from 'react';
import AssociateModule from './associate-module/associate-module';
import { CompositionModule3DInfo, CompositionModuleResponse, Pair, Product, Product3DInfo, Tag } from '../../../../../../../domain/domain';
import compositionModuleService from '../../../../../../../api/services/composition/composition-module.service';
import TagService from '../../../../../../../api/services/tag/tag.service';
import { getFinishTag, GetFinishTagState } from '../../../../../../../services/reducers/tag/tag.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../services/root-reducer';
import compositionModuleTagService from '../../../../../../../api/services/composition/composition-module-tag.service';
import send from '../../../../../../../asset/Envoyer.svg';
import close from '../../../../../../../asset/CloseWindow.svg';

type ComposableModuleProps = {
    openModule: boolean;
    setOpenModal: (openModule: boolean) => void;
    currentProduct: Pair<Product, Product3DInfo>;
    setStep: (step: number) => void;
    setFocus: (focus: number) => void;
};
const ComposableModule = (props: ComposableModuleProps) => {
    const dispatch = useDispatch();
    const composableTableHead = ['Nom', 'Tag Module', 'Type de produit', 'Angle', 'Actions'];

    const tagListRes = useSelector<RootState, GetFinishTagState>((state) => state.getFinishTag);

    //#region method
    const [compositionModules, setCompositionModules] = useState<CompositionModuleResponse[]>([]);
    const [availableTags, setAvailableTags] = useState<Tag[]>([]);
    const [enterAngle, setEnterAngle] = useState(0);
    const [angleInput, setAngleInput] = useState<number>(0);

    const [filteredAvaibleTags, setFilteredAvaibleTags] = useState<Tag[]>([]);

    const [start, setStart] = useState({ moduleId: 0, start: false });
    const [search, setSearch] = useState<{ moduleId: number; value: string }>({ moduleId: 0, value: '' });
    //#endregion

    useEffect(() => {
        setFilteredAvaibleTags([...availableTags]);
    }, [availableTags]);
    useEffect(() => {
        refresh();
    }, [props.openModule]);
    useEffect(() => {
        if (tagListRes && tagListRes.payload && tagListRes.payload.content) {
            setAvailableTags(tagListRes.payload.content);
            setFilteredAvaibleTags([...availableTags]);
        }
    }, [tagListRes]);
    useEffect(() => {
        const filteredList = availableTags.filter((at) => at.name.toLowerCase().startsWith(search.value));
        setFilteredAvaibleTags(filteredList);
    }, [search]);

    //#region method
    const refresh = () => {
        dispatch(getFinishTag('CONSTRUCTION_RULE'));
        getCompositionModules();
    };
    const reset = () => {
        setStart({ moduleId: 0, start: false });
        setSearch({ moduleId: 0, value: '' });
        setEnterAngle(0);
    };
    const handleAngleChange = (angle: number, compositionModule3DInfo: CompositionModule3DInfo) => {
        compositionModuleService
            .save3dInfoToCompositionModule({
                ...compositionModule3DInfo,
                metadata: { ...compositionModule3DInfo.metadata, angle },
            })
            .then(() => {
                refresh();
                setEnterAngle(0);
            });
    };
    const getCompositionModules = () => {
        compositionModuleService
            .getCompositionModuleList(props.currentProduct.first.reference)
            .then((res) => {
                setCompositionModules(res.content);
            })
            .catch(() => {
                setCompositionModules([]);
            });
    };
    const createProductTag = (tagName: string, p: CompositionModuleResponse) => {
        reset();
        TagService.createTag('CONSTRUCTION_RULE', tagName).then((res) => {
            compositionModuleTagService.addTagsToCompositionModule(p.compositionModule.associationReference, [...p.tags.map((t) => t.id), res.content.id]).then(() => {
                refresh();
            });
        });
    };

    const removeAlreadyInList = (currentTagList: Tag[]) => {
        const withoutSysTag = filteredAvaibleTags.filter((t) => {
            return t.name !== 'Joker' && t.name !== 'Full' && t.name !== 'Empty';
        });
        return withoutSysTag.filter((ft: Tag) => {
            return !currentTagList.some((ct: Tag) => ct.id === ft.id);
        });
    };
    //#endregion
    return (
        <div className='compo-paper'>
            {props.openModule && (
                <AssociateModule
                    associateModuleList={compositionModules}
                    setOpenModal={props.setOpenModal}
                    compositionReference={props.currentProduct.first.reference}
                    collectionReference={props.currentProduct.first.collection.reference}
                />
            )}
            <table className='compo-tbl-main'>
                <thead className='compo-tbl-head'>
                <tr>
                    {composableTableHead.map((h, i) => {
                        return <td key={i}>{h}</td>;
                    })}
                </tr>
                </thead>
                <tbody className='compo-tbl-body'>
                {compositionModules.map((p) => {
                    return (
                        <tr key={p.product.id}>
                            <td onClick={reset}>
                                <img alt='produit' src={p.product.thumbnailUrl} />
                                <span> {p.product.name}</span>
                            </td>
                            <td className='compo-tagi'>
                                <div className='tag-select'>
                                    <div className='select-input'>
                                        {p.tags.map((t, index) => {
                                            return (
                                                <div key={t.id} className='tag-item'>
                                                    <span className='text'>{t.name.length < 9 ? t.name : `${t.name.substring(0, 8)}...`}</span>
                                                    <img
                                                        alt='poubelle'
                                                        src={close}
                                                        onClick={() => {
                                                            const newTagList = p.tags.filter((tt) => tt.id !== t.id);
                                                            compositionModuleTagService
                                                                .addTagsToCompositionModule(p.compositionModule.associationReference, [...newTagList.map((nt) => nt.id)])
                                                                .then(() => {
                                                                    refresh();
                                                                });
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                        <input
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    if (filteredAvaibleTags.length === 0) {
                                                        createProductTag(search.value, p);
                                                    }
                                                    if (filteredAvaibleTags.length === 1) {
                                                        compositionModuleTagService
                                                            .addTagsToCompositionModule(p.compositionModule.associationReference, [...p.tags.map((t) => t.id), filteredAvaibleTags[0].id])
                                                            .then(() => {
                                                                refresh();
                                                                reset();
                                                            });
                                                    }
                                                }
                                            }}
                                            value={search.moduleId === p.product.id ? search.value : ''}
                                            onChange={(e) => {
                                                setSearch({
                                                    moduleId: p.product.id,
                                                    value: e.target.value.toLowerCase(),
                                                });
                                            }}
                                            onClick={() => {
                                                setStart({ moduleId: p.product.id, start: true });
                                            }}
                                            type='text'
                                        />
                                    </div>
                                    {start.start && start.moduleId === p.product.id && filteredAvaibleTags.length !== 0 && (
                                        <div className='tag-select-option'>
                                            {removeAlreadyInList(p.tags).map((T: Tag) => {
                                                return (
                                                    <div
                                                        key={T.id}
                                                        className='tag-option'
                                                        onClick={() => {
                                                            reset();
                                                            compositionModuleTagService
                                                                .addTagsToCompositionModule(p.compositionModule.associationReference, [...p.tags.map((t) => t.id), T.id])
                                                                .then(() => {
                                                                    refresh();
                                                                });
                                                        }}
                                                    >
                                                        {T.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </td>
                            <td onClick={reset}>{p.product.productType.toLowerCase()}</td>
                            <td
                                className='compo-angle'
                                onClick={() => {
                                    reset();
                                    setEnterAngle(p.product.id);
                                }}
                            >
                                {enterAngle === p.product.id ? (
                                    <div className='angle-input'>
                                        <input
                                            type='number'
                                            defaultValue={p.compositionModule3DInfo.metadata.angle !== null ? p.compositionModule3DInfo.metadata.angle : 0}
                                            onChange={(e) => {
                                                setAngleInput(parseFloat(e.target.value));
                                            }}
                                        />
                                        <img
                                            src={send}
                                            onClick={() => {
                                                handleAngleChange(angleInput, p.compositionModule3DInfo);
                                            }}
                                        />
                                    </div>
                                ) : p.compositionModule3DInfo.metadata.angle !== null ? (
                                    p.compositionModule3DInfo.metadata.angle
                                ) : (
                                    0
                                )}
                            </td>
                            <td onClick={reset}>
                                <img
                                    alt='poubelle'
                                    src={trash}
                                    onClick={() => {
                                        compositionModuleService.removeModuleFromComposition(props.currentProduct.first.reference, p.product.reference).then(() => {
                                            getCompositionModules();
                                        });
                                    }}
                                />
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className='btn-box'>
                <button
                    className='save'
                    onClick={() => {
                        props.setStep(5);
                        props.setFocus(4);
                    }}
                >
                    Suivant
                </button>
            </div>
        </div>
    );
};
export default ComposableModule;
