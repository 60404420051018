import MdfApiResponse from '../../../domain/common/generic';
import { EntityType, Tag } from '../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TagService from '../../../api/services/tag/tag.service';

//<editor-fold desc="get-all-tag">
export type GetFinishTagState = {
    isLoading: boolean;
    payload: MdfApiResponse<Tag[]>;
};

const initialGetAllTagState: GetFinishTagState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const getFinishTag = createAsyncThunk('getFinish/tag', async (entityType: string) => {
    const res = await TagService.getFinishTag(entityType);
    return res;
});

export const getFinishTagSlice = createSlice({
    name: 'getFinishTagSlice',
    initialState: initialGetAllTagState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFinishTag.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getFinishTag.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getFinishTag.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
//</editor-fold>

//<editor-fold desc="get-all-product-tag">

export type GetProductTagState = {
    isLoading: boolean;
    payload: MdfApiResponse<Tag[]>;
};

const initialGetAllProductTagState: GetProductTagState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const getProductTag = createAsyncThunk('getProduct/tag', async () => {
    const res = await TagService.getProductTag();
    return res;
});

export const getProductTagSlice = createSlice({
    name: 'getProductTagSlice',
    initialState: initialGetAllProductTagState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProductTag.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProductTag.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getProductTag.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
//</editor-fold>

//<editor-fold desc="get-all-tag">
export type GetFinishAndColorsByTagState = {
    isLoading: boolean;
    payload: MdfApiResponse<any>;
};

const initialGetFinishAndColorsByTagState: GetFinishAndColorsByTagState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const getFinishAndColorsByTag = createAsyncThunk('getFinishAndColorByTags', async (tagname: string) => {
    const res = await TagService.getFinishAndColorsByTag(tagname);
    return res;
});

export const getFinishAndColorsByTagSlice = createSlice({
    name: 'getFinishAndColorsByTagSlice',
    initialState: initialGetFinishAndColorsByTagState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFinishAndColorsByTag.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getFinishAndColorsByTag.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getFinishAndColorsByTag.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
//</editor-fold>

//<editor-fold desc="get-texture-tag">
export type GetTextureTagState = {
    isLoading: boolean;
    payload: MdfApiResponse<Tag[]>;
};

const initialGetTextureTagState: GetTextureTagState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const getTextureTag = createAsyncThunk('getTexture/tag', async () => {
    const res = await TagService.getTextureTag();
    return res;
});

export const getTextureTagSlice = createSlice({
    name: 'getTextureTagSlice',
    initialState: initialGetTextureTagState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTextureTag.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getTextureTag.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getTextureTag.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
//</editor-fold>

//<editor-fold desc="create-tag">

export type CreateTagState = {
    isLoading: boolean;
    payload: MdfApiResponse<Tag | undefined>;
};

const initialCreateTagState: CreateTagState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};
export const createTag = createAsyncThunk('create/tag', async (data: { tagEntityType: EntityType; tagName: string }) => {
    const res = await TagService.createTag(data.tagEntityType, data.tagName);
    return res;
});
export const createTagSlice = createSlice({
    name: 'createTagSlice',
    initialState: initialCreateTagState,
    reducers: {
        resetCreateTag: (state) => {
            state = initialCreateTagState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createTag.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createTag.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createTag.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateTag } = createTagSlice.actions;
//</editor-fold>

//<editor-fold desc="get-model-anchor-tag">
export type GetModelAnchorTagState = {
    isLoading: boolean;
    payload: MdfApiResponse<Tag[]>;
};

const initialGetModelAnchorTagState: GetModelAnchorTagState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const getModelAnchorTag = createAsyncThunk('getModelAnchorTag/tag', async () => {
    const res = await TagService.getModelAnchorTag();
    return res;
});

export const getModelAnchorTagSlice = createSlice({
    name: 'getModelAnchorTagSlice',
    initialState: initialGetModelAnchorTagState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getModelAnchorTag.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getModelAnchorTag.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getModelAnchorTag.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
//</editor-fold>

//<editor-fold desc="get-model-anchor-tag">
export type GetModelPartItemTagState = {
    isLoading: boolean;
    payload: MdfApiResponse<Tag[]>;
};

const initialGetModelPartItemTagState: GetModelPartItemTagState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const getModelPartItemTag = createAsyncThunk('getModelPartItemTag/tag', async () => {
    const res = await TagService.getPartItemTag();
    return res;
});

export const getModelPartItemTaglice = createSlice({
    name: 'getModelPartItemTag',
    initialState: initialGetModelPartItemTagState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getModelPartItemTag.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getModelPartItemTag.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getModelPartItemTag.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
//</editor-fold>

