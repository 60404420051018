import MdfApiResponse from '../../../domain/common/generic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import groupMappingService from '../../../api/services/mapping-zoning/group-mapping.service';
import { GroupMapping, GroupMappingCreationForm } from '../../../domain/domain';


export type GroupState = {
    isLoading: boolean;
    payload: MdfApiResponse<GroupMapping>;
};
export type GroupListState = {
    isLoading: boolean;
    payload: MdfApiResponse<GroupMapping[] | undefined>;
};
const initialGroupState: GroupState = {
    isLoading: false,
    payload: {
        content: {
            id: 0,
            name: '',
            mappings: [
                {
                    id: 0,
                    version: 0,
                    updatedAt: new Date(),
                    createdAt: new Date(),
                    name: '',
                    zoningMetadata: { zoneList: [] },
                    refOfPartList: [],
                }],
            collectionReference: '',
            updatedAt: new Date(),
            createdAt: new Date(),
            version: 0,
        }, errors: [], warnings: [],
    },
};
const initialGroupListState: GroupListState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

//<editor-fold desc="get-all-groups">
export const getAllGroups = createAsyncThunk('get/all-groups', async (collectionReference: string) => {
    const res = await groupMappingService.getAllGroups(collectionReference);
    return res;
});

export const getAllGroupsSlice = createSlice({
    name: 'getAllGroups',
    initialState: initialGroupListState,
    reducers: {
        resetGetAllGroups: (state) => {
            state = initialGroupListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllGroups.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllGroups.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllGroups.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetAllGroups } = getAllGroupsSlice.actions;

//</editor-fold>

//<editor-fold desc="get-group">
export const getGroup = createAsyncThunk('get/group', async (groupId: number) => {
    const res = await groupMappingService.getGroup(groupId);
    return res;
});

export const getGroupSlice = createSlice({
    name: 'getGroup',
    initialState: initialGroupState,
    reducers: {
        resetGetGroup: (state) => {
            state = initialGroupState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getGroup.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getGroup.fulfilled, (state, { payload }) => {
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(getGroup.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetGroup } = getGroupSlice.actions;
//</editor-fold>

//<editor-fold desc="get-groups">
export const getGroups = createAsyncThunk('get/groups', async () => {
    const res = await groupMappingService.getGroups();
    return res;
});

export const getGroupsSlice = createSlice({
    name: 'getGroups',
    initialState: initialGroupListState,
    reducers: {
        resetGetGroups: (state) => {
            state = initialGroupListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getGroups.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getGroups.fulfilled, (state, { payload }) => {
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(getGroups.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetGroups } = getGroupsSlice.actions;
//</editor-fold>

//<editor-fold desc="create-group">
export const createGroup = createAsyncThunk('create/group', async (data: { data: GroupMappingCreationForm; collectionReference: string }) => {
    const res = await groupMappingService.createGroup(data.collectionReference, data.data);
    return res;
});

export const createGroupSlice = createSlice({
    name: 'createGroup',
    initialState: initialGroupState,
    reducers: {
        resetCreateGroup: (state) => {
            state = initialGroupState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createGroup.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createGroup.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createGroup.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateGroup } = createGroupSlice.actions;
//</editor-fold>

//<editor-fold desc="update-group">
export const updateGroup = createAsyncThunk('update/group', async (data: { data: GroupMappingCreationForm; groupId: number }) => {
    const res = await groupMappingService.updateGroup(data.groupId, data.data);
    return res;
});

export const updateGroupSlice = createSlice({
    name: 'updateGroup',
    initialState: initialGroupState,
    reducers: {
        resetUpdateGroup: (state) => {
            state = initialGroupState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateGroup.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateGroup.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateGroup.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateGroup } = updateGroupSlice.actions;

//</editor-fold>

//<editor-fold desc="delete-group">
export const deleteGroup = createAsyncThunk('delete/group', async (groupId: number) => {
    const res = await groupMappingService.deleteGroup(groupId);
    return res;
});

export const deleteGroupSlice = createSlice({
    name: 'deleteGroup',
    initialState: initialGroupState,
    reducers: {
        resetDeleteGroup: (state) => {
            state = initialGroupState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteGroup.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteGroup.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteGroup.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteGroup } = deleteGroupSlice.actions;
//</editor-fold>
