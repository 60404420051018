import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../../uicomponents/layout/layout';
import MainPaper from '../../uicomponents/paper/main-paper';
import FilterBlock from '../../uicomponents/flilter-block/filter-block';
import SuppliersTable from '../../components/table/suppliers-table/suppliers-table';

const Suppliers = () => {
    const history = useHistory();
    const [status, setStatus] = useState(undefined);
    return (
        <Layout title="Fournisseurs">
            <FilterBlock
                display={false}
                clearFilter={() => {
                    setStatus(undefined);
                }}
                selectedTab={status}
                label="+  Créer un fournisseur"
                news={false}
                onClickfunc={() => {
                    history.push('/create-supplier');
                }}
            />
            <MainPaper>
                <SuppliersTable />
            </MainPaper>
        </Layout>
    );
};
export default Suppliers;
