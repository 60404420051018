import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {TextureType} from "../ParamTypes/TextureType";
import {BooleanType} from "../ParamTypes/BooleanType";
import {ButtonType} from "../ParamTypes/ButtonType";
import {SliderData, SliderType} from "../ParamTypes/SliderType";
import {InspectorType} from "../ParamTypes/InspectorType";

export class Metalness {
    public m_metalness: InspectorType;
    public m_metalnessMapShow: InspectorType;
    public m_metalnessMap: InspectorType;
    public m_metalnessFlipY: InspectorType;
    public m_metalnessClear: InspectorType;
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        //Albedo
        this.m_uiFolder = m_uiFolders = m_tab.pages[0].addFolder({title: 'Metalness (BW) [no texture]', expanded: false});
        this.m_metalness = new SliderType(new SliderData(0.0,1.0,0.01), m_matParams, "metalness", m_uiFolders);
        this.m_metalnessMapShow = new BooleanType("Show map on mesh", m_matParams, "metalnessMapShowOnMesh", m_uiFolders);
        this.m_metalnessMap = new TextureType(MapType.metalnessMap, m_matParams, "metalnessMap", m_uiFolders);
        this.m_metalnessFlipY = new BooleanType("Flip Y", m_matParams, "metalnessMapFlipY", m_uiFolders);
        this.m_metalnessClear = new ButtonType("Clear map", m_matParams, "", m_uiFolders);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_metalness.InitType();
        this.m_metalnessMapShow.InitType();
        this.m_metalnessMap.InitType();
        this.m_metalnessFlipY.InitType();
        this.m_metalnessClear.InitType();
    }
}