import Inspector from "../Inspector";
import {MeshPhysicalMaterial} from "three";
import * as THREE from "three";
import {MapType} from "../MapType";

export function TransmissionBind(this: Inspector) {
    let inspectorParameters = this.m_materialEditorParameters;
    const self: any = this;


    this.m_transmissionSlide?.m_transmissionMapShow.BindType( function (ev: any) {
        //self.showOnMesh('transmissionMap', self.m_currentEditedMaterial.transmissionMap, ev.value);
        if (!self.m_currentEditedMaterial) return;

        let Mat = self.m_materialEditor.showOnMesh(self.m_currentEditedMaterial, inspectorParameters, 'transmissionMap', ev.value);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
    });
    this.m_transmissionSlide?.m_thicknessMapShow.BindType(function (ev: any) {
        //self.showOnMesh('transmissionMap', self.m_currentEditedMaterial.transmissionMap, ev.value);
        if (!self.m_currentEditedMaterial) return;

        let Mat = self.m_materialEditor.showOnMesh(self.m_currentEditedMaterial, inspectorParameters, 'thicknessMap', ev.value);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
    });
    
    this.m_transmissionSlide?.m_transmission.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        let Mat = self.m_materialEditor.SetTransmission(self.m_currentEditedMaterial, ev.value);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });
    this.m_transmissionSlide?.m_transmissionIor.BindType( function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.ior = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });
    this.m_transmissionSlide?.m_transmissionThickness.BindType( function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.thickness = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();

    });
    this.m_transmissionSlide?.m_transmissionMap.BindType(function () {
        if (self.m_materialEditorParameters.transmissionMap.src) {
            self.ToggleSlotDirty();
            self.UpdateMaterialFromUI(MapType.transmissionMap, self.m_materialEditorParameters.transmissionMap, self.m_materialEditorParameters.transmissionMapFlipY, self.m_materialEditorParameters.transmissionMapEncodingDefault);
        } else {
        }
    }); // strange fact : sometimes we receive map as its data, not img...
    this.m_transmissionSlide?.m_transmissionFlipY.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        let Mat;
        if (self.m_currentEditedMaterial) {
            Mat = self.m_materialEditor.FlipYTexture(['transmissionMap'], ev.value);
        }
        if (self.m_materialUpdatedCallback && Mat) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });

    this.m_transmissionSlide?.m_transmissionClear.BindType( () => {
        console.log("Aled");
        if (!self.m_currentEditedMaterial) return;
        let texSlot = self.GetTextureFromSlot(MapType.transmissionMap);
        if (!texSlot) return;
        let Mat = self.m_materialEditor.ClearMap(this.m_materialEditorParameters, [texSlot.slotName]);
        if (this.m_savedMaterial && self.m_currentEditedMaterial && self.m_dirtyGLTFCallback) self.m_dirtyGLTFCallback(this.m_savedMaterial, self.m_currentEditedMaterial);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat.clone() as MeshPhysicalMaterial, true);
        this.m_pane.refresh();
    });


    this.m_transmissionSlide?.m_thicknessMap.BindType(function () {
        if (self.m_materialEditorParameters.thicknessMap.src) {
            self.ToggleSlotDirty();
            self.UpdateMaterialFromUI(MapType.thicknessMap, self.m_materialEditorParameters.thicknessMap, self.m_materialEditorParameters.thicknessMapFlipY, self.m_materialEditorParameters.thicknessMapEncodingDefault);
        } else {
        }
    }); // strange fact : sometimes we receive map as its data, not img...
    this.m_transmissionSlide?.m_thicknessFlipY.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        let Mat;
        if (self.m_currentEditedMaterial) {
            Mat = self.m_materialEditor.FlipYTexture(['thicknessMap'], ev.value);
        }
        if (self.m_materialUpdatedCallback && Mat) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });

    this.m_transmissionSlide?.m_thicknessClear.BindType(() => {
        if (!self.m_currentEditedMaterial) return;
        let texSlot = self.GetTextureFromSlot(MapType.thicknessMap);
        if (!texSlot) return;
        let Mat = self.m_materialEditor.ClearMap(this.m_materialEditorParameters, [texSlot.slotName]);
        if (this.m_savedMaterial && self.m_currentEditedMaterial && self.m_dirtyGLTFCallback) self.m_dirtyGLTFCallback(this.m_savedMaterial, self.m_currentEditedMaterial);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat.clone() as MeshPhysicalMaterial, true);
        this.m_pane.refresh();
    });

}