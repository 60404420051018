import React, { useEffect, useState } from 'react';
import Textfield from '../textfield/textfield';
import { useHistory } from 'react-router-dom';
import { CreateNewsType } from '../../uicomponents/create-news-form/create-news.type';

import { News, NewsCategory, Role } from '../../domain/domain';

import SelectDropdown, { SelectDropdownItemType } from '../select-dropdown';
import UseForm from '../form/form';
import ImageUpload from '../image-upload/image-upload';
import RichEditor from '../rich-editor/rich-editor';
import Paper from '../paper/paper';
import LayoutSplit from '../layout/layout-split';
import LayoutSidebar from '../layout/Layout-sidebar/layout-sidebar';
import ToggleSwitch from '../toogleSwitch/toggle-switch';
import Preview from '../preview/preview';

import { useDispatch } from 'react-redux';

import apiClient from '../../api/api-client';

import { GET_ROLES } from '../../api/endpoints/role/role.endpoint';
import MdfPreconditions from '../../utils/MdfPreconditions';
import MdfApiResponse from '../../domain/common/generic';

import apercu from '../../asset/Apercu.svg';
import send from '../../asset/Envoyer blanc.svg';
import programmer from '../../asset/Programmer blanc.svg';
import '../../uicomponents/create-news-form/styles.scss';
import Modal, { ModalSize } from '../modal/modal';
import { addModal, removeModal } from '../../services/reducers/modal/modal.slice';
import TimePickerUi from '../timepicker/timepicker';
import { resetUpdateNews } from '../../services/reducers/news/news.slice';
import DateFormatter from '../../utils/date-formatter';
import calendar from '../../asset/calendar.png';
import { setToasterVisible } from '../../services/reducers/toaster-reducer/toaster.slice';
import { Upload } from '../../utils/upload-file-method';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import DatePicker, { getDefaultLocale, registerLocale, setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import moment from 'moment';

type UpdateNewsFormProps = {
    onSubmit: (form: CreateNewsType) => void;
    categoryList: NewsCategory[];
    reloadCategories: () => void;
    newsData: News;
};

const UpdateNewsForm = ({ onSubmit, categoryList, reloadCategories, newsData }: UpdateNewsFormProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [title, setTitle] = useState(newsData.title);
    const [message, setMessage] = useState(newsData.content);
    const [recipient, setRecipient] = useState(String(newsData.roleRecipientId));
    const [criticality, setCriticality] = useState(newsData.criticality.toString());
    const [category, setCategoryId] = useState<number | null>(newsData.newsCategoryId);
    const [scheduledDate, setScheduledDate] = useState<Date | null>(null);
    const [expiredDate, setExpiredDate] = useState<Date | null>(newsData.expiredDate);
    const [status, setStatus] = useState(newsData.status);
    const [textErrorMessage, settextErrorMessage] = useState<string>('');
    const [contentErrorMessage, setContentErrorMessage] = useState<string>('');
    const [categoryErrorMessage, setCategoryErrorMessage] = useState<string>('');

    const [isTextError, setIsTextError] = useState<boolean>(false);
    const [isRichError, setIsRichError] = useState<boolean>(false);

    const [imageFile, setImageFile] = useState<any[]>(newsData.documentUrls);
    const [imageUrl, setImageUrl] = useState<string[]>([]);

    const [preview, setPreview] = useState(false);
    const [roleList, setRoleList] = useState<Role[]>([]);

    const [openImg, setOpenImg] = useState(false);
    const [imgUrlToPreview, setImgUrlToPreview] = useState('');
    const [, setValidate] = useState(true);

    const [imgUrlsToAdd, setImgUrlsToAdd] = useState<any>([]);
    const [imgUrlsToPush, setImgUrlsToPush] = useState<string[]>([]);
    const [imgUrlsToDelete, setImgUrlsToDelete] = useState<any>([]);
    const [titleLayout, setTitleLayout] = useState<string>('Editer la Publication');
    const [isScheduled, setIsScheduled] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    registerLocale('french', fr);
    setDefaultLocale('french');
    const [expiredDateErrorMessage, setExpiredDateErrorMessage] = useState<string>('');
    const [isExpiredDateChange, setIsExpiredDateChange] = useState<boolean>(false);

    // @ts-ignore
    const data = {
        title: title,
        content: message,
        criticality: criticality,
        createdAt: DateFormatter.dateTimeStampToDateTime(newsData.createdAt),
        documentsUrl: imageUrl,
        newsCategoryId: category,
        publicationDate: DateFormatter.dateTimeStampToDateTime(newsData.publicationDate),
        archivingDate: DateFormatter.dateTimeStampToDateTime(newsData.archivingDate),
        scheduledDate: DateFormatter.dateTimeStampToDateTimeWithHours(newsData.scheduledDate),
        expiredDate: DateFormatter.dateTimeStampToDateTimeWithHours(newsData.expiredDate),
        roleRecipientId: roleList,
    };

    const getRoleList = async () => {
        const res = await apiClient(GET_ROLES, undefined, undefined, 'GET');
        MdfPreconditions.continueIfNoErrors(res as MdfApiResponse<any>, [], dispatch, () => {
            setRoleList(res.content);
            setRoleList((prevItemsRole) => [
                {
                    name: 'Tous',
                    permissions: [],
                    id: parseInt('-100'),
                    version: 0,
                    label: 'Tous',
                },
                ...prevItemsRole,
            ]);
        });
    };

    const categoryLoadedUpdate: SelectDropdownItemType = { id: -1, label: ' * Choisir une catégorie' };
    const loadedCategory = () => {
        categoryList
            .filter((categ) => categ.id === category)
            .map((el) => {
                categoryLoadedUpdate.id = String(el.id);
                categoryLoadedUpdate.label = el.name;
            });
        return categoryLoadedUpdate;
    };

    const roleLoadedUpdate: SelectDropdownItemType = { id: '-100', label: 'Tous' };
    const loadedRole = () => {
        roleList
            .filter((role) => role.id === parseInt(recipient))
            .map((el) => {
                roleLoadedUpdate.id = el.id;
                roleLoadedUpdate.label = el.label;
            });
        return roleLoadedUpdate;
    };

    const unScheduled = () => {
        setStatus(`PUBLISHED`);
        setScheduledDate(null);
        setExpiredDate(null);
        setIsScheduled(false);
        setTitleLayout('Editer le Brouillon');
    };

    const checkErrors = () => {
        if (title.length === 0 || message.length === 0 || category === null) {
            if (title.length === 0) {
                settextErrorMessage('Le titre doit être specifié');
                setIsTextError(true);
            }
            if (message.length === 0) {
                setContentErrorMessage('Le message doit être specifié');
                setIsRichError(true);
            }
            if (category === null) {
                setCategoryErrorMessage('La categorie doit être specifié');
            }
            return false;
        }
        if (newsData.status === 'ARCHIVED' && status === 'PUBLISHED') {
            if (Number(expiredDate) < Date.now() || expiredDate === null) {
                setExpiredDateErrorMessage("Entrer une date et une heure supérieur à la date et l'heure du jour");
                return false;
            }
        }
        return true;
    };

    const handleNewsSubmit = () => {
        if (checkErrors()) {
            let finalScheduledDate: Date | null = null;
            if (isScheduled) {
                finalScheduledDate = newsData.scheduledDate;
            } else if (scheduledDate !== null) {
                finalScheduledDate = scheduledDate;
            }
            if (imageFile.length === 0 || (imageFile.length > 0 && imgUrlsToPush.length > 0)) {
                onSubmit({
                    title,
                    content: message,
                    criticality,
                    newsStatus: status,
                    newsCategoryId: category,
                    documentsUrl: imgUrlsToPush,
                    recipientId: recipient,
                    scheduledDate: finalScheduledDate,
                    expiredDate: expiredDate,
                });
                if (status !== 'SCHEDULED') {
                    dispatch(
                        setToasterVisible({
                            message: `La news a été créée`,
                            title: 'News créée',
                        })
                    );
                }
                if (status === 'SCHEDULED') {
                    // @ts-ignore
                    console.log('MONTH : ', scheduledDate?.getMonth());
                    let jourMois;
                    let scheduledMinutes = scheduledDate?.getMinutes();
                    let scheduledHours = scheduledDate?.getHours();
                    // @ts-ignore
                    let scheduledMonth = scheduledDate?.getMonth() + 1;
                    let min;
                    if (scheduledMonth < 10) {
                        jourMois = scheduledDate?.getDate() + '/0' + scheduledMonth.toString(10);
                    } else {
                        jourMois = scheduledDate?.getDate() + '/' + scheduledMonth.toString(10);
                    }
                    if (scheduledMinutes === 0) {
                        min = scheduledMinutes.toString(10) + '0';
                    } else {
                        min = scheduledMinutes;
                    }
                    let heure;
                    if (scheduledHours === 0) {
                        heure = scheduledHours.toString(10) + '0';
                    } else {
                        // @ts-ignore
                        if (scheduledHours < 10) {
                            // @ts-ignore
                            heure = '0' + scheduledHours.toString(10);
                        } else {
                            heure = scheduledDate?.getHours();
                        }
                    }
                    let heuresMin = heure + ':' + min;
                    dispatch(
                        setToasterVisible({
                            message: 'Elle sera programmée le ' + jourMois + ' à ' + heuresMin,
                            title: 'News bien programmée',
                        })
                    );
                }
                history.push('/home/news');
            }
        }
    };

    const scheduledDateModal = 'confirmation-scheduled-date-open';
    const handleChooseScheduledDate = () => {
        setStatus('SCHEDULED');
        dispatch(removeModal(scheduledDateModal));
        handleNewsSubmit();
    };

    useEffect(() => {
        dispatch(resetUpdateNews());
        getRoleList();
        if (newsData.scheduledDate !== null) {
            setIsScheduled(!isScheduled);
        } else {
            setScheduledDate(null);
        }
        setImgUrlsToPush(imageFile);
    }, []);

    useEffect(() => {
        if (imageUrl && imageUrl.length > 0) {
            if (imageUrl.length === 1 && isAdding) {
                setImgUrlsToPush((prevItems) => [...prevItems, imageUrl[0]]);
            }
        }
    }, [imageUrl]);

    useEffect(() => {
        const formData = new FormData();
        if (isAdding) {
            if (imageFile.length > 0 && imageFile[imageFile.length - 1].name !== undefined && imageFile[imageFile.length - 1].name === imgUrlsToAdd.name) {
                formData.append('files', imageFile[imageFile.length - 1]);
                Upload(formData, setImageUrl);
            }
        }

        if (isAdding === false && imgUrlsToPush.length > 0) {
            if (typeof imgUrlsToDelete === 'string') {
                setImgUrlsToPush(imgUrlsToPush.filter((ur: any) => ur !== imgUrlsToDelete));
            } else {
                setImgUrlsToPush(imgUrlsToPush.filter((ur: any) => ur.substring(ur.lastIndexOf('/') + 1).substring(ur.substring(ur.lastIndexOf('/') + 1).indexOf('-') + 1) !== imgUrlsToDelete.name));
            }
        }
    }, [imageFile]);

    const parseDateForDatepicker = () => {
        if (expiredDate !== null && !isExpiredDateChange) {
            const numberDate = Number(expiredDate);
            const formatted = moment.unix(numberDate).format('MM/DD/yyyy HH:mm');
            return new Date(formatted);
        }
        if (expiredDate !== null && isExpiredDateChange) {
            return expiredDate;
        }
        return null;
    };

    return (
        <>
            {openImg && (
                <div className="img-preview" onClick={() => setOpenImg(false)}>
                    <DocViewer pluginRenderers={DocViewerRenderers} documents={[{ uri: imgUrlToPreview }]} />
                </div>
            )}
            <LayoutSplit
                title={titleLayout}
                scheduledProgramDate={data.scheduledDate}
                unScheduled={unScheduled}
                isScheduled={isScheduled}
                labelBack="Quitter la modification de news ?"
                info="Les modifications ne seront pas sauvegardées"
                sideBar={
                    <LayoutSidebar>
                        <Paper size={320}>
                            <ToggleSwitch title="IMPORTANCE DE LA PUBLICATION" setValue={setCriticality} value={criticality} />
                        </Paper>
                        <Paper size={320}>
                            <SelectDropdown
                                color="#F8A12D"
                                error={categoryErrorMessage}
                                setError={setCategoryErrorMessage}
                                defaultItem={loadedCategory()}
                                items={categoryList.map((el) => {
                                    return {
                                        id: el.id,
                                        label: el.name,
                                    };
                                })}
                                title="CATEGORIE DE LA PUBLICATION"
                                onChange={(el) => {
                                    setCategoryId(parseInt(el));
                                }}
                            />
                        </Paper>
                        {newsData.status.toString() === 'PUBLISHED' ||
                            newsData.status.toString() === 'SCHEDULED' ||
                            (newsData.status.toString() === 'ARCHIVED' && (
                                <Paper size={320}>
                                    <span style={{ color: '#889AAB', fontWeight: 'bolder', fontSize: '11px' }}>DATE DE VALIDITE</span>
                                    <DatePicker
                                        onChange={(date) => {
                                            // @ts-ignore
                                            if (date < Date.now()) {
                                                setExpiredDate(null);
                                                setExpiredDateErrorMessage("Entrer une date et une heure supérieur à la date et l'heure du jour");
                                            } else {
                                                setIsExpiredDateChange(true);
                                                setExpiredDateErrorMessage('');
                                                // @ts-ignore
                                                setExpiredDate(date);
                                            }
                                        }}
                                        isClearable
                                        showTimeSelect
                                        locale={getDefaultLocale()}
                                        selected={parseDateForDatepicker()}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        timeCaption="time"
                                        timeFormat="HH:mm"
                                        minDate={new Date()}
                                        placeholderText="Choose a expired date"
                                    />
                                    {expiredDateErrorMessage && <div className="errorMess">{expiredDateErrorMessage}</div>}
                                </Paper>
                            ))}
                    </LayoutSidebar>
                }
            >
                <div className="create-news-main">
                    <Modal
                        validateLabel="Programmer"
                        closeLabel="Annuler"
                        onClick={() => {
                            handleChooseScheduledDate();
                        }}
                        id={scheduledDateModal}
                        title="Programmer la publication"
                        setScheduledDate={setScheduledDate}
                        size={ModalSize.XLARGE}
                        colorValidateButton="#2252CD"
                        icon={calendar}
                    >
                        <TimePickerUi name="scheduledDateProgramming" title="ProgramDate" setScheduledDate={setScheduledDate} />
                    </Modal>
                    <Paper>
                        <UseForm>
                            <SelectDropdown
                                title="DESTINATAIRE"
                                items={roleList
                                    .filter((role) => role.label === 'Vendeur' || role.label === 'Responsable magasin' || role.label === 'Tous')
                                    .map((el) => {
                                        return {
                                            id: el.id,
                                            label: el.label,
                                        };
                                    })}
                                onChange={(value) => setRecipient(value)}
                                defaultItem={loadedRole()}
                            />

                            <div>
                                <Textfield
                                    label="TITRE"
                                    isError={isTextError}
                                    errorMessage={textErrorMessage}
                                    validation="min:4"
                                    name={title}
                                    value={title}
                                    placeholder="Titre de votre news"
                                    onChange={(value) => {
                                        setTitle(value);
                                        settextErrorMessage('');
                                        setIsTextError(false);
                                    }}
                                />
                            </div>

                            <RichEditor label="MESSAGE" text={message} setText={setMessage} isError={isRichError} errorMessage={contentErrorMessage} setErrorMessage={setContentErrorMessage} />

                            <ImageUpload
                                setValidate={setValidate}
                                imageUrl={imageUrl}
                                setImageUrl={setImageUrl}
                                imageFile={imageFile}
                                setImageFile={setImageFile}
                                data={newsData}
                                setIsAdding={setIsAdding}
                                setImgUrlsToDelete={setImgUrlsToDelete}
                                setImgUrlsToAdd={setImgUrlsToAdd}
                            />

                            <div className="btns">
                                <div
                                    className="preview-container"
                                    onClick={() => {
                                        setPreview(true);
                                    }}
                                >
                                    <img alt="logo apercu" src={apercu} />
                                    <span>Aperçu</span>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {isScheduled.valueOf() == true ? (
                                        <div className="send-btn">
                                            <button
                                                onClick={() => {
                                                    handleNewsSubmit();
                                                }}
                                            >
                                                Sauvegarder
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <div
                                                className="draft"
                                                onClick={() => {
                                                    setStatus(`DRAFT`);
                                                    handleNewsSubmit();
                                                }}
                                            >
                                                Enregistrer en brouillon
                                            </div>
                                            <div className="send-btn">
                                                <button
                                                    onClick={() => {
                                                        setStatus(`PUBLISHED`);
                                                        handleNewsSubmit();
                                                    }}
                                                >
                                                    <img alt="logo publier" src={send} />
                                                    Publier
                                                </button>
                                            </div>
                                            <div className="send-btn">
                                                <button
                                                    onClick={() => {
                                                        dispatch(addModal(scheduledDateModal));
                                                    }}
                                                >
                                                    <img alt="logo publier" src={programmer} />
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </UseForm>
                    </Paper>
                    {preview && (
                        // @ts-ignore
                        <Preview setOpen={setOpenImg} open={openImg} setImgUrl={setImgUrlToPreview} imageFile={imageFile} setPreview={setPreview} data={newsData} />
                    )}
                </div>
            </LayoutSplit>
        </>
    );
};

export default UpdateNewsForm;
