import { Engine } from '@lutithree/build/Engine';
import {
    Euler,
    ImageUtils,
    Material,
    Mesh,
    MeshPhysicalMaterial,
    Object3D,
    PlaneGeometry, Spherical,
    Vector2,
    Vector3
} from 'three';
import { MeshFilterComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent';
import { MeshRendererComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent';
import { Guid } from 'guid-typescript';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { ModelLoader } from '@lutithree/build/Modules/WebGL/Resources/Load/ModelLoader';
import { CameraComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Rendering/CameraComponent';
import {CameraSystem} from "@lutithree/build/Modules/WebGL/Scene/Systems/CameraSystem";
import {LoadingManager} from "@lutithree/build/Modules/WebGL/Resources/Load/LoadingManager";
import {BackgroundService} from "./BackgroundService";

export class TargetMeshService {
    private m_engine: Engine;
    private m_backgroundService: BackgroundService;
    private m_customObjectID: Guid | undefined;
    
    public constructor(p_engine: Engine, p_backgroundService: BackgroundService) {
        if (p_engine == null) throw new Error('NullReference Exception: p_engine is null or undefined');
        var oldF = ImageUtils.getDataURL;

        ImageUtils.getDataURL = function (image) {
            if (/^https:/i.test((image as any).src)) {
                return (image as any).src + "?_";
            }

            if (/^http:/i.test((image as any).src))
            {
                return (image as any).src + "?_";
            }
            return oldF(image);
        }
        this.m_backgroundService = p_backgroundService;
        this.m_engine = p_engine;
    }

    public set CustomObjectID(value: Guid)
    {
        this.m_customObjectID = value;
    }
    
    public RequestRender()
    {
        this.m_engine.Modules.LoopStrategy.RequestRender(false);
    }
    
    public RemoveCustomObjectFromScene()
    {
        if(this.m_customObjectID)this.m_engine.Modules.Scene.RemoveEntityByID(this.m_customObjectID);
    }
    
    public TakeScreenShot(p_positionOfCamera: Vector3, p_targetentityID: Guid, p_baseentityID: Guid, p_list: Guid[], p_screenCallback: (p_blob: Blob) => void) {
        const camOriginPos = this.m_engine.Modules.Scene.GetEntitesWithComponents([CameraComponent])[0].GetComponentOfType(CameraComponent).GetObject().position.clone();
        let initnialRot = this.m_engine.Modules.Scene.GetEntityByID(p_list[0]).Transform.GetObject().rotation.y;
        let initnialBackgroudRough = this.m_backgroundService.GetBackgroundRoughness();
        this.m_engine.Modules.Snapshot.TakeSnapshot(new Vector2(512,512), ()=>{
            let newPos = new Spherical().setFromVector3(p_positionOfCamera);
            newPos.theta = newPos.theta + 0.95;
            this.m_engine.Modules.Scene.GetEntitesWithComponents([CameraComponent])[0].GetComponentOfType(CameraComponent).GetObject().position.setFromSpherical(newPos);
            this.m_engine.Modules.Scene.GetEntitesWithComponents([CameraComponent])[0].GetComponentOfType(CameraComponent).GetObject().lookAt(0,0.35,0);
            this.RotateAllObjects(p_list, 0.95);
            this.m_backgroundService.SetBackgroundRougness(0.5);
            this.SetCurrentMeshType(p_targetentityID, p_list, ()=>{}, false);
        }, ()=>{
            this.SetCurrentMeshType(p_baseentityID, p_list, ()=>{}, false);
            let test = this.m_engine.Modules.Systems.CameraSystem.GetMainCameraDatas().control?.target;
            this.m_engine.Modules.Scene.GetEntitesWithComponents([CameraComponent])[0].GetComponentOfType(CameraComponent).Position = camOriginPos;
            this.m_backgroundService.SetBackgroundRougness(initnialBackgroudRough);
            if(test) this.m_engine.Modules.Scene.GetEntitesWithComponents([CameraComponent])[0].GetComponentOfType(CameraComponent).GetObject().lookAt(test.x, test.y, test.z);
            this.RotateAllObjects(p_list, initnialRot);
        }).then((obj)=>{
            p_screenCallback(obj);
        });
    }
    
    public FocusObject(p_object: Object3D, p_fitRatio = 1.2){
        new CameraSystem(this.m_engine.Modules.Scene).FocusMainCameraOnObject([p_object], p_fitRatio)
    }
    
    public LoadFileFromURL(p_url: File, p_loadedFileCallback: ()=>void){
        let customEntity = this.m_engine.Modules.Scene.CreateEntity('CustomEntity');
        let test = URL.createObjectURL(p_url);
        let modelLoader = LoadingManager.Instance.GltfLoader;
        modelLoader.load(test, (object) =>
        {
            let meshFilter = customEntity.AddComponentOfType(MeshFilterComponent, object.scene);
            object.scene.traverse((object) => {
                if (object.type == "Mesh")
                    (object as Mesh).geometry.computeTangents();
            });
            customEntity.AddComponentOfType(MeshRendererComponent, true, true).AddMeshFilter(meshFilter);
            p_loadedFileCallback();
        });
        customEntity.Enable(false);
        return customEntity.Id;
    }
    
    public SetCurrentMeshType(p_meshID: Guid, p_EntityList: Guid[], OnEntityInvalidCallback: (p_id: Guid)=>void, p_focus: boolean = true, p_fitRatio = 1.2): SceneEntity {
        if (!p_meshID) throw new Error('NullReference Exception: p_meshType is null or undefined or empty');
        try
        {
                
            p_EntityList.forEach((id) => {
                try
                {
                this.m_engine.Modules.Scene.GetEntityByID(id).Enable(false);
                }
                catch (e)
                {
                    p_EntityList.splice(p_EntityList.indexOf(id), 1);
                    OnEntityInvalidCallback(id);
                }
            });
        
            let Guid = p_meshID;
            let Entity;
            if(Guid)
                Entity = this.m_engine.Modules.Scene.GetEntityByID(Guid);
            if(!Entity)
                throw new Error("Entity did not exist");
            
            Entity?.Enable(true);
            if(Entity && p_focus) this.FocusObject(Entity.Transform.GetObject(), p_fitRatio)
            this.m_engine.Modules.LoopStrategy.RequestRender(true);
            return Entity;
        } catch (e)
        {
            throw new Error("There was a problem finding the current mesh type");
        }
    }

    public GenerateDuplicateOfMatWithRAL(p_matAsJson: JSON, p_colorVector: Vector3){
        let p_matAsJsonClone = JSON.parse(JSON.stringify(p_matAsJson));
        if(p_matAsJsonClone.color)
            p_matAsJsonClone.color = p_colorVector.x * 65536 + p_colorVector.y * 256 + p_colorVector.z;
        return p_matAsJsonClone;
    }
    
    public SetMaterialOnEntities(p_meshesToApply: Guid[], p_material: Material): void {
        if (p_material == null) throw new Error('NullReference Exception: p_material is null or undefined');
        
        p_meshesToApply.forEach((sceneEntity, key) => {
            try
            {
                let entity = this.m_engine.Modules.Scene.GetEntityByID(sceneEntity);
                if (entity.HasComponentOfType(MeshRendererComponent)) {
                    entity.GetComponentOfType(MeshRendererComponent).Material = p_material;
                }
            }catch (e)
            {
                console.warn("error in file TargetMeshService : " + e);
            }
        });
        this.m_engine.Modules.LoopStrategy.RequestRender(true);
    }

    public RotateAllObjects(p_meshesToApply: Guid[], p_rotation: number): void {
        p_meshesToApply.forEach((sceneEntity, key) => {
            try
            {
                let entity = this.m_engine.Modules.Scene.GetEntityByID(sceneEntity);
                entity.Transform.SetRotation(new Euler(0, p_rotation,0));
            }catch (e)
            {
                console.warn("Network error probably in file TargetMeshService at line 101");
            }
        });
        this.m_engine.Modules.LoopStrategy.RequestRender(true);
    }

    ExportToJSON(p_Mesh: Guid, p_returnCallback: (p_gltf: JSON) => void) {
        let mat = this.m_engine.Modules.Scene.GetEntityByID(p_Mesh)?.GetComponentOfType(MeshRendererComponent).Material as Material;
        let mesh = new Mesh(new PlaneGeometry(), mat);
        mesh.geometry.computeTangents();
        let json = mesh.toJSON();
        let material: MeshPhysicalMaterial | undefined;
        mesh?.traverse((obj)=>{
            if(obj.type === "Mesh")
                material = (obj as Mesh).material as MeshPhysicalMaterial;
        });
        let meta = {images:{}, textures:{}};
        let texJson = material?.sheenColorMap?.toJSON(meta);
        if(texJson)
        {
            if(json.images == undefined || json.images.length <= 0) {
                json.images = [];
            }
            if(!json.textures)
                json.textures = [];
            json.materials[0].sheenColorMap = (texJson as any).uuid;
            json.images.push((meta.images as any)[(texJson as any).image]);
            json.textures.push((meta.textures as any)[(texJson as any).uuid]);
        }
        if(json.images)
        {
            json.images.forEach((image: any) =>
            {
                image.url = image.url.split("?_")[0];
            });
        }
        if(!json.materials[0].emissiveIntensity)
            json.materials[0].emissiveIntensity = material?.emissiveIntensity;
        p_returnCallback(json);
    }

    public BuildBoxMesh(p_loadedCallback: (p_id: Guid)=>void): Guid {
        let boxEntity = this.m_engine.Modules.Scene.CreateEntity('BoxEntity');
        let modelLoader = new ModelLoader('https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/MatEditor/BOX.gltf');
        modelLoader.LoadAsync().then((object) => {
            let meshFilter = boxEntity.AddComponentOfType(MeshFilterComponent, object);
            object.traverse((object)=>{
                if(object.type == "Mesh")
                    (object as Mesh).geometry.computeTangents();
            });
            boxEntity.AddComponentOfType(MeshRendererComponent, true, true).AddMeshFilter(meshFilter);
            p_loadedCallback(boxEntity.Id);
        });
        boxEntity.Transform.AddToPosition(new Vector3(0,0,0))
        boxEntity.Enable(false);
        return boxEntity.Id;
    }

    public BuildSphereMesh(p_loadedCallback: (p_id: Guid)=>void): Guid {
        let SphereEntity = this.m_engine.Modules.Scene.CreateEntity('SphereEntity');
        let modelLoader = new ModelLoader('https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/MatEditor/Sphere.glb');
        modelLoader.LoadAsync().then((object) => {
            let meshFilter = SphereEntity.AddComponentOfType(MeshFilterComponent, object);
            object.traverse((object) => {
                if (object.type == "Mesh")
                    (object as Mesh).geometry.computeTangents();
            });
            SphereEntity.AddComponentOfType(MeshRendererComponent, true, true).AddMeshFilter(meshFilter);
            p_loadedCallback(SphereEntity.Id);
        });
        SphereEntity.Transform.AddToPosition(new Vector3(0,0,0))
        SphereEntity.Enable(false);
        return SphereEntity.Id;
    }
    
    public BuildFabricMesh(p_loadedCallback: (p_id: Guid)=>void): Guid {
        let fabricEntity = this.m_engine.Modules.Scene.CreateEntity('FabricEntity');
        let modelLoader = new ModelLoader('https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/MatEditor/CLOTH.gltf');
        modelLoader.LoadAsync().then((object) => {
            let meshFilter = fabricEntity.AddComponentOfType(MeshFilterComponent, object);
            object.traverse((object) => {
                if (object.type == "Mesh")
                    (object as Mesh).geometry.computeTangents();
            });
            fabricEntity.AddComponentOfType(MeshRendererComponent, true, true).AddMeshFilter(meshFilter);
            p_loadedCallback(fabricEntity.Id);
        });
        fabricEntity.Transform.AddToPosition(new Vector3(0,0,0))
        fabricEntity.Enable(false);
        return fabricEntity.Id;
    }

    public BuildFinalMesh(p_loadedCallback: (p_id: Guid) => void): Guid {
        let MultiEntity = this.m_engine.Modules.Scene.CreateEntity('SofaEntity');
        let modelLoader = new ModelLoader('https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/MatEditor/TREVI_CANAPE-3P.glb');
        modelLoader.LoadAsync().then((object) => {
            let meshFilter = MultiEntity.AddComponentOfType(MeshFilterComponent, object);
            object.traverse((object) => {
                if (object.type == "Mesh")
                    (object as Mesh).geometry.computeTangents();
            });
            MultiEntity.AddComponentOfType(MeshRendererComponent, true, true).AddMeshFilter(meshFilter);
            p_loadedCallback(MultiEntity.Id);
        });
        MultiEntity.Transform.AddToPosition(new Vector3(0,0,0))
        MultiEntity.Enable(false);
        return MultiEntity.Id;
    }

    public BuildPalaceMesh(p_loadedCallback: (p_id: Guid) => void): Guid {
        let MultiEntity = this.m_engine.Modules.Scene.CreateEntity('PalaceEntity');
        let modelLoader = new ModelLoader('https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/MatEditor/PALACE_MAXICANAPE-3P.glb');
        modelLoader.LoadAsync().then((object) => {
            let meshFilter = MultiEntity.AddComponentOfType(MeshFilterComponent, object);
            object.traverse((object) => {
                if (object.type == "Mesh")
                    (object as Mesh).geometry.computeTangents();
            });
            MultiEntity.AddComponentOfType(MeshRendererComponent, true, true).AddMeshFilter(meshFilter);
            p_loadedCallback(MultiEntity.Id);
        });
        MultiEntity.Transform.AddToPosition(new Vector3(0,0,0))
        MultiEntity.Enable(false);
        return MultiEntity.Id;
    }

    public BuildPlateauMesh(p_loadedCallback: (p_id: Guid) => void): Guid {
        let MultiEntity = this.m_engine.Modules.Scene.CreateEntity('PlateauEntity');
        let modelLoader = new ModelLoader('https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/MatEditor/CONCEPT-TABLE_TABLE-SEMIOVALE90-200-FERMEE-P180.glb');
        modelLoader.LoadAsync().then((object) => {
            let meshFilter = MultiEntity.AddComponentOfType(MeshFilterComponent, object);
            object.traverse((object) => {
                if (object.type == "Mesh")
                    (object as Mesh).geometry.computeTangents();
            });
            MultiEntity.AddComponentOfType(MeshRendererComponent, true, true).AddMeshFilter(meshFilter);
            p_loadedCallback(MultiEntity.Id);
        });
        MultiEntity.Transform.AddToPosition(new Vector3(0,0,0))
        MultiEntity.Enable(false);
        return MultiEntity.Id;
    }

    public BuildCloth2Mesh(p_loadedCallback: (p_id: Guid) => void): Guid {
        let MultiEntity = this.m_engine.Modules.Scene.CreateEntity('Cloth2Entity');
        let modelLoader = new ModelLoader('https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/MatEditor/CLOTH_V2.gltf');
        modelLoader.LoadAsync().then((object) => {
            let meshFilter = MultiEntity.AddComponentOfType(MeshFilterComponent, object);
            object.traverse((object) => {
                if (object.type == "Mesh")
                    (object as Mesh).geometry.computeTangents();
            });
            MultiEntity.AddComponentOfType(MeshRendererComponent, true, true).AddMeshFilter(meshFilter);
            p_loadedCallback(MultiEntity.Id);
        });
        MultiEntity.Transform.AddToPosition(new Vector3(0,0,0))
        MultiEntity.Enable(false);
        return MultiEntity.Id;
    }

    public BuildFurnitureMesh(p_loadedCallback: (p_id: Guid) => void): Guid {
        let MultiEntity = this.m_engine.Modules.Scene.CreateEntity('FurnitureEntity');
        let modelLoader = new ModelLoader('https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/MatEditor/Meuble.gltf');
        modelLoader.LoadAsync().then((object) => {
            let meshFilter = MultiEntity.AddComponentOfType(MeshFilterComponent, object);
            object.traverse((object) => {
                if (object.type == "Mesh")
                    (object as Mesh).geometry.computeTangents();
            });
            MultiEntity.AddComponentOfType(MeshRendererComponent, true, true).AddMeshFilter(meshFilter);
            p_loadedCallback(MultiEntity.Id);
        });
        MultiEntity.Transform.AddToPosition(new Vector3(0,0,0))
        MultiEntity.Enable(false);
        return MultiEntity.Id;
    }
}
