import './configurateur-detail.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMappingMz } from '../../../../../../../services/reducers/mapping-zoning/mappingMz.reducer';
import { RootState } from '../../../../../../../services/root-reducer';
import { ExecuteRuleState } from '../../../../../../../services/reducers/product/rules/rules.reducers';
import { ProductCellValueTreeState } from '../../../../../../../services/reducers/product/3DCells/3DCellValues.reducers';
import { Colori, FinishDTO, MappingConstraintResponse, MappingDTO, MdfApiResponse, ModelTree, PartItemTree, PartTree, PresetTree, TagDTO, ZoneDTO } from '../../../../../../../domain/domain';
import TabNav from '../../tabNav/tabNav';
import Atom from '../../atom/atom-v2';
import arrowLeft from '../../../../../../../asset/arrowLeft.svg';
import { ConfiguredProductTreeListState } from '../../../../../../../services/reducers/product/configured-product/configured-product.reducers';
import mappingConstraintService from '../../../../../../../api/services/mapping-zoning/mappingConstraint.service';
import { CurrentMaterial } from '../../create-preset/create-preset-second-step-V2';
import { atomIsMapping } from '../../../../../../../utils/room-object-factory-v2';
import isMount from '../../../../../../IsMount';
import cells3dValuesService from '../../../../../../../api/services/products/3DCells/3DCellsValues.service';

type DetailConfigurateurProps = {
    onAtomClick: (value: ModelTree) => void;
    preset: PresetTree | undefined;
    sectionSelected: PartTree;
    setOnclickModified: (isClicked: boolean) => void;
    currentConfiguration: ModelTree[];
    onMappingClick: (sectionSelected: PartTree, mapping: MappingDTO) => void;
    onColoriClick: (sectionSelected: PartTree, mapping: MappingDTO, zone: ZoneDTO, C: Colori) => void;
    onclickModifed: boolean;
    getCellValuesTreeRes?: ProductCellValueTreeState;
    setIsMount: (isMount: boolean) => void;
    isMount: boolean;
    atomAvailable: boolean;
    setAtomAvailable: (atomAvailable: boolean) => void;
    setClickAtom: (clickAtom: IClickAtom | undefined) => void;
    clickAtom: IClickAtom | undefined;
    confAtomRefList: string[];
    currentMaterial: CurrentMaterial[];
    mappingTree: MappingDTO[]
    setMappingTree: (mappingTree: MappingDTO[]) => void
};

export type IClickAtom = {
    PTRef: string;
    atom: ModelTree;
};
export type MappingList = { ptRef: string; mappings: MappingDTO[] };

function ConfigurateurDetail(props: DetailConfigurateurProps) {
    const dispatch = useDispatch();

    //region useState
    const [selected, setSelected] = useState('FORME');
    const [mappingFocus, setMappingFocus] = useState<MappingDTO | undefined>(undefined);
    const [mappingId, setMappingId] = useState<number>(0);
    const [colorisFocusURL, setColorisFocusURL] = useState<string>('');

    const [selectedZone, setSelectZone] = useState<ZoneDTO>();
    const [selectedTag, setSelectTag] = useState<TagDTO | undefined>(undefined);
    const [selectedFinish, setSelectFinish] = useState<FinishDTO | undefined>(undefined);
    const [coloriFocus, setColoriFocus] = useState<Colori>();

    const [mappingLists, setMappingLists] = useState<MappingList[]>([]);

    const executeRulesRes = useSelector<RootState, ExecuteRuleState>((state) => state.executeRules);

    const getCellValuesTreeRes = useSelector<RootState, ProductCellValueTreeState>((state) => state.getCellValueTree);
    const presetsRes = useSelector<RootState, ConfiguredProductTreeListState>((state) => state.getConfiguredProductTreeList);
    const [changeModel3d, setChangeModel3d] = useState(false);
    //endregion

    //region useEffect
    useEffect(() => {
        const list = props.currentConfiguration.filter((c) => c.atom.partReference === props.sectionSelected.reference);
        cells3dValuesService.getModel3dUrlForCombo(list, props.setMappingTree);
    }, []);
    useEffect(() => {
        if (selected === 'MATIERE') {
            const findPart = props.currentMaterial.find((cm) => cm.part.partRef === props.sectionSelected.reference);
            findPart && setMappingId(findPart.part.mappingId);
            if (findPart && mappingLists.length > 0) {
                const M = mappingLists[0].mappings.filter((m) => m.id === findPart.part.mappingId);
                if (M[0]) {
                    setMappingFocus(M[0]);
                    setSelectZone(M[0].zoneList[0]);
                    setSelectTag(M[0].zoneList[0].tagList[0]);
                    setSelectFinish(M[0].zoneList[0].tagList[0].finishList[0]);
                }
            }
        }
    }, [selected]);

    useEffect(() => {
        const tempRefsList: { partRef: string; atomRef: string }[] = [];
        const current = presetsRes && presetsRes.payload.content.filter((p: PresetTree) => props.preset && p.product.reference === props.preset.product.reference);
        if (!props.clickAtom) {
            let currentMt: MappingList[] = [];
            props.preset &&
            current[0].configuration.forEach((modelTree) => {
                if (atomIsMapping(modelTree)) {
                    return;
                }
                tempRefsList.push({ partRef: modelTree.atom.partReference, atomRef: modelTree.atom.reference });
                getCellValuesTreeRes.payload.content.partTreeList.map((PT: PartTree) => {
                    PT.characteristics.forEach((PIT: PartItemTree) =>
                        PIT.modelTrees.filter((mT: ModelTree) => {
                            if (mT.atom.reference === modelTree.atom.reference) {
                                currentMt.push({
                                    ptRef: PT.reference,
                                    mappings: mT.model3d[0].mappings,
                                });
                            }
                        }),
                    );
                });
            });
            setMappingLists(currentMt);
        } else {
            setMappingFocus(undefined);
            setSelectZone(undefined);
            setMappingLists([{ ptRef: props.clickAtom.PTRef, mappings: props.clickAtom.atom.model3d[0].mappings }]);
        }
        return () => props.setIsMount(true);
    }, [presetsRes, props.clickAtom]);

    useEffect(() => {
        if (selected === 'FORME' && props.clickAtom && changeModel3d) {
            const firstMapping = props.clickAtom.atom.model3d[0].mappings[0];
            firstMapping &&
            firstMapping.zoneList.forEach((z: ZoneDTO) => {
                props.onMappingClick(props.sectionSelected, firstMapping);
            });
        }
    }, [changeModel3d]);

    useEffect(() => {
        props.currentConfiguration.forEach((atom) => {
            if (atomIsMapping(atom)) {
                return;
            }
            if (atom.atom.partReference === props.sectionSelected.reference) {
                props.setClickAtom({
                    PTRef: atom.atom.partReference,
                    atom: atom,
                });
            }
        });
    }, [props.currentConfiguration]);

    useEffect(() => {
        props.preset && dispatch(getMappingMz(props.preset.product.reference));
    }, [props.preset]);

    useEffect(() => {
        if (selectedZone) {
            const findPart = props.currentMaterial.find((cm) => cm.part.partRef === props.sectionSelected.reference);
            const findZone = findPart && findPart.part.zones.find((z) => z.zoneRef === selectedZone.uuid);
            findZone && setColorisFocusURL(findZone.defaultColorisUrl);
        }
    }, [selectedZone, props.currentMaterial, mappingLists]);

    useEffect(() => {
        console.log(props.mappingTree);
    }, [props.mappingTree]);
    //endregion

    //region methods
    const checkIfPartItemIsEmpty = () => {
        const tabItem = [];
        const partTreeSelected = getCellValuesTreeRes.payload.content.partTreeList.filter((el) => el.name === props.sectionSelected.name);

        // check if part contains no characteristic and no atoms => if nothing found, do not display "FORME"
        const partItemIsUniqueCharacteristic = () => partTreeSelected[0].characteristics.findIndex((partItem) => partItem.name === 'UNIQUE') !== -1;
        if (partTreeSelected[0]?.characteristics?.length > 0 && !partItemIsUniqueCharacteristic()) {
            tabItem.push('FORME');
        }
        if (partTreeSelected[0]?.characteristics?.[0]?.modelTrees?.[0]?.model3d?.[0]?.mappings?.length > 0) {
            tabItem.push('MATIERE');
        }
        if (partTreeSelected[0]?.options?.length > 0) {
            tabItem.push('OPTION');
        }
        return tabItem;
    };
    const FormatName = (name: string) => {
        const split = name.split(' ');
        if (split.length === 1) {
            if (split[0].length > 10) {
                const formatName = name.slice(0, 9) + '\n' + name.slice(9);
                return <span>{`${formatName.substring(0, 16).toUpperCase()}...`}</span>;
            } else {
                return <span>{name.toUpperCase()}</span>;
            }
        }
        if (split.length > 1) {
            return <span>{name.toUpperCase()}</span>;
        }
    };

    const checkIfPossible = (atom: ModelTree) => {
        if (!executeRulesRes.payload.content) {
            return false;
        } else {
            if (checkIfNotRuleExist()) {
                return true;
            }
            for (let i = 0; i < executeRulesRes.payload.content.length; i++) {
                for (let j = 0; j < executeRulesRes.payload.content[i].modelTrees.length; j++) {
                    if (executeRulesRes.payload.content[i].modelTrees[j].atom.reference === atom.atom.reference) {
                        return true;
                    }
                }
            }
        }
        return false;
    };
    const checkIfNotRuleExist = () => {
        if (!executeRulesRes.payload.content) {
            return true;
        } else {
            var count = 0;
            for (let i = 0; i < executeRulesRes.payload.content.length; i++) {
                if (executeRulesRes.payload.content[i].modelTrees) {
                    for (let j = 0; j < executeRulesRes.payload.content[i].modelTrees.length; j++) {
                        count = count + 1;
                    }
                }
            }
            if (count === 0) {
                return true;
            }
            return false;
        }
    };
    //endregion

    return (
        <div className='config-detail-main-preset'>
            <div className='config-detail-main-title-bloc-preset'>
                <div className='config-detail-title-preset'>Table Concept</div>
                <div
                    className='config-detail-section-preset'
                    onClick={() => {
                        props.setOnclickModified(!props.onclickModifed);
                    }}>
                    <div>
                        <img alt='fleche gauche' src={arrowLeft} />
                        {props.sectionSelected.name}
                    </div>
                </div>
            </div>
            <TabNav
                tabs={checkIfPartItemIsEmpty()}
                selected={selected}
                setSelected={(v: string) => {
                    setSelected(v);
                }}>
                {selected === 'FORME' && (
                    <div className='config-detail-partItem-bloc'>
                        {getCellValuesTreeRes.payload.content.partTreeList.map((partTree: PartTree) => {
                            if (props.sectionSelected.reference === partTree.reference) {
                                return (
                                    <div className='partTreeList' key={partTree.reference}>
                                        {partTree.characteristics.map((partItemTree: PartItemTree) => {
                                            return (
                                                <>
                                                    <span className='partItemTreeName'>{partItemTree.name.toUpperCase()}</span>
                                                    <div className='partItemTreeList' key={partItemTree.reference}>
                                                        {partItemTree.modelTrees.map((atom: ModelTree) => {
                                                            return (
                                                                <div key={atom.atom.reference}>
                                                                    {props.preset && (
                                                                        <Atom
                                                                            confAtomRefList={props.confAtomRefList}
                                                                            thumb={partItemTree.atomDisplayType === 'TEXT_WITH_IMAGE'}
                                                                            itemWithImg={atom}
                                                                            available={checkIfPossible(atom)}
                                                                            onChangeValue={() => {
                                                                                if (checkIfPossible(atom)) {
                                                                                    if (props.preset) {
                                                                                        props.onAtomClick(atom);
                                                                                        setChangeModel3d(true);
                                                                                    }
                                                                                } else {
                                                                                    props.setAtomAvailable(!checkIfPossible(atom));
                                                                                }
                                                                                setSelectZone(undefined);
                                                                                setSelectTag(undefined);
                                                                                setSelectFinish(undefined);
                                                                                props.setClickAtom({
                                                                                    PTRef: partTree.reference,
                                                                                    atom: atom,
                                                                                });
                                                                            }}
                                                                            preset={props.preset}
                                                                        />
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}

                {selected === 'MATIERE' && (
                    <div className='bt'>
                        <div>
                            {props.mappingTree.length > 1 && <div className='map-title-preset'> HABILLAGE</div>}
                            <div className='map-bloc-preset'>
                                {props.mappingTree.length > 1 &&
                                    props.mappingTree.map((M: MappingDTO) => {
                                        return (
                                            <div
                                                key={M.id}
                                                onClick={() => {
                                                    const selectZone = M.zoneList[0];
                                                    const selectTag = selectZone.tagList[0];
                                                    const selectFinish = selectTag.finishList[0];
                                                    const defaultColoris = selectZone.defaultColori;
                                                    setMappingFocus(M);
                                                    setMappingId(M.id);
                                                    setSelectZone(selectZone);
                                                    setSelectTag(selectTag);
                                                    setSelectFinish(selectFinish);
                                                    setColoriFocus(defaultColoris);
                                                    props.onMappingClick(props.sectionSelected, M);
                                                    mappingFocus &&
                                                    selectedZone &&
                                                    selectedTag &&
                                                    mappingConstraintService
                                                        .executeMappingConstraint(M?.id, selectZone.uuid, selectTag.tagName, defaultColoris.finishId, defaultColoris.id)
                                                        .then((res) => {
                                                            const response = res as MdfApiResponse<MappingConstraintResponse>;
                                                            if (response.content.success) {
                                                                if (response.content.result !== null) {
                                                                    mappingFocus && selectedZone && props.onColoriClick(props.sectionSelected, M, selectZone, defaultColoris);
                                                                    props.onColoriClick(
                                                                        props.sectionSelected,
                                                                        M,
                                                                        response.content.result,
                                                                        response.content.result.tagList[0].finishList[0].coloriList[0],
                                                                    );
                                                                } else {
                                                                    // mappingFocus && selectedZone && props.onColoriClick(props.sectionSelected, mappingFocus, selectedZone, defaultColoris);
                                                                }
                                                            } else if (!response.content.success) {
                                                                alert(response.content.message);
                                                            }
                                                        });
                                                }}
                                                className={`${
                                                    !checkIfPossible({
                                                        // @ts-ignore
                                                        atom: {
                                                            partItemReference: `${props.sectionSelected.reference}-mapping`,
                                                            reference: `>${props.sectionSelected.reference}-mapping-${M.id}`,
                                                        },
                                                        model3d: [],
                                                    })
                                                        ? 'grayed-out'
                                                        : ''
                                                } ${M.id === mappingId ? 'mapp-name-focus' : 'mapp-name-preset'} `}>
                                                <span>{FormatName(M.name)}</span>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            {mappingFocus && mappingFocus.zoneList.length > 1 && <div className='map-title-preset'> ZONE</div>}
                            {mappingFocus && mappingFocus.zoneList.length > 1 && (
                                <div className='map-bloc-preset'>
                                    {mappingFocus &&
                                        mappingFocus?.zoneList.map((Z: ZoneDTO) => {
                                            return (
                                                <>
                                                    {!Z.nonConfigurableZone && (
                                                        <div
                                                            className={selectedZone?.uuid === Z.uuid ? 'mapp-name-focus' : 'mapp-name-preset'}
                                                            onClick={() => {
                                                                setSelectZone(Z);
                                                                setSelectTag(Z.tagList[0]);
                                                                Z.tagList.length > 0 && setSelectFinish(Z.tagList[0].finishList[0]);
                                                            }}
                                                            key={Z.uuid}
                                                        >
                                                            {FormatName(Z.name)}
                                                        </div>
                                                    )}
                                                </>
                                            );
                                        })}
                                </div>
                            )}
                        </div>
                        {selectedZone && !selectedZone.nonConfigurableZone && (
                            <>
                                <div>
                                    {selectedZone && <div className='map-title-preset'>MATIERE</div>}
                                    <div className='tag-list-bloc'>
                                        {selectedZone &&
                                            selectedZone.tagList.map((T: TagDTO, i: number) => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            setSelectTag(T);
                                                            T.finishList.length > 0 && setSelectFinish(T.finishList[0]);
                                                        }}
                                                        className={selectedTag && selectedTag.tagName === T.tagName ? 't-focus' : 't'}
                                                        key={i}
                                                    >
                                                        {T.tagName}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div>
                                    {selectedTag && <div className='map-title-preset'>FINITION</div>}
                                    {selectedFinish && selectedFinish.businessDescription ? (
                                        <div className='d-t'>
                                            <strong>Description technique:</strong>
                                            <pre>{selectedFinish.businessDescription}</pre>
                                        </div>
                                    ) : (
                                        <div>
                                            <strong></strong>
                                        </div>
                                    )}
                                    <div className='finish-list-bloc'>
                                        {selectedTag &&
                                            selectedTag.finishList.map((F: FinishDTO) => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            setSelectFinish(F);
                                                            setColoriFocus(F.coloriList[0]);
                                                        }}
                                                        className={selectedFinish && selectedFinish.id === F.id ? 'f-focus' : 'f'}
                                                        key={F.id}
                                                    >
                                                        {F.name}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        {selectedFinish && <div className='map-title-preset'>COLORIS</div>}
                                        {selectedFinish && (
                                            <div className='coloris-list-bloc'>
                                                {selectedFinish.coloriList.map((C: Colori) => {
                                                    return (
                                                        <div
                                                            onClick={() => {
                                                                setColoriFocus(C);
                                                                mappingFocus &&
                                                                selectedZone &&
                                                                selectedTag &&
                                                                mappingConstraintService
                                                                    .executeMappingConstraint(mappingFocus?.id, selectedZone.uuid, selectedTag?.tagName, C.finishId, C.id)
                                                                    .then((res) => {
                                                                        const response = res as MdfApiResponse<MappingConstraintResponse>;
                                                                        if (response.content.success) {
                                                                            if (response.content.result) {
                                                                                mappingFocus && selectedZone && props.onColoriClick(props.sectionSelected, mappingFocus, selectedZone, C);
                                                                                props.onColoriClick(
                                                                                    props.sectionSelected,
                                                                                    mappingFocus,
                                                                                    response.content.result,
                                                                                    response.content.result.tagList[0].finishList[0].coloriList[0],
                                                                                );
                                                                            } else if (response.content.result === null) {
                                                                                mappingFocus && selectedZone && props.onColoriClick(props.sectionSelected, mappingFocus, selectedZone, C);
                                                                            }
                                                                        } else if (!response.content.success) {
                                                                            alert(response.content.message);
                                                                        }
                                                                    });
                                                            }}
                                                            className={colorisFocusURL === C.modelUrl ? 'C-focus' : 'C'}
                                                            key={C.id}
                                                        >
                                                            <img alt='texture' src={C.thumbnailUrl} />
                                                            <span>{C.name}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
                {selected === 'OPTION' && (
                    <div className='config-detail-partItem-bloc'>
                        {getCellValuesTreeRes.payload.content.partTreeList.map((partTree: PartTree) => {
                            if (props.sectionSelected.reference === partTree.reference) {
                                return (
                                    <div className='partTreeList' key={partTree.reference}>
                                        {partTree.options.map((partItemTree: PartItemTree) => {
                                            return (
                                                <React.Fragment key={partItemTree.reference}>
                                                    <span className='partItemTreeName'>{partItemTree.name.toUpperCase()}</span>
                                                    <div className='partItemTreeList' key={partItemTree.reference}>
                                                        {partItemTree.modelTrees.map((atom: ModelTree) => {
                                                            return (
                                                                <div key={atom.atom.reference}>
                                                                    {props.preset && (
                                                                        <Atom
                                                                            confAtomRefList={props.confAtomRefList}
                                                                            thumb={partItemTree.atomDisplayType === 'TEXT_WITH_IMAGE'}
                                                                            itemWithImg={atom}
                                                                            available={checkIfPossible(atom)}
                                                                            onChangeValue={() => {
                                                                                if (checkIfPossible(atom)) {
                                                                                    if (props.preset) {
                                                                                        props.onAtomClick(atom);
                                                                                        setChangeModel3d(true);
                                                                                    }
                                                                                } else {
                                                                                    props.setAtomAvailable(!checkIfPossible(atom));
                                                                                }
                                                                                setSelectZone(undefined);
                                                                                setSelectTag(undefined);
                                                                                setSelectFinish(undefined);
                                                                                props.setClickAtom({
                                                                                    PTRef: partTree.reference,
                                                                                    atom: atom,
                                                                                });
                                                                            }}
                                                                            preset={props.preset}
                                                                        />
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}
            </TabNav>
        </div>
    );
}

export default ConfigurateurDetail;
