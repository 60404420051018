import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NewsService from '../../../api/services/news/news.service';
import { News, NewsStatus } from '../../../domain/domain';
import MdfApiResponse from '../../../domain/common/generic';

export type SingleNewsState = {
    isLoading: boolean;
    payload: MdfApiResponse<News | null>;
};

const initialState: SingleNewsState = {
    isLoading: false,
    payload: { content: null, errors: [], warnings: [] },
};

type GetNewsData = {
    newsId: number;
    status: NewsStatus;
};

export const updateNewsStatus = createAsyncThunk('news/archive', async (data: GetNewsData, thunkApi) => {
    const response = await NewsService.updateNewsStatus(data.newsId, data.status);
    return response;
});

const archiveNewsSlice = createSlice({
    name: 'archiveNewsSlice',
    initialState: initialState,
    reducers: {
        resetArchiveNews: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateNewsStatus.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateNewsStatus.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateNewsStatus.rejected, (state, { error, payload }) => {
            state.isLoading = false;
        });
    },
});

export const { resetArchiveNews } = archiveNewsSlice.actions;
export default archiveNewsSlice.reducer;
