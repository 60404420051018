import './associate-module.scss';
import { CompositionModuleResponse, Product } from '../../../../../../../../domain/domain';
import mdf from '../../../../../../../../asset/logo-mdf.jpeg';
import { useEffect, useState } from 'react';
import productsService from '../../../../../../../../api/services/products/products.service';
import compositionModuleService from '../../../../../../../../api/services/composition/composition-module.service';

type AssociateModuleProps = {
    setOpenModal: (openModule: boolean) => void;
    collectionReference: string;
    compositionReference: string;
    associateModuleList: CompositionModuleResponse[];
};

const AssociateModule = (props: AssociateModuleProps) => {
    const composableAsTableHead = ['Nom', 'Référence', 'Type de produit'];
    const [associateProductReferences, setAssociateProductReferences] = useState<string[]>([]);
    const [productList, setProductList] = useState<Product[]>([]);
    const [isMount, setIsMount] = useState<boolean>(false);

    useEffect(() => {
        productsService
            .getCompositionLinkableByCollection(props.collectionReference)
            .then((res) => {
                setProductList(res.content);
            })
            .catch((err) => {
                setProductList([]);
            });
    }, []);
    useEffect(() => {
        if (!isMount) {
            const assRef: string[] = [];

            props.associateModuleList.forEach((m) => {
                assRef.push(m.product.reference);
            });
            setAssociateProductReferences(assRef);
        }
        return () => setIsMount(true);
    }, [props.associateModuleList]);

    const addAndRemoveId = (reference: string) => {
        const idIsPresent = associateProductReferences.includes(reference);
        if (idIsPresent) {
            const filteredAssociateProduct = associateProductReferences.filter((i: string) => i !== reference);
            setAssociateProductReferences(filteredAssociateProduct);
        } else {
            setAssociateProductReferences((prevState) => [...prevState, reference]);
        }
    };
    const addModulesToComposition = () => {
        compositionModuleService.addModulesToComposition(props.compositionReference, associateProductReferences).then(() => {
            props.setOpenModal(false);
        });
    };
    const masterCheckBox = () => {
        if (associateProductReferences.length === 0) {
            productList.forEach((p) => setAssociateProductReferences((prevState) => [...prevState, p.reference]));
        } else {
            setAssociateProductReferences([]);
        }
    };
    const masterIsChecked = () => {
        const associateProductReferencesLength = associateProductReferences.length;
        const productListLength = productList.length;
        return productListLength === associateProductReferencesLength;
    };
    const inputIsChecked = (reference: string) => associateProductReferences.includes(reference);

    return (
        <div className='associate-mod-main'>
            <div className='associate-mod'>
                <div className='associate-mod-top'> Asssocier des produits</div>
                <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
                    <table className='associate-mod-middle'>
                        <thead className='compo-as-tbl-head'>
                        <tr>
                            {composableAsTableHead.map((h, i) => {
                                return (
                                    <td key={i}>
                                        {i === 0 && <input checked={masterIsChecked()} onChange={masterCheckBox} type='checkbox' />}
                                        {h}
                                    </td>
                                );
                            })}
                        </tr>
                        </thead>
                        <tbody className='compo-as-tbl-body'>
                        {productList.map((p) => {
                            return (
                                <tr key={p.id}>
                                    <td>
                                        <input type='checkbox' checked={inputIsChecked(p.reference)} onChange={() => addAndRemoveId(p.reference)} />
                                        <img alt='vignette' src={p.thumbnailUrl.length > 0 ? p.thumbnailUrl : mdf} />
                                        {p.name}
                                    </td>
                                    <td>{p.reference}</td>
                                    <td>
                                        {p.productType === 'PRESET' && 'Configurable'}
                                        {p.productType === 'SIMPLE' && 'Simple'}
                                        {p.productType === 'COMPOSITION' && 'Composition'}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                <div className='associate-mod-bottom'>
                    <button onClick={() => props.setOpenModal(false)} className='btn-canceled'>
                        Annuler
                    </button>
                    <button
                        className='btn-submit'
                        onClick={() => {
                            addModulesToComposition();
                        }}
                    >
                        Sauvegarder
                    </button>
                </div>
            </div>
        </div>
    );
};
export default AssociateModule;
