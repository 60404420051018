import { useEffect, useState } from 'react';
import Textfield from '../textfield/textfield';
import DataValidators from '../../utils/data-validators';
import { AuthSigninForm } from '../../domain/domain';
import Paper from '../paper/paper';
import JustifyBlock from '../justify-block/justify-block';
import './create-first-user.scss';
import { useHistory } from 'react-router-dom';

type CreateFirstUserFormProps = {
    onSubmit: (form: AuthSigninForm) => void;
};

const CreateFirstUserForm = ({ onSubmit }: CreateFirstUserFormProps) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState<string>('');
    const [submit, setSubmit] = useState(false);
    const history = useHistory();

    useEffect(() => {}, []);
    const handleUserSubmit = () => {
        if (email === '') {
            setError("l'email doit être specifié");
        } else if (!DataValidators.validateEmail(email)) {
            setError("L'adresse email doit être valide");
        } else {
            onSubmit({ email, password: '' });
            setSubmit(true);
        }
    };

    return (
        <JustifyBlock>
            <div style={{ width: 640 }}>
                <Paper size={610}>
                    <div className="create-main">
                        {submit ? <div className="create-title">Demande envoyée !</div> : <div className="create-title">réinitialisation de mot de passe</div>}

                        <div className="create-info">
                            {submit ? (
                                <>
                                    La demande de réinitialisation de votre mot de passe est effectuée. Vous recevrez un lien de réinitialisation de votre mot de passe à l'adresse suivante :
                                    <br />
                                    <br />
                                    {email}
                                </>
                            ) : (
                                "Vous receverez un lien de réinitialisation de votre mot de passe via l'adresse mail que vous indiquerez ci-dessous"
                            )}
                        </div>
                        {submit ? (
                            <></>
                        ) : (
                            <div className="create-input">
                                <Textfield
                                    validation={'required' && 'email'}
                                    name="email"
                                    label="ADRESSE EMAIL PROFESSIONELLE    "
                                    value={email}
                                    errorMessage={error}
                                    isError={error.length !== 0}
                                    placeholder="email@example.com"
                                    onChange={(value) => {
                                        setEmail(value);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="create-button">
                        {submit ? (
                            <div className="empty" />
                        ) : (
                            <button type="button" onClick={handleUserSubmit}>
                                Demander un nouveau mot de passe
                            </button>
                        )}
                        <div className="return">
                            <div onClick={() => history.push('/')}>REVENIR A LA CONNEXION</div>
                        </div>
                    </div>
                </Paper>
            </div>
        </JustifyBlock>
    );
};

export default CreateFirstUserForm;
