import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NewsService from '../../../api/services/news/news.service';
import { CreateNewsType } from '../../../uicomponents/create-news-form/create-news.type';

interface IcreateNews {
    isLoading: boolean;
    isRejected: boolean;
    errorMessage: string;
    successMessage: string;
    payload: { content: null; errors: []; warnings: [] };
}

const initialState: IcreateNews = {
    isLoading: false,
    isRejected: false,
    errorMessage: '',
    successMessage: '',
    payload: { content: null, errors: [], warnings: [] },
};

export const createNews = createAsyncThunk('news/create', async (createNewsForm: CreateNewsType, thunkApi) => {
    const response = await NewsService.createNews(createNewsForm);
    return response;
});

const createNewsSlice = createSlice({
    name: 'createNewsSlice',
    initialState: initialState,
    reducers: {
        resetCreateNews: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createNews.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createNews.fulfilled, (state, { payload, meta }) => {
            state = {
                payload,
                isLoading: false,
                isRejected: false,
                errorMessage: '',
                successMessage: `la news ${meta.arg.title} est publié avec le statut ${meta.arg.newsStatus}`,
            };
            return state;
        });
        builder.addCase(createNews.rejected, (state, { payload, error }) => {
            state.isLoading = false;
            state.isRejected = true;
            state.successMessage = '';
            // @ts-ignore
            state.errorMessage = error.message;
            state.isLoading = false;
        });
    },
});

export const { resetCreateNews } = createNewsSlice.actions;
export default createNewsSlice.reducer;
