import React, { ReactElement, useState } from 'react';
import { RootState } from '../../services/root-reducer';
import Header from '../../components/header/header';
import BackArrow from '../back-arrow/back-arrow';
import { useLocation } from 'react-router-dom';

import programmer from '../../asset/Programmer.svg';
import { News, NewsCategory } from '../../domain/domain';
import Preview from '../preview/preview';
import Toaster from '../toaster/toaster';
import { setToasterGone } from '../../services/reducers/toaster-reducer/toaster.slice';
import { useDispatch, useSelector } from 'react-redux';
import './layout-split.scss';

export type LayoutSplitProps = {
    children: React.ReactNode;
    sideBar?: ReactElement;
    title: string;
    scheduledProgramDate?: string;
    isScheduled?: boolean;
    unScheduled?: () => void;
    setPreview?: (preview: boolean) => void;
    preview?: boolean | undefined;
    data?: News | undefined;
    getCategoryName?: (id: number) => NewsCategory | undefined;
    imageFile?: any;
    color?: string;
    backGroundColor?: string;
    imgUrl?: string;
    setImgUrl?: (imgUrl: string) => void;
    setOpen?: (open: boolean) => void;
    open?: boolean;
    validate?: boolean;
    info?: string;
    labelBack?: string;
};

type ToasterSelector = {
    isVisible: boolean;
    message: string;
    title: string;
    errors: boolean;
};

const LayoutSplit = ({
    children,
    info,
    sideBar,
    title,
    scheduledProgramDate,
    isScheduled,
    unScheduled,
    setOpen,
    open,
    setPreview,
    setImgUrl,
    imageFile,
    getCategoryName,
    data,
    preview,
    validate,
    labelBack,
}: LayoutSplitProps) => {
    const dispatch = useDispatch();
    const toaster = useSelector<RootState, ToasterSelector>((state) => state.toaster);
    const { isVisible } = useSelector<RootState, ToasterSelector>((state) => state.toaster);
    const location = useLocation();
    const currentLocation = location.pathname;
    const spanMessage: string = `Envoi programmé pour le ${scheduledProgramDate}`;
    const [openAvatar, setopenAvatar] = useState(false);
    return (
        <div className="layout-global">
            <Header open={openAvatar} setOpen={setopenAvatar} />

            <div onClick={() => setopenAvatar(false)} className="layoutSplit-main">
                <div className="layout-split-responsive">
                    <div className="title-container">
                        <div>
                            <div className="title-block">
                                <BackArrow info={info} currentLocation={currentLocation} labelBack={labelBack} />
                                <span>{title}</span>
                            </div>
                        </div>

                        {isScheduled !== null && isScheduled !== undefined && isScheduled && (
                            <div className="layout-split-canceled-block">
                                <img alt="logo publier" src={programmer} />
                                <span>{spanMessage}</span>
                                <button
                                    className="layout-split-btn-cancel"
                                    onClick={() => {
                                        if (unScheduled) {
                                            unScheduled();
                                        }
                                    }}
                                >
                                    Annuler l'envoi
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="bodyLayout">
                        {preview && (
                            <Preview
                                setOpen={setOpen}
                                open={open}
                                setImgUrl={setImgUrl}
                                imageFile={imageFile}
                                getCategoryName={getCategoryName}
                                // @ts-ignore
                                setPreview={setPreview}
                                // @ts-ignore
                                data={data}
                            />
                        )}
                        <div className="layoutSplit-left">{children}</div>
                        <div className="layoutSplit-sidebar">{sideBar}</div>
                    </div>
                    <Toaster
                        visibility={isVisible}
                        validate={validate}
                        onClose={() => {
                            dispatch(setToasterGone());
                        }}
                        title={toaster.title}
                        message={toaster.message}
                    />
                </div>
            </div>
        </div>
    );
};
export default LayoutSplit;
