import { MixedCheckbox } from '@reach/checkbox';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllGroups,
    GroupListState,
} from '../../../../services/reducers/mapping-zoning/group-mapping.reducer';
import folder from '../../../../asset/dossier-grey.svg';
import arrowRight from '../../../../asset/arrow-right.svg';
import close from '../../../../asset/CloseWindow.svg';
import { RootState } from '../../../../services/root-reducer';
import { GroupMapping, Mapping, ProductModel3d } from '../../../../domain/domain';
import './associate-mapping-modal.scss';
import {
    updateProductModels3dMappings,
} from '../../../../services/reducers/product/3DModels/3DModels.reducers';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../domain/Params/params-interface';


type AssociateMappingModal = {
    setOpenModal: (openModal: boolean) => void
    product3dModelList: any
    selectedModel3d: ProductModel3d
};
const AssociateMappingModal = (props: AssociateMappingModal) => {
    const dispatch = useDispatch();
    const { collectionReference } = useParams<Params>();
    const groups = useSelector<RootState, GroupListState>(state => state.getAllGroups);

    const [selectedGroup, setSelectedGroup] = useState<GroupMapping>();
    const [idList, setIdList] = useState<number[]>([]);

    useEffect(() => {
        collectionReference && dispatch(getAllGroups(collectionReference));
    }, []);
    useEffect(() => {
        if (groups.payload.content && groups.payload.errors.length === 0) {
            groups.payload.content.length > 0 && setSelectedGroup(groups.payload.content[0]);
        }
    }, [groups]);
    useEffect(() => {
        setIdList([]);
        props.product3dModelList.payload.content.length > 0 &&
        props.product3dModelList.payload.content.forEach((productModel3d: ProductModel3d) => {
            props.selectedModel3d.id === productModel3d.id &&
            setIdList([...productModel3d.mappings.map(el => el.id)]);
        });
    }, [props.product3dModelList]);

    const handleGroupChange = (e: any, group: GroupMapping) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setIdList(prevState => [...prevState, ...group.mappings.map(m => m.id)]);
        } else {
            const groupMapId = group.mappings.map(m => m.id);
            const idListWithoutGroupMembers = idList.filter(el => !groupMapId.includes(el));
            setIdList(idListWithoutGroupMembers);
        }
    };
    const handelMappingChange = (event: any) => {
        const isChecked = event.target.checked;
        let updatedList = [...idList];
        if (isChecked) {
            updatedList = [...idList, parseInt(event.target.value)];
        } else {
            updatedList.splice(idList.indexOf(parseInt(event.target.value)), 1);
        }
        setIdList(updatedList);
    };

    return (
        <div className='associateMapping-main'>
            <div className='associateMapping-paper'>
                <div className='head'>
                    ASSOCIER DES MAPPINGS
                    <img src={ close } onClick={ () => props.setOpenModal(false) } />

                </div>
                <div className='body'>
                    <div className='first n'>
                        <div className='top'>GROUPES</div>
                        <div className='group-list'>
                            {
                                groups.payload.content && groups.payload.content.map((group: GroupMapping) => {
                                    const groupCheck = (currentGroup: GroupMapping) => {
                                        let j = 0;
                                        const groupMapId = currentGroup.mappings.map(m => m.id);
                                        for (let i = 0; i < groupMapId.length; i++) {
                                            if (idList.includes(groupMapId[i])) {
                                                j += 1;
                                            }
                                        }
                                        if (j === 0) {
                                            return false;
                                        }
                                        if (groupMapId.length === j) {
                                            return true;
                                        } else if (group.mappings.length === 0) {
                                            return false;
                                        } else {
                                            return 'mixed';
                                        }
                                    };

                                    return (
                                        <div onClick={ () => {
                                            setSelectedGroup(group);
                                        } }
                                             className={ selectedGroup && group.id === selectedGroup.id ? 'group focus' : 'group' }
                                             key={ group.id }>
                                            <MixedCheckbox
                                                key={ group.id }
                                                checked={ groupCheck(group) }
                                                value={ group.id }
                                                onChange={ (e) => handleGroupChange(e, group) }
                                                type='checkbox' />

                                            <div className='group-left'>
                                                <img alt='dossier' src={ folder } />
                                                <span>
                                                    { group.name.length < 30 ? group.name.toUpperCase() : `${ group.name.substring(0, 29).toUpperCase() }...` }
                                                </span>
                                            </div>
                                            <img className='arr-right' alt='fleche droite' src={ arrowRight } />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className='first'>
                        <div className='top'>MAPPINGS</div>
                        <div className='mapping-list'>
                            { selectedGroup && selectedGroup.mappings.map((mapping: Mapping) => {
                                const mappCheck = () => {
                                    let isMappingChecked;
                                    idList.includes(mapping.id) ? isMappingChecked = true : isMappingChecked = false;
                                    return isMappingChecked;
                                };
                                return (
                                    <div className='mapping' key={ mapping.id }>
                                        <MixedCheckbox
                                            key={ mapping.id }
                                            value={ mapping.id }
                                            checked={ mappCheck() }
                                            onChange={ handelMappingChange }
                                            type='checkbox' />
                                        <div className='group-right'>
                                            <span>{ mapping.name.toUpperCase() }</span>
                                        </div>
                                    </div>
                                );
                            }) }
                        </div>
                    </div>
                    <div />

                </div>
                <div className='btn-box'>
                    <button onClick={ () => props.setOpenModal(false) } className='btn-close'>Annuler
                    </button>
                    <button
                        onClick={ () => dispatch(updateProductModels3dMappings({
                            modelRef: props.selectedModel3d.reference,
                            form: {
                                mappingIdSet: idList, modelUrl: '', partReference: '', anchorPointReference: '',
                            },
                        })) }
                        className='btn-action'>Valider
                    </button>
                </div>
            </div>
        </div>
    );
};
export default AssociateMappingModal;
