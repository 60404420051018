import React from 'react';
import { Conf } from '../products-table/product-presset/product-preset-table';


type RuleDescriptionColumnProps = {
    conf: Conf[]
};

const RuleDescriptionColumn = ({ conf }: RuleDescriptionColumnProps) => {
    return (
        <div className='preset-conf-absolute'>
            <React.Fragment>
                { conf.map((c: Conf, ci) => {
                    return (
                        <div key={ c.partReference } className='conf'>
                            <span className='partName'>{ c.partName }</span>
                            <div>
                                <div className='item-atom'>
                                    { c.itemAtom.map((ia, index) => {
                                        return <div key={ index }>
                                            { `${ ia.partItemName } / ${ ia.atomName }` }
                                        </div>;
                                    }) }
                                </div>

                            </div>
                        </div>
                    );
                }) }
            </React.Fragment>
        </div>
    );
};

export default RuleDescriptionColumn;
