import './product-detail-thumbnail.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../services/root-reducer';
import { getAllParts, PartListState } from '../../../../../../services/reducers/product/part/part.reducers';
import { useEffect, useState } from 'react';
import { Model3DCellValue, Part, PartItemThumbnailDto } from '../../../../../../domain/domain';
import {
    getProduct3DThumbsbyPart,
    PartItemThumbsDtoListState,
} from '../../../../../../services/reducers/product/3DThumbnails/3dThumbnails.reducers';
import {
    Cell3dValueListState,
    Cell3dValueState,
    changeAtomVisibility,
    resetRename3dCellValueLabel,
} from '../../../../../../services/reducers/product/3DCells/3DCellValues.reducers';
import UploadVignette from '../../../../../../components/modals/product/upload-vignette/upload-vignette';
import GenerateThumb from '../../../../../../components/modals/product/generate-thumb/generate-thumb';
import { useHistory, useParams } from 'react-router-dom';
import { Params } from '../../../../../../domain/Params/params-interface';
import { getProductById, ProductInfoState } from '../../../../../../services/reducers/product/product.reducers';
import close from '../../../../../../asset/CloseWindow.svg';
import update from '../../../../../../asset/Editer.svg';
import Rename3dCellValueLabelModal
    from '../../../../../../components/modals/rename-3d-cell-value-label/rename-3d_cell-value-label';
import apiClient from '../../../../../../api/api-client';


type ProductThumbProps = {
    setStep: (step: number) => void;
    setFocus: (focus: number) => void;
};

const ProductDetailThumbnail = (props: ProductThumbProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { productReference, partRef, supplierReference, collectionReference } = useParams<Params>();
    const parts = useSelector<RootState, PartListState>((state) => state.getAllParts);
    const cells = useSelector<RootState, PartItemThumbsDtoListState>((state) => state.get3dThumbByPart);
    const currentProduct = useSelector<RootState, ProductInfoState>((state) => state.getProductionById);
    const changeAtomVisibilityRes = useSelector<RootState, Cell3dValueListState>((state) => state.changeAtomVisibility);
    const renameRes = useSelector<RootState, Cell3dValueState>(state => state.rename3dCellValueLabel);

    const [selectedPart, setSelectedPart] = useState<Part>();
    const [caraPart, setCaraPart] = useState<PartItemThumbnailDto[] | []>([]);
    const [optionPart, setOptionPart] = useState<PartItemThumbnailDto[]>([]);
    const [enter, setEnter] = useState({ in: false, id: -1 });
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [openUpload, setOpenUpload] = useState<boolean>(false);
    const [openGen, setOpenGen] = useState<boolean>(false);
    const [uploadErr, setUploadErr] = useState<string>('');
    const [cellId, setCellId] = useState(-1);
    const [cell, setCell] = useState<Model3DCellValue | undefined>(undefined);
    const [isMount, setIsMount] = useState(false);

    const [generateDone, setGenerateDone] = useState(false);
    const [toasterImg, setToasterImg] = useState('');

    const [rename, setRename] = useState(false);
    const [atomRef, setAtomRef] = useState('');
    const [renameHov, setRenameHov] = useState(0);

    //#region Useffect
    useEffect(() => {
        productReference && dispatch(getProductById(productReference));
    }, []);
    useEffect(() => {
        currentProduct.payload.content && dispatch(getAllParts(currentProduct.payload.content.first.reference));
    }, [currentProduct]);
    useEffect(() => {
        parts.payload.content[0] && history.push(`/supplier/${ supplierReference }/collection/${ collectionReference }/product/${ productReference }/${ parts.payload.content[0].reference }`);
        !isMount && setSelectedPart(parts.payload.content[0]);
        selectedPart && dispatch(getProduct3DThumbsbyPart(selectedPart.reference));
        return () => setIsMount(true);
    }, [parts]);
    useEffect(() => {
        selectedPart && dispatch(getProduct3DThumbsbyPart(selectedPart.reference));
    }, [partRef]);
    useEffect(() => {
        let oSet: PartItemThumbnailDto[] = [];
        let cSet: PartItemThumbnailDto[] = [];
        cells.payload.content.filter((pitd: PartItemThumbnailDto) => {
            pitd.option ? oSet.push(pitd) : cSet.push(pitd);
        });
        setOptionPart(oSet);
        setCaraPart(cSet);
    }, [cells]);
    useEffect(() => {
        currentProduct.payload.content && dispatch(getAllParts(currentProduct.payload.content.first.reference));
    }, [changeAtomVisibilityRes]);
    useEffect(() => {
        generateDone &&
        setTimeout(() => {
            setToasterImg('');
            setGenerateDone(false);
        }, 3000);
    }, [generateDone]);
    useEffect(() => {
        if (renameRes.payload.content.id > 0 && renameRes.payload.errors.length === 0) {
            setRename(false);
            selectedPart && dispatch(getProduct3DThumbsbyPart(selectedPart.reference));
            dispatch(resetRename3dCellValueLabel());

        }
    }, [renameRes]);
    //#endregion

    const onSave = async (productRef: string) => {
        await apiClient(`v1/product/${ productRef }/save-thumbnail-information`, undefined, undefined, 'post');
        props.setStep(5);
        props.setFocus(5);
    };

    const returnBlock = (partItemThumb: PartItemThumbnailDto, i: number) => {
        return (
            <div key={ i } className='bloc-part-item-thumb'>
                { rename && <Rename3dCellValueLabelModal setOpenModal={ setRename } atomRef={ atomRef } /> }
                <div className='part-item-part-item-thumb'>
                    <span className='part-item-thumb-name'>{ partItemThumb.partItemName }</span>
                    <div className='part-item-label'>Type de présentation</div>
                    <select
                        onChange={ (e) => {
                            dispatch(
                                changeAtomVisibility({
                                    partReference: partItemThumb.partReference,
                                    partItemReference: partItemThumb.partItemReference,
                                    atomVisibility: e.target.value,
                                }),
                            );
                        } }
                        value={ partItemThumb.atomDisplayType }
                        className='dropdown-option'>
                        <option value='TEXT'>Liste sans vignette (texte uniquement)</option>
                        <option value='TEXT_WITH_IMAGE'>Liste avec vignette (texte et image)</option>
                    </select>
                    <div className='part-item-label'>Présentation dans l'application</div>

                    <div className='part-item-tag-bloc'>
                        { partItemThumb.cellList.map((model3DCell: Model3DCellValue) => {
                            return (
                                <div className='tag-list' key={ model3DCell.id }>
                                    { partItemThumb.atomDisplayType === 'TEXT'
                                        ? (<div className='tag-text'>
                                                <span
                                                    onMouseEnter={ () => setRenameHov(model3DCell.id) }
                                                    onMouseLeave={ () => setRenameHov(0) }>
                                                    { renameHov !== model3DCell.id
                                                        ? <label>{ model3DCell.label.length > 19
                                                            ? `${ model3DCell.label.substring(0, 19) }...`
                                                            : model3DCell.label
                                                        }</label>
                                                        : <div
                                                            onClick={ () => {
                                                                setAtomRef(model3DCell.reference);
                                                                setRename(true);
                                                            } }
                                                        ><img alt='éditer' src={ update } />
                                                        </div>
                                                    }
                                                </span>
                                        </div>)
                                        : (<div className='tag-img'>
                                            <div
                                                onMouseEnter={ () =>
                                                    setEnter({
                                                        in: true,
                                                        id: model3DCell.id,
                                                    })
                                                }
                                                onMouseLeave={ () =>
                                                    setEnter({
                                                        in: false,
                                                        id: -1,
                                                    })
                                                }
                                                className='tag-img-b'
                                            >
                                                { enter.in && enter.id === model3DCell.id ? (
                                                    <div className='tag-hov'>
                                                        <div
                                                            onClick={ () => {
                                                                setOpenGen(true);
                                                                setCellId(model3DCell.id);
                                                                setCell(model3DCell);
                                                            } }
                                                            className='tag-hov-1'
                                                        >
                                                            Générer vignette
                                                        </div>
                                                        <div
                                                            className='tag-hov-2'
                                                            onClick={ () => {
                                                                setCell(model3DCell);
                                                                setCellId(model3DCell.id);
                                                                setOpenUpload(true);
                                                            } }
                                                        >
                                                            Importer une image
                                                        </div>
                                                    </div>
                                                ) : model3DCell.thumbnailUrl === '' ? (
                                                    <div className='empty-thumb'>Associer une vignette</div>
                                                ) : (
                                                    <img alt='vignette' src={ model3DCell.thumbnailUrl } />
                                                ) }
                                            </div>
                                            <div className='thumb-label'>
                                                <span
                                                    onMouseEnter={ () => setRenameHov(model3DCell.id) }
                                                    onMouseLeave={ () => setRenameHov(0) }
                                                >
                                                    { renameHov !== model3DCell.id
                                                        ? <label>{ model3DCell.label.length > 19
                                                            ? `${ model3DCell.label.substring(0, 19) }...`
                                                            : model3DCell.label
                                                        }</label>
                                                        : <div
                                                            onClick={ () => {
                                                                setAtomRef(model3DCell.reference);
                                                                setRename(true);
                                                            } }
                                                        ><img alt='éditer' src={ update } />
                                                        </div>
                                                    }
                                    </span>

                                            </div>
                                        </div>)
                                    }
                                </div>
                            );
                        }) }
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className='products-thumb'>
            <>
                { openUpload && selectedPart && (
                    <UploadVignette
                        reference={ selectedPart.reference }
                        cellId={ cellId }
                        setFile={ setSelectedFile }
                        // @ts-ignore
                        file={ selectedFile }
                        setErr={ setUploadErr }
                        err={ uploadErr }
                        setOpenTextureModal={ setOpenUpload }
                    />
                ) }
                { openGen && selectedPart && cell && (
                    <GenerateThumb
                        setToasterImg={ setToasterImg }
                        setGenerate={ setGenerateDone }
                        generateDone={ generateDone }
                        cell={ cell }
                        reference={ selectedPart.reference }
                        cellId={ cellId }
                        part={ selectedPart }
                        setOpenModal={ setOpenGen }
                    />
                ) }
                { generateDone && toasterImg.length > 0 && (
                    <div className='popin-generate'>
                        <div className='popin-top'>
                            <span>Vignette Sauvegardée</span>
                            <img
                                onClick={ () => {
                                    setToasterImg('');
                                    setGenerateDone(false);
                                } }
                                alt='femer la modal'
                                src={ close }
                            />
                        </div>
                        <img alt='vignette' className='generate-img' src={ toasterImg } />
                    </div>
                ) }
            </>
            <div className='top'>
                { parts.payload.content.length > 0 ? (
                    <div className='partTabList'>
                        { parts.payload.content.map((part: Part) => {
                            return (
                                <div
                                    onClick={ () => {
                                        history.push(`/supplier/${ supplierReference }/collection/${ collectionReference }/product/${ productReference }/${ part.reference }`);
                                        setSelectedPart(part);
                                    } }
                                    className={ selectedPart?.name === part.name ? 'partTab focus' : 'partTab' }
                                    key={ part.id }
                                >
                                    { part.name.toUpperCase() }
                                </div>
                            );
                        }) }
                    </div>
                ) : (
                    <div />
                ) }
            </div>
            <div className='body'>
                <div className='part-type'>
                    <span className='part-type-name'>caractéristique</span>
                    { caraPart.map((partItemThumb: PartItemThumbnailDto, i) => {
                        return returnBlock(partItemThumb, i);
                    }) }
                </div>
                <div className='part-type'>
                    <span className='part-type-name'>option</span>
                    { optionPart.map((partItemThumb: PartItemThumbnailDto, i) => {
                        return returnBlock(partItemThumb, i);
                    }) }
                </div>
            </div>
            <div className='bottom'>
                <button
                    onClick={ () => {
                        productReference && onSave(productReference);
                    } }
                >
                    Sauvegarder
                </button>
            </div>
        </div>
    );
};

export default ProductDetailThumbnail;
