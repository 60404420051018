const CREATE_PART = (productRef: string) => `v1/product/${productRef}/parts/create`;
const ADD_PART_ITEM = (partRef: string) => `v1/product/${partRef}/add-item`;
const GET_ALL_PARTS = (productRef: string) => `v1/product/${productRef}/parts`;
const GET_PART = (partRef: string) => `v1/product/${partRef}/part`;
const UPDATE_PART = (partId: number) => ``;
const UPDATE_PART_NAME = (partRef: string, name: string) => `v1/product/${partRef}/rename/${name}`;
const DELETE_PART = (partId: number) => `v1/product/part/${partId}/delete`;
const DELETE_PART_ITEM = (partRef: string, itemRef: string) => `v1/product/${partRef}/delete-item/${itemRef}`;

export {
    CREATE_PART,
    GET_ALL_PARTS,
    GET_PART,
    UPDATE_PART,
    DELETE_PART,
    UPDATE_PART_NAME,
    ADD_PART_ITEM,
    DELETE_PART_ITEM,
};