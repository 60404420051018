import React from 'react';
import { Pageable } from '../../domain/pageable/pageable';
import './pagination.scss';

type PaginationProps = {
    page: Pageable<object>;
    action(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, clickedPage: number): void;
};

export const Pagination = ({ page, action }: PaginationProps) => {
    const buildPageList = (currentPage: number, totalPages: number) => {
        const nbVisiblePages = 7;

        if (totalPages === 0) {
            return [1];
        }
        let result: Array<string> = [];
        let startPage = Math.max(0, currentPage - nbVisiblePages);
        let endPage = Math.min(totalPages, currentPage + nbVisiblePages);

        if (startPage > 1) {
            result.push('...');
        }

        for (let i: number = startPage; i < endPage; i++) {
            result.push(String(i + 1));
        }

        if (endPage < totalPages) {
            result.push('...');
        }
        return result;
    };

    const pageList = buildPageList(page.number, page.totalPages);

    return (
        <div className="pagination-container">
            <div className="row">
                <div className="col-sm">
                    <ul className="pagination pagination-sm justify-content-center">
                        <li className={'page-item' + (page.first ? ' disabled' : '')}>
                            <a className="page-link" href="#" onClick={(e) => action(e, page.number - 1)}>
                                &laquo;
                            </a>
                        </li>
                        {pageList.map((x, i) => {
                            return x === '...' ? (
                                <li key={i} className="page-item disabled">
                                    <a className="page-link">...</a>
                                </li>
                            ) : (
                                <li key={i} className={'page-item ' + (page.number + 1 === x ? 'active' : '')}>
                                    <a className="page-link" href="#" onClick={(e) => action(e, Number(x) - 1)}>
                                        {x}
                                    </a>
                                </li>
                            );
                        })}
                        <li className={'page-item ' + (page.last ? 'disabled' : '')}>
                            <a className="page-link" href="#" onClick={(e) => action(e, page.number + 1)}>
                                &raquo;
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
