import './association-win.scss';
import { Fragment, useEffect, useState } from 'react';
import close from '../../../../../asset/CloseWindow.svg';
import productsService from '../../../../../api/services/products/products.service';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../domain/Params/params-interface';
import {
    Colori,
    FinishDTO,
    MappingDTO,
    Matching,
    MdfApiResponse,
    ModelTree,
    PartItemTree,
    PartTree,
    Product,
    ProductTree,
    TagDTO,
    TarifWin,
    TarifWinConfiguration,
    TarifWinProduct,
    TarifWinProductDto,
    ZoneDTO,
} from '../../../../../domain/domain';
import cells3dValuesService from '../../../../../api/services/products/3DCells/3DCellsValues.service';
import { RuleRefs } from '../../../../../components/modals/rules/create-rules';
import winService from '../../../../../api/services/win/win.service';
import WinTable from './win-table/win-table';
import collectionsService from '../../../../../api/services/suppliers/collection/collections.service';

const AssociationWin = () => {
    const { collectionReference } = useParams<Params>();
    const [focus, setFocus] = useState(0);
    const [isMount, setIsMount] = useState(false);
    const [presetList, setPresetList] = useState<Product[]>([]);
    const [filteredPresetList, setFilteredPresetList] = useState<Product[]>([]);
    const [productList, setProductList] = useState<Product[]>([]);
    const [selectedProductRef, setSelectedProductRef] = useState<string>('');
    const [selectedPartTreeList, setSelectedPartTreeListtTree] = useState<PartTree[]>([]);
    const [selectedProductTree, setSelectedProductTree] = useState<ProductTree | undefined>(undefined);

    const [refList, setRefList] = useState<string[]>([]);
    const [ruleRefs, setRuleRefs] = useState<RuleRefs[]>([]);
    const [showMappings, setShowMappings] = useState(false);
    const [mappingMap, setMappingMap] = useState();
    const [currentAtom, setCurrentAtom] = useState<ModelTree>();
    const [matching, setMatching] = useState<Matching>({ model3DCellValueRefByPartItemRef: {} });

    const [tarifWinList, setTarifWinList] = useState<TarifWin[]>([]);
    const [tarifWinProductDtoList, setTarifWinProductDtoList] = useState<TarifWinProductDto[]>([]);
    const [tarifWinConfigurationList, setTarifWinConfigurationList] = useState<TarifWinConfiguration[]>([]);

    const [selectedWinKey, setSelectedWinKey] = useState('');

    useEffect(() => {
        if (collectionReference && !isMount) {
            productsService.getPresetByCollection(collectionReference).then((r: any) => {
                if (r.errors.length === 0 && r.content) {
                    setPresetList(r.content);
                }
            });
            productsService.getProductsByCollection(collectionReference).then((r: any) => {
                if (r.errors.length === 0 && r.content) {
                    const f = r.content.filter((p: Product) => p.productType === 'CONFIGURABLE');
                    setProductList(f);
                }
            });
            winService.winRates().then((res: MdfApiResponse<TarifWin[]>) => {
                if (res.errors.length === 0 && res.content) {
                    setTarifWinList(res.content);
                }
            });
            winService.winProductsAssociated(collectionReference).then((res: MdfApiResponse<TarifWinProductDto[]>) => {
                if (res.errors.length === 0 && res.content) {
                    setTarifWinProductDtoList(res.content);
                }
            });
            winService.winConfigurationsAssociated().then((r) => {
                setTarifWinConfigurationList(r.content);
            });
        }
        return () => setIsMount(true);
    }, []);

    useEffect(() => {
        if (selectedProductRef.length > 0) {
            cells3dValuesService.get3dCellValuesTree(selectedProductRef).then((res: MdfApiResponse<ProductTree>) => {
                if (res.errors.length === 0 && res.content) {
                    setSelectedProductTree(res.content);
                    setSelectedPartTreeListtTree(res.content.partTreeList);
                }
            });
        }
    }, [selectedProductRef]);

    useEffect(() => {
        currentAtom && updateRulesList(currentAtom.atom.partItemReference, currentAtom.atom.reference);
    }, [currentAtom]);

    useEffect(() => {
        const matchingObject: Matching = { model3DCellValueRefByPartItemRef: {} };
        ruleRefs.forEach((el: RuleRefs) => {
            Object.assign(matchingObject.model3DCellValueRefByPartItemRef, { [el.partItemRef]: el.atomRefList });
        });
        setMatching(matchingObject);
    }, [ruleRefs, refList]);

    useEffect(() => {
        removeAlreadyAssociatedProduct();
    }, [presetList, tarifWinProductDtoList]);

    //#region method
    const updateRefList = (atomRef: string) => {
        const existRef = refList.includes(atomRef);
        if (existRef) {
            const newRefList = refList.filter((ref) => ref !== atomRef);
            setRefList(newRefList);
        } else {
            setRefList([...refList, atomRef]);
        }
    };
    const updateRulesList = (pItemRef: string, atomRef: string) => {
        const foundPartItemRefIndex = ruleRefs.findIndex((partItem) => partItem.partItemRef === pItemRef);
        if (foundPartItemRefIndex < 0) {
            setRuleRefs((prevState) => [
                ...prevState,
                {
                    partItemRef: pItemRef,
                    atomRefList: [atomRef],
                },
            ]);
            setCurrentAtom(undefined);
        } else {
            const foundAtomRef = ruleRefs[foundPartItemRefIndex].atomRefList.find((atom) => atom === atomRef);
            if (!foundAtomRef) {
                setRuleRefs((prevState) => {
                    const toReturn = prevState;
                    !toReturn[foundPartItemRefIndex].atomRefList.includes(atomRef) && toReturn[foundPartItemRefIndex].atomRefList.push(atomRef);
                    return toReturn;
                });
                setCurrentAtom(undefined);
            } else {
                setRuleRefs((prevState) => {
                    const toReturn = prevState;
                    toReturn[foundPartItemRefIndex].atomRefList = toReturn[foundPartItemRefIndex].atomRefList.filter((el) => el !== atomRef);
                    if (toReturn[foundPartItemRefIndex].atomRefList.length === 0) {
                        return toReturn.filter((el) => el.partItemRef !== pItemRef);
                    } else {
                        return toReturn;
                    }
                });
                setCurrentAtom(undefined);
            }
        }
    };
    const atomIsSelected = (atom: string) => {
        return refList.includes(atom) ? 'atomFocus' : 'atom';
    };
    const displayMappings = (partReference: string) => {
        if (!mappingMap) {
            return [];
        }
        // @ts-ignore
        return mappingMap && mappingMap[partReference].mappings.sort((a: MappingDTO, b: MappingDTO) => a.name.toUpperCase() < b.name.toUpperCase());
    };
    const displayZones = (partReference: string) => {
        if (!mappingMap) {
            return [];
        }
        // @ts-ignore
        return mappingMap[partReference].zones.sort((a: ZoneDTO, b: ZoneDTO) => a.name.toUpperCase() < b.name.toUpperCase());
    };
    const displayMaterials = (partReference: string) => {
        if (!mappingMap) {
            return [];
        }
        // @ts-ignore
        return mappingMap[partReference].materials.sort((a: TagDTO, b: TagDTO) => a.tagName.toUpperCase() < b.tagName.toUpperCase());
    };
    const displayFinishes = (partReference: string) => {
        if (!mappingMap) {
            return [];
        }
        // @ts-ignore
        return mappingMap[partReference].finishes.sort((a: FinishDTO, b: FinishDTO) => a.name.toUpperCase() < b.name.toUpperCase());
    };
    const displayColoris = (partReference: string) => {
        if (!mappingMap) {
            return [];
        }
        // @ts-ignore
        return mappingMap[partReference].coloris.sort((a: Colori, b: Colori) => a.name.toUpperCase() < b.name.toUpperCase());
    };
    const associateToWinProduct = (productRef: string) => {
        winService
            .winAssociateProduct({
                productReference: productRef,
                winKey: selectedWinKey,
            })
            .then((res: MdfApiResponse<TarifWinProduct>) => {
                if (res.errors.length === 0 && res.content) {
                    collectionReference &&
                        winService.winProductsAssociated(collectionReference).then((r: MdfApiResponse<TarifWinProductDto[]>) => {
                            if (res.errors.length === 0 && res.content) {
                                setTarifWinProductDtoList(r.content);
                            }
                        });
                    setSelectedWinKey('');
                }
            });
    };
    const associateToWinConfiguration = () => {
        collectionReference &&
            collectionsService.getCollection(collectionReference).then((collectionRes) => {
                const collectionId = collectionRes.content.id;
                winService
                    .winAssociateConfiguration({
                        winKey: selectedWinKey,
                        matching,
                        collectionId,
                    })
                    .then((res) => {
                        winService.winConfigurationsAssociated().then((r) => {
                            setTarifWinConfigurationList(r.content);
                        });
                        setSelectedWinKey('');
                        setRefList([]);
                        setRuleRefs([]);
                        setFocus(0);
                        setShowMappings(false);
                        setMatching({ model3DCellValueRefByPartItemRef: {} });
                    });
            });
    };
    const removeAlreadyAssociatedProduct = () => {
        const ti = presetList.filter((t) => {
            return !tarifWinProductDtoList.some((p) => {
                return t.id === p.product.id;
            });
        });
        setFilteredPresetList(ti);
    };
    //endregion

    return (
        <div className="aw-main">
            {selectedWinKey.length > 0 && (
                <div className="associate-modal">
                    <div className="aw-p bo">
                        <div className="bo-top">
                            <div>
                                <span
                                    onClick={() => {
                                        setSelectedProductRef('');
                                        setFocus(0);
                                    }}
                                    className={focus === 0 ? 'f' : ''}
                                >
                                    Produits
                                </span>
                                <span onClick={() => setFocus(1)} className={focus === 1 ? 'f' : ''}>
                                    Configuration
                                </span>
                            </div>
                            <img onClick={() => setSelectedWinKey('')} src={close} alt="fermer la modal" />
                        </div>
                        <div className="bo-body">
                            {focus === 0 && (
                                <div className="product-list">
                                    {filteredPresetList.map((p: Product) => {
                                        return (
                                            <div className="product" onClick={() => associateToWinProduct(p.reference)} key={p.id}>
                                                <img alt={p.name} src={p.thumbnailUrl} />
                                                {p.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {focus === 1 && (
                                <div className="body-listi">
                                    {selectedProductRef.length === 0 ? (
                                        <div className="product-list">
                                            {productList.map((p: Product) => {
                                                return (
                                                    <div className="p" onClick={() => setSelectedProductRef(p.reference)} key={p.id}>
                                                        <img alt={p.name} src={p.thumbnailUrl} />
                                                        {p.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div className="conf">
                                            <div className="c">
                                                {selectedPartTreeList.map((partTree: PartTree) => {
                                                    return (
                                                        <div className="partItem-block" key={partTree.reference}>
                                                            <>
                                                                {partTree.characteristics.map((partItemTree: PartItemTree) => {
                                                                    if (partItemTree.name === 'UNIQUE') {
                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <Fragment key={partItemTree.reference}>
                                                                            <div className="blk">
                                                                                <div className="partName">
                                                                                    <span>{partTree.name.toUpperCase()}</span>
                                                                                    <span>{partItemTree.name}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="atom-container">
                                                                                {partItemTree.modelTrees.map((atom: ModelTree, index: number) => {
                                                                                    return (
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                updateRefList(atom.atom.reference);
                                                                                                setCurrentAtom(atom);
                                                                                            }}
                                                                                            className={atomIsSelected(atom.atom.reference)}
                                                                                            key={index}
                                                                                        >
                                                                                            {atom.atom.value}
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </Fragment>
                                                                    );
                                                                })}
                                                            </>
                                                            <>
                                                                {partTree.options.map((partItemTree: PartItemTree) => {
                                                                    if (partItemTree.name === 'UNIQUE') {
                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <Fragment key={partItemTree.reference}>
                                                                            <div className="blk">
                                                                                <div className="partName">
                                                                                    <span>{partTree.name.toUpperCase()}</span>
                                                                                    <span>{partItemTree.name}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="atom-container">
                                                                                {partItemTree.modelTrees.map((atom: ModelTree, index: number) => {
                                                                                    return (
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                updateRefList(atom.atom.reference);
                                                                                                setCurrentAtom(atom);
                                                                                            }}
                                                                                            className={atomIsSelected(atom.atom.reference)}
                                                                                            key={index}
                                                                                        >
                                                                                            {atom.atom.value}
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </Fragment>
                                                                    );
                                                                })}
                                                            </>
                                                            {showMappings && (
                                                                <>
                                                                    <>
                                                                        <Fragment>
                                                                            <div className="blk">
                                                                                <div className="partName">
                                                                                    <span>{partTree.name.toUpperCase()}</span>
                                                                                    <span>Mappings</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="atom-container">
                                                                                {mappingMap &&
                                                                                    displayMappings(partTree.reference).map((mapping: MappingDTO, index: number) => {
                                                                                        return (
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    updateRefList(`>${partTree.reference}-mapping-${mapping.id}`);

                                                                                                    setCurrentAtom({
                                                                                                        // @ts-ignore
                                                                                                        atom: {
                                                                                                            partItemReference: `${partTree.reference}-mapping`,
                                                                                                            reference: `>${partTree.reference}-mapping-${mapping.id}`,
                                                                                                        },
                                                                                                    });

                                                                                                    // setCurrentAtom(atom);
                                                                                                }}
                                                                                                className={atomIsSelected(`>${partTree.reference}-mapping-${mapping.id}`)}
                                                                                                key={index}
                                                                                            >
                                                                                                {mapping.name}
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                            </div>
                                                                        </Fragment>
                                                                    </>
                                                                    <>
                                                                        <Fragment>
                                                                            <div className="blk">
                                                                                <div className="partName">
                                                                                    <span>{partTree.name.toUpperCase()}</span>
                                                                                    <span>Zones</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="atom-container">
                                                                                {
                                                                                    // @ts-ignore
                                                                                    mappingMap &&
                                                                                        displayZones(partTree.reference).map((zone: ZoneDTO, index: number) => {
                                                                                            return (
                                                                                                <div
                                                                                                    onClick={() => {
                                                                                                        updateRefList(`>${partTree.reference}-zone-${zone.uuid}`);

                                                                                                        setCurrentAtom({
                                                                                                            // @ts-ignore
                                                                                                            atom: {
                                                                                                                partItemReference: `${partTree.reference}-zone`,
                                                                                                                reference: `>${partTree.reference}-zone-${zone.uuid}`,
                                                                                                            },
                                                                                                        });

                                                                                                        // setCurrentAtom(atom);
                                                                                                    }}
                                                                                                    className={atomIsSelected(`>${partTree.reference}-zone-${zone.uuid}`)}
                                                                                                    key={index}
                                                                                                >
                                                                                                    {zone.name}
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                }
                                                                            </div>
                                                                        </Fragment>
                                                                    </>
                                                                    <>
                                                                        <Fragment>
                                                                            <div className="blk">
                                                                                <div className="partName">
                                                                                    <span>{partTree.name.toUpperCase()}</span>
                                                                                    <span>Matériaux</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="atom-container">
                                                                                {
                                                                                    // @ts-ignore
                                                                                    mappingMap &&
                                                                                        displayMaterials(partTree.reference).map((material: TagDTO, index: number) => {
                                                                                            return (
                                                                                                <div
                                                                                                    onClick={() => {
                                                                                                        updateRefList(`>${partTree.reference}-material-${material.tagName}`);

                                                                                                        setCurrentAtom({
                                                                                                            // @ts-ignore
                                                                                                            atom: {
                                                                                                                partItemReference: `${partTree.reference}-material`,
                                                                                                                reference: `>${partTree.reference}-material-${material.tagName}`,
                                                                                                            },
                                                                                                        });

                                                                                                        // setCurrentAtom(atom);
                                                                                                    }}
                                                                                                    className={atomIsSelected(`>${partTree.reference}-material-${material.tagName}`)}
                                                                                                    key={index}
                                                                                                >
                                                                                                    {material.tagName}
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                }
                                                                            </div>
                                                                        </Fragment>
                                                                    </>
                                                                    <>
                                                                        <Fragment>
                                                                            <div className="blk">
                                                                                <div className="partName">
                                                                                    <span>{partTree.name.toUpperCase()}</span>
                                                                                    <span>Finitions</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="atom-container">
                                                                                {
                                                                                    // @ts-ignore
                                                                                    mappingMap &&
                                                                                        displayFinishes(partTree.reference).map((finish: FinishDTO, index: number) => {
                                                                                            return (
                                                                                                <div
                                                                                                    onClick={() => {
                                                                                                        updateRefList(`>${partTree.reference}-finish-${finish.id}`);

                                                                                                        setCurrentAtom({
                                                                                                            // @ts-ignore
                                                                                                            atom: {
                                                                                                                partItemReference: `${partTree.reference}-finish`,
                                                                                                                reference: `>${partTree.reference}-finish-${finish.id}`,
                                                                                                            },
                                                                                                        });

                                                                                                        // setCurrentAtom(atom);
                                                                                                    }}
                                                                                                    className={atomIsSelected(`>${partTree.reference}-finish-${finish.id}`)}
                                                                                                    key={index}
                                                                                                >
                                                                                                    {finish.name}
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                }
                                                                            </div>
                                                                        </Fragment>
                                                                    </>
                                                                    <>
                                                                        <Fragment>
                                                                            <div className="blk">
                                                                                <div className="partName">
                                                                                    <span>{partTree.name.toUpperCase()}</span>
                                                                                    <span>Coloris</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="atom-container">
                                                                                {
                                                                                    // @ts-ignore
                                                                                    mappingMap &&
                                                                                        displayColoris(partTree.reference).map((colori: Colori, index: number) => {
                                                                                            return (
                                                                                                <div
                                                                                                    onClick={() => {
                                                                                                        updateRefList(`>${partTree.reference}-colori-${colori.id}`);

                                                                                                        setCurrentAtom({
                                                                                                            // @ts-ignore
                                                                                                            atom: {
                                                                                                                partItemReference: `${partTree.reference}-colori`,
                                                                                                                reference: `>${partTree.reference}-colori-${colori.id}`,
                                                                                                            },
                                                                                                        });

                                                                                                        // setCurrentAtom(atom);
                                                                                                    }}
                                                                                                    className={atomIsSelected(`>${partTree.reference}-colori-${colori.id}`)}
                                                                                                    key={index}
                                                                                                >
                                                                                                    {colori.name}
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                }
                                                                            </div>
                                                                        </Fragment>
                                                                    </>
                                                                </>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                                <button
                                                    className="btn-mappings"
                                                    onClick={() => {
                                                        setShowMappings((prevState) => !prevState);
                                                        selectedProductTree &&
                                                            !mappingMap &&
                                                            cells3dValuesService.buildMappingTree(selectedProductTree?.reference, selectedProductTree).then((resMapping) => {
                                                                setMappingMap(resMapping.content);
                                                            });
                                                    }}
                                                >
                                                    {showMappings ? 'Masquer' : 'Afficher'} les habillages
                                                </button>
                                            </div>
                                            <div className="re">
                                                <button
                                                    className="bt"
                                                    onClick={() => {
                                                        associateToWinConfiguration();
                                                    }}
                                                >
                                                    sauvegarder
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div className="aw-win bo">
                <div className="bo-body left">
                    <WinTable
                        setTarifWinProductDtoList={setTarifWinProductDtoList}
                        setTarifWinConfigurationList={setTarifWinConfigurationList}
                        TarifWinProductDtoList={tarifWinProductDtoList}
                        tarifWinConfigurationList={tarifWinConfigurationList}
                        selectedWinKey={selectedWinKey}
                        setSelectedWinKey={setSelectedWinKey}
                        TarifWinList={tarifWinList}
                    />
                </div>
            </div>
        </div>
    );
};
export default AssociationWin;
