import MaterialEditorParameters from "../MaterialEditorParameters";
import {InspectorType} from "../ParamTypes/InspectorType";
import {SliderData, SliderType} from "../ParamTypes/SliderType";

export class Anisotropy {

    public m_anisotropy: InspectorType;
    public m_anisotropyRotation: InspectorType;
    
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        this.m_uiFolder = this.m_uiFolder = m_tab.pages[1].addFolder({title: 'Anisotropy', expanded: false});
        this.m_anisotropy = new SliderType(new SliderData(0.0,1.0,0.01), m_matParams, "anisotropy", this.m_uiFolder);
        this.m_anisotropyRotation = new SliderType(new SliderData(0.0,360.0,0.1), m_matParams, "anisotropyRotation", this.m_uiFolder);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_anisotropy.InitType();
        this.m_anisotropyRotation.InitType();
    }
}