import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import {
    getUserList,
    getUsersByStatus,
    getUserStatusCount,
    UserByStatusState,
    UsersState,
    UserStatusCountState,
} from '../../services/reducers/users/users.slice';
import React, { useEffect, useState } from 'react';
import Loader from '../../uicomponents/loader/loader';
import { News, StatusType, User } from '../../domain/domain';
import { useHistory, useParams } from 'react-router-dom';
import {
    DeleteUserState,
    resetPasswordUserByAdmin,
    ResetPasswordUserState,
} from '../../services/reducers/delete-user/delete-user.slice';
import UsersTable from '../../uicomponents/users-table/users-table';
import MainPaper from '../../uicomponents/paper/main-paper';
import Layout from '../../uicomponents/layout/layout';
import { addModal, removeModal } from '../../services/reducers/modal/modal.slice';
import Modal, { ModalSize } from '../../uicomponents/modal/modal';
import { setToasterError } from '../../services/reducers/toaster-reducer/toaster.slice';
import resetPwd from '../../asset/resetPwd.png';
import { useTranslation } from 'react-i18next';
import { Params } from '../../domain/Params/params-interface';
import UserTab from '../../components/user-tabs/user-tab';


const UserList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const confirmationModal = 'confirmation-modal-open';
    const headers = [t('Ta.users.H.name'), t('Ta.users.H.email'), t('Ta.users.H.role'), t('Ta.users.H.last'), t('Ta.users.H.attachment'), t('Ta.users.H.responsible'), t('Ta.users.H.action')];

    const resetPasswordUserByAdminResponse = useSelector<RootState, ResetPasswordUserState>((state) => state.categoryDeletion);
    const { payload, isLoading } = useSelector<RootState, UsersState>((state) => state.userList);
    const deleteResponse = useSelector<RootState, DeleteUserState>((state) => state.deleteUser);
    const userStatusCountResponse = useSelector<RootState, UserStatusCountState>((state) => state.countUserByStatus);
    const usersByStatus = useSelector<RootState, UserByStatusState>(state => state.getUserByStatus);
    const updateUser = useSelector<RootState, {}>(state => state.updateUser);

    const [filteredvalue] = useState<News[]>([]);
    const [userToResetPasswordSelected, setUserToResetPasswordSelected] = useState(-1);
    const [status, setStatus] = useState<StatusType | undefined>('ACTIVE');
    const [role] = useState<string | undefined>(undefined);
    const [userList, setUserList] = useState<User[]>([]);
    const [openM, setOpenM] = useState<boolean>(false);
    const [isMount, setIsMount] = useState(false);

    const { statusParam } = useParams<Params>();

    const setAndCount = (value: any, setValue: (value: any) => void) => {
        setValue(value);
    };

    useEffect(() => {
        !isMount && dispatch(getUsersByStatus());
        !isMount && history.push('/home/users/active');
        if (statusParam !== undefined) {
            switch (statusParam) {
                case 'active':
                    setStatus('ACTIVE');
                    break;
                case 'never-connected':
                    setStatus('NEVER_CONNECTED');
                    break;
                case 'disabled':
                    setStatus('DISABLED');
                    break;
                default:
                    setStatus(undefined);
                    break;
            }
        } else {
            setStatus(undefined);
        }
        return () => setIsMount(true);
    }, [statusParam]);
    useEffect(() => {
        dispatch(getUserList({ status: status, role: role }));
    }, [role, status, updateUser]);
    useEffect(() => {
        const users = payload.content;
        const activeUser = users.filter((u: User) => u.status === 'ACTIVE');
        const neverConnectedUser = users.filter((u: User) => u.status === 'NEVER_CONNECTED');
        status === 'ACTIVE'
            ? setAndCount(activeUser, setUserList)
            : setAndCount(neverConnectedUser, setUserList);
        dispatch(getUserStatusCount());
    }, [payload, status]);
    useEffect(() => {
        const { isRejected, errorMessage } = resetPasswordUserByAdminResponse;
        if (isRejected) {
            dispatch(setToasterError({ message: errorMessage, title: 'Erreur de la réinitialisation' }));
        }
    }, [resetPasswordUserByAdminResponse]);
    useEffect(() => {
        dispatch(getUserList({ status: status, role: role }));
    }, [deleteResponse]);

    const onResetPasswordUser = () => {
        dispatch(addModal(confirmationModal));
    };
    const paginationChange = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, pageClicked: number) => {
        dispatch(getUserList({ status: status, role: undefined, page: pageClicked }));
    };
    const handleResetPasswordUser = (userId: number) => {
        dispatch(resetPasswordUserByAdmin({ userId: userId }));
        dispatch(removeModal(confirmationModal));
    };
    const findStatusCount = (statusToFind: StatusType) => {
        const foundStatusCount = userStatusCountResponse && userStatusCountResponse.payload.content.find((el) => el.status === statusToFind);
        if (!foundStatusCount) {
            return 0;
        }
        return foundStatusCount.count;
    };

    return (
        <Layout title={ t('P.T.users') } labelBack=''>
            <div style={ { display: 'flex' } }>
                <UserTab
                    activeCount={ usersByStatus.payload.content.activeUser.length }
                    neverCount={ usersByStatus.payload.content.neverConnectedUser.length }
                    status={ status }
                    setStatus={ setStatus } />
            </div>
            {/*  <FilterBlock
                clearFilter={ () => {
                    history.push('/home/users');
                } }
                itemsTotalCount={ userList.length }
                label={ `+ ${ t('P.users.UI.button.L') }` }
                findUserCountByStatus={ findStatusCount }
                selectedTab={ status }
                news={ false }
                pubOnclick={ () => history.push('/home/users/active') }
                proOnclick={ () => history.push('/home/users/never-connected') }
                onClickfunc={ () => {
                    dispatch(resetCreateUser());
                    history.push('/create-user');
                } }
            />*/ } <MainPaper>
            {/*<SearchBar setFilterValue={setFilteredValue} />*/ }
            <Loader isLoading={ isLoading } />
            <Modal
                validateLabel='Confirmer'
                closeLabel='Annuler'
                onClick={ () => {
                    handleResetPasswordUser(userToResetPasswordSelected);
                } }
                id={ confirmationModal }
                title='Réinitialisation mot de passe'
                icon={ resetPwd }
                size={ ModalSize.SMALL }
                colorValidateButton='#E95D5D'
            >
                <p>Veuillez confirmer la réinitialisation du mot de passe!</p>
            </Modal>
            <div />
            <div>
                <div>
                    { !filteredvalue ? (
                        <UsersTable
                            openM={ openM }
                            setOpenM={ setOpenM }
                            users={ [] }
                            headers={ headers }
                            onResetPasswordUser={ onResetPasswordUser }
                            setUserToResetPasswordSelected={ setUserToResetPasswordSelected }
                        />
                    ) : (
                        <UsersTable
                            openM={ openM }
                            setOpenM={ setOpenM }
                            users={ userList }
                            headers={ headers }
                            onResetPasswordUser={ onResetPasswordUser }
                            setUserToResetPasswordSelected={ setUserToResetPasswordSelected }
                        />
                    ) }
                </div>
            </div>
            {/*<div>{ payload.content && <Pagination page={ payload.content } action={ paginationChange } /> }</div>*/}
        </MainPaper>
        </Layout>
    );
};

export default UserList;
