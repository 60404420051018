import MaterialEditorParameters from "../MaterialEditorParameters";
import {SliderData, SliderType} from "../ParamTypes/SliderType";
import {InspectorType} from "../ParamTypes/InspectorType";
import {VectorData, VectorType} from "../ParamTypes/VectorType";
import {EnumData, EnumType} from "../ParamTypes/EnumType";
import {ClampToEdgeWrapping, MirroredRepeatWrapping, RepeatWrapping, Vector2} from "three";

export class Transform {
    public m_tiling: InspectorType;
    public m_offset: InspectorType;
    public m_wrapMode: InspectorType;
    public m_rotation: InspectorType;
    public m_rotationCenter: InspectorType;
    
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        //Albedo
        this.m_uiFolder = m_uiFolders = m_tab.pages[1].addFolder({title: 'Textures Transforms', expanded: false});
        this.m_tiling = new VectorType(new VectorData(new Vector2(-10.0, -10.0), new Vector2(10.0, 10.0)), m_matParams, "txTiling", m_uiFolders);
        this.m_offset = new VectorType(new VectorData(new Vector2(-1, -1), new Vector2(1, 1)), m_matParams, "txOffset", m_uiFolders);
        this.m_wrapMode = new EnumType(new EnumData("Wrap Mode", {
            Repeat: RepeatWrapping,
            Clamp: ClampToEdgeWrapping,
            Mirror: MirroredRepeatWrapping,
        }), m_matParams, "txWrapMode", m_uiFolders);
        this.m_rotation = new SliderType(new SliderData(0.0,360.0,0.1), m_matParams, "txRotationDeg", m_uiFolders);
        this.m_rotationCenter = new VectorType(new VectorData(new Vector2(0, 0), new Vector2(1, 1)), m_matParams, "txRotationCenter", m_uiFolders);

        this.InitTypes();

    }

    private InitTypes() {
        this.m_tiling.InitType();
        this.m_offset.InitType();
        this.m_wrapMode.InitType();
        this.m_rotation.InitType();
        this.m_rotationCenter.InitType();
    }
}