import MdfApiResponse from '../../../../domain/common/generic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import models3dService from '../../../../api/services/products/3DModels/3DModels.servive';
import { ProductModel3d, ProductModel3dCreationForm } from '../../../../domain/domain';

export type Model3dState = {
    isLoading: false;
    payload: MdfApiResponse<ProductModel3d>;
};
const initialModel3dState: Model3dState = {
    isLoading: false,
    payload: {
        content: {
            name: '',
            id: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
            version: 0,
            partReference: '',
            reference: '',
            modelUrl: '',
            mappings: [],
            anchorPointReference: '',
        },
        errors: [],
        warnings: [],
    },
};

export type Model3dListState = {
    isLoading: false;
    payload: MdfApiResponse<ProductModel3d[]>;
};
const initialModel3dListState: Model3dListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

//<editor-fold desc="get-all-3DModels-by-part">
export const getAllModels3dByParts = createAsyncThunk('get/all-Models3d-by-part', async (partRef: string) => {
    const res = await models3dService.getAllModels3dByParts(partRef);
    return res;
});

export const getAllModels3dByPartsSlice = createSlice({
    name: 'getAllModels3dByParts',
    initialState: initialModel3dListState,
    reducers: {
        resetGetAllModels3d: (state) => {
            state = initialModel3dListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllModels3dByParts.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllModels3dByParts.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllModels3dByParts.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetAllModels3d } = getAllModels3dByPartsSlice.actions;
//</editor-fold>

//<editor-fold desc="get-all-3DModels-by-collection">
export const getAllModels3dByCollection = createAsyncThunk('get/all-Models3d-by-Collection', async (collectionReference: string) => {
    const res = await models3dService.getAllModels3dByCollection(collectionReference);
    return res;
});

export const getAllModels3dByCollectionSlice = createSlice({
    name: 'getAllModels3dByCollection',
    initialState: initialModel3dListState,
    reducers: {
        resetGetAllModels3dByCollection: (state) => {
            state = initialModel3dListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllModels3dByCollection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllModels3dByCollection.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllModels3dByCollection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetAllModels3dByCollection } = getAllModels3dByCollectionSlice.actions;
//</editor-fold>

//<editor-fold desc="get-all-3DModels-by-tag">
export const getAllModels3dByTag = createAsyncThunk('get/all-Models3d-by-tag', async (data: { partItemRef: string; tagRef: string }) => {
    const res = await models3dService.getAllModels3dByTag(data.partItemRef, data.tagRef);
    return res;
});

export const getAllModels3dByTagSlice = createSlice({
    name: 'getAllModels3dByTag',
    initialState: initialModel3dListState,
    reducers: {
        resetGetAllModels3dTag: (state) => {
            state = initialModel3dListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllModels3dByTag.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllModels3dByTag.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllModels3dByTag.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetAllModels3dTag } = getAllModels3dByTagSlice.actions;
//</editor-fold>

//<editor-fold desc="get-product-3DModels">
export const getProductModels3d = createAsyncThunk('get/Product-Models3d', async (partRef: string) => {
    const res = await models3dService.getProduct3DModels(partRef);
    return res;
});

export const getProductModels3dSlice = createSlice({
    name: 'getProductModels3d',
    initialState: initialModel3dListState,
    reducers: {
        resetGetProductModels3d: (state) => {
            state = initialModel3dListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProductModels3d.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProductModels3d.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getProductModels3d.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetProductModels3d } = getProductModels3dSlice.actions;
//</editor-fold>

//<editor-fold desc="create-all-product-3DModels">
export const createProductModels3d = createAsyncThunk('create/Product-Models3d', async (data: { form: ProductModel3dCreationForm }) => {
    const res = await models3dService.createProduct3dModels(data.form);
    return res;
});

export const createProductModels3dSlice = createSlice({
    name: 'createProductModels3d',
    initialState: initialModel3dState,
    reducers: {
        resetCreateProductModels3d: (state) => {
            state = initialModel3dState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createProductModels3d.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createProductModels3d.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createProductModels3d.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateProductModels3d } = createProductModels3dSlice.actions;
//</editor-fold>

//<editor-fold desc="delete-product-3DModels">
export const deleteProductModels3d = createAsyncThunk('delete/Product-Models3d', async (modelRef: string) => {
    const res = await models3dService.deleteProduct3DModels(modelRef);
    return res;
});

export const deleteProductModels3dSlice = createSlice({
    name: 'deleteProductModels3d',
    initialState: initialModel3dState,
    reducers: {
        resetDeleteProductModels3d: (state) => {
            state = initialModel3dState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteProductModels3d.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteProductModels3d.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteProductModels3d.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteProductModels3d } = deleteProductModels3dSlice.actions;
//</editor-fold>

//<editor-fold desc="update-product-3DModels">
export const updateProductModels3dMappings = createAsyncThunk('update/Product-Models3d-Mappings', async (data: { modelRef: string; form: ProductModel3dCreationForm }) => {
    const res = await models3dService.updateProduct3DModelMappings(data.modelRef, data.form);
    return res;
});

export const updateProductModelMappings3dSlice = createSlice({
    name: 'updateProductModels3d',
    initialState: initialModel3dState,
    reducers: {
        resetUpdateProductModels3dMappings: (state) => {
            state = initialModel3dState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateProductModels3dMappings.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateProductModels3dMappings.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateProductModels3dMappings.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateProductModels3dMappings } = updateProductModelMappings3dSlice.actions;
//</editor-fold>

//<editor-fold desc="update-product-3DModels-url">
export const updateProductModels3dUrl = createAsyncThunk('update/Product-Models3d-Url', async (data: { modelRef: string; url: string }) => {
    const res = await models3dService.updateProduct3DModelUrl(data.modelRef, data.url);
    return res;
});

export const updateProductModelMappings3dUrlSlice = createSlice({
    name: 'updateProductModels3dUrl',
    initialState: initialModel3dState,
    reducers: {
        resetUpdateProductModels3dUrl: (state) => {
            state = initialModel3dState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateProductModels3dUrl.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateProductModels3dUrl.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateProductModels3dUrl.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateProductModels3dUrl } = updateProductModelMappings3dUrlSlice.actions;

//</editor-fold>
//<editor-fold desc="save-product-3DModels">
export const saveProductModels3d = createAsyncThunk('save/Product-Models3d', async (productRef: string) => {
    const res = await models3dService.saveProduct3DModels(productRef);
    return res;
});

export const saveProductModels3dSlice = createSlice({
    name: 'saveProductModels3d',
    initialState: initialModel3dState,
    reducers: {
        resetSaveProductModels3d: (state) => {
            state = initialModel3dState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(saveProductModels3d.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(saveProductModels3d.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(saveProductModels3d.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetSaveProductModels3d } = saveProductModels3dSlice.actions;
//</editor-fold>
