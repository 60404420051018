import './user-tab.scss';
import React from 'react';
import { StatusType } from '../../domain/domain';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetCreateUser } from '../../services/reducers/create-user/create-user.slice';


type UserTabsProps = {
    setStatus: (status: StatusType) => void
    status: StatusType | undefined
    activeCount: number
    neverCount: number
};
const UserTab = (props: UserTabsProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <div className='user-tab-main'>
            <span className={ props.status === 'ACTIVE' ? 'activeStatus' : '' }
                  onClick={ () => props.setStatus('ACTIVE') }>
                Actifs ({ props.activeCount })
            </span>
            <span className={ props.status === 'NEVER_CONNECTED' ? 'activeStatus' : '' }
                  onClick={ () => props.setStatus('NEVER_CONNECTED') }>Jamais connectés ({ props.neverCount })</span>
            <button onClick={ () => {
                dispatch(resetCreateUser());
                history.push('/create-user');
            }
            }> + Créer un utilisateur
            </button>
        </div>
    );
};

export default UserTab;