import close from '../../../asset/CloseWindow.svg';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createStore, updateStoreName } from '../../../services/reducers/store/store.slice';
import { Store } from '../../../domain/domain';

type CreateStoreProps = {
    setModal: (modal: boolean) => void
    update: boolean
    setUpdate: (update: boolean) => void
    store: Store
    setNameErr: (nameErr: string) => void
    nameErr: string
};
const CreateStoreModal = (props: CreateStoreProps) => {
    const dispatch = useDispatch();
    const [name, setName] = useState<string>('');
    useEffect(() => {
        props.update && setName(props.store.name);
    }, []);
    const onSubmit = () => {
        name.length === 0
            ? props.setNameErr('Le nom doit etre renseigné')
            : dispatch(createStore(name));
    };

    const onUpdate = () => {
        if (name === props.store.name) {
            props.setUpdate(false);
            props.setModal(false);
        } else {
            name.length === 0
                ? props.setNameErr('Le nom doit etre renseigné')
                : dispatch(updateStoreName({ storeRef: props.store.reference, storeName: name }));
        }
    };

    return (
        <div className='create-catalog-main'>
            <div className='create-catalog-paper'>
                <div className='create-catalog-top'>
                    <span>{ props.update ? 'Renommer un magasin' : 'Ajouter un magasin' }</span>
                    <img onClick={ () => {
                        props.setUpdate(false);
                        props.setModal(false);
                    } } alt='fermer la modal' src={ close } />
                </div>
                <div className='create-catalog-body'>
                    <label>Nom</label>
                    <input
                        defaultValue={ props.update ? props.store.name : '' }
                        type='text'
                        placeholder='Nom du magasin'
                        onChange={ (e) => {
                            props.setNameErr('');
                            setName(e.target.value);
                        } }
                    />
                    <span>{ props.nameErr }</span>
                </div>
                <div className='create-catalog-btn-box'>
                    <button onClick={ () => {
                        props.setUpdate(false);
                        props.setModal(false);
                    } } className='btn-close'>
                        Annuler
                    </button>
                    <button
                        onClick={ props.update ? onUpdate : onSubmit }
                        className='btn-action'>
                        { props.update ? 'Modifier' : 'Créer' }
                    </button>
                </div>
            </div>

        </div>
    );
};
export default CreateStoreModal;