import apiClient from '../../../api-client';
import {
    ADD_METADATA,
    ADD_MULTIPLE_ATOMS_TO_CONFIGURED_PRODUCT,
    APPLY_RULES,
    CREATE_CONFIGURED_PRODUCT,
    DELETE_CONFIGURED_PRODUCT,
    DUPLICATE_CONFIGURED_PRODUCT,
    GET_CONFIGURED_PRODUCT_SHEET,
    GET_CONFIGURED_PRODUCT_TREE_BY_REFERENCE,
    GET_CONFIGURED_PRODUCT_TREE_LIST,
    GET_DEFAULT_CONFIGURED_PRODUCT_TREE_BY_CORE_REFERENCE,
    RENAME_CONFIGURED_PRODUCT,
    SET_DEFAULT_CONFIGURED_PRODUCT,
    UPLOAD_THUMBNAIL_URL,
} from '../../../endpoints/product/configured-product';
import { CompositionWrapperBody, Product, SaveConfiguredProductBody } from '../../../../domain/domain';

const configuredProductService = {
    createConfiguredProduct: async (name: string, coreProductReference: string) => apiClient(CREATE_CONFIGURED_PRODUCT(coreProductReference, name), undefined, undefined, 'POST'),
    getConfiguredProductTreeList: async (coreProductReference: string) => apiClient(GET_CONFIGURED_PRODUCT_TREE_LIST(coreProductReference), undefined, undefined, 'GET'),
    addMultipleAtomsToConfiguredProduct: async (productReference: string, saveConfiguredProductBody: SaveConfiguredProductBody) =>
        apiClient(ADD_MULTIPLE_ATOMS_TO_CONFIGURED_PRODUCT(productReference), saveConfiguredProductBody, undefined, 'POST'),
    getConfiguredProductTreeByProductReference: async (productReference: string) => apiClient(GET_CONFIGURED_PRODUCT_TREE_BY_REFERENCE(productReference), undefined, undefined, 'GET'),
    renameConfiguredProduct: async (productReference: string, newName: string) => apiClient(RENAME_CONFIGURED_PRODUCT(productReference, newName), undefined, undefined, 'PUT'),
    uploadThumbnailToConfiguredProduct: async (productReference: string, product: Product) => apiClient(UPLOAD_THUMBNAIL_URL(productReference), product, undefined, 'POST'),
    deleteConfiguredProduct: async (productReference: string) => apiClient(DELETE_CONFIGURED_PRODUCT(productReference), undefined, undefined, 'DELETE'),
    addMetadataToConfiguredProduct: async (productReference: string, metadata: any) => apiClient(ADD_METADATA(productReference), metadata, undefined, 'POST'),
    setDefaultConfiguredProduct: async (coreProductReference: string, newDefaultId: number) =>
        apiClient(SET_DEFAULT_CONFIGURED_PRODUCT(coreProductReference, newDefaultId), undefined, undefined, 'POST'),
    duplicateConfiguredProduct: async (productReference: string, newName: string) => apiClient(DUPLICATE_CONFIGURED_PRODUCT(productReference, newName), undefined, undefined, 'POST'),
    getDefaultConfiguredProductByCoreProductRef: async (coreProductReference: string) =>
        apiClient(GET_DEFAULT_CONFIGURED_PRODUCT_TREE_BY_CORE_REFERENCE(coreProductReference), undefined, undefined, 'GET'),
    getConfiguredProductSheet: async (productReference: string) => apiClient(GET_CONFIGURED_PRODUCT_SHEET(productReference), undefined, undefined, 'GET'),
    applyRulesOnComposition: async (compositionReference: string, compoundedModuleId: number, body: CompositionWrapperBody) =>
        apiClient(APPLY_RULES(compositionReference, compoundedModuleId), body, undefined, 'POST'),
};
export default configuredProductService;
