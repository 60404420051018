import close from '../../../../../asset/CloseWindow.svg';
import redTrash from '../../../../../asset/poubelle-red.svg';
import MainPaper from '../../../../../uicomponents/paper/main-paper';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConstraintListState,
    ConstraintState,
    createMappingConstraint,
    deleteMappingConstraint,
    getMappingConstraint,
    resetCreateMappingConstraint,
    resetDeleteMappingConstraint,
} from '../../../../../services/reducers/mapping-zoning/mapping-constraint.reducers';
import { RootState } from '../../../../../services/root-reducer';
import {
    Mapping,
    MappingConstraint,
    MappingConstraintComparator,
    MappingConstraintType,
    Zone,
} from '../../../../../domain/domain';
import { SubmitHandler, useForm } from 'react-hook-form';
import SimpleModal from '../../../../../uicomponents/modal/simple-modal/simple-modal';
import './constraint-modal.scss';


type ConstraintModalProps = {
    setOpenModal: (openModal: boolean) => void;
    selectedMap: Mapping;
};

type UpdateCreateProductInput = {
    constraintType: string;
    zone1Uuid: string;
    comparator: string;
    zone2Uuid: string;
};

const ConstraintModal = (props: ConstraintModalProps) => {
    const dispatch = useDispatch();
    const typeOption = ['Finition', 'Coloris', 'Matière'];
    const comparatorOption = ['Égal(e) à', 'Different de'];

    const mappingConstraint = useSelector<RootState, ConstraintListState>((state) => state.getMappingConstraint);
    const createConstraintRes = useSelector<RootState, ConstraintState>((state) => state.createMappingConstraint);
    const deleteConstraintRes = useSelector<RootState, ConstraintState>((state) => state.deleteMappingConstraint);

    const closeModal = () => {
        props.setOpenModal(false);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdateCreateProductInput>({});

    const onSubmit: SubmitHandler<UpdateCreateProductInput> = (data) => {
        const comparator = checkComparator(data.comparator);
        const constraintType = checkConstraintType(data.constraintType);
        if (comparator !== undefined && constraintType !== undefined) {
            dispatch(
                createMappingConstraint({
                    id: 0,
                    version: 0,
                    mappingId: props.selectedMap.id,
                    zone1Uuid: data.zone1Uuid,
                    zone2Uuid: data.zone2Uuid,
                    comparator: comparator,
                    constraintType: constraintType
                })
            );
        }
    };

    const [openDeleteConstraint, setOpenDeleteConstraint ] = useState(false);
    const [constraintId, setConstraintId] = useState<number>(0);

    const checkComparator = (value: string): MappingConstraintComparator | undefined => {
        if (value === 'Égal(e) à') {
            return 'EQUAL';
        } else if (value === 'Different de') {
            return 'DIFFERENT';
        } else if (value === 'Comparateur') {
            return undefined;
        }
    };
    const checkConstraintType = (value: string): MappingConstraintType | undefined => {
        if (value === 'Coloris') {
            return 'COLORIS';
        } else if (value === 'Finition') {
            return 'FINISH';
        } else if (value === 'Matière') {
            return 'TAG';
        } else if (value === 'Comparateur') {
            return undefined;
        }
    };

    useEffect(() => {
        dispatch(getMappingConstraint(props.selectedMap.id));
    }, []);
    useEffect(() => {
        createConstraintRes.payload.content && createConstraintRes.payload.errors.length === 0 && dispatch(getMappingConstraint(props.selectedMap.id));
        dispatch(resetCreateMappingConstraint());
    }, [createConstraintRes]);
    useEffect(() => {
        deleteConstraintRes.payload.content && deleteConstraintRes.payload.errors.length === 0 && setOpenDeleteConstraint(false);
        dispatch(getMappingConstraint(props.selectedMap.id));
        dispatch(resetDeleteMappingConstraint());
    }, [deleteConstraintRes]);

    const typeString = (type: string) => {
        return type === 'COLORIS' ? `le ${type}` : type === 'FINISH' ? 'La finition' : 'le tag';
    };
    const comparatorString = (comparator: string, type: string) => {
        return comparator !== 'EQUAL' ? 'different de la' : type === 'COLORIS' ? 'égal à' : 'égale à';
    };
    const findZoneName = (zone: string) => {
        let name = '';
        props.selectedMap.zoningMetadata.zoneList.filter((el) => {
            if (el.uuid === zone) {
                name = el.name;
            }
        });
        return name;
    };

    return (
        <div className="constraint-modal-main">
            {openDeleteConstraint && (
                <SimpleModal
                    icon={redTrash}
                    title="Supprimer une contrainte"
                    info="La contrainte ainsi que toutes ces dépendances seront supprimés"
                    closeOnclick={() => setOpenDeleteConstraint(false)}
                    actionOnclick={() => dispatch(deleteMappingConstraint(constraintId))}
                    deleteOrValide={true}
                    closeLabel="FERMER"
                    actionLabel="SUPPRIMER"
                />
            )}
            <div className="constraint-modal-paper">
                <MainPaper>
                    <div className="constraint-modal-bloc">
                        <div className="constraint-modal-top">
                            <span> {`CONTRAINTES DE ${props.selectedMap.name}`} </span>
                            <img onClick={closeModal} alt="fermer la modal" src={close} />
                        </div>
                        <div className="constraint-modal-body">
                            <div className="constraint-modal-preview">
                                {mappingConstraint.payload.content &&
                                    mappingConstraint.payload.content.map((rules: MappingConstraint) => {
                                        return (
                                            <div className="prev" key={rules.id}>
                                                <span>
                                                    {`Obliger 
                                                ${typeString(rules.constraintType)}
                                                de  ${findZoneName(rules.zone1Uuid)} 
                                                à être ${comparatorString(rules.comparator, rules.constraintType)} 
                                                ${findZoneName(rules.zone2Uuid)} `}
                                                </span>
                                                <img
                                                    onClick={() => {
                                                        setConstraintId(rules.id);
                                                        setOpenDeleteConstraint(true);
                                                    }}
                                                    alt="supprimer une regle"
                                                    src={redTrash}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                            <div className="constraint-modal-rules">
                                <span>AJOUTER UNE NOUVELLE CONTRAINTE</span>
                                <div className="rules">
                                    <form className="rules-table">
                                        <div className="z1">
                                            <span>OBLIGER</span>
                                            <select {...register('constraintType')}>
                                                <option>.. Type ..</option>
                                                {typeOption.map((option, i) => {
                                                    return (
                                                        <option value={option} key={i}>
                                                            {option}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="z2">
                                            <span>DE</span>
                                            <select {...register('zone1Uuid')}>
                                                <option>.. Zone ..</option>
                                                {props.selectedMap.zoningMetadata.zoneList.map((zone: Zone) => {
                                                    return (
                                                        <option value={zone.uuid} key={zone.uuid}>
                                                            {zone.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="z3">
                                            <span>À ÊTRE</span>
                                            <select {...register('comparator')}>
                                                <option> Comparateur</option>
                                                {comparatorOption.map((comparator, i: number) => {
                                                    return <option key={i}> {comparator}</option>;
                                                })}
                                            </select>
                                            <select {...register('zone2Uuid')}>
                                                <option>.. Zone ..</option>
                                                {props.selectedMap.zoningMetadata.zoneList.map((zone: Zone) => {
                                                    return (
                                                        <option value={zone.uuid} key={zone.uuid}>
                                                            {zone.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </form>
                                    <button onClick={handleSubmit(onSubmit)}> Créer</button>
                                </div>
                            </div>
                        </div>
                        <div className="constraint-modal-btn-box">
                            <button className="btn-close" onClick={closeModal}>
                                Annuler
                            </button>
                            <button onClick={closeModal} className="btn-action">
                                Valider
                            </button>
                        </div>
                    </div>
                </MainPaper>
            </div>
        </div>
    );
};
export default ConstraintModal;
