import './create-section.scss';
import close from '../../../../asset/CloseWindow.svg';
import { useEffect, useRef, useState } from 'react';
import { postFile } from '../../../../utils/upload-file-method';
import { useDispatch } from 'react-redux';
import { createSection } from '../../../../services/reducers/catalog/section.slice';
import { Catalog, Section } from '../../../../domain/domain';

type CreateSectionProps = {
    setOpenModal: (openModal: boolean) => void;
    section: Section | undefined;
    setSection: (section: Section | undefined) => void;
    catalog: Catalog
};

const CreateSection = ({ setOpenModal, section, setSection, catalog }: CreateSectionProps) => {
    const dispatch = useDispatch();
    const formRef = useRef<HTMLInputElement>(null);
    const [nameErr, setNameErr] = useState('');
    const [name, setName] = useState<string>('');

    const [fileErr, setFileErr] = useState('');
    const [file, setFile] = useState<File | undefined>(undefined);
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const [awsUrl, setAwsUrl] = useState(undefined);
    const [upAwsUrl, setUpAwsUrl] = useState(undefined);
    const [upType, setUpType] = useState<number | undefined>(undefined);
    const [mount, setMount] = useState(false);

    useEffect(() => {
        !mount && section && setImageUrl(section.logo);
        !mount && section && setName(section.name);
        return () => setMount(true);
    }, [section]);
    useEffect(() => {
        if (file) {
            setImageUrl(URL.createObjectURL(file));
        }
    }, [file]);
    useEffect(() => {
        if (awsUrl !== undefined) {
            dispatch(
                createSection({
                    section: {
                        reference: '',
                        id: 0,
                        version: 0,
                        name: name,
                        // @ts-ignore
                        logo: awsUrl.data.content,
                        catalogId: catalog.id,
                    },
                    catalogId: catalog.id,
                }),
            );
            setAwsUrl(undefined);
            setName('');
            setFile(undefined);
            setOpenModal(false);
        }
    }, [awsUrl]);
    useEffect(() => {
        if (upAwsUrl !== undefined) {
            section &&
            upAwsUrl &&
            dispatch(
                createSection({
                    section: {
                        reference: section.reference,
                        id: section.id,
                        version: section.version,
                        name: upType === 1 ? section.name : name,
                        // @ts-ignore
                        logo: upAwsUrl.data.content,
                        catalogId: catalog.id,
                    },
                    catalogId: catalog.id,
                }),
            );
            cancel();
            setOpenModal(false);
        }
    }, [upAwsUrl]);

    const cancel = () => {
        setOpenModal(false);
        setFile(undefined);
        setName('');
        setNameErr('');
        setFileErr('');
        setSection(undefined);
        setUpAwsUrl(undefined);
        setUpType(undefined);
    };
    const onFileChange = (e: any) => {
        if (e.target && e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };
    const OnSubmit = () => {
        if (name.length === 0 || !file) {
            name.length === 0 && setNameErr('Le nom doit être spécifié');
            !file && setFileErr('Une image doit être téléchargée');
        } else {
            const formData = new FormData();
            formData.append('file', file);
            postFile('v1/documents/upload', formData, () => {
            }, setAwsUrl);
            formData.delete('file');
        }
    };
    const OnUpdate = () => {
        if (section && name === section.name && imageUrl === section.logo) {
            cancel();
            setOpenModal(false);
        } else {
            if (name.length === 0 || !imageUrl) {
                name.length === 0 && setNameErr('Le nom doit être spécifié');
                !imageUrl && setFileErr('Une image doit être téléchargée');
            } else {
                if (section && section.name !== name && section.logo === imageUrl) {
                    dispatch(
                        createSection({
                            section: {
                                reference: section.reference,

                                id: section.id,
                                version: section.version,
                                name: name,
                                // @ts-ignore
                                logo: section.logo,
                                catalogId: catalog.id,
                            },
                            catalogId: catalog.id,
                        }),
                    );
                    cancel();
                }
                if (section && section.logo !== imageUrl && section.name === name) {
                    setUpType(1);
                    const formData = new FormData();
                    file && formData.append('file', file);
                    postFile('v1/documents/upload', formData, () => {
                    }, setUpAwsUrl);
                    formData.delete('file');
                }
                if (section && section.logo !== imageUrl && section.name !== name) {
                    setUpType(2);
                    const formData = new FormData();
                    file && formData.append('file', file);
                    postFile('v1/documents/upload', formData, () => {
                    }, setUpAwsUrl);
                    formData.delete('file');
                }
            }
        }
    };

    return (
        <div className='create-section-main'>
            <div className='create-section-paper'>
                <div className='create-section-top'>
                    <span>{section ? 'Modifier une rubrique' : 'Créer une nouvelle rubrique'}</span>
                    <img onClick={cancel} alt='fermer la modal' src={close} />
                </div>
                <div className='create-section-body'>
                    <div className='create-section-form-name'>
                        <label>Nom</label>
                        <input
                            defaultValue={section && section.name}
                            onChange={(e) => {
                                setNameErr('');
                                setName(e.target.value);
                            }}
                            type='text'
                            placeholder='Nom de la rubrique'
                        />
                        <span>{nameErr}</span>
                    </div>

                    <div className='create-input'>
                        <label>Vignette</label>
                        <input
                            className='inputfile'
                            id='file'
                            ref={formRef}
                            type='file'
                            name='file'
                            accept='image/png, image/jpeg'
                            onChange={(e) => {
                                setFileErr('');
                                onFileChange(e);
                            }}
                        />
                        <label htmlFor='file' id='file'>
                            Ajouter une vignette
                        </label>
                        <span>{fileErr}</span>
                    </div>
                    {imageUrl ? (
                        <div className='prev-img'>
                            <img
                                alt="supprimession"
                                onClick={() => {
                                    setImageUrl(undefined);
                                    setFile(undefined);
                                }}
                                className='delete'
                                src={close}
                            />
                            <img alt='logo fournisseur' className='img' src={imageUrl} />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
                <div className='create-section-btn-box'>
                    <button onClick={cancel} className='btn-close'>
                        Annuler
                    </button>
                    <button onClick={section ? OnUpdate : OnSubmit} className='btn-action'>
                        {section ? 'Éditer' : 'Créer'}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default CreateSection;
