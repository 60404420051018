import Inspector from "../Inspector";
import {MeshPhysicalMaterial} from "three";
import * as THREE from "three";
import {MapType} from "../MapType";

export function EmissiveBind(this: Inspector) {
    let inspectorParameters = this.m_materialEditorParameters;
    const self: any = this;

    this.m_emissiveSlide?.m_emissiveMapShow.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;

        let Mat = self.m_materialEditor.showOnMesh(self.m_currentEditedMaterial, inspectorParameters, 'emissiveMap', ev.value);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
    });
    this.m_emissiveSlide?.m_emissive.BindType( function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.emissiveIntensity = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });

    this.m_emissiveSlide?.m_emissiveColor.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.emissive = new THREE.Color(ev.value.r / 255, ev.value.g / 255, ev.value.b / 255);
        self.m_currentEditedMaterial.emissive.convertSRGBToLinear();
        self.m_currentEditedMaterial.userData.emissiveBuffer = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });
    this.m_emissiveSlide?.m_emissiveMap.BindType(function () {
        if (self.m_materialEditorParameters.emissiveMap.src) {
            self.ToggleSlotDirty();
            self.UpdateMaterialFromUI(MapType.emissiveMap, self.m_materialEditorParameters.emissiveMap, self.m_materialEditorParameters.emissiveMapFlipY, self.m_materialEditorParameters.emissiveMapEncodingDefault);
            self.UpdateInspectorSlotTitle(MapType.emissiveMap, self.m_emissiveSlide?.m_uiFolder);
        } else {
            let folder = self.m_emissiveSlide?.m_uiFolder;
            folder.title = self.m_titlesLabel["emissiveMap"] + " [no texture]";
        }
    }); // strange fact : sometimes we receive map as its data, not img...

    this.m_emissiveSlide?.m_emissiveFlipY.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        let Mat;
        if (self.m_currentEditedMaterial) {
            Mat = self.m_materialEditor.FlipYTexture(['emissiveMap'], ev.value);
        }
        if (self.m_materialUpdatedCallback && Mat) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });

    this.m_emissiveSlide?.m_emissiveClear.BindType(() => {
        if (!self.m_currentEditedMaterial) return;
        let texSlot = self.GetTextureFromSlot(MapType.emissiveMap);
        if (!texSlot) return;
        let Mat = self.m_materialEditor.ClearMap(this.m_materialEditorParameters, [texSlot.slotName]);
        if (this.m_savedMaterial && self.m_currentEditedMaterial && self.m_dirtyGLTFCallback) self.m_dirtyGLTFCallback(this.m_savedMaterial, self.m_currentEditedMaterial);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat.clone() as MeshPhysicalMaterial, true);
        let folder = self.m_emissiveSlide?.m_uiFolder;
        folder.title = this.m_titlesLabel[texSlot.slotName] + " [no texture]";
        this.m_pane.refresh();
    });


}