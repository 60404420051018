import './composable-preset-build.scss';
import close from '../../../../../../../../asset/CloseWindow.svg';
import one from '../../../../../../../../asset/one.png';
import two from '../../../../../../../../asset/two.png';
import send from '../../../../../../../../asset/Envoyer.svg';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CheckedCompositionModule, CompositionModuleResponse, CompositionWrapper, CompoundedModule, MdfApiResponse } from '../../../../../../../../domain/domain';
import ProductStudio from '../../../../../../../../application-3d/application3D-common/Components/ProductStudio/ProductStudio';
import compositionModuleService from '../../../../../../../../api/services/composition/composition-module.service';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../../../../domain/Params/params-interface';
import ConfiguredProductService from '../../../../../../../../api/services/products/configured-product/configured-product.service';
import ObjectDatas from '../../../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas';
import AddModulePanel from './panel/add-module-panel';
import BuildCompositionPanel from './panel/build-composition-panel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../services/store';
import { ProductStudioState } from '../../../../../../../../application-3d/application3D-common/Redux/Reducers/product-studio/ProductStudioReducer';
import DebugDelete from '../../../../../../../../application-3d/application3D-common/Components/UI/Debug/DebugDelete';
import { ObjectParser } from '../../../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/ObjectParser';
import { generateUUID } from 'three/src/math/MathUtils';
import compoundedPresetServices from '../../../../../../../../api/services/composition/compounded-preset-services/compounded-preset.services';

type ComposableBuildProps = {
    setOpenPresetDetail: Dispatch<SetStateAction<boolean>>;
    setCurrentPreset: Dispatch<SetStateAction<CompoundedModule | undefined>>;
    currentPreset: CompoundedModule;
};
const ComposablePresetBuild = (props: ComposableBuildProps) => {
    const { productReference } = useParams<Params>();

    //#region usestate
    const [step, setStep] = useState<number>(1);
    const [rename, setRename] = useState(false);
    const [objectData, setData] = useState<undefined | ObjectDatas>(undefined);
    const [compositionModules, setCompositionModules] = useState<CompositionModuleResponse[]>([]);
    const [possibleCompositionModules, setPossibleCompositionModules] = useState<CompositionModuleResponse[]>([]);
    const [compositionWrapper, setCompositionWrapper] = useState<CompositionWrapper | undefined>(undefined);
    const [buildCompositionModules, setBuildCompositionModules] = useState<CompositionModuleResponse[]>([]);
    const [selectedModule, setSelectedModule] = useState<CheckedCompositionModule | undefined>(undefined);
    const [isMount, setIsmount] = useState(false);
    const [presetName, setPresetName] = useState('');
    //endregion

    //#region useEffect
    useEffect(() => {
        const wrapper = props.currentPreset.metadata as CompositionWrapper;
        if (!wrapper) {
            return;
        }
        setPresetName(props.currentPreset.product ? props.currentPreset.product.name : props.currentPreset.name);
        setCompositionWrapper(props.currentPreset.metadata);
        setData(ObjectParser.RemapData(props.currentPreset.metadata));
        setBuildCompositionModules(wrapper.checkedCompositionModules.map((ccm) => ccm.module));
        setStep(2);
    }, [props.currentPreset]);
    useEffect(() => {
        !isMount &&
        productReference &&
        compositionModuleService
            .getCompositionModuleList(productReference)
            .then((res) => {
                setCompositionModules(res.content);
            })
            .catch(() => {
                setCompositionModules([]);
            });

        return () => setIsmount(true);
    }, []);
    useEffect(() => {
        step === 1 && console.log('wrapper ==>', compositionWrapper);
    }, [step]);
    //endregion

    //#region methods
    const closeModal = () => {
        props.setCurrentPreset(undefined);
        props.setOpenPresetDetail(false);
    };
    const onRename = () => {
        compoundedPresetServices.renameCompoundedPreset(props.currentPreset.id, presetName)
            .then((res) => {
                const response = res as MdfApiResponse<CompoundedModule>;
                setRename(false);
            });
    };
    const onSave = () => {
        console.log('wrapper ===>', compositionWrapper);
        compositionWrapper &&
        compoundedPresetServices.saveCompoundedPresetConfiguration(props.currentPreset.id, compositionWrapper)
            .then(() => {
                closeModal();
            });
    };
    const onModuleClick = (cm: CompositionModuleResponse) => {
        cm.moduleRefOfInstance = generateUUID();
        productReference &&
        ConfiguredProductService.applyRulesOnComposition(productReference, props.currentPreset.id, {
            compositionWrapperOptional: undefined,
            compositionModuleResponseList: [cm],
        }).then((wrapper: MdfApiResponse<CompositionWrapper>) => {
            console.log('=>', wrapper.content);
            setCompositionWrapper(wrapper.content);
            setData(ObjectParser.RemapData(wrapper.content));
            setBuildCompositionModules([...buildCompositionModules, cm]);
            setStep(2);
        });
    };

    return (
        <div className='composable-pb-main'>
            <div className='composable-pb-paper'>
                <div className='composable-pb-top'>
                    <div className='composable-pb-top-left'>
                        <img alt='1er etape' src={step === 1 ? one : two} />
                        <div>
                            {step === 1 ? 'CHOISIR LE MODULE DE DÉPART DU PRESET ' : 'DÉFINIR LA CONFIGURATION DU PRESET '} {props.currentPreset.name.toUpperCase()}
                        </div>
                    </div>
                    <img onClick={closeModal} alt='fermer la modal' src={close} />
                </div>
                <div className='composable-pb-body'>
                    <div className='composable-pb-studio'>
                        <div className='composable-pb-absolute'>
                            {objectData !== undefined && (
                                <div className='abs-rename'>
                                    <span>Nom</span>
                                    {!rename ? (
                                        <div
                                            onClick={() => {
                                                setRename(true);
                                            }}
                                        >
                                            {presetName}
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                onKeyDown={(e) => e.key === 'Enter' && onRename()}
                                                onChange={(e) => setPresetName(e.target.value)}
                                                value={presetName}
                                                placeholder={props.currentPreset.name}
                                                type='text'
                                            />
                                            <img onClick={onRename} src={send} alt='renommer' />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {objectData == undefined ? (
                            <div className='empty-studio'>Choisissez un module de départ</div>
                        ) : (
                            <>
                                <ProductStudio isVisible={true} product={objectData.BasicObjects[0]} datas={objectData} />
                                <DebugDelete
                                    deleteCallback={(val1, val2) => {
                                        console.log('Deleteing hard :', val1, val2);
                                    }}
                                ></DebugDelete>
                            </>
                        )}
                    </div>
                    {step === 1 && <AddModulePanel compositionModules={compositionModules} onModuleClick={onModuleClick} />}
                    {step === 2 && (
                        <BuildCompositionPanel
                            setStep={setStep}
                            setBuildCompositionModules={setBuildCompositionModules}
                            ObjectDatas={objectData}
                            CompositionWrapper={compositionWrapper}
                            RemapData={ObjectParser.RemapData}
                            setData={setData}
                            setPossibleCompositionModules={setPossibleCompositionModules}
                            possibleCompositionModules={possibleCompositionModules}
                            selectedModule={selectedModule}
                            setSelectedModule={setSelectedModule}
                            setCompositionWrapper={setCompositionWrapper}
                            buildCompositionModules={buildCompositionModules}
                            currentPreset={props.currentPreset}
                        />
                    )}
                </div>
                <div className='composable-pb-foot'>
                    {
                        step === 1 && <button className='an' onClick={() => closeModal()}>Annuler</button>
                    }
                    {step === 2 &&
                        <>
                            <button className='an' onClick={() => closeModal()}>Annuler</button>
                            <button className='save' onClick={() => onSave()}>Sauvegarder</button>

                        </>
                    }
                </div>
            </div>
        </div>
    )
        ;
};
export default ComposablePresetBuild;
