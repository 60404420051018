import apiClient from '../../api-client';
import {
    CREATE_WIN_FILTER,
    DELETE_WIN_FILTER,
    FIND_WIN_FILTER,
    WIN_ASSOCIATE_CONFIGURATION,
    WIN_ASSOCIATE_PRODUCT,
    WIN_CONFIGURATION_BY_WIN_KEY,
    WIN_CONFIGURATIONS_ASSOCIATED,
    WIN_DISSOCIATE_CONFIGURATION,
    WIN_DISSOCIATE_PRODUCT,
    WIN_PRODUCTS_ASSOCIATED,
    WIN_RATES,
} from '../../endpoints/win/win.endpoints';
import { MdfApiResponse, TarifWinConfiguration, TarifWinField, WinMatching } from '../../../domain/domain';

type Associate = {
    productReference: string;
    winKey: string;
};

type AssociateConfiguration = {
    matching: WinMatching;
    winKey: string;
    collectionId: number;
};
const winService = {
    winAssociateProduct: async (associate: Associate) => apiClient(WIN_ASSOCIATE_PRODUCT, associate, undefined, 'POST'),
    winDissociateProduct: async (associate: Associate) => apiClient(WIN_DISSOCIATE_PRODUCT, associate, undefined, 'DELETE'),
    winRates: async () => apiClient(WIN_RATES, undefined, undefined, 'GET'),
    winProductsAssociated: async (collectionRef: string) => apiClient(WIN_PRODUCTS_ASSOCIATED(collectionRef), undefined, undefined, 'GET'),

    winAssociateConfiguration: async (associate: AssociateConfiguration | TarifWinConfiguration): Promise<MdfApiResponse<TarifWinConfiguration>> =>
        apiClient(WIN_ASSOCIATE_CONFIGURATION, associate, undefined, 'POST'),
    winDissociateConfiguration: async (associate: AssociateConfiguration | TarifWinConfiguration): Promise<MdfApiResponse<TarifWinConfiguration>> =>
        apiClient(WIN_DISSOCIATE_CONFIGURATION, associate, undefined, 'DELETE'),
    winFindConfigurationByWinKey: async (associate: TarifWinConfiguration): Promise<MdfApiResponse<TarifWinConfiguration>> => apiClient(WIN_CONFIGURATION_BY_WIN_KEY, associate, undefined, 'POST'),
    winConfigurationsAssociated: async (): Promise<MdfApiResponse<TarifWinConfiguration[]>> => apiClient(WIN_CONFIGURATIONS_ASSOCIATED, undefined, undefined, 'GET'),

    findWinFilter: async (collectionRef: string) => apiClient(FIND_WIN_FILTER(collectionRef), undefined, undefined, 'GET'),
    createWinFilter: async (collectionRef: string, tarifWinField: TarifWinField, value: string) => apiClient(CREATE_WIN_FILTER(collectionRef, tarifWinField, value), undefined, undefined, 'POST'),
    deleteWinFilter: async (winFilterId: number) => apiClient(DELETE_WIN_FILTER(winFilterId), undefined, undefined, 'DELETE'),
};
export default winService;
