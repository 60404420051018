import React, { useEffect, useState } from 'react';
import { News, NewsCategory, NewsStatus } from '../../../domain/domain';
import Tag from '../../tag/tag';
import HoverIcon from '../../hover-icon/hover-icon';
import DateFormatter from '../../../utils/date-formatter';
import { useHistory } from 'react-router-dom';

import update from '../../../asset/Editer.svg';
import blueUpdate from '../../../asset/EditerBleu.svg';
import archiver from '../../../asset/Archiver.svg';
import archiverBlanc from '../../../asset/Archiver blanc.svg';
import blueArchiver from '../../../asset/ArchiverBleu.svg';
import trash from '../../../asset/poubelle-red.svg';
import trashModal from '../../../asset/trash-popup.png';
import Modal, { ModalSize } from '../../../uicomponents/modal/modal';
import { addModal, removeModal } from '../../../services/reducers/modal/modal.slice';
import { useDispatch } from 'react-redux';

import SimpleModal from '../../modal/simple-modal/simple-modal';

import './ui-table.scss';

type UiTableProps = {
    headers: string[];
    list: News[];
    categories?: NewsCategory[];
    getCategoryName: (id: number) => NewsCategory | undefined;
    onArchiveNews: (newsId: number, status: NewsStatus) => void;
    onDeleteNews?: (newsId: number) => void;
    setPreview: (preview: boolean) => void;
    setNewsPreview: (newsPreview: News) => void;
};

const UiTable = ({ headers, list, getCategoryName, onArchiveNews, setPreview, setNewsPreview, onDeleteNews }: UiTableProps) => {
    useEffect(() => {}, [list]);

    const history = useHistory();
    const [openModal, setOpenModal] = useState(false);
    const [dataId, setDataId] = useState<number>(0);
    const closeModal = () => {
        setOpenModal(false);
    };
    const openModalFunc = (data: News) => {
        setOpenModal(true);
        setDataId(data.id);
    };
    const handleArchive = () => {
        onArchiveNews(dataId, 'ARCHIVED');
        setOpenModal(false);
    };
    const dispatch = useDispatch();
    const [newsToBeDeleted, setNewsToBeDeleted] = useState(-1);
    const confirmationModal = 'confirmation-modal-open';
    return (
        <React.Fragment>
            {openModal && (
                <SimpleModal
                    icon={archiverBlanc}
                    title="Archiver cette publication ?"
                    info="Celle-ci ne sera plus visible par les collaborateurs"
                    closeOnclick={closeModal}
                    actionOnclick={handleArchive}
                    deleteOrValide={true}
                    closeLabel="Annuler"
                    actionLabel="Confirmer"
                />
            )}

            <Modal
                validateLabel="Confirmer"
                closeLabel="Annuler"
                onClick={() => {
                    if (onDeleteNews) {
                        onDeleteNews(newsToBeDeleted);
                        dispatch(removeModal(confirmationModal));
                    }
                }}
                id={confirmationModal}
                title="Suppression News"
                icon={trashModal}
                size={ModalSize.LARGE}
                colorValidateButton="#E95D5D"
            >
                <p>Veuillez confirmer ou annuler la suppression de la news séléctionnée!</p>
            </Modal>

            <table className="news-table-main">
                <thead className="news-table-head">
                    <tr className="news-table-head-tr">
                        {headers.map((header) => {
                            return <th key={header}>{header}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {list.map((data) => {
                        const message = data.content;
                        const prevMessage = message.substring(0, 10);
                        const prevTitle = data.title.substring(0, 25);
                        const openPreview = () => {
                            setPreview(true);
                            setNewsPreview(data);
                        };
                        return (
                            <tr className="main-tr" key={data.id}>
                                <td onClick={openPreview}>
                                    <div className={data.criticality === 'CRITIQUE' ? 'news-critique' : 'news'}>
                                        {data.title.length < 25 ? data.title : `${prevTitle}...`}
                                        <span dangerouslySetInnerHTML={{ __html: prevMessage }} />
                                    </div>
                                </td>
                                <td onClick={openPreview} className="publish-date">
                                    {data.status.toString() === 'PUBLISHED' && DateFormatter.dateTimeStampToDateTime(data.publicationDate)}
                                    {data.status.toString() === 'SCHEDULED' && DateFormatter.dateTimeStampToDateTime(data.scheduledDate)}
                                    {data.status.toString() === 'DRAFT' && DateFormatter.dateTimeStampToDateTime(data.createdAt)}
                                </td>
                                <td onClick={openPreview} className="archived-date">
                                    {DateFormatter.dateTimeStampToDateTime(data.expiredDate) === '--/--/--' ? <></> : <>{DateFormatter.dateTimeStampToDateTime(data.expiredDate)}</>}
                                </td>
                                <td onClick={openPreview}>
                                    <Tag newsCategoryId={data.newsCategoryId} width={180} title={getCategoryName(data.id)?.name ?? ''} />
                                </td>
                                <td>
                                    <div className="action-main">
                                        <button
                                            style={{ background: 'Transparent', border: 'none' }}
                                            onClick={() => {
                                                history.push(`/update-news/${data.id}`);
                                            }}
                                        >
                                            <HoverIcon icon={update} iconHover={blueUpdate} title="Editer" />
                                        </button>

                                        <button style={{ background: 'Transparent', border: 'none' }} onClick={() => openModalFunc(data)} disabled={data.status.toString() === 'ARCHIVED'}>
                                            <HoverIcon icon={archiver} iconHover={blueArchiver} title="Archiver" />
                                        </button>
                                        {data.status.toString() === 'DRAFT' || data.status.toString() === 'ARCHIVED' ? (
                                            <button
                                                style={{ background: 'Transparent', border: 'none' }}
                                                onClick={() => {
                                                    setNewsToBeDeleted(data.id);
                                                    dispatch(addModal(confirmationModal));
                                                }}
                                            >
                                                <HoverIcon icon={trash} iconHover={trash} title="Supprimer" />
                                            </button>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default UiTable;
