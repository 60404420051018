import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Object3D, Spherical} from "three";
import {
    PointOfViewPlacement
} from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/PointOfViews";
import PointOfViewManager from "../../../application3D-common/Components/PointOfView/PointOfViewManager";
import {
    CharacteristicStudioState, setAllPointOfViewPlacements,
    setPointOfViewPlacement
} from "../../Redux/Reducers/characteristic-studio/CharacteristicStudioReducer";
import {RootState} from "../../../../services/redux/root-reducers";

export type CharacteristicPOVManagerProps = {
    cameraPointOfView?: PointOfViewPlacement
    object?: Object3D
    debugMode?: boolean;
};

const CharacteristicPOVManager = (props: CharacteristicPOVManagerProps) => {
    const { characteristicStudioApp, pointOfViewPlacement, placements } = useSelector<RootState, CharacteristicStudioState>((state) => state.characteristicStudioReducer);
    const dispatch = useDispatch();

    let setPointOfView = (p_pov: number) => {
        if (p_pov == null) return;
        dispatch(setPointOfViewPlacement(p_pov));
    };

    useEffect(() => {
        if(props.cameraPointOfView) setPointOfView(props.cameraPointOfView);
    }, [characteristicStudioApp, props.cameraPointOfView]);

    return (
        <>
            {characteristicStudioApp && 
            <PointOfViewManager 
                cameraService={characteristicStudioApp.Services.Cameras}
                cameraPointOfView={pointOfViewPlacement}
                allPlacements={placements}
                object={props.object}
                debugMode={false}
                setAllPovs={(p_povs: Array<{ placement: PointOfViewPlacement, position: Spherical }>)=>{dispatch(setAllPointOfViewPlacements(p_povs));}}
                setCurrentPov= {(p_pov: number)=>{setPointOfView(p_pov);}}
            />}
    </>);
};

export default CharacteristicPOVManager;