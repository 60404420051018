import {InspectorType} from "./InspectorType";
import MaterialEditorParameters from "../MaterialEditorParameters";

export class TextType extends InspectorType {
    get Text(): string {
        return this.m_text;
    }
    
    private readonly m_text: string;
    constructor(p_text: string, matParam: MaterialEditorParameters, paramName: string, uiFolder: any) {
        super(matParam, paramName, uiFolder);
        this.m_text = p_text;
    }

    public InitType() {
        this.m_uiFolder[this.m_paramName] = this.m_uiFolder.addMonitor(this, 'm_text', {
            label: this.m_paramName,
            multiline: true,
        });
    }
}