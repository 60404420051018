import apiClient from '../../api-client';
import { RESET_PASSWORD } from '../../endpoints/password/reset-password.endpoint';

type PasswordObj = {
    oldPassword: string;
    newPassword: string;
};

const passwordServices = {
    resetPassword: async (userId: number, passwordObj:PasswordObj ) => apiClient(RESET_PASSWORD(userId),passwordObj,undefined,'PUT' )
};

export default passwordServices;