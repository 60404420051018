import {InspectorType} from "./InspectorType";
import {SetTypeOfCell} from "../../../Libraries/TweakPaneUtilities";
import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {MeshPhysicalMaterial} from "three";

export class BooleanType extends InspectorType {
    private m_label : string;
    
    constructor(label: string, matParam: MaterialEditorParameters, paramName: string, uiFolder: any) {
        super(matParam, paramName, uiFolder);
        this.m_label = label;
    }
    public InitType(){
       this.m_uiFolder[this.m_paramName] = this.m_uiFolder.addInput(this.m_materialEditorParam, this.m_paramName, {label: this.m_label});
    }
}
