const GET_SUB_SECTIONS_BY_SECTION = (sectionId: number) => `v1/section/${ sectionId }/sub-sections/`;
const GET_SUB_SECTION_SECTION_BY_ID = (subSectionId: number) => `v1/section/sub-sections/${ subSectionId }`;
const CREATE_SUB_SECTION = (sectionId: number) => `v1/section/${ sectionId }/sub-sections/`;
const UPDATE_SUB_SECTION = (subSectionId: number) => `v1/section/subsection/${ subSectionId }`;
const DELETE_SUB_SECTION = (subSectionId: number) => `v1/section/sub-sections/${ subSectionId }`;
const LINK_PRESET_TO_SUB_SECTION = (catalogId: number, subSectionId: number, productId: number) => `v1/products-catalogs/${ catalogId }/sub-section/${ subSectionId }/product/${ productId }/`;
const UNLINK_PRESET_TO_SUB_SECTION = (catalogId: number, subSectionId: number, productId: number) => `v1/products-catalogs/${ catalogId }/sub-section/${ subSectionId }/product/${ productId }/`;
const FIND_SUB_SECTIONS_BY_PRODUCT_ID = (productId: number) => `v1/products-catalogs/product/${ productId }/sub-sections/`;

export {
    GET_SUB_SECTIONS_BY_SECTION,
    GET_SUB_SECTION_SECTION_BY_ID,
    CREATE_SUB_SECTION,
    DELETE_SUB_SECTION,
    UPDATE_SUB_SECTION,
    LINK_PRESET_TO_SUB_SECTION,
    UNLINK_PRESET_TO_SUB_SECTION,
    FIND_SUB_SECTIONS_BY_PRODUCT_ID
};
