import Layout from '../../../../../uicomponents/layout/layout';
import MainPaper from '../../../../../uicomponents/paper/main-paper';
import ProductDetailNav from './product-detail-nav/product-detail-nav';
import { useEffect, useState } from 'react';
import ProductDetailInfo from './product-detail-info/product-detail-info';
import ProductDetailCharacteristic from './product-detail-characteristic/product-detail-characteristic';
import {
    getProductById,
    ProductInfoState,
    ProductState,
    resetSaveProductCara,
} from '../../../../../services/reducers/product/product.reducers';
import { getProductTag } from '../../../../../services/reducers/tag/tag.slice';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../domain/Params/params-interface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../services/root-reducer';
import ProductDetail3d from './product-detail-3d/product-detail-3d';
import ProductDetailThumbnail from './product-detail-thumbnail/product-detail-thumbnail';
import PresetsDetail3d from './product-presets-detail/product-presets-detail';
import {
    Model3dState,
    resetSaveProductModels3d,
} from '../../../../../services/reducers/product/3DModels/3DModels.reducers';
import ProductDetailRules from './product-detail-rules/product-detail-rules';
import ProductSimpleThumb from './product-detail-thumbnail/product-simple-thumb/product-simple-thumb';
import ComposableInfo from './composable/composable-info/composable-info';
import ComposableModule from './composable/composable-module/composable-module';
import ComposablePreset from './composable/composable-preset/composable-preset-list/composable-preset-list';


const ProductDetail = () => {
    const { productReference } = useParams<Params>();
    const dispatch = useDispatch();
    const saveCaraRes = useSelector<RootState, ProductState>((state) => state.saveProductCara);
    const save3dRes = useSelector<RootState, Model3dState>((state) => state.saveProductModel3d);

    const [focus, setFocus] = useState<number>(5);
    const [step, setStep] = useState<number>(5);
    const product = useSelector<RootState, ProductInfoState>((state) => state.getProductionById);
    const [openModal, setOpenModal] = useState(false);
    const [openPresetModal, setOpenPresetModal] = useState(false);

    useEffect(() => {
        setFocus(0);
        productReference && dispatch(getProductById(productReference));
        dispatch(getProductTag());
    }, []);
    useEffect(() => {
        if (product.payload.content && product.payload.errors.length < 1) {
            //setStep(product.payload.content.first.creationStep);
        }
    }, [product]);
    useEffect(() => {
        if (saveCaraRes.payload.content && saveCaraRes.payload.errors.length < 1) {
            setStep(2);
            setFocus(2);
            dispatch(resetSaveProductCara());
        }
    }, [saveCaraRes]);
    useEffect(() => {
        if (save3dRes.payload.content.name.length > 0 && save3dRes.payload.errors.length < 1) {
            dispatch(resetSaveProductModels3d());
        }
    }, [save3dRes]);

    return (
        <Layout title='' pathUrl='collection'>
            <ProductDetailNav
                setOpenModule={ setOpenModal }
                setOpenPreset={ setOpenPresetModal }
                product={ product.payload.content }
                step={ step }
                focus={ focus } setFocus={ setFocus } />
            <MainPaper>
                { product.payload.content && product.payload.content.first.productType === 'CONFIGURABLE' && (
                    <>
                        { focus === 0 && <ProductDetailInfo focus={ focus } setFocus={ setFocus } /> }
                        { focus === 1 &&
                            <ProductDetailCharacteristic
                                currentProduct={ product.payload.content }
                                setFocus={ setFocus }
                                focus={ focus }
                                setStep={ setStep }
                                productRef={ product.payload.content?.first.reference } /> }
                        { focus === 2 &&
                            <ProductDetail3d setFocus={ setFocus } setStep={ setFocus }
                                             product={ product.payload.content.first } /> }
                        { focus === 3 &&
                            <ProductDetailRules
                                product={ product.payload.content.first }
                                setStep={ setStep }
                                setFocus={ setFocus } /> }
                        { focus === 4 && <ProductDetailThumbnail setFocus={ setFocus } setStep={ setStep } /> }
                        { focus === 5 && <PresetsDetail3d product={ product.payload.content.first }></PresetsDetail3d> }
                    </>
                ) }
                { product.payload.content && product.payload.content.first.productType === 'SIMPLE' && (
                    <>
                        { focus === 0 && <ProductDetailInfo focus={ focus } setFocus={ setFocus } /> }
                        { focus === 1 && <ProductDetail3d setStep={ setStep } setFocus={ setFocus }
                                                          product={ product.payload.content.first } /> }
                        { focus === 2 && <ProductSimpleThumb product={ product.payload.content.first } /> }
                    </>
                ) }
                { product.payload.content && product.payload.content.first.productType === 'COMPOSITION' && (
                    <>
                        { focus === 0 && <ComposableInfo
                            focusi={ focus }
                            setStep={ setStep }
                            setFocusi={ setFocus }
                            currentProduct={ product.payload.content } /> }
                        { focus === 1 &&
                            <ProductDetailCharacteristic
                                currentProduct={ product.payload.content }
                                setFocus={ setFocus }
                                focus={ focus }
                                setStep={ setStep }
                                productRef={ product.payload.content?.first.reference } /> }
                        { focus === 2 &&
                            <ProductDetail3d setFocus={ setFocus } setStep={ setFocus }
                                             product={ product.payload.content.first } /> }
                        { focus === 3 &&
                            <ComposableModule
                                setStep={ setStep }
                                setFocus={ setFocus }
                                setOpenModal={ setOpenModal }
                                currentProduct={ product.payload.content }
                                openModule={ openModal } /> }
                        { focus === 4 && <ProductDetailRules
                            product={ product.payload.content.first }
                            setStep={ setStep }
                            setFocus={ setFocus } /> }
                        { focus === 5 && <ComposablePreset
                            setOpenModal={ setOpenPresetModal }
                            openCreateModal={ openPresetModal } /> }
                    </>
                ) }
            </MainPaper>
        </Layout>
    );
};
export default ProductDetail;
