import React, { useEffect, useState } from 'react';
import Layout from '../../../uicomponents/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { VignetteEnum } from '../../../domain/domain';
import { useHistory, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RootState } from '../../../services/root-reducer';
import {
    createFinish,
    CreateFinishState,
    resetCreateFinish,
} from '../../../services/reducers/suppliers/finish/finishes.slice';
import { formErrors } from '../../../utils/form-errors';
import MainPaper from '../../../uicomponents/paper/main-paper';
import { createTag, CreateTagState, getFinishTag, GetFinishTagState } from '../../../services/reducers/tag/tag.slice';
import TagSelector from '../../../components/tag-selector/tag-selector';
import { FindTagsByFinishIdState } from '../../../services/reducers/suppliers/suppliers.slice';
import './create-finition-page.scss';
import isMount from '../../../components/IsMount';
import { returnadvice } from '../../../components/modals/mapping-zoning/mapping/create-mapping';


type Inputs = {
    name: string;
    reference: string;
    typeVignette: VignetteEnum;
    tagIdList: number[];
    technicalDescription: string;
    commercialDescription: string;
};

export const CreateFinitionPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        watch,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<Inputs>({});

    const createFinishRes = useSelector<RootState, CreateFinishState>((state) => state.createFinish);
    const getFinishTagRes = useSelector<RootState, GetFinishTagState>((state) => state.getFinishTag);
    const createTags = useSelector<RootState, CreateTagState>((state) => state.createTag);
    const tagsByFinishIdRes = useSelector<RootState, FindTagsByFinishIdState>((state) => state.findTagsByFinishId);

    const params: { supplierReference: string } = useParams();
    const [serverErrCode, setServerErrCode] = useState('');
    const [tagList, setTagList] = useState<number[]>([]);
    const [tagListEmpty, setTagListEmpty] = useState(false);
    const [taglistErr, setTagListErr] = useState<string | undefined>();

    useEffect(() => {
        setTagList(tagsByFinishIdRes.payload.content.map((tag) => tag.id));
    }, [tagsByFinishIdRes]);
    useEffect(() => {
        dispatch(getFinishTag('FINISH'));
    }, [createTags]);
    useEffect(() => {
        if (createFinishRes.payload.content !== undefined && createFinishRes.payload.errors.length < 1) {
            let finishs = createFinishRes.payload.content;

            history.push(`/supplier/${finishs.supplierReference}/finitions`);
            dispatch(resetCreateFinish());
        } else {
            createFinishRes.payload.errors.length > 0 && setServerErrCode(createFinishRes.payload.errors[0].code);
        }
    }, [createFinishRes]);
    useEffect(() => {
        dispatch(getFinishTag('FINISH'));
    }, []);

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        tagList.length < 1
            ? setTagListEmpty(true)
            : params.supplierReference &&
            dispatch(
                createFinish({
                    supplierReference: params.supplierReference,
                    data: {
                        logoUrl: '',
                        name: data.name,
                        tagIdListPrix: [],
                        typeVignette: data.typeVignette,
                        reference: data.reference,
                        tagIdList: tagList,
                        technicalDescription: data.technicalDescription,
                        businessDescription: data.commercialDescription,
                    },
                }),
            );
    };
    const createFinishTag = (tagName: string) => {
        dispatch(createTag({ tagEntityType: 'FINISH', tagName: tagName }));
        dispatch(getFinishTag('FINISH'));
    };
    const name = watch('name');

    return (
        <Layout info="Voulez vous quitter la création d'une nouvelle finition ?"
                labelBack="Quitter la création d'une finition ?" title='Créer une finition' pathUrl='supplier'>
            <MainPaper>
                <form
                    className='create-finition-form'

                    onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-left'>
                        <h6>INFORMATIONS GÉNÉRALES</h6>
                        <label htmlFor='name'>Nom</label>
                        <input
                            placeholder='Nom de la finition'
                            {...register('name', {
                                required: true,
                            })}
                        />
                        {returnadvice(name, 24)}
                        {errors && formErrors(errors.name)}

                        <label>Type de vignette</label>
                        <select {...register('typeVignette', { required: true })}>
                            <option value='A_PLAT'>Aplat</option>
                            <option value='TISSUS'>Tissu</option>
                            <option value='SPHERE'>Sphère</option>
                        </select>
                        {errors && formErrors(errors.typeVignette)}

                        <label htmlFor='Tags'>Matière(s)</label>

                        <TagSelector
                            tagListEmpty={tagListEmpty}
                            onChange={(newTagIdList) => {
                                setTagList(newTagIdList);
                            }}
                            selectedTags={tagList}
                            onCreate={(tagName) => {
                                createFinishTag(tagName);
                            }}
                            tagList={getFinishTagRes.payload.content}
                            placeholder='Ajouter une matière'
                        />
                        <span className='tag-err'>{taglistErr}</span>
                    </div>

                    <div className='form-right'>
                        <h6>DESCRIPTIONS</h6>
                        <>
                            <label htmlFor='technicalDescription'>Description technique</label>
                            <textarea onKeyDown={(e) => e.key === 'Enter' && console.log('')}
                                      placeholder='Description technique' {...register('technicalDescription')} />
                            {errors && formErrors(errors.technicalDescription)}
                        </>
                        <div className='btn-box'>
                            <button onClick={() => {
                                tagList.length === 0 && setTagListErr('Ce champ est requis');
                            }} type='submit' className='btn-submit'>
                                Créer
                            </button>
                        </div>
                    </div>
                </form>
            </MainPaper>
        </Layout>
    );
};

export default CreateFinitionPage;
