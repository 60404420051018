import './create-collection.scss';
import Layout from '../../../uicomponents/layout/layout';
import MainPaper from '../../../uicomponents/paper/main-paper';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formErrors } from '../../../utils/form-errors';
import React, { useEffect, useRef, useState } from 'react';
import {
    CollectionState,
    createCollection,
    resetCreateCollection,
} from '../../../services/reducers/suppliers/collection/collection.slice';
import { RootState } from '../../../services/root-reducer';
import axios from 'axios';
import { getConfig } from '../../../api/config';
import { returnadvice } from '../../../components/modals/mapping-zoning/mapping/create-mapping';

type Inputs = {
    name: string;
    logo: string;
};
const CreateCollection = () => {

    const {
        watch,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<Inputs>({});

    const dispatch = useDispatch();
    const history = useHistory();
    const fileRef = useRef();
    const params: { supplierReference: string } = useParams();

    const [img, setImg] = useState(undefined);
    const [imgUrl, setImgUrl] = useState();
    const [file, setFile] = useState();
    const [collectionName, setCollectionName] = useState<string | undefined>(undefined);
    const cName = watch('name');
    const createCollectionRes = useSelector<RootState, CollectionState>((state) => state.createCollection);

    const handleChange = (e: any) => {
        const [fileTarget] = e.target.files;
        // @ts-ignore
        setImg(URL.createObjectURL(fileTarget));
        setFile(fileTarget);
    };


    useEffect(() => {
        if (createCollectionRes.payload.content !== undefined && createCollectionRes.payload.errors.length < 1) {
            history.goBack();
            dispatch(resetCreateCollection());
            setCollectionName(undefined);
            setImgUrl(undefined);
        }
    }, [createCollectionRes]);

    useEffect(() => {
        if (imgUrl !== undefined && collectionName !== undefined) {
            dispatch(
                createCollection({
                    supplierReference: params.supplierReference,
                    data: {
                        reference: '',
                        logo: imgUrl,
                        name: collectionName,
                    },
                }),
            );
        }
    }, [imgUrl]);

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        if (img !== undefined && file !== undefined) {
            const formData = new FormData();
            formData.append('file', file);
            setCollectionName(data.name);
            axios
                .post(`${getConfig().urlHostApi}/v1/documents/upload`, formData, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('mdf-jwt')}` },
                })
                .then((res) => {
                    setImgUrl(res.data.content);
                })
                .catch((e) => {
                    console.log('erreur', e);
                });
        } else {
            dispatch(
                createCollection({
                    supplierReference: params.supplierReference,
                    data: {
                        reference: '',
                        logo: '',
                        name: data.name,
                    },
                }),
            );
        }
    };

    return (
        <Layout info="Voulez vous quitter la création d'une nouvelle collection?"
                labelBack="Quitter la création d'une collection ?"
                title='Créer une collection'
                pathUrl='supplier'>
            <MainPaper>
                <form className='create-collection-form' onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-left'>
                        <h6>INFORMATIONS GÉNÉRALES</h6>
                        <label htmlFor='name'>Nom</label>
                        <input
                            className={errors.name ? 'input-error' : 'input'}
                            {...register('name', {
                                required: true,
                                maxLength: 24,
                            })}
                        />
                        {returnadvice(cName, 20)}
                        <div className='err-bloc'>{errors && formErrors(errors.name, 24)}</div>
                        <div className='file-bloc'>
                            <label htmlFor='logo'>logo</label>
                            <div
                                className='input-button'
                                onClick={() => {
                                    // @ts-ignore
                                    fileRef.current.click();
                                }}
                            >
                                Ajouter un logo
                            </div>
                            <input
                                // @ts-ignore
                                ref={fileRef}
                                onChange={handleChange}
                                multiple={false}
                                type='file'
                                hidden
                            />
                            {img && (
                                <div className='prev'>
                                    <div onClick={() => setImg(undefined)}>X</div>
                                    <img alt='preview' src={img} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='form-right'>
                        <div className='btn-box'>
                            <button type='submit' className='btn-action'>
                                Créer
                            </button>
                        </div>
                    </div>
                </form>
            </MainPaper>
        </Layout>
    );
};
export default CreateCollection;
