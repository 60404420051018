import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import SplitPaper from '../paper/split-paper';
import SimpleModal from '../modal/simple-modal/simple-modal';
import send from '../../asset/Envoyer blanc.svg';
import { getSupplier, GetSupplierState, resetUpdateSupplier, updateSupplier, UpdateSupplierState } from '../../services/reducers/suppliers/suppliers.slice';
import Textfield from '../textfield/textfield';
import UiError from '../errors/ui-error';
import { Button } from '../button/button';
import { RootState } from '../../services/root-reducer';
import axios from 'axios';
import { getConfig } from '../../api/config';

const UpdateSupplierForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const formR = useRef<HTMLInputElement>(null);
    const token = localStorage.getItem('mdf-jwt');

    const { payload } = useSelector<RootState, GetSupplierState>((state) => state.getSupplier);
    const updateRes = useSelector<RootState, UpdateSupplierState>((state) => state.updateSupplier);
    // @ts-ignore
    const { reference } = useParams();

    const [name, setName] = useState('');
    const [isNameErr, setIsNameErr] = useState(false);
    const [nameErrMsg, setNameErrMsg] = useState('');

    const [ref, setRef] = useState('');
    const [isRefErr, setIsRefErr] = useState(false);
    const [refErrMsg, setRefErrMsg] = useState('');

    const [isServerErr, setIsServerErr] = useState<boolean>(false);
    const [serverErrMsg] = useState('Une erreur inconnue est survenue ');

    const [file, setFile] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const [openM, setOpenM] = useState(false);

    const checkError = () => {
        if (name.length < 1 || ref.length < 1) {
            if (name.length < 1) {
                setIsNameErr(true);
                setNameErrMsg('Le nom doit être spécifié');
            }
            if (ref.length < 1) {
                setIsRefErr(true);
                setRefErrMsg('La référence doit être spécifiée');
            }
            return false;
        } else {
            return true;
        }
    };
    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files !== null) {
            setFile(e.target.files[0]);
        }
    };
    const onSubmit = () => {
        let formData = new FormData();
        if (formR.current !== null && formR.current.files !== null) {
            formData.append('file', formR.current.files[0]);
            if (formR.current.files[0] === undefined) {
                dispatch(
                    updateSupplier({
                        reference,
                        supplier: {
                            name: name,
                            reference: ref,
                            logo: imageUrl,
                        },
                    })
                );
            } else {
                axios({
                    method: 'post',
                    url: `${getConfig().urlHostApi}/v1/documents/upload`,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then(function (res) {
                        dispatch(
                            updateSupplier({
                                reference,
                                supplier: {
                                    name: name,
                                    reference: ref,
                                    logo: res.data.content,
                                },
                            })
                        );
                    })
                    .catch(function (response) {
                        console.log(response);
                    });
            }
        }
    };

    const openModal = () => {
        if (checkError()) {
            setOpenM(true);
        }
    };

    useEffect(() => {
        dispatch(getSupplier(reference));
    }, []);
    useEffect(() => {
        if (file) {
            setImageUrl(URL.createObjectURL(file));
        }
    }, [file]);
    useEffect(() => {
        if (payload.content !== undefined) {
            setName(payload.content.name);
            setRef(payload.content.reference);
            setImageUrl(payload.content.logo);
        }
    }, [payload]);
    useEffect(() => {
        if (updateRes.payload.content !== null && updateRes.payload.errors.length === 0) {
            dispatch(resetUpdateSupplier());
            history.push('/home/suppliers');
        }
    }, [updateRes]);
    return (
        <SplitPaper>
            {openM && (
                <SimpleModal
                    icon={send}
                    title="Modifier un fournisseur"
                    info="Cette action modifieras le fournisseur "
                    closeOnclick={() => {
                        setOpenM(false);
                    }}
                    actionOnclick={onSubmit}
                    deleteOrValide={false}
                    closeLabel="Annuler"
                    actionLabel="Modifier"
                />
            )}
            <div className="supplier-form">
                <h6>INFORMATIONS GÉNÉRALES</h6>
                <div className="form">
                    <Textfield
                        onChange={(v) => {
                            setNameErrMsg('');
                            setIsNameErr(false);
                            setIsServerErr(false);
                            setName(v);
                        }}
                        isError={isNameErr}
                        errorMessage={nameErrMsg}
                        label="Nom du fournisseur"
                        value={name}
                        placeholder="Nom du fournisseur"
                    />
                    <Textfield
                        onChange={(v) => {
                            setIsRefErr(false);
                            setRefErrMsg('');
                            setIsServerErr(false);

                            setRef(v);
                        }}
                        label="Réference du fournisseur"
                        value={ref}
                        isError={isRefErr}
                        errorMessage={refErrMsg}
                        placeholder="Réference du fournisseur"
                        isReadOnly={true}
                    />
                </div>
                <div className="download-box">
                    <div className="input">
                        <label>Logo du fournisseur </label>
                        <input
                            ref={formR}
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                onFileChange(e);
                            }}
                        />
                    </div>
                    {(imageUrl && imageUrl.length > 0) || file ? (
                        <div className="prev-img">
                            <div
                                onClick={() => {
                                    setFile(null);
                                    setImageUrl(null);
                                }}
                                className="delete"
                            >
                                {' '}
                                X
                            </div>
                            <img
                                className="img"
                                alt="logo du fournisseur"
                                // @ts-ignore
                                src={imageUrl}
                            />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            </div>
            <div className="error-panel">
                <div className="errorMsg">{isServerErr && <UiError errorMessage={serverErrMsg} />}</div>
                <Button label="Modifier" primary size="large" onClick={openModal} />
            </div>
        </SplitPaper>
    );
};
export default UpdateSupplierForm;
