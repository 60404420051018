import {InspectorType} from "./InspectorType";
import MaterialEditorParameters from "../MaterialEditorParameters";

export class SliderData {
    get Step(): number {
        return this.m_step;
    }
    get High(): number {
        return this.m_high;
    }
    get Low(): number {
        return this.m_low;
    }
    
    private m_low: number;
    private m_high: number;
    private m_step: number;
    constructor(low: number, high: number, step:number) {
        this.m_low = low;
        this.m_high = high;
        this.m_step = step;
    }
}

export class SliderType extends InspectorType {
    
    private m_sliderData: SliderData;
    
    constructor(sliderParam: SliderData, matParam: MaterialEditorParameters, paramName: string, uiFolder: any) {
        super(matParam, paramName, uiFolder);
        this.m_sliderData = sliderParam;
    }
    public InitType(){
        this.m_uiFolder[this.m_paramName] = this.m_uiFolder.addInput(this.m_materialEditorParam, this.m_paramName, {
            min: this.m_sliderData.Low,
            max: this.m_sliderData.High,
            step: this.m_sliderData.Step,
        });
    }

}
