import React from 'react';
import { useDispatch } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formErrors } from '../../../../../utils/form-errors';
import close from '../../../../../asset/CloseWindow.svg';
import {
    createConfiguredProduct,
} from '../../../../../services/reducers/product/configured-product/configured-product.reducers';
import { returnadvice } from '../../../mapping-zoning/mapping/create-mapping';


type CreatePresetElementProps = {
    setOpenCreateModalSecondStep: (openCreateModalSecondStep: boolean) => void;
    setOpenModal: (openModal: boolean) => void;
    productRef?: string;
    setPresetReference: (presetReference: string) => void;
};
type CreatePresetElementInput = {
    name: string;
};
const CreatePresetElement = (props: CreatePresetElementProps) => {
    const dispatch = useDispatch();
    const {
        watch,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<CreatePresetElementInput>({});
    const Pname = watch('name');
    const onSubmit: SubmitHandler<CreatePresetElementInput> = (data) => {
        props.productRef && dispatch(createConfiguredProduct({ name: data.name, coreProductReference: props.productRef }));
    };

    const keyDownHandler = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    };

    return (
        <div className='create-group-main'>
            <div className='create-group-paper'>
                <div className='create-group-top'>
                    <img onClick={() => {
                        props.setOpenModal(false);
                    }} alt='fermer la modal ' src={close} />
                    <div
                        className='create-group-title'>
                        Nommer votre preset
                    </div>
                </div>
                <div className='create-group-body'>
                    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={keyDownHandler}>
                        <div className='input-container'>
                            <label htmlFor='name'>Nom</label>
                            <input
                                placeholder='Nom du preset'
                                {...register('name', {
                                    required: true,
                                })} />
                            {returnadvice(Pname, 20)}
                            <div className='err-box'>{errors && formErrors(errors.name)}</div>
                        </div>
                        <div className='btn-box'>
                            <button
                                onClick={() => {
                                    props.setOpenModal(false);
                                }}
                                className='btn-close'>
                                Annuler
                            </button>
                            <button
                                className='btn-action'
                                onClick={() => {
                                }}
                            >Suivant {'>'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreatePresetElement;
