const GET_ALL_NEWS = '/v1/bo/news/';
const CREATE_NEWS = '/v1/bo/news/';
const GET_NEWS_CATEGORIES = '/v1/news/categories';
const GET_FILTER_NEWS = `/v1/news/search/`;
const ARCHIVE_NEWS = `/v1/bo/news/archive/`;
const GET_NEWS_STATUS_COUNT = `/v1/news/status/count`;
const CREATE_CATEGORY = (name: string, color: string) => `/v1/bo/news/categories/${name}/color/${color}`;
const DELETE_NEWS_CATEGORY = (newsCategoryId: number) => `/v1/bo/news/categories/delete/${newsCategoryId}`;
const GET_NEWS = (newsId: number) => `/v1/bo/news/getNews/${newsId}`;
const UPDATE_NEWS = (newsId: number) => `/v1/bo/news/update-news/${newsId}`;
const DELETE_NEWS = (newsId: number) => `/v1/bo/news/delete/${newsId}`;
export {
    GET_ALL_NEWS,
    CREATE_NEWS,
    GET_NEWS_CATEGORIES,
    GET_FILTER_NEWS,
    ARCHIVE_NEWS,
    CREATE_CATEGORY,
    DELETE_NEWS_CATEGORY,
    GET_NEWS_STATUS_COUNT,
    GET_NEWS,
    UPDATE_NEWS,
    DELETE_NEWS,
};