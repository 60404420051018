import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { News, NewsCriticality, NewsStatus, NewsStatusCount } from '../../../domain/domain';
import NewsService from '../../../api/services/news/news.service';
import MdfApiResponse from '../../../domain/common/generic';
import { Pageable } from '../../../domain/pageable/pageable';
import { CreateNewsType } from '../../../uicomponents/create-news-form/create-news.type';

export type NewsState = {
    isLoading: boolean;
    payload: MdfApiResponse<Pageable<News>>;
};

const initialState: NewsState = {
    isLoading: false,
    payload: { content: { content: [], number: 0, totalPages: 0 }, errors: [], warnings: [] },
};

type GetNewsListParamters = {
    status?: NewsStatus;
    criticality?: NewsCriticality;
    scheduled?: boolean;
    page?: number;
};

export const getNewsList = createAsyncThunk('news/list', async (data: GetNewsListParamters, thunkApi): Promise<MdfApiResponse<Pageable<News>>> => {
    const response = await NewsService.getAllNews(data.criticality, data.status, data.page);
    return response;
});

export const getNewsSlice = createSlice({
    name: 'getNewsSlice',
    initialState: initialState,
    reducers: {
        resetNewsList: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getNewsList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getNewsList.fulfilled, (state, { payload }) => {
            state = { payload: payload, isLoading: false };
            return state;
        });
        builder.addCase(getNewsList.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

export const { resetNewsList } = getNewsSlice.actions;

export const getNewsStatusCount = createAsyncThunk('newsStatusCount/list', async (_, thunkApi) => {
    const response = await NewsService.getNewsStatusCount();
    return response;
});

export type NewsStatusCountState = {
    isLoading: boolean;
    payload: MdfApiResponse<NewsStatusCount[]>;
};

const initialStateNewsStatusCount: NewsStatusCountState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};

export const getNewsStatusCountSlice = createSlice({
    name: 'getNewsStatusSlice',
    initialState: initialStateNewsStatusCount,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getNewsStatusCount.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getNewsStatusCount.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getNewsStatusCount.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const getNewsToUpdate = createAsyncThunk('get-news/by-id', async (newsId: number, thunkApi) => {
    const response = await NewsService.getNewsToUpdate(newsId);
    return response;
});

export type NewsUpdateState = {
    isLoading: boolean;
    payload: MdfApiResponse<News | null>;
};

const initialStateNewsToUpdate: NewsUpdateState = {
    isLoading: false,
    payload: {
        content: null,
        errors: [],
        warnings: [],
    },
};

export const getNewsToUpdateSlice = createSlice({
    name: 'getNewsToUpdateSlice',
    initialState: initialStateNewsToUpdate,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getNewsToUpdate.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getNewsToUpdate.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getNewsToUpdate.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

type GetNewsData = {
    newsId: number,
    updateNewsForm: CreateNewsType,
};
export const updateNews = createAsyncThunk('update/news', async (data: GetNewsData, thunkApi) => {
    const response = await NewsService.updateNews(data.newsId, data.updateNewsForm);
    return response;
});

export type UpdateNewsState = {
    isLoading: boolean;
    isRejected: boolean,
    errorMessage: string,
    successMessage: string,
    payload: MdfApiResponse<News | null>;
};

const initialStateUpdateNews: UpdateNewsState = {
    isLoading: false,
    isRejected: false,
    errorMessage: '',
    successMessage: '',
    payload: {
        content: null,
        errors: [],
        warnings: [],
    },
};

export const updateNewsSlice = createSlice({
    name: 'updateNewsSlice',
    initialState: initialStateUpdateNews,
    reducers: {
        resetUpdateNews: (state) => {
            state = initialStateUpdateNews;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateNews.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateNews.fulfilled, (state, { payload }) => {
            state = {
                payload,
                isLoading: false,
                isRejected: false,
                errorMessage: '',
                successMessage: 'Update reussi',
            };
            return state;
        });
        builder.addCase(updateNews.rejected, (state) => {
            state.isLoading = false;
            state.isRejected = true;
            state.successMessage = '';
            state.errorMessage = 'Erreur lors de la requête';
            state.isLoading = false;
        });
    },
});
export const { resetUpdateNews } = updateNewsSlice.actions;

export type DeleteNewsState = {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string;
    isRejected: boolean;
    payload: MdfApiResponse<News | null>;
};

const initialDeleteNewsState: DeleteNewsState = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    isRejected: false,
    payload: {
        content: null,
        errors: [],
        warnings: [],
    },
};

type GetNewsParameters = {
    newsId: number;
};

export const deleteNews = createAsyncThunk('news/delete', async (data: GetNewsParameters, thunkApi) => {
    const response = await NewsService.deleteNews(data.newsId);
    return response;
});


export const deleteNewsSlice = createSlice({
    name: 'deleteNewsSlice',
    initialState: initialDeleteNewsState,
    reducers: {
        resetNewsDeletion: (state) => {
            state = initialDeleteNewsState;
            return state;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(deleteNews.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteNews.fulfilled, (state, { meta }) => {
            state = {
                isLoading: false,
                isRejected: false,
                errorMessage: '',
                successMessage: `la news ${meta.arg.newsId} est supprimée`,
                payload:{
                    content: null,
                    errors: [],
                    warnings: [],
                },
            };
            return state;
        });
        builder.addCase(deleteNews.rejected, (state) => {
            state.isLoading = false;
            state.successMessage = '';
            // @ts-ignore
            state.errorMessage = error.message;
            state.isRejected = true;
        });

    },

});

export const { resetNewsDeletion } = deleteNewsSlice.actions;