import Layout from '../../../uicomponents/layout/layout';
import NavTag from '../../../uicomponents/navTag/navTag';
import { useEffect, useState } from 'react';
import MainPaper from '../../../uicomponents/paper/main-paper';
import FinishesTable from '../../../components/table/finishes-table/finishes-table';
import CreateTexture from '../../../components/modals/texture/create/create-texture';
import { useLocation, useParams } from 'react-router-dom';
import { Params } from '../../../domain/Params/params-interface';
import { useDispatch, useSelector } from 'react-redux';
import CreateColoris from '../../../components/modals/coloris/create-coloris/create-coloris';
import { RootState } from '../../../services/root-reducer';
import { deleteColoris, GetAllColorisState } from '../../../services/reducers/suppliers/finish/coloris/coloris.slice';
import SimpleModal from '../../../uicomponents/modal/simple-modal/simple-modal';
import trash from '../../../asset/poubelle-disabled.svg';
import { deleteTexture, getAllTextures } from '../../../services/reducers/suppliers/finish/texture/texture.slice';
import UpdateTexture from '../../../components/modals/texture/update-texture/update-texture';
import { EditorState, SetCurrentSlotRef } from '../../../services/reducers/material-editor/EditorReducer';
import TextureToSlot from '../../../components/modals/coloris/texture-to-slot/texture-to-slot';
import Loader from '../../../uicomponents/loader/loader';
import { UploadingImgFileWithPreview } from '../../../domain/domain';
import { deleteFile } from '../../../utils/upload-file-method';

const Finishes = () => {
    const dispatch = useDispatch();
    const tabs = [{ name: 'Coloris' }, { name: 'Textures' }];
    const location = useLocation();

    const getColorisRes = useSelector<RootState, GetAllColorisState>((state) => state.getAllColoris);
    const { currentSlotRef } = useSelector<RootState, EditorState>((state) => state.EditorReducer);

    const { finishId }: Params = useParams();
    const [colorisId, setColorisId] = useState<number>(0);
    const [deletedColorisId, setDeletedColorisId] = useState<number>(0);
    const [textureId, setTextureId] = useState<number>(0);
    const [textureName, setTextureName] = useState<string>('');
    const [textureUrl, setTextureUrl] = useState<string>('');
    const [textureThumbnailUrl, setTextureThumbnailUrl] = useState<string>('');
    const [openTextureModal, setOpenTextureModal] = useState(false);
    const [finishName, setFinishName] = useState('');
    const [openColorModal, setOpenColorModal] = useState(false);
    const [openDeleteColorisModal, setOpenDeleteColorisModal] = useState(false);
    const [openDeleteTextureModal, setOpenDeleteTextureModal] = useState(false);
    const [openUpdateTextureModal, setOpenUpdateTextureModal] = useState(false);
    const [textureLoading, setTextureLoading] = useState(false);
    const [textureListModal, setTextureListModal] = useState(false);
    const [tabName, setTabName] = useState('Textures');
    const [fromCrush, setFromCrush] = useState(false);
    const [uploadList, setUploadedList] = useState<UploadingImgFileWithPreview[]>([]);
    const [uploadPercent, setUploadPercent] = useState('0.00');

    useEffect(() => {
        dispatch(SetCurrentSlotRef(undefined));
        setTextureListModal(false);
    }, []);
    useEffect(() => {
        finishId && dispatch(getAllTextures({ finishId: parseInt(finishId) }));
    }, [openUpdateTextureModal]);
    useEffect(() => {
        currentSlotRef !== undefined && setTextureListModal(true);
    }, [currentSlotRef]);
    useEffect(() => {
        !textureListModal && dispatch(SetCurrentSlotRef(undefined));
    }, [textureListModal]);
    useEffect(() => {
        location.pathname.includes('/textures/create') && setOpenTextureModal(true);
    }, [location]);

    return (
        <Layout finishName={finishName} setFinishName={setFinishName} pathUrl='supplier' title=''>
            <>
                {textureLoading && <Loader uploadList={uploadList} uploadPercent={uploadPercent} isLoading={textureLoading} />}
                {openTextureModal && (
                    <CreateTexture
                        setFromCrush={setFromCrush}
                        uploadList={uploadList}
                        setUploadPercent={setUploadPercent}
                        setUploadedList={setUploadedList}
                        setOpenTextureModal={setOpenTextureModal}
                        setTextureIsLoading={setTextureLoading}
                    />
                )}
                {openColorModal && <CreateColoris setOpenColorModal={setOpenColorModal} refresh={() => {
                }} updateContent={[]} />}
                {textureListModal && <TextureToSlot supplierName={getColorisRes.payload.content[0].finish.name} setTextureListModal={setTextureListModal} />}
                {openUpdateTextureModal && <UpdateTexture textureId={textureId} textureName={textureName} setOpenUpdateTextureModal={setOpenUpdateTextureModal} />}
                {openDeleteColorisModal && (
                    <SimpleModal
                        icon={trash}
                        actionLabel='Supprimer'
                        closeLabel='Annuler'
                        title='Supprimer un coloris'
                        info='Cette action supprimera ce coloris'
                        deleteOrValide={true}
                        actionOnclick={() => {
                            finishId &&
                            dispatch(
                                deleteColoris({
                                    finishId: parseInt(finishId),
                                    colorisId: deletedColorisId,
                                }),
                            );
                        }}
                        closeOnclick={() => setOpenDeleteColorisModal(false)}
                    />
                )}
                {openDeleteTextureModal && (
                    <SimpleModal
                        icon={trash}
                        actionLabel='Supprimer'
                        closeLabel='Annuler'
                        title='Supprimer une texture'
                        info='Cette action supprimera cette texture'
                        deleteOrValide={true}
                        actionOnclick={() => {
                            textureUrl && deleteFile(textureUrl);
                            textureThumbnailUrl && deleteFile(textureThumbnailUrl);
                            finishId && dispatch(deleteTexture({ finishId: parseInt(finishId), textureId: textureId }));
                        }}
                        closeOnclick={() => setOpenDeleteTextureModal(false)}
                    />
                )}
            </>
            <NavTag setOpenFirstTabModal={setOpenTextureModal} setOpenSecondTabModal={setOpenColorModal} tagName={tabName} setTagName={setTabName} tabs={tabs} />
            <MainPaper>
                <FinishesTable
                    fromCrush={fromCrush}
                    setFromCrush={setFromCrush}
                    tabName={tabName}
                    listColoris={getColorisRes}
                    setTextureId={setTextureId}
                    setTextureName={setTextureName}
                    setTextureThumbnailUrl={setTextureThumbnailUrl}
                    setTextureUrl={setTextureUrl}
                    setDeleteColorisId={setDeletedColorisId}
                    setOpenCreateColorisModal={setOpenColorModal}
                    setOpenUpdateTextureModal={setOpenUpdateTextureModal}
                    setOpenDeleteTextureModal={setOpenDeleteTextureModal}
                    //   setOpenDuplicateColorisModal={setOpenDuplicateColorisModal}
                    setOpenDeleteColorisModal={setOpenDeleteColorisModal}
                    refreshData={() => console.log()}
                    setTextureIsLoading={setTextureLoading}
                />
            </MainPaper>
        </Layout>
    );
};
export default Finishes;
