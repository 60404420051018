import apiClient from '../../api-client';
import {
    ADD_TAG_TO_COMPOSITION_MODULE,
    GET_COMPOSITION_MODULE_TAGS,
} from '../../endpoints/rules/composition-module-tags';


const compositionModuleTagService = {
    addTagsToCompositionModule: async (compositionModuleReference: string, tagIdList: number[]) => apiClient(ADD_TAG_TO_COMPOSITION_MODULE(compositionModuleReference), tagIdList, undefined, 'POST'),
    getCompositionModuleTags: async (compositionModuleReference: string) => apiClient(GET_COMPOSITION_MODULE_TAGS(compositionModuleReference), undefined, undefined, 'GET'),
};

export default compositionModuleTagService;
