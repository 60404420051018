import React from 'react';
import './filter-button.scss';
import { NewsStatus } from '../../domain/domain';

export type FilterButtonProps = {
    filterOnClick?: () => void;
    findCountByStatus?: (status: NewsStatus) => number;
    clearFilter: () => void;
    status: NewsStatus;
    label: string;
    selectedTab: string | undefined;
    tab: string;
};

const FilterButton = ({ tab, label, filterOnClick, findCountByStatus, status, selectedTab, clearFilter }: FilterButtonProps) => {
    return (
        <>
            <div className="filter-button-container">
                <div>
                    <p className={selectedTab === tab ? 'selected' : 'unselected'} onClick={filterOnClick}>
                        {findCountByStatus && findCountByStatus?.(status) > 1 ? label + 's ' : label + ' '}({findCountByStatus?.(status)})
                        {selectedTab === tab && (
                            <div
                                className="clear-filter"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    clearFilter();
                                }}
                            >
                                ×
                            </div>
                        )}
                    </p>
                </div>
            </div>
        </>
    );
};
export default FilterButton;
