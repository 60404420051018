const CREATE_TAG = (tagEntityType: string, tagName: string) => `v1/tags/${tagEntityType}/${tagName}`;
const GET_FINISH_TAG = (entityType: string) => `v1/tags/${entityType}`;
const GET_FINISH_AND_COLORS_BY_TAGS = (tagName: string) => `v1/suppliers/finish/${tagName}`;
const GET_TEXTURE_TAG = () => `v1/tags/TEXTURE`;
const GET_PRODUCT_TAG = () => `v1/tags/PRODUCT`;
const GET_MODEL_PART_ITEM_TAG = () => `v1/tags/MODEL_PART_ITEM`;
const GET_MODEL_ANCHOR_POINT_TAG = () => `v1/tags/MODEL_ANCHOR_POINT`;
const GET_ASSOCIATE_MODULE_BY_TAG = (collectionReference: string) => `v1/construction-rule/collection/${collectionReference}/tag`;
const GET_ASSOCIATE_PRODUCT_BY_TAG = (collectionReference: string) => `v1/construction-rule/collection/${collectionReference}/products-tags`;


export {
    CREATE_TAG,
    GET_FINISH_TAG,
    GET_TEXTURE_TAG,
    GET_FINISH_AND_COLORS_BY_TAGS,
    GET_PRODUCT_TAG,
    GET_MODEL_PART_ITEM_TAG,
    GET_MODEL_ANCHOR_POINT_TAG,
    GET_ASSOCIATE_MODULE_BY_TAG,
    GET_ASSOCIATE_PRODUCT_BY_TAG
};