import React from 'react';
import FileItemHolder from './file-item-holder';

import './image-upload.scss';
import * as path from 'path';
import { News } from '../../domain/domain';
import { setToasterError } from '../../services/reducers/toaster-reducer/toaster.slice';
import { useDispatch } from 'react-redux';

type UploadProps = {
    imageFile: any;
    setImageFile: (images: any) => void;
    imageUrl: string[];
    setImageUrl: (imageUrl: string[]) => void;
    data?: News;
    setIsAdding?: (value: boolean) => void;
    setImgUrlsToDelete?: (value: any) => void;
    setImgUrlsToAdd?: (value: any) => void;
    setValidate: (validate: boolean) => void;
};

const ImageUpload = ({ imageFile, setImageFile, setIsAdding, setImgUrlsToDelete, setImgUrlsToAdd, setValidate }: UploadProps) => {
    const dispatch = useDispatch();
    const onChange = (e: any) => {
        let imageSize = e.target.files[0] && e.target.files[0].size;
        if (imageSize > 15000000) {
            setValidate(false);
            dispatch(
                setToasterError({
                    message: "'Le fichier ne peut pas depasser 15mo'",
                    title: 'Document non conforme',
                })
            );
        } else {
            if (setIsAdding) {
                setIsAdding(true);
            }
            if (setImgUrlsToAdd) {
                setImgUrlsToAdd(e.target.files[0]);
            }
            setImageFile((prev: any) => {
                return [...prev, e.target.files[0]];
            });
        }
    };
    const removeImage = (e: any) => {
        const id: string = e.target.getAttribute('name');
        if (setIsAdding) {
            setIsAdding(false);
        }
        for (let i = 0; i < imageFile.length; i++) {
            if (imageFile[i].name === undefined) {
                if (
                    imageFile[i]
                        .substring(imageFile[i].lastIndexOf('/') + 1)
                        .substring(imageFile[i].substring(imageFile[i].lastIndexOf('/') + 1).indexOf('-') + 1)
                        .split('.', 1)[0] === id
                ) {
                    let urlToRemove = imageFile[i];
                    setImageFile(imageFile.filter((ur: any) => ur !== urlToRemove));
                    if (setImgUrlsToDelete) {
                        setImgUrlsToDelete(imageFile[i]);
                    }
                }
            } else {
                if (imageFile[i].name === id) {
                    if (setImgUrlsToDelete) {
                        setImgUrlsToDelete(imageFile[i]);
                    }
                    setImageFile(imageFile.filter((item: any) => item.name !== imageFile[i].name));
                }
            }
        }
    };

    return (
        <>
            <div className="main-upload">
                <div className="title">PIÉCE JOINTES</div>
                <div className="input-container">
                    <input type="file" name="file" accept="image/*,video/*,.doc,.docx,.pdf" onChange={onChange} />
                    <div className="upload-option">
                        <span>PDF,JPEG,DOC,DOCX,PNG, ou VIDEO</span>
                        <span>Taille max 15MO</span>
                    </div>
                </div>
                <div className="upload-bloc-container">
                    {imageFile.map((e: any, i: number) => {
                        const message = e && e.name;
                        const ext = path.extname(e.name);
                        const prevMessage = message; //&& message.substring(0, 20);

                        let dataFileExtension;
                        let dataFileName = '';
                        if (e.name === undefined) {
                            dataFileExtension = e.split('.').pop();
                            const n = e.lastIndexOf('/');
                            const result = e.substring(n + 1);
                            const n2 = result.indexOf('-');
                            const result2 = result.substring(n2 + 1);
                            const extensionname = result2.split('.', 1);
                            dataFileName = extensionname[0];
                        }
                        const nomFichier = dataFileName || prevMessage;
                        const extensionFichier = dataFileExtension || ext;

                        return (
                            <div key={i} className="bloc">
                                <div className="upload-title">
                                    <div style={{ color: 'black', fontWeight: 'bold' }}>{nomFichier.substring(0, 20)}</div>
                                    <div>{extensionFichier}</div>
                                </div>
                                {imageFile && (
                                    <div className="bloc-container">
                                        <div className="btn-container">
                                            <FileItemHolder name={nomFichier} onClick={removeImage} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
export default ImageUpload;
