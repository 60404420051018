import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Params } from '../../domain/Params/params-interface';
import SimpleModal from '../modal/simple-modal/simple-modal';
import backArrow from '../../asset/retour.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import { ColorState } from '../../services/reducers/material-editor/ColorReducer';
import './navTag.scss';


type Tab = { name: string };

type NavTagProps = {
    tabs: Tab[];
    setTagName: (tagName: string) => void;
    tagName: string;
    setOpenFirstTabModal: (openFirstTabModal: boolean) => void;
    setOpenSecondTabModal: (openSecondTabModal: boolean) => void;
    openPage?: string;
};

const NavTag = ({ tabs, setTagName, tagName, setOpenFirstTabModal, setOpenSecondTabModal, openPage }: NavTagProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { supplierReference, finishId } = useParams<Params>();
    const location = useLocation();
    const [focus, setFocus] = useState(location.pathname.includes('finitions') ? 1 : 0);
    const [tabAction, setTabAction] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [buttonClick, setButtonClick] = useState(false);
    const { isGltfDirty } = useSelector<RootState, ColorState>((state) => state.ColorReducer);

    const checkIsDirty = () => {
        isGltfDirty ? setOpenModal(true) : setOpenSecondTabModal(true);
    };
    const opeModal = () => {
        if (openPage !== undefined) {
            location.pathname.includes('collections') ? history.push(openPage) : history.push(openPage);
        } else {
            focus === 1
                ? setOpenFirstTabModal(true)
                : checkIsDirty();
            setButtonClick(true);
        }
    };
    const getButtonLabelFromPathname = () => {
        const pathname = location.pathname;
        if (pathname.includes('/textures')) {
            return 'une texture';
        }
        if (pathname.includes('/collections')) {
            return 'une collection';
        }
        if (pathname.includes('/finitions')) {
            return 'une finition';
        }
        if (pathname.includes('/colors')) {
            return 'un coloris';
        }
    };
    const historyPath = () => {
        if (location.pathname.includes('finishes')) {
            if (focus === 0) {
                return `/supplier/${supplierReference}/finishes/${finishId}/colors`;
            } else {
                return `/supplier/${supplierReference}/finishes/${finishId}/textures`;
            }
        } else {
            if (focus === 0) {
                return `/supplier/${supplierReference}/collections`;
            } else {
                return `/supplier/${supplierReference}/finitions`;
            }
        }
    };
    const checkColorModif = (i: number, el: any) => {
        setButtonClick(false);
        const foc = () => {
            setFocus(i);
            setTagName(el.name);
            setOpenSecondTabModal(false);
            setOpenFirstTabModal(false);
        };
        if (location.pathname.includes('colors')) {
            isGltfDirty ? setOpenModal(true) : foc();
        } else {
            foc();
        }
    };

    useEffect(() => {
        // @ts-ignore
        history.push(historyPath());
    }, [focus]);
    useEffect(() => {
        location.pathname.includes('colors') && setFocus(0);
    }, [location]);

    return (
        <div className='navTag-main'>
            {openModal && (
                <SimpleModal
                    icon={backArrow}
                    title='Quitter sans sauvegarder'
                    info='Vous perdrez les changements non sauvegardés.'
                    closeOnclick={() => {
                        setOpenModal(false);
                        setButtonClick(false);
                    }}
                    actionOnclick={() => {
                        if (tabAction) {
                            setFocus(0);
                            setTabAction(false);
                            setOpenModal(false);
                            setButtonClick(false);
                        } else {
                            buttonClick && setOpenSecondTabModal(true);
                            !buttonClick && setFocus(0);
                            setOpenModal(false);
                            setButtonClick(false);
                        }
                    }}
                    deleteOrValide={true}
                    closeLabel='Annuler'
                    actionLabel='Quitter'
                />
            )}

            {tabs.map((el: Tab, i: number) => {
                return (
                    <span key={i} className={focus === i ? 'span-focus' : ''}
                          onClick={() => checkColorModif(i, el)}>
                        {el.name}
                    </span>
                );
            })}
            <button onClick={() => {
                console.log(location.pathname.includes('/textures'));
                location.pathname.includes('/textures')
                    ? history.push(`/supplier/${supplierReference}/finishes/${finishId}/textures/create`)
                    : opeModal();
            }}>+ Ajouter {getButtonLabelFromPathname()}</button>
        </div>
    );
};
export default NavTag;
