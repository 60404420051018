import React, { FC, useEffect, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetAuth } from '../../services/reducers/auth-reducer/auth-reducer';

const PrivateRoute: FC<{
    component: FC;
    path: string;
}> = ({ path, component }) => {
    const token = localStorage.getItem('mdf-jwt');
    const dispatch = useDispatch();
    const history = useHistory();

    const [sessionExpired, setSessionExpired] = useState(false);

    const expired = () => {
        if (token !== null) {
            const expirationDateToken = JSON.parse(atob(token.split('.')[1]));
            if (expirationDateToken.exp * 1000 < Date.now()) {
                localStorage.removeItem('mdf-jwt');
                localStorage.removeItem('user');
                return true;
            }
        } else {
            dispatch(resetAuth());
            return true;
        }
        return false;
    };

    useEffect(() => {
        setSessionExpired(expired());
    }, []);

    return !sessionExpired ? <Route exact path={ path } component={ component } /> : <Redirect to='/' />;
};

export default PrivateRoute;
