import { useEffect } from 'react';
import './update-texture.scss';
import { useDispatch } from 'react-redux';
import { formErrors } from '../../../../utils/form-errors';
import { SubmitHandler, useForm } from 'react-hook-form';
import TextureService from '../../../../api/services/suppliers/finish/texture/texture.service';

type TextureInputs = {
    TextureName: string;
};
type UpdateTexture = {
    setOpenUpdateTextureModal: (openUpdateTextureModal: boolean) => void;
    textureName: string;
    textureId: number;
};

const UpdateTexture = (props: UpdateTexture) => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<TextureInputs>();

    const onSubmit: SubmitHandler<TextureInputs> = (data) => {
        data.TextureName === props.textureName && props.setOpenUpdateTextureModal(false);
        data.TextureName !== props.textureName &&
            TextureService.updateTexture(props.textureId, data.TextureName)
                .then((res) => {
                    console.log('res', res);
                    props.setOpenUpdateTextureModal(false);
                })
                .catch((err) => {
                    err &&
                        err.response &&
                        err.response.data &&
                        err.response.data.errors &&
                        err.response.data.errors.length > 0 &&
                        err.response.data.errors[0].code &&
                        err.response.data.errors[0].code === 'T0002' &&
                        alert('Une texture existe déjà avec ce nom');
                });
    };
    useEffect(() => {
        setValue('TextureName', props.textureName, {});
    }, [props.textureName]);

    return (
        <div className="update-texture-main">
            <div className="update-texture-paper">
                <div className="update-texture-top">
                    <span> Editer une texture </span>
                </div>

                <div className="update-texture-body">
                    <form className="update-texture-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-container">
                            <label htmlFor="name">Nouveau nom</label>
                            <input placeholder="Entrez un nouveau nom de texture" {...register('TextureName', { required: true })} />
                            {errors && formErrors(errors.TextureName)}
                        </div>
                        <div className="btn-box">
                            <button className="btn-close" onClick={() => props.setOpenUpdateTextureModal(false)}>
                                Annuler
                            </button>
                            <button type="submit" className="btn-action">
                                Editer
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default UpdateTexture;
