import { Model3DCellValue, ModelTree, PartTree, Rule } from '../domain/domain';
import { Conf } from '../components/table/products-table/product-presset/product-preset-table';
import { ProductRulesState } from '../services/reducers/product/rules/rules.reducers';


export const findAtomByReference = (ruleRes: ProductRulesState, reference: string) => {
    const atom = ruleRes.payload.content.valueAtomNameByAtomValueRef[reference];
    return atom;
};

export const generateAtomListFromRule = (ruleRes: ProductRulesState, rule: Rule) => {
    const model3dCellValueReferences: string[] = [];
    Object.values(rule.matching).forEach((el) => {
        Object.values(el).forEach((el2) => {
            const ref = el2 as string;
            model3dCellValueReferences.push(ref);
        });
    });

    const flatReferenceList = model3dCellValueReferences.flat();
    const atomList = flatReferenceList.filter(el => !el.includes('>')).map((reference: string) => {
        return findAtomByReference(ruleRes, reference);
    });
    return atomList;
};

export const generatePartTreeListFromAtomList = (atomList: Model3DCellValue[]) => {
    const partTreeList: PartTree[] = [];

    atomList.forEach((atom) => {
        const foundPart = partTreeList.findIndex(part => part.reference === atom.partReference);
        if (foundPart === -1) {
            partTreeList.push({
                reference: atom.partReference,
                characteristics: [{
                    name: atom.partItemName,
                    atomDisplayType: 'TEXT',
                    reference: atom.partItemReference,
                    modelTrees: [{
                        atom: atom,
                        model3d: [],
                    },
                    ],
                }],
                options: [],
                name: atom.partName,
            });
        } else {
            const foundPartItem = partTreeList[foundPart].characteristics.findIndex(partItem => partItem.reference === atom.partItemReference);
            if (foundPartItem === -1) {
                partTreeList[foundPart].characteristics.push(
                    {
                        name: atom.partItemName,
                        atomDisplayType: 'TEXT',
                        reference: atom.partItemReference,
                        modelTrees: [{
                            atom: atom,
                            model3d: [],
                        },
                        ],
                    },
                );
            } else {
                partTreeList[foundPart].characteristics[foundPartItem].modelTrees.push({
                    atom: atom,
                    model3d: [],
                });
            }
        }
    });
    return partTreeList;
};


export const displayAtomsInCurrentRule = (ruleRes: ProductRulesState, rule: Rule) => {
    const dimensionRulesList = ruleRes.payload.content.ruleSet.rules.rules.filter((r) => r.type === 'DIMENSION');
    const atomList = generateAtomListFromRule(ruleRes, rule);
    const modelTreeList: ModelTree[] = atomList.map((atom) => {
        const modelTree: ModelTree = {
            atom,
            model3d: [],
        };
        return modelTree;
    });
    const presetConf: Conf[] = buildPresetConf(modelTreeList, dimensionRulesList);

    return presetConf;
};

export const buildPresetConf = (modelTreeList: ModelTree[], ruleList?: Rule[]): Conf[] => {
    const result: Conf[] = [];
    modelTreeList.forEach((modelTree, i) => {
        const isAlreadyInResultIndex = result.findIndex(el => el.partReference === modelTree.atom.partReference);
        if (isAlreadyInResultIndex < 0) {
            result.push({
                partName: modelTree.atom.partName,
                partReference: modelTree.atom.partReference,
                itemAtom: [
                    {
                        partItemName: modelTree.atom.partItemName,
                        atomName: modelTree.atom.label,
                    },
                ],
                mappingName: modelTree.model3d.length > 0 && modelTree.model3d[0].mappings.length > 0 ? modelTree.model3d[0].mappings[0].name : 'Pas d\'habillage',
                zoneColorisList: modelTree.model3d.length === 0 || modelTree.model3d[0].mappings.length === 0
                    ? [{ zoneName: 'Pas de zone', defaultColoris: 'Pas de coloris' }]
                    : modelTree.model3d[0].mappings[0].zoneList.map((z) => {
                        return {
                            zoneName: z.name,
                            defaultColoris: z.defaultColori ? z.defaultColori.name : 'Aucun',
                        };
                    }),
                ruleList: ruleList ? ruleList : [],
            });
        } else {
            result[isAlreadyInResultIndex].itemAtom.push({
                partItemName: modelTree.atom.partItemName,
                atomName: modelTree.atom.label,
            });
        }
    });
    return result;
};
