import React from 'react';
import trash from '../../asset/poubelle.svg';

type FileItemHolderProps = {
    name: string;
    onClick: (e: any) => void;
};

const FileItemHolder = ({ name, onClick }: FileItemHolderProps) => {
    // @ts-ignore
    return <img name={name}
                className="file-item-main"
                alt='logo poubelle'
                onClick={onClick}
                src={trash} />;
};

export default FileItemHolder;