import React, { useEffect, useState } from 'react';
import './product-model-3d.scss';
import { Model3DCellValue, Part, PartItem, Product, ProductModel3d, Tag } from '../../../../domain/domain';
import trashRed from '../../../../asset/poubelle-red.svg';
import update from '../../../../asset/Editer.svg';
import plus from '../../../../asset/plus-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../services/root-reducer';
import {
    deleteProductModels3d,
    getProductModels3d,
    Model3dListState,
    Model3dState,
    resetCreateProductModels3d,
    resetUpdateProductModels3dMappings,
} from '../../../../services/reducers/product/3DModels/3DModels.reducers';
import AssociateMappingModal from '../../../modals/product/associate-mapping-modal/associate-mapping-modal';
import SimpleModal from '../../../../uicomponents/modal/simple-modal/simple-modal';
import {
    Cell3dListState,
    Cell3dState,
    create3dCell,
    getAllCellsByPartReference,
} from '../../../../services/reducers/product/3DCells/3DCells.reducers';
import TagSelector from '../../../tag-selector/tag-selector';
import { CreateTagState, getModelAnchorTag, getModelPartItemTag } from '../../../../services/reducers/tag/tag.slice';
import {
    Cell3dValueListState,
    Cell3dValueState,
    create3dCellValue,
    get3dCellValues,
} from '../../../../services/reducers/product/3DCells/3DCellValues.reducers';
import CreateTexture from '../../../modals/texture/create/create-texture';
import replace from '../../../../asset/remplacer.svg';


type ProductModel3dProps = {
    product: Product;
    part: Part | undefined;
    setAssociateOpenMappping: (openAssociateMapping: boolean) => void;
    openAssociateMapping: boolean;
    productModel3d: Model3dListState;
};
type ItemHead = {
    ref: string;
    label: string;
    index: number;
    option: boolean;
};


const ProductModel3dTable = (props: ProductModel3dProps) => {
    const dispatch = useDispatch();
    const delete3dRes = useSelector<RootState, Model3dState>((state) => state.deleteProduct3dModel);
    const updateMappingIdsRes = useSelector<RootState, Model3dState>((state) => state.updateProduct3dMappings);
    const create3dCellRes = useSelector<RootState, Cell3dState>((state) => state.create3dCell);
    const getAll3dCellsByPartReferenceRes = useSelector<RootState, Cell3dListState>((state) => state.getAllCellsByPartReference);
    const get3dModelCellValuesRes = useSelector<RootState, Cell3dValueListState>((state) => state.get3dCellValue);
    const create3dCellValueRes = useSelector<RootState, Cell3dValueState>((state) => state.create3dCellValue);
    const createTags = useSelector<RootState, CreateTagState>((state) => state.createTag);
    const updateUrlRes = useSelector<RootState, Model3dState>(state => state.updateProduct3dUrl);

    const tableHeaderLabels =
        [
            { label: 'MODÈLES 3D', ref: '', index: -1, option: false },
            { label: 'MAPPING', ref: '', index: -1, option: false },
        ];

    const [, setSelectedCell] = useState<ItemHead>();
    const [selectedModel3d, setSelectedModel3d] = useState<ProductModel3d>();
    const [selectTag, setSelectTag] = useState({ partItemRef: '', modelRef: '', in: false });
    const [openDeleteM, setOpenDeleteM] = useState(false);
    const [openCrushModel3D, setOpenCrushModel3D] = useState(false);
    const [refList, setRefList] = useState<string[]>([]);
    const [, setTagList] = useState<number[]>([]);
    const [dynamicHead, setDynamicHead] = useState<ItemHead[]>([]);
    const [hooverCell, setHooverCell] = useState<number>(-1);
    const [hooverCellAnchor, setHooverCellAnchor] = useState<number>(-1);
    const [headConf, setHeadConf] = useState<ItemHead[]>(tableHeaderLabels);

    //#region useEffect
    useEffect(() => {
        dispatch(getModelPartItemTag());
        dispatch(getModelAnchorTag());
    }, []);
    useEffect(() => {
        dispatch(get3dCellValues({ partItemReferenceList: [...refList, 'MODEL_ANCHOR_POINT'] }));
    }, [create3dCellValueRes]);
    useEffect(() => {
        props.part && dispatch(getAllCellsByPartReference(props.part.reference));
    }, [create3dCellRes, create3dCellValueRes]);
    useEffect(() => {
        dispatch(getModelPartItemTag());
        dispatch(getModelAnchorTag());
        if (createTags.payload.content !== undefined && createTags.payload.content !== null) {
            setTagList((prevState) => {
                // @ts-ignore
                return [...prevState, createTags.payload.content.id];
            });
        }
    }, [createTags]);
    useEffect(() => {
        props.part && dispatch(getAllCellsByPartReference(props.part.reference));

        setDynamicHead([]);
        props.part &&
        props.part.partCharacteristicMetadata.partItemList.forEach((partItem: PartItem, i: number) => {
            const res = partIterator(partItem, [], i, false);
            setDynamicHead((prevState) => [...prevState, res]);
        });
        props.part &&
        props.part.partOptionMetadata.partItemList.forEach((partItem: PartItem, i: number) => {
            const res = partIterator(partItem, [], i, true);
            setDynamicHead((prevState) => [...prevState, res]);
        });
    }, [props.part]);
    useEffect(() => {
        {
            setHeadConf([
                { label: 'MODÈLES 3D', ref: '', index: -1, option: false },
                { label: 'POINTS D\'ACCROCHE', ref: '', index: -1, option: false },
                { label: 'MAPPING', ref: '', index: -1, option: false },
            ]);
            let set: ItemHead[] = [];
            dynamicHead &&
            dynamicHead.map((item: any) => {
                item.forEach((el: ItemHead) => {
                    set.push(el);
                });
            });
            set.forEach((el) => {
                setHeadConf((prev) => [...prev, el]);
            });
            const customRefList = set.map((el) => el.ref);
            setRefList(customRefList);

            dispatch(get3dCellValues({ partItemReferenceList: [...customRefList, 'MODEL_ANCHOR_POINT'] }));
        }
    }, [dynamicHead]);
    useEffect(() => {
        if (delete3dRes.payload.content && delete3dRes.payload.errors.length === 0) {
            props.part && dispatch(getProductModels3d(props.part.reference));
            setOpenDeleteM(false);
            dispatch(resetCreateProductModels3d());
        }
    }, [delete3dRes]);
    useEffect(() => {
        if (updateMappingIdsRes.payload.content !== null && updateMappingIdsRes.payload.content.name.length > 0 && updateMappingIdsRes.payload.errors.length === 0) {
            props.setAssociateOpenMappping(false);
            props.part && dispatch(getProductModels3d(props.part.reference));
            dispatch(resetUpdateProductModels3dMappings());
        }
    }, [updateMappingIdsRes]);
    useEffect(() => {
        if (updateUrlRes.payload.content.id > 0 && updateUrlRes.payload.errors.length === 0) {
            props.part && dispatch(getProductModels3d(props.part.reference));
        }
    }, [updateUrlRes]);
//endregion

    //#region methods
    const createModelPartItemTag = (model3dReference: string, model3dCellValue: Model3DCellValue) => {
        dispatch(create3dCellValue({ model3dReference, model3dCellValue }));
    };
    const fillInCellInformation = (modelReference: string, partItemReference: string, newTagIdList: number[]) => {
        dispatch(
            create3dCell({
                modelReference: modelReference,
                partItemReference: partItemReference,
                tags: { tagIdList: newTagIdList },
            }),
        );
    };

    const partIterator: any = (partItem: PartItem, arr: ItemHead[], i: number, option: boolean) => {
        if (partItem === null) {
            return [...arr];
        } else {
            arr.push({
                label: partItem.name,
                ref: partItem.reference,
                index: i,
                option: option,
            });
            partIterator(partItem.partCharacteristics, arr, i);
        }
        return arr;
    };
    const classGen = (el: string) => {
        if (el === 'MODÈLES 3D' || el === 'POINTS D\'ACCROCHE' || el === 'MAPPING') {
            return;
        } else {
            return 'dynamic';
        }
    };

    function findCellContentByModelRefAndPartItemRef(model3dReference: string, partItemReference: string) {
        const cell = getAll3dCellsByPartReferenceRes.payload.content.find((el) => {
            return el.modelReference === model3dReference && el.partItemReference === partItemReference;
        });
        if (cell === undefined) {
            return [];
        } else {
            return cell.tags.map((el) => {
                return el.id;
            });
        }
    }

    const generateTd = (model3dReference: string) => {
        const dynamicColumn = headConf.length - 3;
        let list = [];
        for (let j = 0; j < dynamicColumn; j++) {
            let tagsByCell = findCellContentByModelRefAndPartItemRef(model3dReference, headConf[j + 3].ref);
            const currentElementTagList =
                get3dModelCellValuesRes.payload.content === null
                    ? []
                    : get3dModelCellValuesRes.payload.content
                        .filter((el) => el.partItemReference === headConf[j + 3].ref)
                        .map((el) => {
                            const customTag: Tag = {
                                id: el.id,
                                version: el.version,
                                name: el.value,
                                entityType: 'MODEL_PART_ITEM',
                                createdAt: el.createdAt,
                                updatedAt: el.updatedAt,
                                tagSystem: false,
                            };
                            return customTag;
                        });
            list.push(
                <td
                    onClick={ () => {
                        setSelectedCell(headConf[j + 3]);
                    } }
                    className='dynamic' key={ j }
                >
                    { tagsByCell.length > 0 || selectTag.in && selectTag.modelRef === model3dReference && selectTag.partItemRef === headConf[j + 3].ref ?
                        <TagSelector
                            onChange={ (newTagIdList) => {
                                setTagList(newTagIdList);
                                fillInCellInformation(model3dReference, headConf[j + 3].ref, newTagIdList);
                            } }
                            onCreate={ (tagName) => {
                                const partItem = headConf[j + 3];
                                // createModelPartItemTag(tagName, headConf[j + 3].ref, model3dReference);
                                // @ts-ignore
                                createModelPartItemTag(model3dReference, {
                                    label: tagName,
                                    partItemReference: partItem.ref,
                                    value: tagName,
                                    partItemName: partItem.label,
                                    partReference: props.part?.reference ?? '',
                                    partName: props.part?.name ?? '',
                                    thumbnailUrl: '',
                                });
                            } }
                            tagList={ currentElementTagList }
                            selectedTags={ findCellContentByModelRefAndPartItemRef(model3dReference, headConf[j + 3].ref) }
                            placeholder='Tags'
                            scrollOption={ true }
                        />
                        :
                        <div className='addMappingMain'
                             onClick={ () => {
                                 setSelectTag({
                                     partItemRef: headConf[j + 3].ref,
                                     modelRef: model3dReference,
                                     in: true,
                                 });
                             } }>
                            <div className='hoov'>
                                <img alt='ajouter un tag' src={ plus } />
                                <span>Ajouter un tag</span>
                            </div>
                        </div>
                    }
                </td>);
        }
        return list;
    };

    const trClassName = props.product.productType !== 'CONFIGURABLE' && props.product.productType !== 'COMPOSITION' ? 'simple' : 'conf';
    const removeDash = (string: string) => {
        // @ts-ignore
        return string.match('\\d+-\\s*(.*)') ? string!.match('\\d+-\\s*(.*)')[1] : string;
    };
    //endregion
    return (
        <>
            <>
                {
                    openCrushModel3D &&
                    <CreateTexture setOpenTextureModal={ setOpenCrushModel3D } model3d={ true } part={ props.part }
                                   selectedModel3DRef={ selectedModel3d && selectedModel3d.reference }
                                   setUploadPercent={() => {}}
                                   isCrushModel3D={ openCrushModel3D } />
                }
                { openDeleteM && (
                    <SimpleModal
                        icon={ trashRed }
                        title='Supprimer un modèle 3D'
                        info='Cette action supprimera le modèle 3D ainsi que toutes ces dépendences'
                        closeOnclick={ () => setOpenDeleteM(false) }
                        actionOnclick={ () => selectedModel3d && dispatch(deleteProductModels3d(selectedModel3d.reference)) }
                        deleteOrValide={ true }
                        closeLabel='Annuler'
                        actionLabel='Supprimer'
                    />
                ) }

                { props.openAssociateMapping && selectedModel3d && (
                    <AssociateMappingModal selectedModel3d={ selectedModel3d }
                                           product3dModelList={ props.productModel3d }
                                           setOpenModal={ props.setAssociateOpenMappping } />
                ) }
            </>
            <table className='product3d-table'>
                <thead>
                <tr className='configurable'>
                    { headConf.map((el: ItemHead, i: number) => {
                        return (
                            <th className={ classGen(el.label) } key={ i }>
                                { el.label }{ ' ' }
                            </th>
                        );
                    }) }
                    <th>ACTION</th>
                </tr>
                </thead>
                <tbody>
                { props.productModel3d.payload.content.length > 0 && (
                    <>
                        { props.productModel3d.payload.content.map((model3d: ProductModel3d, i: number) => {
                            let tagsByCell = findCellContentByModelRefAndPartItemRef(model3d.reference, 'MODEL_ANCHOR_POINT');
                            return (
                                <React.Fragment key={ i }>
                                    <tr className={ trClassName }>
                                        <td>{ removeDash(model3d.name) } </td>
                                        <td onMouseEnter={ () => setHooverCellAnchor(model3d.id) }
                                            onMouseLeave={ () => setHooverCellAnchor(-1) }>
                                            { (tagsByCell.length > 0 || selectTag.in && selectTag.modelRef === model3d.reference && selectTag.partItemRef === model3d.partReference) ?
                                                <TagSelector
                                                    onChange={ (newTagIdList) => {
                                                        setTagList(newTagIdList);
                                                        fillInCellInformation(model3d.reference, 'MODEL_ANCHOR_POINT', newTagIdList);
                                                    } }
                                                    onCreate={ (tagName) => {
                                                        // createModelPartItemTag(tagName, 'MODEL_ANCHOR_POINT', model3d.reference);
                                                        // @ts-ignore
                                                        createModelPartItemTag(model3d.reference, {
                                                            label: tagName,
                                                            partItemReference: 'MODEL_ANCHOR_POINT',
                                                            value: tagName,
                                                            partItemName: 'MODEL_ANCHOR_POINT',
                                                            partName: props.part?.name ?? '',
                                                            thumbnailUrl: '',
                                                            partReference: props.part?.reference ?? "",
                                                        });
                                                    } }
                                                    scrollOption={ true }
                                                    tagList={
                                                        get3dModelCellValuesRes.payload.content === null
                                                            ? []
                                                            : get3dModelCellValuesRes.payload.content
                                                                .filter((el) => el.partItemReference === 'MODEL_ANCHOR_POINT')
                                                                .map((el) => {
                                                                    const customTag: Tag = {
                                                                        id: el.id,
                                                                        version: el.version,
                                                                        name: el.value,
                                                                        entityType: 'MODEL_ANCHOR_POINT',
                                                                        createdAt: el.createdAt,
                                                                        updatedAt: el.updatedAt,
                                                                        tagSystem: false,
                                                                    };
                                                                    return customTag;
                                                                })
                                                    }
                                                    selectedTags={ findCellContentByModelRefAndPartItemRef(model3d.reference, 'MODEL_ANCHOR_POINT') }
                                                    placeholder="tag point d'accroche"
                                                />
                                                :
                                                model3d.id === hooverCellAnchor && (
                                                    <div className='addMappingMain'
                                                         onClick={ () => {
                                                             setSelectTag({
                                                                 partItemRef: model3d.partReference,
                                                                 modelRef: model3d.reference,
                                                                 in: true,
                                                             });
                                                         } }>
                                                        <div className='hoov'>
                                                            <img alt='ajouter un tag' src={ plus } />
                                                            <span>Ajouter un tag</span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </td>

                                        <td onMouseEnter={ () => setHooverCell(model3d.id) }
                                            onMouseLeave={ () => setHooverCell(-1) }>
                                            { model3d.mappings.length > 0 ? (
                                                <>
                                                    <div
                                                        className='mappingsList'>{ model3d.mappings.length > 1 ? `${ model3d.mappings.length } mappings` : model3d.mappings[0].name }</div>
                                                    <img
                                                        onClick={ () => {
                                                            props.setAssociateOpenMappping(true);
                                                            setSelectedModel3d(model3d);
                                                        } }
                                                        alt='modifier le mapping'
                                                        src={ update }
                                                    />
                                                </>
                                            ) : (
                                                model3d.id === hooverCell && (
                                                    <div
                                                        className='addMappingMain'
                                                        onClick={ () => {
                                                            props.setAssociateOpenMappping(true);
                                                            setSelectedModel3d(model3d);
                                                        } }>
                                                        <div className='hoov'>
                                                            <img alt='ajouter un mapping' src={ plus } />
                                                            <span>Associer un mapping</span>
                                                        </div>
                                                    </div>
                                                )
                                            ) }
                                        </td>
                                        { generateTd(model3d.reference) }
                                        <td className="act" >
                                            <img
                                                onClick={ () => {
                                                    setOpenCrushModel3D(true);
                                                    setSelectedModel3d(model3d);
                                                } }
                                                alt='logo poubelle'
                                                src={ replace }
                                            />
                                            <img
                                                onClick={ () => {
                                                    setOpenDeleteM(true);
                                                    setSelectedModel3d(model3d);
                                                } }
                                                alt='logo poubelle'
                                                src={ trashRed }
                                            />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            );
                        }) }
                    </>
                ) }
                </tbody>
            </table>
        </>
    )
        ;
};
export default ProductModel3dTable;
