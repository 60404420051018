import close from '../../../../asset/CloseWindow.svg';
import React, { useEffect } from 'react';
import { formErrors } from '../../../../utils/form-errors';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { GroupState, updateGroup } from '../../../../services/reducers/mapping-zoning/group-mapping.reducer';
import { RootState } from '../../../../services/root-reducer';
import '../create-group-mapping.scss';
import { returnadvice } from '../mapping/create-mapping';


type CreateGroupeProps = {
    setOpenModal: (openModal: boolean) => void;
    collectionReference: string;
    groupId?: number;
    name: string | undefined;
    setName: (name: string | undefined) => void;
};

type AddGroupInput = {
    name: string;
};

const UpdateGroupMapping = (props: CreateGroupeProps) => {
    const dispatch = useDispatch();
    const group = useSelector<RootState, GroupState>((state) => state.getGroup);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm<AddGroupInput>({});

    const onSubmit: SubmitHandler<AddGroupInput> = (data) => {
        props.setName(undefined);
        props.groupId &&
        dispatch(
            updateGroup({
                groupId: props.groupId,
                data: { name: data.name },
            }),
        );
    };

    const keyDownHandler = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    };

    useEffect(() => {
        props.name && setValue('name', props.name);
    }, [props.name]);

    return (
        <div className='create-group-main'>
            <div className='create-group-paper'>
                <div className='create-group-top'>
                    <img
                        onClick={ () => {
                            props.setOpenModal(false);
                            props.setName(undefined);
                        } }
                        alt='fermer la modal '
                        src={ close }
                    />
                    <div className='create-group-title'>Renommer un groupe de mapping</div>
                </div>
                <div className='create-group-body'>
                    <form onSubmit={ handleSubmit(onSubmit) } onKeyDown={ keyDownHandler }>
                        <div className='input-container'>
                            <label htmlFor='name'>Nom</label>
                            <input
                                defaultValue={ group.payload.content?.name }
                                placeholder='Nom du groupe'
                                { ...register('name', {
                                    required: true,
                                }) }
                            />
                            <> {returnadvice(watch('name'), 24)}</>
                            <div className='err-box'>{ errors && formErrors(errors.name) }</div>
                        </div>
                        <div className='btn-box'>
                            <button
                                onClick={ () => {
                                    props.setOpenModal(false);
                                    props.setName(undefined);
                                } }
                                className='btn-close'
                            >
                                Annuler
                            </button>
                            <button className='btn-action'>Modifier</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default UpdateGroupMapping;
