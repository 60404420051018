import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Guid } from 'guid-typescript';

export type EntityState = {
    entityIds: { entityId: Guid; thumbnailRef: string }[]; //useless
    currentTargetEntity: SceneEntity | undefined; //useless
};

const InitialEntityState: EntityState = {
    entityIds: [],
    currentTargetEntity: undefined,
};

const EntitiesSlice = createSlice({
    name: 'Entities',
    initialState: InitialEntityState,
    //reducers : define how the state can be updated
    reducers: {
        AddEntityID: (state, action: PayloadAction<{ entityId: Guid; thumbnailRef: string }>) => {
            if (action.payload != null) state.entityIds.push(action.payload);
        },
        RemoveEntityWithID: (state, action: PayloadAction<Guid>) =>
        {
            let object = state.entityIds.find((obj)=>{
                return obj.entityId.equals(action.payload);
            });
            if(!object)
            {
                console.warn("Attempted to remove invalid object");
                return;
            }
            let index = state.entityIds.indexOf(object);
            if (action.payload != null) state.entityIds.splice(index, 1);
        },
        ClearEntitiesIDs: (state) => {
            state.entityIds.splice(0, state.entityIds.length);
        },
        RemoveEntityWithRef: (state, action: PayloadAction<string>) =>
        {
            let objectToRemove;
            state.entityIds.forEach((obj) =>
            {
                if(obj.thumbnailRef === action.payload)
                    objectToRemove = obj;
            });
            if(objectToRemove) state.entityIds.splice(state.entityIds.indexOf(objectToRemove));
        },
        SetCurrentTargetEntity: (state, action: PayloadAction<SceneEntity>) => {
            if (action.payload != null) state.currentTargetEntity = action.payload;
        },
        ClearCurrentTargetEntity: (state) => {
            state.currentTargetEntity = undefined;
        },
    },
});

export const {RemoveEntityWithID , AddEntityID, ClearEntitiesIDs, SetCurrentTargetEntity, ClearCurrentTargetEntity, RemoveEntityWithRef } = EntitiesSlice.actions;
export default EntitiesSlice;
