import './cutom-modal.scss';
import Textfield from '../../textfield/textfield';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToasterVisible } from '../../../services/reducers/toaster-reducer/toaster.slice';
import { resetPassword, resetUserPassword } from '../../../services/reducers/reset-password/reset-password';
import { RootState } from '../../../services/root-reducer';
import MdfApiResponse from '../../../domain/common/generic';

type CustomModalprops = {
    setOpen: (open: boolean) => void;
};

const CustomModal = ({ setOpen }: CustomModalprops) => {
    const [password, setPaswword] = useState<string>('');
    const [isPassErr, setIsPassErr] = useState<boolean>(false);
    const [passErrMsg, setPassErrMsg] = useState<string>('');

    const [newPassword, setNewPaswword] = useState<string>('');
    const [isNewPassErr, setIsNewPassErr] = useState<boolean>(false);
    const [newPassErrMsg, setNewPassErrMsg] = useState<string>('');

    const [confirmPassword, setConfirmPaswword] = useState<string>('');
    const [isConfirmPassErr, setIsConfirmPassErr] = useState<boolean>(false);
    const [confirmPassErrMsg, setConfirmPassErrMsg] = useState<string>('');
    const [, setServerErr] = useState<any | undefined>([]);
    const [cont, setCont] = useState<boolean>(false);

    const dispatch = useDispatch();
    const checkErrors = () => {
        if (password.length < 1 || confirmPassword.length < 1 || newPassword.length < 1) {
            if (password.length < 1) {
                setIsPassErr(true);
                setPassErrMsg('Le mot de passe doit être specifié');
            }
            if (newPassword.length < 1) {
                setIsNewPassErr(true);
                setNewPassErrMsg('Le nouveau mot de passe doit être specifié');
            }
            if (confirmPassword.length < 1) {
                setIsConfirmPassErr(true);
                setConfirmPassErrMsg('La confirmation du nouveau mot de passe doit être specifiée');
            }
            return false;
        }
        return true;
    };

    type ResetPassword = {
        isLoading: boolean;
        payload: MdfApiResponse<any>;
    };
    const { payload } = useSelector<RootState, ResetPassword>((state) => state.resetUserPassword);

    const userInfo = localStorage.getItem('user');
    const user = userInfo && JSON.parse(userInfo);

    useEffect(() => {
        if (cont) {
            const set = async () => {
                await setServerErr(payload.errors);
            };
            set().then(() => {
                if (payload.errors.length > 0) {
                    setIsPassErr(true);
                    setPassErrMsg('le mot de passe est invalide');
                    setCont(false);
                } else {
                    setPaswword('');
                    setNewPaswword('');
                    setConfirmPaswword('');
                    dispatch(
                        setToasterVisible({
                            title: 'Nouveau mot de passe',
                            message: 'le mot de passe à été modifié',
                        })
                    );
                    dispatch(resetUserPassword());
                    setCont(false);
                    setOpen(false);
                }
            });
        } else {
            return;
        }
    }, [payload]);

    const handleSubmit = () => {
        if (checkErrors()) {
            const validPassword = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$');
            if (!validPassword.test(newPassword)) {
                setIsNewPassErr(true);
                setNewPassErrMsg('le mot de pass doit contenir: 8 caractères, 1 majuscule, 1 chiffre et 1 caractère speciale ');
            } else {
                if (newPassword !== confirmPassword) {
                    setIsConfirmPassErr(true);
                    setIsNewPassErr(true);
                    setConfirmPassErrMsg('Les mots de passe ne correspôndent pas ');
                } else {
                    dispatch(
                        resetPassword({
                            userId: user.id,
                            passwordObj: { oldPassword: password, newPassword: newPassword },
                        })
                    );
                    setCont(true);
                }
            }
        }
    };

    return (
        <div className="custom-modal-main">
            <div className="custom-modal-dialog-box">
                <div className="custom-modal-title">NOUVEAU MOT DE PASSE</div>
                <div className="custom-modal-form">
                    <Textfield
                        label="MOT DE PASSE ACTUEL"
                        isError={isPassErr}
                        errorMessage={passErrMsg}
                        name={password}
                        value={password}
                        placeholder=""
                        onChange={(value) => {
                            setPaswword(value);
                            setPassErrMsg('');
                            setIsPassErr(false);
                        }}
                    />
                    <Textfield
                        type="password"
                        label="NOUVEAU MOT DE PASSE"
                        isError={isNewPassErr}
                        errorMessage={newPassErrMsg}
                        name={newPassword}
                        value={newPassword}
                        placeholder=""
                        onChange={(value) => {
                            setNewPaswword(value);
                            setNewPassErrMsg('');
                            setIsNewPassErr(false);
                            setIsConfirmPassErr(false);
                        }}
                    />
                    <Textfield
                        label="CONFIRMER LE NOUVEAU MOT DE PASSE"
                        type="password"
                        isError={isConfirmPassErr}
                        errorMessage={confirmPassErrMsg}
                        name={confirmPassword}
                        value={confirmPassword}
                        placeholder=""
                        onChange={(value) => {
                            setConfirmPaswword(value);
                            setConfirmPassErrMsg('');
                            setIsConfirmPassErr(false);
                            setIsNewPassErr(false);
                        }}
                    />
                </div>
                <div className="custom-modal-button-container">
                    <button onClick={() => setOpen(false)} className="button-cancel">
                        Annuler
                    </button>
                    <button onClick={handleSubmit} className="button-action">
                        Changer le mot de passe
                    </button>
                </div>
            </div>
        </div>
    );
};
export default CustomModal;
