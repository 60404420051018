import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {TextureType} from "../ParamTypes/TextureType";
import {BooleanType} from "../ParamTypes/BooleanType";
import {ButtonType} from "../ParamTypes/ButtonType";
import {SliderData, SliderType} from "../ParamTypes/SliderType";
import {InspectorType} from "../ParamTypes/InspectorType";
import {MeshPhysicalMaterial, Vector2} from "three";
import {VectorData, VectorType} from "../ParamTypes/VectorType";
import {SetTypeOfCell} from "../../../Libraries/TweakPaneUtilities";

export class Normal {
    public m_normalMapShow: InspectorType;
    public m_normalScale: InspectorType;
    public m_normalMap: InspectorType;
    public m_normalFlipY: InspectorType;
    public m_normalClear: InspectorType;
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        //Albedo
        this.m_uiFolder = m_uiFolders = m_tab.pages[0].addFolder({title: 'Normal Map (RGB) [no texture]', expanded: false});
        this.m_normalMapShow = new BooleanType("Show map on mesh", m_matParams, "normalMapShowOnMesh", m_uiFolders);
        this.m_normalScale = new VectorType(new VectorData(new Vector2(-1,-1), new Vector2(1,1)), m_matParams, "normalScale", m_uiFolders);
        this.m_normalMap = new TextureType(MapType.normalMap, m_matParams, "normalMap", m_uiFolders);
        this.m_normalFlipY = new BooleanType("Flip Y", m_matParams, "normalMapFlipY", m_uiFolders);
        this.m_normalClear = new ButtonType("Clear map", m_matParams, "", m_uiFolders);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_normalMapShow.InitType();
        this.m_normalScale.InitType();
        this.m_normalMap.InitType();
        this.m_normalFlipY.InitType();
        this.m_normalClear.InitType();
    }
}