import React from 'react';
import './news-categories-head.scss';

const NewsCategoriesHeader = () => (
        <thead className="categories-head-main">
            <tr>
                <th>NOM</th>
                <th className="category-head-deleted">ACTIONS</th>
            </tr>
        </thead>
);

export default NewsCategoriesHeader;
