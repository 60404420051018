import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import passwordServices from '../../../api/services/paswword/password.services';

type Password = {
    userId: number;
    passwordObj: {
        oldPassword: string;
        newPassword: string;
    };
};

const initialState = {
    isLoading: false,
    isRejected: false,
    errorMessage: '',
    successMessage: '',
    payload: { content: null, errors: [], warnings: [] },
};

export const resetPassword = createAsyncThunk('reset/password', async (data: Password) => {
    const res = await passwordServices.resetPassword(data.userId, data.passwordObj);
    return res;
});



const resetPasswordSlice = createSlice({
    name: 'resetPasswordSlice',
    initialState: initialState,
    reducers: {
        resetUserPassword: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetPassword.pending,(state)=>{
            state.isLoading=true;
        });
        builder.addCase(resetPassword.rejected, (state)=>{
            state.isLoading=false;
            state.isRejected=true;
        });
        builder.addCase(resetPassword.fulfilled,(state, { payload })=>{
            state.payload=payload;
            state.isRejected=false;
            state.isLoading=false;
            state.successMessage="request passed successfully";
        });
    },
});

export const { resetUserPassword } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;