import Layout from '../../../uicomponents/layout/layout';
import MainPaper from '../../../uicomponents/paper/main-paper';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CatalogProducts from '../catalog-produit/catalog-products';
import CatalogSection from '../catalog-section/catalog-section';
import { Params } from '../../../domain/Params/params-interface';
import CatalogInfo from '../catalog-info/catalog-info';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/root-reducer';
import { CatalogResponseState, CatalogState, getCatalogByRef } from '../../../services/reducers/catalog/catalog.slice';
import { CatalogResponse, Collection, Supplier } from '../../../domain/domain';
import { resetGetCollections } from '../../../services/reducers/suppliers/collection/collection.slice';
import CatalogServices from '../../../api/services/catalog/catalog.services';


const Catalog = () => {
    const { catalogRef } = useParams<Params>();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const spanTab = ['Informations', 'Rubriques', 'Presets'];
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [selectedSupplier, setSelectSupplier] = useState<Supplier | undefined>(undefined);
    const [selectedCollection, setSelectCollection] = useState<Collection | undefined>(undefined);

    const catalog = useSelector<RootState, CatalogResponseState>(state => state.getCatalogByRef);
    const [catalogR, setCatalogR] = useState<CatalogResponse>({
        catalog: {
            id: 0,
            version: 0,
            reference: '',
            numberOfPreset: 0,
            name: '',
            createdAt: new Date(),
            updatedAt: new Date(),
        },
        catalogUserList: [],
    });

    useEffect(() => {
        dispatch(resetGetCollections());
        CatalogServices.getCatalogByRef(catalogRef)
            .then((r) => {
                setCatalogR(r.content);
            });
        tabIndex === 0 && history.push(`/catalog/${catalogRef}/infos`);
        tabIndex === 1 && history.push(`/catalog/${catalogRef}/sections`);
        tabIndex === 2 && history.push(`/catalog/${catalogRef}/products`);
    }, [tabIndex]);

    return (
        <Layout title=''>
            <div className='navTag-main'>
                {spanTab.map((el: string, i: number) => {
                    return (
                        <span
                            key={i}
                            className={tabIndex === i ? 'span-focus' : ''}
                            onClick={() => {
                                setTabIndex(i);
                                setSelectSupplier(undefined);
                                setSelectCollection(undefined);
                            }}
                        >
                            {el}
                        </span>
                    );
                })}
            </div>
            <MainPaper>
                {location.pathname.includes('infos') && <CatalogInfo catalog={catalogR} />}
                {location.pathname.includes('sections') && <CatalogSection />}
                {location.pathname.includes('products') &&
                    <CatalogProducts
                        selectedSupplier={selectedSupplier}
                        setSelectSupplier={setSelectSupplier}
                        selectedCollection={selectedCollection}
                        setSelectCollection={setSelectCollection}
                        currentCatalog={catalog.payload.content.catalog} />}
            </MainPaper>
        </Layout>
    );
};
export default Catalog;
