import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vector3 } from 'three';

export type ThumbnailState = {
    image: string | Blob | undefined; //useless
    type: string | undefined; //String "Plane", "Fabric", "Multi" permet d'alterer le model de présentation
    currentType: string | undefined; //String "Plane", "Fabric", "Multi" permet d'alterer le model de présentation
    typeCameraPositions: { thumbnailType: string; cameraPosition: Vector3 }[]; //String "Plane", "Fabric", "Multi" permet d'alterer le model de présentation
};

const InitialThumbnailState: ThumbnailState = {
    image: undefined,
    type: undefined,
    currentType: undefined,
    typeCameraPositions: [],
};

const ThumbnailSlice = createSlice({
    name: 'Entities',
    initialState: InitialThumbnailState,
    //reducers : define how the state can be updated
    reducers: {
        SetImage: (state, action: PayloadAction<string | Blob | undefined>) => {
            if (action.payload != null) state.image = action.payload;
        },
        SetType: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload != null) state.type = action.payload;
        },
        SetCurrentType: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload != null) state.currentType = action.payload;
        },
        AddTypeCameraPosition: (state, action: PayloadAction<{ thumbnailType: string; cameraPosition: Vector3 }>) => {
            if (action.payload != null) state.typeCameraPositions.push(action.payload);
        },
        ClearTypeCameraPositions: (state) => {
            state.typeCameraPositions.Clear();
        },
        ClearImage: (state) =>
        {
            state.image = undefined;
        },
    },
});

export const { SetImage, SetType, SetCurrentType, ClearTypeCameraPositions, AddTypeCameraPosition, ClearImage} = ThumbnailSlice.actions;
export default ThumbnailSlice;
