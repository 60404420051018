import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import projectsService from '../../../api/services/projects/projects.services';
import MdfApiResponse from '../../../domain/common/generic';
import { Project, Room } from '../../../domain/domain';
import ProductData from '../../../domain/common/product';

// ==== get all project ===
export type GetProjectsState = {
    isLoading: boolean;
    payload: any;
    error: any;
};
const initialGetProjects: GetProjectsState = {
    isLoading: false,
    payload: { content: [], warnings: [], errors: [] },
    error: {},
};
export const getProjects = createAsyncThunk('/projects', async () => {
    const result = await projectsService.getProjects();
    return result;
});
export const getProjectsSlice = createSlice({
    name: 'ProjectSlice',
    initialState: initialGetProjects,
    reducers: {
        resetProjects: (state) => {
            state = initialGetProjects;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProjects.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProjects.fulfilled, (state, { payload }) => {
            state.error = payload.errors;
            state.isLoading = false;
            state.payload = payload.content;
        });
        builder.addCase(getProjects.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// ==== get User project ===
export type GetUserProjectsState = {
    isLoading: boolean;
    payload: MdfApiResponse<Project[]>;
};
const initialGetUserProjects: GetUserProjectsState = {
    isLoading: false,
    payload: { content: [], warnings: [], errors: [] },
};
export const getUserProjects = createAsyncThunk('User/projects', async (userId: number) => {
    const result = await projectsService.getUserProjects(userId);
    return result;
});
export const getUserProjectsSlice = createSlice({
    name: 'ProjectSlice',
    initialState: initialGetUserProjects,
    reducers: {
        resetUserProjects: (state) => {
            state = initialGetProjects;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserProjects.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getUserProjects.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(getUserProjects.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

export const { resetUserProjects } = getUserProjectsSlice.actions;

// ==== get project by code ===
export type GetUserProjectByCode = {
    isLoading: boolean;
    payload: MdfApiResponse<Project | undefined>;
};
const initialGetUserProjectByCode: GetUserProjectByCode = {
    isLoading: false,
    payload: { content: undefined, warnings: [], errors: [] },
};
export const getUserProjectByCode = createAsyncThunk('User/project/byCode', async (code: string | undefined) => {
    const result = await projectsService.getProjectsByCode(code);
    return result;
});
export const getUserProjectByCodeSlice = createSlice({
    name: 'ProjectByCodeSlice',
    initialState: initialGetUserProjectByCode,
    reducers: {
        resetUserProjectByCode: (state) => {
            state = initialGetUserProjectByCode;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserProjectByCode.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getUserProjectByCode.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(getUserProjectByCode.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

export const { resetUserProjectByCode } = getUserProjectByCodeSlice.actions;

// ==== create project ===
export type CreateProjectsState = {
    isLoading: boolean;
    payload: MdfApiResponse<Project | undefined>;
};
const initialCreateProjects: CreateProjectsState = {
    isLoading: false,
    payload: { content: undefined, warnings: [], errors: [] },
};

export const createProject = createAsyncThunk('create/project', async ({ projectName }: { projectName: string }) => {
    const result = await projectsService.createProject(projectName);

    return result;
});
export const createProjectsSlice = createSlice({
    name: 'CreateProjectSlice',
    initialState: initialCreateProjects,
    reducers: {
        resetCreateProjects: (state) => {
            state = initialCreateProjects;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createProject.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createProject.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(createProject.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateProjects } = createProjectsSlice.actions;

// ==== import project ===
export type ImportProjectsState = {
    isLoading: boolean;
    payload: MdfApiResponse<Project | undefined>;
};
const initialImportProjects: ImportProjectsState = {
    isLoading: false,
    payload: { content: undefined, warnings: [], errors: [] },
};
export const importProject = createAsyncThunk('import/project', async ({
                                                                           userId,
                                                                           code,
                                                                       }: { userId: number; code: string }) => {
    const result = await projectsService.importProject(userId, code);
    return result;
});
export const importProjectsSlice = createSlice({
    name: 'ImportProjectSlice',
    initialState: initialImportProjects,
    reducers: {
        resetImportProjects: (state) => {
            state = initialImportProjects;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(importProject.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(importProject.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(importProject.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});
export const { resetImportProjects } = importProjectsSlice.actions;

// ==== delete User project ===
export type DeleteUserProjectsState = {
    isLoading: boolean;
    payload: MdfApiResponse<Project | undefined>;
};
const initialDeleteUserProjects: DeleteUserProjectsState = {
    isLoading: false,
    payload: { content: undefined, warnings: [], errors: [] },
};
export const deleteUserProjects = createAsyncThunk('delete/user/projects', async ({
                                                                                      projectId,
                                                                                      userId,
                                                                                  }: { projectId: number; userId: number }) => {
    const result = await projectsService.deleteUserProjects(projectId, userId);
    return result;
});
export const deleteUserProjectsSlice = createSlice({
    name: 'deleteUserProjectSlice',
    initialState: initialDeleteUserProjects,
    reducers: {
        resetDeleteUserProjects: (state) => {
            state = initialDeleteUserProjects;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteUserProjects.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteUserProjects.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(deleteUserProjects.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// ==== update User project ===
export type UpdateProjectState = {
    isLoading: boolean;
    payload: MdfApiResponse<ProductData | undefined>;
};
const initialUpdateProject: UpdateProjectState = {
    isLoading: false,
    payload: {
        content: {
            code: '',
            rooms: [
                {
                    name: '',
                    products: [
                        {
                            ref: 'someKindOfUniqueRef',
                            assets: [
                                {
                                    refOfPart: 'setTableConceptPied',
                                    type: 'Model',
                                    section: 'Pied',
                                    urls: ['https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/TableConceptLOD/Pieds/CONCEPT_PIED01-P135_LOD0.glb'],
                                    options: [],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        warnings: [],
        errors: [],
    },
};
export const updateProject = createAsyncThunk('update/project', async ({
                                                                           projectId,
                                                                           project,
                                                                       }: { projectId: number; project: ProductData }) => {
    const result = await projectsService.updateProject(projectId, project);
    return result;
});
export const updateProjectSlice = createSlice({
    name: 'updateProjectSlice',
    initialState: initialUpdateProject,
    reducers: {
        resetUpdateUserProject: (state) => {
            state = initialUpdateProject;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateProject.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateProject.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(updateProject.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// ==== update project Name ===
export type UpdateProjectNameState = {
    isLoading: boolean;
    payload: MdfApiResponse<Project[]>;
};
const initialUpdateProjectName: UpdateProjectNameState = {
    isLoading: false,
    payload: { content: [], warnings: [], errors: [] },
};
export const updateProjectName = createAsyncThunk('projects/name', async ({
                                                                              projectId,
                                                                              projectName,
                                                                          }: { projectId: number | undefined; projectName: string }) => {
    const result = await projectsService.updateProjectName(projectId, projectName);
    return result;
});
export const updateProjectNameSlice = createSlice({
    name: 'UpdateProjectNameSlice',
    initialState: initialUpdateProjectName,
    reducers: {
        resetUpdateProjectName: (state) => {
            state = initialUpdateProjectName;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateProjectName.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateProjectName.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(updateProjectName.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// ==== update project room ===
export type UpdateProjectRoomState = {
    isLoading: boolean;
    payload: MdfApiResponse<Room | undefined>;
};
const updateProjectNameInitialState: UpdateProjectRoomState = {
    isLoading: false,
    payload: { content: undefined, warnings: [], errors: [] },
};
export const updateProjectRoom = createAsyncThunk('projects/room/save', async ({
                                                                                   projectId,
                                                                                   roomUuid,
                                                                                   room,
                                                                               }: { projectId: number; roomUuid: string; room: Room }) => {
    const result = await projectsService.saveProjectRoom(projectId, roomUuid, room);
    return result;
});
export const updateProjectRoomSlice = createSlice({
    name: 'updateProjectRoomSlice',
    initialState: updateProjectNameInitialState,
    reducers: {
        resetUpdateProjectRoom: (state) => {
            state = updateProjectNameInitialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateProjectRoom.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateProjectRoom.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(updateProjectRoom.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

export const updateProjectMetadata = createAsyncThunk('projects/room/metadata/save', async ({
                                                                                                projectId,
                                                                                                roomUuid,
                                                                                                data,
                                                                                            }: { projectId: number; roomUuid: string; data: String }) => {
    const result = await projectsService.saveProjectMetadata(projectId, roomUuid, data);
    return result;
});
export const updateProjectRoomMetadataSlice = createSlice({
    name: 'updateProjectRoomMetadataSlice',
    initialState: updateProjectNameInitialState,
    reducers: {
        resetUpdateProjectRoomMetadata: (state) => {
            state = updateProjectNameInitialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateProjectMetadata.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateProjectMetadata.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(updateProjectMetadata.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

export const { resetUpdateProjectRoom } = updateProjectRoomSlice.actions;
