import './row/role-row.scss';
import { RoleWithNumberOfUser } from '../../domain/domain';
import RolesHeader from './head/role-head';
import RoleRow from './row/role-row';

type RolesTableProps = {
    roles: RoleWithNumberOfUser[];
    onDeleteRole: (roleId: number) => void;
};

const RoleTable = ({ roles, onDeleteRole }: RolesTableProps) => {
    return (
        <table className='table-main'>
            <RolesHeader />
            <tbody>

                { roles &&
                    roles.map((el) => {
                        return <RoleRow item={ el } key={ el.roleId } onDeleteRole={ onDeleteRole } />;
                    }) }

            </tbody>
        </table>
    );
};

export default RoleTable;
