import './product-rules-table.scss';
import trash from '../../../asset/poubelle-red.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/root-reducer';
import { deleteProductRules, getProductRules, ProductRulesState, resetCreateRules, resetDeleteProductRules } from '../../../services/reducers/product/rules/rules.reducers';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ActionType, Product, Rule, RuleType } from '../../../domain/domain';
import SimpleModal from '../../../uicomponents/modal/simple-modal/simple-modal';
import PriceRules from './price.rules';
import ConfigurationRules from './configuration-rules';
import DescriptionRules from './description-rules';
import ConstructionRule from './construction-rule';

type ProductRulesTableProps = {
    setOpenCreateModal: (openModal: boolean) => void;
    setRuleConfType: (ruleConfType: RuleType) => void;
    type: number;
    product: Product;
    setOpenAssociateRule: Dispatch<SetStateAction<boolean>>;
    openAssociateRule: boolean;
    setCurrentRule: (currentRule: Rule) => void;
};

const ProductRulesTable = ({ type, product, setOpenCreateModal, setRuleConfType, setOpenAssociateRule, openAssociateRule, setCurrentRule }: ProductRulesTableProps) => {
    const dispatch = useDispatch();
    const rulesRes = useSelector<RootState, ProductRulesState>((state) => state.getRules);
    const deleteRulesRes = useSelector<RootState, ProductRulesState>((state) => state.deleteRules);
    const addRulesRes = useSelector<RootState, ProductRulesState>((state) => state.createRule);

    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [ruleRef, setRuleRef] = useState<string>('');

    useEffect(() => {
        if (deleteRulesRes.payload.errors.length === 0 && !deleteRulesRes.payload.content.parts) {
            setOpenDeleteModal(false);
            dispatch(getProductRules(product.reference));
            dispatch(resetDeleteProductRules());
        }
    }, [deleteRulesRes]);
    useEffect(() => {
        if (addRulesRes.payload.errors.length === 0 && !addRulesRes.payload.content.parts) {
            setOpenCreateModal(false);
            dispatch(getProductRules(product.reference));
            dispatch(resetCreateRules());
        }
    }, [addRulesRes]);

    const returnTableByRulesType = () => {
        if (rulesRes.payload.content !== null) {
            const priceRulesList = rulesRes.payload.content.ruleSet.rules.rules.filter((el: Rule) => el.type === 'PRICE');
            const confRulesList = rulesRes.payload.content.ruleSet.rules.rules.filter((el: Rule) => el.type === 'POSSIBLE_CONF' || el.type === 'IMPOSSIBLE_CONF');
            const descRulesList = rulesRes.payload.content.ruleSet.rules.rules.filter((el: Rule) => el.type === 'DIMENSION');
            const returnActionType = (actionType: ActionType) => {
                switch (actionType) {
                    case 'PRICE_SET_BASE':
                        return 'Prix de base';

                    case 'PRICE_APPLY_RATIO':
                        return 'Augmentation';
                }
            };
            const returnAtomValue = (valueRefAndName: { [index: string]: string }, rule: Rule): string[] => {
                let listOfRuleAtomKeyValue: string[] = [];
                let listOfRuleAtomValue: string[] = [];

                Object.values(rule.matching.model3DCellValueRefByPartItemRef).map((v) => listOfRuleAtomKeyValue.push(v[0]));

                listOfRuleAtomKeyValue.forEach((ref: string) => {
                    valueRefAndName[ref] !== undefined ? listOfRuleAtomValue.push(valueRefAndName[ref]) : listOfRuleAtomValue.push('?');
                });
                return listOfRuleAtomValue;
            };
            const returnValue = (actionType: ActionType, value: number, percent: boolean) => {
                if (actionType === 'PRICE_SET_BASE') {
                    return `${(value / 100).toFixed(2)} €`;
                } else {
                    return percent ? `${(value / 100).toFixed(2)} %` : `${(value / 100).toFixed(2)} €`;
                }
            };

            switch (type) {
                case 0:
                    return (
                        <PriceRules
                            setCurrentRule={setCurrentRule}
                            setOpenUpdateModal={setOpenCreateModal}
                            setRuleRef={setRuleRef}
                            priceRulesList={priceRulesList}
                            rulesRes={rulesRes}
                            setRuleConfType={setRuleConfType}
                            returnActionType={returnActionType}
                            returnAtomValue={returnAtomValue}
                            returnValue={returnValue}
                            setOpenDeleteModal={setOpenDeleteModal}
                        />
                    );
                case 1:
                    return (
                        <ConfigurationRules
                            setCurrentRule={setCurrentRule}
                            setOpenUpdateModal={setOpenCreateModal}
                            setRuleConfType={setRuleConfType}
                            confRulesList={confRulesList}
                            setRuleRef={setRuleRef}
                            setOpenDeleteModal={setOpenDeleteModal}
                        />
                    );
                case 2:
                    return (
                        <DescriptionRules
                            setCurrentRule={setCurrentRule}
                            setOpenUpdateModal={setOpenCreateModal}
                            setRuleConfType={setRuleConfType}
                            confRulesList={descRulesList}
                            setRuleRef={setRuleRef}
                            setOpenDeleteModal={setOpenDeleteModal}
                        />
                    );
                case 3:
                    return <ConstructionRule openAssociateRule={openAssociateRule} setOpenAssociateRule={setOpenAssociateRule} />;
            }
        }
    };

    return (
        <>
            {openDeleteModal && (
                <SimpleModal
                    icon={trash}
                    title="Supprimer cette règle"
                    info="Cette cation supprimera la règle lié au produit"
                    closeOnclick={() => setOpenDeleteModal(false)}
                    actionOnclick={() => dispatch(deleteProductRules({ productRef: product.reference, ruleRef }))}
                    deleteOrValide={true}
                    closeLabel="Annuler"
                    actionLabel="Supprimer"
                />
            )}
            {returnTableByRulesType()}
        </>
    );
};
export default ProductRulesTable;
