import React from 'react';
import './button.css';

interface ButtonProps {
    primary?: boolean;
    backgroundColor?: string;
    size?: 'small' | 'medium' | 'large' | 'max';
    className?: string;
    label: string;
    isActive?: boolean;
    onClick?: () => void;
}

export const Button = ({ className, primary = false, size = 'medium', backgroundColor, label, isActive, ...props }: ButtonProps) => {
    const mode = primary ? 'storybook-button--primary' : 'storybook-button--secondary';
    return (
        <button type="button"
                className={['storybook-button', `storybook-button--${size}`, className, mode].join(' ')}
                style={{ backgroundColor }}
                disabled={isActive} {...props}>
            {label}
        </button>
    );
};
