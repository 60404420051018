import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Spherical} from "three";
import {
    PointOfViewPlacement
} from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/PointOfViews";
import {CharacteristicStudioApp} from "../../../Libraries/Studios/CharacteristicStudio/CharacteristicStudioApp";


export type CharacteristicStudioState = {
    characteristicStudioApp: CharacteristicStudioApp | undefined, 
    pointOfViewPlacement: PointOfViewPlacement | undefined,
    placements: Array<{ placement: PointOfViewPlacement, position: Spherical }>,
};

const StudioInitialState: CharacteristicStudioState = {
    characteristicStudioApp: undefined,
    pointOfViewPlacement: PointOfViewPlacement.THREE_QUARTER_OFF,
    placements: new Array<{ placement: PointOfViewPlacement, position: Spherical }>(),
};

const StudioSlice = createSlice({
    name: 'Studio',
    initialState: StudioInitialState,
    //reducers : define how the state can be updated
    reducers: {
        setStudio: (state, action: PayloadAction<any>) => {
            if (action.payload !== null)
                state.characteristicStudioApp = action.payload;
        },
        clearPlacements: (state) => {
            state.placements.Clear();
        },
        setAllPointOfViewPlacements: (state, action: PayloadAction<{ placement: PointOfViewPlacement, position: Spherical }[]>) => {
            if (action.payload !== null) {
                state.placements = action.payload;
            }
        },
        setPointOfViewPlacement: (state, action: PayloadAction<PointOfViewPlacement>) => {
            if (action.payload !== null) {
                state.pointOfViewPlacement = action.payload;
            }
        },
    },
});

export const { setStudio, setPointOfViewPlacement, setAllPointOfViewPlacements, clearPlacements } = StudioSlice.actions;
export default StudioSlice;
