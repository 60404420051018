import MdfApiResponse from '../domain/common/generic';
import { Action, Dispatch } from 'redux';
import { setToasterError } from '../services/reducers/toaster-reducer/toaster.slice';
import { AuthenticationResponse, RolePermissionType } from '../domain/domain';

const continueIfNoErrors = (response: MdfApiResponse<unknown>, extraConditions: boolean[], dispatch: Dispatch<Action>, callback: () => void) => {
    let conditionCombo = true;
    for (const condition in extraConditions) {
        if (!condition) {
            conditionCombo = false;
        }
    }
    if (!conditionCombo || response.errors.length > 0) {
        dispatch(setToasterError({ message: response.errors[0].message, title: response.errors[0].code }));
    } else {
        callback();
    }
};

const checkPermission = (permission: RolePermissionType, user: AuthenticationResponse | null) => {
    let authorized = false;
    if (!user) {
        return false;
    }

    user.user.roles.forEach((r) => {
        r.permissions.forEach((p) => {
            if (p.name === permission) {
                authorized = true;
            }
        });
    });
    return authorized;
};

const MdfPreconditions = { continueIfNoErrors, checkPermission };

export default MdfPreconditions;
