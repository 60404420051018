import close from '../../asset/CloseWindow.svg';
import { Tag } from '../../domain/domain';
import { useEffect, useState } from 'react';
import './tag-input.scss';
import { createTag, CreateTagState, getProductTag } from '../../services/reducers/tag/tag.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';


type TagInputProps = {
    tagIdList: number[]
    setTagIdList: (tagIdList: number[]) => void
    taglist: Tag[]
    setTagList: (taglist: Tag[]) => void
    allProductTag: Tag[]
    focus: boolean
    setFocus: (focus: boolean) => void
};

const TagInput = (props: TagInputProps) => {
    const dispatch = useDispatch();

    const createTagsRes = useSelector<RootState, CreateTagState>((state) => state.createTag);

    const [filterTagList, setFilterTagList] = useState<Tag[]>([]);
    const [displayTagList, setDisplayTagList] = useState<Tag[]>([]);
    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        setDisplayTagList(props.taglist);
    }, [props.taglist]);

    useEffect(() => {
        let idlist: number[] = [];
        displayTagList.forEach((displayTag: Tag) => idlist.push(displayTag.id));
        createFilterTagList();
        props.setTagIdList(idlist);
    }, [search, displayTagList, createTagsRes]);

    useEffect(() => {
        if (createTagsRes.payload.content && createTagsRes.payload.errors.length === 0) {

            // @ts-ignore
            setDisplayTagList(prevState => [...prevState, createTagsRes.payload.content]);
            // @ts-ignore
            props.setTagIdList(prevState => [...prevState, createTagsRes.payload.content]);
        }
    }, [createTagsRes]);

    const removeTag = (index: number) => {
        setDisplayTagList(displayTagList.filter((RemovableTag: Tag, i: number) => i !== index));
    };
    const createFilterTagList = () => {
        const ids = new Set(displayTagList.map(d => d.id));
        const filter = props.allProductTag.filter((productTag: Tag) => {
            return !ids.has(productTag.id);
        });
        setFilterTagList(filter);
    };
    const createProductTag = (tagName: string) => {
        props.setFocus(false);
        setSearch('');
        dispatch(createTag({ tagEntityType: 'PRODUCT', tagName: tagName }));
        dispatch(getProductTag());
    };

    const searchedList = filterTagList.filter((filterTag: Tag) => {
        return search === '' ? filterTagList : filterTag.name.toLowerCase().includes(search);
    });


    return (
        <>
            <div className='tags-input-container'>
                <div
                    onClick={ () => props.setFocus(false) }
                    className='item-box'>
                    { displayTagList.map((productTag: Tag, index: number) => {
                        return (
                            <div
                                key={ productTag.id } className='tag-item'>
                                <span
                                    className='text'>{ productTag.name.length < 9 ? productTag.name : `${ productTag.name.substring(0, 8) }...` }</span>
                                <img alt='supprimer un tag'
                                     onClick={ () => removeTag(index) } src={ close }
                                     className='close' />
                            </div>
                        );
                    }) }
                </div>
                <input id='frm' onClick={ () => {
                    props.setFocus(true);
                } }
                       onKeyDown={ (e) => e.key === 'Enter' && createProductTag(search) }
                       onChange={ (e) => setSearch(e.target.value.toLowerCase()) }
                       type='text'
                       value={ search }
                       className='tags-input'
                       placeholder='Ajouter un tag produit'
                />
            </div>
            { props.focus &&
                <div className='option'>
                    <div className='tag-option-title'> Ajouter un tag</div>
                    <div>{ searchedList.map((searchTag: Tag) => {
                        return (

                            <div className='tag-option'
                                 onClick={ () => {
                                     setSearch('');
                                     props.setFocus(false);
                                     setDisplayTagList(prevState => [...prevState, searchTag]);
                                     props.setTagIdList([...props.tagIdList, searchTag.id]);
                                 } }
                                 key={ searchTag.id }>
                                { searchTag.name }
                            </div>

                        );
                    })
                    }</div>
                </div>
            }
        </>
    );
};
export default TagInput;