import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { News, NewsCategory } from '../../domain/domain';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import { createNews } from '../../services/reducers/create-news/create-news.slice';
import { getNewsCategoryList } from '../../services/reducers/news/news-cateogory-list.slice';
import { setToasterError } from '../../services/reducers/toaster-reducer/toaster.slice';

import MdfPreconditions from '../../utils/MdfPreconditions';
import MdfApiResponse from '../../domain/common/generic';
import CreateNewsForm from '../../uicomponents/create-news-form/create-news';
import Perm from '../../components/perm/perm';


type NewsSelector = {
    isRejected: boolean;
    isLoading: boolean;
    payload: MdfApiResponse<News | null>;
};

type CategorySelector = {
    isLoading: boolean;
    payload: MdfApiResponse<NewsCategory[]>;
};

function CreateNews() {
    const dispatch = useDispatch();
    const history = useHistory();
    const createdNews = useSelector<RootState, NewsSelector>((state) => state.createNews);
    const categoryListResponse = useSelector<RootState, CategorySelector>((state) => state.categoryList);
    const [categoryList, setCategoryList] = useState<NewsCategory[]>([]);

    useEffect(() => {
        dispatch(getNewsCategoryList());
    }, []);

    useEffect(() => {
        MdfPreconditions.continueIfNoErrors(categoryListResponse.payload, [], dispatch, () => {
            setCategoryList(categoryListResponse.payload.content);
        });
    }, [categoryListResponse]);
    useEffect(() => {
        if (createdNews.payload.content !== null && createdNews.payload.errors.length === 0 && !createdNews.isLoading) {
            history.push('/home/news');
        } else if (createdNews.payload.errors.length !== 0) {
            dispatch(setToasterError({
                message: createdNews.payload.errors[0].message,
                title: createdNews.payload.errors[0].code,
            }));
        }
    }, [createdNews]);
    return (
        <Perm permission='NEWS_BACKOFFICE'>
            <CreateNewsForm
                onSubmit={ (form) => {
                    dispatch(createNews(form));
                } }
                reloadCategories={ () => {
                    dispatch(getNewsCategoryList());
                } }
                categoryList={ categoryList }
            />
            { createdNews.payload.errors.length > 0 && (
                <div className='mt-3'>
                    { createdNews.payload.errors.map((err) => (
                        <h4 key={ err.code }>{ err.message }</h4>
                    )) }
                </div>
            ) }
        </Perm>
    );
}

export default CreateNews;
