import Inspector from "../Inspector";
import {MeshPhysicalMaterial} from "three";
import * as THREE from "three";
import {MapType} from "../MapType";

export function AlbedoBind(this: Inspector) {
    let inspectorParameters = this.m_materialEditorParameters;
    const self: any = this;
    this.m_albedoSlide?.m_mapShow.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;

        let Mat = self.m_materialEditor.showOnMesh(self.m_currentEditedMaterial, inspectorParameters, 'map', ev.value);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
    });
    this.m_albedoSlide?.m_color.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.color = new THREE.Color(ev.value.r / 255, ev.value.g / 255, ev.value.b / 255);
        self.m_currentEditedMaterial.color.convertSRGBToLinear();
        self.m_currentEditedMaterial.userData.colorBuffer = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });
    this.m_albedoSlide?.m_albedoMap.BindType(function () {
        if (self.m_materialEditorParameters.map.src) {
            self.ToggleSlotDirty();
            self.UpdateMaterialFromUI(MapType.albedoMap, self.m_materialEditorParameters.map, self.m_materialEditorParameters.mapFlipY, self.m_materialEditorParameters.mapEncodingDefault);
            self.UpdateInspectorSlotTitle(MapType.albedoMap, self.m_albedoSlide?.m_uiFolder);
        } else {
            let folder = self.m_albedoSlide?.m_uiFolder;
            folder.title = self.m_titlesLabel["map"] + " [no texture]";
        }
    }); // strange fact : sometimes we receive map as its data, not img...
    this.m_albedoSlide?.m_albedoFlipY.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        let Mat;
        if (self.m_currentEditedMaterial) {
            Mat = self.m_materialEditor.FlipYTexture(['map'], ev.value);
        }
        if (self.m_materialUpdatedCallback && Mat) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });

    this.m_albedoSlide?.m_albedoClear.BindType(() => {
        if (!self.m_currentEditedMaterial) return;
        let texSlot = self.GetTextureFromSlot(MapType.albedoMap);
        if (!texSlot) return;
        let Mat = self.m_materialEditor.ClearMap(this.m_materialEditorParameters, [texSlot.slotName]);
        if (self.m_savedMaterial && self.m_currentEditedMaterial && self.m_dirtyGLTFCallback) self.m_dirtyGLTFCallback(self.m_savedMaterial, self.m_currentEditedMaterial);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat.clone() as MeshPhysicalMaterial, true);
        let folder = self.m_albedoSlide?.m_uiFolder;
        folder.title = this.m_titlesLabel[texSlot.slotName] + " [no texture]";
        (this.m_materialEditorParameters as any)[texSlot.slotName] = null;
        this.m_pane.refresh();
    });
}