import './MappingStudio.scss';
import React, {useEffect, useState} from "react";
import { Object3D } from "three";
import { MappingStudioViewer } from "./MappingStudioViewer";
import MeshLoaderManager from "./MeshLoaderManager";
import ResourceErrorData
    from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ResourceErrorData";
import Asset3D from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D";
import {
    PointOfViewPlacement
} from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/PointOfViews";
import {ResourceErrorHandler} from "../../../application3D-common/Components/ResourceErrorHandler/ResourceErrorHandler";


export type MappingStudioProps = {
    previewMesh?: Asset3D
    previewMapping?: Asset3D
    cameraPointOfView?: PointOfViewPlacement
    onLoadingFail?: (p_resourceErrorData: ResourceErrorData)=>void;
};

const MappingStudio = (props: MappingStudioProps) => {
    const [object, SetObject] = useState<Object3D | undefined>(undefined);
    
    return (
        <>
            <ResourceErrorHandler onLoadingFail={props.onLoadingFail} />
            <MeshLoaderManager previewMesh={props.previewMesh}
                            setObjectCallback={SetObject}/>
            <MappingStudioViewer/>
        </>
    );
};
 
export default MappingStudio;
