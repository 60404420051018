import './catalog/catalog.scss';
import Layout from '../../uicomponents/layout/layout';
import MainPaper from '../../uicomponents/paper/main-paper';
import CatalogTable from '../../components/table/catalog/catalog-table';
import { useEffect, useState } from 'react';
import CreateCatalog from '../../components/modals/catalog/create-catalogue/create-catalog';
import { useDispatch, useSelector } from 'react-redux';
import {
    CatalogListState, CatalogResponseState,
    CatalogState,
    getCatalogs,
    resetCreateCatalog,
    resetDeleteCatalog,
    SetCatalogName,
} from '../../services/reducers/catalog/catalog.slice';
import { RootState } from '../../services/root-reducer';
import { useHistory } from 'react-router-dom';


const CatalogMain = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    //region state
    const [createModal, setCreateModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isMount, setIsMount] = useState(false);
    //endregion

    //region selector
    const catalogs = useSelector<RootState, CatalogListState>((state) => state.getCatalogs);
    const createAndUpdateCatalogRes = useSelector<RootState, CatalogResponseState>((state) => state.createAndUpdateCatalog);
    const deleteCatalogRes = useSelector<RootState, CatalogState>((state) => state.deleteCatalog);
    //endregion

    //region useEffect
    useEffect(() => {
        !isMount && dispatch(getCatalogs());
        return () => setIsMount(true);
    }, []);
    useEffect(() => {
        if (createAndUpdateCatalogRes.payload.content.catalog.id !== 0 && createAndUpdateCatalogRes.payload.errors.length === 0) {
            history.push(`/catalog/${ createAndUpdateCatalogRes.payload.content.catalog.reference }/infos`);
            dispatch(getCatalogs());
            dispatch(SetCatalogName(createAndUpdateCatalogRes.payload.content.catalog.name));
            dispatch(resetCreateCatalog());
            setCreateModal(false);
        }
    }, [createAndUpdateCatalogRes]);
    useEffect(() => {
        if (deleteCatalogRes.fullfiled) {
            dispatch(getCatalogs());
            dispatch(resetDeleteCatalog());
            setDeleteModal(false);
        }
    }, [deleteCatalogRes]);
    //endregion

    return (
        <Layout title='Catalogue' visible>
            { createModal && <CreateCatalog setModal={ setCreateModal } /> }
            <div className='create-catalog'>
                <button onClick={ () => setCreateModal(true) }> + Créer un catalogue</button>
            </div>
            <MainPaper>
                <CatalogTable setModal={ setDeleteModal } modal={ deleteModal } catalogList={ catalogs } />
            </MainPaper>
        </Layout>
    );
};

export default CatalogMain;
