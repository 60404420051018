import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CompoundedPresetServices from '../../../../api/services/composition/compounded-preset-services/compounded-preset.services';
import MdfApiResponse from '../../../../domain/common/generic';
import { CompoundedModule } from '../../../../domain/domain';

export type CompoundedPresetState = {
    isLoading: boolean;
    fullfiled: boolean;
    payload: MdfApiResponse<CompoundedModule>;
};

const initialCompoundedPresetState: CompoundedPresetState = {
    isLoading: false,
    fullfiled: false,
    payload: {
        content: {
            product: {
                id: 0,
                updatedAt: new Date(),
                createdAt: new Date(),
                version: 0,
                name: '',
                productType: 'COMPOSITION_PRESET',
                parentProductReference: '',
                reference: '',
                creationStep: 1,
                deleted: false,
                collection: {
                    id: 0,
                    version: 0,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    name: '',
                    isSystem: false,
                    reference: '',
                    logo: '',
                    supplier: {
                        id: 0,
                        updatedAt: new Date(),
                        createdAt: new Date(),
                        version: 0,
                        name: '',
                        reference: '',
                        logo: '',
                    },
                },
                thumbnailUrl: '',
                completionStatus: 'COMPLETE',
            },
            productReference: '',
            id: 0,
            version: 0,
            name: '',
            createdAt: new Date(),
            updatedAt: new Date(),
            default: false,
            compositionId: 0,
            metadata: {},
        },
        errors: [],
        warnings: [],
    },
};

export type CompoundedPresetListState = {
    isLoading: boolean;
    fullfiled: boolean;
    payload: MdfApiResponse<CompoundedModule[]>;
};

const initialCompoundedPresetListState: CompoundedPresetListState = {
    isLoading: false,
    fullfiled: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};

//#region find compounded preset
export const findCompoundedPresetsByCompositionId = createAsyncThunk('find/compounded-presets', async (compositionRef: string) => {
    const res = await CompoundedPresetServices.findAllCompoundedPresetByCompositionId(compositionRef);
    return res;
});
export const findCompoundedPresetsByCompositionIdSlice = createSlice({
    name: 'findCompoundedPresets',
    initialState: initialCompoundedPresetListState,
    reducers: {
        resetFindCompoundedPresetsByCompositionId: (state) => {
            state = initialCompoundedPresetListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(findCompoundedPresetsByCompositionId.pending, (state) => {
            state.isLoading = true;
            state.fullfiled = false;
        });
        builder.addCase(findCompoundedPresetsByCompositionId.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, fullfiled: true };
            return state;
        });
        builder.addCase(findCompoundedPresetsByCompositionId.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetFindCompoundedPresetsByCompositionId } = findCompoundedPresetsByCompositionIdSlice.actions;

//endregion

//#region create compounded preset
export const createCompoundedPreset = createAsyncThunk('create/compounded-preset', async (data: { comositionRef: string; compoundedPresetName: string }) => {
    const res = await CompoundedPresetServices.createCompoundedPreset(data.comositionRef, data.compoundedPresetName);
    return res;
});
export const createCompoundedPresetSlice = createSlice({
    name: 'createCompoundedPreset',
    initialState: initialCompoundedPresetState,
    reducers: {
        resetCreateCompoundedPreset: (state) => {
            state = initialCompoundedPresetState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createCompoundedPreset.pending, (state) => {
            state.isLoading = true;
            state.fullfiled = false;
        });
        builder.addCase(createCompoundedPreset.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, fullfiled: true };
            return state;
        });
        builder.addCase(createCompoundedPreset.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateCompoundedPreset } = createCompoundedPresetSlice.actions;

//endregion

//#region delete compounded preset
export const deleteCompoundedPreset = createAsyncThunk('delete/compounded-preset', async (compoundedPreset: CompoundedModule) => {
    const res = await CompoundedPresetServices.deleteCompoundedPreset(compoundedPreset);
    return res;
});
export const deleteCompoundedPresetSlice = createSlice({
    name: 'deleteCompoundedPreset',
    initialState: {
        isLoading: false,
        fullfiled: false,
    },
    reducers: {
        resetDeleteCompoundedPreset: (state) => {
            state = {
                isLoading: false,
                fullfiled: false,
            };
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteCompoundedPreset.pending, (state) => {
            state.isLoading = true;
            state.fullfiled = false;
        });
        builder.addCase(deleteCompoundedPreset.fulfilled, (state) => {
            state = { isLoading: false, fullfiled: true };
            return state;
        });
        builder.addCase(deleteCompoundedPreset.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteCompoundedPreset } = deleteCompoundedPresetSlice.actions;

//endregion
