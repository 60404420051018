import Inspector from "../Inspector";
import {MeshPhysicalMaterial} from "three";
import * as THREE from "three";
import {MapType} from "../MapType";

export function IridescenceBind(this: Inspector) {
    let inspectorParameters = this.m_materialEditorParameters;
    const self: any = this;
    
    this.m_iridescenceSlide?.m_iridescence.BindType( function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.iridescence = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });
    this.m_iridescenceSlide?.m_iridescenceIOR.BindType( function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.iridescenceIOR = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });
}