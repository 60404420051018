import './product-simple-studio.scss';
import close from '../../../../../../../../asset/CloseWindow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateProduct3DThumbs } from '../../../../../../../../services/reducers/product/3DThumbnails/3dThumbnails.reducers';
import { Product } from '../../../../../../../../domain/domain';
import { Vector2 } from 'three';
import { RootState } from '../../../../../../../../services/root-reducer';
import React, { useEffect, useState } from 'react';
import { postFile } from '../../../../../../../../utils/upload-file-method';
import { CharacteristicStudioState, setPointOfViewPlacement } from '../../../../../../../../application-3d/application3D-backoffice/Redux/Reducers/characteristic-studio/CharacteristicStudioReducer';
import Asset3D from '../../../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D';
import { PointOfViewPlacement } from '../../../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Features3D/PointOfViews';
import { onLoadingFail } from '../../../../../../../../application-3d/application3D-common/Utils/loading-fail';
import CharacteristicStudio from '../../../../../../../../application-3d/application3D-backoffice/Components/CharacteristicStudio/CharacteristicStudio';

type ProductSimpleStudioProps = {
    product: Product;
    url: string;
    model3dObject: Asset3D;
    setOpenModal: (openModal: boolean) => void;
    mappingObject: Asset3D;
};

const ProductSimpleStudio = ({ model3dObject, setOpenModal, mappingObject, product }: ProductSimpleStudioProps) => {
    const dispatch = useDispatch();
    const characteristicStudioApp = useSelector<RootState, CharacteristicStudioState>((state) => state.characteristicStudioReducer);
    const { pointOfViewPlacement } = useSelector<RootState, CharacteristicStudioState>((state) => state.characteristicStudioReducer);
    const [snap, setSnap] = useState<Blob | undefined>();
    const [awsFile, setAwsFile] = useState<any>();

    useEffect(() => {
        const data = new FormData();
        snap && data.append('file', snap);
        snap !== undefined && postFile('v1/documents/upload', data, () => {}, setAwsFile);
    }, [snap]);
    useEffect(() => {
        console.log(awsFile && awsFile.data.content);
        awsFile &&
            dispatch(
                updateProduct3DThumbs({
                    productRef: product.reference,
                    product: {
                        id: 0,
                        parentProductReference: '',
                        version: 0,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        thumbnailUrl: awsFile.data.content,
                        reference: '',
                        name: 'ASdfasdf',
                        productType: 'SIMPLE',
                        collection: {
                            isSystem: false,
                            supplier: {
                                version: 0,
                                createdAt: new Date(),
                                updatedAt: new Date(),
                                id: 0,
                                reference: '',
                                logo: '',
                                name: '',
                            },
                            name: '',
                            version: 0,
                            id: 0,
                            createdAt: new Date(),
                            updatedAt: new Date(),
                            logo: '',
                            reference: '',
                        },

                        creationStep: 0,
                        deleted: true,
                        completionStatus: 'CREATING',
                    },
                })
            );
    }, [awsFile]);

    return (
        <div className="product-simple-studio">
            <div className="products-simple-studio">
                <div className="product-simple-title">
                    <div>
                        <div>DÉFINIR LE POINT DE VUE DE LA VIGNETTE DU PRODUIT</div>
                        <div className="pov">
                            <div>Point de vue</div>
                            <select
                                defaultValue={pointOfViewPlacement}
                                onChange={(v) => {
                                    dispatch(setPointOfViewPlacement(parseInt(v.target.value)));
                                }}
                            >
                                <option value={PointOfViewPlacement.TOP}>TOP</option>
                                <option value={PointOfViewPlacement.TOP_90}>TOP_90</option>
                                <option value={PointOfViewPlacement.THREE_QUARTER}>THREE_QUARTER</option>
                                <option value={PointOfViewPlacement.THREE_QUARTER_OFF}>THREE_QUARTER_OFF</option>
                                <option value={PointOfViewPlacement.FRONT}>FRONT</option>
                                <option selected={pointOfViewPlacement === 5} value={PointOfViewPlacement.FREE}>
                                    FREE
                                </option>
                            </select>
                        </div>
                    </div>

                    <img onClick={() => setOpenModal(false)} alt="fermer" src={close} />
                </div>
                <div className="studio">
                    <CharacteristicStudio cameraPointOfView={2} previewMapping={mappingObject} previewMesh={model3dObject} onLoadingFail={onLoadingFail} setPovActivated={true} />
                </div>
                <div className="btn-box">
                    <button onClick={() => setOpenModal(false)} className="cancel">
                        Annuler
                    </button>
                    <button
                        className="action"
                        onClick={() => characteristicStudioApp.characteristicStudioApp?.Services.ScreenshotService.Take3DSnapshot(new Vector2(570, 390)).then((res) => setSnap(res))}
                    >
                        Générer
                    </button>
                </div>
            </div>
        </div>
    );
};
export default ProductSimpleStudio;
