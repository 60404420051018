import { useEffect } from 'react';
import { ColorState, SetGLTF } from '../../../services/reducers/material-editor/ColorReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/root-reducer';
import { EditorState } from '../../../services/reducers/material-editor/EditorReducer';
import { EntityState } from '../../../services/reducers/material-editor/EntitiesReducer';
import { Guid } from 'guid-typescript';
import { ExitType } from '../Scripts/Inspector/ExitType';
import { SetImage, ThumbnailState } from '../../../services/reducers/material-editor/ThumbnailReducer';

const CurrentMaterial = () => {
    const dispatch = useDispatch();
    const { material, isGltfDirty } = useSelector<RootState, ColorState>((state) => state.ColorReducer);
    const { game, exitStatus } = useSelector<RootState, EditorState>((state) => state.EditorReducer);
    const { entityIds, currentTargetEntity } = useSelector<RootState, EntityState>((state) => state.EntitiesReducer);
    const {
        type,
        currentType,
        typeCameraPositions,
    } = useSelector<RootState, ThumbnailState>((state) => state.ThumbnailReducer);

    useEffect(() => {
        if (material && game) {
            let allEntites: Guid[] = [];
            entityIds.forEach((obj) => {
                allEntites.push(obj.entityId);
            });
            game.TargetMeshService.SetMaterialOnEntities(allEntites, material);
            game.TargetMeshService.RequestRender();
        }
    }, [material]);

    let GenerateBlobScreenshot = () => {

        let allEntites: Guid[] = [];
        entityIds.forEach((obj) => {
            allEntites.push(obj.entityId);
        });
        let test = entityIds.find((obj) => {
            return obj.thumbnailRef === type;
        });
        let test2 = entityIds.find((obj) => {
            return obj.thumbnailRef === currentType;
        });
        let foundPos = typeCameraPositions.find((obj) => {
            return obj.thumbnailType === type;
        });
        if (test2 && test && foundPos) game?.TargetMeshService.TakeScreenShot(foundPos.cameraPosition, test.entityId, test2.entityId, allEntites, (obj) => {
            const url = URL.createObjectURL(obj);
            dispatch(SetImage(obj));
        });
    };

    useEffect(() => {
        if (!isGltfDirty && material && exitStatus === ExitType.Save) {
            
            GenerateBlobScreenshot();
            let GUID = entityIds.find((obj) => {
                return obj.thumbnailRef === 'Box';
            })?.entityId;
            if (GUID) game?.TargetMeshService?.ExportToJSON(GUID, (p_return) => {
                dispatch(SetGLTF(p_return));
            });
        }
    }, [isGltfDirty]);
    return <></>;
};
export default CurrentMaterial;
