import './layout-sidebar.scss';
import React from 'react';

type LayoutProps = {
    children: React.ReactNode;
};

const LayoutSidebar = ({ children }: LayoutProps) => {
    return <div className="layout-sidebar">{children}</div>;
};

export default LayoutSidebar;
