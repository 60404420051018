import MainPaper from '../../../uicomponents/paper/main-paper';
import Layout from '../../../uicomponents/layout/layout';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getStoreByReference, StoreState } from '../../../services/reducers/store/store.slice';
import { Params } from '../../../domain/Params/params-interface';
import { RootState } from '../../../services/root-reducer';
import StoreTable from '../../../components/table/stores/store/store-table';
import { initialUser } from '../add-users-to-store/add-users-to-store';
import { User } from '../../../domain/domain';

const Store = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { storeName, storeRef } = useParams<Params>();

    //region state
    const [isMount, setIsMount] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    //endregion
    //region useSelector
    const store = useSelector<RootState, StoreState>(state => state.getStoreByRef);

    //endregion
    //region useEffect
    useEffect(() => {
        !isMount && dispatch(getStoreByReference(storeRef));
        return () => setIsMount(true);
    }, []);
    //endregion
    return (
        <Layout title={ `Magasin ${ storeName }` }>
            <div className='create-catalog'>
                <button onClick={ () => history.push(`/store/${ storeRef }/${ storeName }/add-users`) }> + Ajouter un
                    membre
                </button>
            </div>
            <MainPaper>
                <StoreTable modal={ deleteModal }
                            store={ store.payload.content }
                            setModal={ setDeleteModal } />
            </MainPaper>
        </Layout>
    );
};
export default Store;