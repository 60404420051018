const CREATE_CONFIGURED_PRODUCT = (coreProductReference: string, name: string) => `/v1/configured-products/${coreProductReference}/${name}/create`;

const GET_CONFIGURED_PRODUCT_TREE_LIST = (coreProductReference: string) => `/v1/configured-products/core-product/${coreProductReference}/tree`;

const ADD_MULTIPLE_ATOMS_TO_CONFIGURED_PRODUCT = (productReference: string) => `/v1/configured-products/product-reference/${productReference}/add-multiple-atoms`;

const GET_CONFIGURED_PRODUCT_TREE_BY_REFERENCE = (productReference: string) => `/v1/configured-products/product/${productReference}/tree`;

const RENAME_CONFIGURED_PRODUCT = (productReference: string, newName: string) => `/v1/configured-products/product-reference/${productReference}/rename/${newName}`;

const UPLOAD_THUMBNAIL_URL = (productReference: string) => `/v1/configured-products/product-reference/${productReference}/save-thumbnail`;

const DELETE_CONFIGURED_PRODUCT = (productReference: string) => `/v1/configured-products/product/${productReference}`;

const ADD_METADATA = (productReference: string) => `/v1/configured-products/product-reference/${productReference}/add-metadata`;

const SET_DEFAULT_CONFIGURED_PRODUCT = (coreProductReference: string, newDefaultId: number) =>
    `/v1/configured-products/product-reference/${coreProductReference}/default-configured-product/${newDefaultId}/`;

const DUPLICATE_CONFIGURED_PRODUCT = (productReference: string, newName: string) => `/v1/configured-products/configured-product-reference/${productReference}/duplicate/rename/${newName}`;

const GET_DEFAULT_CONFIGURED_PRODUCT_TREE_BY_CORE_REFERENCE = (coreProductReference: string) => `/v1/configured-products/parent-product-reference/${coreProductReference}/default-configured-product`;

const GET_CONFIGURED_PRODUCT_SHEET = (productReference: string) => `/v1/configured-products/product/${productReference}/configured-product-sheet`;

const APPLY_RULES = (compositionReference: string, compoundedModuleId: number) => `/v1/composition-modules/apply-rules/composition/${compositionReference}/compoundedModule/${compoundedModuleId}`;

export {
    CREATE_CONFIGURED_PRODUCT,
    GET_CONFIGURED_PRODUCT_TREE_LIST,
    ADD_MULTIPLE_ATOMS_TO_CONFIGURED_PRODUCT,
    GET_CONFIGURED_PRODUCT_TREE_BY_REFERENCE,
    RENAME_CONFIGURED_PRODUCT,
    UPLOAD_THUMBNAIL_URL,
    ADD_METADATA,
    APPLY_RULES,
    DELETE_CONFIGURED_PRODUCT,
    SET_DEFAULT_CONFIGURED_PRODUCT,
    DUPLICATE_CONFIGURED_PRODUCT,
    GET_DEFAULT_CONFIGURED_PRODUCT_TREE_BY_CORE_REFERENCE,
    GET_CONFIGURED_PRODUCT_SHEET,
};
