import { NewsCategory } from '../../domain/domain';
import NewsCategoriesHeader from './head/news-categories-head';
import NewsCategoriesRow from './row/news-categories-row';

import './news-categories-table.scss';

type NewsCategoriesTableProps = {
    newsCategories: NewsCategory[];
    onDeleteNewsCategory: () => void;
    setCategoryToBeDeleted: (id: number) => void;
};
const NewsCategoriesTable = ({ newsCategories, onDeleteNewsCategory, setCategoryToBeDeleted }: NewsCategoriesTableProps) => {
    return (
        <table className="categories-table-main">
            <NewsCategoriesHeader />
            <tbody>
                {newsCategories.map((el) => {
                    return <NewsCategoriesRow item={el} key={el.id} onDeleteNewsCategory={onDeleteNewsCategory} setCategoryToBeDeleted={setCategoryToBeDeleted} />;
                })}
            </tbody>
        </table>
    );
};

export default NewsCategoriesTable;
