import * as THREE from 'three';
import {
    Color,
    LinearEncoding,
    Material, MeshBasicMaterial,
    MeshLambertMaterial,
    MeshPhysicalMaterial,
    sRGBEncoding,
    Texture,
    Vector2
} from 'three';
import { MapType } from './MapType';
import TransformData from './TransformData';
import InspectorParameters from './MaterialEditorParameters';
import {INFINITY} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";

export default class MaterialEditor {
    private m_noTextureMap: Texture;
    private m_previewMat: MeshBasicMaterial;
    private m_previewSlot: string;
    public m_editMat: MeshPhysicalMaterial;

    private m_usingPreview: boolean = false;

    public constructor() {
        this.m_noTextureMap = new THREE.TextureLoader().load('assets/no.jpg');
        this.m_noTextureMap.wrapS = THREE.RepeatWrapping;
        this.m_noTextureMap.wrapT = THREE.RepeatWrapping;
        this.m_noTextureMap.repeat = new Vector2(10, 20);

        //preview material
        this.m_previewSlot = '';
        this.m_previewMat = new MeshBasicMaterial();
        this.m_previewMat.color = new Color(1, 1, 1);

        this.m_editMat = new MeshPhysicalMaterial();
        this.m_editMat.side = THREE.DoubleSide;
    }

    public SetEditedMaterial(p_editMat: MeshPhysicalMaterial) {
        this.m_editMat = p_editMat;
    }

    public UpdateAllSlotsTransform(p_transformData: TransformData) {
        this.UpdateTextureSlotTransforms(['map'], p_transformData);
        this.UpdateTextureSlotTransforms(['roughnessMap'], p_transformData);
        this.UpdateTextureSlotTransforms(['metalnessMap'], p_transformData);
        this.UpdateTextureSlotTransforms(['AOMap'], p_transformData);
        this.UpdateTextureSlotTransforms(['normalMap'], p_transformData);
        this.UpdateTextureSlotTransforms(['specularColorMap'], p_transformData);
        this.UpdateTextureSlotTransforms(['emissiveMap'], p_transformData);
        this.UpdateTextureSlotTransforms(['clearcoatMap'], p_transformData);
        this.UpdateTextureSlotTransforms(['clearcoatNormalMap'], p_transformData);
        this.UpdateTextureSlotTransforms(['clearcoatRoughnessMap'], p_transformData);
        this.UpdateTextureSlotTransforms(['sheenColorMap'], p_transformData);
        this.UpdateTextureSlotTransforms(['transmissionMap'] , p_transformData);
        this.UpdateTextureSlotTransforms(['thicknessMap'] , p_transformData);
    }

    public UpdateMatEnvIntensity(p_value: number){
        let editedMaterial = this.m_editMat;
        if (editedMaterial) {
            editedMaterial.envMapIntensity = p_value;
            editedMaterial.needsUpdate = true;
        }
    }
    
    public FlipYTexture(slot: string[], p_value: boolean) {
        slot.forEach((mapSlot) => {
            let editedMaterial = this.m_editMat as any;
            if (editedMaterial[mapSlot]) {
                editedMaterial[mapSlot].flipY = p_value;
                editedMaterial[mapSlot].needsUpdate = true;
            }

            if (this.m_previewMat.map && this.m_previewSlot === mapSlot) {
                this.m_previewMat.map.flipY = p_value;
                this.m_previewMat.map.needsUpdate = true;
            }
        });
        let toReturn = this.m_usingPreview ? this.m_previewMat : (this.m_editMat as any);
        return this.CopyMesh(toReturn);
    }

    public UpdateTextureSlotTransforms(slot: string[], p_transformData: TransformData) {
        let editedMaterial = this.m_editMat as any;
        if (this.m_previewMat && this.m_previewMat.map) {
            this.m_previewMat.map.offset = p_transformData.TxOffset;
            this.m_previewMat.map.repeat = p_transformData.TxTiling;
            this.m_previewMat.map.wrapS = p_transformData.TxWrapMode;
            this.m_previewMat.map.wrapT = p_transformData.TxWrapMode;
            this.m_previewMat.map.rotation = (p_transformData.TxRotation * Math.PI) / 180.0;
            this.m_previewMat.map.center = p_transformData.TxRotationCenter;

            this.m_previewMat.map.needsUpdate = true;
        }
        slot.forEach((mapSlot)=>{
        if (editedMaterial[mapSlot]) {
            editedMaterial[mapSlot].offset = p_transformData.TxOffset;
            editedMaterial[mapSlot].repeat = p_transformData.TxTiling;
            editedMaterial[mapSlot].wrapS = p_transformData.TxWrapMode;
            editedMaterial[mapSlot].wrapT = p_transformData.TxWrapMode;
            editedMaterial[mapSlot].rotation = p_transformData.TxRotation;
            editedMaterial[mapSlot].center = p_transformData.TxRotationCenter;
            editedMaterial[mapSlot].needsUpdate = true;
        }
        });
    }

    public SetTextureEncoding(p_editedMaterial: MeshPhysicalMaterial, slot: string, p_usesRGB: boolean) {
        let editedMaterial = p_editedMaterial as any;

        if (editedMaterial && editedMaterial[slot]) {
            if(p_usesRGB)
                editedMaterial[slot].encoding = sRGBEncoding;
            else
            {
                editedMaterial[slot].encoding = LinearEncoding;
                editedMaterial[slot].needsUpdate = true;
            }
            editedMaterial.needsUpdate = true;
        }
    }

    public showOnMesh(p_editedMaterial: MeshPhysicalMaterial, p_inspecParameters: InspectorParameters, mapSlot: string, showOn: boolean) {
        let editedMaterial = p_editedMaterial as any;
        if (showOn) {
            //switch off other checkboxes
            this.m_previewSlot = mapSlot;
            if (mapSlot !== 'map')          p_inspecParameters.mapShowOnMesh = false;
            if (mapSlot !== 'roughnessMap') p_inspecParameters.roughnessMapShowOnMesh = false;
            if (mapSlot !== 'metalnessMap') p_inspecParameters.metalnessMapShowOnMesh = false;
            if (mapSlot !== 'specularMap') p_inspecParameters.specularMapShowOnMesh = false;
            if (mapSlot !== 'normalMap')    p_inspecParameters.normalMapShowOnMesh = false;
            if (mapSlot !== 'emissiveMap')  p_inspecParameters.emissiveMapShowOnMesh = false;
            if (mapSlot !== 'clearcoatMap')  p_inspecParameters.clearcoatMapShowOnMesh = false;
            if (mapSlot !== 'clearcoatNormalMap')  p_inspecParameters.clearcoatNormalMapShowOnMesh = false;
            if (mapSlot !== 'clearcoatRoughnessMap')  p_inspecParameters.clearcoatRoughnessMapShowOnMesh = false;
            if (mapSlot !== 'sheenMap')  p_inspecParameters.sheenmapShowOnMesh = false;
            if (mapSlot !== 'transmissionMap')  p_inspecParameters.transmissionMapShowOnMesh = false;
            if (mapSlot !== 'thicknessMap')  p_inspecParameters.thicknessMapShowOnMesh = false;
            // this.pane.refresh();

            if (editedMaterial[mapSlot] === null) {
                this.m_previewMat.map = this.m_noTextureMap;
            } else this.m_previewMat.map = (this.m_editMat as any)[mapSlot];
            if (!this.m_previewMat || !this.m_previewMat.map) return;
            this.m_previewMat.map.offset = p_inspecParameters.txOffset;
            if (editedMaterial[mapSlot] !== this.m_noTextureMap) this.m_previewMat.map.repeat = p_inspecParameters.txTiling;
            else this.m_previewMat.map.repeat = new Vector2(10, 10);
            this.m_previewMat.map.rotation = p_inspecParameters.txRotation;
            this.m_previewMat.map.center = p_inspecParameters.txRotationCenter;
            this.m_previewMat.map.wrapS = p_inspecParameters.txWrapMode;
            this.m_previewMat.map.wrapT = p_inspecParameters.txWrapMode;

            if (!this.m_usingPreview) this.m_editMat = p_editedMaterial;
            this.m_usingPreview = true;
            return this.CopyMesh(this.m_previewMat);
        } else {
            this.m_usingPreview = false;
            this.m_previewSlot = '';
            return this.m_editMat;
        }
    }

    public SetTransmission(p_editedMaterial: MeshPhysicalMaterial, value: number) {
        p_editedMaterial.transmission = value;
        if (p_editedMaterial.transmission > 0) {
            p_editedMaterial.transparent = true;
        } else {
            p_editedMaterial.transparent = false;
        }
        p_editedMaterial.needsUpdate = true;
        return this.CopyMesh(p_editedMaterial);
    }

    public ClearMap(p_inspecParameters: InspectorParameters, mapSlot: string[]) {
        mapSlot.forEach((slot) => {
            let editedMaterial = this.m_editMat as any;
            let params = p_inspecParameters as any;

            // clear material part
            if (editedMaterial[slot]) {
                editedMaterial[slot].dispose();
                editedMaterial[slot] = null;
                editedMaterial.needsUpdate = true;
            }

            if (this.m_previewMat && this.m_previewSlot === slot) {
                this.m_previewMat.map?.dispose();
                this.m_previewMat.map = null;
                this.m_previewMat.needsUpdate = true;
            }

            if (params[slot]) params[slot] = null;
        });
        let toReturn = this.m_usingPreview ? this.m_previewMat : (this.m_editMat as any);
        return toReturn;
    }

    public CopyMesh(p_editedMaterial: Material): Material {
        let test;
        if (p_editedMaterial.type == 'MeshPhysicalMaterial') test = new MeshPhysicalMaterial();
        else test = new MeshBasicMaterial();
        test.copy(p_editedMaterial);
        return test;
    }

    public UpdateTextureOnMaterial(mapSlot: string[], p_transformData: TransformData, p_flipY: boolean = false, p_usesRGB: boolean, p_image: HTMLImageElement | null = null, p_anisotropy: number) {
        let newtexture;
        if (p_image)
            newtexture = new Texture(p_image);
        if(newtexture)
            mapSlot.forEach((slot)=>{
                let editedMaterial = this.m_editMat as any;
                if (p_image) {
                    if ((p_image.width === 128 && p_image.height === 64) || !p_image.src) return; // really dirty because based on image size
                }
                if (editedMaterial) {
                    if (p_image) {
                        editedMaterial[slot] = new Texture(p_image);
                        editedMaterial[slot].flipY = p_flipY;
                        editedMaterial[slot].offset = p_transformData.TxOffset;
                        editedMaterial[slot].repeat = p_transformData.TxTiling;
                        editedMaterial[slot].rotation = p_transformData.TxRotation;
                        editedMaterial[slot].center = p_transformData.TxRotationCenter;
                        editedMaterial[slot].wrapS = p_transformData.TxWrapMode;
                        editedMaterial[slot].wrapT = p_transformData.TxWrapMode;
                        editedMaterial[slot].needsUpdate = true;
                        editedMaterial[slot].anisotropy = p_anisotropy;
                        editedMaterial.needsUpdate = true;
                        this.SetTextureEncoding(this.m_editMat, slot, p_usesRGB);
                        this.UpdateTextureSlotTransforms([slot], p_transformData);
                    } else {
                        editedMaterial[slot] = null;
                    }
                }
            });
        let toReturn = (this.m_editMat as any);
        return this.CopyMesh(toReturn) as MeshPhysicalMaterial;
    }
}
