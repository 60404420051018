import { Fragment, useEffect, useState } from 'react';

import folderGrey from '../../../../../asset/dossier-grey.svg';
import plus from '../../../../../asset/plus-circle.svg';
import update from '../../../../../asset/Editer.svg';
import trash from '../../../../../asset/poubelle.svg';
import whiteTrash from '../../../../../asset/poubelle-disabled.svg';

import CreateGroupMapping from '../../../../../components/modals/mapping-zoning/group-mapping/create-group-mapping';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteGroup,
    getAllGroups,
    getGroup,
    GroupListState,
    GroupState,
    resetCreateGroup,
    resetDeleteGroup,
    resetUpdateGroup,
} from '../../../../../services/reducers/mapping-zoning/group-mapping.reducer';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../domain/Params/params-interface';
import { RootState } from '../../../../../services/root-reducer';
import SimpleModal from '../../../../../uicomponents/modal/simple-modal/simple-modal';
import UpdateGroupMapping from '../../../../../components/modals/mapping-zoning/group-mapping/update-group-mapping';
import { GroupMapping, Mapping } from '../../../../../domain/domain';
import CreateMapping from '../../../../../components/modals/mapping-zoning/mapping/create-mapping';
import {
    deleteMapping,
    getMapping,
    MappingState,
    resetCreateMapping,
    resetDeleteMapping,
    resetUpdateMapping,
} from '../../../../../services/reducers/mapping-zoning/mapping.reducer';
import UpdateMapping from '../../../../../components/modals/mapping-zoning/mapping/update-mapping';
import './mapping.scss';
import ConstraintModal from '../../../../../components/modals/mapping-zoning/mapping/constraint-modal/constraint-modal';
import { initialGroup, initialMapping } from '../mappings';


type MappingBlocProps = {
    currentGroup: GroupMapping;
    setCurrentGroup: (currentGroup: GroupMapping) => void

    currenMapping: Mapping;
    setCurrentMapping: (currenMapping: Mapping) => void
};

const MappingBloc = (props: MappingBlocProps) => {
    const { collectionReference } = useParams<Params>();
    const dispatch = useDispatch();

    //#region state
    const [isMount, setIsmount] = useState(false);
    const [openConstraint, setOpenConstraint] = useState(false);
    const [, setGroupeName] = useState<string | undefined>(undefined);

    const [openAddGroupe, setOpenAddGroup] = useState(false);
    const [openUpdateGroupe, setOpenUpdateGroup] = useState(false);
    const [openDeleteGroupe, setOpenDeleteGroup] = useState(false);

    const [openAddMapping, setOpenAddMapping] = useState(false);
    const [openUpdateMapping, setOpenUpdateMapping] = useState(false);
    const [openDeleteMapping, setOpenDeleteMapping] = useState(false);
    //#endregion
    //#region useSelector
    const groups = useSelector<RootState, GroupListState>((state) => state.getAllGroups);
    const createGroupRes = useSelector<RootState, GroupState>((state) => state.createGroup);
    const updateGroupRes = useSelector<RootState, GroupState>((state) => state.updateGroup);
    const deleteGroupRes = useSelector<RootState, GroupState>((state) => state.deleteGroup);
    const createMappingRes = useSelector<RootState, MappingState>((state) => state.createMapping);
    const updateMappingRes = useSelector<RootState, MappingState>((state) => state.updateMapping);
    const deleteMappingRes = useSelector<RootState, MappingState>((state) => state.deleteMapping);
    //#endregion
    //#region useEffect
    //#region group
    useEffect(() => {
        const groupPayload = groups.payload;
        if (groupPayload.content && groupPayload.errors.length === 0) {
            if (groupPayload.content.length > 0) {
                !isMount && props.currentGroup.id === 0 && props.setCurrentGroup(groupPayload.content[0]);
                if (groupPayload.content[0].mappings.length > 0) {
                    props.setCurrentGroup(groupPayload.content[0]);
                    props.currenMapping.id === 0 && props.setCurrentMapping(groupPayload.content[0].mappings[0]);
                }
            }
        }
        return () => setIsmount(true);
    }, [groups]);
    useEffect(() => {
        if (createGroupRes.payload.content !== undefined && createGroupRes.payload.errors.length === 0) {
            props.setCurrentMapping(initialMapping);
            collectionReference && dispatch(getAllGroups(collectionReference));
            setOpenAddGroup(false);
            dispatch(resetCreateGroup());
        }
    }, [createGroupRes]);
    useEffect(() => {
        if (updateGroupRes.payload.content && updateGroupRes.payload.errors.length === 0) {
            setOpenUpdateGroup(false);
            collectionReference && dispatch(getAllGroups(collectionReference));
            dispatch(resetUpdateGroup());
        }
    }, [updateGroupRes]);
    useEffect(() => {
        if (deleteGroupRes.payload.content.id !== 0 && deleteGroupRes.payload.errors.length === 0) {
            collectionReference && dispatch(getAllGroups(collectionReference));
            props.setCurrentMapping(initialMapping);
            setOpenDeleteGroup(false);
            dispatch(resetDeleteGroup());
        }
    }, [deleteGroupRes]);
    //endregion
    //#region mapping
    useEffect(() => {
        if (createMappingRes.payload.content !== undefined && createMappingRes.payload.errors.length === 0) {
            collectionReference && dispatch(getAllGroups(collectionReference));
            props.setCurrentMapping(createMappingRes.payload.content);
            props.setCurrentGroup(props.currentGroup);
            dispatch(getGroup(props.currentGroup.id));
            dispatch(resetCreateMapping());
            setOpenAddMapping(false);
        }
    }, [createMappingRes]);
    useEffect(() => {
        if (updateMappingRes.payload.content !== undefined && updateMappingRes.payload.errors.length === 0) {
            collectionReference && dispatch(getAllGroups(collectionReference));
            setOpenUpdateMapping(false);
            dispatch(resetUpdateMapping());
        }
    }, [updateMappingRes]);
    useEffect(() => {
        if (deleteMappingRes.payload.content !== undefined && deleteMappingRes.payload.errors.length === 0) {
            collectionReference && dispatch(getAllGroups(collectionReference));
            props.setCurrentMapping(initialMapping);
            setOpenDeleteMapping(false);
            dispatch(resetDeleteMapping());
        }
    }, [deleteMappingRes]);
    //endregion
    //#endregion

    return (
        <div className='mapping-main'>
            <div className='mapping-head'>
                MAPPINGS
                <div
                    onClick={ () => {
                        setOpenAddGroup(true);
                    } }
                    className='mappping-create-group'>
                    <img alt='plus' src={ plus } />
                    <span>Groupe</span>
                </div>
            </div>
            <div className='mapping-creation-bloc'>
                <>
                    <>
                        { openAddGroupe && collectionReference &&
                            <CreateGroupMapping
                                collectionReference={ collectionReference }
                                setOpenModal={ setOpenAddGroup } /> }
                        { openUpdateGroupe && collectionReference && (
                            <UpdateGroupMapping
                                groupId={ props.currentGroup.id }
                                name={ props.currentGroup.name }
                                collectionReference={ collectionReference }
                                setOpenModal={ setOpenUpdateGroup }
                                setName={ setGroupeName } />
                        ) }
                        { openDeleteGroupe && (
                            <SimpleModal
                                icon={ whiteTrash }
                                title='Supprimer un groupe ?'
                                info='Le groupe ainsi que toutes ces dépendances seront supprimés.'
                                closeOnclick={ () => setOpenDeleteGroup(false) }
                                actionOnclick={ () => dispatch(deleteGroup(props.currentGroup.id)) }
                                deleteOrValide={ true }
                                closeLabel='Annuler'
                                actionLabel='Supprimer'
                            />
                        ) }
                    </>
                    <>
                        { openAddMapping &&
                            <CreateMapping
                                groupId={ props.currentGroup.id }
                                setOpenModal={ setOpenAddMapping } /> }

                        { openUpdateMapping &&
                            <UpdateMapping
                                groupId={ props.currentGroup.id }
                                currentMapping={ props.currenMapping }
                                name={ props.currenMapping.name }
                                setOpenModal={ setOpenUpdateMapping } /> }

                        { openDeleteMapping && (
                            <SimpleModal
                                icon={ whiteTrash }
                                title='Supprimer un mapping ?'
                                info='Le mapping ainsi que toutes ces dépendances seront supprimés.'
                                closeOnclick={ () => setOpenDeleteMapping(false) }
                                actionOnclick={ () => dispatch(deleteMapping({
                                    groupId: props.currentGroup.id, mappingId: props.currenMapping.id,
                                })) }
                                deleteOrValide={ true }
                                closeLabel='Annuler'
                                actionLabel='Supprimer'
                            />
                        ) }
                    </>
                    { openConstraint &&
                        <ConstraintModal
                            selectedMap={ props.currenMapping }
                            setOpenModal={ setOpenConstraint } /> }
                </>

                { groups.payload.content && groups.payload.content.length > 0 && (
                    <>
                        { groups.payload.content.map((group: GroupMapping) => {
                            return (
                                <Fragment key={ group.id }>
                                    <div className='mapping-group'>
                                        <div className='group-left'>
                                            <img alt='dossier' src={ folderGrey } />
                                            <span>{ group.name }</span>
                                        </div>
                                        <div className='group-right'>
                                            <div className='add-map' onClick={ () => {
                                                props.setCurrentGroup(group);
                                                setOpenAddMapping(true);
                                            } }>
                                                <img
                                                    className='add'
                                                    alt='plus'
                                                    src={ plus } />
                                                <span> Mapping </span>
                                            </div>
                                            <div className='add-map-right'>
                                                <img
                                                    onClick={ () => {
                                                        props.setCurrentGroup(group);
                                                        setOpenUpdateGroup(true);
                                                    } }
                                                    alt='fleche ouvrir'
                                                    src={ update }
                                                />
                                                <img
                                                    onClick={ () => {
                                                        props.setCurrentGroup(group);
                                                        setOpenDeleteGroup(true);
                                                    } }
                                                    alt='poubelle'
                                                    src={ trash }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    { group.mappings.map((map: Mapping) => {
                                        return (
                                            <div
                                                key={ map.id }
                                                className={ props.currenMapping.id === map.id ? 'group focus' : 'group' }
                                                onClick={ () => {
                                                    props.setCurrentMapping(map);
                                                    props.setCurrentGroup(group);
                                                } }>
                                                <span>{ map.name }</span>
                                                <div className='right'>
                                                    <span
                                                        className='constraint'
                                                        onClick={ () => {
                                                            props.setCurrentMapping(map);
                                                            dispatch(getMapping(map.id));
                                                            setOpenConstraint(true);
                                                        } }>
                                                        contrainte
                                                    </span>
                                                    <div>
                                                        <img
                                                            onClick={ () => {
                                                                props.setCurrentMapping(map);
                                                                setOpenUpdateMapping(true);
                                                            } }
                                                            alt='fleche ouvrir'
                                                            src={ update } />
                                                        <img
                                                            onClick={ () => {
                                                                props.setCurrentMapping(map);
                                                                props.setCurrentGroup(group);
                                                                setOpenDeleteMapping(true);
                                                            } }
                                                            alt='dossier'
                                                            src={ trash }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }) }
                                </Fragment>
                            );
                        }) }
                    </>
                ) }
            </div>
        </div>
    );
};
export default MappingBloc;
