import React, { useEffect, useState } from 'react';
import { CreateTagState, getProductTag, GetProductTagState } from '../../../../../../services/reducers/tag/tag.slice';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../../domain/Params/params-interface';
import {
    AssociatedTagListState,
    createProductInfo,
    getProductAssociatedTagsByReference,
    getProductById,
    ProductInfoState,
    resetCreateProductInfo,
} from '../../../../../../services/reducers/product/product.reducers';
import { RootState } from '../../../../../../services/root-reducer';
import { Tag } from '../../../../../../domain/domain';
import TagInput from '../../../../../../uicomponents/tag/tag-input';
import './product-detail-info.scss';
import { returnadvice } from '../../../../../../components/modals/mapping-zoning/mapping/create-mapping';


type ProductDetailInfoInput = {
    name: string;
    ref: string;
    tagIdList: number[];
    wallConstraint: boolean;
    basicPrice: number;
    height: string;
    productLenght: number;
    productHeight: number;
    productDeft: number;
    technicalDescription: string;
};

const ProductDetailInfo = (props: { setFocus: (focus: number) => void; focus: number }) => {
    const dispatch = useDispatch();
    const { productReference } = useParams<Params>();

    const product = useSelector<RootState, ProductInfoState>((state) => state.getProductionById);
    const getProductTagRes = useSelector<RootState, GetProductTagState>((state) => state.getProductTags);
    const createTagsRes = useSelector<RootState, CreateTagState>((state) => state.createTag);
    const createInfoRes = useSelector<RootState, ProductInfoState>((state) => state.createProductInfo);
    const getProductAssociatedTagsRes = useSelector<RootState, AssociatedTagListState>((state) => state.getProductAssociatedTagsByReference);

    const [nonConf, setNonConf] = useState<boolean>(false);
    const [tagIdList, setTagIdList] = useState<number[]>([]);
    const [tagList, setTagList] = useState<Tag[]>([]);
    const [allTagList, setAllTagList] = useState<Tag[]>([]);
    const [focus, setFocus] = useState(false);

    const { register, handleSubmit, setValue, watch } = useForm<ProductDetailInfoInput>({});

    const ref = watch('ref');
    const onSubmit: SubmitHandler<ProductDetailInfoInput> = (data) => {
        productReference &&
        dispatch(
            createProductInfo({
                productReference,
                tagIdList: tagIdList,
                name: data.name,
                data: {
                    constraint: nonConf,
                    height: parseInt(data.height),
                    basicPrice: data.basicPrice,
                    technicalDescription: data.technicalDescription,
                    product_lenght: data.productLenght,
                    product_height: data.productHeight,
                    product_deft: data.productDeft,
                },
            }),
        );
    };

    useEffect(() => {
        setTagIdList([]);
        setTagList([]);
        if (product.payload.content) {
            dispatch(getProductAssociatedTagsByReference(product.payload.content.first.reference));
            setAllTagList(getProductTagRes.payload.content);
            product.payload.content.second.data.constraint ? setNonConf(true) : setNonConf(false);
            setValue('name', product.payload.content.first.name);
            setValue('ref', product.payload.content.first.reference);
            setValue('wallConstraint', product.payload.content.second.data.constraint);
            setValue('height', product.payload.content.second.data.height);
            setValue('basicPrice', product.payload.content.second.data.basicPrice);
            setValue('productLenght', product.payload.content.second.data.product_lenght);
            setValue('productHeight', product.payload.content.second.data.product_height);
            setValue('productDeft', product.payload.content.second.data.product_deft);
            setValue('technicalDescription', product.payload.content.second.data.technicalDescription);
        }
    }, [product, props.focus]);
    useEffect(() => {
        if (createTagsRes.payload.content && createTagsRes.payload.errors.length < 1) {
            dispatch(getProductTag());
        }
    }, [createTagsRes]);
    useEffect(() => {
        if (createInfoRes.payload.content && createInfoRes.payload.errors.length < 1) {
            productReference && dispatch(getProductById(productReference));
            props.setFocus(1);
            dispatch(resetCreateProductInfo());
        }
    }, [createInfoRes]);
    useEffect(() => {
        if (getProductAssociatedTagsRes.payload.content && getProductAssociatedTagsRes.payload.errors.length === 0) {
            setTagIdList(getProductAssociatedTagsRes.payload.content.map((el) => el.id));
            setTagList(getProductAssociatedTagsRes.payload.content);
        }
    }, [getProductAssociatedTagsRes]);

    return (
        <div className='products-detail-info-main'>
            <div className='products-detail-info-left'>
                <h2>INFORMATIONS GÉNÉRALES</h2>
                <form>
                    <div
                        onClick={ () => setFocus(false) }
                        className='bloc-input'>
                        <label> Nom du produit</label>
                        <input { ...register('name') } />
                        <div style={{marginTop:10}}> {returnadvice(watch('name'), 24)}</div>
                    </div>
                    <div
                        onClick={ () => setFocus(false) }
                        className='bloc-input'>
                        <label> Référence</label>
                        <input value={ ref } />
                    </div>
                    <div className='bloc-input'>
                        <label> Tags produit </label>
                        <TagInput
                            focus={ focus }
                            setFocus={ setFocus }
                            tagIdList={ tagIdList }
                            setTagIdList={ setTagIdList }
                            allProductTag={ allTagList }
                            setTagList={ setTagList }
                            taglist={ tagList } />
                    </div>
                </form>
            </div>
            <div  onClick={ () => setFocus(false) }  className='products-detail-info-right'>
                <div>
                    <h2>COMPORTEMENT 3D</h2>
                    <form>
                        <div className='bloc-horizontal'>
                            <label> Contrainte murale </label>
                            <div className='inspector-toogle'>
                                <label className='toggle-switch'>
                                    <input { ...register('wallConstraint') } type='checkbox' checked={ nonConf }
                                           onChange={ () => setNonConf(!nonConf) } />
                                    <span className='switch' />
                                </label>
                            </div>
                        </div>
                        <div className='bloc-horizontal'>
                            <label> Hauteur par rapport au sol( cm )</label>
                            <input defaultValue={ 0 } { ...register('height') } type='number' />
                        </div>
                        <div className='bloc-horizontal'>
                            <label> Prix ( € ) </label>
                            <input defaultValue={ 0 } { ...register('basicPrice') } type='number' />
                        </div>
                        <div className='bloc-horizontal'>
                            <label> Longeur ( cm )</label>
                            <input defaultValue={ 0 } { ...register('productLenght') } type='number' />
                        </div>
                        <div className='bloc-horizontal'>
                            <label> Hauteur ( cm )</label>
                            <input defaultValue={ 0 } { ...register('productHeight') } type='number' />
                        </div>
                        <div className='bloc-horizontal'>
                            <label> Profondeur ( cm )</label>
                            <input defaultValue={ 0 } { ...register('productDeft') } type='number' />
                        </div>
                        <div className='bloc-input'>
                            {/*<label> Fiche technique </label>
                            <textarea {...register('technicalDescription')} />*/ }
                        </div>
                    </form>
                </div>
                <div className='products-detail-info-btn-box'>
                    <button onClick={ handleSubmit(onSubmit) }>Sauvegarder</button>
                </div>
            </div>
        </div>
    );
};
export default ProductDetailInfo;
