import './store-table.scss';
import React, { useEffect, useState } from 'react';
import { Store, User } from '../../../../domain/domain';
import trashRed from '../../../../asset/poubelle-red.svg';
import edit from '../../../../asset/Editer.svg';
import SimpleModal from '../../../../uicomponents/modal/simple-modal/simple-modal';
import { useDispatch, useSelector } from 'react-redux';
import { initialUser } from '../../../../pages/stores/add-users-to-store/add-users-to-store';
import { useHistory, useParams } from 'react-router-dom';
import HoverIcon from '../../../../uicomponents/hover-icon/hover-icon';
import keygrey from '../../../../asset/key-grey.svg';
import keyBleu from '../../../../asset/Key-bleu.svg';
import { addModal, removeModal } from '../../../../services/reducers/modal/modal.slice';
import Modal, { ModalSize } from '../../../../uicomponents/modal/modal';
import resetPwd from '../../../../asset/resetPwd.png';
import { resetPasswordUserByAdmin } from '../../../../services/reducers/delete-user/delete-user.slice';
import {
    getStoreByReference,
    removeUserToStore, resetRemoveUserToStore,
    StoreState,
} from '../../../../services/reducers/store/store.slice';
import { Params } from '../../../../domain/Params/params-interface';
import { RootState } from '../../../../services/root-reducer';


type StoreProps = {
    store: Store
    setModal: (modal: boolean) => void
    modal: boolean
};

const StoreTable = ({ store, setModal, modal }: StoreProps) => {
        const history = useHistory();
        const dispatch = useDispatch();
        const { storeRef } = useParams<Params>();
        const confirmationModal = 'confirmation-modal-open';
        const storeHeaders = ['NOM', 'MAIL', 'POSTE', 'ACTIONS'];
        const [currentEmployee, setCurrentEmployee] = useState<User>(initialUser);
        const [userToResetPasswordSelected, setUserToResetPasswordSelected] = useState(-1);

        const removeEmployeeRes = useSelector<RootState, StoreState>(state => state.removeUserToStore);

        //region useeffect
        useEffect(() => {
            if (removeEmployeeRes.payload.content.id !== 0 && removeEmployeeRes.payload.errors.length === 0) {
                dispatch(getStoreByReference(storeRef));
                dispatch(resetRemoveUserToStore());
                setModal(false);
            }
        }, [removeEmployeeRes]);
        //endregion

        //region methods
        const onRemove = () => {
            dispatch(removeUserToStore({ storeRef: storeRef, userId: currentEmployee.id }));
        };
        const handleResetPasswordUser = (userId: number) => {
            dispatch(resetPasswordUserByAdmin({ userId: userId }));
            dispatch(removeModal(confirmationModal));
        };
        const onResetPasswordUser = () => {
            dispatch(addModal(confirmationModal));
        };

        const capitalized = (firstname: string) =>
            firstname.charAt(0).toUpperCase()
            + firstname.slice(1);
// endregion

        return (
            <div className='st-table-main'>
                <Modal
                    validateLabel='Confirmer'
                    closeLabel='Annuler'
                    onClick={ () => {
                        handleResetPasswordUser(userToResetPasswordSelected);
                    } }
                    id={ confirmationModal }
                    title='Réinitialisation mot de passe'
                    icon={ resetPwd }
                    size={ ModalSize.SMALL }
                    colorValidateButton='#E95D5D'
                >
                    <p>Veuillez confirmer la réinitialisation du mot de passe!</p>
                </Modal>
                { modal && <SimpleModal
                    icon={ trashRed }
                    title='Retirer cet employé du magasin'
                    info='Cette action retirera cette employé du magasin'
                    closeOnclick={ () => setModal(false) }
                    actionOnclick={ onRemove }
                    deleteOrValide={ true }
                    closeLabel='Annuler'
                    actionLabel='Supprimer' /> }
                <table>
                    <thead>
                    <tr>
                        { storeHeaders && storeHeaders.map((sh: string, i: number) => {
                            return <th key={ i }>{ sh }</th>;
                        }) }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        store.responsibleSet && store.responsibleSet.map((s: User) => {
                            return (
                                <tr key={ s.id }>
                                    <td className='store-name'>{ s.lastname.toUpperCase() }{ ' ' }{ capitalized(s.firstname) }</td>
                                    <td className='store-email'>{ s.email }</td>
                                    <td>Responsable</td>
                                    <td className='store-table-action'>
                                        <img onClick={ () => history.push(`/update-user-by-admin/${ s.id }`) }
                                             alt='logo poubelle'
                                             src={ edit } />
                                        <HoverIcon
                                            onClick={ () => {
                                                setUserToResetPasswordSelected(s.id);
                                                onResetPasswordUser();
                                            } }
                                            icon={ keygrey }
                                            iconHover={ keyBleu }
                                            title='Réinitialiser le mot de passe ' />
                                        <img onClick={ () => {
                                            setCurrentEmployee(s);
                                            setModal(true);
                                        } }
                                             alt='logo poubelle'
                                             src={ trashRed } />
                                    </td>
                                </tr>
                            );
                        }) }
                    {
                        store.sellerSet && store.sellerSet.map((s: User) => {
                            return (
                                <tr key={ s.id }>
                                    <td className='store-name'>{ s.lastname.toUpperCase() }{ ' ' }{ capitalized(s.firstname) }</td>
                                    <td className='store-email'>{ s.email }</td>
                                    <td>Vendeur</td>
                                    <td className='store-table-action'>
                                        <img onClick={ () => history.push(`/update-user-by-admin/${ s.id }`) }
                                             alt='logo poubelle'
                                             src={ edit } />
                                        <HoverIcon
                                            onClick={ () => {
                                                setUserToResetPasswordSelected(s.id);
                                                onResetPasswordUser();
                                            } }
                                            icon={ keygrey }
                                            iconHover={ keyBleu }
                                            title='Réinitialiser le mot de passe ' />
                                        <img onClick={ () => {
                                            setCurrentEmployee(s);
                                            setModal(true);
                                        } }
                                             alt='logo poubelle'
                                             src={ trashRed } />
                                    </td>
                                </tr>
                            );
                        }) }
                    </tbody>
                </table>
            </div>
        );
    }
;
export default StoreTable;
