import './product-rules-table.scss';
import { ActionType, Rule, RuleType } from '../../../domain/domain';
import duplicate from '../../../asset/dupliquer.svg';
import trash from '../../../asset/poubelle-red.svg';
import edit from '../../../asset/Editer.svg';
import { ProductRulesState } from '../../../services/reducers/product/rules/rules.reducers';
import React, { useState } from 'react';
import { displayAtomsInCurrentRule } from '../../../utils/buildPresetConf';
import RuleDescriptionColumn from './rule-description-column';
import { useParams } from 'react-router-dom';
import { Params } from '../../../domain/Params/params-interface';
import { useDispatch } from 'react-redux';
import { OnDuplicateRule } from './onDuplicateRule';


type PriceRulesProps = {
    priceRulesList: any
    rulesRes: ProductRulesState
    setRuleConfType: (ruleConfType: RuleType) => void
    returnAtomValue: (valueAtomNameByAtomValueRef: { [index: string]: string; }, rule: Rule) => string[]
    returnActionType: (actionType: ActionType) => string | undefined;
    returnValue: (actionType: ActionType, value: number, percent: boolean) => string
    setRuleRef: (ruleRef: string) => void
    setOpenDeleteModal: (openDeleteModal: boolean) => void
    setOpenUpdateModal: (openUpdateModal: boolean) => void
    setCurrentRule: (currentRule: Rule) => void
};

const PriceRules = (props: PriceRulesProps) => {
    const priceHeaders = ['TYPE DE RÈGLE', 'COMBINAISON', 'TYPE DE PRIX', 'VALEUR', 'ACTIONS'];
    const [ruleHoverId, setRuleHoverId] = useState('');
    const { productReference } = useParams<Params>();
    const dispatch = useDispatch();

    return (
        <table className='products-rules-table'>
            {props.priceRulesList.length === 0 ?
                <div className='empty-rules-list'>
                    Aucune règles de prix liées a ce produit
                </div> :
                <>
                    <thead className='price-head'>
                    <tr>
                        {priceHeaders.map((el: string, index: number) => {
                            return (
                                <th className='table-head' key={index}>{el}</th>
                            );
                        })}
                    </tr>
                    </thead>
                    <div className='products-rules-scroll'>
                        {
                            props.rulesRes.payload.content.ruleSet.rules.rules.map((rule: Rule, index) => {
                                const conf = displayAtomsInCurrentRule(props.rulesRes, rule);
                                if (rule.type === 'PRICE') {
                                    props.setRuleConfType(rule.type);
                                    return (
                                        <tbody className='price-body' key={index}>
                                        <tr>
                                            <td className='typePrice'>Prix</td>
                                        </tr>
                                        <tr>
                                            <td className='rules-combi preset-conf'
                                                onMouseEnter={() => rule && setRuleHoverId(rule.ruleRef)}
                                                onMouseLeave={() => rule && setRuleHoverId('')}>
                                                {ruleHoverId === rule.ruleRef
                                                    ? <RuleDescriptionColumn conf={conf} />
                                                    : <div
                                                        className='c-partName'
                                                        key={rule.ruleRef}>
                                                        <span className='t'>Part:</span>
                                                        <span> {conf.length > 0 && conf[0].partName}</span>
                                                    </div>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {props.returnActionType(rule.action.type)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {props.returnValue(rule.action.type, rule.action.params.value, rule.action.params.percent)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img onClick={() => {
                                                    props.setCurrentRule(rule);
                                                    props.setOpenUpdateModal(true);
                                                }} alt='editer regle' src={edit} />
                                                <img onClick={() => {
                                                    productReference && OnDuplicateRule(productReference, dispatch, rule);
                                                }} alt='dupliquer' src={duplicate} />
                                                <img onClick={() => {
                                                    props.setRuleRef(rule.ruleRef);
                                                    props.setOpenDeleteModal(true);
                                                }} alt='poubelle'
                                                     src={trash} />
                                            </td>
                                        </tr>
                                        </tbody>
                                    );
                                }
                            })
                        }
                    </div>
                </>
            }
        </table>
    );
};

export default PriceRules;
