import './product-detail-rules.scss';
import plus from '../../../../../../asset/plus-circle.svg';
import { Fragment, useEffect, useState } from 'react';
import CreateRules from '../../../../../../components/modals/rules/create-rules';
import ProductRulesTable from '../../../../../../components/table/product-rules-table/product-rules-table';
import { ActionType, Product, Rule, RuleType } from '../../../../../../domain/domain';
import { useDispatch, useSelector } from 'react-redux';
import {
    getProductRules,
    switchConfRulesType, SwitchRulesState,
} from '../../../../../../services/reducers/product/rules/rules.reducers';
import { RootState } from '../../../../../../services/root-reducer';
import apiClient from '../../../../../../api/api-client';
import ToogleSwitch from '../../../../../../application-3d/application3D-common/Components/UI/Basics/ToogleSwitch/ToogleSwitch';


type ProductDetailRulesProps = {
    setStep: (step: number) => void
    setFocus: (focus: number) => void
    product: Product;
};

const ProductDetailRules = (props: ProductDetailRulesProps) => {
    const ruleType = [
        { label: 'Prix', type: 0 },
        { label: 'Configuration', type: 1 },
        { label: 'Descriptif', type: 2 },
    ];
    const composableRuleType = [
        { label: 'Prix', type: 0 },
        { label: 'Configuration', type: 1 },
        { label: 'Descriptif', type: 2 },
        { label: 'Construction', type: 3 },
    ];
    const dispatch = useDispatch();

    const [isMount, setIsMount] = useState(false);
    const [emptyStart, setEmptyStart] = useState(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openCompoModal, setOpenCompoModal] = useState<boolean>(false);
    const [currentConfRuleType, setCurrentConfRuleType] = useState<RuleType>('POSSIBLE_CONF');
    const [confType, setConfType] = useState<ActionType>('POSSIBLE_CONF');
    const [currentRule, setCurrentRule] = useState<Rule | undefined>();

    const [selectedType, setSelectedType] = useState<{ index: number, type: number, label: string }>({
        index: 0,
        type: 0,
        label: 'Prix',
    });

    const switchRes = useSelector<RootState, SwitchRulesState>(state => state.switchRuleType);


    useEffect(() => {
        !isMount && dispatch(getProductRules(props.product.reference));
        return () => setIsMount(true);
    }, [props.product]);
    useEffect(() => {
        currentConfRuleType === 'POSSIBLE_CONF' ? setEmptyStart(false) : setEmptyStart(true);

    }, [currentConfRuleType]);
    useEffect(() => {
        if (selectedType.type !== 0 && selectedType.type !== 2) {
            setConfType(emptyStart ? 'IMPOSSIBLE_CONF' : 'POSSIBLE_CONF');
            emptyStart
                ? dispatch(switchConfRulesType({ productRef: props.product.reference, newRuleType: 'IMPOSSIBLE_CONF' }))
                : dispatch(switchConfRulesType({ productRef: props.product.reference, newRuleType: 'POSSIBLE_CONF' }));

        } else {
            setConfType('SET_DIMENSION');
        }
    }, [emptyStart]);
    useEffect(() => {
        if (switchRes.payload.content.id !== 0) {
            dispatch(getProductRules(props.product.reference));
        }
    }, [switchRes]);
    const onSave = async (productRef: string) => {
        await apiClient(`v1/product/${productRef}/save-business-rules`, undefined, undefined, 'post');
        if (props.product.productType === 'COMPOSITION') {
            props.setStep(5);
            props.setFocus(5);
        } else {
            props.setStep(4);
            props.setFocus(4);
        }
    };
    const returnRuleType = () => {
        return props.product.productType !== 'COMPOSITION'
            ? ruleType
            : composableRuleType;
    };

    return (
        <div className='products-rules-main'>
            {openModal && <CreateRules
                setRule={setCurrentRule}
                rule={currentRule}
                confType={confType}
                emptyStart={emptyStart}
                setEmptyStart={setEmptyStart}
                confRuleType={currentConfRuleType}
                product={props.product}
                rules={selectedType}
                openModal={openModal}
                setOpenModal={setOpenModal} />}
            <div className='products-rules-tab'>
                {returnRuleType().map((el, index) => {
                    return (
                        <Fragment key={index}>
                            <div key={index}
                                 onClick={() => {
                                     setSelectedType({ index: index, type: el.type, label: el.label });
                                 }}
                                 className={selectedType.index === index ? 'partTab focus' : 'partTab'}>
                                {el.label.toUpperCase()}
                            </div>
                        </Fragment>
                    );
                })}
            </div>
            <div className='products-rules-body'>
                {selectedType.type === 1 && <div className='po-impo-rules'>
                    <div>Chosir le type de règle de configuration à appliquer pour le produit</div>
                    <div className='body-toogle'>
                        <span className={emptyStart ? '' : 'fill'}>Possible</span>
                        <ToogleSwitch title=''
                                      setIsToggled={setEmptyStart}
                                      isToggled={emptyStart} />
                        <span className={emptyStart ? 'fill' : ''}>Impossible</span>
                    </div>
                </div>}
                <ProductRulesTable
                    setCurrentRule={setCurrentRule}
                    setOpenAssociateRule={setOpenCompoModal}
                    openAssociateRule={openCompoModal}
                    setRuleConfType={setCurrentConfRuleType}
                    setOpenCreateModal={setOpenModal}
                    product={props.product}
                    type={selectedType.type} />
            </div>
            <div className='btn-box'>
                <button className='import'
                        onClick={() => {

                            selectedType.type === 3
                                ? setOpenCompoModal(true)
                                : setOpenModal(true);
                        }}>

                    <img alt='plus' src={plus} />
                    <span>{
                        selectedType.type === 3 ? 'Associer' : 'Créer'}</span>
                </button>

                <button
                    className='save'
                    onClick={() => {
                        onSave(props.product.reference);
                    }}>Suivant
                </button>
            </div>
        </div>
    );
};
export default ProductDetailRules;