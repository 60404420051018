import { Engine } from "@lutithree/build/Engine";
import { MeshLoaderService } from "./MeshLoaderService";
import CamerasService
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/CamerasService";
import ScreenshotService from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Screenshot/ScreenshotService";


export class CharacteristicStudioServices
{
    private m_screenshotService: ScreenshotService;
    
    private m_meshService: MeshLoaderService;

    private readonly m_cameras: CamerasService;
    
    public constructor(p_engine: Engine) {
        if (p_engine == null) throw new Error('NullReference Exception: p_engine is null or undefined');
        this.m_screenshotService = new ScreenshotService(p_engine);
        this.m_meshService = new MeshLoaderService(p_engine);
        this.m_cameras = new CamerasService(p_engine);
    }

    public get ScreenshotService(): ScreenshotService {
        return this.m_screenshotService;
    }
    
    public get MeshService(): MeshLoaderService {
        return this.m_meshService;
    }

    public get Cameras(): CamerasService {
        return this.m_cameras;
    }
}
