import { useEffect, useState } from 'react';
import Inspector from '../Scripts/Inspector/Inspector';
import { useDispatch, useSelector } from 'react-redux';
import { MapType } from '../Scripts/Inspector/MapType';
import { RootState } from '../../../services/root-reducer';
import store from '../../../services/store';
import { MeshPhysicalMaterial } from 'three';
import { Set } from '../Libraries/TweakPaneUtilities';
import {
    ColorState,
    SetGLTF,
    SetMaterial,
    ToggleGLTFDirty,
} from '../../../services/reducers/material-editor/ColorReducer';
import {
    ClearCurrentTexture,
    EditorState,
    SetCurrentSlotRef,
    SetEditorDirty,
} from '../../../services/reducers/material-editor/EditorReducer';
import { SetCurrentType, SetType, ThumbnailState } from '../../../services/reducers/material-editor/ThumbnailReducer';
import { getFinish, GetFinishState } from '../../../services/reducers/suppliers/finish/finishes.slice';
import { useParams } from 'react-router-dom';
import { Params } from '../../../domain/Params/params-interface';
import { ExitType } from '../Scripts/Inspector/ExitType';
import { Guid } from 'guid-typescript';
import { EntityState } from '../../../services/reducers/material-editor/EntitiesReducer';

const InspectorContainer = () => {
    const dispatch = useDispatch();
    const { finishId } = useParams<Params>();
    const { entityIds } = useSelector<RootState, EntityState>((state) => state.EntitiesReducer);

    const { material, isGltfDirty, url } = useSelector<RootState, ColorState>((state) => state.ColorReducer);
    const { editorDirty, currentTexture, currentSlotRef, savedMat, game, exitStatus } = useSelector<RootState, EditorState>((state) => state.EditorReducer);
    const finish = useSelector<RootState, GetFinishState>((state) => state.getFinish);

    const [inspector, setInspector] = useState<Inspector | null>(null);
    const { type, currentType } = useSelector<RootState, ThumbnailState>((state) => state.ThumbnailReducer);

    useEffect(() => {
        finishId && dispatch(getFinish(parseInt(finishId)));
    }, []);

    useEffect(() => {
        if (!isGltfDirty && material && exitStatus === ExitType.Save) {
            inspector?.SetSavedMaterial(inspector?.m_materialEditor.m_editMat);
            inspector?.ResetShowOnMesh();
            let allEntites: Guid[] = [];
            entityIds.forEach((obj) => {
                allEntites.push(obj.entityId);
            });
            if (inspector?.m_materialEditor.m_editMat) game?.TargetMeshService.SetMaterialOnEntities(allEntites, inspector?.m_materialEditor.m_editMat);
            if (inspector as any) {
                // TODO Lubin fix : J'ai rajouté ce if temporairement, ça à l'air de fonctionner, mais c'est un fix à la Scuderia Ferrari :) Tawfiq
                (inspector as any).m_pane.refresh();
            }
        }
    }, [isGltfDirty]);

    useEffect(() => {
        switch(finish.payload.content?.typeVignette) {
            case "A_PLAT":
                dispatch(SetType('Box'));
                break;
            case "SPHERE":
                dispatch(SetType('Sphere'));
                break;
            case "TISSUS":
                dispatch(SetType('Fabric'));
                break;
            default:
                break;
        }
    }, [finish]);

    useEffect(() => {
        if (inspector && material) {
            inspector.SetUpdatedMaterial(material as MeshPhysicalMaterial);
            inspector.UpdateUIFromMaterial(material as MeshPhysicalMaterial, url, editorDirty);
        }
    }, [material]);

    useEffect(() => {
        if (savedMat) inspector?.SetSavedMaterial(savedMat);
    }, [savedMat]);

    useEffect(() => {
        dispatch(SetCurrentType(type));
    }, [type]);

    useEffect(() => {
        if (currentType) {
            inspector?.ChangeSupportOption(currentType);
        }
    }, [currentType]);

    useEffect(() => {
        if (currentTexture && currentSlotRef != null) inspector?.UpdateInspectorSlotFromUrl(currentSlotRef, currentTexture);
        dispatch(ClearCurrentTexture());
    }, [currentTexture]);

    useEffect(() => {
        inspector?.SetMaterialUpdatedCallback((p_material, p_dirty) => {
            store.dispatch(SetEditorDirty(p_dirty));
            store.dispatch(SetMaterial(p_material));
        });

        inspector?.SetMeshUpdatedCallback((p_mesh) => {
            store.dispatch(SetCurrentType(p_mesh));
        });
        inspector?.SetExportToJsonCallback((p_json) => {
            store.dispatch(SetGLTF(p_json));
        });
        inspector?.SetDirtyGLTFCallback(() => {
            store.dispatch(ToggleGLTFDirty(true));
        });
        inspector?.BuildInspectorUI();
    }, [inspector]);

    useEffect(() => {
        if (!game) return;
        inspector?.SetBackgroundStudioCallback((value) => {
            game?.BackgroundService.SetBackgroundStudio(value);
            game?.TargetMeshService.RequestRender();
        });
        
        inspector?.SetBackgroundRougnessCallback((value) => {
            game?.BackgroundService.SetBackgroundRougness(value);
            game?.TargetMeshService.RequestRender();
        });
        if(inspector)
            inspector.m_maxAnisotropy = game.MaxAnisotropy;
       
    }, [game, inspector]);

    useEffect(() => {
        let allEntites: Guid[] = [];
        entityIds.forEach((obj) => {
            allEntites.push(obj.entityId);
        });
        inspector?.SetEnvmapRotation((value) => {
            game?.SetEnvmapRotation(allEntites, value);
        });
        if(inspector) game?.SetEnvmapRotation(allEntites, inspector.GetEnvRotation());
    }, [game, inspector, entityIds]);
    
    useEffect(() => {
       if(inspector && url)
           inspector.UpdateMatURL(url);
    }, [url]);
    

    useEffect(() => {
        if(url && !inspector)
            setInspector(new Inspector(url));
        Set((nameOfCell: MapType) => {
            dispatch(SetCurrentSlotRef(nameOfCell));
        });
    }, [url]);

    return <></>;
};

export default InspectorContainer;
