import './CharacteristicStudio.scss';
import { CharacteristicStudioViewer } from './CharacteristicStudioViewer';
import React, { useEffect, useState } from 'react';
import { Object3D } from 'three';
import MeshLoaderManager from './MeshLoaderManager';
import CharacteristicPOVManager from './CharacteristicPOVManager';
import { useDispatch, useSelector } from 'react-redux';
import ResourceErrorData
    from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ResourceErrorData";
import {
    PointOfViewPlacement
} from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/PointOfViews";
import Asset3D from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D";
import {setPointOfViewPlacement} from "../../Redux/Reducers/characteristic-studio/CharacteristicStudioReducer";
import {ResourceErrorHandler} from "../../../application3D-common/Components/ResourceErrorHandler/ResourceErrorHandler";



export type CharacteristicStudioProps = {
    previewMesh?: Asset3D
    previewMapping?: Asset3D
    cameraPointOfView?: PointOfViewPlacement
    onLoadingFail?: (p_resourceErrorData: ResourceErrorData) => void

    setPovActivated: boolean;
};

const CharacteristicStudio = (props: CharacteristicStudioProps) => {
    const [object, SetObject] = useState<Object3D | undefined>(undefined);

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.cameraPointOfView !== PointOfViewPlacement.TOP
            && props.cameraPointOfView !== PointOfViewPlacement.TOP_90
            && props.cameraPointOfView !== PointOfViewPlacement.THREE_QUARTER
            && props.cameraPointOfView !== PointOfViewPlacement.THREE_QUARTER_OFF
            && props.cameraPointOfView !== PointOfViewPlacement.FRONT) {
            dispatch(setPointOfViewPlacement(PointOfViewPlacement.FREE));
        }
    }, [props.cameraPointOfView]);

    return (
        <>
            <ResourceErrorHandler onLoadingFail={ props.onLoadingFail } />
            <CharacteristicPOVManager
                cameraPointOfView={ props.cameraPointOfView }
                object={ object }
            />
            <MeshLoaderManager previewMesh={ props.previewMesh }
                               previewMaterial={ props.previewMapping }
                               setObjectCallback={ SetObject } />
            <CharacteristicStudioViewer />
        </>
    );
};

export default CharacteristicStudio;
