import React from 'react';
import './paper.scss';

export type PaperProps = {
    size?: number;
    height?: number;
    top?: number;
    bottom?: number;
    children: React.ReactNode;
};

const Paper = ({ top, bottom, size, height, children }: PaperProps) => {
    let style = {
        height: `${height}px`,
        marginTop: `${top}px`,
        marginBottom: `${bottom}px`,
    };
    return (
        <div style={style} className="paper-main">
            {children}
        </div>
    );
};

export default Paper;
