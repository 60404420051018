import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from '../login/login';
import CreateNews from '../news/create-news';
import UpdateNews from '../news/update-news';
import CreateUser from '../user/create-user';
import PrivateRoute from './private-route';
import CreateFirstUser from '../user/create-user/create-first-user';
import CreatePassword from '../user/create-password/create-password';
import NewsList from '../news/news-list';
import UserList from '../user/user-list';
import NewsCategoryList from '../news-category/news-category-list';
import CreateRole from '../role/create-role';
import RoleList from '../role/role-list';
import UpdateUserByAdmin from '../user/update-user/update-user-byAdmin';
import CreateCategoryPage from '../news-category/create-category-page';
import UpdateUser from '../../uicomponents/update-user-form/update-user';
import Suppliers from '../supplier/suppliers';
import CreateSupplier from '../supplier/create-supplier';
import UpdateSupplier from '../supplier/update-supplier';
import Supplier from '../supplier/supplier';
import Finishes from '../supplier/finishes/finishes';
import CreateFinitionPage from '../supplier/finishes/create-finition-page';
import UpdateFinish from '../supplier/finishes/update-finish';
import CreateCollection from '../supplier/collections/create-collection';
import UpdateCollection from '../supplier/collections/update-collection';
import Modele from '../supplier/modeles/modele';
import ProductDetail from '../supplier/modeles/products/product-detail/product-detail';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import { ProductInfoState } from '../../services/reducers/product/product.reducers';
import Catalog from '../catalog/catalog/catalog';
import CatalogMain from '../catalog/catalog-main';
import Stores from '../stores/stores';
import Store from '../stores/store/store';
import AddUsersToStore from '../stores/add-users-to-store/add-users-to-store';


export const MainRouter = () => {
    const product = useSelector<RootState, ProductInfoState>((state) => state.getProductionById);
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/' exact component={ Login } />
                <Route path='/first-user' exact component={ CreateFirstUser } />
                <Route path='/create-password/:resetPasswordToken' exact component={ CreatePassword } />
                <PrivateRoute component={ NewsList } path='/home/news/:statusParam' />
                <PrivateRoute component={ NewsList } path='/home/news' />
                <PrivateRoute component={ UserList } path='/home/users/:statusParam' />
                <PrivateRoute component={ UserList } path='/home/users' />
                <PrivateRoute component={ NewsCategoryList } path='/home/news-categories' />
                <PrivateRoute component={ CreateCategoryPage } path='/create-news-category' />
                <PrivateRoute component={ RoleList } path='/home/roles' />
                <PrivateRoute component={ CreateNews } path='/create-news' />
                <PrivateRoute component={ CreateUser } path='/create-user' />
                <PrivateRoute component={ UpdateUserByAdmin } path='/update-user-by-admin/:id' />
                <PrivateRoute component={ UpdateUser } path='/update-user' />
                <PrivateRoute component={ UpdateNews } path='/update-news/:id' />
                <PrivateRoute component={ CreateRole } path='/create-role/:id?' />
                <PrivateRoute component={ UpdateFinish }
                              path='/supplier/:supplierReference/finishes/:finishId/update' />
                <PrivateRoute component={ UpdateCollection }
                              path='/supplier/:supplierReference/collections/:collectionReference/update' />
                <PrivateRoute component={ Finishes }
                              path='/supplier/:supplierReference/finishes/:finishId/textures/create' />
                <PrivateRoute component={ Finishes } path='/supplier/:supplierReference/finishes/:finishId/textures' />
                <PrivateRoute component={ Finishes } path='/supplier/:supplierReference/finishes/:finishId/colors' />
                <PrivateRoute component={ Modele }
                              path='/supplier/:supplierReference/collections/:collectionReference/products' />
                <PrivateRoute component={ Modele }
                              path='/supplier/:supplierReference/collections/:collectionReference/mapping' />
                <PrivateRoute component={ Modele }
                              path='/supplier/:supplierReference/collections/:collectionReference/composition' />
                <PrivateRoute component={ Modele }
                              path='/supplier/:supplierReference/collections/:collectionReference/win' />
                <PrivateRoute component={ Suppliers } path='/home/suppliers' />
                <PrivateRoute component={ Supplier } path='/supplier/:supplierReference/collections' />
                <PrivateRoute component={ Supplier } path='/supplier/:supplierReference/finitions' />
                <PrivateRoute component={ CreateSupplier } path='/create-supplier' />
                <PrivateRoute component={ UpdateSupplier } path='/suppliers/:reference' />
                <PrivateRoute component={ CreateFinitionPage } path='/finishes/create/:supplierReference' />
                <PrivateRoute component={ CreateCollection } path='/collections/create/:supplierReference' />
                <PrivateRoute component={ ProductDetail }
                              path='/supplier/:supplierReference/collection/:collectionReference/product/:productReference/:partRef' />
                <PrivateRoute component={ ProductDetail }
                              path='/supplier/:supplierReference/collection/:collectionReference/product/:productReference' />
                <PrivateRoute component={ CatalogMain } path='/catalogs' />
                <PrivateRoute component={ Catalog } path='/catalog/:catalogRef/products' />
                <PrivateRoute component={ Catalog } path='/catalog/:catalogRef/sections' />
                <PrivateRoute component={ Catalog } path='/catalog/:catalogRef/infos' />
                <PrivateRoute component={ Stores } path='/stores' />
                <PrivateRoute component={ AddUsersToStore } path='/store/:storeRef/:storeName/add-users' />
                <PrivateRoute component={ Store } path='/store/:storeRef/:storeName' />
                <PrivateRoute component={ Login } path='*' />
            </Switch>
        </BrowserRouter>
    );
};
