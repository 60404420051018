import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Project, Room, RoomDTO, RoomModel, RoomResponse } from '../../../domain/domain';
import MdfApiResponse from '../../../domain/common/generic';
import roomsService from '../../../api/services/rooms/rooms.services';

// ==== add room ===
export type AddRoomState = {
    isLoading: boolean;
    payload: MdfApiResponse<Room | undefined>;
};
const initialAddRoom: AddRoomState = {
    isLoading: false,
    payload: { content: undefined, warnings: [], errors: [] },
};
export const addRoom = createAsyncThunk('add/room', async ({
                                                               projectId,
                                                               templateId,
                                                               roomDTO,
                                                           }: { projectId: number | undefined; templateId: number, roomDTO: RoomDTO }) => {
    const result = await roomsService.addRoom(projectId, templateId, roomDTO);
    return result;
});
export const addRoomSlice = createSlice({
    name: 'AddRoomSlice',
    initialState: initialAddRoom,
    reducers: {
        resetAddRoom: (state) => {
            state = initialAddRoom;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addRoom.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(addRoom.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(addRoom.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});
export const { resetAddRoom } = addRoomSlice.actions;
// ==== get room model ====
export type GetRoomModelState = {
    isLoading: boolean;
    //  payload2:MdfApiResponse<RoomModel[] | undefined>;
    payload2: RoomModel[] | undefined;
};

const initalGetRoomModel: GetRoomModelState = {
    isLoading: false,
    payload2: undefined,
};

export const getRoomModel = createAsyncThunk('get/RoomModel',
    async () => {
        const result = await roomsService.getRoomModels();
        return result;
    });

export const getRoomModelSlice = createSlice({
    name: 'GetRoomModelSlice',
    initialState: initalGetRoomModel,
    reducers: {
        resetGetRoomModel: state => {
            state = initalGetRoomModel;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRoomModel.pending, (state) => {
            state.isLoading = true;
        });


        builder.addCase(getRoomModel.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload2 = payload;
            state.isLoading = false;
        });

        builder.addCase(getRoomModel.rejected, (state) => {
            state.isLoading = false;
        });

    },
});
export const { resetGetRoomModel } = getRoomModelSlice.actions;

// ==== get room ===
export type GetRoomState = {
    isLoading: boolean;
    payload: MdfApiResponse<RoomResponse | undefined>;
};
const initialGetRoom: GetRoomState = {
    isLoading: false,
    payload: { content: undefined, warnings: [], errors: [] },
};

export const getRoom = createAsyncThunk('get/room',
    async ({
               projectId,
               roomUuid,
           }: { projectId: number | undefined; roomUuid: string | undefined }) => {
        const result = await roomsService.getRoom(projectId, roomUuid);
        return result;
    });
export const getRoomSlice = createSlice({
    name: 'GetRoomSlice',
    initialState: initialGetRoom,
    reducers: {
        resetGetRoom: (state) => {
            state = initialGetRoom;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRoom.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getRoom.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(getRoom.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetRoom } = getRoomSlice.actions;

// ==== delete Room ===
export type DeleteRoomState = {
    isLoading: boolean;
    payload: MdfApiResponse<Project | undefined>;
};
const initialDeleteRoom: DeleteRoomState = {
    isLoading: false,
    payload: { content: undefined, warnings: [], errors: [] },
};
export const deleteRoom = createAsyncThunk('delete/room', async ({
                                                                     projectId,
                                                                     roomName,
                                                                 }: { projectId: number | undefined; roomName: string | undefined }) => {
    const result = await roomsService.deleteRoom(projectId, roomName);
    return result;
});

export const deleteRoomSlice = createSlice({
    name: 'deleteRoomSlice',
    initialState: initialDeleteRoom,
    reducers: {
        resetDeleteRoom: (state) => {
            state = initialDeleteRoom;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteRoom.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteRoom.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(deleteRoom.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteRoom } = deleteRoomSlice.actions;

// === get Room Name ===

export type RoomNameState = {
    roomName: string;
};

const initialRoomNameState: RoomNameState = {
    roomName: '',
};

export const getRoomNameSlice = createSlice({
    name: 'getRoomName',
    initialState: initialRoomNameState,
    reducers: {
        getRoomName: (state, action) => {
            state.roomName = action.payload;
            return state;
        },
    },
});

export const { getRoomName } = getRoomNameSlice.actions;

// ==== duplicate room ===
export type DuplicateRoomState = {
    isLoading: boolean;
    payload: MdfApiResponse<Room | undefined>;
};
const initialDuplicateRoom: DuplicateRoomState = {
    isLoading: false,
    payload: { content: undefined, warnings: [], errors: [] },
};
export const duplicateRoom = createAsyncThunk('duplicate/room', async ({
                                                                           projectId,
                                                                           roomName,
                                                                       }: { projectId: number | undefined; roomName: string | undefined }) => {
    const result = await roomsService.duplicateRoom(projectId, roomName);
    return result;
});
export const duplicateRoomSlice = createSlice({
    name: 'DuplicateRoomSlice',
    initialState: initialDuplicateRoom,
    reducers: {
        resetDuplicateRoom: (state) => {
            state = initialDuplicateRoom;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(duplicateRoom.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(duplicateRoom.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(duplicateRoom.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});
export const { resetDuplicateRoom } = duplicateRoomSlice.actions;

// ==== update room name===
export type UpdateRoomNameState = {
    isLoading: boolean;
    payload: MdfApiResponse<Room | undefined>;
};
const initialUpdateRoomName: UpdateRoomNameState = {
    isLoading: false,
    payload: { content: undefined, warnings: [], errors: [] },
};
export const updateRoomName = createAsyncThunk(
    'update/roomName',
    async ({
               projectId,
               roomId,
               newName,
           }: { projectId: number | undefined; roomId: string | undefined; newName: string | undefined }) => {
        const result = await roomsService.updateRoomName(projectId, roomId, newName);
        return result;
    },
);

export const updateRoomNameSlice = createSlice({
    name: 'UpdateRoomNameSlice',
    initialState: initialUpdateRoomName,
    reducers: {
        resetUpdateRoomName: (state) => {
            state = initialUpdateRoomName;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateRoomName.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateRoomName.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(updateRoomName.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateRoomName } = updateRoomNameSlice.actions;


// ==== edit Room Description ===

export type EditRoomDescState = {
    isLoading: boolean;
    payload: MdfApiResponse<Room | undefined>;
};
const initialEditRoomDesc: EditRoomDescState = {
    isLoading: false,
    payload: { content: undefined, warnings: [], errors: [] },
};
export const editRoomDesc = createAsyncThunk(
    'edit/roomDesc',
    async ({
               projectId,
               roomId,
               data,
           }: { projectId: number | undefined, roomId: string | undefined, data: { description: string } }) => {
        const result = await roomsService.editRoomDesc(projectId, roomId, data);
        return result;
    },
);

export const editRoomDescSlice = createSlice({
    name: 'EditRoomDescSlice',
    initialState: initialEditRoomDesc,
    reducers: {
        resetEditRoomDesc: (state) => {
            state = initialEditRoomDesc;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(editRoomDesc.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(editRoomDesc.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(editRoomDesc.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});
export const { resetEditRoomDesc } = editRoomDescSlice.actions;
