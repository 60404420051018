import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Engine } from '@lutithree/build/Engine';
import { MeshLoaderService } from "./GameLogic/Services/MeshLoaderService";
import {MappingStudio} from "./GameLogic/Studio/MappingStudio";
import CamerasService
    from "../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/CamerasService";
import TSBasicNotifierService
    from "../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Notifier/TSBasicNotifierService";
import BasicStudioHandlers
    from "../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/BasicStudioHandlers";

export class MappingStudioApp {
    
    private readonly m_engine: Engine;
    
    private readonly m_studio: MappingStudio;
    
    private readonly m_meshServices: MeshLoaderService;

    private readonly m_cameras: CamerasService;

    private readonly m_notifier: TSBasicNotifierService;

    private readonly m_rootEntities: Map<string, SceneEntity>;

    public constructor(p_engine: Engine) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (!p_engine.IsInitialized()) throw new Error('Engine need to be initialized before being usued!');

        this.m_notifier = new TSBasicNotifierService();
        
        // Set Engine
        this.m_engine = p_engine;
        this.m_rootEntities = new Map<string, SceneEntity>();

        // Set application Service
        this.m_cameras = new CamerasService(p_engine);
        this.m_meshServices = new MeshLoaderService(p_engine);
        
        // Handlers
        let handlers = new BasicStudioHandlers(this.m_engine, this.m_notifier);
        handlers.InitEventManagerWithHandlers();

        this.m_studio = new MappingStudio(this.m_rootEntities);
        this.m_studio.Build(this.m_engine);
    }

    public get Notifier(): TSBasicNotifierService {
        return this.m_notifier;
    }

    public get EngineService(): Engine {
        return this.m_engine;
    }

    public get MeshService(): MeshLoaderService {
        return this.m_meshServices;
    }
    
    public Clear(): void {
        this.m_rootEntities.clear();
    }

    public get Cameras(): CamerasService {
        return this.m_cameras;
    }
}
