import axios from 'axios';
import { getConfig } from '../api/config';
import { ColorisUpdateForm, UploadingImgFileWithPreview } from '../domain/domain';


export const Upload = async (data: unknown, setImageUrl: (urls: string[]) => void) => {
    await axios
        .post(`${ getConfig().urlHostApi }/v1/documents/upload/list`, data, {
            headers: { Authorization: `Bearer ${ localStorage.getItem('mdf-jwt') }` },
        })
        .then((res) => {
            setImageUrl(res.data.content);
        })
        .catch((e) => {
            console.log('erreur', e);
        });
};

export const postFile = async (url: string, form: FormData, onSuccess: () => void, setResult?: (result: any) => void, setIsLoading?: (loading: boolean) => void, list?: string[]) => {
    setIsLoading && setIsLoading(true);
    await axios
        .post(`${ getConfig().urlHostApi }/${ url }`, form, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('mdf-jwt') }`,
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => {
            //   setIsLoading && setIsLoading(false);
            setResult && setResult(res);
            onSuccess();
            list && list.push(res.data.content);
        })
        .catch((e) => {
            console.log('erreur', e);
        });
};

export const postJson = async (url: string, json: ColorisUpdateForm, onSuccess: () => void, setResult?: (result: any) => void, setIsLoading?: (loading: boolean) => void, list?: string[]) => {
    setIsLoading && setIsLoading(true);
    await axios
        .post(`${ getConfig().urlHostApi }/${ url }`, json, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('mdf-jwt') }`,
            },
        })
        .then((res) => {
            setIsLoading && setIsLoading(false);
            setResult && setResult(res);
            onSuccess();
            list && list.push(res.data.content);
        })
        .catch((e) => {
            console.log('erreur', e);
        });
};
export const postFileWithPreview = async (url: string, file: FormData, fileNameWithTimeStamp: boolean, setUploadedList: (uploadedList: UploadingImgFileWithPreview[]) => void) => {
    await axios
        .post(`${ getConfig().urlHostApi }/${ url }?fileNameWithTimeStamp=${ fileNameWithTimeStamp }`, file, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('mdf-jwt') }`,
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => {
            // @ts-ignore
            setUploadedList((prevState) => [...prevState, {
                    url: res.data.content.url,
                    urlOfPreview: res.data.content.urlOfPreview,
                }],
            );
        }).catch((e) => console.log('e ==> ', e));
};


export const postFileListWithPreview = async (url: string, files: FormData, fileNameWithTimeStamp: boolean, uuid: string, setUploadedList: (uploadedList: UploadingImgFileWithPreview[]) => void) => {
    await axios
        .post(`${ getConfig().urlHostApi }/${ url }?fileNameWithTimeStamp=${ fileNameWithTimeStamp }&progressUuid=${ uuid }`, files, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('mdf-jwt') }`,
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => {
            console.log('RES', res);
            if (res && res.data && res.data.content) {
                setUploadedList(res.data.content);
            }
            // @ts-ignore
            // setUploadedList((prevState) => [...prevState, {
            //         url: res.data.content.url,
            //         urlOfPreview: res.data.content.urlOfPreview,
            //     }],
            // );
        }).catch((e) => console.log('e ==> ', e));
};

export const deleteFile = async (url: string) => {
    await axios
        .delete(`${ getConfig().urlHostApi }/v1/documents/delete?url=${ encodeURIComponent(url) }`, {
            headers: { Authorization: `Bearer ${ localStorage.getItem('mdf-jwt') }` },
        })
        .then((res) => {

        })
        .catch((e) => {
            console.log('erreur', e);
        });
};
