import Layout from '../../uicomponents/layout/layout';
import MainPaper from '../../uicomponents/paper/main-paper';

import CreateSupplierForm from '../../uicomponents/create-supplier-form/create-supplier-form';

const CreateSupplier = () => {
    return (
        <Layout labelBack="Quitter la création d'un fournisseur ?" title="Créer un fournisseur" info="Voulez vous quitter la creation du nouveau fournisseur ?">
            <MainPaper>
                <CreateSupplierForm />
            </MainPaper>
        </Layout>
    );
};
export default CreateSupplier;
