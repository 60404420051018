import close from '../../../../asset/CloseWindow.svg';
import { useDispatch } from 'react-redux';
import { createMapping } from '../../../../services/reducers/mapping-zoning/mapping.reducer';
import { SubmitHandler, useForm } from 'react-hook-form';

type CreateMappingProps = {
    setOpenModal: (openModal: boolean) => void;
    groupId: number
};

type AddMappingInput = {
    name: string;
};

export const returnadvice = (st: string, limit: number) => {
    const returnLength = () => {
        if (st !== undefined) {
            return st.length;
        } else {
            return 0;
        }
    };
    return <div className={`advice ${st && st.length > limit ? 'red' : ''}`}>
        <b> {`${returnLength()}/ ${limit} `}</b>
        {st && st.length > limit && <b>Nombre de caractères conseillés</b>}
    </div>;
};
const CreateMapping = (props: CreateMappingProps) => {
        const dispatch = useDispatch();

        const {
            watch,
            register,
            handleSubmit,
            formState: { errors },
        } = useForm<AddMappingInput>({});

        const onSubmit: SubmitHandler<AddMappingInput> = (data) => {
            props.groupId && dispatch(createMapping({
                groupId: props.groupId,
                data: {
                    name: data.name,
                    zoningMetadata: {
                        zoneList: [],
                    },
                },
            }));
        };
        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSubmit(onSubmit)();
            }
        };

        const name = watch('name');


        return (
            <div className='create-group-main'>
                <div className='create-group-paper'>
                    <div className='create-group-top'>
                        <img onClick={() => {
                            props.setOpenModal(false);
                        }} alt='fermer la modal ' src={close} />
                        <div
                            className='create-group-title'>
                            Créer un mapping
                        </div>
                    </div>
                    <div className='create-group-body'>
                        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={keyDownHandler}>
                            <div className='input-container'>
                                <label htmlFor='name'>Nom</label>
                                <input
                                    placeholder='Nom du mapping'
                                    {...register('name', {
                                        required: true,
                                    })} />
                                <> {returnadvice(name, 24)}</>
                            </div>
                            <div className='btn-box'>
                                <button onClick={() => {
                                    props.setOpenModal(false);
                                }} className='btn-close'>
                                    Annuler
                                </button>
                                <button className='btn-action'>Créer</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
;
export default CreateMapping;