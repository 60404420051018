import { useState } from 'react';
import './hovericon.scss';

type HoverIconProps = {
    onClick?: () => void;
    icon: string;
    iconHover: string;
    title: string;
};

const HoverIcon = ({ onClick, icon, iconHover, title }: HoverIconProps) => {
    const [hover, setHover] = useState(false);

    return (
        <div
            onClick={onClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => {
                setHover(false);
            }}
            className="hoverIcon-main"
        >
            {hover ? <img alt="logo modifier bleu" src={iconHover} title={title} /> : <img onClick={onClick} alt="logo update" src={icon} />}
        </div>
    );
};
export default HoverIcon;
