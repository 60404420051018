import './catalog-info.scss';
import { useEffect, useState } from 'react';
import Multiselect from 'react-widgets/Multiselect';
import { useParams } from 'react-router-dom';
import { formErrors } from '../../../utils/form-errors';
import {
    CatalogResponseState,
    createAndUpdateCatalog, resetCreateCatalog,
} from '../../../services/reducers/catalog/catalog.slice';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Params } from '../../../domain/Params/params-interface';
import { RootState } from '../../../services/root-reducer';
import { getAllRole, GetRoleListState } from '../../../services/reducers/role-reducer/role-slice';
import { CatalogResponse, CatalogVisibility, Role, User } from '../../../domain/domain';
import { getActiveUser, UserListState } from '../../../services/reducers/users/users.slice';
import Toaster from '../../../uicomponents/toaster/toaster';

type CatalogInfoProps = { catalog: CatalogResponse };
type CatalogInfoInputs = {
    name: string;
    visibility: CatalogVisibility[];
    role: Role[];
    users: User[];
};

type VisibilityOption = { id: number, label: CatalogVisibility };
const CatalogInfo = ({ catalog }: CatalogInfoProps) => {
    const dispatch = useDispatch();

    const visibilityOptions: VisibilityOption[] = [
        { id: 1, label: 'WEB' },
        { id: 2, label: 'SELLER' },
    ];
    const {
        register,
        handleSubmit,
        setError,
        watch,
        setValue,
        formState: { errors },
    } = useForm<CatalogInfoInputs>();

    const vis = watch('visibility');
    const role = watch('role');
    const users = watch('users');
    const cat = catalog.catalog;

    //region selector
    const roles = useSelector<RootState, GetRoleListState>((state) => state.getAllRoles);
    const usersList = useSelector<RootState, UserListState>((state) => state.getActiveUsers);
    const createAndUpdateRes = useSelector<RootState, CatalogResponseState>(state => state.createAndUpdateCatalog);
    //endregion

    //region state
    const [visibilityErr, setVisibilityErr] = useState('');
    const [roleErr, setRoleErr] = useState('');
    const [userErr, setUserErr] = useState('');
    const [isMount, setIsMount] = useState<boolean>(false);
    const [visibilityArray, setVisibilityArray] = useState<VisibilityOption[]>([]);

    const [defaultRoleIds, setDefaultRoleIds] = useState<number[]>([]);
    const [defaultUsersIds, setDefaultUsersIds] = useState<number[]>([]);
    const [defaultVisibilityIds, setDefaultVisibilityIds] = useState<number[]>([]);
    const [finish, setFinish] = useState(false);

    const [saveSucess, setSavaSucess] = useState<boolean>(false);
    //endregion

    //region effect
    useEffect(() => {
        !isMount && dispatch(getAllRole());
        !isMount && dispatch(getActiveUser());
        !isMount && setValue('users', []);
        return () => setIsMount(false);
    }, []);
    useEffect(() => {
        if (catalog.catalog.id !== 0) {
            setValue('name', catalog.catalog.name);
            //setValue('visibility', c.visibility);
            // setValue('role', c.destinationRoles);
            setValue('users', catalog.catalogUserList);

            let visIdsList: number[] = [];
            /*c.visibility.forEach((v: CatalogVisibility) => {
                v === 'WEB' && visIdsList.push(1);
                v === 'SELLER' && visIdsList.push(2);
            });*/
            setDefaultVisibilityIds(visIdsList);
            let roleIdsList: number[] = [];
            //c.destinationRoles.forEach((r) => roleIdsList.push(r.id));
            setDefaultRoleIds(roleIdsList);

            let usersIdsList: number[] = [];
            catalog.catalogUserList.forEach((u) => usersIdsList.push(u.id));
            setDefaultUsersIds(usersIdsList);
        }
        return () => setFinish(true);
    }, [catalog]);
    useEffect(() => {
        const newViList: CatalogVisibility[] = [];

        visibilityArray.map((v) => {
            newViList.push(v.label);
        });
        setValue('visibility', newViList);
    }, [visibilityArray]);
    useEffect(() => {
        if (createAndUpdateRes.payload.content.catalog.id !== 0) {
            dispatch(resetCreateCatalog());
            setSavaSucess(true);
        }
    }, [createAndUpdateRes]);
    //endregion

    //region methods
    const onSubmit: SubmitHandler<CatalogInfoInputs> = (data) => {
        dispatch(
            createAndUpdateCatalog({
                catalog: {
                    id: cat.id,
                    version: cat.version,
                    createdAt: cat.createdAt,
                    updatedAt: new Date(),
                    name: data.name,
                    reference: cat.reference,
                },
                catalogUserList: data.users,
            }),
        );
        /*        if (!vis || vis.length === 0) {
                    //setVisibilityErr('Ce champ est requis');
                } else {
                    const cat = catalog.payload.content;
                    catalog.payload.content &&
                    dispatch(
                        createAndUpdateCatalog({
                            id: cat.id,
                            version: cat.version,
                            reference: cat.reference,
                            name: data.name,
                        }),
                    );
                }*/
    };
    //endregion

    return (
        <div className='catalog-info-main'>
            {saveSucess && <Toaster validate={true} visibility={saveSucess} title='Sauvegarde de catalogue'
                                    message='Le catalogue à bien été sauvegardé'
                                    onClose={() => setSavaSucess(false)} />}
            <div className='catalog-info-body'>
                {finish && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='form-bloc'>
                            <div className='bloc-title'>INFORMATIONS</div>
                            <label htmlFor='name'>Nom</label>
                            <input
                                className='input'
                                type='text'
                                placeholder='Nom du catalogue'
                                {...register('name', {/*{ required: true }*/ })}
                            />
                            {errors && formErrors(errors.name, 20)}
                        </div>

                        {/*     <div className='form-bloc'>
                            <div className='bloc-title'>VISIBILITÉS</div>
                            <label htmlFor='visibility'>Visible sur</label>
                            <Multiselect
                                className='combo'
                                data={ visibilityOptions }
                                defaultValue={ defaultVisibilityIds }
                                onChange={ (e) => {
                                    setVisibilityErr('');
                                    // @ts-ignore
                                    setVisibilityArray(e);
                                } }
                                dataKey='id'
                                textField='label'
                                placeholder='Définir la visibilité'
                            />
                            { visibilityErr.length > 0 && <span>{ visibilityErr }</span> }
                        </div>
                        <div className='form-bloc'>
                            <div className='bloc-title'>DESTINATAIRES</div>
                            <label htmlFor='width'>Rôle</label>
                            <Multiselect
                                defaultValue={ defaultRoleIds }
                                onChange={ (e) => {
                                    setRoleErr('');
                                    setValue('role', e);
                                } }
                                dataKey='id'
                                className='combo'
                                data={ roles.payload.content }
                                textField='label'
                                placeholder='Définir les rôles'
                            />
                            { roleErr.length > 0 && <span>{ visibilityErr }</span> }
                        </div>
                        <div className='form-bloc'>
                            <label htmlFor='width'>Utilisateurs</label>
                            <Multiselect
                                defaultValue={ defaultUsersIds }
                                dataKey='id'
                                onChange={ (e) => {
                                    setUserErr('');
                                    setValue('users', e);
                                } }
                                className='combo'
                                data={ usersList.payload.content }
                                textField='firstname'
                                renderListItem={ ({ item }) => (
                                    <>
                                        <div className='opt-name'>
                                            <strong> { `${ item.lastname.toUpperCase() }` }</strong>
                                            <span>{ `${ item.firstname }` }</span>
                                            <span className='opt-email'>
                                            { `( ${ item.email } )` }
                                            </span>
                                        </div>

                                    </>
                                ) }
                                placeholder='Définir les utilisateurs'
                            />
                            { userErr.length > 0 && <span>{ visibilityErr }</span> }
                        </div>*/}
                        <div className='form-bloc'>
                            <label htmlFor='width'>Utilisateurs</label>
                            <Multiselect
                                defaultValue={defaultUsersIds}
                                dataKey='id'
                                onChange={(e) => {
                                    setUserErr('');
                                    setValue('users', e);
                                }}
                                className='combo'
                                data={usersList.payload.content}
                                textField='firstname'
                                renderListItem={({ item }) => (
                                    <>
                                        <div className='opt-name'>
                                            <strong> {`${item.lastname.toUpperCase()}`}</strong>
                                            <span>{`${item.firstname}`}</span>
                                            <span className='opt-email'>
                                            {`( ${item.email} )`}
                                            </span>
                                        </div>

                                    </>
                                )}
                                placeholder='Définir les utilisateurs'
                            />
                            {userErr.length > 0 && <span>{visibilityErr}</span>}
                        </div>
                    </form>
                )}
            </div>
            <div className='catalog-info-foot'>
                <button onClick={handleSubmit(onSubmit)}>Sauvegarder</button>
            </div>
        </div>
    );
};
export default CatalogInfo;
