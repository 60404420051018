import Layout from '../../uicomponents/layout/layout';
import MainPaper from '../../uicomponents/paper/main-paper';
import UpdateProviderForm from '../../uicomponents/update-provider-form/update-provider-form';

const UpdateSupplier = () => {
    return (
        <Layout labelBack="Quitter l'édition du fournisseur ?" title="Éditer un fournisseur" info="Voulez vous quitter l'édition du nouveau fournisseur ? ">
            <MainPaper>
                <UpdateProviderForm />
            </MainPaper>
        </Layout>
    );
};

export default UpdateSupplier;
