import './composable-preset-list.scss';
import CreateComposablePreset from '../create-composable-preset/create-composable-preset';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ComposablePresetBuild from '../composable-preset-build/composable-preset-build';
import { CompoundedModule } from '../../../../../../../../domain/domain';
import { useDispatch, useSelector } from 'react-redux';
import { CompoundedPresetListState, findCompoundedPresetsByCompositionId } from '../../../../../../../../services/reducers/product/compounded-preset-reducer/compounded.reducer';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../../../../domain/Params/params-interface';
import { RootState } from '../../../../../../../../services/root-reducer';
import CompoundedPresetTable from '../../../../../../../../components/table/products-table/compounded-preset-table/compounded-preset-table';

type ComposablePresetListProps = {
    openCreateModal: boolean;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
};
const ComposablePresetList = (props: ComposablePresetListProps) => {
    const dispatch = useDispatch();
    const { productReference } = useParams<Params>();

    const [presetDetail, setPresetDetail] = useState<boolean>(false);
    const [currentPreset, setCurrentPreset] = useState<CompoundedModule | undefined>(undefined);
    const [isMount, setIsMount] = useState(false);

    const compoundedPresets = useSelector<RootState, CompoundedPresetListState>((state) => state.findCompoundedPresets);

    // useEffect(() => {
    //     !isMount && productReference && dispatch(findCompoundedPresetsByCompositionId(productReference));
    //     return () => setIsMount(true);
    // }, []);

    useEffect(() => {
        productReference && dispatch(findCompoundedPresetsByCompositionId(productReference));
    }, [presetDetail]);

    return (
        <div className="ComposablePresetList-main">
            {props.openCreateModal && <CreateComposablePreset setCurrentPreset={setCurrentPreset} setOpenPresetDetail={setPresetDetail} setOpenModal={props.setOpenModal} />}
            {presetDetail && currentPreset && <ComposablePresetBuild setCurrentPreset={setCurrentPreset} currentPreset={currentPreset} setOpenPresetDetail={setPresetDetail} />}
            <CompoundedPresetTable setCurrentPreset={setCurrentPreset} setOpenPresetDetail={setPresetDetail} compoundedPresets={compoundedPresets} />
        </div>
    );
};
export default ComposablePresetList;
