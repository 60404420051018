import MdfApiResponse from '../../../../domain/common/generic';
import { ConstructionRule, ProductRuleResponse } from '../../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import rulesService from '../../../../api/services/rules/rules.service';
import ConstructionRuleSercice, {
    ConstructionRulesForm,
} from '../../../../api/services/rules/construction-rule.sercice';


export type ConstructionRuleState = {
    isLoading: false;
    payload: MdfApiResponse<ConstructionRule>;
};
const initialConstructionRuleState: ConstructionRuleState = {
    isLoading: false,
    payload: {
        errors: [],
        warnings: [],
        content: {
            id: 0, version: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
            type: 'MANDATORY', name: '',
            collectionReference: '',
            tagList: [],
        },

    },
};
export type ConstructionRuleListState = {
    isLoading: false;
    payload: MdfApiResponse<ConstructionRule[]>;
};
const initialConstructionRulesListState: ConstructionRuleListState = {
    isLoading: false,
    payload: {
        errors: [],
        warnings: [],
        content: [],

    },
};

//<editor-fold desc="find-construction-Rules">
export const findConstructionRulesi = createAsyncThunk('find/Construction-Rules', async (collectionRef: string) => {
    const res = await ConstructionRuleSercice.findConstructionRules(collectionRef);
    return res;
});


export const findConstructionRulesSlice = createSlice({
    name: 'findConstructionRules',
    initialState: initialConstructionRulesListState,
    reducers: {
        resetFindConstructionRules: (state) => {
            state = initialConstructionRulesListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(findConstructionRulesi.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(findConstructionRulesi.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(findConstructionRulesi.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetFindConstructionRules } = findConstructionRulesSlice.actions;

//</editor-fold>

//<editor-fold desc="find-construction-Rules-by-compositionReference">
export const findConstructionRulesByCompoRef = createAsyncThunk('find/Construction-Rules-by-CompoRef', async (compositionRef: string) => {
    const res = await ConstructionRuleSercice.findConstructionRulesByCompositionReference(compositionRef);
    return res;
});


export const findConstructionRulesByCompoRefSlice = createSlice({
    name: 'findConstructionRulesByCompoRef',
    initialState: initialConstructionRulesListState,
    reducers: {
        resetFindConstructionRulesByCompoRef: (state) => {
            state = initialConstructionRulesListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(findConstructionRulesByCompoRef.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(findConstructionRulesByCompoRef.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(findConstructionRulesByCompoRef.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetFindConstructionRulesByCompoRef } = findConstructionRulesByCompoRefSlice.actions;

//</editor-fold>

//<editor-fold desc="find-construction-Rule-by-id">
export const findConstructionRuleById = createAsyncThunk('find/Construction-Rules-ById', async (ruleId: number) => {
    const res = await ConstructionRuleSercice.findConstructionRuleById(ruleId);
    return res;
});
export const findConstructionRuleByIdSlice = createSlice({
    name: 'findConstructionRuleById',
    initialState: initialConstructionRuleState,
    reducers: {
        resetFindConstructionRuleById: (state) => {
            state = initialConstructionRuleState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(findConstructionRuleById.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(findConstructionRuleById.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(findConstructionRuleById.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetFindConstructionRuleById } = findConstructionRuleByIdSlice.actions;

//</editor-fold>

//<editor-fold desc="find-construction-Rules">
export const addConstructionRules = createAsyncThunk('add/Construction-Rules', async (form: ConstructionRulesForm) => {
    const res = await ConstructionRuleSercice.addConstructionRules(form);
    return res;
});

export const addConstructionRulesSlice = createSlice({
    name: 'addConstructionRules',
    initialState: initialConstructionRuleState,
    reducers: {
        resetAddConstructionRules: (state) => {
            state = initialConstructionRuleState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addConstructionRules.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(addConstructionRules.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(addConstructionRules.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetAddConstructionRules } = addConstructionRulesSlice.actions;

//</editor-fold>

//<editor-fold desc="delete-construction-Rule">
export const deleteConstructionRule = createAsyncThunk('delete/Construction-Rule', async (ruleId: number) => {
    const res = await ConstructionRuleSercice.deleteConstructionRule(ruleId);
    return res;
});
export const deleteConstructionRuleSlice = createSlice({
    name: 'deleteConstructionRule',
    initialState: initialConstructionRuleState,
    reducers: {
        resetDeleteConstructionRule: (state) => {
            state = initialConstructionRuleState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteConstructionRule.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteConstructionRule.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteConstructionRule.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteConstructionRule } = deleteConstructionRuleSlice.actions;

//</editor-fold>

//<editor-fold desc="associate-construction-Rule">
export const associateConstructionRule = createAsyncThunk('associate/Construction-Rule', async (data: { compositionRef: string, ruleIdsList: number[] }) => {
    const res = await ConstructionRuleSercice.associateConstructionRules(data.compositionRef, data.ruleIdsList);
    return res;
});
export const associateConstructionRuleSlice = createSlice({
    name: 'associateConstructionRule',
    initialState: initialConstructionRuleState,
    reducers: {
        resetAssociateConstructionRule: (state) => {
            state = initialConstructionRuleState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(associateConstructionRule.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(associateConstructionRule.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(associateConstructionRule.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetAssociateConstructionRule } = associateConstructionRuleSlice.actions;

//</editor-fold>

//<editor-fold desc="associate-construction-Rule">
export const dissociateConstructionRule = createAsyncThunk('dissociate/Construction-Rule', async (data: { compositionRef: string, ruleId: number }) => {
    const res = await ConstructionRuleSercice.dissociateConstructionRule(data.compositionRef, data.ruleId);
    return res;
});
export const dissociateConstructionRuleSlice = createSlice({
    name: 'dissociateConstructionRule',
    initialState: initialConstructionRuleState,
    reducers: {
        resetDissociateConstructionRule: (state) => {
            state = initialConstructionRuleState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(dissociateConstructionRule.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(dissociateConstructionRule.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(dissociateConstructionRule.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDissociateConstructionRule } = dissociateConstructionRuleSlice.actions;

//</editor-fold>