import React, { useEffect, useState } from 'react';
import Textfield from '../textfield/textfield';
import apiClient from '../../api/api-client';
import { GET_ROLES } from '../../api/endpoints/role/role.endpoint';
import { resetCreateNews } from '../../services/reducers/news/news-category-creation.slice';
import { useDispatch, useSelector } from 'react-redux';
import MdfPreconditions from '../../utils/MdfPreconditions';
import MdfApiResponse from '../../domain/common/generic';

import { getResponsibleList, ResponsibleState } from '../../services/reducers/users/users.slice';
import { RootState } from '../../services/root-reducer';
import { CreateUserType } from '../create-user-form/create-user.type';
import { Role, User } from '../../domain/domain';

import MainPaper from '../paper/main-paper';
import './update-user.scss';
import errorLogo from '../../asset/Layer 2.svg';
import '../create-user-form/styles.scss';

type CreateUserFormProps = {
    onSubmit: (form: CreateUserType) => void;
    user: User;

    error: string;
    setError: (error: string) => void;

    isEmailError: boolean;
    setIsEmailError: (isEmailError: boolean) => void;

    emailErrorMessage: string;
    setEmailErrorMessage: (emailErrorMessage: string) => void;
};

const UpdateUserByAdminForm = ({
                                   onSubmit,
                                   user,

                                   error,
                                   setError,

                                   isEmailError,
                                   setIsEmailError,

                                   emailErrorMessage,

                                   setEmailErrorMessage,
                               }: CreateUserFormProps) => {
    const [firstname, setFirstname] = useState(user.firstname);
    const [isFirstnameError, setIsFirstnameError] = useState<boolean>(false);
    const [firstnameErrorMessage, setFirstnameErrorMessage] = useState<string>('');

    const [lastname, setName] = useState(user.lastname);
    const [isLastnameError, setIsLastnameError] = useState<boolean>(false);
    const [lastnameErrorMessage, setLastnameErrorMessage] = useState<string>('');

    const [email, setEmail] = useState(user.email);

    const [roleList, setRoleList] = useState([]);
    const [role, setRole] = useState<string[]>(user.roles.map((e) => e.label));
    const [singleRole, setSingleRole] = useState<string[]>(['']);
    const [responsible, setResponsible] = useState('');

    const [, setIsResponsableError] = useState<boolean>(false);
    const [, setResponsableErrorMessage] = useState<string>('');

    const [, setIsRoleErrorMessage] = useState<string>('');
    const [, setIsRoleError] = useState<boolean>(false);

    const dispatch = useDispatch();

    const { responsibleList } = useSelector<RootState, ResponsibleState>((state) => state.getResponsibleList);

    const getRoleList = async () => {
        const res = await apiClient(GET_ROLES, undefined, undefined, 'GET');
        MdfPreconditions.continueIfNoErrors(res as MdfApiResponse<Role[]>, [], dispatch, () => {
            setRoleList(res.content);
        });
    };

    const findUserResponsible = (responsibleId: number) => {
        const found = responsibleList.content.find((el) => el.id === responsibleId);
        if (!found) return;
        setResponsible(`${ found.firstname } ${ found.lastname }`);
    };

    useEffect(() => {
        dispatch(getResponsibleList());
        dispatch(resetCreateNews());
        getRoleList();
    }, []);

    useEffect(() => {
        if (responsibleList.content.length > 0 && responsibleList.errors.length === 0) {
            findUserResponsible(user.responsibleId);
        }
    }, [responsibleList]);

    const checkErrors = () => {
        let emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
        if (email.length < 1 || lastname.length < 1 || firstname.length < 1 || responsible.length < 1 || role.length < 1) {
            if (email.length < 1) {
                setIsEmailError(true);
                setEmailErrorMessage('L\'email doit être specifié');
            }
            if (!emailRegex.test(email) && email.length > 0) {
                setIsEmailError(true);
                setEmailErrorMessage('L\'email n\'est pas valide');
            }
            if (firstname.length < 1) {
                setIsFirstnameError(true);
                setFirstnameErrorMessage('Le Prenom doit être specifié');
            }
            if (lastname.length < 1) {
                setIsLastnameError(true);
                setLastnameErrorMessage('Le Nom doit être specifié');
            }
            if (responsible.length < 1) {
                setIsResponsableError(true);
                setResponsableErrorMessage('Le responsable doit être specifié');
            }
            if (role.length < 1) {
                setIsRoleError(true);
                setIsRoleErrorMessage('Le role doit être specifié');
            }
            setError('Une erreur inconnue est survenue');
            return false;
        }
        return true;
    };

    const getResponsibleId = () => {
        return responsibleList.content
            ? responsibleList.content.filter((e) => e.firstname + ' ' + e.lastname === responsible)[0] && responsibleList.content.filter((e) => e.firstname + ' ' + e.lastname === responsible)[0].id
            : 0;
    };

    const handleUserSubmit = () => {
        if (checkErrors()) {
            onSubmit({
                email: email,
                firstname: firstname,
                lastname: lastname,
                roles: role,
                responsibleId: getResponsibleId(),
            });
        }
    };

    useEffect(() => {
        const s = role.filter((el) => el !== 'Utilisateur');
        setSingleRole(s);
    }, [role]);

    return (
        <MainPaper>
            <div className='user-form-main'>
                <div className='user-form'>
                    <Textfield
                        isError={ isLastnameError }
                        errorMessage={ lastnameErrorMessage }
                        label='NOM'
                        name=''
                        value={ lastname }
                        placeholder='Entrez votre Nom'
                        onChange={ (value) => {
                            setName(value);
                        } }
                    />
                    <Textfield
                        isError={ isFirstnameError }
                        errorMessage={ firstnameErrorMessage }
                        label='PRENOM'
                        name=''
                        value={ firstname }
                        placeholder='Entrez votre Prenom'
                        onChange={ (value) => {
                            setFirstname(value);
                        } }
                    />
                    <Textfield
                        isError={ isEmailError }
                        errorMessage={ emailErrorMessage }
                        label='EMAIL'
                        name='email'
                        value={ email }
                        placeholder='Entrer un email'
                        onChange={ (value) => {
                            setEmail(value);
                        } }
                    />
                    <div className='user-form-select'>
                        <label> RESPONSABLE </label>
                        <select
                            className='form-control'
                            onChange={ (e) => {
                                setResponsible(e.target.value);
                            } }
                            value={ responsible }
                        >
                            <option defaultValue={ responsible }>Responsable</option>
                            { responsibleList.content.map((el) => (
                                <option key={ el.id }>{ el.firstname + ' ' + el.lastname }</option>
                            )) }
                        </select>
                    </div>

                    <div className='user-form-select'>
                        <label>RÔLE(S)</label>
                        <select
                            className='form-control'
                            onChange={ (e) => {
                                setIsRoleErrorMessage('');
                                setIsRoleError(false);
                                let value = Array.from(e.target.selectedOptions, (option) => option.value);
                                setRole(value);
                            } }
                        >
                            { roleList.map((el: any) => {
                                return (
                                    <option selected={ el.label === singleRole[0] } key={ el.id }>{ el.label }</option>
                                );
                            }) }
                        </select>
                    </div>
                </div>
                <div className='user-form-info'>
                    <div className='user-error-message'>
                        { error && (
                            <div className='error-banner'>
                                <img alt="logo d'erreur" src={ errorLogo } />
                                <div>{ error }</div>
                            </div>
                        ) }
                    </div>

                    <div className='update-user-button-box'>
                        <button type='button' className='btn btn-primary' onClick={ handleUserSubmit }>
                            Confirmer les changements
                        </button>
                    </div>
                </div>
            </div>
        </MainPaper>
    );
};

export default UpdateUserByAdminForm;
