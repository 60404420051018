import apiClient from '../../api-client';

import { RoomDTO } from '../../../domain/domain';
import {
    ADD_ROOM,
    DELETE_ROOM,
    DUPLICATE_ROOM, EDIT_ROOM_DESCRIPTION,
    GET_ROOM,
    GET_ROOM_MODEL,
    UPDATE_ROOM_NAME,
} from '../../endpoints/room/room';

const roomsService = {
    getRoomModels: async () => apiClient(GET_ROOM_MODEL(), undefined, undefined, 'GET'),
    addRoom: async (projectId: number | undefined, templateId: number, roomDTO: RoomDTO) => apiClient(ADD_ROOM(projectId, templateId, roomDTO), roomDTO, undefined, 'POST'),
    getRoom: async (projectId: number | undefined, roomUuid: string | undefined) => apiClient(GET_ROOM(projectId, roomUuid), undefined, undefined, 'GET'),
    deleteRoom: async (projectId: number | undefined, roomName: string | undefined) => apiClient(DELETE_ROOM(projectId, roomName), undefined, undefined, 'DELETE'),
    duplicateRoom: async (projectId: number | undefined, roomName: string | undefined) => apiClient(DUPLICATE_ROOM(projectId, roomName), undefined, undefined, 'POST'),
    updateRoomName: async (projectId: number | undefined, roomId: string | undefined, newName: string | undefined) => apiClient(UPDATE_ROOM_NAME(projectId, roomId, newName), undefined, undefined, 'PUT'),
    editRoomDesc: async (projectId: number | undefined, roomId: string | undefined, data: { description: string }) => apiClient(EDIT_ROOM_DESCRIPTION(projectId, roomId), data, undefined, 'PUT'),
};
export default roomsService;
