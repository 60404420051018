import './role-row.scss';
import React, { useState } from 'react';
import { RoleWithNumberOfUser } from '../../../domain/domain';
import trash from '../../../asset/poubelle-red.svg';
import trashDisabled from '../../../asset/poubelle-disabled.svg';
import editDisabled from '../../../asset/Editer-blanc.svg';
import edit from '../../../asset/Editer.svg';
import editBlue from '../../../asset/EditerBleu.svg';
import { useHistory } from 'react-router-dom';
import HoverIcon from '../../hover-icon/hover-icon';

type UserRowProps = {
    item: RoleWithNumberOfUser;
    onDeleteRole: (roleId: number) => void;
};

const RoleRow = ({ item, onDeleteRole }: UserRowProps) => {
    const ROLE_USER = 'Utilisateur';
    const ROLE_ADMIN = 'Administrateur';
    const ROLE_STORE_MANAGER = 'Responsable magasin';
    const ROLE_SELLER = 'Vendeur';
    const [showTooltip, setShowTooltip] = useState(false);
    const [message, setMessage] = useState('');
    const history = useHistory();

    const isDisabled = (roleName: string) => {
        if (roleName === ROLE_USER || roleName === ROLE_ADMIN || roleName === ROLE_STORE_MANAGER || roleName === ROLE_SELLER) {
            return true;
        }
        return false;
    };

    const onMouseEnter = (nbr: number, label: string) => {
        if (isDisabled(label)) {
            setShowTooltip(true);
        } else if (!isDisabled(label) && nbr > 0) {
            setShowTooltip(true);
            setMessage('Ce rôle est affecté à des utilisateurs. Il ne peut pas être supprimé');
        } else {
            setShowTooltip(false);
            setMessage('');
        }
    };

    return (
        <tr className="role-row">
            <td className={`${isDisabled(item.roleLabel) || item.number > 0 ? 'role-row__label-disabled' : 'role-row__label'}`}>{item.roleLabel}</td>
            <td className="role-row__number">{item.number}</td>

            <td>
                <button disabled={isDisabled(item.roleLabel)} style={{ background: 'Transparent', border: 'none' }} onClick={() => history.push(`/create-role/${item.roleId}`)}>
                    {isDisabled(item.roleLabel) ? <img className="role__trash" alt="logo poubelle désactivé" src={editDisabled} /> : <HoverIcon icon={edit} iconHover={editBlue} title="Modifier" />}
                </button>

                <button disabled={isDisabled(item.roleLabel) || item.number > 0} style={{ background: 'Transparent', border: 'none' }} onClick={() => onDeleteRole(item.roleId)}>
                    {isDisabled(item.roleLabel) || item.number > 0 ? (
                        <img className="role__trash" alt="logo poubelle désactivé" src={trashDisabled} />
                    ) : (
                        <img className="role__trash" alt="logo poubelle" src={trash} />
                    )}
                </button>

                {showTooltip && <div className="role-row__tooltip">{message}</div>}
            </td>
        </tr>
    );
};

export default RoleRow;
