import CustomModal from '../modal/custom-modal/custom-modal';
import React, { useEffect, useState } from 'react';
import Layout from '../layout/layout';
import MainPaper from '../paper/main-paper';
import Textfield from '../textfield/textfield';

import { Button } from '../button/button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import { getMe, GetMeState } from '../../services/reducers/users/users.slice';
import { Role, User } from '../../domain/domain';
import { updateUser } from '../../services/reducers/update-user/update-user.slice';
import { setToasterVisible } from '../../services/reducers/toaster-reducer/toaster.slice';
import { useHistory } from 'react-router-dom';
import './update-user.scss';
import { CreateUserType } from '../create-user-form/create-user.type';
import { initialUser } from '../../pages/stores/add-users-to-store/add-users-to-store';
import MdfApiResponse from '../../domain/common/generic';


type UserSelector = {
    isLoading: boolean;
    isRejected: boolean;
    payload: MdfApiResponse<User | null>;
};

const UpdateUser = () => {
    const userInfo = localStorage.getItem('user');
    const currentUser = userInfo && JSON.parse(userInfo);

    const [openUser, setopenUser] = useState<boolean>(false);
    const [user, setUser] = useState<User>(initialUser);

    const [lastnameErrMsg, setLastnameErrMsg] = useState<string>('');
    const [firstnameErrMsg, setFirstnameErrMsg] = useState<string>('');

    const [isLastnameErr, setIsLastnameErr] = useState<boolean>(false);
    const [isFirstnameErr, setIsFirstnameErr] = useState<boolean>(false);
    const [email] = useState(currentUser.email);
    const [lastname, setLastname] = useState(currentUser.lastname);
    const [firstname, setFirstname] = useState(currentUser.firstname);
    const [isMount, setIsMount] = useState(false);

    const [role] = useState<Role[]>(currentUser.roles);
    const history = useHistory();

    const dispatch = useDispatch();
    const { payload } = useSelector<RootState, GetMeState>((state) => state.getMe);

    useEffect(() => {
        !isMount && dispatch(getMe());

    }, []);
    useEffect(() => {
        !isMount && payload.content && setUser(payload.content.user);
        return () => setIsMount(true);
    }, [payload]);

    const updateUserInfo: CreateUserType = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        roles: role.map((r) => r.label),
        responsibleId: payload.content?.user.responsibleId,
    };

    const handleUserSubmit = () => {
        if (firstname === '') {
            setIsFirstnameErr(true);
            setFirstnameErrMsg('le Prenom doit être specifié');
        } else if (lastname === '') {
            setIsLastnameErr(true);
            setLastnameErrMsg('le Nom doit être specifié');
        } else {
            dispatch(
                updateUser({
                    userId: currentUser.id || user?.id,
                    createUserForm: updateUserInfo,
                }),
            );
            history.push('/home/users');
            if (payload.content) {
                const updateuser: User = {
                    id: payload.content.user.id,
                    firstname: firstname,
                    lastname: lastname,
                    email: email,
                    roles: payload.content?.user.roles,
                    createdAt: payload.content.user.createdAt,
                    updatedAt: new Date(),
                    version: payload.content.user.version,
                    responsibleId: payload.content.user.responsibleId,
                    status: payload.content.user.status,
                    removed: payload.content.user.removed,
                    connectionAt: payload.content.user.connectionAt,
                    resetPasswordToken: payload.content.user.resetPasswordToken,
                };
                localStorage.setItem('user', JSON.stringify(updateuser));
            }
        }
    };

    return (
        <Layout title='Profil' labelBack="Quitter la modification d'utilisateur"
                info='Les modifications ne serons pas sauvgardées'>
            <MainPaper>
                { openUser && <CustomModal setOpen={ setopenUser } /> }
                <div className='update-user-info'>
                    <Textfield label='NOM' errorMessage={ lastnameErrMsg } isError={ isLastnameErr }
                               onChange={ (value) => setLastname(value) } type='text' value={ lastname } />
                    <Textfield label='PRENOM' errorMessage={ firstnameErrMsg } isError={ isFirstnameErr }
                               onChange={ (value) => setFirstname(value) } type='text' value={ firstname } />
                    <Textfield label='EMAIL' value='' placeholder={ `${ email } - Ce champ n'est pas modifiable` } />
                </div>
                <div className='update-user-password'>
                    MOT DE PASSE <span onClick={ () => setopenUser(true) }>Changer</span>
                </div>
                <div className='update-user-role'>
                    RÔLE(S)
                    <div className='update-user-role-item-bloc'>
                        { currentUser
                            ? currentUser.roles.map((el: Role, i: number) => {
                                return (
                                    <div className='update-user-role-item' key={ i }>
                                        { el.label }
                                    </div>
                                );
                            })
                            : user?.roles.map((el: Role, i: number) => {
                                return (
                                    <div className='update-user-role-item' key={ i }>
                                        { el.label }
                                    </div>
                                );
                            }) }
                    </div>
                </div>
                <div className='update-user-button'>
                    <Button onClick={ handleUserSubmit } label='Modifier mon profil' primary />
                </div>
            </MainPaper>
        </Layout>
    );
};
export default UpdateUser;
