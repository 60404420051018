import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../services/root-reducer';
import { MdfApiResponse, PresetTree, Product, Rule } from '../../../../domain/domain';
import duplicate from '../../../../asset/dupliquer.svg';
import trashRed from '../../../../asset/poubelle-red.svg';
import './product-preset-table.scss';
import SimpleModal from '../../../../uicomponents/modal/simple-modal/simple-modal';
import ProductPresetRow from './product-preset-row/product-preset-row';
import DuplicateModal from '../../../../uicomponents/modal/duplicate-modal/duplicate-modal';
import { ConfiguredProductTreeListState } from '../../../../services/reducers/product/configured-product/configured-product.reducers';
import moment from 'moment';
import { buildPresetConf } from '../../../../utils/buildPresetConf';
import configuredProductService from '../../../../api/services/products/configured-product/configured-product.service';

type ProductPreset = {
    setOpenCreateModalSecondStep: (openCreateModalSecondStep: boolean) => void;
    product: Product;
    presetRef: string;
    setPresetRef: (presetRef: string) => void;
    setPresetToEdit: (preset: PresetTree | undefined) => void;
    refreshPresetName: (newValue: string) => void;
    preset: PresetTree | undefined;
};
export type Conf = {
    partName: string;
    partReference: string;
    itemAtom: { partItemName: string; atomName: string }[];
    zoneColorisList: ZoneColoris[];
    mappingName: string;
    ruleList?: Rule[]
};
export type PresetConf = {
    presetId: number;
    presetName: string;
    presetConf: Conf[];
};

type ZoneColoris = { zoneName: string; defaultColoris: string };
const ProductPresetTable = (props: ProductPreset) => {
    const [configuredProductTreeList, setConfiguredProductTreeList] = useState<PresetTree[]>([]);
    const presetHeaders = ['NOM', 'CONFIGURATION', 'PAR DEFAUT', 'ACTIONS'];
    const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [newName, setNewName] = useState('');
    const [presetList, setPresetList] = useState<PresetTree[]>([]);
    const getConfiguredProductTreeListRes = useSelector<RootState, ConfiguredProductTreeListState>((state) => state.getConfiguredProductTreeList);
    const [conf, setConf] = useState<PresetConf[]>([]);
    const [RefAndNameList, setRefAndNameList] = useState<{ partRef: string; mappingName: string }[]>([]);

    const refresh = () => {
        configuredProductService.getConfiguredProductTreeList(props.product.reference).then((res) => {
            const response = res as MdfApiResponse<PresetTree[]>;
            setConfiguredProductTreeList(response.content);
        });
    };

    const duplicateProduct = () => {
        configuredProductService.duplicateConfiguredProduct(props.presetRef, newName).then(() => {
            refresh();
            setOpenDuplicateModal(false);
        });
    };

    const deleteProduct = () => {
        configuredProductService.deleteConfiguredProduct(props.presetRef).then(() => {
            refresh();
            setOpenDeleteModal(false);
        });
    };

    useEffect(() => {
        if (
            !getConfiguredProductTreeListRes ||
            !getConfiguredProductTreeListRes.payload ||
            !getConfiguredProductTreeListRes.payload.content ||
            getConfiguredProductTreeListRes.payload.content.length === 0
        ) {
            refresh();
        } else {
            setConfiguredProductTreeList(getConfiguredProductTreeListRes.payload.content);
        }
    }, [getConfiguredProductTreeListRes]);

    useEffect(() => {
        if (configuredProductTreeList && configuredProductTreeList.length > 1) {
            const presetListSorted = [...configuredProductTreeList].sort((a, b) => {
                const date1 = moment(a.createdAt);
                const date2 = moment(b.createdAt);
                return date2.diff(date1);
            });
            setPresetList(presetListSorted);
        } else if (configuredProductTreeList) {
            setPresetList(configuredProductTreeList);
        }
    }, [configuredProductTreeList]);
    useEffect(() => {
        let newPresetList: PresetTree[] = [];
        let confList: PresetConf[] = [];
        presetList.forEach((_: PresetTree, i) => {
            const uniquePreset = presetList.reduce((accumulator: PresetTree[], current) => {
                if (!accumulator.find((item) => item.id === current.id)) {
                    accumulator.push(current);
                }
                return accumulator;
            }, []);
            newPresetList = uniquePreset;
        });

        newPresetList.forEach((np: PresetTree) => {
            const f = confList.find((pre: PresetConf) => pre.presetId === np.id);
            f
                ? confList.push({
                    presetName: np.product.name,
                    presetId: np.id,
                    presetConf: buildPresetConf(np.configuration),
                })
                : confList.push({
                    presetName: np.product.name,
                    presetId: np.id,
                    presetConf: buildPresetConf(np.configuration),
                });
        });
        setConf(confList);
    }, [presetList]);

    return (
        <table className='preset-table-main'>
            <>
                <thead className='preset-table-head'>
                <tr>
                    {presetHeaders.map((header, i) => {
                        return <th key={i}>{header}</th>;
                    })}
                </tr>
                </thead>
                <br />
                <tbody className='preset-table-body'>
                <>
                    {openDuplicateModal && (
                        <DuplicateModal
                            icon={duplicate}
                            actionLabel='Dupliquer'
                            closeLabel='Annuler'
                            title='Nommer votre copie'
                            info='Cette action dupliquera ce preset'
                            deleteOrValide={false}
                            actionOnclick={() => {
                                if (!(newName === '')) {
                                    props.product && duplicateProduct();
                                }
                            }}
                            closeOnclick={() => setOpenDuplicateModal(false)}
                            setRefreshPresetName={setNewName}
                        />
                    )}
                    {openDeleteModal && (
                        <SimpleModal
                            icon={trashRed}
                            actionLabel='Supprimer'
                            closeLabel='Annuler'
                            title='Supprimer un preset'
                            info='Cette action supprimera ce preset'
                            deleteOrValide={true}
                            actionOnclick={() => {
                                props.product && deleteProduct();
                            }}
                            closeOnclick={() => setOpenDeleteModal(false)}
                        />
                    )}
                    {(!presetList || presetList.length === 0) && <div>Aucun preset de configuration</div>}
                </>
                {presetList &&
                    presetList.map((preset: PresetTree) => {
                        return (
                            <tr key={preset.id}>
                                <ProductPresetRow
                                    conf={conf}
                                    preset={preset}
                                    setPresetRef={props.setPresetRef}
                                    setPresetToEdit={props.setPresetToEdit}
                                    setOpenDuplicateModal={setOpenDuplicateModal}
                                    setOpenDeleteModal={setOpenDeleteModal}
                                    setOpenCreateModalSecondStep={props.setOpenCreateModalSecondStep}
                                />
                            </tr>
                        );
                    })}
                </tbody>
            </>
        </table>
    );
};

export default ProductPresetTable;
