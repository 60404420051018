import Layout from '../../../uicomponents/layout/layout';
import { useEffect, useState } from 'react';
import MainPaper from '../../../uicomponents/paper/main-paper';
import { useHistory, useParams } from 'react-router-dom';
import {
    getFinish,
    GetFinishState,
    UpdateFinishState,
} from '../../../services/reducers/suppliers/finish/finishes.slice';
import { useDispatch, useSelector } from 'react-redux';
import { Params } from '../../../domain/Params/params-interface';
import { RootState } from '../../../services/root-reducer';
import UpdateFinishForm from '../../../components/form/finishes/update-finish-form/update-finish-form';


const UpdateFinish = () => {
    const dispatch = useDispatch();
    const { finishId, supplierName } = useParams<Params>();
    const history = useHistory();
    const { payload } = useSelector<RootState, GetFinishState>((state) => state.getFinish);
    const updateFinishRes = useSelector<RootState, UpdateFinishState>((state) => state.updateFinish);
    const [finishName, setFinishName] = useState('');

    useEffect(() => {
        finishId && dispatch(getFinish(parseInt(finishId)));
    }, []);

    return (
        <Layout
            labelBack='Quitter la modification de finition'
            info='Les modifications ne seront pas sauvegardées'
            finishName={ finishName }
            setFinishName={ setFinishName }
            pathUrl='supplier'
            title='Modifier la finition'
        >
            <MainPaper>
                { finishId &&
                    <UpdateFinishForm
                        finishId={ parseInt(finishId) }
                        payload={ payload } /> }
            </MainPaper>
        </Layout>
    );
};
export default UpdateFinish;
