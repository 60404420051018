import './composition.scss';
import trash from '../../../../../asset/poubelle-red.svg';
import { useEffect, useState } from 'react';
import CreateRuleComposition from '../create-rule-composition/create-rule-composition';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConstructionRuleListState, ConstructionRuleState, deleteConstructionRule,
    findConstructionRulesi, resetDeleteConstructionRule,
} from '../../../../../services/reducers/product/rules/construction-rules.reducers';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../domain/Params/params-interface';
import { RootState } from '../../../../../services/root-reducer';
import { ConstructionRule } from '../../../../../domain/domain';
import SimpleModal from '../../../../../uicomponents/modal/simple-modal/simple-modal';


type CompositionProps = {
    ruleId: number,
    setRuleId: (ruleId: number) => void
    setCurrentRule: (rule: ConstructionRule) => void
    currentRule: ConstructionRule

};
const Composition = ({ ruleId, setCurrentRule, currentRule, setRuleId }: CompositionProps) => {
    const dispatch = useDispatch();
    const { collectionReference } = useParams<Params>();


    const [isMount, setIsmount] = useState(false);
    const [id, setId] = useState(0);
    const [openM, setOpenM] = useState(false);


    const [mandatoryRules, setMandatoryRules] = useState<ConstructionRule[]>([]);
    const [forbiddenRules, setForbiddenRules] = useState<ConstructionRule[]>([]);

    const constructionRules = useSelector<RootState, ConstructionRuleListState>(state => state.findConstructionRules);
    const deleteRule = useSelector<RootState, ConstructionRuleState>(state => state.deleteConstructionRule);

    useEffect(() => {
        !isMount && collectionReference && dispatch(findConstructionRulesi(collectionReference));
        return () => setIsmount(true);
    }, [collectionReference]);
    useEffect(() => {
        if (constructionRules.payload.content && constructionRules.payload.errors.length === 0) {
            setRuleId(0);
            const rules = constructionRules.payload.content;
            const mRules = rules.filter((r) => r.type === 'MANDATORY');
            const fRules = rules.filter((r) => r.type === 'IMPOSSIBLE');
            setForbiddenRules(fRules);
            setMandatoryRules(mRules);
        }
    }, [constructionRules]);
    useEffect(() => {
        if (deleteRule.payload.content.id !== 0 && deleteRule.payload.errors.length === 0) {
            collectionReference && dispatch(findConstructionRulesi(collectionReference));
            dispatch(resetDeleteConstructionRule());
            setId(0);
            setOpenM(false);
        }
    }, [deleteRule]);

    return (
        <div className='composition-main'>
            { openM && <SimpleModal
                icon={ trash }
                title='Supprimer la règle'
                info='Cette action supprimera la règle ainsi que toutes ses dépendances'
                closeOnclick={ () => {
                    setOpenM(false);
                    setId(0);
                } }
                actionOnclick={ () => dispatch(deleteConstructionRule(id)) }
                deleteOrValide={ true }
                closeLabel='Annuler'
                actionLabel='Supprimer' />
            }
            <div className='top'>
                <div>RÈGLES D'OBLIGATIONS</div>
                <div>RÈGLES D'INTERDICTIONS</div>
            </div>
            <div className='middle'>
                <div className='a-rule-list'>{
                    mandatoryRules.map((a) => {
                        return (
                            <div
                                onClick={ () => {
                                    setCurrentRule(a);
                                    setRuleId(a.id);
                                } }
                                className={ ruleId !== a.id ? 'a-rule' : 'a-rule focus' } key={ a.id }>
                                <span> { a.name }</span>
                                <img
                                    onClick={ () => {
                                        setId(a.id);
                                        setOpenM(true);
                                    } }
                                    alt='supprimer' src={ trash } />
                            </div>
                        );
                    })
                }</div>
                <div className='a-rule-list'>
                    {
                        forbiddenRules.map((a) => {
                            return (
                                <div
                                    onClick={ () => {
                                        setCurrentRule(a);
                                        setRuleId(a.id);
                                    } }
                                    className={ ruleId !== a.id ? 'a-rule' : 'a-rule focus' } key={ a.id }>
                                    <span> { a.name }</span>
                                    <img
                                        onClick={ () => {
                                            setId(a.id);
                                            setOpenM(true);
                                        } }
                                        alt='supprimer' src={ trash } />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className='bottom'>
                <div className='rules-compo'>
                    { ruleId !== 0
                        ?
                        <CreateRuleComposition currentRule={ currentRule } setRuleId={ setRuleId } ruleId={ ruleId } />
                        : 'Aucune règle sélectionnée ' }
                </div>
            </div>
        </div>
    );
};
export default Composition;