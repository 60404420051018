import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {ColorType} from "../ParamTypes/ColorType";
import {TextureType} from "../ParamTypes/TextureType";
import {BooleanType} from "../ParamTypes/BooleanType";
import {ButtonType} from "../ParamTypes/ButtonType";
import {InspectorType} from "../ParamTypes/InspectorType";
import {SliderData, SliderType} from "../ParamTypes/SliderType";

export class AO {

    public m_intensity: InspectorType;
    public m_aoMapShow: InspectorType;
    public m_aoMap: InspectorType;
    public m_aoMapFlipY: InspectorType;
    public m_aoMapClear: InspectorType;
    
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        this.m_uiFolder = this.m_uiFolder = m_tab.pages[0].addFolder({title: 'AO Map (BW) [no texture]', expanded: false});
        this.m_intensity = new SliderType(new SliderData(0.0,1.0,0.01), m_matParams, "aoMapIntensity", this.m_uiFolder);
        this.m_aoMapShow = new BooleanType("Show map on mesh", m_matParams, "aoMapShowOnMesh", this.m_uiFolder);
        this.m_aoMap = new TextureType(MapType.aoMap, m_matParams, "aoMap", this.m_uiFolder);
        this.m_aoMapFlipY = new BooleanType("Flip Y", m_matParams, "aoMapFlipY", this.m_uiFolder);
        this.m_aoMapClear = new ButtonType("Clear map", m_matParams, "", this.m_uiFolder);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_intensity.InitType();
        this.m_aoMapShow.InitType();
        this.m_aoMap.InitType();
        this.m_aoMapFlipY.InitType();
        this.m_aoMapClear.InitType();
    }
}