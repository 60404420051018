import './product-configurator.scss';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../../../../../services/root-reducer';
import { useDispatch, useSelector } from 'react-redux';
import edit from '../../../../../../asset/Editer.svg';
import { ProductCellValueTreeState } from '../../../../../../services/reducers/product/3DCells/3DCellValues.reducers';
import { Model3dTree, ModelTree, PartTree, PresetTree } from '../../../../../../domain/domain';
import { getConfigurationSectionList } from '../../../../../../services/reducers/configurateur-v2/configurateur-v2.slice';

type ProductConfiguratorProps = {
    preset: PresetTree | undefined;
    onClickModifier: (Part: PartTree) => void;
    selectedConfiguration: ModelTree[] | undefined;
};
type ZoneColoris = { i: number; partName: string; partRef: string; zone: { zoneName: string; colorName: string }[] };
const ProductConfigurator = (props: ProductConfiguratorProps) => {
    const dispatch = useDispatch();
    const getCellValuesTreeRes = useSelector<RootState, ProductCellValueTreeState>((state) => state.getCellValueTree);
    const [zoneColoris, setZoneColoris] = useState<ZoneColoris[]>([]);
    const [RefAndNameList, setRefAndNameList] = useState<{ partRef: string; mappingName: string }[]>([]);

    useEffect(() => {
        dispatch(getConfigurationSectionList());
    }, []);

    useEffect(() => {
        const newZoneColoris: ZoneColoris[] = [];
        props.selectedConfiguration &&
            props.selectedConfiguration.forEach((el, idxEl) => {
                if (el.model3d[0] && el.model3d[0].mappings && el.model3d[0].mappings.length !== 0) {
                    const f = newZoneColoris.find((m) => m.partName === el.atom.partName);
                    findMapping(el.model3d, el.atom.partReference);
                    !f &&
                        newZoneColoris.push({
                            i: idxEl,
                            partName: el.atom.partName,
                            partRef: el.atom.partReference,
                            zone: el.model3d[0].mappings[0].zoneList.map((z) => {
                                return {
                                    zoneName: z.name,
                                    colorName: z.defaultColori !== null ? `${z.defaultColori.name} ${z.defaultColori.code}` : 'Nul',
                                };
                            }),
                        });
                } else {
                    newZoneColoris.push({
                        i: idxEl,
                        partName: el.atom.partName,
                        partRef: el.atom.partReference,
                        zone: [{ zoneName: 'Pas de Zone', colorName: 'Pas de coloris' }],
                    });
                }
            });
        setZoneColoris(newZoneColoris);
    }, [props.selectedConfiguration]);
    const findMapping = (model3dList: Model3dTree[], ref: string) => {
        model3dList.forEach((mo) => {
            mo.mappings.forEach((ma) => {
                const find = RefAndNameList.find((f) => f.partRef === mo.partReference);
                !find &&
                    setRefAndNameList((prevState) => [
                        ...prevState,
                        {
                            partRef: mo.partReference,
                            mappingName: ma.name,
                        },
                    ]);
            });
        });
    };
    const returnName = (re: string) => {
        const findRef = RefAndNameList.map((res) => (res.partRef === re ? res.mappingName : ''));
        const clean = findRef.filter((f) => f.length > 0);
        return clean[0];
    };

    return (
        <div className="product-configurator-main">
            <div className="preset-name">{props.preset?.product.name.toUpperCase()}</div>
            {getCellValuesTreeRes.payload.content &&
                getCellValuesTreeRes.payload.content.partTreeList.map((part, index) => {
                    return (
                        <div className="product-configurator-partList" key={index}>
                            <div className="product-configurator-part">
                                <div className="partName">{part.name}</div>
                                <div className="s-bloc">
                                    <>
                                        {part.characteristics.findIndex((chara) => chara.name === 'UNIQUE') === -1 && ( // check if part contains no characteristic and no atoms => if nothing found, do not display "FORME"
                                            <div className="section-title">Forme</div>
                                        )}
                                        {props.selectedConfiguration &&
                                            props.selectedConfiguration.map((el, idxEl) => {
                                                if (el.atom.partName === part.name && el.atom.partItemName !== 'UNIQUE') {
                                                    return (
                                                        <React.Fragment key={idxEl}>
                                                            <div className="config-seller-atom" key={idxEl}>
                                                                <span className="atom-key">{el.atom.partItemName}</span>
                                                                <span className="atom-value"> : {el.atom.label}</span>
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                }
                                            })}
                                    </>
                                </div>
                                <div className="s-bloc">
                                    <div className="section-title">MATIÈRE</div>
                                    {zoneColoris.map((zc, i) => {
                                        return (
                                            <div className="zc-bloc" key={i}>
                                                {i === 0 && (
                                                    <div className="zc">
                                                        <span className="zone-h-name"> Habillage</span>
                                                        <span>{!returnName(part.reference) ? "Pas d'habillage" : returnName(part.reference)}</span>
                                                    </div>
                                                )}
                                                {zc.zone.map((z) => {
                                                    {
                                                        if (zc.partName === part.name) {
                                                            return (
                                                                <div className="zc" key={zc.i}>
                                                                    <span className="zone-name">{z.zoneName}</span>
                                                                    <span className="colori-name">{z.colorName}</span>
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <img
                                className="product-configurator-icon"
                                onClick={() => {
                                    props.onClickModifier(part);
                                }}
                                alt="editer"
                                src={edit}
                            />
                        </div>
                    );
                })}
        </div>
    );
};
export default ProductConfigurator;
