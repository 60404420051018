import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Engine } from "@lutithree/build/Engine";
import {CharacteristicStudioApp} from "../../Libraries/Studios/CharacteristicStudio/CharacteristicStudioApp";
import {EngineViewer} from "../../../application3D-common/Components/EngineViewer/EngineViewer";
import {RootState} from "../../../../services/redux/root-reducers";
import {
    CharacteristicStudioState,
    setStudio
} from "../../Redux/Reducers/characteristic-studio/CharacteristicStudioReducer";


const CharacteristicStudioViewer = () => {
    const dispatch = useDispatch();
    const { characteristicStudioApp } = useSelector<RootState, CharacteristicStudioState>((state) => state.characteristicStudioReducer);

    const buildApplication3d = (p_dom: HTMLDivElement) => {
        let engine = new Engine(p_dom);
        let enginePromise = engine.Initialize();
        enginePromise.then(() => {
            let game = new CharacteristicStudioApp(engine);
            engine.Start();
            dispatch(setStudio(game));
        });
    };

    const updateApplicationDomNode = (p_dom: HTMLDivElement) => {
        if (characteristicStudioApp && p_dom) {
            characteristicStudioApp.EngineService.UpdateDom(p_dom);
        }
    };

    return (
        <>
            <EngineViewer
                engine={ characteristicStudioApp ? characteristicStudioApp.EngineService : null }
                onDomNodeEnable={ (p_dom: HTMLDivElement) => buildApplication3d(p_dom) }
                onDomNodeUpdate={ (p_dom: HTMLDivElement) => updateApplicationDomNode(p_dom) }
            />
        </>
    );
};

export { CharacteristicStudioViewer }; 
