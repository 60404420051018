import './compounded-preset-table.scss';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CompoundedModule } from '../../../../domain/domain';
import {
    CompoundedPresetListState,
    deleteCompoundedPreset,
    findCompoundedPresetsByCompositionId,
    resetDeleteCompoundedPreset,
} from '../../../../services/reducers/product/compounded-preset-reducer/compounded.reducer';
import trashRed from '../../../../asset/poubelle-red.svg';
import logo from '../../../../asset/logo-mdf.jpeg';
import edit from '../../../../asset/Editer.svg';
import SimpleModal from '../../../../uicomponents/modal/simple-modal/simple-modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../services/root-reducer';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../domain/Params/params-interface';

type CompoundedPresetProps = {
    compoundedPresets: CompoundedPresetListState;
    setCurrentPreset: Dispatch<SetStateAction<CompoundedModule | undefined>>;
    setOpenPresetDetail: Dispatch<SetStateAction<boolean>>;
};
const CompoundedPresetTable = (props: CompoundedPresetProps) => {
    const dispatch = useDispatch();
    const { productReference } = useParams<Params>();

    const deletePresetRes = useSelector<
        RootState,
        {
            isLoading: boolean;
            fullfiled: boolean;
        }
    >((state) => state.deleteCompoundedPreset);

    const productHeader = ['NOM', 'CONFIGURATION', 'PAR DÉFAUT', 'ACTIONS'];
    const [clickedPreset, setClickedPreset] = useState<CompoundedModule | undefined>(undefined);

    useEffect(() => {
        if (deletePresetRes.fullfiled) {
            dispatch(resetDeleteCompoundedPreset());
            productReference && dispatch(findCompoundedPresetsByCompositionId(productReference));
            setClickedPreset(undefined);
        }
    }, [deletePresetRes]);

    const setCurrentPreset = (preset: CompoundedModule) => {
        props.setCurrentPreset(preset);
        props.setOpenPresetDetail(true);
    };
    return (
        <table className="CompoundedPresetTable-main">
            {clickedPreset && (
                <SimpleModal
                    icon={trashRed}
                    title="Supprimer un preset"
                    info="Cette acion supprimera ce preset ainsi que toutes ces dépendences"
                    closeOnclick={() => setClickedPreset(undefined)}
                    actionOnclick={() => dispatch(deleteCompoundedPreset(clickedPreset))}
                    deleteOrValide={true}
                    closeLabel="Annuler"
                    actionLabel="Supprimer"
                />
            )}
            <thead>
                {productHeader.map((ph, i) => (
                    <tr key={i}>
                        <th>{ph}</th>
                    </tr>
                ))}
            </thead>
            <tbody>
                {props.compoundedPresets.payload.content.map((preset: CompoundedModule) => {
                    return (
                        <tr key={preset.id}>
                            <td onClick={() => setCurrentPreset(preset)} className="products-table-name">
                                <img src={logo} alt="logo" />
                                <span>{preset.product ? preset.product.name : preset.name}</span>
                            </td>
                            <td onClick={() => setCurrentPreset(preset)}>
                                <span>en cours</span>
                            </td>
                            <td onClick={() => setCurrentPreset(preset)} className="products-table-radio">
                                <input type="radio" />
                            </td>
                            <td className="products-table-trash">
                                <img
                                    onClick={() => {
                                        setCurrentPreset(preset);
                                    }}
                                    alt="éditer"
                                    src={edit}
                                />
                                <img
                                    onClick={() => {
                                        setClickedPreset(preset);
                                    }}
                                    alt="logo poubelle"
                                    src={trashRed}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
export default CompoundedPresetTable;
