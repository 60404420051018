import './supplier-modal-delete.scss';
import trash from '../../../asset/poubelle-disabled.svg';
import { useState } from 'react';

type SuplierModalDeleteProps = {
    supplier: string;
    setOpenModal: (openModal: boolean) => void;
    onclickMethod: () => void;
};

const SuplierModalDelete = ({ supplier, setOpenModal, onclickMethod }: SuplierModalDeleteProps) => {
    const [name, setName] = useState('');
    return (
        <div className="supplier-modal-delete-main">
            <div className="supplier-modal-delete-paper">
                <div className="supplier-modal-delete-top" onClick={() => setOpenModal(false)}>
                    <span>X</span>
                </div>

                <div className="title-block">
                    <div className="ext-red">
                        <div className="int-red">
                            <img alt="icône" src={trash} />
                        </div>
                    </div>

                    <div className="title-right">
                        <div className="delete-title"> Supprimer le fournisseur {supplier} ?</div>
                        <div className="info">Le fournisseur et toutes ses dépendances disparaitront et ne seront pas récupérables. Tapez le nom du fournisseur pour le supprimer.</div>
                    </div>
                </div>

                <div className="delete-input-container">
                    <input onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="btn-box">
                    <button onClick={() => setOpenModal(false)} className="cancel">
                        Annuler
                    </button>
                    {name === supplier ? (
                        <button onClick={onclickMethod} className="action">
                            Supprimer
                        </button>
                    ) : (
                        <button className="disable">Supprimer</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SuplierModalDelete;
