import axios, { AxiosError, Method } from 'axios';
import MdfApiResponse from '../domain/common/generic';
import { getConfig } from './config';
import { RolePermissionType, User } from '../domain/domain';

const apiClient = (isBp: boolean) => {
    const axiosInstance = axios.create({
        baseURL: isBp ? getConfig().bpHostApi : getConfig().urlHostApi,
        responseType: 'json',
    });
    return axiosInstance;
};

const sendRequest = async (route: string, data: object | undefined | string, params: object | undefined, method: Method, contentType?: string, isBp = false) => {
    const token = localStorage.getItem('mdf-jwt');
    const client = apiClient(isBp);
    const user = localStorage.getItem('user');
    const u: User = user !== null && JSON.parse(user);

    try {
        const req = await client({
            url: route,
            method,
            headers: {
                where: 'backoffice',
                Authorization: `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                ...(contentType ? { 'Content-Type': contentType } : {}),
            },
            data,
            params,
        });
        return req.data;
    } catch (apiErr: AxiosError | any) {
        console.error('API ERROR', {
            route,
            data,
            params,
            method,
            apiErr,
        });
        let errorResponse: MdfApiResponse<unknown> = {
            warnings: [],
            errors: [{ code: 'G0001', message: 'an unexpected error occurred', detail: `${apiErr}` }],
            content: null,
        };
        if (!axios.isAxiosError(apiErr)) {
            return Promise.reject(apiErr);
        }

        if (apiErr && apiErr.response) {
            if (apiErr.response.status) {
                if (apiErr.response.status === 401 && !window.location.href.includes('/login')) {
                    localStorage.removeItem('mdf-jwt');
                    window.location.href = '/login';
                }
                if (apiErr.response.status === 403) {
                    const role = u.roles.map((r) => r);
                    const permission: RolePermissionType[] = role.map((r) => r.permissions.map((p) => p.name)).flatMap((p) => p);
                    if (permission.includes('ADMINISTRATION')) {
                        window.location.href = '/home/users';
                    }
                    if (permission.includes('BASE_PRODUIT')) {
                        window.location.href = '/catalogs';
                    }
                    if (permission.includes('NEWS_BACKOFFICE')) {
                        window.location.href = '/home/news';
                    } else if (permission.includes('STORE_MANAGER')) {
                        window.location.href = '/stores';
                    }
                }
            }
            if (apiErr.response.data) {
                errorResponse = apiErr.response.data;
            }
        }
        return Promise.reject(apiErr);
    }
};

export default sendRequest;
