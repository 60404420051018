import './product-rules-table.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/root-reducer';
import { ProductRulesState } from '../../../services/reducers/product/rules/rules.reducers';
import { Action, Rule, RuleType } from '../../../domain/domain';
import duplicate from '../../../asset/dupliquer.svg';
import trash from '../../../asset/poubelle-red.svg';
import { displayAtomsInCurrentRule } from '../../../utils/buildPresetConf';
import React  from 'react';
import { Conf } from '../products-table/product-presset/product-preset-table';
import { useParams } from 'react-router-dom';
import { Params } from '../../../domain/Params/params-interface';
import { OnDuplicateRule } from './onDuplicateRule';
import edit from '../../../asset/Editer.svg';


type DescriptionRulesProps = {
    confRulesList: any
    setRuleConfType: (ruleConfType: RuleType) => void
    setRuleRef: (ruleRef: string) => void
    setOpenDeleteModal: (openDeleteModal: boolean) => void
    setOpenUpdateModal: (openUpdateModal: boolean) => void
    setCurrentRule: (currentRule: Rule) => void

};

const DescriptionRules = (props: DescriptionRulesProps) => {
    const { productReference } = useParams<Params>();
    const headers = ['CONDITIONS', 'ACTIONS'];
    const rulesRes = useSelector<RootState, ProductRulesState>(state => state.getRules);
    const dispatch = useDispatch();

    const returnActionParms = (action: Action) => {
        return (
            <div className='action-detail'>
                {action.params.definedMaxHeight !== -1 && <span> <b style={{ fontWeight: 500 }}>Hauteur</b>{` : ${action.params.definedMaxHeight} cm`}</span>}
                {action.params.definedMaxLength !== -1 && <span><b style={{ fontWeight: 500 }}>Longueur</b>{` : ${action.params.definedMaxLength} cm`}</span>}
                {action.params.definedMaxWidth !== -1 && <span><b style={{ fontWeight: 500 }}>Largeur</b>{` : ${action.params.definedMaxWidth} cm`}</span>}
                {action.params.addToHeight !== -1 && <span><b style={{ fontWeight: 500 }}>Ajout à la hauteur</b>{` : ${action.params.addToHeight} cm`}</span>}
                {action.params.addToLength !== -1 && <span><b style={{ fontWeight: 500 }}>Ajout à la longueur</b>{` : ${action.params.addToLength} cm`}</span>}
                {action.params.addToWidth !== -1 && <span><b style={{ fontWeight: 500 }}>Ajout à la largeur</b>{` : ${action.params.addToWidth} cm`}</span>}
            </div>
        );

    };

    return (
        <table className='products-rules-table'>
            {props.confRulesList.length === 0
                ? <div className='empty-rules-list'>
                    Aucune règles de description liées a ce produit
                </div>
                : <>
                    <thead>
                    <tr>
                        {headers.map((el: string, index: number) => {
                            return (
                                <th className='table-head' key={index}>{el}</th>
                            );
                        })}
                    </tr>
                    </thead>
                    <div className='products-rules-scroll'>
                        {
                            rulesRes.payload.content.ruleSet.rules.rules.map((rule: Rule, index) => {
                                if (rule.type === 'DIMENSION') {
                                    props.setRuleConfType(rule.type);
                                    return (
                                        <tbody key={index}>
                                        <tr className='d-r'>
                                            <td className='d'>
                                                {displayAtomsInCurrentRule(rulesRes, rule)
                                                    .map((c: Conf, ci) => {
                                                        const currentRule: Rule | undefined = c.ruleList?.find((r) => r.ruleRef === rule.ruleRef);
                                                        const actionParams = currentRule && currentRule.action;
                                                        return (
                                                            <div key={c.partReference} className='confit'>
                                                                <div className='partName'>{c.partName}</div>
                                                                <div className='item-atom'>
                                                                    {c.itemAtom.map((ia, i: number) => {
                                                                        return <div className='c' key={i}>
                                                                            {`${ia.partItemName} / ${ia.atomName}`}
                                                                        </div>;
                                                                    })}
                                                                </div>
                                                                <div className='action'>{actionParams && ci === 0 &&
                                                                    returnActionParms(actionParams)
                                                                }</div>
                                                            </div>
                                                        );
                                                    })}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className='del'>
                                                <img onClick={() => {
                                                    props.setCurrentRule(rule);
                                                    props.setOpenUpdateModal(true);
                                                }} alt='editer regle' src={edit} />
                                                <img onClick={() => {
                                                    productReference && OnDuplicateRule(productReference, dispatch, rule);
                                                }} alt='dupliquer' src={duplicate} />


                                                <img onClick={() => {
                                                    props.setRuleRef(rule.ruleRef);
                                                    props.setOpenDeleteModal(true);
                                                }} alt='poubelle'
                                                     src={trash} />
                                            </td>
                                        </tr>
                                        </tbody>
                                    );
                                }
                            })
                        }
                    </div>
                </>
            }

        </table>
    );
};
export default DescriptionRules;