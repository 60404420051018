import './loader.scss';
import { GridLoader } from 'react-spinners';
import { UploadingImgFileWithPreview } from '../../domain/domain';


type LoaderProps = {
    isLoading: boolean;
    uploadList?: UploadingImgFileWithPreview[],
    uploadPercent?:string
};

const Loader = ({ isLoading, uploadList ,uploadPercent }: LoaderProps) => {
    return (
        <>
            { isLoading && (
                <div className='main-loader'>
                    { uploadPercent && uploadPercent !== "0.00"  &&
                        <div className='texture-count'>
                            Nombre de textures téléchargées : <span> { uploadPercent }%</span>
                        </div> }
                    <div className='loader-container'>
                        <h3>Loading...</h3>
                        <GridLoader color='#921632FF' />
                    </div>
                </div>
            ) }
        </>
    );
};
export default Loader;
