import './create-and-edit-roles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../services/root-reducer';
import {
    createRole,
    createRoleSlice,
    CreateRoleState, editRole,
    getRole,
    GetRoleState, resetEditRole,
} from '../../services/reducers/role-reducer/role-slice';
import { useEffect, useState } from 'react';
import Paper from '../../uicomponents/paper/paper';
import Layout from '../../uicomponents/layout/layout';
import { RolePermissionType } from '../../domain/domain';


function CreateRole() {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const [enable, setEnable] = useState(false);
    const [roleName, setRoleName] = useState('');
    const [rolePermission, setRolePermission] = useState<RolePermissionType[] | []>([]);
    const [isMount, setIsMount] = useState(false);
    const [error, setError] = useState('');

    const role = useSelector<RootState, GetRoleState>(state => state.getRole);
    const creatRole = useSelector<RootState, CreateRoleState>((state) => state.createRole);
    const upRole = useSelector<RootState, CreateRoleState>((state) => state.editRole);

    useEffect(() => {
        setRoleName('');
        !isMount && edit() && dispatch(getRole(parseInt(params.id)));

        return () => setIsMount(true);
    }, []);
    useEffect(() => {
        if (role.payload.content.id !== 0 && role.payload.errors.length === 0) {
            setRoleName(role.payload.content.label);
            const editPerm = role.payload.content.permissions.map((r) => r.name);
            setRolePermission(editPerm);
        }
    }, [role]);
    useEffect(() => {
        if (creatRole.payload.content && creatRole.payload.content.length !== 0 && creatRole.payload.errors.length === 0 && !creatRole.isLoading) {
            history.push('/home/roles');
            dispatch(createRoleSlice.actions.resetCreateRole());
        } else {
            if (creatRole.payload.errors.length > 0) {
                creatRole.payload.errors[0].code === 'R0001' && setError('Un rôle avec ce nom existe déjà');
            }
        }
    }, [creatRole]);
    useEffect(() => {
        if (upRole.payload.content && upRole.payload.content.length !== 0 && upRole.payload.errors.length === 0 && !upRole.isLoading) {
            history.push('/home/roles');
            dispatch(resetEditRole());
        }
    }, [upRole]);
    useEffect(() => {
        if (roleName.length > 0 && rolePermission.length > 0) {
            setEnable(true);
        } else {
            setEnable(false);
        }

    }, [roleName, rolePermission]);

    const edit = () => {
        return !!params.id;
    };
    const onRoleSubmit = () => {
        edit() && dispatch(editRole({
            roleId: parseInt(params.id),
            form: { permissions: rolePermission, label: roleName },
        }));
        !edit() && dispatch(createRole({
            label: roleName,
            // @ts-ignore
            permissions: !rolePermission.includes('PERMLESS') ? rolePermission : [],
        }));
    };
    const ischecked = (value: string) => {
        // @ts-ignore
        return rolePermission.includes(value);
    };
    const permissionHandleChange = (e: any) => {
        // @ts-ignore
        const r = rolePermission.includes(e.target.value);
        if (r) {
            const res = rolePermission.filter((s: RolePermissionType) => s !== e.target.value);
            setRolePermission(res);
        } else {
            setRolePermission(prevState => [...prevState, e.target.value]);

        }
    };


    return (
        <Layout
            title={ edit() ? 'Éditer un rôle' : 'Créer un rôle' }
            labelBack="Quitter l'ajout de rôle ?"
            info='Les modifications ne seront pas sauvegardées '>
            <Paper>
                <div className='ce-main'>
                    <div className='label'>INTITULÉ</div>
                    <label>Rôle</label>
                    <input
                        defaultValue={ roleName }
                        onChange={ (e) => {
                            setError('');
                            setRoleName(e.target.value);
                        } }
                        className='in-tex'
                        type='text'
                        placeholder='Entrer le nom du rôle' />
                    { error.length > 0
                        ? <span className='E'>{ error }</span>
                        : <span className='E' /> }

                    <div className='label'>ACCÈS AUX FONCTIONNALITÉS</div>
                    <div className='ce-title'>APPLICATION BACK OFFICE</div>
                    <form onChange={ (e) => {
                        permissionHandleChange(e);
                    } }>
                        <div>
                            <input checked={ ischecked('ADMINISTRATION') } value='ADMINISTRATION' type='checkbox' />
                            <span>Administration</span>
                        </div>
                        <div>
                            <input checked={ ischecked('NEWS_BACKOFFICE') } value='NEWS_BACKOFFICE' type='checkbox' />
                            <span>News</span>
                        </div>
                        <div>
                            <input checked={ ischecked('BASE_PRODUIT') } value='BASE_PRODUIT' type='checkbox' />
                            <span>Base produits</span>
                        </div>
                        <div>
                            <input checked={ rolePermission.length === 0 && edit() } value='PERMLESS' type='checkbox' />
                            <span>Rôle sans permissions</span>
                        </div>
                    </form>
                    <button
                        onClick={ () => enable && onRoleSubmit() }
                        className={ enable ? 'e' : 'd' }>{ edit() ? 'Éditer' : 'Créer' }
                    </button>
                </div>
            </Paper>
        </Layout>
    );
}

export default CreateRole;
