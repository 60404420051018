import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {ColorType} from "../ParamTypes/ColorType";
import {TextureType} from "../ParamTypes/TextureType";
import {SliderData, SliderType} from "../ParamTypes/SliderType";
import {BooleanType} from "../ParamTypes/BooleanType";
import {ButtonType} from "../ParamTypes/ButtonType";
import {InspectorType} from "../ParamTypes/InspectorType";
import {TextType} from "../ParamTypes/TextType";

export class Specular {
    public m_specularIntenstity: InspectorType;
    public m_specularReflectivity: InspectorType;
    public m_specularColor: InspectorType;
    public m_specularShow: InspectorType;
    public m_specularMap: InspectorType;
    public m_specularMapClear: InspectorType;
    public m_specularFlipY: InspectorType;
    public m_specularMapTip: InspectorType;
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        this.m_uiFolder = m_uiFolders = m_tab.pages[0].addFolder({title: 'Specular (RGBA)', expanded: false});
        
        this.m_specularIntenstity = new SliderType(new SliderData(0.0,1.0,0.01), m_matParams, 'specularIntensity', m_uiFolders);
        this.m_specularReflectivity = new SliderType(new SliderData(0.0,1.0,0.01), m_matParams, 'specularReflectivity', m_uiFolders);
        this.m_specularColor = new ColorType(m_matParams, 'specularColor', m_uiFolders);
        
        this.m_specularShow = new BooleanType("Show map on mesh", m_matParams, 'specularMapShowOnMesh', m_uiFolders);
        this.m_specularMap = new TextureType(MapType.specularMap, m_matParams, 'specularMap', m_uiFolders);
        this.m_specularMapClear = new ButtonType("Clear map", m_matParams, '2', m_uiFolders);
        this.m_specularFlipY = new BooleanType("Flip Y", m_matParams, 'specularMapFlipY', m_uiFolders);
        this.m_specularMapTip = new TextType("RGB: specular color\nA: specular intensity", m_matParams, 'specularMapTip', m_uiFolders);
        
        this.InitTypes();
    }

    private InitTypes() {
        this.m_specularIntenstity.InitType();
        this.m_specularReflectivity.InitType();
        this.m_specularColor.InitType();
        
        this.m_specularShow.InitType();
        this.m_specularMapTip.InitType();
        this.m_specularMap.InitType();
        this.m_specularFlipY.InitType();
        this.m_specularMapClear.InitType();
    }
}