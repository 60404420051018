import React, { useEffect, useState } from 'react';
import './header.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HamburgerMenu from '../../uicomponents/hamburger/hamburger';

import { resetAuth } from '../../services/reducers/auth-reducer/auth-reducer';
import Paper from '../../uicomponents/paper/paper';

import exit from '../../asset/Deconnection.svg';
import profil from '../../asset/Profil.svg';
import logoMdf from '../../asset/mdf.png';
import frenchFlag from '../../asset/french.svg';
import ukFlag from '../../asset/uk-flag.png';
import Select, { components } from 'react-select';

import { RootState } from '../../services/root-reducer';
import {
    CatalogResponseState,
    getCatalogByRef,
} from '../../services/reducers/catalog/catalog.slice';
import { Params } from '../../domain/Params/params-interface';

type HeaderProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
};
const countries = [
    { value: 'fr', label: 'Francais', icon: frenchFlag },
    { value: 'en', label: 'English', icon: ukFlag },
];
const Option = (props: any) => (
    <components.Option {...props} className='country-option'>
        <img src={props.data.icon} alt='logo' className='country-logo' />
        {props.data.label}
    </components.Option>
);

const Header = ({ open = false, setOpen }: HeaderProps) => {
    const userInfo = localStorage.getItem('user');
    const user = userInfo && JSON.parse(userInfo);

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { i18n } = useTranslation();
    const { storeRef, storeName, catalogRef } = useParams<Params>();

    const catalog = useSelector<RootState, CatalogResponseState>(state => state.getCatalogByRef);

    const [selectedCountry, setSelectedCountry] = useState(i18n.language === 'en' ? countries[1] : countries[0]);

    useEffect(() => {
        catalogRef && dispatch(getCatalogByRef(catalogRef));
    }, [catalogRef]);

    //#region method
    const SingleValue = ({ children, ...props }: { children: React.ReactNode }) => (
        // @ts-ignore
        <components.SingleValue {...props}>
            <img src={selectedCountry.icon} alt='s-logo' className='selected-logo' />
            {children}
        </components.SingleValue>
    );
    const handleChange = (e: any) => {
        const languageValue = e.value;
        i18n.changeLanguage(languageValue);
        setSelectedCountry(e);
    };
    //endregion


    return (
        <div className='header-container'>
            <div className='hamburger'>
                <HamburgerMenu />
            </div>
            <div className='header-main'>
                <img alt='logo mdf' src={logoMdf} />
                <div className='breadcrumb'>
                    {location.pathname.includes('store') &&
                        <span className={location.pathname.includes('store') && location.pathname.includes(storeRef)
                            ? 'head-supp-name' : 'last'}
                              onClick={() => history.push('/stores')}>
                            Magasins
                        </span>}
                    {location.pathname.includes('catalog') &&
                        <span className={location.pathname.includes('catalog') && location.pathname.includes(catalogRef)
                            ? 'head-supp-name'
                            : 'last'}
                              onClick={() => history.push('/catalogs')}>
                            Catalogue
                        </span>}
                    {storeRef && <span>/</span>}
                    {catalogRef && <span>/</span>}
                    {storeName && <span className='last'>{storeName}</span>}
                    {catalogRef && catalog.payload.content !== null &&
                        <span className='last'>{catalog.payload.content.catalog.name}</span>}
                </div>
            </div>
            {/*     <button onClick={() => {
                apiClient('/v1/auth/custom/401', undefined, undefined, 'GET');
            }}>
                401
            </button>*/}
            <div className='avatar'>
                <Select
                    value={selectedCountry}
                    options={countries}
                    onChange={handleChange}
                    styles={{
                        control: (base) => ({
                            ...base,
                            border: 0,
                            boxShadow: 'none',
                            color: '#0f223b',
                        }),
                        singleValue: (base) => ({
                            ...base,
                            display: 'flex',
                            alignItems: 'center',
                        }),
                    }}
                    components={{
                        Option,
                        SingleValue,
                    }}
                />
                <img
                    onClick={() => {
                        setOpen(!open);
                    }}
                    src={profil}
                    alt='avatar logo '
                />
                {open && (
                    <div className='avatar-modal'>
                        <Paper top={20} height={138} size={205}>
                            <div>
                                <p onClick={() => {
                                    history.push('/update-user');
                                }}
                                >
                                    <img alt='initial avatar' src={profil} />
                                    {user.firstname} {user.lastname}
                                </p>
                                <p onClick={() => {
                                    dispatch(resetAuth());
                                    localStorage.removeItem('mdf-jwt');
                                    localStorage.removeItem('user');
                                    history.push('/');
                                }}>
                                    <img alt='logo deconnect' src={exit} /> Se Déconnecter
                                </p>
                            </div>
                        </Paper>
                    </div>
                )}
            </div>
        </div>
    )
        ;
};
export default Header;
