import { Engine } from '@lutithree/build/Engine';
import { Group, Material, Mesh, MeshPhysicalMaterial, Object3D } from "three";
import { MeshRendererComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent";
import { MeshFilterComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";
import { Guid } from "guid-typescript";
import EntityMeshController
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/EntityMeshController";
import Asset3D
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D";
import {
    ModelDecorator
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/EntityDecorators/Assets/ModelDecorator";
import {
    Model3dDecorator
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/EntityDecorators/Assets/Model3dDecorator";


export class MeshLoaderService {
    private m_engine: Engine;

    private m_entityID: Guid;

    private m_meshController: EntityMeshController;

    public constructor(p_engine: Engine) {
        if (p_engine == null) throw new Error('NullReference Exception: p_engine is null or undefined');
        this.m_engine = p_engine;
        this.m_entityID = this.m_engine.Modules.Scene.CreateEntity('ViewerMesh').Id;
        this.m_meshController = new EntityMeshController();
    }

    public ClearManager(): void {
        if(this.m_engine.Modules.Scene.HasEntityWithID(this.m_entityID))  this.m_engine.Modules.Scene.RemoveEntityByID(this.m_entityID);
    }

    private ClearEntityMaterial() {
        let entity = this.m_engine.Modules.Scene.GetEntityByID(this.m_entityID);

        if (entity.HasComponentOfType(MeshRendererComponent))
            entity.RemoveComponent(entity.GetComponentOfType(MeshRendererComponent));
    }

    private ClearEntityModel() {
        let entity = this.m_engine.Modules.Scene.GetEntityByID(this.m_entityID);

        if (entity.HasComponentOfType(MeshFilterComponent)) {
            entity.GetComponentsOfType(MeshFilterComponent).forEach((meshFilter) => {
                entity.RemoveComponent(meshFilter);
            });
        }
    }

    public OutlineZone(p_zone: string[] | undefined, selectedMat: MeshPhysicalMaterial, unselectedMat: MeshPhysicalMaterial) {
        let entityMeshControl = new EntityMeshController();
        let entity = this.m_engine.Modules.Scene.GetEntityByID(this.m_entityID);
        if (!p_zone) {
            this.m_engine.Modules.Rendering.ClearOutlineObjects();
            entityMeshControl.GetOrAddMeshRenderer(entity).Material = unselectedMat;
            return;
        }
        entityMeshControl.GetOrAddMeshRenderer(entity).Material = unselectedMat;
        this.m_engine.Modules.Rendering.ClearOutlineObjects();
        entityMeshControl.GetOrAddMeshRenderer(entity).GetMappedMesh(p_zone).forEach((mesh) => {
            (mesh as Mesh).material = selectedMat;
            this.m_engine.Modules.Rendering.RenderOutlineForObject(mesh);
        });
        entity.Transform.GetObject().traverse((obj) => {
            obj.castShadow = false;
            obj.receiveShadow = false;
        });
        this.m_engine.Modules.LoopStrategy.RequestRender(true);
    }

    public LoadModel(p_assetModel: Asset3D): Promise<Object3D> {
        if (p_assetModel == null) throw new Error("p_asset is undefined or null");

        const cleanUpFunc = (resource: Material | Material[] | Group | Object3D) => {
            if (Array.isArray(resource)) {
                resource.forEach((item) => {
                    this.m_engine.Modules.Resources.Cleaner.Dispose(item);
                });
            } else this.m_engine.Modules.Resources.Cleaner.Dispose(resource);
        };

        this.ClearEntityModel();
        let deco;
        if (p_assetModel.Type === "Model")
            deco = new ModelDecorator();
        else if (p_assetModel.Type === "Model3d")
            deco = new Model3dDecorator();
        else
            throw new Error("Type was not correctly handled in Mesh Loader Service");

        let entity = this.m_engine.Modules.Scene.GetEntityByID(this.m_entityID);
        return deco.DecorateAsset(entity, p_assetModel, cleanUpFunc).then(() => {
            return new Promise((resolve, reject) => {
                if (entity.HasComponentOfType(MeshFilterComponent))
                    resolve(entity.GetComponentOfType(MeshFilterComponent).GetObject());
                else
                    reject();
            });
        });
    }
}
