import apiClient from '../../../api-client';
import {
    GET_PART,
    GET_ALL_PARTS,
    CREATE_PART,
    ADD_PART_ITEM,
    UPDATE_PART,
    DELETE_PART,
    UPDATE_PART_NAME, DELETE_PART_ITEM,
} from '../../../endpoints/product/part/part';
import { PartItemDTO } from '../../../../domain/domain';

const partService = {
    createPart: async (productRef: string, data: { name: string }) => apiClient(CREATE_PART(productRef), data, undefined, 'POST'),
    addPartItem: async (partRef: string, data: PartItemDTO) => apiClient(ADD_PART_ITEM(partRef), data, undefined, 'POST'),
    getAllParts: async (productRef: string) => apiClient(GET_ALL_PARTS(productRef), undefined, undefined, 'GET'),
    getPart: async (partRef: string) => apiClient(GET_PART(partRef), undefined, undefined, 'GET'),
    updatePart: async (partId: number, createPartForm: {}) => apiClient(UPDATE_PART(partId), createPartForm, undefined, 'PUT'),
    updatePartName: async (partRef: string, name: string) => apiClient(UPDATE_PART_NAME(partRef, name), undefined, undefined, 'PUT'),
    deletePart: async (partId: number) => apiClient(DELETE_PART(partId), undefined, undefined, 'DELETE'),
    deletePartItem: async (partRef: string, itemRef: string) => apiClient(DELETE_PART_ITEM(partRef, itemRef), undefined, undefined, 'DELETE'),
};

export default partService;