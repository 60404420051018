import MdfApiResponse from '../../../domain/common/generic';
import { AuthenticationResponse, StatusType, User, UserByStatus, UserStatusCount } from '../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from '../../../api/services/user/user.service';


export type UsersState = {
    isLoading: boolean;
    payload: MdfApiResponse<Array<User>>;
};

const initialUsersPageState: UsersState = {
    isLoading: false,
    payload: { content: [] , errors: [], warnings: [] },
};

export type UserListState = {
    isLoading: boolean,
    payload: MdfApiResponse<User[]>;
};

const initialUserListState: UserListState = {
    isLoading: false,
    payload: {
        content: [], errors: [], warnings: [],
    },
};

type GetUsersListParameters = {
    status?: StatusType;
    role?: string;
    page?: number;
};

export const getUserList = createAsyncThunk('user/Page', async (data: GetUsersListParameters, thunkApi) => {
    const response = await UserService.getUserList(data.status, data.role, data.page);
    return response;
});

export const usersSlice = createSlice({
    name: 'usersPageSlice',
    initialState: initialUsersPageState,
    reducers: {
        resetUserList: (state) => {
            state = initialUsersPageState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getUserList.fulfilled, (state, { payload }) => {
            state = { payload: payload, isLoading: false };
            return state;
        });
        builder.addCase(getUserList.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

// GET ME //

export type GetMeState = {
    isLoading: boolean;
    payload: MdfApiResponse<AuthenticationResponse | null>;
};

const getMeInitialState: GetMeState = {
    isLoading: false,
    payload: { content: null, errors: [], warnings: [] },
};

export const getMe = createAsyncThunk('user/get-me', async (thunkApi) => {
    const response = await UserService.getMe();
    return response;
});

export const getMeSlice = createSlice({
    name: 'getMeSlice',
    initialState: getMeInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMe.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMe.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getMe.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

export type UserState = {
    isLoading: boolean;
    payload: MdfApiResponse<User>;
};

const initialUserState: UserState = {
    isLoading: false,
    payload: {
        content: {
            createdAt: new Date(),
            email: '',
            firstname: '',
            id: 0,
            lastname: '',
            removed: false,
            resetPasswordToken: '',
            responsibleId: 0,
            roles: [],
            status: 'ACTIVE',
            updatedAt: new Date(),
            connectionAt: new Date(),
            version: 0,
        }, errors: [], warnings: [],
    },
};

export const getUser = createAsyncThunk('user/by-id', async (userId: number, thunkApi) => {
    const response = await UserService.getUser(userId);
    return response;
});

export const getUserSlice = createSlice({
    name: 'userSlice',
    initialState: initialUserState,
    reducers: {
        resetUser: (state) => {
            state = initialUserState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getUser.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getUser.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

//region get all active users
export const getActiveUser = createAsyncThunk('user/by-id', async () => {
    const response = await UserService.geActiveUsers();
    return response;
});

export const getActiveUserSlice = createSlice({
    name: 'getActiveUser',
    initialState: initialUserListState,
    reducers: {
        resetUser: (state) => {
            state = initialUserListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getActiveUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getActiveUser.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getActiveUser.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
//endregion


// get User Count by Status

export const getUserStatusCount = createAsyncThunk('newsUserStatusCount/list', async (_, thunkApi) => {
    const response = await UserService.getUserStatusCount();
    return response;
});

export type UserStatusCountState = {
    isLoading: boolean;
    payload: MdfApiResponse<UserStatusCount[]>;
};

const initialStateUserStatusCount: UserStatusCountState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};

export const getUserStatusCountSlice = createSlice({
    name: 'getUserStatusSlice',
    initialState: initialStateUserStatusCount,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserStatusCount.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getUserStatusCount.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getUserStatusCount.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

// GET RESPONSIBLE LIST

export type ResponsibleState = {
    isLoading: boolean;
    responsibleList: MdfApiResponse<User[]>;
};

const initialResponsiblePageState: ResponsibleState = {
    isLoading: false,
    responsibleList: { content: [], errors: [], warnings: [] },
};


export const getResponsibleList = createAsyncThunk('responsible/List', async (_, thunkApi) => {
    const response = await UserService.getResponsibleList();
    return response;
});

export const responsibleSlice = createSlice({
    name: 'responsibleListSlice',
    initialState: initialResponsiblePageState,
    reducers: {
        resetResponsibleList: (state) => {
            state = initialResponsiblePageState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getResponsibleList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getResponsibleList.fulfilled, (state, { payload }) => {
            state = { responsibleList: payload, isLoading: false };
            return state;
        });
        builder.addCase(getResponsibleList.rejected, (state) => {
            state.isLoading = false;
        });
    },
});


//region get Users by status

export type UserByStatusState = {
    isLoading: boolean;
    payload: MdfApiResponse<UserByStatus>;
};

const initialUserByStatus: UserByStatusState = {
    isLoading: false,
    payload: {
        content: {
            activeUser: [],
            neverConnectedUser: [],
        },
        errors: [],
        warnings: [],
    },
};

export const getUsersByStatus = createAsyncThunk('get/usersByStatus', async () => {
    const res = await UserService.getUserByStatus();
    return res;
});

export const getUsersByStatusSlice = createSlice({
    name: 'etUsersByStatus',
    initialState: initialUserByStatus,
    reducers: {
        resetGetUsersByStatus: (state) => {
            state = initialUserByStatus;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUsersByStatus.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getUsersByStatus.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getUsersByStatus.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetUsersByStatus } = getUsersByStatusSlice.actions;
//endregion
