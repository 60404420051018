import './associate-rules.scss';
import close from '../../../../asset/CloseWindow.svg';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    associateConstructionRule,
    ConstructionRuleListState,
    ConstructionRuleState,
    findConstructionRulesByCompoRef,
    findConstructionRulesi,
    resetAssociateConstructionRule,
} from '../../../../services/reducers/product/rules/construction-rules.reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../domain/Params/params-interface';
import { ConstructionRule } from '../../../../domain/domain';
import { RootState } from '../../../../services/root-reducer';


type AssociateRulesProps = {
    setOpenAssociateRule: Dispatch<SetStateAction<boolean>>
    currentRules: ConstructionRule[]
};
const AssociateRules = (props: AssociateRulesProps) => {
        const dispatch = useDispatch();
        const { productReference, collectionReference } = useParams<Params>();

        const constructionRules = useSelector<RootState, ConstructionRuleListState>(state => state.findConstructionRules);
        const associateRuleRes = useSelector<RootState, ConstructionRuleState>(state => state.associateConstructionRule);

        const [isMount, setIsmount] = useState(false);
        const [mandatoryRules, setMandatoryRules] = useState<ConstructionRule[]>([]);
        const [forbiddenRules, setForbiddenRules] = useState<ConstructionRule[]>([]);
        const [idsList, setIdsList] = useState<number[]>([]);

        //#region useEffect
        useEffect(() => {
            !isMount && collectionReference && dispatch(findConstructionRulesi(collectionReference));
            return () => setIsmount(true);
        }, [collectionReference]);
        useEffect(() => {
            let newIdList: number[] = [];
            props.currentRules.forEach((rule) => newIdList.push(rule.id));
            setIdsList(newIdList);
        }, [props.currentRules]);
        useEffect(() => {
            if (constructionRules.payload.content && constructionRules.payload.errors.length === 0) {
                const rules = constructionRules.payload.content;
                const mRules = rules.filter((r) => r.type === 'MANDATORY');
                const fRules = rules.filter((r) => r.type === 'IMPOSSIBLE');
                setForbiddenRules(fRules);
                setMandatoryRules(mRules);
            }
        }, [constructionRules]);
        useEffect(() => {
            if (associateRuleRes.payload.content.id !== 0 && associateRuleRes.payload.errors) {
                props.setOpenAssociateRule(false);
                productReference && dispatch(findConstructionRulesByCompoRef(productReference));
                dispatch(resetAssociateConstructionRule());
            }
        }, [associateRuleRes]);
        //endregion

        //#region method
        const addAndRemoveIds = (id: number) => {
            const filteredList = idsList.filter((i) => i !== id);
            idsList.includes(id)
                ? setIdsList(filteredList)
                : setIdsList(prevState => [...prevState, id]);
        };
        const ruleAlreadyAssociate = (id: number): boolean => idsList.includes(id);
        const onAssociate = () => {
            productReference && dispatch(associateConstructionRule({
                compositionRef: productReference, ruleIdsList: idsList,
            }));
        };
        const masterCheckBox = (ruleList: ConstructionRule[]) => {
            let list: number[] = [];
            ruleList.forEach((r) => list.push(r.id));
            const result = list.some(el => idsList.includes(el));
            if (result) {
                const newList = idsList.filter((e) => !list.includes(e));
                setIdsList(newList);
            } else {
                list.forEach(e => setIdsList(prevState => [...prevState, e]));
            }
        };
        const allRefIn = (ruleList: ConstructionRule[]): boolean => {
            let list: number[] = [];
            ruleList.forEach((r) => list.push(r.id));
            return list.every(el => idsList.includes(el));
        };
        //endregion

        return (
            <div className='asso-main'>
                <div className='asso-paper'>
                    <div className='asso-top'>
                        <div>Associer les règles au produit</div>
                        <img alt="fermer la modal" onClick={() => props.setOpenAssociateRule(false)} src={close} />
                    </div>
                    <div className='asso-body'>
                        <div className='asso-middle'>
                            <div className='asso-middle-top'>
                                <div>RÈGLES D'OBLIGATIONS</div>
                                <div>RÈGLES D'INTERDICTIONS</div>
                            </div>
                            <div className='asso-middle-body'>
                                <div className='f c'>
                                    {mandatoryRules.length > 0 &&
                                        <input checked={allRefIn(mandatoryRules)}
                                               onChange={() => masterCheckBox(mandatoryRules)}
                                               type='checkbox'
                                               className='master' />}
                                    {mandatoryRules.map((m) => {
                                        return (
                                            <div key={m.id}>
                                                <div className='b'>
                                                    <input
                                                        checked={ruleAlreadyAssociate(m.id)}
                                                        value={m.id}
                                                        type='checkbox'
                                                        onChange={(e) => {
                                                            addAndRemoveIds(parseInt(e.target.value));
                                                        }}
                                                    />
                                                    <span>{m.name}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className='c'>
                                    {forbiddenRules.length > 0 &&
                                        <input checked={allRefIn(forbiddenRules)}
                                               onChange={() => masterCheckBox(forbiddenRules)}
                                               className='master'
                                               type='checkbox' />}
                                    {forbiddenRules.map((f) => {
                                        return (
                                            <div key={f.id}>
                                                <div className='b' key={f.id}>
                                                    <input
                                                        checked={ruleAlreadyAssociate(f.id)}
                                                        onChange={(e) => addAndRemoveIds(parseInt(e.target.value))}
                                                        value={f.id} type='checkbox' />
                                                    <span>{f.name}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='asso-btn-box'>
                            <button className='ann' onClick={() => props.setOpenAssociateRule(false)}>Annuler</button>
                            <button onClick={onAssociate}> Associer</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
;
export default AssociateRules;