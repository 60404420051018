import React, { useState } from 'react';
import fakeLogo from '../../../../../asset/logo-mdf.jpeg';
import logo from '../../../../../asset/logo-mdf.jpeg';
import edit from '../../../../../asset/Editer.svg';
import duplicate from '../../../../../asset/dupliquer.svg';
import trashRed from '../../../../../asset/poubelle-red.svg';
import { PresetTree } from '../../../../../domain/domain';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../services/root-reducer';
import configuredProductService
    from '../../../../../api/services/products/configured-product/configured-product.service';
import { Conf, PresetConf } from '../product-preset-table';
import {
    getConfiguredProductTreeList,
} from '../../../../../services/reducers/product/configured-product/configured-product.reducers';
import {
    ProductStudioState
} from "../../../../../application-3d/application3D-common/Redux/Reducers/product-studio/ProductStudioReducer";


type ProductPresetRowProps = {
    setOpenCreateModalSecondStep: (openCreateModalSecondStep: boolean) => void;
    preset: PresetTree | undefined;
    setPresetRef: (presetRef: string) => void;
    setPresetToEdit: (preset: PresetTree) => void;
    setOpenDuplicateModal: (open: boolean) => void;
    setOpenDeleteModal: (open: boolean) => void;
    conf: PresetConf[]
};

const ProductPresetRow = ({
                              conf,
                              setOpenCreateModalSecondStep,
                              preset,
                              setPresetRef,
                              setPresetToEdit,
                              setOpenDuplicateModal,
                              setOpenDeleteModal,
                          }: ProductPresetRowProps) => {
        const dispatch = useDispatch();

        const productStudio = useSelector<RootState, ProductStudioState>((state) => state.productStudio);
        const [hovId, setHovId] = useState(0);
        const [presetHoverId, setPresetHovId] = useState<number>(0);


        const handleRadioChange = () => {
            preset && configuredProductService.setDefaultConfiguredProduct(preset.product.parentProductReference, preset.id).then(res => {
                preset && dispatch(getConfiguredProductTreeList({ coreProductReference: preset.product.parentProductReference }));
            });
        };

        return (
            <>
                <td className='preset-name'>
                    <img alt='logo collection'
                         onMouseEnter={ () => preset && setHovId(preset.id) }
                         onMouseLeave={ () => setHovId(0) }
                         src={ preset && preset.thumbnailUrl.length < 1
                             ? fakeLogo
                             : preset && preset.thumbnailUrl } />
                    { preset && preset.product.name }
                </td>
                { preset && preset.id === hovId &&
                    <th className='prevOnHoov'>
                        <img alt='prev on hoover'
                             src={ preset && preset.thumbnailUrl
                                 ? preset && preset.thumbnailUrl
                                 : logo } />
                    </th> }
                <td className='preset-conf'
                    onMouseEnter={ () => preset && setPresetHovId(preset.id) }
                    onMouseLeave={ () => preset && setPresetHovId(0) }
                >
                    { conf.map((pc, i) => {
                        return presetHoverId === pc.presetId
                            ? <div key={ pc.presetId } className='preset-conf-absolute'>
                                { pc.presetId === presetHoverId && (
                                    <React.Fragment key={ pc.presetId }>
                                        { pc.presetConf.map((c: Conf, ci) => {
                                            return (
                                                <div key={ c.partReference } className='conf'>
                                                    <span className='partName'>{ c.partName }</span>
                                                    <div>
                                                        <div style={ { textDecorationLine: 'underline' } }
                                                             className='partName'>Forme
                                                        </div>
                                                        <div className='item-atom'>
                                                            { c.itemAtom.map((ia, index) => {
                                                                return <div key={ index }>
                                                                    { `${ ia.partItemName } / ${ ia.atomName }` }
                                                                </div>;
                                                            }) }
                                                        </div>

                                                    </div>
                                                    <div>
                                                        <div style={ { textDecorationLine: 'underline' } }
                                                             className='partName'>Matière
                                                        </div>
                                                        <div>
                                                            <span className='partName'>Habillage:</span> { c.mappingName }
                                                        </div>
                                                        <div className='item-atomi'>
                                                            <span className='partName'>Zone / Coloris :</span>
                                                            { c.zoneColorisList.map((zc, id) => {
                                                                return (
                                                                    <div className='item-atom' key={ id }>
                                                                        <span
                                                                            className='zc'>{ zc.zoneName }</span>{ zc.defaultColoris }
                                                                    </div>

                                                                );
                                                            }) }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={ { textDecorationLine: 'underline' } }
                                                             className='partName'>Options
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }) }
                                    </React.Fragment>
                                )
                                }
                            </div>
                            :
                            <th key={ pc.presetId } className='c'>{
                                i === 0 && pc.presetConf.map((c: Conf) =>
                                    <div key={ c.partReference }
                                         className='c-partName'>
                                        <span className='t'>Part:</span>
                                        <span> { c.partName }</span>
                                    </div>,
                                )

                            }{ i === 0 && '...' }
                            </th>;
                    }) }

                </td>
                <td>
                    <input
                        checked={ preset && preset.isDefault }
                        type='radio'
                        name='type'
                        onChange={ handleRadioChange }
                        value={ preset && preset.product.reference }
                    />
                </td>

                <td className='last-child'>
                    <img

                        onClick={ () => {
                            if (productStudio) {
                                productStudio.productStudioApp?.EngineService.Resize();
                                preset && setPresetRef(preset.product.reference);
                                preset && setPresetToEdit(preset);
                            }

                            setOpenCreateModalSecondStep(true);
                        } }
                        alt='edit'
                        src={ edit } />
                    <img
                        onClick={ () => {
                            preset && setPresetRef(preset.product.reference);
                            setOpenDuplicateModal(true);
                        } }
                        alt='duplicate'
                        src={ duplicate }
                    />
                    <img
                        onClick={ () => {
                            preset && setPresetRef(preset.product.reference);
                            setOpenDeleteModal(true);
                        } }
                        alt='poubelle'
                        src={ trashRed }
                    />
                </td>
            </>
        )
            ;
    }
;

export default ProductPresetRow;

