import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/root-reducer';
import { setToasterError, setToasterVisible } from '../../../services/reducers/toaster-reducer/toaster.slice';
import { forgotPassword, ForgotPasswordState } from '../../../services/reducers/auth-reducer/auth-reducer';

import Loader from '../../../uicomponents/loader/loader';
import CreateFirstUserForm from '../../../uicomponents/create-user-form/create-first-user-form';

function CreateFirstUser() {
    const dispatch = useDispatch();
    const forgotPasswordResponse = useSelector<RootState, ForgotPasswordState>((state) => state.forgotPassword);

    useEffect(() => {
        if (forgotPasswordResponse.payload.content !== null && forgotPasswordResponse.payload.errors.length === 0 && !forgotPasswordResponse.isLoading) {
            dispatch(setToasterVisible({ message: 'reinitialisation envoyée', title: 'Envoyée !' }));
        } else if (forgotPasswordResponse.payload.errors.length !== 0) {
            dispatch(setToasterError({ message: forgotPasswordResponse.payload.errors[0].message, title: forgotPasswordResponse.payload.errors[0].code }));
        }
    }, [forgotPasswordResponse]);

    return (
        <div>
            <Loader isLoading={forgotPasswordResponse.isLoading} />
            <div>
                <CreateFirstUserForm
                    onSubmit={(form) => {
                        dispatch(forgotPassword(form));
                    }}
                />
            </div>
        </div>
    );
}

export default CreateFirstUser;
