import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../../services/root-reducer';
import MdfApiResponse from '../../../domain/common/generic';
import { User } from '../../../domain/domain';
import { useEffect, useState } from 'react';
import Layout from '../../../uicomponents/layout/layout';
import { getUser, UserState } from '../../../services/reducers/users/users.slice';
import { resetUpdateUser, updateUser } from '../../../services/reducers/update-user/update-user.slice';
import UpdateUserByAdminForm from '../../../uicomponents/update-user-form/update-user-by-admin-form';


type UserSelector = {
    isLoading: boolean;
    isRejected: boolean;
    payload: MdfApiResponse<User | null>;
};

function UpdateUserByAdmin() {
    const dispatch = useDispatch();
    const history = useHistory();
    const param = useParams<{ id: string }>();
    const user = useSelector<RootState, UserState>((state) => state.getUser);
    const updatedUser = useSelector<RootState, UserSelector>((state) => state.updateUser);

    const [isEmailError, setIsEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [serverErr, setServerErr] = useState('');
    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        !isMount && dispatch(getUser(parseInt(param.id, 10)));
        return () => setIsMount(true);
    }, []);
    useEffect(() => {
        if (updatedUser.payload.content !== null && updatedUser.payload.errors.length === 0 && !updatedUser.isLoading) {
            dispatch(resetUpdateUser());
            history.push('/home/users');
            dispatch(getUser(parseInt(param.id, 10)));
            dispatch(resetUpdateUser());
            localStorage.setItem('user', JSON.stringify(updatedUser.payload.content));
        } else if (updatedUser.payload.errors.length !== 0) {
            setIsEmailError(true);
            setEmailErrorMessage('Un utilisateur avec cet adresse mail existe déjà');
            setServerErr('Une erreur inconnue est survenue');
        }
    }, [updatedUser]);

    return (
        <Layout title='Modifier un utilisateur' labelBack='Quitter la modification de cette utilisateur ?'>
            { user.payload.content.id === parseInt(param.id, 10) &&
                (<UpdateUserByAdminForm
                    error={ serverErr }
                    setError={ setServerErr }

                    emailErrorMessage={ emailErrorMessage }
                    setEmailErrorMessage={ setEmailErrorMessage }

                    isEmailError={ isEmailError }
                    setIsEmailError={ setIsEmailError }

                    onSubmit={ (form) => {
                        dispatch(updateUser({ userId: parseInt(param.id, 10), createUserForm: form }));
                    } }

                    user={ user.payload.content }
                />) }
        </Layout>
    );
}

export default UpdateUserByAdmin;
