import close from '../../../../asset/CloseWindow.svg';
import { formErrors } from '../../../../utils/form-errors';
import { useDispatch } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { createZoning } from '../../../../services/reducers/mapping-zoning/zoning.reducer';
import React from 'react';
import { returnadvice } from '../mapping/create-mapping';


type CreateZoningProps = {
    setOpenModal: (openModal: boolean) => void;
    groupId: number;
    mappingId: number
};

type AddZoningInput = {
    name: string;
};


const CreateZoning = (props: CreateZoningProps) => {
        const dispatch = useDispatch();

        const {
            register,
            watch,
            handleSubmit,
            formState: { errors },
        } = useForm<AddZoningInput>({});


        const onSubmit: SubmitHandler<AddZoningInput> = (data) => {
            props.groupId && dispatch(createZoning({
                mappingId: props.mappingId,
                zoneName: data.name,
            }));
        };

        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSubmit(onSubmit)();
            }
        };

        return (
            <div className='create-group-main'>
                <div className='create-group-paper'>
                    <div className='create-group-top'>
                        <img onClick={() => {
                            props.setOpenModal(false);
                        }} alt='fermer la modal ' src={close} />
                        <div
                            className='create-group-title'>
                            Créer une Zone
                        </div>
                    </div>
                    <div className='create-group-body'>
                        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={keyDownHandler}>
                            <div className='input-container'>
                                <label htmlFor='name'>Nom</label>
                                <input
                                    placeholder='Nom de la zone'
                                    {...register('name', {
                                        required: true,
                                    })} />
                                <> {returnadvice(watch('name'), 24)}</>
                                <div className='err-box'>{errors && formErrors(errors.name)}</div>
                            </div>
                            <div className='btn-box'>
                                <button onClick={() => {
                                    props.setOpenModal(false);
                                }} className='btn-close'>
                                    Annuler
                                </button>
                                <button className='btn-action'>Créer</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
;
export default CreateZoning;