import apiClient from '../../../../api-client';
import {
    CREATE_TEXTURE,
    DELETE_TEXTURE,
    GET_TEXTURES,
    UPDATE_TEXTURE,
} from '../../../../endpoints/suppliers/finish/texture/texture.endpoints';
import { Texture } from '../../../../../domain/domain';


const TextureService = {
    getFinishTextures: async (finishId: number) => apiClient(GET_TEXTURES(finishId), undefined, undefined, 'GET'),
    createTexture: async (finishId: number, textureSet: Texture[]) => apiClient(CREATE_TEXTURE(finishId), textureSet, undefined, 'POST'),
    getAllTexture: async (finishId: number, page: number, size: number) => apiClient(`v1/suppliers/finishes/${ finishId }/textures?page=${ page }&size=${ size }`, undefined, undefined, 'GET'),
    updateTexture: async (textureId: number, textureName: string) => apiClient(UPDATE_TEXTURE(textureId, textureName), undefined, undefined, 'PUT'),
    deleteTexture: async (finishId: number, textureId: number) => apiClient(DELETE_TEXTURE(textureId, textureId), undefined, undefined, 'DELETE'),
};

export default TextureService;
