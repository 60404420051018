import plus from '../../../../../../asset/plus-circle.svg';
import React, { useEffect, useState } from 'react';
import update from '../../../../../../asset/Editer.svg';
import CreatePartElement from '../../../../../../components/modals/product/create-part/create-part';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../services/root-reducer';
import {
    deletePart,
    deletePartItem,
    getAllParts,
    PartListState,
    PartState, resetAddPartItem,
    resetCreatePart,
    resetDeletePartItem,
    resetUpdatePartName,
} from '../../../../../../services/reducers/product/part/part.reducers';
import SimpleModal from '../../../../../../uicomponents/modal/simple-modal/simple-modal';
import trash from '../../../../../../asset/poubelle.svg';
import { Pair, Part, PartItem, PartItemMetadata, Product, Product3DInfo } from '../../../../../../domain/domain';
import './product-detail-characteristic.scss';
import {
    ProductState,
    resetSaveProductCara,
    saveProductCara,
} from '../../../../../../services/reducers/product/product.reducers';


type ProductDetailCharacteristicProps = {
    productRef: string;
    setFocus: (focus: number) => void,
    focus: number
    setStep: (step: number) => void;
    currentProduct: Pair<Product, Product3DInfo>
};
const ProductDetailCharacteristic = (props: ProductDetailCharacteristicProps) => {
    const dispatch = useDispatch();
    const createPartRes = useSelector<RootState, PartState>((state) => state.createPart);
    const saveCaraRes = useSelector<RootState, ProductState>((state) => state.saveProductCara);
    const addPartItemRes = useSelector<RootState, PartState>((state) => state.addPartItem);
    const updatePartNameRes = useSelector<RootState, PartState>((state) => state.updatePartName);
    const deletePartRes = useSelector<RootState, PartState>((state) => state.deletePart);
    const deletePartItemRes = useSelector<RootState, PartState>((state) => state.deletePartItem);
    const partListRes = useSelector<RootState, PartListState>((state) => state.getAllParts);

    const [createPartModal, setCreatePartModal] = useState(false);
    const [updatePartModal, setUpdatePartModal] = useState(false);
    const [createCaraModal, setCreateCaraPartModal] = useState(false);
    const [createOptionModal, setCreateOptionPartModal] = useState(false);
    const [createOptionSubCategoryModal, setCreateOptionSubCategoryDetailModal] = useState(false);
    const [createCaraSubCategoryModal, setCreateCaraSubCategoryDetailModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openDeleteItemModal, setOpenDeleteItemModal] = useState(false);

    const [updateOn, setUpdateOn] = useState(false);
    const [selectedPart, setSelectedPart] = useState<Part>();
    const [partCara, setPartCara] = useState<PartItem[]>([]);
    const [partOption, setPartOption] = useState<PartItem[]>([]);

    const [partRef, setPartRef] = useState<string>();
    const [partItemRef, setPartItemRef] = useState<string>();
    const [partName, setPartName] = useState<string>();
    const [partId, setPartId] = useState<number | undefined>(undefined);
    const [partItemIndex, setPartItemIndex] = useState<number>(0);

    useEffect(() => {
        if (props.focus === 1) {
            setSelectedPart(undefined);
            setPartId(0);
            dispatch(getAllParts(props.productRef));
        }
    }, [props.focus]);
    useEffect(() => {
        if (partListRes.payload.content.length > 0 && partListRes.payload.errors.length === 0) {
            if (selectedPart) {
                const findPart = partListRes.payload.content.find((p: Part) => p.id === selectedPart.id);
                if (findPart) {
                    setPartCara(findPart.partCharacteristicMetadata.partItemList);
                    setPartOption(findPart.partOptionMetadata.partItemList);
                }
            }
        }
    }, [partListRes]);
    useEffect(() => {
        if (createPartRes.payload.content && createPartRes.payload.errors.length === 0) {
            setPartId(createPartRes.payload.content.id);
            dispatch(getAllParts(props.productRef));
            dispatch(resetCreatePart());
            return () => setSelectedPart(createPartRes.payload.content);
        }
    }, [createPartRes]);
    useEffect(() => {
        if (addPartItemRes.payload.content.name.length > 0 && addPartItemRes.payload.errors.length === 0) {
            dispatch(getAllParts(props.productRef));
            dispatch(resetAddPartItem());
        }
    }, [addPartItemRes]);
    useEffect(() => {
        if (updatePartNameRes.payload.content.name.length > 0 && updatePartNameRes.payload.errors.length === 0) {
            dispatch(getAllParts(props.productRef));
            dispatch(resetUpdatePartName());
        }
    }, [updatePartNameRes]);
    useEffect(() => {
        if (deletePartRes.payload.content.name.length > 0 && deletePartRes.payload.errors.length === 0) {
            partListRes.payload.content[0] && setPartId(partListRes.payload.content[0].id);
            partListRes.payload.content[0] && setSelectedPart(partListRes.payload.content[0]);
            dispatch(getAllParts(props.productRef));
            setOpenDeleteModal(false);

        }
    }, [deletePartRes]);
    useEffect(() => {
        if (deletePartItemRes.payload.content.id !== 0 && deletePartItemRes.payload.errors.length === 0) {
            setOpenDeleteItemModal(false);
            dispatch(getAllParts(props.productRef));
            dispatch(resetDeletePartItem());
        }
    }, [deletePartItemRes]);
    useEffect(() => {
        if (selectedPart) {
            setPartRef(selectedPart?.reference);
            setPartCara(selectedPart.partCharacteristicMetadata.partItemList);
            setPartOption(selectedPart.partOptionMetadata.partItemList);
        }
    }, [selectedPart]);
    useEffect(() => {
        if (saveCaraRes.payload.content && saveCaraRes.payload.errors.length > 0) {
            props.setStep(2);
            props.setFocus(2);
            dispatch(resetSaveProductCara());
        }
    }, [saveCaraRes]);

    const generateComponents = (partItem: PartItem, componentList: any, key: number, i: number, type: string): JSX.Element[] => {
        if (partItem === null) {
            return [
                ...componentList,
                <div key={ key } className='detail-card-plus'>
                    <img
                        key={ i }
                        onClick={ () => {
                            setPartItemIndex(i);
                            type === 'CHARACTERISTIC'
                                ? setCreateCaraSubCategoryDetailModal(true)
                                : setCreateOptionSubCategoryDetailModal(true);
                        } }
                        alt='ajout'
                        src={ plus }
                    />
                </div>,
            ];
        } else {
            return [
                ...componentList,
                <>
                    <div key={ key } className='detail-card'>
                        <span className='c-name'>{ partItem.name }</span>
                        <span
                            onClick={ () => {
                                setPartItemRef(partItem.reference);
                                setOpenDeleteItemModal(true);
                            } }
                            style={ { cursor: 'pointer' } }
                        >
                        X
                    </span>
                    </div>
                </>
                ,
                generateComponents(partItem.partCharacteristics, componentList, key + 1, i, type),
            ];
        }
    };
    const onSubmit = () => {
        dispatch(saveProductCara(props.productRef));
    };

    return (
        <div className='product-detail-cara'>
            <>
                { createPartModal &&
                    <CreatePartElement productRef={ props.productRef } setOpenPartModal={ setCreatePartModal } /> }
                { updatePartModal && (
                    <CreatePartElement update={ updateOn } setUpdate={ setUpdateOn } partRef={ partRef }
                                       setOpenPartModal={ setCreatePartModal }
                                       setOpenUpdateModal={ setUpdatePartModal } name={ partName } />) }
                { createCaraModal &&
                    <CreatePartElement part={ selectedPart } cara setOpenPartModal={ setCreateCaraPartModal } /> }
                { createOptionModal &&
                    <CreatePartElement option part={ selectedPart } setOpenPartModal={ setCreateOptionPartModal } /> }
                { createOptionSubCategoryModal &&
                    <CreatePartElement part={ selectedPart } subOptionCategory partIndex={ partItemIndex }
                                       setOpenPartModal={ setCreateOptionSubCategoryDetailModal } /> }
                { createCaraSubCategoryModal &&
                    <CreatePartElement part={ selectedPart } subCaraCategory partIndex={ partItemIndex }
                                       setOpenPartModal={ setCreateCaraSubCategoryDetailModal } /> }
                { openDeleteModal && (
                    <SimpleModal
                        icon={ trash }
                        title='supprimer une part'
                        info='Cette action supprimera le part'
                        closeOnclick={ () => setOpenDeleteModal(false) }
                        actionOnclick={ () => partId && dispatch(deletePart(partId)) }
                        deleteOrValide={ true }
                        closeLabel='Annuler'
                        actionLabel='Supprimer'
                    />
                ) }
                { openDeleteItemModal && (
                    <SimpleModal
                        icon={ trash }
                        title='Supprimer un item'
                        info='Cette action supprimera cette item et toutes ses dépendances '
                        closeOnclick={ () => setOpenDeleteItemModal(false) }
                        actionOnclick={ () => {
                            partRef && partItemRef && dispatch(deletePartItem({
                                partRef: partRef,
                                itemRef: partItemRef,
                            }));
                        } }
                        deleteOrValide={ true }
                        closeLabel='Annuler'
                        actionLabel='Supprimer'
                    />
                ) }
            </>
            <div className='product-detail-cara-left'>
                <span className='title'> PARTS </span>
                <div className='product-detail-cara-add'>
                    <div className='vertical-scroll'>
                        { partListRes.payload.content &&
                            partListRes.payload.content.map((part: Part, i: number) => {
                                return (
                                    <div
                                        className={ partId === part.id ? 'part focus' : 'part' }
                                        onClick={ () => {
                                            setSelectedPart(part);
                                            setPartId(part.id);
                                        } }
                                        key={ i }
                                    >
                                        <span> { part.name }</span>
                                        <div className='img-box'>
                                            <img
                                                onClick={ () => {
                                                    setCreatePartModal(false);
                                                    setUpdatePartModal(true);
                                                    setUpdateOn(true);
                                                    setPartRef(part.reference);
                                                    setPartName(part.name);
                                                } }
                                                alt='modifier'
                                                src={ update }
                                            />
                                            <img
                                                onClick={ () => {
                                                    setPartId(part.id);
                                                    setOpenDeleteModal(true);
                                                } }
                                                alt='supprimer'
                                                src={ trash }
                                            />
                                        </div>
                                    </div>
                                );
                            }) }
                    </div>
                    <div onClick={ () => setCreatePartModal(true) } className='add'>
                        <img src={ plus } alt='ajouter une part' />
                        <span>Créer une part</span>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className='product-detail-cara-right'>
                { selectedPart && (
                    <>
                        <span className='title right-cara'>{ selectedPart && `PART - ${ selectedPart.name.toUpperCase() }` }</span>
                        <div className='product-detail-cara-body'>
                            <div>
                                <div className='right-cara-title'>Caractéristiques</div>
                                <div className='detail-bloc'>
                                    {
                                        partCara.map((partItem: PartItem, i: number) => {
                                            return (
                                                <div className='bloc' key={ i }>
                                                    { generateComponents(partItem, [], 0, i, 'CHARACTERISTIC') }
                                                </div>
                                            );
                                        }) }
                                </div>
                                <div onClick={ () => setCreateCaraPartModal(true) } className='add-cara'>
                                    <img src={ plus } alt='ajout de caractéristique' />
                                    <span> Créer une caractéristique</span>
                                </div>

                                { props.currentProduct.first.productType === 'CONFIGURABLE' &&
                                    <>
                                        <div className='detail-bloc'>
                                            <div className='right-cara-title'>Options</div>
                                            {
                                                partOption.map((partItem: PartItem, i: number) => {
                                                    return (
                                                        <div className='bloc' key={ i }>
                                                            { generateComponents(partItem, [], 0, i, 'OPTION') }
                                                        </div>
                                                    );
                                                }) }
                                        </div>
                                        <div onClick={ () => setCreateOptionPartModal(true) } className='add-cara'>
                                            <img alt='ajout de caractéristique' src={ plus } />
                                            <span> Créer une option</span>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className='add-cara-btn-box'>
                                <button onClick={ () => onSubmit() }>Sauvegarder</button>
                            </div>
                        </div>
                    </>
                ) }
                {
                    !selectedPart && props.currentProduct.first.productType === 'COMPOSITION' &&
                    <div className='product-detail-cara-body'>
                        <div className='add-cara-btn-boxi'>
                            <button onClick={ () => onSubmit() }>Sauvegarder</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};
export default ProductDetailCharacteristic;
