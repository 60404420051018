import CreateCategoryForm from '../../uicomponents/create-category-form/create-category-form';
import Perm from '../../components/perm/perm';


const CreateCategoryPage = () => {
    return (
        <Perm permission='NEWS_BACKOFFICE'>
            <CreateCategoryForm />
        </Perm>
    );
};

export default CreateCategoryPage;