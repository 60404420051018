import apiClient from '../../../api-client';
import {
    CREATE_COMPOUNDED_PRESET,
    DELETE_COMPOUNDED_PRESET,
    FIND_COMPOUNDED_PRESET_BY_COMPOSITION_ID,
    RENAME_COMPOUNDED_PRESET,
    SAVE_COMPOUNDED_PRESET_CONFIGURATION,
} from '../../../endpoints/composition/compounded-preset/compounded-preset.endpoint';
import { CompositionWrapper, CompoundedModule } from '../../../../domain/domain';

const CompoundedPresetServices = {
    findAllCompoundedPresetByCompositionId: async (compositionRef: string) => apiClient(FIND_COMPOUNDED_PRESET_BY_COMPOSITION_ID(compositionRef), undefined, undefined, 'GET'),
    createCompoundedPreset: async (compositionRef: string, compoundedPresetName: string) => apiClient(CREATE_COMPOUNDED_PRESET(compositionRef, compoundedPresetName), undefined, undefined, 'POST'),
    deleteCompoundedPreset: async (compoundedModule: CompoundedModule) => apiClient(DELETE_COMPOUNDED_PRESET, compoundedModule, undefined, 'DELETE'),
    saveCompoundedPresetConfiguration: async (compoundedModuleId: number, wrapper: CompositionWrapper) =>
        apiClient(SAVE_COMPOUNDED_PRESET_CONFIGURATION(compoundedModuleId), wrapper, undefined, 'POST'),
    renameCompoundedPreset: async (compoundedModuleId: number, newName: string) => apiClient(RENAME_COMPOUNDED_PRESET(compoundedModuleId, newName), undefined, undefined, 'PUT'),
};
export default CompoundedPresetServices;
