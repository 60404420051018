const GET_CELL_MODEL_3D_INFO = (modelRef: string, partItemRef: string) => `v1/model-3d-cell/${modelRef}/${partItemRef}/get-cell`;
const DELETE_3D_CELL = (modelRef: string, partItemRef: string, tagId: number) => `v1/model-3d-cell/${modelRef}/${partItemRef}/${tagId}/delete-cell-item`;
const CREATE_3D_CELL = (modelRef: string, partItemRef: string) => `v1/model-3d-cell/${modelRef}/${partItemRef}/fill-cell`;
const GET_ALL_3D_CELL = () => `v1/model-3d-cell`;
const GET_ALL_CELLS_BY_PART_REFERENCE = (partReference: string) => `v1/model-3d-cell/${partReference}`;

const GET_ANCHOR_POINT = (model3dCellReference: string) => `v1/model-3d-cell/model3dCellReference/${model3dCellReference}/get-anchor-points`;

export {
    GET_CELL_MODEL_3D_INFO,
    DELETE_3D_CELL,
    CREATE_3D_CELL,
    GET_ALL_3D_CELL,
    GET_ALL_CELLS_BY_PART_REFERENCE,
    GET_ANCHOR_POINT,
};
