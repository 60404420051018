import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Engine } from "@lutithree/build/Engine";
import {MappingStudioApp} from "../../Libraries/Studios/MappingStudio/MappingStudioApp";
import {EngineViewer} from "../../../application3D-common/Components/EngineViewer/EngineViewer";
import {MappingStudioState, SetStudio} from "../../Redux/Reducers/MappingStudio/MappingStudioReducer";
import {RootState} from "../../../../services/redux/root-reducers";


const MappingStudioViewer = () => {
    const dispatch = useDispatch();
    const { mappingStudioApp } = useSelector<RootState, MappingStudioState>((state) => state.mappingStudioReducer);

    const buildApplication3d = (p_dom: HTMLDivElement) => {
        let engine = new Engine(p_dom);
        let enginePromise = engine.Initialize();
        enginePromise.then(() => {
            let game = new MappingStudioApp(engine);
            engine.Start();
            dispatch(SetStudio(game));
        });
    };

    const updateApplicationDomNode = (p_dom: HTMLDivElement) => {
        if (mappingStudioApp && p_dom) {
            mappingStudioApp.EngineService.UpdateDom(p_dom);
        }
    };

    return (
        <>
            <EngineViewer
                engine={ mappingStudioApp ? mappingStudioApp.EngineService : null }
                onDomNodeEnable={ (p_dom: HTMLDivElement) => buildApplication3d(p_dom) }
                onDomNodeUpdate={ (p_dom: HTMLDivElement) => updateApplicationDomNode(p_dom) }
            />
        </>
    );
};

export { MappingStudioViewer };
