import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { MeshPhysicalMaterial, Object3D } from 'three';
import Asset3D from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D";
import {MappingStudioState} from "../../Redux/Reducers/MappingStudio/MappingStudioReducer";
import {RootState} from "../../../../services/redux/root-reducers";
import {ZoningState3} from "../../../../services/reducers/mapping-zoning/zoning.reducer";


export type MeshLoaderManagerProps = {
    previewMesh?: Asset3D
    setObjectCallback: (p_object: Object3D) => void
};

const MeshLoaderManagerProps = (props: MeshLoaderManagerProps) => {
    const { mappingStudioApp } = useSelector<RootState, MappingStudioState>((state) => state.mappingStudioReducer);
    const updateZoning = useSelector<RootState, ZoningState3>((state) => state.updateZoning2);

    const [selectedMat] = useState<MeshPhysicalMaterial>(new MeshPhysicalMaterial({ color: "#249AF3" }));
    const [unselectedMat] = useState<MeshPhysicalMaterial>(new MeshPhysicalMaterial({ color: "grey" }));

    useEffect(() => {
        if (updateZoning.payload)
            mappingStudioApp?.MeshService.OutlineZone(updateZoning.payload.availableIds, selectedMat, unselectedMat);
        if (updateZoning.selectedIds)
            mappingStudioApp?.MeshService.OutlineZone(updateZoning.selectedIds, selectedMat, unselectedMat);
    }, [updateZoning]);

    useEffect(() => {
        return () => {
            mappingStudioApp?.MeshService.ClearManager();
        };
    }, []);

    useEffect(() => {
        if (!mappingStudioApp || !props.previewMesh) return;
        mappingStudioApp?.MeshService.OutlineZone(undefined, selectedMat, unselectedMat);
        mappingStudioApp.MeshService.LoadModel(props.previewMesh).then((object3D) => {
            object3D.traverse((obj)=>{
                obj.castShadow = false;
                obj.receiveShadow = false;
            });
            props.setObjectCallback(object3D);
            mappingStudioApp.Cameras.FocusPerspectiveCameraOnObjects([object3D],1.2);
            if (updateZoning.payload)
                mappingStudioApp?.MeshService.OutlineZone(updateZoning.payload.availableIds, selectedMat, unselectedMat);
        });
    }, [mappingStudioApp, props.previewMesh]);

    return <>
    </>;
};

export default MeshLoaderManagerProps;