import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {TextureType} from "../ParamTypes/TextureType";
import {BooleanType} from "../ParamTypes/BooleanType";
import {ButtonType} from "../ParamTypes/ButtonType";
import {SliderData, SliderType} from "../ParamTypes/SliderType";
import {InspectorType} from "../ParamTypes/InspectorType";
import {SetTypeOfCell} from "../../../Libraries/TweakPaneUtilities";
import {VectorData, VectorType} from "../ParamTypes/VectorType";
import {Vector2} from "three";

export class ClearCoat {
    public m_clearcoat: InspectorType;
    public m_clearcoatMapShow: InspectorType;
    public m_clearcoatMap: InspectorType;
    public m_clearcoatFlipY: InspectorType;
    public m_clearcoatClear: InspectorType;
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        //Albedo
        this.m_uiFolder = m_uiFolders = m_tab.pages[1].addFolder({title: 'ClearCoat (BW) [no texture]', expanded: false});
        this.m_clearcoat = new SliderType(new SliderData(0.0,1.0,0.01), m_matParams, "clearcoat", m_uiFolders);
        this.m_clearcoatMapShow = new BooleanType("Show map on mesh", m_matParams, "clearcoatMapShowOnMesh", m_uiFolders);
        this.m_clearcoatMap = new TextureType(MapType.clearCoatMap, m_matParams, "clearcoatMap", m_uiFolders);
        this.m_clearcoatFlipY = new BooleanType("Flip Y", m_matParams, "clearcoatMapFlipY", m_uiFolders);
        this.m_clearcoatClear = new ButtonType("Clear map", m_matParams, "", m_uiFolders);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_clearcoat.InitType();
        this.m_clearcoatMapShow.InitType();
        this.m_clearcoatMap.InitType();
        this.m_clearcoatFlipY.InitType();
        this.m_clearcoatClear.InitType();
    }
}

export class ClearcoatNormal {
    public m_clearcoatNormal: InspectorType;
    public m_clearcoatNormalMapShow: InspectorType;
    public m_clearcoatNormalMap: InspectorType;
    public m_clearcoatNormalFlipY: InspectorType;
    public m_clearcoatNormalClear: InspectorType;
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        //Albedo
        this.m_uiFolder = m_uiFolders = m_tab.pages[1].addFolder({title: 'clearcoat Normal (RGB) [no texture]', expanded: false});
        this.m_clearcoatNormal = new VectorType(new VectorData(new Vector2(-1, -1), new Vector2(1, 1)), m_matParams, "clearcoatNormalScale", m_uiFolders);
        this.m_clearcoatNormalMapShow = new BooleanType("Show map on mesh", m_matParams, "clearcoatNormalMapShowOnMesh", m_uiFolders);
        this.m_clearcoatNormalMap = new TextureType(MapType.clearCoatNormalMap, m_matParams, "clearcoatNormalMap", m_uiFolders);
        this.m_clearcoatNormalFlipY = new BooleanType("Flip Y", m_matParams, "clearcoatNormalMapFlipY", m_uiFolders);
        this.m_clearcoatNormalClear = new ButtonType("Clear map", m_matParams, "", m_uiFolders);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_clearcoatNormal.InitType();
        this.m_clearcoatNormalMapShow.InitType();
        this.m_clearcoatNormalMap.InitType();
        this.m_clearcoatNormalFlipY.InitType();
        this.m_clearcoatNormalClear.InitType();
    }
}

export class ClearCoatRoughness {
    public m_clearcoatRoughness: InspectorType;
    public m_clearcoatRoughnessMapShow: InspectorType;
    public m_clearcoatRoughnessMap: InspectorType;
    public m_clearcoatRoughnessFlipY: InspectorType;
    public m_clearcoatRoughnessClear: InspectorType;
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        //Albedo
        this.m_uiFolder = m_uiFolders = m_tab.pages[1].addFolder({title: 'clearcoat Roughness (BW) [no texture]', expanded: false});
        this.m_clearcoatRoughness = new SliderType(new SliderData(0.0, 1.0, 0.01), m_matParams, "clearcoatRoughness", m_uiFolders);
        this.m_clearcoatRoughnessMapShow = new BooleanType("Show map on mesh", m_matParams, "clearcoatRoughnessMapShowOnMesh", m_uiFolders);
        this.m_clearcoatRoughnessMap = new TextureType(MapType.clearCoatRoughnessMap, m_matParams, "clearcoatRoughnessMap", m_uiFolders);
        this.m_clearcoatRoughnessFlipY = new BooleanType("Flip Y", m_matParams, "clearcoatRoughnessMapFlipY", m_uiFolders);
        this.m_clearcoatRoughnessClear = new ButtonType("Clear map", m_matParams, "", m_uiFolders);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_clearcoatRoughness.InitType();
        this.m_clearcoatRoughnessMapShow.InitType();
        this.m_clearcoatRoughnessMap.InitType();
        this.m_clearcoatRoughnessFlipY.InitType();
        this.m_clearcoatRoughnessClear.InitType();
    }
}