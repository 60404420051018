import './catalog-product.scss';

import { Combobox } from 'react-widgets/cjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSuppliers, GetSuppliersState } from '../../../services/reducers/suppliers/suppliers.slice';
import { RootState } from '../../../services/root-reducer';
import {
    Catalog,
    Collection,
    ProductCatalogLine,
    ProductType,
    SectionWithSubSections,
    SubSection,
    Supplier,
} from '../../../domain/domain';
import { CollectionListState, getCollections } from '../../../services/reducers/suppliers/collection/collection.slice';
import fakeThumb from '../../../asset/chain.png';
import arrowDown from '../../../asset/Arrow-down.svg';
import validate from '../../../asset/Validation-blue.svg';
import {
    linkPresetToSubSection,
    LinkState,
    resetLinkPresetToSubSection,
    resetUnLinkPresetToSubSection,
    unLinkPresetToSubSection,
} from '../../../services/reducers/catalog/sub-section.slice';
import {
    getProductCatalogLines,
    ProductCatalogLinesState,
    resetGetProductCatalogLines,
} from '../../../services/reducers/product/product.reducers';
import {
    getTreeCatalog,
    linkProductToCatalog,
    LinkToCatalogState,
    resetLinkProductToCatalog,
    resetUnlinkProductToCatalog,
    TreeCatalogState,
    unlinkProductToSubCatalog,
    UnlinkToCatalogState,
} from '../../../services/reducers/catalog/catalog.slice';
import catalogServices from '../../../api/services/catalog/catalog.services';


export type CatalogProductsProps = {
    currentCatalog: Catalog
    selectedSupplier: Supplier | undefined
    setSelectSupplier: (selectedSupplier: Supplier | undefined) => void

    selectedCollection: Collection | undefined
    setSelectCollection: (selectedCollection: Collection | undefined) => void
};

const CatalogProducts = ({
                             currentCatalog,
                             selectedCollection,
                             setSelectCollection,
                             setSelectSupplier,
                             selectedSupplier,
                         }: CatalogProductsProps) => {
    const dispatch = useDispatch();

    //region selector
    const suppliers = useSelector<RootState, GetSuppliersState>((state) => state.getSuppliers);
    const collections = useSelector<RootState, CollectionListState>((state) => state.getCollections);
    const linkToSubSectionRes = useSelector<RootState, LinkState>((state) => state.linkToSubSection);
    const unLinkToSubSectionRes = useSelector<RootState, LinkState>((state) => state.unLinkToSubSection);
    const linkToCatalogRes = useSelector<RootState, LinkToCatalogState>(state => state.linkProductToCatalog);
    const unlinkToCatalogRes = useSelector<RootState, UnlinkToCatalogState>(state => state.unlinkProductToCatalog);
    const treeCatalog = useSelector<RootState, TreeCatalogState>(state => state.getTreeCatalog);
    const productLine = useSelector<RootState, ProductCatalogLinesState>(state => state.productCatalogLines);

    //endregion
    //#region state
    const [isMount, setIsmount] = useState(false);
    const [openDd, setOpenDd] = useState<{ id: number; open: boolean }>({ id: 0, open: false });


    const [Dd, setDd] = useState(false);
    const [master, setMaster] = useState(false);
    const [clickMaster, setClickMaster] = useState(false);
    const [catalogCheck, setCatalogCheck] = useState(false);
    //endregion
    //region effect
    useEffect(() => {
        !isMount && dispatch(resetGetProductCatalogLines());
        !isMount && dispatch(getSuppliers());
        !isMount && currentCatalog.id !== 0 && dispatch(getTreeCatalog(currentCatalog.id));
        return () => setIsmount(true);
    }, []);
    useEffect(() => {
        if (selectedSupplier) {
            setSelectCollection(undefined);
            dispatch(getCollections(selectedSupplier.reference));
        }
    }, [selectedSupplier]);
    useEffect(() => {
        if (collections.payload.content && collections.payload.errors.length === 0) {
            collections.payload.content.length > 0
                ? setSelectCollection(collections.payload.content[0])
                : dispatch(resetGetProductCatalogLines());
        }
    }, [collections]);
    useEffect(() => {
        selectedCollection && dispatch(getProductCatalogLines({
            collectionReference: selectedCollection.reference,
            catalogId: currentCatalog.id,
        }));
        dispatch(resetLinkPresetToSubSection());
        dispatch(resetUnLinkPresetToSubSection());
    }, [selectedCollection, linkToSubSectionRes, unLinkToSubSectionRes]);
    useEffect(() => {
        if (linkToCatalogRes.success) {
            selectedCollection && dispatch(getProductCatalogLines({
                collectionReference: selectedCollection.reference,
                catalogId: currentCatalog.id,
            }));
            dispatch(resetLinkProductToCatalog());
            // dispatch(resetGetProductCatalogLines());
        }
    }, [linkToCatalogRes]);
    useEffect(() => {
        if (unlinkToCatalogRes.success) {
            selectedCollection && dispatch(getProductCatalogLines({
                collectionReference: selectedCollection.reference,
                catalogId: currentCatalog.id,
            }));
            dispatch(resetUnlinkProductToCatalog());
            // dispatch(resetGetProductCatalogLines());

        }
    }, [unlinkToCatalogRes]);
    useEffect(() => {
        if (clickMaster) {
            if (!master) {
                catalogServices.removeAllProductsFromCollectionToCatalog(currentCatalog.id, selectedCollection?.id ?? 0)
                    .then(() => {
                        selectedCollection && dispatch(getProductCatalogLines({
                            collectionReference: selectedCollection.reference,
                            catalogId: currentCatalog.id,
                        }));
                    });
            } else {
                catalogServices.linkAllProductsFromCollectionToCatalog(currentCatalog.id, selectedCollection?.id ?? 0)
                    .then(() => {
                        selectedCollection && dispatch(getProductCatalogLines({
                            collectionReference: selectedCollection.reference,
                            catalogId: currentCatalog.id,
                        }));
                    });

            }
            setClickMaster(false);
        }
    }, [clickMaster]);
    useEffect(() => {
        if (productLine.payload.content !== null) {
            const allInCatalog = productLine.payload.content.every((p: ProductCatalogLine) => p.inCatalog);
            if (productLine.payload.content.length === 0) {
                setCatalogCheck(false);
            } else {
                allInCatalog ? setCatalogCheck(true) : setCatalogCheck(false);
            }
        }
    }, [collections, productLine]);
    useEffect(() => {
        const setTrue = () => {
            setMaster(true);
            setCatalogCheck(true);
        };
        const setFalse = () => {
            setMaster(false);
            setCatalogCheck(false);
        };

        master
            ? setTrue()
            : setFalse();
    }, [master]);
    //endregion
    //region methods
    const returnType = (type: ProductType) => {
        switch (type) {
            case 'COMPOSITION':
                return 'Modulable';
            case 'SIMPLE':
                return 'Simple';
            case 'PRESET':
                return 'Configurable';
        }
    };
    const linkAndUnlinkToSubSection = (catalogId: number, subSectionId: number, productId: number) => {
        !checkLinkedSubSections(productId, subSectionId)
            ? dispatch(
                linkPresetToSubSection({
                    catalogId: catalogId,
                    subSectionId: subSectionId,
                    productId: productId,
                }),
            ) : dispatch(
                unLinkPresetToSubSection({
                    catalogId: catalogId,
                    subSectionId: subSectionId,
                    productId: productId,
                }),
            );
    };
    const linkAndUnlinkToCatalog = (catalogId: number, productId: number, currentCheck: boolean) => {

        !currentCheck
            ? dispatch(
                linkProductToCatalog({
                    catalogId: catalogId,
                    productId: productId,
                }),
            ) : dispatch(
                unlinkProductToSubCatalog({
                    catalogId: catalogId,
                    productId: productId,
                }),
            );
    };
    const setDdToInitial = () => {
        setOpenDd({ id: 0, open: false });
    };
    const checkLinkedSubSections = (productId: number, subsectionId: number): boolean => {
        const current = productLine.payload.content.find((p) => p.product.id === productId);
        const find = current && current.subSectionList.find((s) => s.id === subsectionId);
        return find !== undefined;
    };

    //endregion
    return (
        <div className='catalog-product-main'>
            <div onClick={ setDdToInitial } className='catalog-product-top'>
                <div className='combo-bloc'>
                    <span>Fournisseur</span>
                    <Combobox
                        defaultValue={ undefined }
                        data={ suppliers.payload.content }
                        placeholder='Liste des fournisseurs'
                        onChange={ (e: Supplier | string) => {
                            setDd(false);
                            typeof e === 'object' && setSelectSupplier(e);
                        } }
                        dataKey='id'
                        textField='name'
                    />
                </div>
                <div className='combo-bloc'>
                    <span>Collection</span>
                    <div className='multiselect'>
                        <div
                            className='input'
                            onClick={ () => {
                                setDd(!Dd);
                            } }>
                            { selectedCollection && selectedCollection.name }
                        </div>
                        { Dd && (
                            <div className='dd'>
                                { collections.payload.content?.map((c: Collection) => {
                                    return (
                                        <div
                                            className='coll-name'
                                            onClick={ () => {
                                                setSelectCollection(c);
                                                setDd(false);
                                            } }
                                            key={ c.reference }
                                        >
                                            <div className={ selectedCollection?.reference === c.reference ? 'focus' : 'div' }>
                                                <span>{ c.name }</span>
                                            </div>
                                        </div>
                                    );
                                }) }
                            </div>
                        ) }
                    </div>
                </div>
                <button className='deep-product'>Modifier la profondeur de finitions</button>
            </div>
            <div className='catalog-product-body'>
                <div onClick={ setDdToInitial } className='catalog-product-body-head'>
                    <div>
                        <span>Nom</span>
                    </div>
                    <div>
                        <span>Dimensions</span>
                    </div>
                    <div>
                        <span>Type de produit</span>
                    </div>
                    <div>
                        <span>Sous rubriques</span>
                    </div>
                    <div className='master-c'>
                        <input
                            checked={ catalogCheck }
                            onChange={ () => {
                                setClickMaster(true);
                                setMaster(!master);
                            } } type='checkbox' />
                        <span>Associer au catalogue</span>
                    </div>
                </div>
                <div className='catalog-product-body-list'>
                    { productLine.payload.content && productLine.payload.content.map((p: ProductCatalogLine) => {
                        return (
                            <div key={ p.product.id } className='list-preset'>
                                <div onClick={ setDdToInitial } className='preset-name'>
                                    <img alt='vignette'
                                         src={ p.product.thumbnailUrl.length === 0
                                             ? fakeThumb
                                             : p.product.thumbnailUrl } />
                                    <span>{ p.product.name }</span>
                                </div>
                                <div onClick={ setDdToInitial }> {
                                    `L ${ p.product3DInfo.data.product_lenght } - H ${ p.product3DInfo.data.product_height } - P ${ p.product3DInfo.data.product_deft } `
                                }</div>
                                <div onClick={ setDdToInitial }>{ returnType(p.product.productType) }</div>
                                <div>
                                    <div className='combo-sub' onClick={ () => openDd.id === 0
                                        ? setOpenDd({
                                            id: p.product.id,
                                            open: true,
                                        })
                                        : setDdToInitial }>
                                        <div className='sub-prev-list'>
                                            { p.subSectionList.length === 0 &&
                                                <div className='pl'> Associer à des sous-rubriques</div> }
                                            {
                                                p.subSectionList.length < 4 ?
                                                    p.subSectionList.map((s: SubSection) => {
                                                        return (
                                                            <div className='sub-prev' key={ s.id }>
                                                                { s.name }
                                                            </div>
                                                        );
                                                    })
                                                    : p.subSectionList.slice(0, 3).map((s: SubSection) => {
                                                        return (
                                                            <div className='sub-prev' key={ s.id }>
                                                                { s.name }
                                                            </div>
                                                        );
                                                    })
                                            }
                                            { p.subSectionList.length < 4 ? '' : `+ ${ p.subSectionList.length - 3 }` }
                                        </div>
                                        <img onClick={ () => setOpenDd({ id: 0, open: false }) }
                                             alt='dropdown'
                                             src={ arrowDown } />
                                        { openDd?.id === p.product.id && <div className='opt-sub'>
                                            { treeCatalog.payload.content !== null && treeCatalog.payload.content.sections.map((s: SectionWithSubSections) => {
                                                return (
                                                    <>
                                                        { s.id !== null &&
                                                            <div className='opt' key={ s.id }>
                                                                <div
                                                                    className='opt-se-name'>{ s.name.toUpperCase() }</div>
                                                                {
                                                                    s.subSections.map((sub: SubSection) => {
                                                                        return (
                                                                            <div
                                                                                onClick={ () => treeCatalog.payload.content !== null && linkAndUnlinkToSubSection(
                                                                                    treeCatalog.payload.content.catalog.id,
                                                                                    sub.id,
                                                                                    openDd.id,
                                                                                ) }
                                                                                key={ sub.id }
                                                                                className={ !checkLinkedSubSections(p.product.id, sub.id) ? 'opt-sub-name' : 'opt-sub-name-checked' }>
                                                                                <div> { sub.name }</div>
                                                                                { checkLinkedSubSections(p.product.id, sub.id)
                                                                                    ? <img alt='valider'
                                                                                           src={ validate } />
                                                                                    : <div />
                                                                                }
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </>
                                                )
                                                    ;
                                            }) }
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className='check'>
                                    <input value={ p.product.id }
                                           checked={ p.inCatalog }
                                           onClick={ () =>
                                               treeCatalog.payload.content !== null && linkAndUnlinkToCatalog(
                                                   treeCatalog.payload.content.catalog.id,
                                                   p.product.id,
                                                   p.inCatalog,
                                               )
                                           }
                                           type='checkbox' />
                                </div>
                            </div>
                        );
                    }) }
                </div>
            </div>
            <div className='catalog-product-foot' />
        </div>
    )
        ;
};
export default CatalogProducts;
