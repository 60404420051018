import './applicable-coloris-modal.scss';
import MainPaper from '../../../../../uicomponents/paper/main-paper';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getFinish,
    getFinishesFromSupplierAndCommun,
    GetFinishesFromSupplierAndCommunState,
    GetFinishState,
} from '../../../../../services/reducers/suppliers/finish/finishes.slice';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../domain/Params/params-interface';
import { RootState } from '../../../../../services/root-reducer';
import {
    FindTagsByFinishIdState,
    getCommonSupplier,
    GetCommonSupplierState,
    getSupplierTexturesAndColoris,
    GetSupplierTexturesAndColorisState,
    resetFindTagsByFinishId,
} from '../../../../../services/reducers/suppliers/suppliers.slice';
import { Colori, Finish, Zone } from '../../../../../domain/domain';
import arrowRight from '../../../../../asset/arrow-right.svg';
import {
    ColorisFromCommonAndSupplierIdState,
    getAllColoris,
    GetAllColorisState,
    getColoriFromSupplierAndCommon,
} from '../../../../../services/reducers/suppliers/finish/coloris/coloris.slice';
import TagSelector from '../../../../tag-selector/tag-selector';
import { getFinishTag, GetFinishTagState } from '../../../../../services/reducers/tag/tag.slice';
import { MixedCheckbox } from '@reach/checkbox';
import '@reach/checkbox/styles.css';


type ApplicableColoriModalProps = {
    setOpenModal: (openModal: boolean) => void;
    setDefaultId: (defaultId: number) => void;
    defaultId: number;
    onSubmit: () => void;
    selectedZone?: Zone;
    colorMarked: Colori[];
    setColorMarked: Dispatch<SetStateAction<Colori[]>>;
    colorCommunMarked: Colori[];
    setColorCommunMarked: Dispatch<SetStateAction<Colori[]>>;
    finishFocus: number | undefined;
    setFinishFocus: (finishFocus: number | undefined) => void;
    currentZone: Zone
};
const ApplicableColoriModal = (props: ApplicableColoriModalProps) => {
    const dispatch = useDispatch();

    //#region state
    const [tagList, setTagList] = useState<number[]>([]);
    const [filterColoris, setFilterColoris] = useState<string>('');
    const { supplierReference } = useParams<Params>();
    const [supplierFinisList, setSupplierFinishList] = useState<Finish[]>([]);
    const [commonFinisList, setCommonFinishList] = useState<Finish[]>([]);
    const [nonCommonColoris, setNonCommonColoris] = useState<Colori[]>([]);
    const [commonColoris, setCommonColoris] = useState<Colori[]>([]);
    const [isMount, setIsMount] = useState(false);
    //endregion
    //#region selector
    const getFinishTagRes = useSelector<RootState, GetFinishTagState>((state) => state.getFinishTag);
    const finishRes = useSelector<RootState, GetFinishState>((state) => state.getFinish);
    const finishColor = useSelector<RootState, GetAllColorisState>((state) => state.getAllColoris);
    const supplierFinishesAndTextures = useSelector<RootState, GetSupplierTexturesAndColorisState>((state) => state.getSupplierTexturesAndColoris);
    const finishesSupplierAndCommun = useSelector<RootState, GetFinishesFromSupplierAndCommunState>((state) => state.getFinishesFromSupplierAndCommun);
    const tagsByFinishIdRes = useSelector<RootState, FindTagsByFinishIdState>((state) => state.findTagsByFinishId);
    const commonSupplier = useSelector<RootState, GetCommonSupplierState>((state) => state.commonSupplier);
    const colorisFromSupplierAndCommon = useSelector<RootState, ColorisFromCommonAndSupplierIdState>((state) => state.fetchAllColorisFromSupplierAndCommonRes);
    //endregion
    //#region selector
    useEffect(() => {
        !isMount && dispatch(resetFindTagsByFinishId());
        dispatch(getCommonSupplier());
        dispatch(getFinishTag('FINISH'));
        supplierReference && dispatch(getSupplierTexturesAndColoris(supplierReference));
        supplierReference && dispatch(getColoriFromSupplierAndCommon(supplierReference));
        supplierReference && dispatch(getFinishesFromSupplierAndCommun(supplierReference));
        return () => setIsMount(true);
    }, []);

    useEffect(() => {
        if (commonSupplier.payload.content !== undefined && commonSupplier.payload.content.reference.length > 0 && commonSupplier.payload.errors.length === 0) {
            dispatch(getSupplierTexturesAndColoris(commonSupplier.payload.content.reference));
        }
    }, [commonSupplier]);

    useEffect(() => {
        if (supplierFinishesAndTextures.payload.content !== undefined && supplierFinishesAndTextures.payload.content !== null) {
            if (supplierFinishesAndTextures.payload.content.colorisSet.length > 0 && supplierFinishesAndTextures.payload.content.colorisSet[0].finish.supplierReference !== 'COMMUN') {
                setNonCommonColoris(supplierFinishesAndTextures.payload.content?.colorisSet);
            } else {
                setCommonColoris(supplierFinishesAndTextures.payload.content?.colorisSet);
            }
        }
    }, [supplierFinishesAndTextures]);

    useEffect(() => {
        if (!colorisFromSupplierAndCommon || !colorisFromSupplierAndCommon.payload || !colorisFromSupplierAndCommon.payload.content) {
            return;
        }
        const selectedColoris = colorisFromSupplierAndCommon.payload.content.filter((cfs) => props.selectedZone && props.selectedZone.applicableColoriIds.includes(cfs.id));
        const selectedColorisFromCommun = selectedColoris.filter((c) => c.finish.supplierReference === 'COMMUN');
        const selectedColorisFromSupplier = selectedColoris.filter((c) => c.finish.supplierReference !== 'COMMUN');
        props.setColorMarked(selectedColorisFromSupplier);
        props.setColorCommunMarked(selectedColorisFromCommun);
    }, [colorisFromSupplierAndCommon]);

    useEffect(() => {
        setTagList(tagsByFinishIdRes.payload.content.map((tag) => tag.id));
    }, [tagsByFinishIdRes]);

    useEffect(() => {
        if (finishesSupplierAndCommun.payload.content && finishesSupplierAndCommun.payload.errors.length === 0) {
            const supplierFinishes = finishesSupplierAndCommun.payload.content.filter((finish: Finish) => {
                return finish.supplierReference !== 'COMMUN';
            });
            const commonFinishes = finishesSupplierAndCommun.payload.content.filter((finish: Finish) => {
                return finish.supplierReference === 'COMMUN';
            });
            setSupplierFinishList(supplierFinishes);
            setCommonFinishList(commonFinishes);
        }
    }, [finishesSupplierAndCommun]);

    useEffect(() => {
        if (supplierFinisList.length > 0) {
            props.setFinishFocus(supplierFinisList[0].id);
        }
    }, [supplierFinisList]);

    useEffect(() => {
        let tempSupplierFinishes: Finish[] = [];
        let tempsCommonFinishes: Finish[] = [];

        if (finishesSupplierAndCommun.payload.content && finishesSupplierAndCommun.payload.errors.length === 0) {
            const supplierFinishes = finishesSupplierAndCommun.payload.content.filter((finish: Finish) => {
                return finish.supplierReference !== 'COMMUN';
            });

            const commonFinishes = finishesSupplierAndCommun.payload.content.filter((finish: Finish) => {
                return finish.supplierReference === 'COMMUN';
            });

            if (tagList.length < 1) {
                setSupplierFinishList(supplierFinishes);
                setCommonFinishList(commonFinishes);
            } else {
                supplierFinishes &&
                supplierFinishes.map((finish: Finish) => {
                    for (let idx = 0; idx < finish.tags.length; idx++) {
                        if (tagList.includes(finish.tags[idx].id)) {
                            tempSupplierFinishes.push(finish);
                            break;
                        }
                    }
                });
                commonFinishes &&
                commonFinishes.map((finish: Finish) => {
                    for (let idx = 0; idx < finish.tags.length; idx++) {
                        if (tagList.includes(finish.tags[idx].id)) {
                            tempsCommonFinishes.push(finish);
                            break;
                        }
                    }
                });
                setSupplierFinishList(tempSupplierFinishes);
                setCommonFinishList(tempsCommonFinishes);
            }
        }
    }, [tagList]);

    useEffect(() => {
        props.finishFocus && dispatch(getFinish(props.finishFocus));
        props.finishFocus && dispatch(getAllColoris(props.finishFocus));
        props.finishFocus && dispatch(getCommonSupplier());
    }, [props.finishFocus]);
    //endregion
    const returnFinishName = () => {
        if (finishRes.payload.content) {
            if (finishRes.payload.content.name.length < 14) {
                return finishRes.payload.content.name.toUpperCase();
            } else {
                return `${ finishRes.payload.content.name.toUpperCase().substring(0, 11) }...`;
            }
        }
        return;
    };
    const handleCheck = (checked: boolean, color: Colori) => {
        if (checked) {
            if (color.finish.supplierReference === 'COMMUN') {
                props.setColorCommunMarked((prev) => [...prev, color]);
            } else {
                props.setColorMarked((prev) => [...prev, color]);
            }
        } else {
            if (color.finish.supplierReference === 'COMMUN') {
                props.setColorCommunMarked((prev) => {
                    return prev.filter((el) => el.id !== color.id);
                });
            } else {
                props.setColorMarked((prev) => {
                    return prev.filter((el) => el.id !== color.id);
                });
            }
        }
    };
    const dynamiqueInputCheck = (color: Colori) => {
        const isCommonSupplier = color.finish.supplierReference === 'COMMUN';
        if (isCommonSupplier) {
            const found = props.colorCommunMarked.find((element) => {
                return element.id === color.id;
            });
            return found !== undefined;
        } else {
            const found = props.colorMarked.find((element) => {
                return element.id === color.id;
            });
            return found !== undefined;
        }
    };
    const handleCheckFinish = (check: boolean, isChecked: any, finish: Finish) => {
        [...nonCommonColoris, ...commonColoris].forEach((colori) => {
            if (colori.finish.id === finish.id) {
                if (isChecked === 'mixed') {
                    handleCheck(false, colori);
                } else {
                    handleCheck(check, colori);
                }
            }
        });
    };
    const isFinishChecked = (finish: Finish) => {
        if (colorisFromSupplierAndCommon.payload.content !== null) {
            const colorisFromFinish = colorisFromSupplierAndCommon.payload.content.filter((el) => el.finish.id === finish.id);
            const markedFromFinish = [...props.colorMarked, ...props.colorCommunMarked].filter((f) => f.finish.id === finish.id);
            if (finish.supplierReference === 'COMMUN') {
                if (markedFromFinish.length === colorisFromFinish.length && colorisFromFinish.length > 0) {
                    return true;
                } else if (markedFromFinish.length === 0) {
                    return false;
                } else {
                    return 'mixed';
                }
            } else {
                if (markedFromFinish.length === colorisFromFinish.length && colorisFromFinish.length > 0) {
                    return true;
                } else if (markedFromFinish.length === 0) {
                    return false;
                } else {
                    return 'mixed';
                }
            }
        }
    };
    const checkIfDefaultRadioIsDisabled = (color: Colori) => {
        const allColorsMarked = [...props.colorMarked, ...props.colorCommunMarked];
        const found = allColorsMarked.find((el) => el.id === color.id);

        return found === undefined;
    };

    return (
        <div className='app-col-main'>
            <div className='app-col-paper'>
                <MainPaper>
                    <div className='app-col-body'>
                        <div className='top'>ASSOCIER DES COLORIS</div>
                        <div className='col-body'>
                            <div className='left'>
                                <div className='finishList'>
                                    <div className='finishList-top'>
                                        <span>FINITION</span>
                                        <div className='input-box'>
                                            <TagSelector
                                                placeholder='Filtrer par matière'
                                                onChange={ (newTagIdList) => {
                                                    setTagList(newTagIdList);
                                                } }
                                                selectedTags={ tagList }
                                                onCreate={ () => {
                                                } }
                                                tagList={ getFinishTagRes.payload.content }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className='finishList-middle'>{ supplierFinisList.length > 0 && supplierFinisList[0].supplierReference }</div>
                                    <div className='finishList-list'>
                                        <div className='common-list'>
                                            { supplierFinisList.map((finish) => {
                                                return (
                                                    <div
                                                        className={ props.finishFocus === finish.id ? 'finish focus' : 'finish' }
                                                        key={ finish.id }
                                                        onClick={ () => {
                                                            props.setFinishFocus(finish.id);
                                                        } }
                                                    >
                                                        <MixedCheckbox
                                                            onChange={ (e) => handleCheckFinish(e.target.checked, isFinishChecked(finish), finish) }
                                                            type='checkbox'
                                                            checked={ isFinishChecked(finish) }
                                                            onClick={ () => {
                                                                props.setFinishFocus(finish.id);
                                                            } }
                                                        />
                                                        <span>{ finish.name.length < 11 ? finish.name : `${ finish.name.substring(0, 10) }...` }</span>
                                                        <img alt='fleche' src={ arrowRight } />
                                                    </div>
                                                );
                                            }) }
                                        </div>
                                        <div className='list-middle'>COMMUN</div>
                                        <div className='common-list'>
                                            { commonFinisList.map((finish) => {
                                                return (
                                                    <div
                                                        className={ props.finishFocus === finish.id ? 'finish focus' : 'finish' }
                                                        key={ finish.id }
                                                        onClick={ () => {
                                                            props.setFinishFocus(finish.id);
                                                        } }
                                                    >
                                                        <MixedCheckbox
                                                            onChange={ (e) => handleCheckFinish(e.target.checked, isFinishChecked(finish), finish) }
                                                            type='checkbox'
                                                            checked={ isFinishChecked(finish) }
                                                            onClick={ () => {
                                                                props.setFinishFocus(finish.id);
                                                            } }
                                                        />
                                                        <span>{ finish.name }</span>
                                                        <img src={ arrowRight } />
                                                    </div>
                                                );
                                            }) }
                                        </div>
                                    </div>
                                </div>
                                <div className='colorList'>
                                    <div className='finishList-top'>
                                        <span>COLORIS</span>
                                        <div className='input-box'>
                                            <input
                                                className='textfield'
                                                onChange={ (e) => {
                                                    setFilterColoris(e.target.value);
                                                } }
                                                placeholder='Rechercher un coloris'
                                            ></input>
                                        </div>
                                    </div>
                                    <div className='color-middle'>
                                        <span>{ returnFinishName() }</span>
                                        <span>PAR DÉFAUT</span>
                                    </div>
                                    <div className='color-list'>
                                        { props.finishFocus && filterColoris?.length === 0
                                            ? finishColor.payload.content.map((color: Colori) => {
                                                return (
                                                    <div className='color-box' key={ color.id }>
                                                        <div className='color'>
                                                            <input checked={ dynamiqueInputCheck(color) }
                                                                   onChange={ (e) => handleCheck(e.target.checked, color) }
                                                                   type='checkbox' onClick={ () => {
                                                            } } />
                                                            <img alt='coloris' src={ color.thumbnailUrl } />
                                                            <span>{ color.code }{ ' ' }{ color.name }</span>
                                                        </div>
                                                        <>
                                                            <input
                                                                type='radio'
                                                                checked={ color.id === props.defaultId }
                                                                name='default'
                                                                disabled={ checkIfDefaultRadioIsDisabled(color) }
                                                                onChange={ (e) => props.setDefaultId(parseInt(e.target.value)) }
                                                                value={ color.id }
                                                                id={ color.name }
                                                            />
                                                            <label htmlFor={ color.name } />
                                                        </>
                                                    </div>
                                                );
                                            })
                                            : finishColor.payload.content
                                                .filter((color: Colori) => {
                                                    return color.name.toLowerCase().includes(filterColoris.toLowerCase());
                                                })
                                                .map((color) => (
                                                    <div className='color-box' key={ color.id }>
                                                        <div className='color'>
                                                            <input
                                                                type='radio'
                                                                checked={ color.id === props.defaultId }
                                                                name='default'
                                                                disabled={ checkIfDefaultRadioIsDisabled(color) }
                                                                onChange={ (e) => props.setDefaultId(parseInt(e.target.value)) }
                                                                value={ color.id }
                                                                id={ color.name }
                                                            />
                                                            <img alt='coloris' src={ color.thumbnailUrl } />
                                                            <span>{ color.code } bhbdhsqbdhqsdbhdsqbdsqhb{ color.name }</span>
                                                        </div>
                                                    </div>
                                                )) }
                                    </div>
                                </div>
                            </div>
                            <div className='right'>
                                <div className='app-col-btn-box'>
                                    <button
                                        className='cancel'
                                        onClick={ () => {
                                            setSupplierFinishList([]);
                                            props.setFinishFocus(undefined);
                                            props.setOpenModal(false);
                                        } }>
                                        Annuler
                                    </button>
                                    <button onClick={ props.onSubmit } className='action'>
                                        Valider
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </MainPaper>
            </div>
        </div>
    );
};

export default ApplicableColoriModal;
