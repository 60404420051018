import MdfApiResponse from '../../../domain/common/generic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SuppliersService from '../../../api/services/suppliers/suppliers.service';
import { Supplier, Tag, TextureColorisAggregation } from '../../../domain/domain';

// Create Suppliers
export type CreateSupplierState = {
    isLoading: boolean;
    payload: MdfApiResponse<Supplier[] | undefined>;
};

const initialCreateSupplierState: CreateSupplierState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

export const createSupplier = createAsyncThunk('create/supplier', async (data: { name: string; logo: any }) => {
    const res = await SuppliersService.createSuppliers(data);
    return res;
});

export const createSupplierSlice = createSlice({
    name: 'createSupplierSlice',
    initialState: initialCreateSupplierState,
    reducers: {
        resetCreateSupplier: (state) => {
            state = initialCreateSupplierState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createSupplier.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createSupplier.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createSupplier.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateSupplier } = createSupplierSlice.actions;

// get Suppliers
export type GetSuppliersState = {
    isLoading: boolean;
    payload: MdfApiResponse<Supplier[]>;
};

const initialGetSuppliersState: GetSuppliersState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const getSuppliers = createAsyncThunk('get/suppliers', async () => {
    const res = await SuppliersService.getSuppliers();
    return res;
});

export const getSuppliersSlice = createSlice({
    name: 'getSuppliersSlice',
    initialState: initialGetSuppliersState,
    reducers: {
        resetGetSuppliers: (state) => {
            state = initialGetSuppliersState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSuppliers.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getSuppliers.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getSuppliers.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetSuppliers } = getSuppliersSlice.actions;

// get Suppliers
export type GetSupplierState = {
    isLoading: boolean;
    payload: MdfApiResponse<Supplier | undefined>;
};
const initialGetSupplierState: GetSupplierState = {
    isLoading: false,
    payload: {
        content: undefined,
        errors: [],
        warnings: [],
    },
};
export const getSupplier = createAsyncThunk('get/supplier', async (reference: string) => {
    const res = await SuppliersService.getSupplier(reference);
    return res;
});
export const getSupplierSlice = createSlice({
    name: 'getSupplierSlice',
    initialState: initialGetSupplierState,
    reducers: {
        resetGetSupplier: (state) => {
            state = initialGetSupplierState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSupplier.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getSupplier.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getSupplier.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetSupplier } = getSupplierSlice.actions;

// get Supplier textures and coloris
export type GetSupplierTexturesAndColorisState = {
    isLoading: boolean;
    payload: MdfApiResponse<TextureColorisAggregation | undefined>;
};
const initialGetSupplierTexturesAndColorisState: GetSupplierTexturesAndColorisState = {
    isLoading: false,
    payload: {
        content: undefined,
        errors: [],
        warnings: [],
    },
};
export const getSupplierTexturesAndColoris = createAsyncThunk('get/supplierTexturesAndColoris', async (supplierReference: string) => {
    const res = await SuppliersService.getSuppliersTexturesAndColoris(supplierReference);
    return res;
});
export const getSupplierTexturesAndColorisSlice = createSlice({
    name: 'getSupplierTexturesAndColorisSlice',
    initialState: initialGetSupplierTexturesAndColorisState,
    reducers: {
        resetGetSupplierTexturesAndColoris: (state) => {
            state = initialGetSupplierTexturesAndColorisState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSupplierTexturesAndColoris.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getSupplierTexturesAndColoris.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getSupplierTexturesAndColoris.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetSupplierTexturesAndColoris } = getSupplierTexturesAndColorisSlice.actions;

// get common supplier
export type GetCommonSupplierState = {
    isLoading: boolean;
    payload: MdfApiResponse<Supplier>;
};
const initialGetCommonSupplierState: GetCommonSupplierState = {
    isLoading: false,
    payload: {
        content: {
            name: 'COMMUN',
            createdAt: new Date(),
            id: 0,
            logo: '',
            reference: '',
            updatedAt: new Date(),
            version: 0,
        },
        errors: [],
        warnings: [],
    },
};
export const getCommonSupplier = createAsyncThunk('get/supplier/common', async () => {
    const res = await SuppliersService.getCommon();

    return res;
});

export const getCommonSupplierSlice = createSlice({
    name: 'getCommonSupplierSlice',
    initialState: initialGetCommonSupplierState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCommonSupplier.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCommonSupplier.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getCommonSupplier.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

// update Suppliers
export type UpdateSupplierState = {
    isLoading: boolean;
    payload: MdfApiResponse<Supplier | null>;
};

const initialUpdateSupplierState: UpdateSupplierState = {
    isLoading: false,
    payload: { content: null, errors: [], warnings: [] },
};
type SupplierData = {
    reference: string;
    supplier: { name: string; reference: string; logo: string | null };
};
export const updateSupplier = createAsyncThunk('update/supplier', async (data: SupplierData) => {
    const res = await SuppliersService.updateSupplier(data.reference, data.supplier);
    return res;
});

export const updateSupplierSlice = createSlice({
    name: 'updateSupplierSlice',
    initialState: initialUpdateSupplierState,
    reducers: {
        resetUpdateSupplier: (state) => {
            state = initialUpdateSupplierState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateSupplier.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateSupplier.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateSupplier.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateSupplier } = updateSupplierSlice.actions;

// delete Suppliers
export type DeleteSupplierState = {
    isLoading: boolean;
    payload: MdfApiResponse<Supplier[]>;
};

const initialDeleteSupplierState: DeleteSupplierState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const deleteSupplier = createAsyncThunk('delete/supplier', async (reference: string) => {
    const res = await SuppliersService.deleteSupplier(reference);
    return res;
});

export const deleteSupplierSlice = createSlice({
    name: 'deleteSupplierSlice',
    initialState: initialDeleteSupplierState,
    reducers: {
        resetDeleteSupplier: (state) => {
            state = initialDeleteSupplierState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteSupplier.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteSupplier.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteSupplier.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteSupplier } = deleteSupplierSlice.actions;

// find Tags By Finish Id

export type FindTagsByFinishIdState = {
    isLoading: boolean;
    payload: MdfApiResponse<Tag[]>;
};

const initialFindTagsByFinishIdState: FindTagsByFinishIdState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const findTagsByFinishId = createAsyncThunk('suppliers/finishes/tags-by-id', async (finishId: number) => {
    const res = await SuppliersService.findTagsByFinishId(finishId);
    return res;
});

export const findTagsByFinishIdSlice = createSlice({
    name: 'findTagsByFinishIdSlice',
    initialState: initialFindTagsByFinishIdState,
    reducers: {
        resetFindTagsByFinishId: (state) => {
            state = initialFindTagsByFinishIdState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(findTagsByFinishId.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(findTagsByFinishId.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(findTagsByFinishId.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetFindTagsByFinishId } = findTagsByFinishIdSlice.actions;
