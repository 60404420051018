import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RolePermissionType, User } from '../../domain/domain';


type PermProps = {
    children: React.ReactNode
    permission: RolePermissionType
};
const Perm = (props: PermProps) => {
    const history = useHistory();
    const user = localStorage.getItem('user');
    const u: User = user !== null && JSON.parse(user);
    const role = u.roles.map((r) => r);
    const permission: RolePermissionType[] = role.map((r) => r.permissions.map(p => p.name)).flatMap((p) => p);

    useEffect(() => {
        if (!permission.includes(props.permission)) {
            permission.includes('ADMINISTRATION') && history.push('/home/users/active');
            permission.includes('NEWS_BACKOFFICE') && history.push('/home/news');
            permission.includes('BASE_PRODUIT') && history.push('/home/suppliers');
            permission.includes('STORE_MANAGER') && history.push('/stores');
        }
    }, []);
    return (
        <>
            { props.children }
        </>
    );
};
export default Perm;