import { CompositionModuleResponse } from '../../../../../../../../../domain/domain';

type AddModulePanelProps = {
    compositionModules: CompositionModuleResponse[]
    onModuleClick: (cm: CompositionModuleResponse) => any
};
const AddModulePanel = (props: AddModulePanelProps) => {
    return (
        <div className='composable-pb-panel'>
            <div className='composable-pb-panel-title'>Choisir le module de départ</div>
            <div className='composable-pb-panel-modules'>
                {props.compositionModules.map((cm: CompositionModuleResponse) => {
                    return (
                        <div onClick={() => props.onModuleClick(cm)} className='module' key={cm.product.id}>
                            <img alt={`vignette du module ${cm.product.name}`} src={cm.product.thumbnailUrl} />
                            <span> {cm.product.name}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default AddModulePanel;