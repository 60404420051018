import React, { useEffect, useState } from 'react';

import Layout from '../../uicomponents/layout/layout';
import MainPaper from '../../uicomponents/paper/main-paper';
import SupplierTable from '../../components/table/supplier-table/supplier-table';
import NavTag from '../../uicomponents/navTag/navTag';
import UpdateFinition from '../../components/modals/finition/update-finition/update-finition';
import SimpleModal from '../../uicomponents/modal/simple-modal/simple-modal';

import trash from '../../asset/poubelle-disabled.svg';
import { useDispatch } from 'react-redux';
import { deleteFinish, resetDeleteFinish } from '../../services/reducers/suppliers/finish/finishes.slice';
import { useLocation, useParams } from 'react-router-dom';


const Supplier = () => {
    const dispatch = useDispatch();
    const tabs = [{ name: 'Collections' }, { name: 'Finitions' }];
    const [tabName, setTabName] = useState('Collections');
    const [finishId, setFinsihId] = useState(0);
    const location = useLocation();
    const [openCollectionModal, setOpenCollectionModal] = useState(false);
    const [openFinitionModal, setOpenFinitionModal] = useState(false);
    const [openUpdateFinitionModal, setOpenUpdateFinitionModal] = useState(false);
    const [openDeleteFinitionModal, setOpenDeleteFinitionModal] = useState(false);

    const [collectionPage, setCollectionPage] = useState(false);
    const par: { supplierReference: string; name: string } = useParams();

    useEffect(() => {
        if (location.pathname.includes('collections')) {
            setCollectionPage(true);
        } else setCollectionPage(false);
    }, [location]);

    return (
        <Layout title='' pathUrl='supplier'>
            { openUpdateFinitionModal &&
                <UpdateFinition finishId={ finishId } setOpenUpdateFinitionModal={ setOpenUpdateFinitionModal } /> }
            { openDeleteFinitionModal && (
                <SimpleModal
                    icon={ trash }
                    deleteOrValide={ true }
                    closeLabel='Annuler'
                    actionLabel='Supprimer'
                    closeOnclick={ () => setOpenDeleteFinitionModal(false) }
                    actionOnclick={ () => {
                        dispatch(deleteFinish(finishId));
                        setOpenDeleteFinitionModal(false);
                        dispatch(resetDeleteFinish());
                    } }
                    title='Supprimer une finition'
                    info='Cette action supprimera cette finition'
                />
            ) }
            <NavTag
                openPage={ collectionPage ? `/collections/create/${ par.supplierReference }` : `/finishes/create/${ par.supplierReference }` }
                setOpenFirstTabModal={ setOpenCollectionModal }
                setOpenSecondTabModal={ setOpenFinitionModal }
                tagName={ tabName }
                setTagName={ setTabName }
                tabs={ tabs } />
            <MainPaper>
                <SupplierTable
                    setFinishId={ setFinsihId }
                    setOpenDeleteFinitionModal={ setOpenDeleteFinitionModal }
                    tabName={ tabName } />
            </MainPaper>
        </Layout>
    );
};
export default Supplier;
