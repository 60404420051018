import './mappings.scss';
import Zoning from './zoning/zoning';
import MappingBloc from './mappings/mapping';
import { useEffect, useState } from 'react';
import { getAllGroups } from '../../../../services/reducers/mapping-zoning/group-mapping.reducer';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../domain/Params/params-interface';
import MappingZoningStudio from './mapping-zoning-studio/mapping-zoning-studio';
import { GroupMapping, Mapping, Zone } from '../../../../domain/domain';


export const initialMapping: Mapping = {
    id: 0,
    version: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    name: '',
    zoningMetadata: { zoneList: [] },
    refOfPartList: [],
};

export const initialZone: Zone = {
    uuid: 'none',
    name: '',
    availableIds: [],
    applicableColoriIds: [],
    defaultColoriId: 0,
    nonConfigurableZone: false,
};

export const initialGroup: GroupMapping = {
    id: 0,
    version: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    name: '',
    collectionReference: '',
    mappings: [],
};
const Mappings = () => {
    const { collectionReference } = useParams<Params>();
    const dispatch = useDispatch();
    const [isMount, setIsMount] = useState(false);

    const [currentMapping, setCurrentMapping] = useState<Mapping>(initialMapping);
    const [currentGroup, setCurrentGroup] = useState<GroupMapping>(initialGroup);

    useEffect(() => {
        if (!isMount) {
            collectionReference && dispatch(getAllGroups(collectionReference));
            setIsMount(true);
        }
    }, []);

    return (
        <div className='mappings-main'>
            <MappingBloc
                currentGroup={ currentGroup }
                setCurrentGroup={ setCurrentGroup }
                currenMapping={ currentMapping }
                setCurrentMapping={ setCurrentMapping } />
            <MappingZoningStudio mapFocus={ currentMapping } />
            <Zoning
                currentGroup={ currentGroup }
                setCurrentGroup={ setCurrentGroup }
                currenMapping={ currentMapping }
            />
        </div>
    );
};
export default Mappings;
