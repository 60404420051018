import './mapping-zoning-studio.scss';
import 'react-widgets/styles.css';
import { Combobox } from 'react-widgets/cjs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Params } from '../../../../../domain/Params/params-interface';
import {
    getAllModels3dByCollection,
    Model3dListState,
} from '../../../../../services/reducers/product/3DModels/3DModels.reducers';
import { RootState } from '../../../../../services/root-reducer';
import { Colori, Mapping, ProductModel3d, Zone } from '../../../../../domain/domain';
import axios from 'axios';
import { ZoningState2 } from '../../../../../services/reducers/mapping-zoning/zoning.reducer';
import { getConfig } from '../../../../../api/config';
import Asset3D from '../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D';
import AssignmentData
    from "../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/AssignmentData";
import MappingStudio from "../../../../../application-3d/application3D-backoffice/Components/MappingStudio/MappingStudio";
import {onLoadingFail} from "../../../../../application-3d/application3D-common/Utils/loading-fail";


export type MappingZoningStudioProps = {
    mapFocus: Mapping | undefined;
};

const MappingZoningStudio = ({ mapFocus }: MappingZoningStudioProps) => {
    const dispatch = useDispatch();
    const [isMount, setIsMount] = useState(false);
    const { collectionReference } = useParams<Params>();

    const model3dRes = useSelector<RootState, Model3dListState>((state) => state.getAllModels3dByCollection);
    const updateZoningRes = useSelector<RootState, ZoningState2>((state) => state.updateZoning);

    //#region state
    const [selectedModel3dName, setSelectedModel3dName] = useState('');
    const [selectedModel3d, setSelectedModel3d] = useState<ProductModel3d | undefined>(undefined);
    const [model3dObject, setModel3dObject] = useState<Asset3D | undefined>(undefined);
    const [mappingObject, setMappingObject] = useState<Asset3D | undefined>(undefined);
    const [colorisList, setColorisList] = useState<Colori[]>([]);
    //#endregion
    //#region useEffect
    useEffect(() => {
        setSelectedModel3d(undefined);
        setModel3dObject(undefined);
        setMappingObject(undefined);
        setColorisList([]);
        !isMount && collectionReference && dispatch(getAllModels3dByCollection(collectionReference));
        return () => setIsMount(true);
    }, []);
    useEffect(() => {
        setModel3dObject(undefined);
        if (model3dRes && model3dRes.payload && model3dRes.payload.content) {
            const res = model3dRes.payload.content.filter((el: ProductModel3d) => el.name === selectedModel3dName);
            setSelectedModel3d(res[0]);
        }
    }, [selectedModel3dName]);
    useEffect(() => {
        let model3dBuf =
            selectedModel3d &&
            Object.assign(new Asset3D(), {
                ...selectedModel3d,
                type: 'Model',
                datas: [selectedModel3d.modelUrl],
            });
        setModel3dObject(model3dBuf);
    }, [selectedModel3d, mapFocus, colorisList]);
    useEffect(() => {
        setColorisList([]);
        mapFocus &&
        mapFocus.zoningMetadata.zoneList.map((el: Zone) => {
            axios
                .get(`${ getConfig().urlHostApi }/v1/suppliers/coloris/${ el.defaultColoriId }`, {
                    headers: {
                        Authorization: `Bearer ${ localStorage.getItem('mdf-jwt') }`,
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(
                    (res) => {
                        setColorisList((prevState) => [...prevState, res.data.content]);
                    },
                    () => {
                        setColorisList(() => []);
                    },
                );
        });
    }, [mapFocus, updateZoningRes]);
    useEffect(() => {
        setMappingObject(undefined);
        const findColorUrl = (zone: Zone) => {
            let colorUrl = '';
            colorisList.forEach((color: Colori) => {
                if (color.id === zone.defaultColoriId) {
                    colorUrl = color.modelUrl;
                }
            });
            return colorUrl;
        };
        if (colorisList.length === mapFocus?.zoningMetadata.zoneList.length) {
            let mapping3dBuf =
                mapFocus &&
                Object.assign(new Asset3D(), {
                    refOfPart: 'ref of part',
                    type: 'MaterialAssignment',
                    datas: mapFocus.zoningMetadata.zoneList.map((el: Zone) => {
                        const newAssignmentData = new AssignmentData();
                        newAssignmentData.LoadData(el.availableIds, [findColorUrl(el)]);
                        return newAssignmentData;
                    }),
                });
            setMappingObject(mapping3dBuf);
        } else {
            let mapping3dBuf =
                mapFocus &&
                Object.assign(new Asset3D(), {
                    refOfPart: 'ref of part',
                    type: 'MaterialAssignment',
                    datas: [],
                });
            setMappingObject(mapping3dBuf);
        }
    }, [colorisList]);
    //#endregion


    return (
        <div className='studio-main'>
            <div className='studio-top'>
                <span className='studio-title'>Sélectionner un modèle 3D</span>
                <Combobox
                    renderListItem={ ({ item }) => (
                        <span>{ item.substring(item.indexOf('-') + 1) }</span>
                    ) }
                    // @ts-ignore
                    textField={ item => item.split('-')[1] }
                    onChange={ (e) => setSelectedModel3dName(e) }
                    placeholder='Sélectionner un modèle 3D'
                    data={ model3dRes.payload.content !== null
                        ? model3dRes.payload.content.map((model3d) => model3d.name)
                        : [] } />
            </div>
            <div className='mapping-studio'>
                { selectedModel3d ? <MappingStudio previewMesh={ model3dObject } onLoadingFail={onLoadingFail}/> :
                    <div className='empty'> Choisir un modèle 3D ainsi qu'un mapping</div> }
            </div>
        </div>
    );
};
export default MappingZoningStudio;
