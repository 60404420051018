import MdfApiResponse from '../../../domain/common/generic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import productsService from '../../../api/services/products/products.service';
import { ModelTree, Pair, PresetTree, Product, Product3DInfo, ProductCatalogLine, ProductCreationForm, ProductInformationForm, ProductModel3dUpdateForm, Tag } from '../../../domain/domain';
import productTagsService from '../../../api/services/products/product-tags/product-tags.service';
import productService from '../../../api/services/product/product';

export type ProductListState = {
    isLoading: false;
    payload: MdfApiResponse<Product[]>;
};

const initialProductListState: ProductListState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};

export type PresetState = {
    isLoading: false;
    payload: MdfApiResponse<PresetTree>;
};

// const initialModelTreeState: ModelTreetState = {
//     isLoading: false,
//     payload: {
//         content: {
//             atom: {
//                 id: 0,
//                 version: 0,
//                 value: '',
//                 label: '',
//                 partItemReference: '',
//                 partItemName: '',
//                 partReference: '',
//                 partName: '',
//                 thumbnailUrl: '',
//                 reference: '',
//                 createdAt: new Date(),
//                 updatedAt: new Date(),
//             },
//             model3d: [
//                 {
//                     name: '',
//                     partReference: '',
//                     reference: '',
//                     modelUrl: '',
//                     anchorPoints: [],
//                     mappings: [
//                         {
//                             id: 0,
//                             name: '',
//                             zoneList: [
//                                 {
//                                     uuid: '',
//                                     name: '',
//                                     tagList: [],
//                                     defaultColori: {
//                                         id: 0,
//                                         version: 0,
//                                         name: '',
//                                         code: '',
//                                         modelUrl: '',
//                                         weight: 0,
//                                         colorR: 0,
//                                         colorG: 0,
//                                         colorB: 0,
//                                         finishId: 0,
//                                         createdAt: new Date(),
//                                         updatedAt: new Date(),
//                                         finish: {
//                                             id: 0,
//                                             version: 0,
//                                             supplierReference: '',
//                                             name: '',
//                                             reference: '',
//                                             typeVignette: 'A_PLAT',
//                                             businessDescription: '',
//                                             technicalDescription: '',
//                                             nonEditable: false,
//                                             nonDeletable: false,
//                                             logoUrl: '',
//                                             createdAt: new Date(),
//                                             updatedAt: new Date(),
//                                             tags: [
//                                                 {
//                                                     id: 0,
//                                                     version: 0,
//                                                     name: '',
//                                                     entityType: 'PRODUCT',
//                                                     tagSystem: false,
//                                                     createdAt: new Date(),
//                                                     updatedAt: new Date(),
//                                                 },
//                                             ],
//                                         },
//                                         nonDeletable: false,
//                                         nonEditable: false,
//                                         duplicateNumber: 0,
//                                         thumbnailUrl: '',
//                                         fromRal: false,
//                                         coloriParent: 0,
//                                         originalName: '',
//                                     },
//                                     nonConfigurableZone: false,
//                                     availableIds: [],
//                                 },
//                             ],
//                         },
//                     ],
//                 },
//             ],
//         },
//         errors: [],
//         warnings: [],
//     },
// };

export type ModelTreetState = {
    isLoading: boolean;
    payload: MdfApiResponse<ModelTree[]>;
};

const initialModelTreeListState: ModelTreetState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

const initialPresetState: PresetState = {
    isLoading: false,
    payload: {
        content: {
            id: 0,
            updatedAt: new Date(),
            subSectionList: [],
            createdAt: new Date(),
            configuration: [],
            isDefault: false,
            metadata: {},
            mappingConfiguration: [],
            product: {
                id: 0,
                parentProductReference: '',
                version: 0,
                name: '0',
                createdAt: new Date(),
                productType: 'COMPOSITION',
                creationStep: 0,
                completionStatus: 'CREATING',
                updatedAt: new Date(),
                reference: '',
                thumbnailUrl: '',
                deleted: false,
                collection: {
                    isSystem: false,
                    supplier: {
                        version: 0,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        id: 0,
                        reference: '',
                        logo: '',
                        name: '',
                    },
                    name: '',
                    version: 0,
                    id: 0,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    logo: '',
                    reference: '',
                },
            },
            product3DInfo: {
                updatedAt: new Date(),
                createdAt: new Date(),
                version: 0,
                productReference: '',
                id: 0,
                data: {},
            },
            thumbnailUrl: '',
        },
        errors: [],
        warnings: [],
    },
};

export type ProductState = {
    isLoading: boolean;
    payload: MdfApiResponse<Product | undefined>;
};
const initialProductState: ProductState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

export type ProductCatalogLinesState = {
    isLoading: boolean;
    payload: MdfApiResponse<ProductCatalogLine[]>;
};

const initialProductCatalogLinesState: ProductCatalogLinesState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};

export type ProductInfoState = {
    isLoading: boolean;
    payload: MdfApiResponse<Pair<Product, Product3DInfo> | undefined>;
};
const initialProductInfoState: ProductInfoState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

export type AssociatedTagListState = {
    isLoading: boolean;
    payload: MdfApiResponse<Tag[]>;
};

const initialTagList: AssociatedTagListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export type ModelTreeState = {
    isLoading: boolean;
    payload: MdfApiResponse<ModelTreetState[]>;
};

//<editor-fold desc="get-all-products">
export const getAllProducts = createAsyncThunk('get/all-products', async () => {
    const res = await productsService.getProducts();
    return res;
});

export const getAllProductsSlice = createSlice({
    name: 'getAllGroups',
    initialState: initialProductListState,
    reducers: {
        resetGetAllProducts: (state) => {
            state = initialProductListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllProducts.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllProducts.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllProducts.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetAllProducts } = getAllProductsSlice.actions;
//</editor-fold>

//<editor-fold desc="get-products-by-collection">
export const getAllProductsByCollection = createAsyncThunk('get/all-products-by-collection', async (collectionReference: string) => {
    const res = await productsService.getProductsByCollection(collectionReference);
    return res;
});
export const getAllProductsByCollectionSlice = createSlice({
    name: 'getAllProductsByCollection',
    initialState: initialProductListState,
    reducers: {
        resetGetAllProductsByCollection: (state) => {
            state = initialProductListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllProductsByCollection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllProductsByCollection.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllProductsByCollection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetAllProductsByCollection } = getAllProductsByCollectionSlice.actions;
//</editor-fold>

//<editor-fold desc="delete-product">
export const getChildrenProductsByCollection = createAsyncThunk('get/all-Children-products-by-collection', async (collectionReference: string) => {
    const res = await productsService.getChildrenProductsByCollection(collectionReference);
    return res;
});
export const getChildrenProductsByCollectionSlice = createSlice({
    name: 'getChildrenProductsByCollection',
    initialState: initialProductListState,
    reducers: {
        resetGetChildrenlProductsByCollection: (state) => {
            state = initialProductListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getChildrenProductsByCollection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getChildrenProductsByCollection.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getChildrenProductsByCollection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetChildrenlProductsByCollection } = getChildrenProductsByCollectionSlice.actions;
//#endregion

//<editor-fold desc="delete-product">
export const deleteProduct = createAsyncThunk('delete/products', async (productReference: string) => {
    const res = await productsService.deleteProductsById(productReference);
    return res;
});
export const deleteProductByIdSlice = createSlice({
    name: 'deleteProduct',
    initialState: initialProductState,
    reducers: {
        resetDeleteProduct: (state) => {
            state = initialProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteProduct.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteProduct } = deleteProductByIdSlice.actions;
//</editor-fold>

//<editor-fold desc="get-product-by-id">
export const getProductById = createAsyncThunk('get/products-by-id', async (productReference: string) => {
    const res = await productsService.getProductsById(productReference);
    return res;
});
export const getProductByIdSlice = createSlice({
    name: 'getAllProductsById',
    initialState: initialProductInfoState,
    reducers: {
        resetGetProductById: (state) => {
            state = initialProductInfoState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProductById.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProductById.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getProductById.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetProductById } = getProductByIdSlice.actions;
//</editor-fold>

//<editor-fold desc="product-catalog-line">
export const getProductCatalogLines = createAsyncThunk('get/products-catalog-lines', async (data: { collectionReference: string; catalogId: number }) => {
    const res = await productsService.productCatalogLines(data.collectionReference, data.catalogId);
    return res;
});
export const getProductCatalogLinesSlice = createSlice({
    name: 'getProductCatalogLines',
    initialState: initialProductCatalogLinesState,
    reducers: {
        resetGetProductCatalogLines: (state) => {
            state = initialProductCatalogLinesState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProductCatalogLines.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProductCatalogLines.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getProductCatalogLines.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetProductCatalogLines } = getProductCatalogLinesSlice.actions;
//</editor-fold>

//<editor-fold desc="create-product">
export const createProduct = createAsyncThunk('create/product', async (productForm: ProductCreationForm) => {
    const res = await productsService.createProduct(productForm);
    return res;
});

export const createProductSlice = createSlice({
    name: 'createProduct',
    initialState: initialProductState,
    reducers: {
        resetCreateProducts: (state) => {
            state = initialProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createProduct.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateProducts } = createProductSlice.actions;

//</editor-fold>

//<editor-fold desc="create-product-info">
export const createProductInfo = createAsyncThunk('create/productInfo', async (productForm: ProductInformationForm) => {
    const res = await productsService.createProductInfo(productForm);
    return res;
});

export const createProductInfoSlice = createSlice({
    name: 'createProductInfo',
    initialState: initialProductInfoState,
    reducers: {
        resetCreateProductInfo: (state) => {
            state = initialProductInfoState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createProductInfo.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createProductInfo.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createProductInfo.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateProductInfo } = createProductInfoSlice.actions;

//</editor-fold>

//<editor-fold desc="save-product-cara">
export const saveProductCara = createAsyncThunk('save/productCara', async (productRef: string) => {
    const res = await productsService.saveProductCara(productRef);
    return res;
});

export const saveProductCaraSlice = createSlice({
    name: 'saveProductCara',
    initialState: initialProductState,
    reducers: {
        resetSaveProductCara: (state) => {
            state = initialProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(saveProductCara.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(saveProductCara.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(saveProductCara.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetSaveProductCara } = saveProductCaraSlice.actions;

export const updateProductModel3DName = createAsyncThunk('save/updateProductModel3DName', async (model3dUpdateForm: ProductModel3dUpdateForm) => {
    const res = await productsService.updateProductModel3DName(model3dUpdateForm);
    return res;
});

export const updateProductModel3DNameSlice = createSlice({
    name: 'updateProductModel3DName',
    initialState: initialProductState,
    reducers: {
        resetUpdateProductModel3DName: (state) => {
            state = initialProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateProductModel3DName.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateProductModel3DName.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateProductModel3DName.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateProductModel3DName } = updateProductModel3DNameSlice.actions;

//</editor-fold>

//<editor-fold desc="save-product-thumb-info">
export const saveProductThumbInfo = createAsyncThunk('save/productThumbInfo', async (productRef: string) => {
    const res = await productsService.saveThumbnailInformation(productRef);
    return res;
});

export const saveProductThumbInfoSlice = createSlice({
    name: 'saveProductThumbInfo',
    initialState: initialProductState,
    reducers: {
        resetSaveProductThumbInfo: (state) => {
            state = initialProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(saveProductThumbInfo.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(saveProductThumbInfo.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(saveProductThumbInfo.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetSaveProductThumbInfo } = saveProductThumbInfoSlice.actions;

//</editor-fold>

//<editor-fold desc="getProductTagsByReference">
export const getProductAssociatedTagsByReference = createAsyncThunk('get/getProductTagsByReference', async (productReference: string) => {
    const res = await productTagsService.getProductTags(productReference);
    return res;
});
export const getProductAssociatedTagsByReferenceSlice = createSlice({
    name: 'getProductAssociatedTagsByReferenceSlice',
    initialState: initialTagList,
    reducers: {
        resetGetProductAssociatedTagsByReference: (state) => {
            state = initialTagList;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProductAssociatedTagsByReference.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProductAssociatedTagsByReference.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getProductAssociatedTagsByReference.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetProductAssociatedTagsByReference } = getProductAssociatedTagsByReferenceSlice.actions;
//</editor-fold>

//#region get products by statut
export const getProductDefaultPreset = createAsyncThunk('get/productDefaultPreset', async (productRef: string) => {
    const res = await productService.getProductDefaultPreset(productRef);
    return res;
});

export const getProductDefaultPresetSlice = createSlice({
    name: 'getProductDefaultPreset',
    initialState: initialPresetState,
    reducers: {
        resetGetProductDefaultPreset: (state) => {
            state = initialPresetState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProductDefaultPreset.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProductDefaultPreset.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getProductDefaultPreset.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetProductDefaultPreset } = getProductDefaultPresetSlice.actions;
//#endregion

//#find configuration possible from an model3dCellReference
export const getConfigurationByModel3dCellReference = createAsyncThunk('get/findConfigurationByModel3dCellReference', async (data: { coreProductReference: string; model3dCellReference: string }) => {
    const res = await productsService.getConfigurationByModel3dCellReference(data.coreProductReference, data.model3dCellReference);
    return res;
});

export const getConfigurationByModel3dCellReferenceSlice = createSlice({
    name: 'getConfigurationByModel3dCellReferenceSlice',
    initialState: initialModelTreeListState,
    reducers: {
        resetGetConfigurationByModel3dCellReference: (state) => {
            state = initialModelTreeListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getConfigurationByModel3dCellReference.pending, (state) => {
            // @ts-ignore
            state.isLoading = false;
        });
        builder.addCase(getConfigurationByModel3dCellReference.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getConfigurationByModel3dCellReference.rejected, (state) => {
            // @ts-ignore
            state.isLoading = false;
        });
    },
});

export const { resetGetConfigurationByModel3dCellReference } = getConfigurationByModel3dCellReferenceSlice.actions;
//#endregion
