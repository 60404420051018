import './generate-thumb.scss';
import close from '../../../../asset/CloseWindow.svg';
import React, { useEffect, useState } from 'react';
import { Colori, Mapping, Model3DCellValue, Part, ProductModel3d, Zone } from '../../../../domain/domain';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllModels3dByTag,
    Model3dListState,
} from '../../../../services/reducers/product/3DModels/3DModels.reducers';
import { RootState } from '../../../../services/root-reducer';
import { Vector2 } from 'three';
import axios from 'axios';
import { getProduct3DThumbsbyPart } from '../../../../services/reducers/product/3DThumbnails/3dThumbnails.reducers';
import { getConfig } from '../../../../api/config';
import {
    PointOfViewPlacement
} from "../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Features3D/PointOfViews";
import {
    CharacteristicStudioState, setPointOfViewPlacement
} from "../../../../application-3d/application3D-backoffice/Redux/Reducers/characteristic-studio/CharacteristicStudioReducer";
import Asset3D from "../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D";
import Model3dData
    from "../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Model3dData";
import AssignmentData
    from "../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/AssignmentData";
import {onLoadingFail} from "../../../../application-3d/application3D-common/Utils/loading-fail";
import CharacteristicStudio
    from "../../../../application-3d/application3D-backoffice/Components/CharacteristicStudio/CharacteristicStudio";


type GenerateThumbProps = {
    setOpenModal: (openModal: boolean) => void;
    part: Part;
    cellId: number;
    reference: string;
    cell: Model3DCellValue;
    generateDone: boolean;
    setGenerate: (generateDone: boolean) => void;
    setToasterImg: (toasterImg: string) => void;
};

const GenerateThumb = (props: GenerateThumbProps) => {

    const dispatch = useDispatch();
    const models3d = useSelector<RootState, Model3dListState>((state) => state.getAllModels3DByTag);
    const characteristicStudioApp = useSelector<RootState, CharacteristicStudioState>((state) => state.characteristicStudioReducer);

    const [valide, setValide] = useState(false);
    const [selectModel3d, setSelectedModel3d] = useState<ProductModel3d>();
    const [model3dObject, setModel3dObject] = useState<Asset3D | undefined>(undefined);
    const [selectMapping, setSelectedMapping] = useState<Mapping | undefined>(undefined);
    const [mappingObject, setMappingObject] = useState<Asset3D | undefined>(undefined);
    const [selectView, setSelectedView] = useState<number>(1);
    const [snapShot, setSnap] = useState<Blob | undefined>(undefined);
    const [colorisList, setColorisList] = useState<Colori[]>([]);
    const [mount, setMount] = useState(false);
    const {
        pointOfViewPlacement,
    } = useSelector<RootState, CharacteristicStudioState>((state) => state.characteristicStudioReducer);
    const SetUndefined = () => {
        setSelectedModel3d(undefined);
        setModel3dObject(undefined);
        setMappingObject(undefined);
        setSelectedMapping(undefined);
        setSelectedView(2);
        dispatch(setPointOfViewPlacement(PointOfViewPlacement.THREE_QUARTER));
    };
    const uploadAndDispatch = async (snap: Blob) => {
        let formData = new FormData();
        // @ts-ignore
        formData.append('file', snap);
        await axios
            .post(`${ getConfig().urlHostApi }/v1/model-3d-cell-thumbnails/${ props.cellId }/upload-thumbnail-image`, formData, {
                headers: {
                    Authorization: `Bearer ${ localStorage.getItem('mdf-jwt') }`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                props.setOpenModal(false);
                SetUndefined();
                dispatch(getProduct3DThumbsbyPart(props.reference));
                setSnap(undefined);
                props.setGenerate(true);
                props.setToasterImg(res.data.content.thumbnailUrl);
            })
            .catch((e) => console.log(e));
    };

    //#region useEffect
    useEffect(() => {
        dispatch(setPointOfViewPlacement(PointOfViewPlacement.THREE_QUARTER));
        SetUndefined();
    }, []);
    useEffect(() => {
        !mount && dispatch(getAllModels3dByTag({
            partItemRef: props.cell.partItemReference,
            tagRef: props.cell.reference,
        }));
        return () => setMount(true);
    }, [props.part]);
    useEffect(() => {
        selectModel3d && setValide(true);
        //#region model3d to asset3d
        let datas = selectModel3d && Object.assign(new Model3dData(), {
            ...selectModel3d,
            urls:
                [selectModel3d.modelUrl],
        });
        let model3dBuf =
            selectModel3d &&
            Object.assign(new Asset3D(), {
                ...selectModel3d,
                type: 'Model3d',
                datas,
            });
        setModel3dObject(model3dBuf);
        //#endregion
    }, [selectModel3d]);
    useEffect(() => {
        //#region mapping3d to asset3d
        selectMapping &&
        selectMapping.zoningMetadata.zoneList.map((el: Zone) => {
            axios
                .get(`${ getConfig().urlHostApi }/v1/suppliers/coloris/${ el.defaultColoriId }`, {
                    headers: {
                        Authorization: `Bearer ${ localStorage.getItem('mdf-jwt') }`,
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    setColorisList((prevState) => [...prevState, res.data.content]);
                });
        });
        //#endregion
    }, [selectMapping, selectView]);
    useEffect(() => {
        const findColorUrl = (zone: Zone) => {
            let colorUrl = '';
            colorisList.forEach((color: Colori) => {
                if (color.id === zone.defaultColoriId) {
                    colorUrl = color.modelUrl;
                }
            });
            return colorUrl;
        };
        if (colorisList.length === selectMapping?.zoningMetadata.zoneList.length) {
            let mapping3dBuf =
                selectMapping &&
                Object.assign(new Asset3D(), {
                    refOfPart: 'ref of part',
                    type: 'MaterialAssignment',
                    datas: selectMapping.zoningMetadata.zoneList.map((el: Zone) => {
                        const newAssignmentData = new AssignmentData();
                        newAssignmentData.LoadData(el.availableIds, [findColorUrl(el)]);
                        return newAssignmentData;
                    }),
                });
            setMappingObject(mapping3dBuf);
        }
    }, [colorisList]);
    useEffect(() => {
        if (snapShot !== undefined) {
            uploadAndDispatch(snapShot).then(() => console.log('uploaded'));
        }
    }, [snapShot]);
    useEffect(() => {
        if (selectView !== PointOfViewPlacement.TOP
            && selectView !== PointOfViewPlacement.TOP_90
            && selectView !== PointOfViewPlacement.THREE_QUARTER
            && selectView !== PointOfViewPlacement.THREE_QUARTER_OFF
            && selectView !== PointOfViewPlacement.FRONT) {
            dispatch(setPointOfViewPlacement(PointOfViewPlacement.FREE));
            console.log(selectView);
        }
    }, [selectView]);
    //endregion

    return (
        <div className='gen-main'>
            <div className='gene-paper'>
                <div className='gen-top'>
                    <span>GÉNÉRER UNE VIGNETTE POUR { props.cell.value.toUpperCase() } </span>
                    <img
                        onClick={ () => {
                            SetUndefined();
                            characteristicStudioApp.characteristicStudioApp?.Services.MeshService.ClearManager();
                            props.setOpenModal(false);
                        } }
                        alt='femer la modal'
                        src={ close }
                    />
                </div>
                <div className='gen-body'>
                    <div className='gen-body-left'>
                        <span>PARAMÈTRES DU MODÈLE</span>
                        <div className='gen-form'>
                            <>
                                <label>Modèle 3D</label>
                                <select
                                    placeholder='Choisir un modèle 3D'
                                    value={ JSON.stringify(selectModel3d) }
                                    onChange={ (e) =>
                                        e.target.value !== ''
                                            ?
                                            (setSelectedModel3d(JSON.parse(e.target.value)),
                                                setSelectedMapping(undefined),
                                                setMappingObject(undefined))
                                            :
                                            (setSelectedModel3d(undefined),
                                                setSelectedMapping(undefined))
                                    }
                                >
                                    <option value=''>Choisir un modèle 3D</option>
                                    { models3d.payload.content.map((model3d) => {
                                        return (
                                            <option key={ model3d.id } value={ JSON.stringify(model3d) }>
                                                { model3d.name.substring(model3d.name.indexOf('-') + 1)}
                                            </option>
                                        );
                                    }) }
                                </select>
                                <>
                                    <label>Mapping</label>
                                    <select
                                        className={ selectModel3d && selectModel3d.mappings.length > 0 && true ? '' : 'dropdown-option' }
                                        onChange={ (e) => (e.target.value !== '' ? (setColorisList([]), setSelectedMapping(JSON.parse(e.target.value))) : setSelectedMapping(undefined)) }
                                    >
                                        <option
                                            value=''>{ selectModel3d && selectModel3d.mappings.length > 0 ? 'Choisir un mapping' : '' }</option>
                                        { selectModel3d &&
                                            selectModel3d.mappings.map((mapping: Mapping) => {
                                                return (
                                                    <option key={ mapping.id } value={ JSON.stringify(mapping) }>
                                                        { mapping.name }
                                                    </option>
                                                );
                                            }) }
                                    </select>
                                </>
                            </>
                            <>
                                <span className='view-name'>PARAMÈTRE DU POINT DE VUE DE LA VIGNETTE</span>
                                <label>Point de vue</label>
                                <select defaultValue={ pointOfViewPlacement } onChange={ (v) => {
                                    dispatch(setPointOfViewPlacement(parseInt(v.target.value)));
                                } }>
                                    <option value={ PointOfViewPlacement.TOP }>TOP</option>
                                    <option value={ PointOfViewPlacement.TOP_90 }>TOP_90</option>
                                    <option value={ PointOfViewPlacement.THREE_QUARTER }>THREE_QUARTER</option>
                                    <option value={ PointOfViewPlacement.THREE_QUARTER_OFF }>THREE_QUARTER_OFF</option>
                                    <option value={ PointOfViewPlacement.FRONT }>FRONT</option>
                                    <option selected={ pointOfViewPlacement === 5 }
                                            value={ PointOfViewPlacement.FREE }>FREE
                                    </option>
                                </select>
                            </>
                        </div>
                    </div>
                    <div className='gen-body-right'>
                        <span>PREVIEW DE LA VIGNETTE</span>
                        <div className={ selectModel3d ? 'thumb-preview-cont' : 'thumb-preview-cont empty' }>
                            { selectModel3d ? (
                                <CharacteristicStudio previewMesh={ model3dObject }
                                                               cameraPointOfView={ selectView }
                                                               previewMapping={ mappingObject }
                                                               onLoadingFail={ onLoadingFail }
                                                               setPovActivated={ false } />
                            ) : (
                                <div>Selectionner un modele 3D</div>
                            ) }
                        </div>
                    </div>
                </div>
                <div className='gen-btn'>
                    <button
                        onClick={ () => {
                            SetUndefined();
                            characteristicStudioApp.characteristicStudioApp?.Services.MeshService.ClearManager();
                            props.setOpenModal(false);
                        } }
                        className='gen-btn-1'
                    >
                        Annuler
                    </button>
                    <button
                        onClick={ () => valide && characteristicStudioApp.characteristicStudioApp?.Services.ScreenshotService.Take3DSnapshot(new Vector2(120,120)).then((res) => setSnap(res)) }
                        className={ valide ? 'gen-btn-2 valide' : 'gen-btn-2' }
                    >
                        Générer
                    </button>
                </div>
            </div>
        </div>
    );
};
export default GenerateThumb;
