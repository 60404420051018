import './add-users-to-store.scss';
import Layout from '../../../uicomponents/layout/layout';
import MainPaper from '../../../uicomponents/paper/main-paper';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/root-reducer';
import { getActiveUser, getMe, GetMeState, UserListState } from '../../../services/reducers/users/users.slice';
import { Combobox } from 'react-widgets/esm';
import { User } from '../../../domain/domain';
import { useForm } from 'react-hook-form';
import {
    addUserToStore,
    getStoreByReference, resetAddUserToStore, StoreState,
} from '../../../services/reducers/store/store.slice';
import { useHistory, useParams } from 'react-router-dom';
import { Params } from '../../../domain/Params/params-interface';
import { createUser, resetCreateUser } from '../../../services/reducers/create-user/create-user.slice';
import MdfApiResponse from '../../../domain/common/generic';
import Loader from '../../../uicomponents/loader/loader';

export const initialUser: User = {
    id: 0,
    version: 0,
    updatedAt: new Date(),
    createdAt: new Date(),
    email: '',
    connectionAt: new Date(),
    firstname: '',
    lastname: '',
    removed: false,
    roles: [],
    status: 'NEVER_CONNECTED',
    resetPasswordToken: '',
    responsibleId: 0,
};

export type Inputs = {
    lastname: string,
    firstname: string,
    email: string,
};


const AddUsersToStore = () => {
    type UserSelector = {
        isLoading: boolean;
        isRejected: boolean;
        payload: MdfApiResponse<User | null>;
    };
    const dispatch = useDispatch();
    const { storeRef } = useParams<Params>();
    const history = useHistory();
    const {
        register,
        watch,
        setValue,
    } = useForm<Inputs>();

    //region state
    const [isMount, setIsMount] = useState(false);
    const [existUser, setExistUser] = useState<User>(initialUser);
    const [createUseri, setCreateUseri] = useState<boolean>(false);
    const [, setErr] = useState<boolean>(false);
    const [type, setType] = useState(0);
    const [emailErrMsg, setEmailErrMsg] = useState('');
    const [nameErrMsg, setNameErrMsg] = useState('');
    const [fNameErrMsg, setFNameErrMsg] = useState('');
    const [rspAndSelList, setRspAndSelList] = useState<User[]>([]);
    const [inside, setInside] = useState(false);

    const lastName = watch('lastname', '');
    const firstname = watch('firstname', '');
    const email = watch('email', '');
    //endregion

    //region useSelector
    const usersRes = useSelector<RootState, UserListState>(state => state.getActiveUsers);
    const addUserRes = useSelector<RootState, StoreState>(state => state.addUserToStore);
    const s = useSelector<RootState, StoreState>(state => state.getStoreByRef);
    const createUserRes = useSelector<RootState, UserSelector>(state => state.createUser);
    const me = useSelector<RootState, GetMeState>(state => state.getMe);

    //endregion
    //region useEffect
    useEffect(() => {
        !isMount && dispatch(getActiveUser());
        !isMount && dispatch(getStoreByReference(storeRef));
        !isMount && dispatch(getMe());
        !isMount && setValue('lastname', '');
        !isMount && setValue('firstname', '');
        !isMount && setValue('email', '');
        return () => setIsMount(true);
    }, []);
    useEffect(() => {
        setAllFalse();
        if (lastName.length > 0 || firstname.length > 0 || email.length > 0) {
            setCreateUseri(true);
        } else {
            setCreateUseri(false);
        }
    }, [lastName, firstname, email]);
    useEffect(() => {
        if (addUserRes.payload.content.id !== 0 && addUserRes.payload.errors.length === 0) {
            setExistUser(initialUser);
            history.push(`/store/${ addUserRes.payload.content.reference }/${ addUserRes.payload.content.name }`);
            dispatch(resetAddUserToStore());
        }
    }, [addUserRes]);
    useEffect(() => {
        if (createUserRes.payload.content !== null && createUserRes.payload.errors.length === 0 && inside) {
            type === 0 && dispatch(addUserToStore({
                storeRef: storeRef,
                userId: createUserRes.payload.content.id,
                roleName: 'ROLE_STORE_MANAGER',
            }));
            type === 1 && dispatch(addUserToStore({
                storeRef: storeRef,
                userId: createUserRes.payload.content.id,
                roleName: 'ROLE_SELLER',
            }));
            dispatch(resetCreateUser());
            dispatch(resetAddUserToStore());
            setInside(false);
        }
    }, [createUserRes]);
    useEffect(() => {
        let RList: User[] = s.payload.content.responsibleSet;
        let SList: User[] = s.payload.content.sellerSet;
        let rAndSList: User[] = RList !== undefined && SList !== undefined ? RList.concat(SList) : [];
        let allUser = [...usersRes.payload.content];
        removeFromObject(allUser, rAndSList);
        setRspAndSelList(allUser);
    }, [usersRes, s]);
    //endregion

    //region methods
    const removeFromObject = (initialList: any[], toRemoveList: any[]) => {
        //let initialList = [...usersRes.payload.content];
        initialList.map((u, i) => {
            toRemoveList.map((res, j) => {
                if (initialList[i] && (initialList[i].id === toRemoveList[j].id)) {
                    initialList.splice(i, 1);
                }
            });
        });
    };
    const onSubmit = () => {
        if (checkError()) {

            if (existUser.id !== 0) {
                type === 0 && dispatch(addUserToStore({
                    storeRef: storeRef,
                    userId: existUser.id,
                    roleName: 'ROLE_STORE_MANAGER',
                }));
                type === 1 && dispatch(addUserToStore({
                    storeRef: storeRef,
                    userId: existUser.id,
                    roleName: 'ROLE_SELLER',
                }));
            }
            if (createUseri) {
                setInside(true);
                dispatch(createUser(
                    {
                        firstname: watch('firstname'),
                        email: watch('email'),
                        lastname: watch('lastname'),
                        roles: [type === 0 ? 'ROLE_STORE_MANAGER' : 'ROLE_SELLER'],
                        responsibleId: me.payload.content?.user.id,
                    }));
            }
        }
    };
    const setAllFalse = () => {
        setErr(false);
        setEmailErrMsg('');
        setFNameErrMsg('');
        setNameErrMsg('');
    };
    const checkError = () => {
        // @ts-ignore
        if (!createUser && existUser.id === 0 && setEmailErrMsg('L\'une des 2 options est obligatoire') && setErr(true)
        ) {
            return false;
        }
        if (watch('email').length === 0 || watch('firstname').length === 0 || watch('lastname').length === 0) {
            if (existUser.id > 0) {
                return true;
            } else {
                // @ts-ignore
                createUseri && watch('email').length === 0 && setEmailErrMsg('Ce champ est obligatoire') && setErr(true);
                // @ts-ignore
                createUseri && watch('lastname').length === 0 && setNameErrMsg('Ce champ est obligatoire') && setErr(true);
                // @ts-ignore
                createUseri && watch('firstname').length === 0 && setFNameErrMsg('Ce champ est obligatoire') && setErr(true);
                return false;
            }
        } else return true;
    };
    //endregion

    return (
        <Layout title='Créer un membre'>
            { createUserRes.isLoading && <Loader isLoading={ createUserRes.isLoading } /> }
            <MainPaper>
                <div className='add-users-main'>
                    <div className='add-users-type'>
                        <label>Poste</label>
                        <div>
                            <span>Responsable de magasin</span>
                            <input onChange={ () => setType(0) } checked={ type === 0 } name='type' value={ 0 }
                                   type='radio' />
                            <span>Vendeur</span>
                            <input onChange={ () => setType(1) } checked={ type === 1 } name='type' value={ 1 }
                                   type='radio' />
                        </div>
                    </div>
                    <div className='add-users-active'>
                        <div className='active'>
                            <label>Ajouter à partir d'un utilisateur existant</label>
                            <Combobox
                                disabled={ createUseri }
                                onChange={ (user: any) => {
                                    setAllFalse();
                                    setExistUser(user);
                                } }
                                onMouseLeave={ () => existUser.id === undefined && setExistUser(initialUser) }
                                placeholder='Choisissez un utilisateur'
                                textField='email'
                                data={ rspAndSelList } />
                        </div>
                        <div className='hr-lines'>OU</div>

                        <div className='add-non-existent-users'>
                            <div className='div'>Créer un nouvel utilisateur</div>

                            <div className='add-bloc'>
                                <label>Nom</label>
                                <input
                                    { ...register('lastname') }

                                    disabled={ existUser.id != 0 }
                                    placeholder='Nom'
                                    type='text' />
                                <div className='err-box'><span>{ nameErrMsg.length > 0 && nameErrMsg }</span>
                                </div>
                            </div>

                            <div className='add-bloc'>
                                <label>Prénom</label>
                                <input
                                    { ...register('firstname') }
                                    disabled={ existUser.id != 0 }
                                    placeholder='Prénom'
                                    type='text' />
                                <div className='err-box'>
                                    <span>{ fNameErrMsg.length > 0 && fNameErrMsg }</span>
                                </div>
                            </div>

                            <div className='add-bloc'>
                                <label>Email</label>
                                <input
                                    { ...register('email') }
                                    disabled={ existUser.id != 0 }
                                    placeholder='Email'
                                    type='email' />
                                <div className='err-box'>
                                    <span>{ emailErrMsg.length > 0 && emailErrMsg }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='add-users-btn'>
                        <button onClick={ onSubmit }>Ajouter</button>
                    </div>
                </div>
            </MainPaper>

        </Layout>
    );
};
export default AddUsersToStore;