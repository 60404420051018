import React from 'react';
import '../row/role-row.scss';

const RolesHeader = () => (
    <thead className="roles-header">
        <tr>
            <th>INTITULÉ</th>
            <th>NOMBRE D'UTILISATEURS</th>
            <th>ACTIONS</th>
        </tr>
    </thead>
);

export default RolesHeader;
