import { useEffect, useState } from 'react';
import close from '../../../../asset/CloseWindow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { SetCurrentTexture } from '../../../../services/reducers/material-editor/EditorReducer';
import { useParams } from 'react-router-dom';
import { getAllTextures, GetTexturesState } from '../../../../services/reducers/suppliers/finish/texture/texture.slice';
import { Params } from '../../../../domain/Params/params-interface';
import { RootState } from '../../../../services/root-reducer';
import { Texture } from '../../../../domain/domain';
import loupe from '../../../../asset/loupe.svg';
import './texture-to-slot.scss';
import {
    getCommonSupplier,
    GetCommonSupplierState,
    getSupplierTexturesAndColoris,
    GetSupplierTexturesAndColorisState,
} from '../../../../services/reducers/suppliers/suppliers.slice';


type TextureToSlotProps = {
    setTextureListModal: (textureListModal: boolean) => void;
    supplierName: string;
};

const TextureToSlot = ({ setTextureListModal, supplierName }: TextureToSlotProps) => {
    const dispatch = useDispatch();
    const { finishId } = useParams<Params>();
    const [focusTab, setFocusTab] = useState<number>(0);
    const [focus, setFocus] = useState<number | undefined>(undefined);
    const [selectedTexture, setSelectedTexture] = useState<string | undefined>(undefined);

    const textures = useSelector<RootState, GetTexturesState>((state) => state.getAllTextures);
    const commonSupplier = useSelector<RootState, GetCommonSupplierState>((state) => state.commonSupplier);
    const commonTexture = useSelector<RootState, GetSupplierTexturesAndColorisState>((state) => state.getSupplierTexturesAndColoris);

    const [searchName, setSearchName] = useState<string>('');

    const [commonTextureList, setCommonTextureList] = useState<Texture[]>([]);
    const [filterSupplierList, setFilterSupplierList] = useState<Texture[]>([]);
    const [filterCommonList, setFilterCommonList] = useState<Texture[]>([]);

    const searchInList = (list: Texture[], setFilterList: (filterList: Texture[]) => void) => {
        const result = list.filter((texture: Texture) => {
            return texture.name.toLowerCase().includes(searchName.toLowerCase());
        });
        setFilterList(result);
    };

    useEffect(() => {
        if (finishId) {
            dispatch(getAllTextures({ finishId: parseInt(finishId) }));
            dispatch(getCommonSupplier());
        }
    }, []);
    useEffect(() => {
        if (commonSupplier.payload && commonSupplier.payload.content && commonSupplier.payload.content.reference.length > 0) {
            dispatch(getSupplierTexturesAndColoris(commonSupplier.payload.content.reference));
        }
    }, [commonSupplier]);
    useEffect(() => {
        if (commonTexture.payload.content !== undefined && commonTexture.payload.errors.length < 1) {
            setCommonTextureList(commonTexture.payload.content.textureSet);
        }
    }, [commonTexture]);
    useEffect(() => {
        if (textures.payload !== undefined && focusTab === 0) {
            searchInList(textures.payload.content, setFilterSupplierList);
        }
    }, [searchName, textures]);
    useEffect(() => {
        if (commonTextureList.length > 0 && focusTab === 1) {
            searchInList(commonTextureList, setFilterCommonList);
        }
    }, [commonTextureList, focusTab, searchName]);

    return (
        <div className='texture-to-slot-main'>
            <div className='texture-to-slot-paper'>
                <div className='texture-to-slot-top'>
                    <div className='texture-to-slot-left'>
                        <span
                            className={ focusTab === 0 ? 'texture-focus' : '' }
                            onClick={ () => {
                                setSearchName('');
                                setSelectedTexture(undefined);
                                setFocusTab(0);
                            } }
                        >
                            { supplierName }
                        </span>
                        <span
                            className={ focusTab === 1 ? 'texture-focus' : '' }
                            onClick={ () => {
                                setSearchName('');
                                setSelectedTexture(undefined);
                                setFocusTab(1);
                            } }
                        >
                            Commun
                        </span>
                    </div>
                    <div className='texture-to-slot-right'>
                        <div className='texture-to-slot-input'>
                            <img alt='loupe' src={ loupe } />
                            <input value={ searchName } onChange={ (e) => setSearchName(e.target.value) } />
                        </div>
                        <img
                            className='texture-to-slot-close' src={ close }
                            onClick={ () => {
                                setTextureListModal(false);
                            } }
                            alt="férmer la modale d'ajout de texture" />
                    </div>
                </div>
                <div className='texture-to-slot-body'>
                    <div className='texture-to-slot-list'>
                        { focusTab === 0 ? (
                            <div>
                                { filterSupplierList.map((texture, index) => {
                                    return (
                                        <div
                                            onDoubleClick={ () => {
                                                dispatch(SetCurrentTexture(texture.contentUrl));
                                                setTextureListModal(false);
                                                setSearchName('');
                                            }}
                                            onClick={ () => {
                                                setFocus(index);
                                                setSelectedTexture(texture.contentUrl);
                                            } }
                                            className={ focus === index ? 'focus' : 'texture-to-slot-card' }
                                            key={ texture.id }
                                        >
                                            <img alt='texture-miniature' src={ texture.thumbnailUrl } />
                                            <span>{ texture.name.length > 40 ? `${ texture.name.substring(0, 40) }...` : texture.name }</span>
                                        </div>
                                    );
                                }) }
                            </div>
                        ) : (
                            <div>
                                { filterCommonList.map((texture, index) => {
                                    return (
                                        <div
                                            onClick={ () => {
                                                setFocus(index);
                                                setSelectedTexture(texture.contentUrl);
                                            } }
                                            className={ focus === index ? 'focus' : 'texture-to-slot-card' }
                                            key={ texture.id }
                                        >
                                            <img alt='texture-miniature' src={ texture.thumbnailUrl } />
                                            <span>{ texture.name.length > 35 ? `${ texture.name.substring(0, 35) }...` : texture.name }</span>
                                        </div>
                                    );
                                }) }
                            </div>
                        ) }
                    </div>
                </div>
                <div className='texture-to-slot-btn-box'>
                    <button
                        className='btn-close'
                        onClick={ () => {
                            setTextureListModal(false);
                            setSearchName('');
                        } }
                    >
                        Annuler
                    </button>
                    <button
                        onClick={ () => {
                            dispatch(SetCurrentTexture(selectedTexture));
                            setTextureListModal(false);
                            setSearchName('');
                        } }
                        className='btn-action'
                    >
                        Choisir
                    </button>
                </div>
            </div>
        </div>
    );
};
export default TextureToSlot;
