import MdfApiResponse from '../../../../domain/common/generic';
import { CellCreationForm, Model3DCell } from '../../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cells3dService from '../../../../api/services/products/3DCells/3DCells.service';

export type Cell3dState = {
    isLoading: false;
    payload: MdfApiResponse<Model3DCell>;
};

const initialModel3dState: Cell3dState = {
    isLoading: false,
    payload: {
        content: {
            id: 0,
            tags: [],
            modelReference: '',
            partItemReference: '',
            createdAt: new Date(),
            updatedAt: new Date(),
            version: 0,
        },
        errors: [],
        warnings: [],
    },
};

export type Cell3dListState = {
    isLoading: false;
    payload: MdfApiResponse<Model3DCell[]>;
};

const initialCell3dListState: Cell3dListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

//<editor-fold desc="get-cell-info-3DCell">
export const get3dCellInfo = createAsyncThunk('get/3d-cell', async (data: { modelRef: string; partItemRef: string }) => {
    const res = await cells3dService.get3dCellInfo(data.modelRef, data.partItemRef);
    return res;
});

export const get3dCellInfoSlice = createSlice({
    name: 'get3dCellInfo',
    initialState: initialCell3dListState,
    reducers: {
        resetGet3dCellInfo: (state) => {
            state = initialCell3dListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(get3dCellInfo.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(get3dCellInfo.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(get3dCellInfo.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGet3dCellInfo } = get3dCellInfoSlice.actions;

//</editor-fold>

//<editor-fold desc="delete-cell-info-3DCell">
export const delete3sCell = createAsyncThunk('delete/3d-cell/', async (data: { modelRef: string; partItemRef: string; tagId: number }) => {
    const res = await cells3dService.delete3sCell(data.modelRef, data.partItemRef, data.tagId);
    return res;
});

export const delete3dCellInfoSlice = createSlice({
    name: 'delete3dCellInfo',
    initialState: initialModel3dState,
    reducers: {
        resetDelete3sCell: (state) => {
            state = initialModel3dState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(delete3sCell.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(delete3sCell.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(delete3sCell.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDelete3sCell } = delete3dCellInfoSlice.actions;
//</editor-fold>

//<editor-fold desc="create-cell-info-3DCell">
export const create3dCell = createAsyncThunk('create/3d-cell/', async (data: { modelReference: string; partItemReference: string; tags: CellCreationForm }) => {
    const res = await cells3dService.create3dCell(data.modelReference, data.partItemReference, data.tags);
    return res;
});

export const create3dCellInfoSlice = createSlice({
    name: 'create3dCellInfo',
    initialState: initialModel3dState,
    reducers: {
        resetCreate3sCell: (state) => {
            state = initialModel3dState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(create3dCell.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(create3dCell.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(create3dCell.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreate3sCell } = create3dCellInfoSlice.actions;
//</editor-fold>

//<editor-fold desc="get-all-cell-info-3DCell">
export const getAllCells = createAsyncThunk('get-all/3d-cell/', async () => {
    const res = await cells3dService.getAll3dCell();
    return res;
});

export const getAll3dCellInfoSlice = createSlice({
    name: 'getAll3dCellInfo',
    initialState: initialCell3dListState,
    reducers: {
        resetGetAll3dCell: (state) => {
            state = initialCell3dListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllCells.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllCells.fulfilled, (state, { payload }) => {
            console.log(payload);
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllCells.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetAll3dCell } = getAll3dCellInfoSlice.actions;
//</editor-fold>

//<editor-fold desc="get-all-cells-by-part-reference">
export const getAllCellsByPartReference = createAsyncThunk('get-all/3d-cell/by-part-reference', async (partReference: string) => {
    const res = await cells3dService.getAllCellsByPartReference(partReference);
    return res;
});

export const getAllCellsByPartReferenceSlice = createSlice({
    name: 'getAllCellsByPartReference',
    initialState: initialCell3dListState,
    reducers: {
        resetGetAll3dCellByPartReference: (state) => {
            state = initialCell3dListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllCellsByPartReference.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllCellsByPartReference.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllCellsByPartReference.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetAll3dCellByPartReference } = getAllCellsByPartReferenceSlice.actions;
//</editor-fold>

//<editor-fold desc="get-anchor-point">
export const getAnchorPoint = createAsyncThunk('get-all/3d-cell/anchor-point', async (model3dCellReference: string) => {
    const res = await cells3dService.getAnchorPoint(model3dCellReference);
    return res;
});

export const getAnchorPointSlice = createSlice({
    name: 'getAnchorPoint',
    initialState: initialCell3dListState,
    reducers: {
        resetGetAnchorPoint: (state) => {
            state = initialCell3dListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAnchorPoint.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAnchorPoint.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAnchorPoint.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetAnchorPoint } = getAnchorPointSlice.actions;
//</editor-fold>