import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Params } from '../../../domain/Params/params-interface';
import './modele-tabs.scss';
import { useEffect, useState } from 'react';

type ModeleTabsProps = {
    focus: number;
    setFocus: (focus: number) => void;
    setOpenModal: (openModal: boolean) => void;
    setRuleId: (ruleId: number) => void
    ruleId: number
};

const ModeleTabs = (props: ModeleTabsProps) => {
    const history = useHistory();
    const { supplierReference, collectionReference } = useParams<Params>();
    useEffect(() => {
        props.setRuleId(0);
    }, [props.focus]);
    return (
        <div className='modele-tabs-main'>
            <div>
                <span
                    className={props.focus === 0 ? 'focus' : ''}
                    onClick={() => {
                        history.push(`/supplier/${supplierReference}/collections/${collectionReference}/products`);
                        props.setFocus(0);
                    }}>
                    Produits
                </span>
                <span
                    className={props.focus === 1 ? 'focus' : ''}
                    onClick={() => {
                        history.push(`/supplier/${supplierReference}/collections/${collectionReference}/mapping`);
                        props.setFocus(1);
                    }}>
                    Mappings
                </span>
                <span
                    className={props.focus === 2 ? 'focus' : ''}
                    onClick={() => {
                        history.push(`/supplier/${supplierReference}/collections/${collectionReference}/composition`);
                        props.setFocus(2);
                    }}>
                    Règles de composition
                </span>
                <span
                    className={props.focus === 3 ? 'focus' : ''}
                    onClick={() => {
                        props.setFocus(3);
                        history.push(`/supplier/${supplierReference}/collections/${collectionReference}/win`);
                    }}>
                    Association Win
                </span>
            </div>
            {props.focus === 0 && <button
                onClick={() => props.setOpenModal(true)}>
                + Créer un produit
            </button>}
            {props.focus === 2 &&
                <button className={props.ruleId === 0 ? '' : 'disable'}
                        onClick={() => props.ruleId === 0 && props.setRuleId(-1)}>
                    + Créer une règle
                </button>
            }
        </div>
    );
};
export default ModeleTabs;
