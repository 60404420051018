import React from 'react';
import { Conf } from '../products-table/product-presset/product-preset-table';
import { Rule } from '../../../domain/domain';
import RuleDescriptionColumn from './rule-description-column';


type ConfigurationRuleRowProps = {
    conf: Conf[]
    rule: Rule
    setRuleHoverId: (ref: string) => void
    ruleHoverId: string
};

const ConfigurationRuleRow = ({ conf, ruleHoverId, setRuleHoverId, rule }: ConfigurationRuleRowProps) => {
    return (
        <tr className='preset-conf' onMouseEnter={() => rule && setRuleHoverId(rule.ruleRef)}
            onMouseLeave={() => rule && setRuleHoverId('')}>
            {ruleHoverId === rule.ruleRef
                ? <RuleDescriptionColumn conf={conf} />
                : <td>
                    <div key={rule.ruleRef}
                         className='c-partName'>
                        <span className='t'>Part:</span>
                        <span> {conf[0].partName}</span>
                    </div>
                </td>
            }
        </tr>
    );
};

export default ConfigurationRuleRow;
