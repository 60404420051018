import FileInput from '../../../../uicomponents/fileInput/file-input';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Params } from '../../../../domain/Params/params-interface';
import { postFile, postFileListWithPreview, postFileWithPreview } from '../../../../utils/upload-file-method';
import close from '../../../../asset/CloseWindow.svg';
import { createTexture, CreateTextureState, getAllTextures, resetCreateTexture } from '../../../../services/reducers/suppliers/finish/texture/texture.slice';
import { useDispatch, useSelector } from 'react-redux';

import './create-texture.scss';
import { Part, Texture, UploadingImgFileWithPreview } from '../../../../domain/domain';
import { createProductModels3d, resetCreateProductModels3d, resetUpdateProductModels3dUrl, updateProductModels3dUrl } from '../../../../services/reducers/product/3DModels/3DModels.reducers';
import axios from 'axios';
import { getConfig } from '../../../../api/config';
import { RootState } from '../../../../services/root-reducer';
import { UPLOAD_IMG_WITH_PREVIEW, UPLOAD_IMG_WITH_PREVIEW_LIST } from '../../../../api/endpoints/document/documents.endpoint';

type CreateTextureProps = {
    setOpenTextureModal: (openTextureModal: boolean) => void;
    model3d?: boolean;
    setUploadPercent: (percent: string) => void;
    part?: Part;
    setIsLoading?: (loading: boolean) => void;
    setTextureIsLoading?: (loading: boolean) => void;
    name?: string;
    setUploadedList?: (uploadList: UploadingImgFileWithPreview[]) => void;
    uploadList?: UploadingImgFileWithPreview[];
    isCrushModel3D?: boolean;
    selectedModel3DRef?: string;
    setFromCrush?: (fromCrush: boolean) => void;
};
const CreateTexture = ({
                           setOpenTextureModal,
                           model3d,
                           part,
                           setIsLoading,
                           setTextureIsLoading,
                           setUploadPercent,
                           name,
                           uploadList,
                           setUploadedList,
                           isCrushModel3D,
                           selectedModel3DRef,
                           setFromCrush,
                       }: CreateTextureProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const limitSize = 500;

    const { finishId, supplierReference } = useParams<Params>();
    const createTexturesRes = useSelector<RootState, CreateTextureState>((state) => state.createTextures);

    //#region useState
    const [err, setErr] = useState('');
    const [fileList, setFileList] = useState<File[] | undefined>(undefined);
    const [fileListSize, setFileListSize] = useState(0);
    const [awsUrl, setAwsUrl] = useState(undefined);
    const [resUrlList, setResUrlList] = useState<string[]>([]);
    const [fileToUploadLength, setFileToUploadLength] = useState<number>(0);
    //#endregion

    //#region useEffect
    useEffect(() => {
        if (fileToUploadLength > 0 && uploadList && uploadList.length === fileToUploadLength && !isCrushModel3D) {
            const textureSet: Texture[] = uploadList.map((el: UploadingImgFileWithPreview) => {
                const texture: Texture = {
                    id: -1,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    version: 1,
                    name: '', // name is generated automatically
                    finish: {
                        // can be set with default values
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        name: '',
                        version: 1,
                        id: -1,
                        logoUrl: '',
                        nonDeletable: false,
                        nonEditable: false,
                        reference: '',
                        tags: [],
                        businessDescription: '',
                        supplierReference: '',
                        typeVignette: 'A_PLAT',
                        technicalDescription: '',
                    },
                    thumbnailUrl: el.urlOfPreview,
                    contentUrl: el.url,
                };
                return texture;
            });
            finishId &&
            dispatch(
                createTexture({
                    finishId: parseInt(finishId),
                    textureSet: textureSet,
                }),
            );
        }
    }, [uploadList]);
    useEffect(() => {
        if (createTexturesRes && createTexturesRes.payload.errors.length === 0 && createTexturesRes.payload.content.length !== 0 && !isCrushModel3D) {
            setOpenTextureModal(false);
            finishId && dispatch(getAllTextures({ finishId: parseInt(finishId) }));
            dispatch(resetCreateTexture());
            setUploadedList && setUploadedList([]);
            setOpenTextureModal(false);
            setFromCrush && setFromCrush(true);
            console.log('here');
            history.push(`/supplier/${supplierReference}/finishes/${finishId}/textures`);
        }
    }, [createTexturesRes]);
    useEffect(() => {
        let sizeList: number[] = [];
        let size = 0;
        fileList && fileList.forEach((f: File) => sizeList.push(f.size));
        sizeList.map((x) => (size += x));
        setFileListSize(size);

        if (resUrlList.length === fileList?.length && part) {
            isCrushModel3D
                ? selectedModel3DRef &&
                dispatch(
                    updateProductModels3dUrl({
                        modelRef: selectedModel3DRef,
                        // @ts-ignore
                        url: resUrlList[0],
                    }),
                )
                : resUrlList.forEach((el: string) => {
                    dispatch(
                        createProductModels3d({
                            form: {
                                partReference: part.reference,
                                // @ts-ignore
                                modelUrl: el,
                                anchorPointReference: '',
                                // @ts-ignore
                                mappings: [],
                            },
                        }),
                    );
                });
            setIsLoading && setIsLoading(false);
            dispatch(resetCreateProductModels3d());
            dispatch(resetUpdateProductModels3dUrl());
            setResUrlList([]);
            setOpenTextureModal(false);
        }
    }, [resUrlList, fileList]);
    useEffect(() => {
        if (model3d && !isCrushModel3D) {
            awsUrl &&
            part &&
            dispatch(
                createProductModels3d({
                    form: {
                        partReference: part.reference,
                        // @ts-ignore
                        modelUrl: awsUrl.data.content,
                        anchorPointReference: '',
                        // @ts-ignore
                        mappings: [],
                    },
                }),
            );
            setIsLoading && setIsLoading(false);
            dispatch(resetCreateProductModels3d());
        }
    }, [awsUrl]);
    useEffect(() => {
        err.length > 0 &&
        setTimeout(() => {
            setFileList(undefined);
            setErr('');
        }, 2000);
    }, [err]);
    //#endregion

    //#region method
    const checkEmpty = () => {
        if (fileList === undefined) {
            setErr('Vous devez sélectionner au moins un fichier');
            setOpenTextureModal(true);
            return false;
        }
        return true;
    };

    const createTextureRequest = (files: File[] | undefined) => {
        if (files !== undefined && finishId) {
            setFileToUploadLength(files.length);
            for (let i = 0; i < files.length; i++) {
                const formData = new FormData();
                formData.append('file', files[i]);
                formData.append('prefix', finishId);
                if (setTextureIsLoading && setUploadedList) {
                    setTextureIsLoading(true);
                    postFileWithPreview(UPLOAD_IMG_WITH_PREVIEW, formData, false, setUploadedList);
                }
            }
        }
    };

    const startUploading = (files: File[]) => {
        const eventSource = new EventSource(`${getConfig().urlHostApi}/v1/progress/subscribe`);

        eventSource.addEventListener('GUI_ID', (event: any) => {
            const guidValue = event.data;

            eventSource.addEventListener(guidValue, (eventUpload) => {
                console.log(eventUpload.data);
                const percent = eventUpload.data;
                setUploadPercent(percent);
                if (percent === '100.00') {
                    eventSource.close();
                    setUploadPercent('0.00');
                }
            });
            createTextureRequestList(files, guidValue);
        });

        eventSource.onerror = (event) => {
            console.error('Event error');
            eventSource.close();
            setUploadPercent('0.00');
        };

        eventSource.onopen = () => {
            console.log('connection opened');
        };
    };

    const createTextureRequestList = (files: File[], uuid: string) => {
        if (files !== undefined && finishId) {
            setFileToUploadLength(files.length);
            const formData = new FormData();

            formData.append('prefix', finishId);

            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
            }
            if (setTextureIsLoading && setUploadedList) {
                setTextureIsLoading(true);

                postFileListWithPreview(UPLOAD_IMG_WITH_PREVIEW_LIST, formData, false, uuid, setUploadedList);
            }
        }
    };
    const create3dModel = (file: File[] | undefined) => {
        if (file !== undefined && part) {
            if (name === 'UNIQUE') {
                if (file.length > 1) {
                    setErr('Vous ne pouvez importez qu\'un seul modèle 3d');
                } else {
                    for (let j = 0; j < file?.length; j++) {
                        const formData = new FormData();
                        formData.append('file', file[0]);
                        postFile('v1/documents/upload', formData, () => {
                        }, setAwsUrl, setIsLoading);
                        formData.delete('file');
                    }
                }
            } else {
                setIsLoading && setIsLoading(true);
                for (let j = 0; j < file.length; j++) {
                    const formData = new FormData();
                    formData.append('file', file[j]);
                    axios
                        .post(`${getConfig().urlHostApi}/v1/documents/upload`, formData, {
                            headers: { Authorization: `Bearer ${localStorage.getItem('mdf-jwt')}` },
                        })
                        .then((res) => {
                            setResUrlList((prevState) => [...prevState, res.data.content]);
                        })
                        .catch((e) => {
                            console.log('erreur', e);
                        });
                }
            }
        }
    };
    //#endregion

    return (
        <div className='create-texture-main'>
            <div className='create-texture-paper'>
                <div className='create-texture-top'>
                    <div className='texture-name'> {model3d ? 'Importer un modèle 3D' : 'Importer une texture'}</div>
                    <img onClick={() => setOpenTextureModal(false)} className='texture-close' alt='fermer la modal de création' src={close} />
                </div>
                <div className='create-texture-body'>
                    <div className='create-texture-form'>
                        <FileInput size={fileListSize / 5_000_000} limitSize={limitSize} model3d={model3d} setErr={setErr} err={err} file={fileList} setFile={setFileList} />

                        <div className='btn-box'>
                            <button onClick={() => setOpenTextureModal(false)} className='btn-close'>
                                Annuler
                            </button>
                            <button
                                className='btn-action'
                                onClick={() => {
                                    if (model3d) {
                                        checkEmpty() && create3dModel(fileList);
                                    } else {
                                        checkEmpty() && fileList && startUploading(fileList);
                                    }
                                }}
                            >
                                Importer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CreateTexture;
