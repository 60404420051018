import { SubmitHandler, UnpackNestedValue, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createColoris, duplicateColoris, updateColoris } from '../../../../services/reducers/suppliers/finish/coloris/coloris.slice';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../domain/Params/params-interface';
import { Colori, ColoriCreateForm, Finish } from '../../../../domain/domain';
import React, { useEffect, useState } from 'react';
import { SetCanReturn } from '../../../../services/reducers/can-return/can-return.slice';
import { ToggleGLTFDirty } from '../../../../services/reducers/material-editor/ColorReducer';
import close from '../../../../asset/CloseWindow.svg';

import './create-coloris.scss';
import FileInput from '../../../../uicomponents/fileInput/file-input';
import { postFile } from '../../../../utils/upload-file-method';

type CreateColorProps = {
    setOpenColorModal: (openColorModal: boolean) => void;
    update?: boolean;
    duplicate?: boolean;
    title?: string;
    action?: (name: string) => void;
    duplicateAcion?: (coloriCreateForm: ColoriCreateForm) => void;
    refresh: () => void;
    coloris?: Colori;
    updateContent: any;
};
type UpdateColorInput = {
    id: number;
    version: number;
    name: string;
    code: string;
    modelUrl: string;
    finish: Finish;
    duplicateNumber: number;
    originalName: string;
};

const CreateColoris = (props: CreateColorProps) => {
    const { register, handleSubmit, setValue, watch } = useForm<UpdateColorInput>({});

    const dispatch = useDispatch();
    const { finishId } = useParams<Params>();

    //#region useState
    const [cErr, setCErr] = useState('');
    const [totalLength, setTotalLength] = useState(0);
    const [disable, setDisable] = useState(false);
    const [click, setClick] = useState(1);
    const [tempData, setTempData] = useState<UnpackNestedValue<UpdateColorInput> | undefined>(undefined);

    const [err, setErr] = useState('');
    const [fileList, setFileList] = useState<File[] | undefined>(undefined);
    const [fileListSize] = useState(0);
    const [url, setUrl] = useState('');
    const [AwsUrl, setAwsUrl] = useState(undefined);
    //endregion

    const onSubmit: SubmitHandler<UpdateColorInput> = (data) => {
        if (fileList) {
            const formData = new FormData();
            fileList && formData.append('file', fileList[0]);
            postFile('v1/documents/upload', formData, () => {}, setAwsUrl);
            setTempData(data);
        } else {
            if (props.update) {
                if (data.name.length === 0 && data.code.length === 0) {
                    setCErr("Vous devez renseigner au minimum l'un des 2 champs");
                } else {
                    props.coloris &&
                        dispatch(
                            updateColoris({
                                colorisId: props.coloris.id,
                                updateColorisForm: {
                                    coloriCode: data.code,
                                    coloriName: data.name,
                                    colorisContent: JSON.stringify(props.updateContent),
                                    colorisThumbnailUrl: props.coloris.thumbnailUrl,
                                    uploadedThumbnailUrl: '',
                                },
                            })
                        );
                }
            } else if (props.action) {
                props.action(data.name);
            } else if (props.duplicateAcion) {
                if (data.name.length === 0 && data.code.length === 0) {
                    setCErr("Vous devez renseigner au minimum l'un des 2 champs");
                } else {
                    const n = watch('name');
                    const c = watch('code');
                    props.duplicateAcion({ coloriName: n, coloriCode: c, declinaisonRal: false });
                }
            } else {
                if (data.name.length === 0 && data.code.length === 0) {
                    setCErr("Vous devez renseigner au minimum l'un des 2 champs");
                } else {
                    finishId &&
                        dispatch(
                            createColoris({
                                finishId: parseInt(finishId),
                                colorForm: { coloriCode: data.code, coloriName: data.name, declinaisonRal: false },
                            })
                        );
                    dispatch(ToggleGLTFDirty(false));
                    dispatch(SetCanReturn(true));
                }
            }
        }
    };
    const keyDownHandler = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    };
    const returnButtonClass = () => {
        if (click === 1) {
            return '';
        } else {
            return 'warn';
        }
    };
    const returnButtonLabel = () => {
        if (click === 1) {
            return 'Dupliquer selon une table RAL';
        } else {
            return 'Attention !  Cette action ajoutera 212 coloris , Confirmer ? ';
        }
    };

    useEffect(() => {
        if (AwsUrl) {
            finishId &&
                tempData &&
                props.coloris &&
                dispatch(
                    updateColoris({
                        colorisId: props.coloris.id,
                        updateColorisForm: {
                            coloriCode: tempData.code,
                            coloriName: tempData.name,
                            colorisContent: JSON.stringify(props.updateContent),
                            colorisThumbnailUrl: props.coloris.thumbnailUrl,
                            // @ts-ignore
                            uploadedThumbnailUrl: AwsUrl.data.content,
                        },
                    })
                );
            setFileList(undefined);
            setTempData(undefined);
        }
    }, [AwsUrl]);

    useEffect(() => {
        if (props.update && props.coloris !== undefined) {
            setValue('id', props.coloris.id);
            setValue('version', props.coloris.version);
            setValue('name', props.coloris.name);
            setValue('code', props.coloris.code);
            setValue('modelUrl', props.coloris.modelUrl);
            setValue('finish', props.coloris.finish);
            setValue('duplicateNumber', props.coloris.duplicateNumber);
            props.coloris.uploadedThumbnailUrl && setUrl(props.coloris.uploadedThumbnailUrl);
            console.log('url  ====> ', props.coloris);
        }
    }, [props.coloris]);

    useEffect(() => {
        const name = watch('name');
        const code = watch('code');
        setTotalLength(name.length + code.length);
        if (name.length > 0 || code.length > 0) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [watch('name'), watch('code')]);

    return (
        <div className="create-color-main">
            <div className={props.duplicate ? 'create-color-paper duplicate' : 'create-color-paper'}>
                <div className="create-color-top">
                    <div className="color-name">
                        {!props.update && !props.title && 'Créer un coloris'}
                        {props.update && !props.title && 'Modifier un coloris'}
                        {props.title && props.title}
                    </div>
                    <img onClick={() => props.setOpenColorModal(false)} className="color-close" src={close} />
                </div>
                <div className="create-color-body">
                    <form className="create-color-form" onSubmit={handleSubmit(onSubmit)} onKeyDown={keyDownHandler}>
                        <div className="input-container">
                            <label htmlFor="name">Nom</label>
                            <input placeholder="Nom du coloris" {...register('name')} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="code">Code</label>
                            <input placeholder="Code du coloris" {...register('code')} />
                            <span>{cErr}</span>
                            <span className={totalLength > 20 ? 'countOff' : 'count'}>{`( ${totalLength} )`} Nous vous conseillons de ne pas dépasser les 20 caractères.</span>
                        </div>
                        <div className="co">
                            {props.update && (
                                <FileInput setUrl={setUrl} url={url} update={true} size={fileListSize / 5_000_000} limitSize={500} setErr={setErr} err={err} file={fileList} setFile={setFileList} />
                            )}
                        </div>

                        <div className={props.duplicate ? 'btn-box duplicate-btn' : 'btn-box'}>
                            <button
                                onClick={() => {
                                    props.setOpenColorModal(false);
                                }}
                                className="btn-close"
                            >
                                Annuler
                            </button>
                            <button className="btn-action">{!props.update ? 'Créer' : 'Modifier'}</button>
                        </div>
                    </form>
                    {props.duplicate && (
                        <div className="ral">
                            <div>OU</div>
                            <button
                                onClick={() => {
                                    if (click === 1) {
                                        setClick(2);
                                    } else {
                                        finishId &&
                                            props.coloris &&
                                            dispatch(
                                                duplicateColoris({
                                                    finishId: parseInt(finishId),
                                                    colorisId: props.coloris.id,
                                                    coloriCreateForm: {
                                                        coloriName: '',
                                                        coloriCode: '',
                                                        declinaisonRal: true,
                                                    },
                                                })
                                            );
                                    }
                                }}
                                disabled={disable}
                                className={disable ? 'btn-disable' : returnButtonClass()}
                            >
                                {disable ? 'Non disponible' : returnButtonLabel()}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default CreateColoris;
