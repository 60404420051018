import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import React, { useEffect, useState } from 'react';
import Loader from '../../uicomponents/loader/loader';
import { Button } from '../../uicomponents/button/button';
import { useHistory } from 'react-router-dom';
import {
    DeletedRoleState,
    deleteRole,
    deleteRoleSlice,
    getUsedAndUnusedRoleList, resetGetRole,
    UsedAndUnusedRoleState,
} from '../../services/reducers/role-reducer/role-slice';
import RoleTable from '../../uicomponents/role-table/role-table';
import Layout from '../../uicomponents/layout/layout';
import MainPaper from '../../uicomponents/paper/main-paper';
import { setToasterError } from '../../services/reducers/toaster-reducer/toaster.slice';
import Modal, { ModalSize } from '../../uicomponents/modal/modal';
import { addModal, removeModal } from '../../services/reducers/modal/modal.slice';
import '../../uicomponents/role-table/role-table';
import trash from '../../asset/trash-popup.png';

const RoleList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const confirmationModal = 'confirmation-delete-role-modal';
    const [roleToBeDeleted, setRoleToBeDeleted] = useState(-1);

    const {
        payload,
        isLoading,
    } = useSelector<RootState, UsedAndUnusedRoleState>((state) => state.userAndUnusedRoleList);

    const roleDeleted = useSelector<RootState, DeletedRoleState>((state) => state.deleteRole);

    useEffect(() => {
        dispatch(resetGetRole());
    }, []);
    useEffect(() => {
        dispatch(getUsedAndUnusedRoleList());
    }, [roleDeleted]);

    useEffect(() => {
        if (roleDeleted.payload.errors.length === 0 && !roleDeleted.isLoading) {
            history.push('/home/roles');
            dispatch(deleteRoleSlice.actions.resetRoleDeletion());
        } else if (roleDeleted.payload.errors.length !== 0) {
            dispatch(
                setToasterError({
                    message: roleDeleted.payload.errors[0].message,
                    title: roleDeleted.payload.errors[0].code,
                }),
            );
        }
    }, [roleDeleted]);

    const onDeleteRole = (roleId: number) => {
        setRoleToBeDeleted(roleId);
        dispatch(addModal(confirmationModal));
    };

    return (
        <Layout
            title='Rôles'
            labelBack=''
            option={
                <Button
                    label='+ Créer un rôle'
                    size='medium'
                    primary
                    onClick={ () => {
                        history.push('/create-role');
                    } }
                />
            }>
            <Loader isLoading={ isLoading } />
            <MainPaper>
                <Modal
                    validateLabel='Confirmer'
                    onClick={ () => {
                        dispatch(deleteRole(roleToBeDeleted));
                        dispatch(removeModal(confirmationModal));
                    } }
                    id={ confirmationModal }
                    title='Supprimer ce rôle ?'
                    closeLabel='Annuler'
                    size={ ModalSize.SMALL }
                    colorValidateButton='#E95D5D'
                    icon={ trash }
                >
                    <p>Il disparaîtra de la liste définitivement</p>
                </Modal>
                <div />
                <RoleTable roles={ payload.content } onDeleteRole={ onDeleteRole } />
            </MainPaper>
        </Layout>
    );
};

export default RoleList;
