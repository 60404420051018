import React, { useEffect, useState } from 'react';
import logo from '../../../asset/logo-mdf.jpeg';
import trashRed from '../../../asset/poubelle-red.svg';
import duplicate from '../../../asset/dupliquer.svg';
import { useHistory, useParams } from 'react-router-dom';
import { Params } from '../../../domain/Params/params-interface';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllProductsByCollection,
    ProductListState,
    ProductState,
    resetCreateProducts,
    resetDeleteProduct,
    resetGetProductById,
} from '../../../services/reducers/product/product.reducers';
import { RootState } from '../../../services/root-reducer';
import './products.table.scss';
import { Product } from '../../../domain/domain';
import { resetGetAllParts } from '../../../services/reducers/product/part/part.reducers';


type ProductTableProps = {
    setOpenModal: (openModal: boolean) => void;
    setOpenDeleteProductModal: (openDeleteModal: boolean) => void;
    setProductReference: (productReference: string) => void;
};

const ProductsTable = (props: ProductTableProps) => {
        const history = useHistory();
        const dispatch = useDispatch();

        const productHeader = ['NOM', 'RÉFÉRENCE', 'TYPE DE PRODUIT', 'STATUT', 'ACTIONS'];
        const { supplierReference, collectionReference } = useParams<Params>();
        const [hovId, setHovId] = useState(0);

        //#region useselector
        const productsByCollection = useSelector<RootState, ProductListState>((state) => state.getProductCollection);
        const createProductRes = useSelector<RootState, ProductState>((state) => state.createProduct);
        const deleteProductRes = useSelector<RootState, ProductState>((state) => state.deleteProduct);
        //#endregion

        //#region useEffect
        useEffect(() => {
            dispatch(resetGetAllParts());
            collectionReference && dispatch(getAllProductsByCollection(collectionReference));
            dispatch(resetGetProductById());
        }, []);
        useEffect(() => {
            if (createProductRes.payload.content && createProductRes.payload.errors.length < 1 && collectionReference) {
                dispatch(getAllProductsByCollection(collectionReference));
                props.setOpenModal(false);
                dispatch(resetCreateProducts());
            }
        }, [createProductRes]);
        useEffect(() => {
            if (deleteProductRes.payload.content && deleteProductRes.payload.errors.length < 1 && collectionReference) {
                dispatch(getAllProductsByCollection(collectionReference));
                props.setOpenDeleteProductModal(false);
                dispatch(resetDeleteProduct());
            }
        }, [deleteProductRes]);
        //#endregion

        const pushToProductDetail = (productReference: string) => {
            history.push(`/supplier/${ supplierReference }/collection/${ collectionReference }/product/${ productReference }`);
        };
        const returnStatuts = (product: Product) => {
            switch (product.completionStatus) {
                case 'CREATING':
                    return (
                        <div className='products-table-status'>
                            <span> En Cours </span>
                            <div style={ { backgroundColor: '#EBC00C' } } />
                        </div>
                    );
                case 'COMPLETE':
                    return (
                        <div className='products-table-status'>
                            <span> Complet </span>
                            <div style={ { backgroundColor: '#30980d' } } />
                        </div>
                    );
                case 'INCOMPLETE':
                    return (
                        <div className='products-table-status'>
                            <span> Incomplet </span>
                            <div style={ { backgroundColor: '#ff0000' } } />
                        </div>
                    );
            }
        };

        return (
            <table className='products-table'>
                <thead className='products-table-head'>
                <tr>
                    { productHeader.map((header, i) => {
                        return <th key={ i }>{ header }</th>;
                    }) }
                </tr>
                </thead>
                <tbody className='products-table-body'>
                { productsByCollection.payload.content &&
                    productsByCollection.payload.content.map((product) => {
                        return (
                            <tr key={ product.id }>
                                <td onClick={ () => pushToProductDetail(product.reference) }
                                    className='products-table-name'>
                                    <img data-react-ignore
                                         onMouseEnter={ () => setHovId(product.id) }
                                         onMouseLeave={ () => setHovId(0) }
                                         alt='vignette'
                                         src={ product.thumbnailUrl
                                             ? product.thumbnailUrl
                                             : logo } />

                                    <span> { product.name.length < 25 ? product.name : `${ product.name.substring(0, 24) }...` }</span>
                                </td>
                                { product.id === hovId &&
                                    <td className='prevOnHoov'>
                                        <img alt='prev on hoover'
                                             src={ product.thumbnailUrl
                                                 ? product.thumbnailUrl
                                                 : logo } />
                                    </td> }
                                <td onClick={ () => pushToProductDetail(product.reference) }>{ product.reference }</td>
                                <td onClick={ () => pushToProductDetail(product.reference) }>{ product.productType }</td>
                                <td> { returnStatuts(product) } </td>
                                <td className='products-table-trash'>
                                    <img
                                        onClick={ () => {
                                            console.log('dupliquer');
                                        } }
                                        alt='dupliquer'
                                        src={ duplicate }
                                    />
                                    <img
                                        onClick={ () => {
                                            props.setProductReference(product.reference);
                                            props.setOpenDeleteProductModal(true);
                                        } }
                                        alt='logo poubelle'
                                        src={ trashRed }
                                    />
                                </td>
                            </tr>
                        );
                    }) }
                </tbody>
            </table>
        );
    }
;
export default ProductsTable;
