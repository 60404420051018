import Inspector from "../Inspector";
import {MeshPhysicalMaterial} from "three";
import * as THREE from "three";
import {MapType} from "../MapType";

export function SpecularBind(this: Inspector) {
    let inspectorParameters = this.m_materialEditorParameters;
    const self: any = this;
    this.m_specularSlide?.m_specularColor.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.specularColor = new THREE.Color(ev.value.r / 255, ev.value.g / 255, ev.value.b / 255);
        self.m_currentEditedMaterial.specularColor.convertSRGBToLinear();
        self.m_currentEditedMaterial.specularColor.specularColorBuffer = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();});
    this.m_specularSlide?.m_specularIntenstity.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.specularIntensity = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });
    this.m_specularSlide?.m_specularReflectivity.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        self.m_currentEditedMaterial.reflectivity = ev.value;
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_materialEditor.CopyMesh(self.m_currentEditedMaterial) as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });
    
    //Specular Map
    this.m_specularSlide?.m_specularShow.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;

        let Mat = self.m_materialEditor.showOnMesh(self.m_currentEditedMaterial, inspectorParameters, 'specularColorMap', ev.value);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
    });
    this.m_specularSlide?.m_specularMap.BindType(function () {
        if (self.m_materialEditorParameters.specularMap.src) {
            self.ToggleSlotDirty();
            self.UpdateMaterialFromUI(MapType.specularMap, self.m_materialEditorParameters.specularMap, self.m_materialEditorParameters.specularMapFlipY, self.m_materialEditorParameters.specularMapEncodingDefault);
        } else {
        }
    });
    this.m_specularSlide?.m_specularMapClear.BindType( () => {
        if (!self.m_currentEditedMaterial) return;
        let texSlot = self.GetTextureFromSlot(MapType.specularMap);
        if (!texSlot) return;
        let Mat = self.m_materialEditor.ClearMap(this.m_materialEditorParameters,
                texSlot.slotNameSpec ? texSlot.slotNameSpec : texSlot.slotName);
        if (this.m_savedMaterial && self.m_currentEditedMaterial && self.m_dirtyGLTFCallback) self.m_dirtyGLTFCallback(this.m_savedMaterial, self.m_currentEditedMaterial);
        if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(Mat.clone() as MeshPhysicalMaterial, true);
        this.m_pane.refresh();
    });
    this.m_specularSlide?.m_specularFlipY.BindType(function (ev: any) {
        if (!self.m_currentEditedMaterial) return;
        let Mat;
        if (self.m_currentEditedMaterial) {
            Mat = self.m_materialEditor.FlipYTexture(['specularColorMap', 'specularIntensityMap'], ev.value);
        }
        if (self.m_materialUpdatedCallback && Mat) self.m_materialUpdatedCallback(Mat as MeshPhysicalMaterial, false);
        self.ToggleSlotDirty();
    });
}