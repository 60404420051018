import { combineReducers } from 'redux';
import store from './store';
import {
    deleteNewsSlice,
    getNewsSlice,
    getNewsStatusCountSlice,
    getNewsToUpdateSlice,
    updateNewsSlice,
} from './reducers/news/news.slice';
import createNewsReducer from './reducers/create-news/create-news.slice';
import newsCategoryReducer from './reducers/news/news-cateogory-list.slice';
import {
    authSlice,
    createFirstUserSlice,
    createPasswordSlice,
    forgotPasswordSlice,
} from './reducers/auth-reducer/auth-reducer';
import toasterReducer from './reducers/toaster-reducer/toaster.slice';
import createUserReducer from './reducers/create-user/create-user.slice';
import updateUserReducer from './reducers/update-user/update-user.slice';
import {
    getActiveUserSlice,
    getMeSlice,
    getUsersByStatusSlice,
    getUserSlice,
    getUserStatusCountSlice,
    responsibleSlice,
    usersSlice,
} from './reducers/users/users.slice';
import createNewsCategoryReducer from './reducers/news/news-category-creation.slice';

import updateNewsStatusReducer from './reducers/archive-news/archive-news.slice';
import modalReducer from './reducers/modal/modal.slice';
import deleteNewsCategory from './reducers/delete-news-category/delete-news-category.slice';
import { deleteUsersSlice, resetPasswordUserSlice } from './reducers/delete-user/delete-user.slice';
import {
    createRoleSlice,
    deleteRoleSlice,
    editRoleSlice,
    getAllRoleSlice,
    getRoleSlice,
    userAndUnusedRoleSlice,
} from './reducers/role-reducer/role-slice';

import resetPasswordReducer from './reducers/reset-password/reset-password';
import { getLangageSlice, saveLangageSlice } from './reducers/langage/langage.slice';

import {
    createSupplierSlice,
    deleteSupplierSlice,
    findTagsByFinishIdSlice,
    getCommonSupplierSlice,
    getSupplierSlice,
    getSuppliersSlice,
    getSupplierTexturesAndColorisSlice,
    updateSupplierSlice,
} from './reducers/suppliers/suppliers.slice';
import {
    createFinishSlice,
    deleteFinishSlice,
    getFinishesFromSupplierAndCommunSlice,
    getFinishesSlice,
    getFinishlice,
    updateFinishSlice,
} from './reducers/suppliers/finish/finishes.slice';
import {
    createCollectionSlice,
    deleteCollectionSlice,
    getCollectionSlice,
    getCollectionsSlice,
    updateCollectionSlice,
} from './reducers/suppliers/collection/collection.slice';
import {
    createTextureSlice,
    deleteTextureSlice,
    getAllTexturesSlice,
    updateTextureSlice,
} from './reducers/suppliers/finish/texture/texture.slice';
import {
    createColorisSlice,
    deleteColorisSlice,
    duplicateColorisSlice,
    fetchAllColorisFromSupplierAndCommon,
    getAllColorisSlice,
    getColorisSlice,
    updateColorisDTOSlice,
    updateColorisSlice,
} from './reducers/suppliers/finish/coloris/coloris.slice';
import EntitiesReducer from './reducers/material-editor/EntitiesReducer';
import ThumbnailReducer from './reducers/material-editor/ThumbnailReducer';
import EditorReducer from './reducers/material-editor/EditorReducer';
import ColorReducer from './reducers/material-editor/ColorReducer';
import {
    createTagSlice,
    getFinishAndColorsByTagSlice,
    getFinishTagSlice,
    getModelAnchorTagSlice,
    getModelPartItemTaglice,
    getProductTagSlice,
    getTextureTagSlice,
} from './reducers/tag/tag.slice';
import { canReturnSlice } from './reducers/can-return/can-return.slice';

import {
    createGroupSlice,
    deleteGroupSlice,
    getAllGroupsSlice,
    getGroupSlice,
    getGroupsSlice,
    updateGroupSlice,
} from './reducers/mapping-zoning/group-mapping.reducer';

import {
    createZoningSlice,
    deleteZoningSlice,
    updateZoning2Slice,
    updateZoningSlice,
} from './reducers/mapping-zoning/zoning.reducer';

import {
    createMappingSlice,
    deleteMappingSlice,
    getAllMappingsSlice,
    getMappingSlice,
    updateMappingSlice,
} from './reducers/mapping-zoning/mapping.reducer';
import {
    createMappingConstraintSlice,
    deleteMappingConstraintSlice,
    getMappingConstraintSlice,
} from './reducers/mapping-zoning/mapping-constraint.reducers';

import {
    createProductInfoSlice,
    createProductSlice,
    deleteProductByIdSlice,
    getAllProductsByCollectionSlice,
    getAllProductsSlice,
    getChildrenProductsByCollectionSlice,
    getConfigurationByModel3dCellReferenceSlice,
    getProductAssociatedTagsByReferenceSlice,
    getProductByIdSlice,
    getProductCatalogLinesSlice,
    getProductDefaultPresetSlice,
    saveProductCaraSlice,
    saveProductThumbInfoSlice,
} from './reducers/product/product.reducers';

import {
    addPartItemSlice,
    createPartSlice,
    deletePartItemSlice,
    deletePartSlice,
    getAllPartsSlice,
    getPartSlice,
    updatePartNameSlice,
    updatePartSlice,
} from './reducers/product/part/part.reducers';

import {
    createProductModels3dSlice,
    deleteProductModels3dSlice,
    getAllModels3dByCollectionSlice,
    getAllModels3dByPartsSlice,
    getAllModels3dByTagSlice,
    getProductModels3dSlice,
    saveProductModels3dSlice,
    updateProductModelMappings3dSlice,
    updateProductModelMappings3dUrlSlice,
} from './reducers/product/3DModels/3DModels.reducers';
import {
    create3dCellInfoSlice,
    delete3dCellInfoSlice,
    get3dCellInfoSlice,
    getAll3dCellInfoSlice,
    getAllCellsByPartReferenceSlice,
    getAnchorPointSlice,
} from './reducers/product/3DCells/3DCells.reducers';
import {
    getProduct3DThumbsbyPartSlice,
    updateProduct3DThumbSlice,
    uploadCellThumbSlice,
} from './reducers/product/3DThumbnails/3dThumbnails.reducers';
import {
    changeAtomVisibilitySlice,
    create3dCellValueSlice,
    get3dCellValueSlice,
    get3dCellValueTreeSlice,
    getDefaultProductConfigurationSlice,
    rename3dCellValueLabelSlice,
} from './reducers/product/3DCells/3DCellValues.reducers';

import { panelSlice } from './reducers/panel/panel.slice';

import {
    createProductRulesSlice,
    deleteProductRulesSlice,
    executeRulesSlice,
    getProductRulesSlice,
    SwitchConfRulesTypeSlice,
} from './reducers/product/rules/rules.reducers';
import {
    addRoomSlice,
    deleteRoomSlice,
    duplicateRoomSlice,
    editRoomDescSlice,
    getRoomModelSlice,
    getRoomNameSlice,
    getRoomSlice,
    updateRoomNameSlice,
} from './reducers/rooms/rooms.slice';
import { getUserProjectByCodeSlice, updateProjectRoomSlice } from './reducers/projects/project.slice';
import {
    getConfigurateurSlice,
    getDefaultConfigurationSlice,
    getReferentialSetByConfigurationSlice,
    getReferentialSetSlice,
} from './reducers/configurateur/configurateur.slice';
import {
    getConfigurateurV2Slice,
    getDefaultConfigurationV2Slice,
    getReferentialSetByConfigurationV2Slice,
    getReferentialSetV2Slice,
    getSuggestionConfigurationV2Slice,
} from './reducers/configurateur-v2/configurateur-v2.slice';
import { getTablePresetsSlice } from './reducers/product/preset.slice';
import { getMappingMzSlice } from './reducers/mapping-zoning/mappingMz.reducer';

import {
    createSectionSlice,
    deleteSectionSlice,
    getAllSectionsByCatalogIdSlice,
    getAllSectionsSlice,
    getSectionByIdSlice,
    updateSectionsSlice,
} from './reducers/catalog/section.slice';

import {
    createSubSectionSlice,
    deleteSubSectionSlice,
    getAllSubSectionsSlice,
    getSubSectionByIdSlice,
    getSubSectionByProductIdSlice,
    linkPresetToSubSectionSlice,
    unLinkPresetToSubSectionSlice,
    updateSubSectionSlice,
} from './reducers/catalog/sub-section.slice';
import {
    catalogSlice,
    createAndUpdateCatalogSlice,
    deleteCatalogSlice,
    getCatalogByIdSlice,
    getCatalogByRefSlice,
    getCatalogsByProductIdSlice,
    getCatalogsSlice,
    getTreeCatalogSlice,
    linkProductToCatalogSlice,
    unlinkProductToCatalogSlice,
} from './reducers/catalog/catalog.slice';
import {
    addUserToStoreSlice,
    createStoreSlice,
    deleteStoreSlice,
    getStoreByManagerIdSlice,
    getStoreByRefSlice,
    getStoreSlice,
    getUserStoreMapSlice,
    removeUserToStoreSlice,
    updateStoreNameSlice,
} from './reducers/store/store.slice';
import {
    addMultipleAtomsToConfiguredProductSlice,
    createConfiguredProductSlice,
    deleteConfiguredProductSlice,
    duplicateConfiguredProductSlice,
    getConfiguredProductTreeListSlice,
    getConfiguredProductTreeSlice,
    getDefaultConfiguredProductByCoreProductRefSlice,
    setDefaultConfiguredProductSlice,
    uploadThumbnailUrlToConfiguredProductSlice,
} from './reducers/product/configured-product/configured-product.reducers';
import {
    addConstructionRulesSlice,
    deleteConstructionRuleSlice,
    findConstructionRuleByIdSlice,
    findConstructionRulesSlice,
    associateConstructionRuleSlice,
    dissociateConstructionRuleSlice, findConstructionRulesByCompoRefSlice,
} from './reducers/product/rules/construction-rules.reducers';
import {
    createCompoundedPresetSlice,
    deleteCompoundedPresetSlice, findCompoundedPresetsByCompositionIdSlice,
} from './reducers/product/compounded-preset-reducer/compounded.reducer';
import CharacteristicStudioReducer
    from "../application-3d/application3D-backoffice/Redux/Reducers/characteristic-studio/CharacteristicStudioReducer";
import productStudioSlice from "../application-3d/application3D-common/Redux/Reducers/product-studio/ProductStudioReducer";
import MappingStudioReducer from "../application-3d/application3D-backoffice/Redux/Reducers/MappingStudio/MappingStudioReducer";


const rootReducer = combineReducers({
    user: authSlice.reducer,
    createPassword: createPasswordSlice.reducer,
    news: getNewsSlice.reducer,
    newsStatusCount: getNewsStatusCountSlice.reducer,
    getNewsToUpdate: getNewsToUpdateSlice.reducer,
    updateNews: updateNewsSlice.reducer,
    createUser: createUserReducer,
    createFirstUser: createFirstUserSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    createNews: createNewsReducer,
    categoryList: newsCategoryReducer,
    toaster: toasterReducer,
    userList: usersSlice.reducer,
    getActiveUsers: getActiveUserSlice.reducer,
    getMe: getMeSlice.reducer,
    createCategory: createNewsCategoryReducer,
    modals: modalReducer,
    updateNewsStatus: updateNewsStatusReducer,
    categoryDeletion: deleteNewsCategory,
    resetPasswordByAdmin: resetPasswordUserSlice.reducer,
    resetUserPassword: resetPasswordReducer,
    userAndUnusedRoleList: userAndUnusedRoleSlice.reducer,
    createRole: createRoleSlice.reducer,
    updateUser: updateUserReducer,
    getUser: getUserSlice.reducer,
    getUserByStatus: getUsersByStatusSlice.reducer,
    deleteUser: deleteUsersSlice.reducer,
    countUserByStatus: getUserStatusCountSlice.reducer,
    getResponsibleList: responsibleSlice.reducer,
    deleteRole: deleteRoleSlice.reducer,
    editRole: editRoleSlice.reducer,
    getRole: getRoleSlice.reducer,
    getAllRoles: getAllRoleSlice.reducer,
    deleteNews: deleteNewsSlice.reducer,
    getLangage: getLangageSlice.reducer,
    saveLangage: saveLangageSlice.reducer,

    createSupplier: createSupplierSlice.reducer,
    getSuppliers: getSuppliersSlice.reducer,
    getSupplier: getSupplierSlice.reducer,
    getSupplierTexturesAndColoris: getSupplierTexturesAndColorisSlice.reducer,
    updateSupplier: updateSupplierSlice.reducer,
    deleteSupplier: deleteSupplierSlice.reducer,
    commonSupplier: getCommonSupplierSlice.reducer,
    findTagsByFinishId: findTagsByFinishIdSlice.reducer,

    createFinish: createFinishSlice.reducer,
    getFinish: getFinishlice.reducer,
    getFinishes: getFinishesSlice.reducer,
    getFinishesFromSupplierAndCommun: getFinishesFromSupplierAndCommunSlice.reducer,
    updateFinish: updateFinishSlice.reducer,
    deleteFinish: deleteFinishSlice.reducer,

    getCollection: getCollectionSlice.reducer,
    getCollections: getCollectionsSlice.reducer,
    createCollection: createCollectionSlice.reducer,
    updateCollection: updateCollectionSlice.reducer,
    deleteCollection: deleteCollectionSlice.reducer,

    getAllTextures: getAllTexturesSlice.reducer,
    updateTextures: updateTextureSlice.reducer,
    deleteTexture: deleteTextureSlice.reducer,
    createTextures: createTextureSlice.reducer,

    getAllColoris: getAllColorisSlice.reducer,
    getColoris: getColorisSlice.reducer,
    createColoris: createColorisSlice.reducer,
    deleteColoris: deleteColorisSlice.reducer,
    updateColoris: updateColorisSlice.reducer,
    updateColorisDto: updateColorisDTOSlice.reducer,
    duplicateColoris: duplicateColorisSlice.reducer,
    fetchAllColorisFromSupplierAndCommonRes: fetchAllColorisFromSupplierAndCommon.reducer,

    EntitiesReducer: EntitiesReducer.reducer,
    ThumbnailReducer: ThumbnailReducer.reducer,
    EditorReducer: EditorReducer.reducer,
    ColorReducer: ColorReducer.reducer,

    getFinishTag: getFinishTagSlice.reducer,
    getTextureTag: getTextureTagSlice.reducer,
    createTag: createTagSlice.reducer,
    getFinishAndColorsByTags: getFinishAndColorsByTagSlice.reducer,
    getModelPartItemTag: getModelPartItemTaglice.reducer,
    getModelAnchorTag: getModelAnchorTagSlice.reducer,

    getGroup: getGroupSlice.reducer,
    getGroups: getGroupsSlice.reducer,
    getAllGroups: getAllGroupsSlice.reducer,
    createGroup: createGroupSlice.reducer,
    updateGroup: updateGroupSlice.reducer,
    deleteGroup: deleteGroupSlice.reducer,

    createMapping: createMappingSlice.reducer,
    getMapping: getMappingSlice.reducer,
    updateMapping: updateMappingSlice.reducer,
    deleteMapping: deleteMappingSlice.reducer,

    createZoning: createZoningSlice.reducer,
    updateZoning: updateZoningSlice.reducer,
    updateZoning2: updateZoning2Slice.reducer,
    deleteZoning: deleteZoningSlice.reducer,

    createMappingConstraint: createMappingConstraintSlice.reducer,
    getAllMappings: getAllMappingsSlice.reducer,
    getMappingConstraint: getMappingConstraintSlice.reducer,
    deleteMappingConstraint: deleteMappingConstraintSlice.reducer,

    createProduct: createProductSlice.reducer,
    createProductInfo: createProductInfoSlice.reducer,
    saveProductCara: saveProductCaraSlice.reducer,
    saveProductThumb: saveProductThumbInfoSlice.reducer,
    updateProduct3DThumb: updateProduct3DThumbSlice.reducer,
    getAllProducts: getAllProductsSlice.reducer,
    getProductCollection: getAllProductsByCollectionSlice.reducer,
    getChildrenProducts: getChildrenProductsByCollectionSlice.reducer,
    getProductionById: getProductByIdSlice.reducer,
    productCatalogLines: getProductCatalogLinesSlice.reducer,
    deleteProduct: deleteProductByIdSlice.reducer,
    getProductTags: getProductTagSlice.reducer,
    canReturn: canReturnSlice.reducer,
    getProductAssociatedTagsByReference: getProductAssociatedTagsByReferenceSlice.reducer,

    createPart: createPartSlice.reducer,
    addPartItem: addPartItemSlice.reducer,
    getPart: getPartSlice.reducer,
    getAllParts: getAllPartsSlice.reducer,
    updatePart: updatePartSlice.reducer,
    updatePartName: updatePartNameSlice.reducer,
    deletePart: deletePartSlice.reducer,
    deletePartItem: deletePartItemSlice.reducer,

    createProduct3dModel: createProductModels3dSlice.reducer,
    getAllModels3dByParts: getAllModels3dByPartsSlice.reducer,
    getAllModels3dByCollection: getAllModels3dByCollectionSlice.reducer,
    getAllModels3DByTag: getAllModels3dByTagSlice.reducer,
    getProduct3dModel: getProductModels3dSlice.reducer,
    deleteProduct3dModel: deleteProductModels3dSlice.reducer,
    updateProduct3dMappings: updateProductModelMappings3dSlice.reducer,
    updateProduct3dUrl: updateProductModelMappings3dUrlSlice.reducer,
    saveProductModel3d: saveProductModels3dSlice.reducer,

    get3dCellInfo: get3dCellInfoSlice.reducer,
    delete3sCell: delete3dCellInfoSlice.reducer,
    create3dCell: create3dCellInfoSlice.reducer,
    getAll3dCell: getAll3dCellInfoSlice.reducer,
    getAllCellsByPartReference: getAllCellsByPartReferenceSlice.reducer,
    getAnchorPoint: getAnchorPointSlice.reducer,

    get3dCellValue: get3dCellValueSlice.reducer,
    getCellValueTree: get3dCellValueTreeSlice.reducer,
    create3dCellValue: create3dCellValueSlice.reducer,
    getDefaultConfiguration: getDefaultProductConfigurationSlice.reducer,

    get3dThumbByPart: getProduct3DThumbsbyPartSlice.reducer,
    uploadcellThumb: uploadCellThumbSlice.reducer,
    changeAtomVisibility: changeAtomVisibilitySlice.reducer,
    rename3dCellValueLabel: rename3dCellValueLabelSlice.reducer,

    characteristicStudioReducer: CharacteristicStudioReducer.reducer,
    productStudio: productStudioSlice.reducer,
    panel: panelSlice.reducer,
    mappingStudioReducer: MappingStudioReducer.reducer,

    getRules: getProductRulesSlice.reducer,
    createRule: createProductRulesSlice.reducer,
    deleteRules: deleteProductRulesSlice.reducer,
    switchRuleType: SwitchConfRulesTypeSlice.reducer,
    executeRules: executeRulesSlice.reducer,

    findConstructionRules: findConstructionRulesSlice.reducer,
    findConstructionRuleById: findConstructionRuleByIdSlice.reducer,
    findConstructionRuleByCompoRef: findConstructionRulesByCompoRefSlice.reducer,
    addConstructionRules: addConstructionRulesSlice.reducer,
    deleteConstructionRule: deleteConstructionRuleSlice.reducer,
    associateConstructionRule: associateConstructionRuleSlice.reducer,
    dissociateConstructionRule: dissociateConstructionRuleSlice.reducer,

    addRoom: addRoomSlice.reducer,
    getRoom: getRoomSlice.reducer,
    getRoomName: getRoomNameSlice.reducer,
    deleteRoom: deleteRoomSlice.reducer,
    duplicateRoom: duplicateRoomSlice.reducer,
    updateRoomName: updateRoomNameSlice.reducer,
    updateProjectRoom: updateProjectRoomSlice.reducer,
    editRoomDesc: editRoomDescSlice.reducer,
    getRoomModel: getRoomModelSlice.reducer,

    configurateurCollection: getConfigurateurSlice.reducer,
    configurateurV2Collection: getConfigurateurV2Slice.reducer,

    configurateurReferential: getReferentialSetSlice.reducer,
    configurateurV2Referential: getReferentialSetV2Slice.reducer,

    configurateurDefault: getDefaultConfigurationSlice.reducer,
    configurateurV2Default: getDefaultConfigurationV2Slice.reducer,
    suggestionConfiguration: getSuggestionConfigurationV2Slice.reducer,

    getTablePreset: getTablePresetsSlice.reducer,

    referentialSetByConfiguration: getReferentialSetByConfigurationSlice.reducer,
    referentialSetByConfigurationV2: getReferentialSetByConfigurationV2Slice.reducer,

    getMappingMz: getMappingMzSlice.reducer,
    getUserProjectByCode: getUserProjectByCodeSlice.reducer,

    getAllSections: getAllSectionsSlice.reducer,
    getSectionsByCatalogId: getAllSectionsByCatalogIdSlice.reducer,
    getSection: getSectionByIdSlice.reducer,
    createSection: createSectionSlice.reducer,
    updateSection: updateSectionsSlice.reducer,
    deleteSection: deleteSectionSlice.reducer,

    getAllSubSections: getAllSubSectionsSlice.reducer,
    getSubSection: getSubSectionByIdSlice.reducer,
    getSubSectionsByProductId: getSubSectionByProductIdSlice.reducer,
    createSubSection: createSubSectionSlice.reducer,
    updateSubSection: updateSubSectionSlice.reducer,
    deleteSubSectionn: deleteSubSectionSlice.reducer,
    linkToSubSection: linkPresetToSubSectionSlice.reducer,
    unLinkToSubSection: unLinkPresetToSubSectionSlice.reducer,

    createAndUpdateCatalog: createAndUpdateCatalogSlice.reducer,
    getCatalogs: getCatalogsSlice.reducer,
    getCatalogById: getCatalogByIdSlice.reducer,
    getCatalogByRef: getCatalogByRefSlice.reducer,
    deleteCatalog: deleteCatalogSlice.reducer,
    getTreeCatalog: getTreeCatalogSlice.reducer,
    linkProductToCatalog: linkProductToCatalogSlice.reducer,
    unlinkProductToCatalog: unlinkProductToCatalogSlice.reducer,
    getCatalogsByProductId: getCatalogsByProductIdSlice.reducer,

    getStores: getStoreSlice.reducer,
    getStoreByRef: getStoreByRefSlice.reducer,
    getStoreByManagerId: getStoreByManagerIdSlice.reducer,
    createStore: createStoreSlice.reducer,
    updateStoreName: updateStoreNameSlice.reducer,
    addUserToStore: addUserToStoreSlice.reducer,
    removeUserToStore: removeUserToStoreSlice.reducer,
    deleteStore: deleteStoreSlice.reducer,
    storeUserMap: getUserStoreMapSlice.reducer,

    getCatalogName: catalogSlice.reducer,

    getProductDefaultPreset: getProductDefaultPresetSlice.reducer,

    getConfiguredProductTreeList: getConfiguredProductTreeListSlice.reducer,
    getConfiguredProductTree: getConfiguredProductTreeSlice.reducer,
    createConfiguredProduct: createConfiguredProductSlice.reducer,
    addMultipleAtomsToConfiguredProduct: addMultipleAtomsToConfiguredProductSlice.reducer,
    uploadThumbnailUrlToConfiguredProduct: uploadThumbnailUrlToConfiguredProductSlice.reducer,
    deleteConfiguredProduct: deleteConfiguredProductSlice.reducer,
    duplicateConfiguredProduct: duplicateConfiguredProductSlice.reducer,
    setDefaultConfiguredProduct: setDefaultConfiguredProductSlice.reducer,
    getDefaultConfiguredProductByCoreProductRef: getDefaultConfiguredProductByCoreProductRefSlice.reducer,

    getConfigurationByModel3dCellReference: getConfigurationByModel3dCellReferenceSlice.reducer,

    createCompoundedPreset: createCompoundedPresetSlice.reducer,
    findCompoundedPresets: findCompoundedPresetsByCompositionIdSlice.reducer,
    deleteCompoundedPreset: deleteCompoundedPresetSlice.reducer,
});

// Infer the `RootState` and `AppDispatch` types from the services itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default rootReducer;
