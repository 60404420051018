import apiClient from '../../../api-client';
import {
    GET_CELL_THUMBS_BY_PART,
    UPDATE_THUMB,
    UPLOAD_CELL_THUMB,
} from '../../../endpoints/product/3DThumbnails/3DThumbnails';
import { Product } from '../../../../domain/domain';

const product3DThumbsService = {
    getCellThumbsByParts: async (partRef: string) => apiClient(GET_CELL_THUMBS_BY_PART(partRef), undefined, undefined, 'GET'),
    updateThumbnail: async (productRef: string, product:Product) => apiClient(UPDATE_THUMB(productRef), product, undefined, 'POST'),
    uploadCellThumbs: async (cellId: number, file: FormData) => apiClient(UPLOAD_CELL_THUMB(cellId), file, undefined, 'POST'),
};
export default product3DThumbsService;