import './simple-modal.scss';
import close from '../../../asset/CloseWindow.svg';
import trash from '../../../asset/trash-popup.png';
import save from '../../../asset/calendar.png';
import { useEffect, useRef, useState } from 'react';


type SimpleModalProps = {
    icon: string;
    title: string;
    info: string;
    closeOnclick: () => void;
    actionOnclick: () => void;
    deleteOrValide: boolean;
    closeLabel: string;
    actionLabel: string;
    confirmation?: boolean
    confirmationName?: string
};

const SimpleModal = (props: SimpleModalProps) => {
    const ref = useRef(null);
    const [validationName, setValidationName] = useState<string>('');

    useEffect(() => {
        // @ts-ignore
        ref.current !== null && ref.current.focus();
    }, []);

    const returnDisableButtonClass = (): boolean => validationName === props.confirmationName;

    return (
        <div className='simple-modal-main'>
            <div ref={ ref }
                 tabIndex={ 0 }
                 onKeyDown={ (e) => e.key === 'Enter' && props.actionOnclick() }
                 className='simple-modal-dialog-box'>
                <div className='modal-headi'>
                    { props.deleteOrValide
                        ? <img className="l" src={ trash } />
                        : <img className="l" src={ save } /> }
                    <div className='modal-title-block'>
                        <div className='title'>{ props.title }</div>
                        <div className='info'> { props.info }</div>
                    </div>
                    <img className='close' alt='close' onClick={ props.closeOnclick } src={ close } />
                </div>
                { props.confirmation &&
                    <div className='c'>
                        <span className='label'> Veuillez entrez le nom de la collection </span>
                        <input
                            onChange={ (e) => setValidationName(e.target.value) }
                            type='text' placeholder={ props.confirmationName } />
                    </div>
                }
                <div className='modal-button'>
                    <div className='button-container'>
                        <button onClick={ props.closeOnclick } className='button-cancel'>
                            { props.closeLabel }
                        </button>
                        <button
                            className={ props.deleteOrValide ? `button-action ${ returnDisableButtonClass() || !props.confirmation ? 'red' : 'disable' }` : 'button-action blue' }
                            onClick={ () => {
                                props.confirmation
                                    ? returnDisableButtonClass() ? props.actionOnclick() : console.log('unAuthorize')
                                    : props.actionOnclick();
                            } }>
                            { props.actionLabel }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SimpleModal;
