import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {MappingStudioApp} from "../../../Libraries/Studios/MappingStudio/MappingStudioApp";

export type MappingStudioState = {
    mappingStudioApp: MappingStudioApp | undefined, 
};

const StudioInitialState: MappingStudioState = {
    mappingStudioApp: undefined,
};

const MappingStudioSlice = createSlice({
    name: 'MappingStudio',
    initialState: StudioInitialState,
    //reducers : define how the state can be updated
    reducers: {
        SetStudio: (state, action: PayloadAction<any>) => {
            if (action.payload !== null)
                state.mappingStudioApp = action.payload;
        },
    },
});

export const { SetStudio } = MappingStudioSlice.actions;
export default MappingStudioSlice;
