import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../services/root-reducer";
import {EditorState} from "../../../services/reducers/material-editor/EditorReducer";
import {useEffect} from "react";
import {AddEntityID, ClearEntitiesIDs} from "../../../services/reducers/material-editor/EntitiesReducer";
import {Guid} from "guid-typescript";

const SupportBuilder = () => {
    const {game} = useSelector<RootState, EditorState>((state) => state.EditorReducer);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(!game)
        {
            dispatch(ClearEntitiesIDs());
            return;
        }
        
        game.TargetMeshService.BuildBoxMesh((p_id)=> {
            AddEntity(p_id, "Box");
        });
        game.TargetMeshService.BuildSphereMesh((p_id)=> {
            AddEntity(p_id, "Sphere");
        });
        game.TargetMeshService.BuildFabricMesh((p_id)=>{
            AddEntity(p_id, "Fabric");
        });
        game.TargetMeshService.BuildFinalMesh((p_id) => {
            AddEntity(p_id, "Dolce Vita");
        });
        game.TargetMeshService.BuildPalaceMesh((p_id) => {
            AddEntity(p_id, "Palace");
        });
        game.TargetMeshService.BuildPlateauMesh((p_id) => {
            AddEntity(p_id, "Plateau 200");
        });
        game.TargetMeshService.BuildCloth2Mesh((p_id) => {
            AddEntity(p_id, "Fabric2");
        });
        game.TargetMeshService.BuildFurnitureMesh((p_id) => {
            AddEntity(p_id, "Furniture");
            });
        
    }, [game]);
    
    let AddEntity = (p_id: Guid, name: string) => {
        dispatch(AddEntityID({entityId: p_id, thumbnailRef: name}));
    }
    
    return (
            <>
            </>
    );
};

export default SupportBuilder;
