import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router-dom';

import back from '../../asset/arrow-left.png';
import backi from '../../asset/arrowLeft.svg';
import SimpleModal from '../modal/simple-modal/simple-modal';
import './back-arrow.scss';
import backArrow from '../../asset/retour.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import { ColorState } from '../../services/reducers/material-editor/ColorReducer';
import { Params } from '../../domain/Params/params-interface';


type BackArrowProps = { currentLocation: string; labelBack?: string; info?: string };
const BackArrow = ({ currentLocation, labelBack, info }: BackArrowProps) => {
    const history = useHistory();
    const { supplierReference, productId, collectionReference } = useParams<Params>();
    const [open, setOpen] = useState(false);
    const [canReturn, setCanReturn] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const { storeName, storeRef } = useParams<Params>();

    const { isGltfDirty } = useSelector<RootState, ColorState>((state) => state.ColorReducer);

    useEffect(() => {
        !canReturn && setOpenModal(true);
    }, [canReturn]);

    return (
        <>
            { openModal && (
                <SimpleModal
                    icon={ backArrow }
                    title='Quitter sans sauvegarder'
                    info='Vous perdrez les changements en cours '
                    closeOnclick={ () => {
                        setCanReturn(true);
                        setOpenModal(false);
                    } }
                    actionOnclick={ () => {
                        setCanReturn(true);
                        setOpenModal(false);
                        history.goBack();
                    } }
                    deleteOrValide={ true }
                    closeLabel='Annuler'
                    actionLabel='Quitter'
                />
            ) }
            { open && (
                <SimpleModal
                    icon={ backi }
                    title={ labelBack || '' }
                    info={ info || '' }
                    closeOnclick={ () => setOpen(false) }
                    actionOnclick={ () => {
                        setOpen(false);
                        history.goBack();
                    } }
                    deleteOrValide={ true }
                    closeLabel='Annuler'
                    actionLabel='Confirmer'
                />
            ) }
            { currentLocation !== '/' && !currentLocation.includes('/home') && !currentLocation.includes('/create-password') ? (
                <div
                    className='back-arrow-main'
                    onClick={ () => {
                        if (currentLocation.includes('colors')) {
                            isGltfDirty ? setCanReturn(false) : history.push(`/supplier/${ supplierReference }/finitions`);
                        }
                        if (currentLocation.includes('textures')) {
                            history.push(`/supplier/${ supplierReference }/finitions`);
                        }
                        if (!currentLocation.includes('textures') && !currentLocation.includes('colors')) {
                            info !== undefined ? setOpen(true) : history.push('/home/suppliers');
                        }
                        if (currentLocation.includes('collection') && currentLocation.includes('product')) {
                            history.push(`/supplier/${ supplierReference }/collections/${ collectionReference }/products`);
                        }
                        if (currentLocation.includes('collections') && currentLocation.includes('products') && productId === undefined) {
                            history.push(`/supplier/${ supplierReference }/collections`);
                        }
                        if (currentLocation.includes('collections') && currentLocation.includes('mapping')) {
                            history.push(`/supplier/${ supplierReference }/collections`);
                        }
                        if (currentLocation.includes('catalog')) {
                            history.push(`/catalogs`);
                        }if (currentLocation.includes('create-news-category')) {
                            history.push(`/home/news-categories`);
                        }
                        if (currentLocation.includes('catalogs')) {
                            history.push(`/home/news`);
                        }
                        if (currentLocation.includes('store') && !currentLocation.includes('add-users')) {
                            history.push(`/stores`);
                        }
                        if (currentLocation.includes('update-user-by-admin') && !currentLocation.includes('add-users')) {
                            history.push(`/home/users/active`);
                        }
                        if (currentLocation.includes('store') && currentLocation.includes('add-users')) {
                            history.push(`/store/${ storeRef }/${ storeName }`);
                        }
                    } }
                >
                    {/*@ts-ignore*/}
                    <FontAwesomeIcon icon={ faArrowLeft } size='1x' />
                    <div className='back-title'>Retour</div>
                </div>
            ) : (
                <div />
            ) }
        </>
    );
};
export default BackArrow;
