import { Engine } from '@lutithree/build/Engine';
import { Group, Material, MeshPhysicalMaterial, Object3D } from "three";
import { MeshRendererComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent";
import { MeshFilterComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";
import { Guid } from "guid-typescript";
import {ResourceLoadingError} from "@lutithree/build/Modules/WebGL/Resources/Load/ResourceLoadingError";
import {TexturesLoadingError} from "@lutithree/build/Modules/WebGL/Resources/Load/TexturesLoadingError";
import EntityMeshController from '../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/EntityMeshController';
import ResourceErrorData
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ResourceErrorData";
import ResourceLoadingFailEvent from '../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/ResourceLoadingFailEvent';
import Asset3D from '../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D';
import {
    Model3dDecorator
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/EntityDecorators/Assets/Model3dDecorator";
import {
    ModelDecorator
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/EntityDecorators/Assets/ModelDecorator";
import AssetErrorData
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetErrorData";
import MaterialAssignmentDecorator
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/EntityDecorators/Assets/MaterialAssignmentDecorator";
import {
    MaterialDecorator
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/EntityDecorators/Assets/MaterialDecorator";
import TextureErrorData
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/TextureErrorData";

export class MeshLoaderService {
    private m_engine: Engine;

    private m_entityID: Guid;

    private m_meshController: EntityMeshController;

    protected m_onResourceLoadingFailed: (p_resourceErrorData: ResourceErrorData)=> void;

    public constructor(p_engine: Engine) {
        if (p_engine == null) throw new Error('NullReference Exception: p_engine is null or undefined');
        this.m_engine = p_engine;
        this.m_entityID = this.m_engine.Modules.Scene.CreateEntity('ViewerMesh').Id;
        this.m_meshController = new EntityMeshController();

        this.m_onResourceLoadingFailed = (p_resourceErrorData: ResourceErrorData)=>{
            this.m_engine.Modules.EventManager.Publish(ResourceLoadingFailEvent, new ResourceLoadingFailEvent(p_resourceErrorData));
            this.m_engine.Modules.LoopStrategy.RequestRender(true);
        };
    }

    public ClearManager(): void{
        if(this.m_engine.Modules.Scene.HasEntityWithID(this.m_entityID)) this.m_engine.Modules.Scene.RemoveEntityByID(this.m_entityID);
    }

    private ClearEntityMaterial() {
        if (this.m_engine.Modules.Scene.HasEntityWithID(this.m_entityID)) {
            let entity = this.m_engine.Modules.Scene.GetEntityByID(this.m_entityID);

            if (entity.HasComponentOfType(MeshRendererComponent))
                entity.RemoveComponent(entity.GetComponentOfType(MeshRendererComponent));
        }
    }

    private ClearEntityModel() {
        if (this.m_engine.Modules.Scene.HasEntityWithID(this.m_entityID)) {
            let entity = this.m_engine.Modules.Scene.GetEntityByID(this.m_entityID);

            if (entity.HasComponentOfType(MeshFilterComponent))
                entity.RemoveComponent(entity.GetComponentOfType(MeshFilterComponent));
        }
    }

    public LoadModel(p_assetModel: Asset3D): Promise<Object3D>{
        if(p_assetModel == null) throw new Error("p_asset is undefined or null");

        if (!this.m_engine.Modules.Scene.HasEntityWithID(this.m_entityID))
            this.m_entityID = this.m_engine.Modules.Scene.CreateEntity('ViewerMesh').Id;

        const cleanUpFunc = (resource:Material | Material[] | Group | Object3D) => {
            if (Array.isArray(resource)) {
                resource.forEach((item) => {
                    this.m_engine.Modules.Resources.Cleaner.Dispose(item);
                });
            } else this.m_engine.Modules.Resources.Cleaner.Dispose(resource);
        };

        this.ClearEntityModel();
        let deco : ModelDecorator|Model3dDecorator;
        if(p_assetModel.Type === "Model")
            deco = new ModelDecorator();
        else if(p_assetModel.Type === "Model3d")
            deco = new Model3dDecorator();
        else
            throw new Error("Type was not correctly handled in Mesh Loader Service");

        let entity = this.m_engine.Modules.Scene.GetEntityByID(this.m_entityID);

        if(p_assetModel.Datas == undefined) this.m_onResourceLoadingFailed(new AssetErrorData("", p_assetModel, ""));
        return new Promise<Object3D>((resolve, reject)=>{
            deco.DecorateAsset(entity, p_assetModel, cleanUpFunc).then(()=>{
                if(entity.HasComponentOfType(MeshFilterComponent)){
                    let meshFilter = entity.GetComponentOfType(MeshFilterComponent);
                    let meshRenderer = entity.GetComponentOfType(MeshRendererComponent)? entity.GetComponentOfType(MeshRendererComponent): undefined;
                    if(meshRenderer && !meshRenderer.Material) meshRenderer.Material = new Map<string, Material>();
                    this.m_engine.Modules.LoopStrategy.RequestRender(true);
                    resolve(meshFilter.GetObject());
                }
                else
                    reject();
            }).catch((url)=>{
                this.m_onResourceLoadingFailed(new AssetErrorData(url, p_assetModel, ""));
            });
        });
    }

    public LoadMaterialMapping(p_assetMaterial: Asset3D): Promise<void>{
        if (p_assetMaterial == null) throw new Error("p_assetMaterial is undefined or null");

        if (!this.m_engine.Modules.Scene.HasEntityWithID(this.m_entityID))
            this.m_entityID = this.m_engine.Modules.Scene.CreateEntity('ViewerMesh').Id;

        const cleanUpFunc = (resource: Material | Material[] | Group | Object3D) => {
            if (Array.isArray(resource)) {
                resource.forEach((item) => {
                    this.m_engine.Modules.Resources.Cleaner.Dispose(item);
                });
            } else this.m_engine.Modules.Resources.Cleaner.Dispose(resource);
        };

        let deco;
        if (p_assetMaterial.Type === "MaterialAssignment")
            deco = new MaterialAssignmentDecorator(new MeshPhysicalMaterial());
        else if (p_assetMaterial.Type === "Material")
            deco = new MaterialDecorator(new MeshPhysicalMaterial());
        else{
            console.warn("Type was not correctly handled in Material Loader Service. asset.Type = '"+p_assetMaterial.Type+"'");
            return new Promise<void>(()=>{});
        }

        let entity = this.m_engine.Modules.Scene.GetEntityByID(this.m_entityID);
        if(p_assetMaterial.Datas == undefined) this.m_onResourceLoadingFailed(new AssetErrorData("", p_assetMaterial, ""));

        return deco.DecorateAsset(entity, p_assetMaterial, cleanUpFunc).then(()=>{
            this.m_engine.Modules.LoopStrategy.RequestRender(true);
        }).catch((reason)=>{
            if(reason instanceof TexturesLoadingError){
                reason.Textures.forEach((url,slot)=>{
                    this.m_onResourceLoadingFailed(new TextureErrorData(url, reason.Path, slot));
                });
            } else if(reason instanceof ResourceLoadingError){
                this.m_onResourceLoadingFailed(new AssetErrorData(reason.Path, p_assetMaterial, ''));
            }
        });
    }
}
