import MdfApiResponse from '../../../../domain/common/generic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import partService from '../../../../api/services/products/part/part.service';
import { Part, PartItemDTO } from '../../../../domain/domain';

export type PartState = {
    isLoading: false;
    payload: MdfApiResponse<Part>
};
const initialPartState: PartState = {
    isLoading: false,
    payload: {
        content: {
            name: '',
            partCharacteristicMetadata: { partItemList: [] },
            partOptionMetadata: { partItemList: [] },
            id: 0,
            reference: '',
            productReference: '',
            updatedAt: new Date(),
            createdAt: new Date(),
            version: 0,
            deleted: false,
        },
        errors: [], warnings: [],
    },
};
export type PartListState = {
    isLoading: false;
    payload: MdfApiResponse<Part[]>
};
const initialPartListState: PartListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};


//<editor-fold desc="get-all-part">
export const getAllParts = createAsyncThunk('get/all-parts', async (productRef: string) => {
    const res = await partService.getAllParts(productRef);
    return res;
});

export const getAllPartsSlice = createSlice({
    name: 'getAllParts',
    initialState: initialPartListState,
    reducers: {
        resetGetAllParts: (state) => {
            state = initialPartListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllParts.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllParts.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllParts.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetAllParts } = getAllPartsSlice.actions;
//</editor-fold>

//<editor-fold desc="get-part">
export const getPart = createAsyncThunk('get/part', async (partRef: string) => {
    const res = await partService.getPart(partRef);
    return res;
});

export const getPartSlice = createSlice({
    name: 'getPart',
    initialState: initialPartState,
    reducers: {
        resetGetPart: (state) => {
            state = initialPartState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPart.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getPart.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getPart.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetPart } = getPartSlice.actions;
//</editor-fold>

//<editor-fold desc="update-part-name">
export const updatePartName = createAsyncThunk('update/partName', async (data: { partRef: string, name: string }) => {
    const res = await partService.updatePartName(data.partRef, data.name);
    return res;
});

export const updatePartNameSlice = createSlice({
    name: 'updatePartName',
    initialState: initialPartState,
    reducers: {
        resetUpdatePartName: (state) => {
            state = initialPartState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updatePartName.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updatePartName.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updatePartName.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetUpdatePartName } = updatePartNameSlice.actions;
//</editor-fold>

//<editor-fold desc="delete-part">
export const deletePart = createAsyncThunk('delete/part', async (partId: number) => {
    const res = await partService.deletePart(partId);
    return res;
});

export const deletePartSlice = createSlice({
    name: 'deletePart',
    initialState: initialPartState,
    reducers: {
        resetDeletePart: (state) => {
            state = initialPartState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deletePart.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deletePart.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deletePart.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetDeletePart } = deletePartSlice.actions;
//</editor-fold>

//<editor-fold desc="delete-part-item">
export const deletePartItem = createAsyncThunk('delete/part-item', async (data: { partRef: string, itemRef: string }) => {
    const res = await partService.deletePartItem(data.partRef, data.itemRef);
    return res;
});

export const deletePartItemSlice = createSlice({
    name: 'deletePartItem',
    initialState: initialPartState,
    reducers: {
        resetDeletePartItem: (state) => {
            state = initialPartState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deletePartItem.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deletePartItem.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deletePartItem.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetDeletePartItem } = deletePartItemSlice.actions;
//</editor-fold>

//<editor-fold desc="create-part">
export const createPart = createAsyncThunk('create/part', async (data: { name: string, productRef: string, }) => {
    const res = await partService.createPart(data.productRef, { name: data.name });
    return res;
});

export const createPartSlice = createSlice({
    name: 'createPart',
    initialState: initialPartState,
    reducers: {
        resetCreatePart: (state) => {
            state = initialPartState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createPart.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createPart.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createPart.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetCreatePart } = createPartSlice.actions;
//</editor-fold>

//<editor-fold desc="add-part-item">
export const addPartItem = createAsyncThunk('add/part-item', async (data: { partRef: string, partItem: PartItemDTO, }) => {
    const res = await partService.addPartItem(data.partRef, data.partItem);
    return res;
});

export const addPartItemSlice = createSlice({
    name: 'addPartItem',
    initialState: initialPartState,
    reducers: {
        resetAddPartItem: (state) => {
            state = initialPartState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addPartItem.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(addPartItem.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(addPartItem.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetAddPartItem } = addPartItemSlice.actions;
//</editor-fold>

//<editor-fold desc="update-part">
export const updatePart = createAsyncThunk('update/part', async (partId: number, form: {}) => {
    const res = await partService.updatePart(partId, form);
    return res;
});

export const updatePartSlice = createSlice({
    name: 'updatePart',
    initialState: initialPartState,
    reducers: {
        resetUpdatePart: (state) => {
            state = initialPartState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updatePart.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updatePart.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updatePart.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetUpdatePart } = updatePartSlice.actions;
//</editor-fold>