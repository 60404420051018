import apiClient from '../../api-client';
import {
    ADD_CONSTRUCTION_RULES,
    FIND_CONSTRUCTION_RULES,
    FIND_CONSTRUCTION_RULES_BY_ID,
    FIND_CONSTRUCTION_RULES_BY_COMPOSITION_REFERENCE,
    DELETE_CONSTRUCTION_RULES,
    ASSOCIATE_CONSTRUCTION_RULES,
    DISSOCIATE_CONSTRUCTION_RULES,
} from '../../endpoints/rules/construction-rules';
import { ConstructionRuleType, Tag } from '../../../domain/domain';


export type ConstructionRulesForm = {
    id?: number
    version?: number
    name: string
    collectionReference: string
    tagList: Tag[]
    type: ConstructionRuleType

};

const constructionRuleSercice = {
    addConstructionRules: async (form: ConstructionRulesForm) => apiClient(ADD_CONSTRUCTION_RULES, form, undefined, 'POST'),
    findConstructionRules: async (collectionRef: string) => apiClient(FIND_CONSTRUCTION_RULES(collectionRef), undefined, undefined, 'GET'),
    findConstructionRulesByCompositionReference: async (compositionRef: string) => apiClient(FIND_CONSTRUCTION_RULES_BY_COMPOSITION_REFERENCE(compositionRef), undefined, undefined, 'GET'),
    findConstructionRuleById: async (ruleId: number) => apiClient(FIND_CONSTRUCTION_RULES_BY_ID(ruleId), undefined, undefined, 'GET'),
    deleteConstructionRule: async (ruleId: number) => apiClient(DELETE_CONSTRUCTION_RULES(ruleId), undefined, undefined, 'DELETE'),

    associateConstructionRules: async (compositionRef: string, ruleIdsList: number[]) => apiClient(ASSOCIATE_CONSTRUCTION_RULES(compositionRef), ruleIdsList, undefined, 'POST'),
    dissociateConstructionRule: async (compositionRef: string, ruleId: number) => apiClient(DISSOCIATE_CONSTRUCTION_RULES(compositionRef, ruleId), undefined, undefined, 'DELETE'),
};
export default constructionRuleSercice;