import './product-detail-nav.scss';

type ProductDetailNavProps = {
    setFocus: (focus: number) => void;
    focus: number;
    step: number;
    product: any;
    setOpenModule: (openModule: boolean) => void;
    setOpenPreset: (openModule: boolean) => void;
};

const ProductDetailNav = (props: ProductDetailNavProps) => {
    const focusSpan = (label: string, i: number) => {
        return (
            <span className={props.focus === i ? 'tab focus' : 'tab'} onClick={() => (props.step >= i ? props.setFocus(i) : console.log('pas disponible'))}>
                {label}
            </span>
        );
    };

    return (
        <>
            {}
            {props.product && props.product.first.productType === 'CONFIGURABLE' && (
                <div className="products-detail-nav-main">
                    {focusSpan('Informations', 0)}
                    {focusSpan('Caractéristiques', 1)}
                    {focusSpan('Modèles 3D', 2)}
                    {focusSpan('Règles metier', 3)}
                    {focusSpan('Vignettes des caractéristiques', 4)}
                    {focusSpan('Presets', 5)}
                </div>
            )}
            {props.product && props.product.first.productType === 'SIMPLE' && (
                <div className="products-detail-nav-main">
                    {focusSpan('Information', 0)}
                    {focusSpan('Modèles 3D', 1)}
                    {focusSpan('Vignette du produit', 2)}
                </div>
            )}
            {props.product && props.product.first.productType === 'COMPOSITION' && (
                <div className="products-detail-nav-main">
                    <div>
                        {focusSpan('Informations', 0)}
                        {focusSpan('Caractéristique', 1)}
                        {focusSpan('Modèles 3D', 2)}
                        {focusSpan('Modules', 3)}
                        {focusSpan('Règles metier', 4)}
                        {focusSpan('Presets', 5)}
                    </div>
                    {props.focus === 3 && <button onClick={() => props.setOpenModule(true)}>+ Associer des produits</button>}
                    {props.focus === 5 && <button onClick={() => props.setOpenPreset(true)}>+ Creer un preset</button>}
                </div>
            )}
        </>
    );
};
export default ProductDetailNav;
