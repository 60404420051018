import { useEffect, useRef, useState } from 'react';
import SplitPaper from '../paper/split-paper';
import Textfield from '../textfield/textfield';
import { Button } from '../button/button';
import UiError from '../errors/ui-error';
import { useDispatch, useSelector } from 'react-redux';

import SimpleModal from '../modal/simple-modal/simple-modal';

import send from '../../asset/Envoyer blanc.svg';
import {
    createSupplier,
    CreateSupplierState,
    resetCreateSupplier,
} from '../../services/reducers/suppliers/suppliers.slice';
import { RootState } from '../../services/root-reducer';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './create-supplier.scss';
import { getConfig } from '../../api/config';
import close from '../../asset/CloseWindow.svg';

const CreateSupplierForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const formRef = useRef<HTMLInputElement>(null);
    const token = localStorage.getItem('mdf-jwt');

    const [name, setName] = useState('');
    const [isNameErr, setIsNameErr] = useState(false);
    const [nameErrMsg, setNameErrMsg] = useState('');

    const [ref, setRef] = useState('');
    const [isRefErr, setIsRefErr] = useState(false);
    const [refErrMsg, setRefErrMsg] = useState('');

    const [isServerErr, setIsServerErr] = useState<boolean>(false);
    const [serverErrMsg, setServerErrMsg] = useState('Une erreur inconnue est survenue ');

    const [file, setFile] = useState<File | undefined>(undefined);
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

    const [openM, setOpenM] = useState(false);
    const createRes = useSelector<RootState, CreateSupplierState>((state) => state.createSupplier);

    const checkError = () => {
        if (name.length < 1) {
            if (name.length < 1) {
                setIsNameErr(true);
                setNameErrMsg('Le nom doit être spécifié');
            }
            return false;
        } else {
            return true;
        }
    };
    const onFileChange = (e: any) => {
        if (e.target && e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };
    const onSubmit = () => {
        let formData = new FormData();
        if (formRef.current !== null && formRef.current.files !== null) {
            formData.append('file', formRef.current.files[0]);
            if (formRef.current.files[0] !== undefined) {
                axios({
                    method: 'post',
                    url: `${getConfig().urlHostApi}/v1/documents/upload`,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then(function(res) {
                        dispatch(createSupplier({ name: name, logo: res.data.content }));
                    })
                    .catch(function(response) {
                        console.log(response);
                    });
            } else {
                dispatch(createSupplier({ name: name, logo: null }));
            }
        }
    };

    const openModal = () => {
        if (checkError()) {
            setOpenM(true);
        }
    };

    useEffect(() => {
        if (createRes.payload.errors.length > 0) {
            setIsServerErr(true);
            setOpenM(false);
        } else {
            if (createRes.payload.content !== undefined) {
                history.push('/home/suppliers');
                dispatch(resetCreateSupplier());
            }
        }
    }, [createRes]);
    useEffect(() => {
        if (file) {
            setImageUrl(URL.createObjectURL(file));
        }
    }, [file]);

    return (
        <SplitPaper>
            {openM && (
                <SimpleModal
                    icon={send}
                    title='Créer un fournisseur'
                    info='Cette action créera un nouveau fournisseur '
                    closeOnclick={() => {
                        setOpenM(false);
                    }}
                    actionOnclick={onSubmit}
                    deleteOrValide={false}
                    closeLabel='ANNULER'
                    actionLabel='CREER'
                />
            )}
            <div className='supplier-form'>
                <h6>INFORMATIONS GÉNÉRALES</h6>
                <div className='form'>
                    <Textfield
                        onChange={(v) => {
                            setNameErrMsg('');
                            setIsNameErr(false);
                            setIsServerErr(false);
                            setName(v);
                        }}
                        isError={isNameErr}
                        errorMessage={nameErrMsg}
                        label='Nom du Fournisseur'
                        value={name}
                        placeholder='Nom du fournisseur'
                    />
                </div>
                <div className='download-box'>
                    <div className='input'>
                        <label>Logo du fournisseur </label>
                        <input
                            ref={formRef}
                            type='file'
                            name='file'
                            accept='image/jpeg,image/png,image/jpg'
                            onChange={(e) => {
                                onFileChange(e);
                            }}
                        />
                    </div>
                    {file !== undefined ? (
                        <div className='prev-img'>
                            <img onClick={() => {
                                setFile(undefined);
                            }} alt='fermer' src={close} />
                            <img alt='logo fournisseur' className='img' src={imageUrl} />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            </div>
            <div className='error-panel'>
                <div className='errorMsg'>{isServerErr && <UiError errorMessage={serverErrMsg} />}</div>
                <Button label='Créer' primary size='large' onClick={openModal} />
            </div>
        </SplitPaper>
    );
};
export default CreateSupplierForm;
