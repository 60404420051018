import MdfApiResponse from '../../../domain/common/generic';
import { CreateRoleForm, Role, RoleWithNumberOfUser } from '../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RolesService from '../../../api/services/roles/roles.service';

export type UsedAndUnusedRoleState = {
    isLoading: boolean;
    payload: MdfApiResponse<RoleWithNumberOfUser[]>;
};

const initialState: UsedAndUnusedRoleState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const getUsedAndUnusedRoleList = createAsyncThunk('userAndUnusedRoles/list', async (_, thunkApi) => {
    const response = await RolesService.getALLUsedAndUnusedRoles();
    return response;
});

export const userAndUnusedRoleSlice = createSlice({
    name: 'userAndUnusedRoleSlice',
    initialState,
    reducers: {
        resetRoleList: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUsedAndUnusedRoleList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getUsedAndUnusedRoleList.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getUsedAndUnusedRoleList.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

//==========

export type CreateRoleState = {
    isLoading: boolean;
    isCreated: boolean;
    payload: MdfApiResponse<RoleWithNumberOfUser[]>;
};

const initialCreateRoleState: CreateRoleState = {
    isLoading: false,
    isCreated: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const createRole = createAsyncThunk('createRole/list', async (form: CreateRoleForm, thunkApi) => {
    const response = await RolesService.createRole(form);
    return response;
});

export const createRoleSlice = createSlice({
    name: 'createRoleSlice',
    initialState: initialCreateRoleState,
    reducers: {
        resetCreateRole: (state) => {
            state = initialCreateRoleState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createRole.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createRole.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, isCreated: true };
            return state;
        });
        builder.addCase(createRole.rejected, (state) => {
            state.isLoading = false;
        });
    },
});


export const { resetCreateRole } = createRoleSlice.actions;

//====== DELETE ROLE

export type DeletedRoleState = {
    isLoading: boolean;
    payload: MdfApiResponse<Role | undefined>;
};

const initialDeleteRoleState: DeletedRoleState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

export const deleteRole = createAsyncThunk('role/delete', async (roleId: number, thunkApi) => {
    const response = await RolesService.deleteRole(roleId);
    return response;
});

export const deleteRoleSlice = createSlice({
    name: 'deleteRoleSlice',
    initialState: initialDeleteRoleState,
    reducers: {
        resetRoleDeletion: (state) => {
            state = initialDeleteRoleState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteRole.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteRole.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteRole.rejected, (state) => {
            state.isLoading = false;
        });
    },
});


//===== Edit Role =====


const initialEditRoleState: CreateRoleState = {
    isLoading: false,
    isCreated: false,
    payload: { content: [], errors: [], warnings: [] },
};

type DateToEditRole = {
    roleId: number,
    form: CreateRoleForm,
};

export const editRole = createAsyncThunk('editRole/list', async (data: DateToEditRole, thunkApi) => {
    const response = await RolesService.editRole(data.roleId, data.form);
    return response;
});

export const editRoleSlice = createSlice({
    name: 'editRoleSlice',
    initialState: initialEditRoleState,
    reducers: {
        resetEditRole: (state) => {
            state = initialEditRoleState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(editRole.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(editRole.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, isCreated: true };
            return state;
        });
        builder.addCase(editRole.rejected, (state) => {
            state.isLoading = false;
        });
    },
});


export const { resetEditRole } = editRoleSlice.actions;


//===== Get Role =====

export type GetRoleState = {
    isLoading: boolean;
    payload: MdfApiResponse<Role>;
};

const initialGetRoleState: GetRoleState = {
    isLoading: false,
    payload: { content: { label: '', name: '', id: 0, permissions: [], version: 0 }, errors: [], warnings: [] },
};

export const getRole = createAsyncThunk('getRole/elt', async (roleId: number, thunkApi) => {
    const response = await RolesService.getRole(roleId);
    return response;
});

export const getRoleSlice = createSlice({
    name: 'getRoleSlice',
    initialState: initialGetRoleState,
    reducers: {
        resetGetRole: (state) => {
            state = initialGetRoleState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRole.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getRole.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getRole.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetRole } = getRoleSlice.actions;

//===== Get all  Role =====

export type GetRoleListState = {
    isLoading: boolean;
    payload: MdfApiResponse<Role[]>;
};

const initialGetRoleListState: GetRoleListState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [], warnings: [],
    },
};

export const getAllRole = createAsyncThunk('getAllRole/', async (thunkApi) => {
    const response = await RolesService.getALLRoles();
    return response;
});

export const getAllRoleSlice = createSlice({
    name: 'getAllRoleSlice',
    initialState: initialGetRoleListState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllRole.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllRole.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllRole.rejected, (state) => {
            state.isLoading = false;
        });
    },
});


