import InspectorContainer from './InspectorContainer';
import Viewer3DContainer from './Viewer3DContainer';
import CurrentMaterialLoader from './CurrentMaterialLoader';
import ResourceErrorData
    from "../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/ResourceErrorData";

export type MaterialEditorProps = {
    onLoadingFail?: (p_resourceErrorData: ResourceErrorData) => void;
};

const MaterialEditor = (props: MaterialEditorProps) => {
    return (
            <>
                <InspectorContainer />
                <Viewer3DContainer />
                <CurrentMaterialLoader onLoadingFail={props.onLoadingFail} />
            </>
    );
};

export default MaterialEditor;
