import apiClient from '../../../api-client';
import {
    CREATE_FINISH,
    DELETE_FINISH,
    GET_FINISH,
    GET_FINISHES,
    GET_FINISHES_FROM_SUPPLIER_AND_COMMUN,
    UPDATE_FINISH,
} from '../../../endpoints/suppliers/finish/finishes.endpoints';
import { FinishCreationForm } from '../../../../domain/domain';

const finishesService = {
    getFinish: async (finishId: number) => apiClient(GET_FINISH(finishId), undefined, undefined, 'GET'),
    getFinishes: async (supplierReference: string) => apiClient(GET_FINISHES(supplierReference), undefined, undefined, 'GET'),
    getFinishesFromSupplierAndCommun: async (supplierReference: string) => apiClient(GET_FINISHES_FROM_SUPPLIER_AND_COMMUN(supplierReference), undefined, undefined, 'GET'),
    createFinish: async (supplierReference: string, data: FinishCreationForm) => apiClient(CREATE_FINISH(supplierReference), data, undefined, 'POST'),
    updateFinish: async (finishId: number, data: FinishCreationForm) => apiClient(UPDATE_FINISH(finishId), data, undefined, 'PUT'),
    deleteFinish: async (finishId: number) => apiClient(DELETE_FINISH(finishId), undefined, undefined, 'DELETE'),
};

export default finishesService;
