import { useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import { NewsCategoryState } from '../../services/reducers/news/news-cateogory-list.slice';
import { colorScheme } from '../color-combo/color-scheme';
import './tag.scss';


type TagProps = {
    width?: number;
    height?: number;
    title: string | undefined;
    newsCategoryId: number;
};

const Tag = ({ width, height, newsCategoryId }: TagProps) => {
    const {
        payload: { content },
    } = useSelector<RootState, NewsCategoryState>((state) => state.categoryList);

    const findCombo = (comboName: string) => {
        const foundCombo = colorScheme.find((el) => el.name === comboName);

        if (foundCombo !== undefined) {
            return foundCombo;
        } else {
            return { labelAssociated: '#F8A12D', backgroundColor: '#FFE8C3' };
        }
    };

    return (
        <>
            { newsCategoryId ? (
                content.map((el) => {
                    const name = el.name;
                    const namePrev = name.substring(0, 16) + '...';
                    if (newsCategoryId === el.id) {
                        return (
                            <div
                                title={ el.name }
                                key={ el.id }
                                className="tag-main"
                                style={ {
                                    width: `${ width }px`,
                                    height: `${ height }px`,
                                    backgroundColor: findCombo(el.color)?.backgroundColor,
                                    color: findCombo(el.color)?.labelAssociated,
                                } }
                            >
                                { el.name.length <= 16 ? el.name : namePrev }
                            </div>
                        );
                    } else {
                        return <div key={ el.id } />;
                    }
                })
            ) : (
                <div>Categorie</div>
            ) }
        </>
    );
};
export default Tag;
