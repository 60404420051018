import * as THREE from 'three';
import {Material, MathUtils, MeshPhysicalMaterial, Texture, Vector2} from 'three';
import * as TweakpaneImagePlugin from '../../Libraries/TweakPaneImgPlugin';
import {MapType} from './MapType';
import MaterialEditor from './MaterialEditor';
import TransformData from './TransformData';
import MaterialEditorParameters from './MaterialEditorParameters';
import {Pane} from 'tweakpane';
import {SetCurrentMatName} from '../../Libraries/TweakPaneUtilities';
import {diff} from 'json-diff';
import {LutithreeToneMappingMode} from "@lutithree/build/Modules/WebGL/Rendering/LutithreeToneMappingMode";
import {Specular} from "./Parameters/Specular";
import {Albedo} from "./Parameters/Albedo";
import {Roughness} from "./Parameters/Roughness";
import {AlbedoBind} from "./Binds/AlbedoBind";
import {RoughnessBind} from './Binds/RoughnessBind';
import {Metalness} from "./Parameters/Metalness";
import {MetalnessBind} from "./Binds/MetalnessBind";
import {Normal} from "./Parameters/Normal";
import {NormalBind} from "./Binds/NormalBind";
import {ClearCoat, ClearcoatNormal, ClearCoatRoughness} from "./Parameters/ClearCoat";
import {Sheen} from "./Parameters/Sheen";
import {Transmission} from "./Parameters/Transmission";
import {Transform} from "./Parameters/Transform";
import {Emissive} from "./Parameters/Emissive";
import {EmissiveBind} from "./Binds/EmissiveBind";
import {ClearcoatBind} from "./Binds/ClearcoatBind";
import {SheenBind} from "./Binds/SheenBind";
import {TransmissionBind} from "./Binds/TransmissionBind";
import {TransformBind} from "./Binds/TransformBind";
import {AO} from "./Parameters/AO";
import {Iridescence} from "./Parameters/Iridescence";
import {Anisotropy} from "./Parameters/Anisotropy";
import {SpecularBind} from "./Binds/SpecularBind";
import {AnisotropyBind} from "./Binds/AnisotropyBind";
import {IridescenceBind} from "./Binds/IridescenceBind";
import {AOBind} from "./Binds/AOBind";

export default class Inspector {
    private m_bound: boolean = false;
    protected m_pane: any;
    private m_shaderBallMeshUI: any;

    protected m_uiFolders: any;
    private m_mapSlots: any;
    protected m_titlesLabel: any;
    private m_textureUpdated: any;

    protected m_currentEditedMaterial: MeshPhysicalMaterial | null = null;
    protected m_savedMaterial: MeshPhysicalMaterial | null = null;
    private m_savedTransformData: TransformData | null = null;
    protected m_materialEditorParameters: MaterialEditorParameters;

    private m_materialUpdatedCallback: ((p_material: MeshPhysicalMaterial, p_dirty: boolean) => void) | null = null;
    private m_meshUpdatedCallback: ((p_mesh: string) => void) | null = null;
    private m_exportToJsonCallback: ((p_export: JSON) => void) | null = null;
    private m_dirtyGLTFCallback: ((p_mat1: Material, p_mat2: Material) => void) | null = null;
    private m_backgroundRoughnessCallback: ((p_value: number) => void) | null = null;
    private m_backgroundStudioCallback: ((p_value: boolean) => void) | null = null;
    private m_envRotationCallback: ((p_value: number) => void) | null = null;
    private m_toneMappingModeCallback: ((p_value: LutithreeToneMappingMode) => void) | null = null;

    protected m_albedoSlide: Albedo | undefined;
    protected m_roughnessSlide: Roughness | undefined;
    protected m_specularSlide: Specular | undefined;
    protected m_metalnessSlide: Metalness | undefined;
    protected m_emissiveSlide: Emissive | undefined;
    protected m_normalSlide: Normal | undefined;
    protected m_clearcoatSlide: ClearCoat | undefined;
    protected m_clearcoatNormalSlide: ClearcoatNormal | undefined;
    protected m_clearcoatRoughnessSlide: ClearCoatRoughness | undefined;
    protected m_sheenSlide: Sheen | undefined;
    protected m_transmissionSlide: Transmission | undefined;
    protected m_transformSlide: Transform | undefined;
    protected m_AOSlide: AO | undefined;
    protected m_iridescenceSlide: Iridescence | undefined;
    protected m_anisotropySlide: Anisotropy | undefined;
    
    public m_materialEditor: MaterialEditor;
    
    public m_maxAnisotropy: number = 1;

    private AlbedoBind = AlbedoBind;
    private RoughnessBind = RoughnessBind;
    private SpecularBind = SpecularBind;
    private MetalnessBind = MetalnessBind;
    private NormalBind = NormalBind;
    private EmissiveBind = EmissiveBind;
    private ClearcoatBind = ClearcoatBind;
    private SheenBind = SheenBind;
    private TransmissionBind = TransmissionBind;
    private TransformBind = TransformBind;
    private AnisotropyBind = AnisotropyBind;
    private IridescenceBind = IridescenceBind;
    private AOBind = AOBind;
    
    constructor(p_urlValue: string) {
        let uiContainer = document.getElementById('webplanner');
        this.m_pane = new Pane({ container: uiContainer ? uiContainer : undefined, expanded: true });
        this.m_pane.registerPlugin(TweakpaneImagePlugin);

        this.m_materialEditor = new MaterialEditor();
        this.m_materialEditorParameters = new MaterialEditorParameters(p_urlValue);

        this.m_mapSlots = [
            MapType.albedoMap,
            MapType.normalMap,
            MapType.specularMap,
            MapType.aoMap,
            MapType.roughnessMap,
            MapType.metalnessMap,
            MapType.emissiveMap,
            MapType.clearCoatMap,
            MapType.clearCoatNormalMap,
            MapType.clearCoatRoughnessMap,
            MapType.transmissionMap,
            MapType.thicknessMap,
            MapType.sheenColorMap,
        ];

        this.m_titlesLabel = {
            map: 'Albedo (RGB)',
            roughnessMap: 'Roughness (BW)',
            specularMap: 'Specular Map (RGBA)',
            aoMap: 'AO Map (BW)',
            metalnessMap: 'Metalness (BW)',
            normalMap: 'Normal Map (RGB)',
            emissiveMap: 'Emissive (RGB)',
            clearcoatMap: 'ClearCoat (BW)',
            clearcoatNormalMap: 'ClearCoat Normal Map (RGB)',
            clearcoatRoughnessMap: 'ClearCoat Roughness (BW)',
            transmissionMap: 'Transparency (BW)',
            sheenColorMap: 'Sheen (RGBA)',
        };
    }
    
    public GetEnvRotation() {
        return this.m_materialEditorParameters.EnvmapRotation;
    }

    private calc(theform: number) {
        return Math.round((theform + Number.EPSILON) * 100) / 100
    }
    
    private TestDifferenceInMat(p_mat1: Material, p_mat2: Material) {
        let mat1 = p_mat1.toJSON();
        let meta = {images:{}, textures:{}};
        let texJson = (p_mat1 as MeshPhysicalMaterial)?.sheenColorMap?.toJSON(meta);
        if(texJson)
        {
            if(mat1.images == undefined || mat1.images.length <= 0) {
                mat1.images = [];
            }
            if(!mat1.textures)
                mat1.textures = [];
            mat1.sheenColorMap = (texJson as any).uuid;
            mat1.images.push((meta.images as any)[(texJson as any).image]);
            mat1.textures.push((meta.textures as any)[(texJson as any).uuid]);
        }
        if(mat1.images)
            mat1.images.forEach((image: any)=>{
                image.url = image.url.split("?_")[0];
            });
        if(mat1.textures)
        {
        mat1.textures.forEach((texture: any)=>{
            texture.offset = this.m_savedTransformData?.TxOffset.toArray();
            texture.rotation = this.m_savedTransformData?.TxRotation;
            texture.center = this.m_savedTransformData?.TxRotationCenter.toArray();
            texture.repeat = this.m_savedTransformData?.TxTiling.toArray();
        });
        }
        if(!mat1.emissiveIntensity)
            mat1.emissiveIntensity = (p_mat1 as MeshPhysicalMaterial).emissiveIntensity;
        if(!mat1.ior)
            mat1.ior = this.calc((p_mat1 as MeshPhysicalMaterial).ior);
        let mat2 = p_mat2.toJSON();
        let meta2 = {images:{}, textures:{}};
        let texJson2 = (p_mat2 as MeshPhysicalMaterial)?.sheenColorMap?.toJSON(meta2);
        if(texJson2)
        {
            if(mat2.images == undefined || mat2.images.length <= 0) {
                mat2.images = [];
            }
            if(!mat2.textures)
                mat2.textures = [];
            mat2.sheenColorMap = (texJson2 as any).uuid;
            mat2.images.push((meta2.images as any)[(texJson2 as any).image]);
            mat2.textures.push((meta2.textures as any)[(texJson2 as any).uuid]);
        }
        if(mat2.images)
        mat2.images.forEach((image: any)=>{
            image.url = image.url.split("?_")[0];
        });
        if(!mat2.emissiveIntensity)
            mat2.emissiveIntensity = (p_mat2 as MeshPhysicalMaterial).emissiveIntensity;
        if(!mat2.ior)
            mat2.ior = this.calc((p_mat2 as MeshPhysicalMaterial).ior);
        let jsonDiff = diff(mat1, mat2);
        if(!jsonDiff)
            return false;
        if(jsonDiff.textures)
        {
            let test = false;
            jsonDiff.textures.forEach((obj: any)=>{
                if(obj[1] && obj[1].flipY 
                        || obj[1] && obj[1].encoding
                        || obj[1] && obj[1].repeat
                        || obj[1] && obj[1].offset
                        || obj[1] && obj[1].rotation
                        || obj[1] && obj[1].center
                        || obj[1] && obj[1].wrap) test=true;
            });
            if(test)
            {
                return true;
            }
        }
        if (jsonDiff.images)
        {
            let test = false;
            jsonDiff.images.forEach((obj: any) =>
            {
                if (obj[1] && obj[1].url) test = true;
            });
            if (test)
            {
                return true;
            }
        }
        if(     jsonDiff.images__deleted || jsonDiff.images__added ||
                jsonDiff.color || jsonDiff.roughness || jsonDiff.metalness || jsonDiff.normalScale ||
                jsonDiff.emissive || jsonDiff.clearcoat || jsonDiff.clearcoatNormalScale ||
                jsonDiff.clearcoatRoughness || jsonDiff.sheen || jsonDiff.sheenRoughness || 
                jsonDiff.sheenColor || jsonDiff.transmission || jsonDiff.ior || jsonDiff.thickness ||
                jsonDiff.txTiling || jsonDiff.txOffset || jsonDiff.txRotation || jsonDiff.txRotationCenter ||
                jsonDiff.txWrapMode || jsonDiff.envMapIntensity ||
                jsonDiff.specularIntensity || jsonDiff.specularColor ||
                jsonDiff.aoMapIntensity || jsonDiff.iridescence || jsonDiff.iridescenceIOR||
                jsonDiff.anisotropy || jsonDiff.anisotropyRotation){
            return true;
        }
        return false;
    }

    public ResetShowOnMesh(){
        this.m_materialEditorParameters.clearcoatMapShowOnMesh = false;
        this.m_materialEditorParameters.mapShowOnMesh = false;
        this.m_materialEditorParameters.specularMapShowOnMesh = false;
        this.m_materialEditorParameters.aoMapShowOnMesh = false;
        this.m_materialEditorParameters.clearcoatNormalMapShowOnMesh = false;
        this.m_materialEditorParameters.sheenmapShowOnMesh = false;
        this.m_materialEditorParameters.emissiveMapShowOnMesh = false;
        this.m_materialEditorParameters.metalnessMapShowOnMesh = false;
        this.m_materialEditorParameters.normalMapShowOnMesh = false;
        this.m_materialEditorParameters.transmissionMapShowOnMesh = false;
        this.m_materialEditorParameters.thicknessMapShowOnMesh = false;
        this.m_materialEditorParameters.roughnessMapShowOnMesh = false;
        this.m_materialEditorParameters.clearcoatRoughnessMapShowOnMesh = false;
    }
    
    public SetSavedMaterial(p_mat: Material) {
        this.m_savedMaterial = p_mat.clone() as MeshPhysicalMaterial;
        if(!this.m_savedMaterial)return;
        if (this.m_savedMaterial.map){
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.map.offset.clone(),
                    this.m_savedMaterial.map.repeat.clone(),
                    this.m_savedMaterial.map.wrapT,
                    this.m_savedMaterial.map.rotation,
                    this.m_savedMaterial.map.center.clone());
        }
        if (this.m_savedMaterial.specularColorMap)
        {
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.specularColorMap.offset.clone(),
                    this.m_savedMaterial.specularColorMap.repeat.clone(),
                    this.m_savedMaterial.specularColorMap.wrapT,
                    this.m_savedMaterial.specularColorMap.rotation,
                    this.m_savedMaterial.specularColorMap.center.clone())
        }
        if (this.m_savedMaterial.aoMap) {
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.aoMap.offset.clone(),
                    this.m_savedMaterial.aoMap.repeat.clone(),
                    this.m_savedMaterial.aoMap.wrapT,
                    this.m_savedMaterial.aoMap.rotation,
                    this.m_savedMaterial.aoMap.center.clone())
        }
        if (this.m_savedMaterial.roughnessMap)
        {
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.roughnessMap.offset.clone(),
                    this.m_savedMaterial.roughnessMap.repeat.clone(),
                    this.m_savedMaterial.roughnessMap.wrapT,
                    this.m_savedMaterial.roughnessMap.rotation,
                    this.m_savedMaterial.roughnessMap.center.clone())
        }
        if (this.m_savedMaterial.metalnessMap)
        {
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.metalnessMap.offset.clone(),
                    this.m_savedMaterial.metalnessMap.repeat.clone(),
                    this.m_savedMaterial.metalnessMap.wrapT,
                    this.m_savedMaterial.metalnessMap.rotation,
                    this.m_savedMaterial.metalnessMap.center.clone())
        }
        if (this.m_savedMaterial.normalMap)
        {
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.normalMap.offset.clone(),
                    this.m_savedMaterial.normalMap.repeat.clone(),
                    this.m_savedMaterial.normalMap.wrapT,
                    this.m_savedMaterial.normalMap.rotation,
                    this.m_savedMaterial.normalMap.center.clone())
        }
        if (this.m_savedMaterial.emissiveMap)
        {
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.emissiveMap.offset.clone(),
                    this.m_savedMaterial.emissiveMap.repeat.clone(),
                    this.m_savedMaterial.emissiveMap.wrapT,
                    this.m_savedMaterial.emissiveMap.rotation,
                    this.m_savedMaterial.emissiveMap.center.clone())
        }
        if (this.m_savedMaterial.clearcoatMap)
        {
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.clearcoatMap.offset.clone(),
                    this.m_savedMaterial.clearcoatMap.repeat.clone(),
                    this.m_savedMaterial.clearcoatMap.wrapT,
                    this.m_savedMaterial.clearcoatMap.rotation,
                    this.m_savedMaterial.clearcoatMap.center.clone())
        }
        if (this.m_savedMaterial.clearcoatNormalMap)
        {
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.clearcoatNormalMap.offset.clone(),
                    this.m_savedMaterial.clearcoatNormalMap.repeat.clone(),
                    this.m_savedMaterial.clearcoatNormalMap.wrapT,
                    this.m_savedMaterial.clearcoatNormalMap.rotation,
                    this.m_savedMaterial.clearcoatNormalMap.center.clone())
        }
        if (this.m_savedMaterial.clearcoatRoughnessMap)
        {
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.clearcoatRoughnessMap.offset.clone(),
                    this.m_savedMaterial.clearcoatRoughnessMap.repeat.clone(),
                    this.m_savedMaterial.clearcoatRoughnessMap.wrapT,
                    this.m_savedMaterial.clearcoatRoughnessMap.rotation,
                    this.m_savedMaterial.clearcoatRoughnessMap.center.clone())
        }
        if (this.m_savedMaterial.transmissionMap)
        {
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.transmissionMap.offset.clone(),
                    this.m_savedMaterial.transmissionMap.repeat.clone(),
                    this.m_savedMaterial.transmissionMap.wrapT,
                    this.m_savedMaterial.transmissionMap.rotation,
                    this.m_savedMaterial.transmissionMap.center.clone())
        }
        if (this.m_savedMaterial.thicknessMap) {
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.thicknessMap.offset.clone(),
                    this.m_savedMaterial.thicknessMap.repeat.clone(),
                    this.m_savedMaterial.thicknessMap.wrapT,
                    this.m_savedMaterial.thicknessMap.rotation,
                    this.m_savedMaterial.thicknessMap.center.clone())
        }
        if (this.m_savedMaterial.sheenColorMap)
        {
            this.m_savedTransformData = new TransformData(this.m_savedMaterial.sheenColorMap.offset.clone(),
                    this.m_savedMaterial.sheenColorMap.repeat.clone(),
                    this.m_savedMaterial.sheenColorMap.wrapT,
                    this.m_savedMaterial.sheenColorMap.rotation,
                    this.m_savedMaterial.sheenColorMap.center.clone())
        }
    }
    
    public SetMaterialUpdatedCallback(p_callback: (p_material: MeshPhysicalMaterial, p_dirty: boolean) => void) {
        this.m_materialUpdatedCallback = (p_material, p_dirty) =>
        {
            p_callback(p_material, p_dirty);
        };
    }

    public SetMeshUpdatedCallback(p_callback: (p_mesh: string) => void) {
        this.m_meshUpdatedCallback = p_callback;
    }

    public SetBackgroundRougnessCallback(p_callback: (p_value: number) => void) {
        this.m_backgroundRoughnessCallback= p_callback;
    } 
    
    public SetBackgroundStudioCallback(p_callback: (p_value: boolean) => void) {
        this.m_backgroundStudioCallback= p_callback;
    } 
    
    public SetEnvmapRotation(p_callback: (p_value: number) => void) {
        this.m_envRotationCallback= p_callback;
    }
    
    public SetToneMappingCallback(p_callback: (p_value: LutithreeToneMappingMode) => void) {
        this.m_toneMappingModeCallback = p_callback;
    }

    public SetExportToJsonCallback(p_callback: (p_mesh: JSON) => void) {
        this.m_exportToJsonCallback = p_callback;
    }

    public SetDirtyGLTFCallback(p_callback: () => void) {
            this.m_dirtyGLTFCallback = (p_mat1: Material, p_mat2:Material)=>{
                if (this.TestDifferenceInMat(p_mat1, p_mat2))
                    p_callback();
            };
    }

    public UpdateMatURL(p_url: string) {
        this.m_materialEditorParameters.m_matURL = p_url;
    }
    
    public SetUpdatedMaterial(p_editedMaterial: MeshPhysicalMaterial) {
        this.m_currentEditedMaterial = p_editedMaterial;
        if (p_editedMaterial.type === 'MeshPhysicalMaterial') this.m_materialEditor.SetEditedMaterial(p_editedMaterial);
    }

    public ChangeSupportOption(p_option: string) {
        if(this.m_shaderBallMeshUI)this.m_shaderBallMeshUI.value = p_option;
    }
    
    public BuildInspectorUI() {
        var self = this;
        this.m_shaderBallMeshUI = this.m_pane.addBlade({
            view: 'list',
            label: 'Preview Mesh',
            options: [
                { text: 'Box', value: 'Box' },
                { text: 'Sphere', value: 'Sphere' },
                { text: 'Dolce Vita', value: 'Dolce Vita' },
                { text: 'Palace', value: 'Palace' },
                { text: 'Plateau 200', value: 'Plateau 200' },
                { text: 'Fabric', value: 'Fabric' },
                { text: 'Fabric2', value: 'Fabric2' },
                { text: 'Furniture', value: 'Furniture' },
                { text: 'Custom', value: 'Custom' },
            ],
            value: 'Box',
        });

        this.m_shaderBallMeshUI.value = 'Box';

        this.m_shaderBallMeshUI.on('change', function(ev: any) {
            if (self.m_meshUpdatedCallback) self.m_meshUpdatedCallback(ev.value);
        });

        this.m_uiFolders = {};
        this.m_uiFolders['general'] = this.m_pane;

        this.m_uiFolders['general']['cullMode'] = this.m_pane.addInput(this.m_materialEditorParameters, 'side', {
            label: 'Cull mode',
            options: { Front: THREE.BackSide, Back: THREE.FrontSide, None: THREE.DoubleSide },
        });

        this.m_uiFolders['general']['backgroundStudio'] = this.m_pane.addInput(this.m_materialEditorParameters, 'backgroundStudio', {
            label: 'Background Studio'
        });

        this.m_uiFolders['general']['backgroundRoughness'] = this.m_pane.addInput(this.m_materialEditorParameters, 'backgroundRoughness', {
            label: 'Background Blur',
            min: 0.0,
            max: 1.0,
            step: 0.01,
        });

        this.m_uiFolders['general']['EnvmapRotation'] = this.m_pane.addInput(this.m_materialEditorParameters, 'EnvmapRotation', {
            label: 'Env Rotation',
            min: 0.0,
            max: 2* Math.PI,
            step: 0.01,
        });

        this.m_uiFolders['general']['URL'] = this.m_pane.addMonitor(this.m_materialEditorParameters, 'm_matURL',{
            label: 'URL',
        });

        this.m_uiFolders['general']['copyURL'] = this.m_pane.addButton({
            title: 'Copy to clipboard',   // optional
        });

        this.m_pane.addSeparator();

        const tab = this.m_pane.addTab({
            pages: [{ title: 'PBR' }, { title: 'Other' }],
        });
        
        this.m_albedoSlide = new Albedo(this.m_materialEditorParameters, this.m_uiFolders['map'], tab);
        this.m_specularSlide = new Specular(this.m_materialEditorParameters, this.m_uiFolders['specularMap'], tab);
        this.m_AOSlide = new AO(this.m_materialEditorParameters, this.m_uiFolders['AOMap'], tab);
        this.m_roughnessSlide = new Roughness(this.m_materialEditorParameters, this.m_uiFolders['roughnessMap'], tab);
        this.m_metalnessSlide = new Metalness(this.m_materialEditorParameters, this.m_uiFolders['metalnessMap'], tab);
        this.m_emissiveSlide = new Emissive(this.m_materialEditorParameters, this.m_uiFolders['emissiveMap'], tab);
        this.m_normalSlide = new Normal(this.m_materialEditorParameters, this.m_uiFolders['normalMap'], tab);
        this.m_clearcoatSlide = new ClearCoat(this.m_materialEditorParameters, this.m_uiFolders['clearcoatMap'], tab);
        this.m_clearcoatNormalSlide = new ClearcoatNormal(this.m_materialEditorParameters, this.m_uiFolders['clearcoatNormalMap'], tab);
        this.m_clearcoatRoughnessSlide = new ClearCoatRoughness(this.m_materialEditorParameters, this.m_uiFolders['clearcoatRoughnessMap'], tab);
        this.m_sheenSlide = new Sheen(this.m_materialEditorParameters, this.m_uiFolders['sheenMap'], tab);
        this.m_transmissionSlide = new Transmission(this.m_materialEditorParameters, this.m_uiFolders['transmissionMap'], tab);
        this.m_iridescenceSlide = new Iridescence(this.m_materialEditorParameters, this.m_uiFolders['iridescence'], tab);
        this.m_anisotropySlide = new Anisotropy(this.m_materialEditorParameters, this.m_uiFolders['anisotropy'], tab);
        this.m_transformSlide = new Transform(this.m_materialEditorParameters, this.m_uiFolders['transform'], tab);
        
        this.m_uiFolders['envMapIntensity'] = tab.pages[0].addFolder({title: 'Environment Intensity', expanded: false});
        this.m_uiFolders['envMapIntensity']['intensity'] = this.m_uiFolders['envMapIntensity'].addInput(this.m_materialEditorParameters, 'envMapIntensity', {
            label: 'Intensity',
            min: 0.0,
            max: 5.0,
            step: 0.01,
        });
    }

    public GetTransformData() {
        let radRotation = MathUtils.degToRad(this.m_materialEditorParameters.txRotationDeg);
        return new TransformData(
            this.m_materialEditorParameters.txOffset,
            this.m_materialEditorParameters.txTiling,
            this.m_materialEditorParameters.txWrapMode, radRotation,
            this.m_materialEditorParameters.txRotationCenter,
        );
    }

    public UpdateMaterialFromUI(p_mapType: MapType, p_image: HTMLImageElement | null, p_yFlip: boolean, p_sRGB: boolean) {
        if (!this.m_currentEditedMaterial) return;
        let texSlot = this.GetTextureFromSlot(p_mapType);
        if (!texSlot) return;
        this.ResetShowOnMesh();
        let Mat = this.m_materialEditor.UpdateTextureOnMaterial(
                texSlot.slotNameSpec? texSlot.slotNameSpec : [texSlot.slotName], 
                this.GetTransformData(), 
                p_yFlip, 
                p_sRGB,
                p_image,
                this.m_maxAnisotropy);
        if (this.m_materialUpdatedCallback && Mat) this.m_materialUpdatedCallback(Mat, false);
    }

    public BindUI(val: boolean) {
        const self = this;
        let inspectorParameters = this.m_materialEditorParameters;
        if (val && !this.m_bound) {
            this.m_uiFolders['general']['cullMode'].on('change', function() {
                if (!self.m_currentEditedMaterial) return;
                self.m_currentEditedMaterial.side = inspectorParameters.side;
                if (self.m_materialUpdatedCallback) self.m_materialUpdatedCallback(self.m_currentEditedMaterial.clone(), true);
                self.m_currentEditedMaterial.needsUpdate = true;
            });

            this.m_uiFolders['general']['copyURL'].on('click', () => {
                navigator.clipboard.writeText(self.m_materialEditorParameters.m_matURL).then(() => {
                    /* Resolved - text copied to clipboard successfully */
                }, () => {
                    /* Rejected - text failed to copy to the clipboard */
                });
            });
            
            this.m_uiFolders['general']['backgroundStudio'].on('change', function(ev: any) {
                if(self.m_backgroundStudioCallback)  
                    self.m_backgroundStudioCallback(ev.value);
            });
            
            this.m_uiFolders['general']['backgroundRoughness'].on('change', function(ev: any) {
                if(self.m_backgroundRoughnessCallback)  
                    self.m_backgroundRoughnessCallback(ev.value);
            });
            
            this.m_uiFolders['general']['EnvmapRotation'].on('change', function(ev: any) {
                if(self.m_envRotationCallback)  
                    self.m_envRotationCallback(ev.value);
            });

            this.AlbedoBind();
            this.RoughnessBind();
            this.MetalnessBind();
            this.NormalBind();
            this.EmissiveBind();
            this.ClearcoatBind();
            this.SheenBind();
            this.TransmissionBind();
            this.TransformBind();
            this.SpecularBind();
            this.AnisotropyBind();
            this.IridescenceBind();
            this.AOBind();
            
            this.m_uiFolders['envMapIntensity']['intensity'].on('change', function (ev: any) {
                if (self.m_materialUpdatedCallback && self.m_currentEditedMaterial) {
                    self.m_materialEditor.UpdateMatEnvIntensity(ev.value);
                    self.m_materialUpdatedCallback(self.m_currentEditedMaterial.clone(), true);
                }
                self.ToggleSlotDirty();
            });
            this.m_bound = true;
        }
    }

    private ToggleSlotDirty() {
        if (this.m_savedMaterial && this.m_dirtyGLTFCallback && this.m_currentEditedMaterial )
        {
                this.m_dirtyGLTFCallback(this.m_savedMaterial, this.m_materialEditor.m_editMat);
        }
    }
    
    public UpdateUIFromMaterial(p_editedMaterial: MeshPhysicalMaterial, p_url: string | undefined, p_dirty: boolean) {
        let transformUpdated = false;
        this.m_materialEditorParameters.m_matName = p_editedMaterial.name;
        if (this.m_materialEditorParameters.matType == "MeshPhysicalMaterial" && p_editedMaterial.type == "MeshPhysicalMaterial" ) {
           this.ResetShowOnMesh();

            let color
            if(p_editedMaterial.userData.colorBuffer) color = p_editedMaterial.userData.colorBuffer;
            else {
                color = {r:0,g:0,b:0};
                color.r = p_editedMaterial.color.r * 255;
                color.g = p_editedMaterial.color.g * 255;
                color.b = p_editedMaterial.color.b * 255;
            }
            this.m_materialEditorParameters.color = {
                r: color.r,
                g: color.g,
                b: color.b,
            };
            
            this.m_materialEditorParameters.specularIntensity = p_editedMaterial.specularIntensity;
            this.m_materialEditorParameters.specularReflectivity = p_editedMaterial.reflectivity;
            let specColor
            if (p_editedMaterial.userData.specularColorBuffer) specColor = p_editedMaterial.userData.specularColorBuffer;
            else {
                specColor = {r: 0, g: 0, b: 0};
                specColor.r = p_editedMaterial.specularColor.r * 255;
                specColor.g = p_editedMaterial.specularColor.g * 255;
                specColor.b = p_editedMaterial.specularColor.b * 255;
            }
            this.m_materialEditorParameters.specularColor = {
                r: specColor.r,
                g: specColor.g,
                b: specColor.b,
            };
            
            this.m_materialEditorParameters.aoMapIntensity = p_editedMaterial.aoMapIntensity;
            
            this.m_materialEditorParameters.iridescence = p_editedMaterial.iridescence;
            this.m_materialEditorParameters.iridescenceIOR = p_editedMaterial.iridescenceIOR;
            
            this.m_materialEditorParameters.anisotropy = p_editedMaterial.anisotropy!;
            this.m_materialEditorParameters.anisotropyRotation = MathUtils.radToDeg(p_editedMaterial.anisotropyRotation!);
            
            this.m_materialEditorParameters.roughness = p_editedMaterial.roughness;
            this.m_materialEditorParameters.metalness = p_editedMaterial.metalness;
            this.m_materialEditorParameters.normalScale = p_editedMaterial.normalScale;

            let emissiveColor
            if(p_editedMaterial.userData.emissiveBuffer) emissiveColor = p_editedMaterial.userData.emissiveBuffer;
            else {
                emissiveColor = {r:0,g:0,b:0};
                emissiveColor.r = p_editedMaterial.emissive.r * 255;
                emissiveColor.g = p_editedMaterial.emissive.g * 255;
                emissiveColor.b = p_editedMaterial.emissive.b * 255;
            }
            this.m_materialEditorParameters.emissive = {
                r: emissiveColor.r,
                g: emissiveColor.g,
                b: emissiveColor.b,
            };
            this.m_materialEditorParameters.sheen = p_editedMaterial.sheen;
            this.m_materialEditorParameters.sheenRoughness = p_editedMaterial.sheenRoughness;

            let sheenColor
            if(p_editedMaterial.userData.sheenBuffer) sheenColor = p_editedMaterial.userData.sheenBuffer;
            else {
                sheenColor = {r: 0, g: 0, b: 0};
                sheenColor.r = p_editedMaterial.sheenColor.r * 255;
                sheenColor.g = p_editedMaterial.sheenColor.g * 255;
                sheenColor.b = p_editedMaterial.sheenColor.b * 255;
            }
            this.m_materialEditorParameters.sheenColor = {
                r: sheenColor.r,
                g: sheenColor.g,
                b: sheenColor.b,
            };
            this.m_materialEditorParameters.ior = p_editedMaterial.ior;
            this.m_materialEditorParameters.thickness = p_editedMaterial.thickness;
            this.m_materialEditorParameters.reflectivity = p_editedMaterial.reflectivity;
            this.m_materialEditorParameters.transmission = p_editedMaterial.transmission;
            this.m_materialEditorParameters.clearcoat = p_editedMaterial.clearcoat;
            this.m_materialEditorParameters.clearcoatNormalScale = p_editedMaterial.clearcoatNormalScale;
            this.m_materialEditorParameters.clearcoatRoughness = p_editedMaterial.clearcoatRoughness;
        }

        if (p_editedMaterial.map){
            this.m_materialEditorParameters.mapFlipY = p_editedMaterial.map?.flipY;
            this.UpdateTransform(p_editedMaterial.map);
            transformUpdated = true;
        }else {
            this.m_materialEditorParameters.mapFlipY = false;
        }
        
        if (p_editedMaterial.roughnessMap) {
            this.m_materialEditorParameters.roughnessMapFlipY = p_editedMaterial.roughnessMap?.flipY;
            if(!transformUpdated){
                this.UpdateTransform(p_editedMaterial.roughnessMap);
                transformUpdated = true;
            }
        } else {
            this.m_materialEditorParameters.roughnessMapFlipY = false;
        }

        if (p_editedMaterial.specularColorMap) {
            this.m_materialEditorParameters.specularMapFlipY = p_editedMaterial.specularColorMap?.flipY;
            this.UpdateTransform(p_editedMaterial.specularColorMap);
            transformUpdated = true;
        } else {
            this.m_materialEditorParameters.specularMapFlipY = false;
        } 
        
        if (p_editedMaterial.aoMap) {
            this.m_materialEditorParameters.aoMapFlipY = p_editedMaterial.aoMap?.flipY;
            this.UpdateTransform(p_editedMaterial.aoMap);
            transformUpdated = true;
        } else {
            this.m_materialEditorParameters.aoMapFlipY = false;
        }
        
        if (p_editedMaterial.metalnessMap) {
            this.m_materialEditorParameters.metalnessMapFlipY = p_editedMaterial.metalnessMap?.flipY;
            if (!transformUpdated) {
                this.UpdateTransform(p_editedMaterial.metalnessMap);
                transformUpdated = true;
            }
        } else {
            this.m_materialEditorParameters.metalnessMapFlipY = false;
        }

        if (p_editedMaterial.normalMap) {
            this.m_materialEditorParameters.normalMapFlipY = p_editedMaterial.normalMap?.flipY;
            if (!transformUpdated) {
                this.UpdateTransform(p_editedMaterial.normalMap);
                transformUpdated = true;
            }
        } else {
            this.m_materialEditorParameters.normalMapFlipY = false;
        }

        if (p_editedMaterial.emissiveMap) {
            this.m_materialEditorParameters.emissiveMapFlipY = p_editedMaterial.emissiveMap?.flipY;
            if (!transformUpdated) {
                this.UpdateTransform(p_editedMaterial.emissiveMap);
                transformUpdated = true;
            }
        } else {
            this.m_materialEditorParameters.emissiveMapFlipY = false;
        }

        if (p_editedMaterial.clearcoatMap) {
            this.m_materialEditorParameters.clearcoatMapFlipY = p_editedMaterial.clearcoatMap?.flipY;
            if (!transformUpdated) {
                this.UpdateTransform(p_editedMaterial.clearcoatMap);
                transformUpdated = true;
            }
        } else {
            this.m_materialEditorParameters.clearcoatMapFlipY = false;
        }

        if (p_editedMaterial.clearcoatNormalMap) {
            this.m_materialEditorParameters.clearcoatNormalMapFlipY = p_editedMaterial.clearcoatNormalMap?.flipY;
            if (!transformUpdated) {
                this.UpdateTransform(p_editedMaterial.clearcoatNormalMap);
                transformUpdated = true;
            }
        } else {
            this.m_materialEditorParameters.clearcoatNormalMapFlipY = false;
        }

        if (p_editedMaterial.clearcoatRoughnessMap) {
            this.m_materialEditorParameters.clearcoatRoughnessMapFlipY = p_editedMaterial.clearcoatRoughnessMap?.flipY;
            if (!transformUpdated) {
                this.UpdateTransform(p_editedMaterial.clearcoatRoughnessMap);
                transformUpdated = true;
            }
        } else {
            this.m_materialEditorParameters.clearcoatRoughnessMapFlipY = false;
        }

        if (p_editedMaterial.transmissionMap) {
            this.m_materialEditorParameters.transmissionMapFlipY = p_editedMaterial.transmissionMap?.flipY;
            if (!transformUpdated) {
                this.UpdateTransform(p_editedMaterial.transmissionMap);
                transformUpdated = true;
            }
        } else {
            this.m_materialEditorParameters.transmissionMapFlipY = false;
        }

        if (p_editedMaterial.thicknessMap) {
            this.m_materialEditorParameters.thicknessMapFlipY = p_editedMaterial.thicknessMap?.flipY;
            if (!transformUpdated) {
                this.UpdateTransform(p_editedMaterial.thicknessMap);
                transformUpdated = true;
            }
        } else {
            this.m_materialEditorParameters.thicknessMapFlipY = false;
        }

        if (p_editedMaterial.sheenColorMap) {
            this.m_materialEditorParameters.sheenColorMapFlipY = p_editedMaterial.sheenColorMap?.flipY;
            if (!transformUpdated) {
                this.UpdateTransform(p_editedMaterial.sheenColorMap);
                transformUpdated = true;
            }
        } else {
            this.m_materialEditorParameters.sheenColorMapFlipY = false;
        }

        if(!transformUpdated){
            this.m_materialEditorParameters.txTiling = new Vector2(1, 1);
            this.m_materialEditorParameters.txOffset = new Vector2(0, 0);
            this.m_materialEditorParameters.txRotation = 0;
            this.m_materialEditorParameters.txRotationDeg = 0;
            this.m_materialEditorParameters.txRotationCenter = new Vector2(0.5, 0.5);
            this.m_materialEditorParameters.txWrapMode = THREE.RepeatWrapping;
        }
        
        this.m_materialEditorParameters.envMapIntensity = p_editedMaterial.envMapIntensity;
        this.m_materialEditorParameters.emissiveIntensity = p_editedMaterial.emissiveIntensity;
        
        if(p_url)
            SetCurrentMatName(p_url);
        
        Promise.all(this.m_mapSlots.map((ms: MapType) => {
            if(p_editedMaterial.type == "MeshPhysicalMaterial")
                this.UpdateInspectorSlot(ms);
            })).then(() => {
            if (p_dirty) this.m_pane.refresh();
            //ResetAllSlots();
            this.BindUI(true);
        });
    }

    private UpdateTransform(p_texture: Texture) {
        this.m_materialEditorParameters.txTiling = p_texture.repeat;
        this.m_materialEditorParameters.txRotation = p_texture.rotation;
        this.m_materialEditorParameters.txRotationDeg = MathUtils.radToDeg(p_texture.rotation);
        this.m_materialEditorParameters.txOffset = p_texture.offset;
        this.m_materialEditorParameters.txWrapMode = p_texture.wrapS;
        this.m_materialEditorParameters.txRotationCenter = p_texture.center;
    }

    public GetTextureFromSlot(slot: MapType): { slotName: string; texture: Texture | null; slotNameSpec?:string[] } | null {
        if (!this.m_currentEditedMaterial) return null;
        switch (slot) {
            case MapType.albedoMap:
                return {
                    slotName: 'map',
                    texture: this.m_currentEditedMaterial.map,
                };
                break;
            case MapType.specularMap:
                return {
                    slotName: 'specularMap',
                    texture: this.m_currentEditedMaterial['specularColorMap'],
                    slotNameSpec: ['specularColorMap', 'specularIntensityMap']
                };
                break;
            case MapType.aoMap:
                return {
                    slotName: 'aoMap',
                    texture: this.m_currentEditedMaterial['aoMap'],
                };
                break;
            case MapType.roughnessMap:
                return {
                    slotName: 'roughnessMap',
                    texture: this.m_currentEditedMaterial['roughnessMap'],
                };
                break;
            case MapType.metalnessMap:
                return {
                    slotName: 'metalnessMap',
                    texture: this.m_currentEditedMaterial['metalnessMap'],
                };
                break;
            case MapType.normalMap:
                return {
                    slotName: 'normalMap',
                    texture: this.m_currentEditedMaterial['normalMap'],
                };
                break;
            case MapType.emissiveMap:
                return {
                    slotName: 'emissiveMap',
                    texture: this.m_currentEditedMaterial['emissiveMap'],
                };
                break;
            case MapType.clearCoatMap:
                return {
                    slotName: 'clearcoatMap',
                    texture: this.m_currentEditedMaterial['clearcoatMap'],
                };
                break;
            case MapType.clearCoatNormalMap:
                return {
                    slotName: 'clearcoatNormalMap',
                    texture: this.m_currentEditedMaterial['clearcoatNormalMap'],
                };
                break;
            case MapType.clearCoatRoughnessMap:
                return {
                    slotName: 'clearcoatRoughnessMap',
                    texture: this.m_currentEditedMaterial['clearcoatRoughnessMap'],
                };
                break;
            case MapType.sheenColorMap:
                return {
                    slotName: 'sheenColorMap',
                    texture: this.m_currentEditedMaterial['sheenColorMap'],
                    slotNameSpec: ['sheenColorMap', 'sheenRoughnessMap']
                };
                break;
            case MapType.transmissionMap:
                return {
                    slotName: 'transmissionMap',
                    texture: this.m_currentEditedMaterial['transmissionMap'],
                };
                break;
            case MapType.thicknessMap:
                return {
                    slotName: 'thicknessMap',
                    texture: this.m_currentEditedMaterial['thicknessMap'],
                };
                break;
        }
        return null;
    }

    public UpdateInspectorSlotTitle(slot: MapType, p_uiFolder: any) {
        let slotObject = this.GetTextureFromSlot(slot);
        if(slotObject)
        {
            var splitSrc = (slotObject.texture!.source.data.src as string).split('/');
            var splitTail = splitSrc[splitSrc.length-1].split('?_');
            if (this.m_currentEditedMaterial && slotObject.texture)
                p_uiFolder.title = this.m_titlesLabel[slotObject.slotName] + ' [' + slotObject.texture.image.width + ',' + slotObject.texture.image.height + '] ' + splitTail[0];
            else
                p_uiFolder.title = "[no texture]";
        }
    }

    public GetImage(canvas: any, width: any, height: any, mime = 'image/png', quality = 1.0) {
        return new Promise((resolve, reject) => {
            const tmpCanvas = document.createElement('canvas');
            tmpCanvas.width = width;
            tmpCanvas.height = height;
            const ctx = tmpCanvas.getContext('2d');
            ctx?.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, width, height);
            tmpCanvas.toBlob((blob) => resolve(blob), mime, quality);
        });
    }

    public UpdateInspectorSlotFromUrl(p_slot: MapType, p_url: string) {
        let slotObject = this.GetTextureFromSlot(p_slot);
        let newImg = new Image();
        newImg.src = p_url;
        if (slotObject) this.UpdateMaterialFromUI(p_slot, newImg, (this.m_materialEditorParameters as any)[slotObject.slotName + 'FlipY'], (this.m_materialEditorParameters as any)[slotObject.slotName + 'EncodingDefault']);
        if (this.m_currentEditedMaterial) this.UpdateUIFromMaterial(this.m_currentEditedMaterial, undefined, true);
    }

    public UpdateInspectorSlot(slot: MapType) {
        var self = this;
        let slotObject = this.GetTextureFromSlot(slot);
        return new Promise<void>((resolve) => {
            if (this.m_currentEditedMaterial /*&& this.m_currentEditedMaterial.type === 'MeshPhysicalMaterial' */&& slotObject) {
                if (slotObject.texture?.image) {
                    if (slotObject.texture.image instanceof HTMLImageElement) {
                        (this.m_materialEditorParameters as any)[slotObject.slotName] = slotObject.texture.image.src;
                        resolve();
                    } else {
                        var canvasBlob = this.GetImage(slotObject.texture.image, slotObject.texture.image.width, slotObject.texture.image.height);
                        let inspecParam = this.m_materialEditorParameters;
                        canvasBlob.then(
                                function (blob: any) {
                                    if (!slotObject) return;
                                    var url = URL.createObjectURL(blob);
                                    if ((inspecParam as any)[slotObject.slotName] && (inspecParam as any)[slotObject.slotName].src)
                                    {
                                        (inspecParam as any)[slotObject.slotName].src = url;
                                        resolve();
                                    }
                                else {
                                        (inspecParam as any)[slotObject.slotName] = url;
                                        resolve();
                                    }
                                    if ((inspecParam as any)[slotObject.slotName].onload)
                                        (inspecParam as any)[slotObject.slotName].onload = function () {
                                            resolve();
                                        };
                                },
                                function (err) {
                                    console.log(err);
                                },
                        );
                    }
                } else {
                    (this.m_materialEditorParameters as any)[slotObject.slotName] = null;
                    resolve();
                }
            }
        });
    }
}
