import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {TextureType} from "../ParamTypes/TextureType";
import {BooleanType} from "../ParamTypes/BooleanType";
import {ButtonType} from "../ParamTypes/ButtonType";
import {SliderData, SliderType} from "../ParamTypes/SliderType";
import {InspectorType} from "../ParamTypes/InspectorType";

export class Roughness {
    public m_roughness: InspectorType;
    public m_roughnessMapShow: InspectorType;
    public m_roughnessMap: InspectorType;
    public m_roughnessFlipY: InspectorType;
    public m_roughnessClear: InspectorType;
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        //Albedo
        this.m_uiFolder = m_uiFolders = m_tab.pages[0].addFolder({title: 'Roughness (BW) [no texture]', expanded: false});
        this.m_roughness = new SliderType(new SliderData(0.0,1.0,0.01), m_matParams, "roughness", m_uiFolders);
        this.m_roughnessMapShow = new BooleanType("Show map on mesh", m_matParams, "roughnessMapShowOnMesh", m_uiFolders);
        this.m_roughnessMap = new TextureType(MapType.roughnessMap, m_matParams, "roughnessMap", m_uiFolders);
        this.m_roughnessFlipY = new BooleanType("Flip Y", m_matParams, "roughnessMapFlipY", m_uiFolders);
        this.m_roughnessClear = new ButtonType("Clear map", m_matParams, "", m_uiFolders);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_roughness.InitType();
        this.m_roughnessMapShow.InitType();
        this.m_roughnessMap.InitType();
        this.m_roughnessFlipY.InitType();
        this.m_roughnessClear.InitType();
    }
}