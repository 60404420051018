import './create-rule-composition.scss';
import { useEffect, useState } from 'react';
import { ConstructionRule, ConstructionRuleType, Tag } from '../../../../../domain/domain';
import AssociateTagsModal from '../associateTagModal/associate-tags-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
    addConstructionRules,
    ConstructionRuleState,
    findConstructionRulesi,
    resetAddConstructionRules,
} from '../../../../../services/reducers/product/rules/construction-rules.reducers';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../domain/Params/params-interface';
import { RootState } from '../../../../../services/root-reducer';


type CreateCompoRuleProps = {
    ruleId: number
    setRuleId: (ruleId: number) => void
    currentRule: ConstructionRule
};
const CreateRuleComposition = (props: CreateCompoRuleProps) => {
    const dispatch = useDispatch();
    const { collectionReference } = useParams<Params>();

    const [ruleName, setRuleName] = useState<string>('');
    const [ruleType, setRuleType] = useState<ConstructionRuleType>('MANDATORY');
    const [tagsList, setTagsList] = useState<Tag[]>([]);
    const [associateModal, setAssociateModal] = useState<boolean>(false);
    const [err, setErr] = useState<boolean>(false);

    const addRuleRes = useSelector<RootState, ConstructionRuleState>(state => state.addConstructionRules);

    useEffect(() => {
        if (props.currentRule.id !== 0) {
            if (props.ruleId !== -1) {
                setRuleName(props.currentRule.name);
                setRuleType(props.currentRule.type);
                setTagsList(props.currentRule.tagList);
            }
        }
    }, [props.currentRule]);
    useEffect(() => {
        if (addRuleRes.payload.content.id !== 0 && addRuleRes.payload.errors.length === 0) {
            collectionReference && dispatch(findConstructionRulesi(collectionReference));
            dispatch(resetAddConstructionRules());
        }
    }, [addRuleRes]);
    const typeIsChecked = (c: ConstructionRuleType) => c === ruleType;
    const onSubmit = () => {
        if (props.ruleId === -1) {
            if (ruleName.length === 0) {
                setErr(true);
            } else {
                collectionReference && dispatch(addConstructionRules({
                    name: ruleName, tagList: tagsList, type: ruleType, collectionReference: collectionReference,
                }));
            }
        } else if (props.ruleId > 0) {
            collectionReference && dispatch(addConstructionRules({
                id: props.currentRule.id,
                version: props.currentRule.version,
                name: ruleName,
                tagList: tagsList,
                type: ruleType,
                collectionReference: collectionReference,
            }));
        }
    };
    return (
        <div className='create-compo-main'>
            { associateModal && <AssociateTagsModal
                setOpenModal={ setAssociateModal }
                tagList={ tagsList }
                setTagList={ setTagsList } /> }
            <div className='create-compo-body'>
                <div className='create-rule-title'>{
                    props.ruleId === -1
                        ? 'CRÉER UNE RÈGLE'
                        : 'MODIFIER UNE RÈGLE' }
                </div>
                <div className='create-rule-text'>
                    <label>Nom de la règle</label>
                    <div className='create-rules-label'>
                        <input onChange={ (e) => {
                            setErr(false);
                            setRuleName(e.target.value);
                        } }
                               value={ ruleName }
                               placeholder='Nom de la règle' type='text' />
                        <span>{ err && <span> Vous devez renseigner un nom </span> } </span>
                    </div>
                </div>
                <div className='create-rule-radio'>
                    <input onChange={ () => setRuleType('MANDATORY') } checked={ typeIsChecked('MANDATORY') }
                           type='radio' />
                    <label>Obligation</label>
                    <input onChange={ () => setRuleType('IMPOSSIBLE') } checked={ typeIsChecked('IMPOSSIBLE') }
                           type='radio' />
                    <label>Interdiction</label>
                </div>
                <div onClick={ () => setAssociateModal(true) } className='create-rule-tags'>
                    { tagsList.map((t: Tag, i: number) => {
                        return (
                            <div className='tag' key={ i }>{ t.name.toUpperCase() }</div>
                        );
                    }) }
                </div>
            </div>
            <div className='btn-box'>
                <button onClick={ () => {
                    props.setRuleId(0);
                } } className='btn-canceled '>Fermer
                </button>
                <button
                    onClick={ onSubmit }
                    className='btn-submit'>{ props.ruleId === -1 ? 'Créer' : 'Modifier' }</button>
            </div>
        </div>
    );
};
export default CreateRuleComposition;