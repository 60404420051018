import { Section, SubSection } from '../../../domain/domain';
import apiClient from '../../api-client';

import {
    CREATE_SUB_SECTION,
    DELETE_SUB_SECTION,
    GET_SUB_SECTIONS_BY_SECTION,
    GET_SUB_SECTION_SECTION_BY_ID,
    LINK_PRESET_TO_SUB_SECTION,
    UNLINK_PRESET_TO_SUB_SECTION,
    UPDATE_SUB_SECTION, FIND_SUB_SECTIONS_BY_PRODUCT_ID,
} from '../../endpoints/catalog/sub-section';


export type CreateAndUpdateSubSectionForm = {
    id?: number,
    version?: number,
    name?: string,
    reference?: string,
    section?: Section
};

const subSectionService = {
    getSubSectionsBySection: async (sectionId: number) => apiClient(GET_SUB_SECTIONS_BY_SECTION(sectionId), undefined, undefined, 'GET'),
    getSubSectionById: async (subSectionId: number) => apiClient(GET_SUB_SECTION_SECTION_BY_ID(subSectionId), undefined, undefined, 'GET'),
    findSubsectionsByProductId: async (productId: number) => apiClient(FIND_SUB_SECTIONS_BY_PRODUCT_ID(productId), undefined, undefined, 'GET'),
    createSubSection: async (sectionId: number, createAndUpdateSubSectionForm: CreateAndUpdateSubSectionForm) => apiClient(CREATE_SUB_SECTION(sectionId), createAndUpdateSubSectionForm, undefined, 'POST'),
    updateSubSection: async (subSectionId: number, subSection: SubSection) => apiClient(UPDATE_SUB_SECTION(subSectionId), subSection, undefined, 'PUT'),
    deleteSubSection: async (subSectionId: number) => apiClient(DELETE_SUB_SECTION(subSectionId), undefined, undefined, 'DELETE'),
    linkPresetToSubSection: async (catalogId: number, subSectionId: number, productId: number) => apiClient(LINK_PRESET_TO_SUB_SECTION(catalogId, subSectionId, productId), undefined, undefined, 'POST'),
    unLinkPresetToSubSection: async (catalogId: number, subSectionId: number, productId: number) => apiClient(UNLINK_PRESET_TO_SUB_SECTION(catalogId, subSectionId, productId), undefined, undefined, 'DELETE'),
};
export default subSectionService;
