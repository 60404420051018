import React, { useState } from 'react';
import Header from '../../components/header/header';
import SupplierHeader from '../../components/header/supplierHeader/supplier-header';

import Toaster from '../toaster/toaster';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import { setToasterGone } from '../../services/reducers/toaster-reducer/toaster.slice';
import BackArrow from '../back-arrow/back-arrow';
import { useLocation, useParams } from 'react-router-dom';

import Preview from '../preview/preview';
import { News, NewsCategory } from '../../domain/domain';

import './layout.scss';
import { Params } from '../../domain/Params/params-interface';

type LayoutProps = {
    children: React.ReactNode;
    title: string;
    labelBack?: string;
    option?: React.ReactElement;

    setPreview?: (preview: boolean) => void;
    preview?: boolean | undefined;
    data?: News | undefined;
    getCategoryName?: (id: number) => NewsCategory | undefined;
    imageFile?: string[];
    color?: string;
    backGroundColor?: string;
    imgUrl?: string;
    setImgUrl?: (imgUrl: string) => void;
    setOpen?: (open: boolean) => void;
    open?: boolean;
    validate?: boolean;
    info?: string;
    pathUrl?: string;
    finishName?: string;
    setFinishName?: (finishName: string) => void;
    collectionName?: string;
    setCollectionName?: (finishName: string) => void;
    catalogName?: string;
    visible?: boolean;
};
type ToasterSelector = {
    isVisible: boolean;
    message: string;
    title: string;
    errors: boolean;
};

const Layout = (props: LayoutProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentLocation = location.pathname;
    const { supplierReference } = useParams<Params>();

    const [openAvatar, setopenAvatar] = useState(false);

    const toaster = useSelector<RootState, ToasterSelector>((state) => state.toaster);
    const { isVisible } = useSelector<RootState, ToasterSelector>((state) => state.toaster);

    return (
        <div className="layout-global">
            {location.pathname.includes('supplier') && <SupplierHeader setFinishName={props.setFinishName} finishName={props.finishName} setOpen={setopenAvatar} open={openAvatar} />}
            {!location.pathname.includes('supplier') && <Header open={openAvatar} setOpen={setopenAvatar} />}

            <div onClick={() => setopenAvatar(false)} className="mainLayout">
                {props.preview && (
                    <Preview
                        setOpen={props.setOpen}
                        open={props.open}
                        setImgUrl={props.setImgUrl}
                        imageFile={props.imageFile}
                        getCategoryName={props.getCategoryName}
                        setPreview={props.setPreview!}
                        data={props.data!}
                    />
                )}
                <div className="layout-responsive">
                    <div className="title-container">
                        {props.preview || props.visible ? (
                            <div className="arrow-title">
                                <div className="title-block">{props.title}</div>
                            </div>
                        ) : (
                            <div className="arrow-title">
                                <BackArrow info={props.info} currentLocation={currentLocation} labelBack={props.labelBack} />
                                <div className="title-block">
                                    {props.title}
                                    {props.option}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="bodyLayout">{props.preview ? <></> : <div>{props.children}</div>}</div>
                    <Toaster
                        visibility={isVisible}
                        validate={props.validate}
                        onClose={() => {
                            dispatch(setToasterGone());
                        }}
                        title={toaster.title}
                        message={toaster.message}
                    />
                </div>
            </div>
        </div>
    );
};
export default Layout;
