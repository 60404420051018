import { NewsCategory } from '../../../domain/domain';
import redDelete from '../../../asset/poubelle.rouge.svg';
import Tag from '../../tag/tag';

import './news-categories-row.scss';


type NewsCategoriesRowProps = {
    item: NewsCategory;
    onDeleteNewsCategory: () => void;
    setCategoryToBeDeleted: (id: number) => void;
};

const NewsCategoriesRow = ({ item, onDeleteNewsCategory, setCategoryToBeDeleted }: NewsCategoriesRowProps) => {
    const DEFAULT_CATEGORY = 'Général';
    const isDisabled = (nameCateg: string) => {
        return nameCateg === DEFAULT_CATEGORY;
    };
    return (
        <tr className='categories-row-main'>
            <td>
                <Tag newsCategoryId={ item.id } width={ 180 } title={ item.name } />
            </td>
            <td className='td-block'>
                <div className='logo-container'>
                    { !isDisabled(item.name) &&
                        <img
                            className='te'
                            onClick={ () => {
                                setCategoryToBeDeleted(item.id);
                                onDeleteNewsCategory();
                            } }
                            alt='logo supprimer'
                            src={ redDelete }
                        />
                    }
                </div>
            </td>
        </tr>
    );
};

export default NewsCategoriesRow;
