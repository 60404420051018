import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {TextureType} from "../ParamTypes/TextureType";
import {BooleanType} from "../ParamTypes/BooleanType";
import {ButtonType} from "../ParamTypes/ButtonType";
import {SliderData, SliderType} from "../ParamTypes/SliderType";
import {InspectorType} from "../ParamTypes/InspectorType";
import {ColorType} from "../ParamTypes/ColorType";
import {SetTypeOfCell} from "../../../Libraries/TweakPaneUtilities";

export class Transmission {
    public m_transmission: InspectorType;
    public m_transmissionIor: InspectorType;
    public m_transmissionThickness: InspectorType;
    public m_transmissionMapShow: InspectorType;
    public m_transmissionMap: InspectorType;
    public m_transmissionFlipY: InspectorType;
    public m_transmissionClear: InspectorType;

    public m_thicknessMapShow: InspectorType;
    public m_thicknessMap: InspectorType;
    public m_thicknessFlipY: InspectorType;
    public m_thicknessClear: InspectorType;
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        //Albedo
        this.m_uiFolder = m_uiFolders = m_tab.pages[1].addFolder({title: 'Transparency (BW) [no texture]', expanded: false});
        this.m_transmission = new SliderType(new SliderData(0.0,1.0,0.01), m_matParams, "transmission", m_uiFolders);
        this.m_transmissionIor = new SliderType(new SliderData(1.0, 2.33,0.01), m_matParams, "ior", m_uiFolders);
        this.m_transmissionThickness = new SliderType(new SliderData(0.01, 2.0, 0.01), m_matParams, "thickness", m_uiFolders);
        this.m_transmissionMapShow = new BooleanType("Show map on mesh", m_matParams, "transmissionMapShowOnMesh", m_uiFolders);
        this.m_transmissionMap = new TextureType(MapType.transmissionMap, m_matParams, "transmissionMap", m_uiFolders);
        this.m_transmissionFlipY = new BooleanType("Flip Y", m_matParams, "transmissionMapFlipY", m_uiFolders);
        this.m_transmissionClear = new ButtonType("Clear map", m_matParams, "1", m_uiFolders);
        
        this.m_thicknessMapShow = new BooleanType("Show map on mesh", m_matParams, "thicknessMapShowOnMesh", m_uiFolders);
        this.m_thicknessMap = new TextureType(MapType.thicknessMap, m_matParams, "thicknessMap", m_uiFolders);
        this.m_thicknessFlipY = new BooleanType("Flip Y", m_matParams, "thicknessMapFlipY", m_uiFolders);
        this.m_thicknessClear = new ButtonType("Clear map", m_matParams, "2", m_uiFolders);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_transmission.InitType();
        this.m_transmissionIor.InitType();
        this.m_transmissionThickness.InitType();
        this.m_transmissionMapShow.InitType();
        this.m_transmissionMap.InitType();
        this.m_transmissionFlipY.InitType();
        this.m_transmissionClear.InitType(); 
        
        this.m_thicknessMapShow.InitType();
        this.m_thicknessMap.InitType();
        this.m_thicknessFlipY.InitType();
        this.m_thicknessClear.InitType();
    }
}