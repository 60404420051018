const GET_STORES = `v2/stores/`;
const GET_STORE_BY_REF = (storeRef: string) => `v2/stores/${storeRef}`;
const GET_STORE_BY_MANAGER_ID = (managerId: number) => `v2/stores/store-manager/${managerId}`;
const CREATE_STORES = (name: string) => `v2/stores/${name}/create`;
const UPDATE_STORE_NAME = (storeRef: string, name: string) => `v2/stores/${storeRef}/${name}/rename`;
const ADD_USER_TO_STORE = (storeRef: string, roleName: string, userId: number) => `v2/stores/${storeRef}/${roleName}/${userId}/add`;
const REMOVE_USER_TO_STORE = (storeRef: string, userId: number) => `v2/stores/${storeRef}/${userId}/remove`;
const DELETE_STORES = (storeRef: string) => `v2/stores/${storeRef}/delete`;

const STORE_USER_MAP = `v2/stores/stores-by-user`;

export { GET_STORES, CREATE_STORES, DELETE_STORES, GET_STORE_BY_REF, GET_STORE_BY_MANAGER_ID, REMOVE_USER_TO_STORE, UPDATE_STORE_NAME, ADD_USER_TO_STORE, STORE_USER_MAP };
