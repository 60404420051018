import './color-combo.scss';
import { colorScheme } from './color-scheme';
import { useState } from 'react';
import { ColorScheme } from "../../types/color-scheme";


export type ColorComboProps = {
    setCombo: (combo: string) => void;
};

const ColorCombo = ({ setCombo }: ColorComboProps) => {
    const [activeButton, setActiveButton] = useState<number | undefined>(undefined);
    return (
        <div className="color-combo-main">
            { colorScheme.map((e: ColorScheme, i: number) => {
                return (
                    <button
                        key={ i }
                        className={ activeButton === i ? 'color-combo-card-active' : 'color-combo-card' }
                        onClick={ () => {
                            setActiveButton(i);
                            setCombo(e.name);
                        } }
                        style={ {
                            backgroundColor: e.backgroundColor,
                            color: e.labelAssociated,
                        } }
                    >
                        { e.name }
                    </button>
                );
            }) }{ ' ' }
        </div>
    );
};

export default ColorCombo;
