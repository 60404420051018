import './catalog-table.scss';
import React, { useState } from 'react';
import duplicate from '../../../asset/dupliquer.svg';
import trashRed from '../../../asset/poubelle-red.svg';
import { useHistory } from 'react-router-dom';
import SimpleModal from '../../../uicomponents/modal/simple-modal/simple-modal';
import { CatalogListState, deleteCatalog, SetCatalogName } from '../../../services/reducers/catalog/catalog.slice';
import { useDispatch } from 'react-redux';

type CatalogProps = {
    catalogList: CatalogListState;
    setModal: (modal: boolean) => void;
    modal: boolean;
};
const CatalogTable = (props: CatalogProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const catalogHeaders = ['NOM', 'NOMBRE DE PRESETS', 'VISIBLE PAR', 'ACTIONS'];

    //region state
    const [catalogId, setCatalogId] = useState<number>(0);
    const [vHoov, setVHoov] = useState<number>(0);
    //endregion

    return (
        <div className='catalog-table-main'>
            { props.modal && (
                <SimpleModal
                    icon={ trashRed }
                    title='Supprimer un catalogue '
                    info='Cette action supprimera le catalogue ainsi que toutes ces dépendance'
                    closeOnclick={ () => props.setModal(false) }
                    actionOnclick={ () => dispatch(deleteCatalog(catalogId)) }
                    deleteOrValide={ true }
                    closeLabel='Annuler'
                    actionLabel='Supprimer'
                />
            ) }
            <table>
                <thead className='finition-table-head'>
                <tr>
                    { catalogHeaders.map((header: string, i: number) => {
                        return <th key={ i }>{ header }</th>;
                    }) }
                </tr>
                </thead>
                { props.catalogList.payload.content.map((catalog) => {
                    return (
                        <tbody key={ catalog.name }>
                        <tr
                            onClick={ () => {
                                dispatch(SetCatalogName(catalog.name));
                                history.push(`/catalog/${ catalog.reference }/infos`);
                            } }
                            className='catalog-name'
                        >
                            <td>{ catalog.name }</td>
                        </tr>
                        <tr
                            onClick={ () => {
                                dispatch(SetCatalogName(catalog.name));
                                history.push(`/catalog/${ catalog.reference }/infos`);
                            } }>
                            <td>{ catalog.numberOfPreset }</td>
                        </tr>
                        <tr
                            onMouseEnter={ () => setVHoov(catalog.id) }
                            onMouseLeave={ () => setVHoov(0) }
                            onClick={ () => {
                                dispatch(SetCatalogName(catalog.name));
                                history.push(`/catalog/${ catalog.reference }/infos`);
                            } }
                            className='catalog-table-visibility'>
                            {/*  {catalog.destinationUsers.map((u: User, i) => {
                                    return <td key={i}>{u.email},</td>;
                                })}*/ }
                        </tr>
                        { vHoov === catalog.id && (
                            <div className='visibility-hoover'>
                                {/*  {catalog.destinationUsers.map((u: User, i) => {
                                        return <td key={i}>{u.email},</td>;
                                    })}
                                    {catalog.destinationRoles.map((r: Role, i) => {
                                        return <td key={i}>{r.name},</td>;
                                    })}*/ }
                            </div>
                        ) }
                        <tr>
                            <td className='catalog-table-action'>
                                <img onClick={ () => alert('duplicate') } alt='logo dupliquer' src={ duplicate } />
                                <img
                                    onClick={ () => {
                                        setCatalogId(catalog.id);
                                        props.setModal(true);
                                    } }
                                    alt='logo poubelle'
                                    src={ trashRed }
                                />
                            </td>
                        </tr>
                        </tbody>
                    );
                }) }
            </table>
        </div>
    );
};
export default CatalogTable;
