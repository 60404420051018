import MdfApiResponse from '../../../../../domain/common/generic';
import { Texture } from '../../../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TextureService from '../../../../../api/services/suppliers/finish/texture/texture.service';


export type GetTexturesState = {
    isLoading: boolean;
    payload: MdfApiResponse<Texture[]>;
};

const initialCreateTexture: GetTexturesState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};

export const getAllTextures = createAsyncThunk('get/textures', async (data: { finishId: number }) => {
    const res = await TextureService.getFinishTextures(data.finishId);
    return res;
});

export const getAllTexturesSlice = createSlice({
    name: 'getAllTexturesSlice',
    initialState: initialCreateTexture,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllTextures.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllTextures.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllTextures.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

//<editor-fold desc="delete-texture">

export type CreateTextureState = {
    isLoading: boolean;
    payload: MdfApiResponse<Texture[]>;
};

const initialCreateTextureState: CreateTextureState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};
export const createTexture = createAsyncThunk('create/texture', async (data: { finishId: number; textureSet: Texture[] }) => {
    const res = await TextureService.createTexture(data.finishId, data.textureSet);
    return res;
});
export const createTextureSlice = createSlice({
    name: 'createTextureSlice',
    initialState: initialCreateTextureState,
    reducers: {
        resetCreateTexture: (state) => {
            state = initialCreateTextureState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createTexture.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createTexture.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createTexture.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateTexture } = createTextureSlice.actions;
//</editor-fold>


//<editor-fold desc="delete-texture">

export type DeleteTextureState = {
    isLoading: boolean;
    payload: MdfApiResponse<Texture | undefined>;
};

const initialDeleteTextureState: DeleteTextureState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};
export const deleteTexture = createAsyncThunk('delete/texture', async (data: { finishId: number; textureId: number }) => {
    const res = await TextureService.deleteTexture(data.finishId, data.textureId);
    return res;
});
export const deleteTextureSlice = createSlice({
    name: 'deleteTetxureSlice',
    initialState: initialDeleteTextureState,
    reducers: {
        resetDeleteTexture: (state) => {
            state = initialDeleteTextureState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteTexture.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteTexture.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteTexture.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteTexture } = deleteTextureSlice.actions;
//</editor-fold>

//<editor-fold desc="update texture">

export type UpdateTextureState = {
    isLoading: boolean;
    payload: MdfApiResponse<Texture | undefined>;
};

const initialUpdateTextureState: UpdateTextureState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};
export const updateTexture = createAsyncThunk('update/texture', async (data: { textureId: number; textureName: string }) => {
    const res = await TextureService.updateTexture(data.textureId, data.textureName);
    return res;
});

export const updateTextureSlice = createSlice({
    name: 'updateTextureSlice',
    initialState: initialUpdateTextureState,
    reducers: {
        resetUpdateTexture: (state) => {
            state = initialUpdateTextureState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateTexture.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateTexture.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateTexture.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateTexture } = updateTextureSlice.actions;
//</editor-fold>
