import './store-table.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getStores, resetGetStoreByManagerId, resetGetStores, StoreListState,
} from '../../../services/reducers/store/store.slice';
import { RootState } from '../../../services/root-reducer';
import { Role, Store } from '../../../domain/domain';
import trashRed from '../../../asset/poubelle-red.svg';
import update from '../../../asset/Editer.svg';
import { useHistory } from 'react-router-dom';


type StoreTableProps = {
    setModal: (modal: boolean) => void
    setUpdateModal: (modal: boolean) => void
    setStoreRef: (storeRef: string) => void
    setUpdate: (update: boolean) => void
    setCurrentStore: (store: Store) => void
};
const StoresTable = (props: StoreTableProps) => {
        const storeHeaders = ['NOM', 'RESPONSABLE', 'ACTIONS'];
        const l = localStorage.getItem('user');
        const user = l !== null && JSON.parse(l);
        const dispatch = useDispatch();
        const history = useHistory();

        //region state
        const [isMount, setIsMount] = useState(false);
        const [roList, setRolist] = useState<string[]>([]);
        const [responsableStoreLIst, setResponsableStoreLIst] = useState<Store[]>([]);
        //endregion
        //region useSelector
        const storesRes = useSelector<RootState, StoreListState>(state => state.getStores);
        const responsableStoreRes = useSelector<RootState, StoreListState>(state => state.getStoreByManagerId);
        //endregion
        //region useEffect
        useEffect(() => {
            !isMount && dispatch(resetGetStores());
            !isMount && dispatch(resetGetStoreByManagerId());
            const rList: string[] = [];
            if (l !== null) {
                user.roles.map((r: Role) => rList.push(r.name));
                !isMount && setRolist(rList);
            }
        }, []);
        useEffect(() => {
            !isMount && dispatch(getStores());
            return () => setIsMount(true);
        }, [roList]);
        useEffect(() => {
            if (responsableStoreRes.payload.content.length > 0 && responsableStoreRes.payload.errors.length === 0) {
                const res = responsableStoreRes.payload.content.map((s: Store) => {
                    return s;
                });
                setResponsableStoreLIst(res);
            }
        }, [responsableStoreRes]);
        //endregion

        const navigate = (s: Store) => {
            history.push(`/store/${ s.reference }/${ s.name }`);
        };
        const returnListByRole = () => roList.includes('ROLE_ADMIN') ? storesRes.payload.content : responsableStoreLIst;
        const capitalized = (firstname: string) =>
            firstname.charAt(0).toUpperCase()
            + firstname.slice(1);

        return (
            <div className='store-table-main'>
                <table>
                    <thead>
                    <tr>
                        { storeHeaders.map((sh: string, i: number) => {
                            return <th key={ i }>{ sh }</th>;
                        }) }
                    </tr>
                    </thead>
                    <tbody>
                    { storesRes.payload.content.map((s: Store) => {
                        return (
                            <tr key={ s.id }>
                                <td onClick={ () => navigate(s) } className='store-name'>{ s.name }</td>
                                <td onClick={ () => navigate(s) }>{ s.responsibleSet.map(r => (r.lastname.toUpperCase() + ' ' + capitalized(r.firstname))) }</td>
                                <td className='store-table-action'>
                                    <img onClick={ () => {
                                        props.setCurrentStore(s);
                                        props.setUpdateModal(true);
                                        props.setUpdate(true);
                                    } }
                                         alt='éditer'
                                         src={ update } />

                                    <img onClick={ () => {
                                        props.setStoreRef(s.reference);
                                        props.setModal(true);
                                    } }
                                         alt='logo poubelle'
                                         src={ trashRed } />
                                </td>
                            </tr>
                        );
                    }) }
                    </tbody>
                </table>
            </div>
        );
    }
;
export default StoresTable;