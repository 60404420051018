const CREATE_USER = '/v1/users/create-user/by-admin';
const GET_USERS = '/v1/users/';

const GET_USERS_BY_STATUS = '/v1/users/user-count';
const GET_ME = '/v1/users/get-me';
const GET_USER_STATUS_COUNT = `/v1/users/status/count`;
const GET_RESPONSIBLE_LIST = `/v1/users/responsible-list`;
const GET_USER = (userId: number) => `/v1/users/${ userId }`;
const GET_ACTIVE_USER = () => `/v1/users/active`;
const UPDATE_USER = (userId: number) => `/v1/users/update-user/${ userId }`;
const DELETE_USER = (userId: number) => `/v1/users/${ userId }/delete`;
const RESET_PASSWORD_USER = (userId: number) => `/v1/users/${ userId }/reset-password-by-admin`;
export {
    CREATE_USER,
    GET_USERS,
    GET_USERS_BY_STATUS,
    GET_ME,
    GET_USER_STATUS_COUNT,
    GET_RESPONSIBLE_LIST,
    DELETE_USER,
    RESET_PASSWORD_USER,
    UPDATE_USER,
    GET_USER,
    GET_ACTIVE_USER,
};
