import { useEffect, useState } from 'react';
import './toogle-switch.scss';

export type ToogleProps = {
    title: string;
    value?: string;
    setValue: (value: string) => void;
};

const ToggleSwitch = ({ title, value, setValue }: ToogleProps) => {
    const [isToggled, setIsToggled] = useState(false);

    const onToggle = () => setIsToggled(!isToggled);

    useEffect(() => {
        if (value && value === 'CRITIQUE') {
            onToggle();
        }
    }, []);

    useEffect(() => {
        if (isToggled) {
            setValue('CRITIQUE');
        } else {
            setValue('NORMAL');
        }
    });
    return (
        <div className="toogle-main">
            <div className="title">{title}</div>
            <label className="toggle-switch">
                <input type="checkbox" checked={isToggled} onChange={onToggle} />
                <span className="switch" />
            </label>
        </div>
    );
};

export default ToggleSwitch;
