import { Rule } from '../../../domain/domain';
import { createProductRules } from '../../../services/reducers/product/rules/rules.reducers';
export const OnDuplicateRule = (productReference: string, dispatch: any, rule: Rule) => {
    dispatch(createProductRules({
        productRef: productReference,
        rule: {
            type: rule.type,
            matching: rule.matching,
            action: { type: rule.action.type, params: rule.action.params },
            ruleRef: `DUPLICATE-${rule.ruleRef}`,
        },
    }));
};