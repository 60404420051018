import './create-preset-seconde-step-V2.scss';
import React, { useEffect, useState } from 'react';
import { Colori, MappingDTO, Model3dTree, ModelTree, PartTree, PresetTree, ProductModel3d, ProductTree, ZoneDTO } from '../../../../../../domain/domain';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../services/root-reducer';
import close from '../../../../../../asset/CloseWindow.svg';
import cells3dValuesService from '../../../../../../api/services/products/3DCells/3DCellsValues.service';

import ProductConfigurator from '../edit-panel/product-configurator';
import one from '../../../../../../asset/one.png';
import two from '../../../../../../asset/two.png';
import backArrow from '../../../../../../asset/Retour.svg';
import ConfigurateurDetail, { IClickAtom } from '../edit-panel/configurator-detail/configurateur-detail';
import { getCellValuesTree, getDefaultProductConfiguration, ProductCellValueTreeState } from '../../../../../../services/reducers/product/3DCells/3DCellValues.reducers';
import { Vector2 } from 'three';
import axios from 'axios';
import { buildRoomObjectFromExistingPreset } from '../../../../../../utils/room-object-factory-v2';
import configuredProductService from '../../../../../../api/services/products/configured-product/configured-product.service';
import {
    addMultipleAtomsToConfiguredProduct,
    ConfiguredProductState,
    getConfiguredProductTreeList,
    resetAddMultipleAtomsToConfiguredProductSlice,
    uploadThumbnailUrlToConfiguredProduct,
} from '../../../../../../services/reducers/product/configured-product/configured-product.reducers';
import { executeRules } from '../../../../../../services/reducers/product/rules/rules.reducers';
import Loader from 'react-spinners/BeatLoader';
import { getConfig } from '../../../../../../api/config';
import PopupInconsistentChoice from '../../../../popup-inconsistent-choice/popup-inconsistent-choice';
import productsService from '../../../../../../api/services/products/products.service';
import Asset3D from '../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D';
import { ProductStudioState } from '../../../../../../application-3d/application3D-common/Redux/Reducers/product-studio/ProductStudioReducer';
import { ObjectParser } from '../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/ObjectParser';
import BasicObject from '../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject';
import { onLoadingFail } from '../../../../../../application-3d/application3D-common/Utils/loading-fail';
import PresetEditor from '../../../../../../application-3d/application3D-backoffice/Components/PresetEditor/PresetEditor';
import MdfApiResponse from '../../../../../../domain/common/generic';
import ObjectDatas from '../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas';

type CreatePresetSecondeStepProps = {
    setOpenCreateModalSecondStep: (openCreateModalSecondStep: boolean) => void;
    setPreset: (setPreset: PresetTree | undefined) => void;
    preset: PresetTree | undefined;
    setOpenModal: (openModal: boolean) => void;
    setGenerate: (generateDone: boolean) => void;
    setToasterImg: (toasterImg: string) => void;
    refreshPresetName: (newValue: string) => void;
    setPresetRef: (presetRef: string) => void;
};
type Conf = { partItemRef: string; atomRef: string };

export type CurrentMaterial = {
    part: {
        partRef: string;
        mappingId: number;
        zones: {
            zoneRef: string;
            defaultColorisUrl: string;
        }[];
    };
};

const CreatePresetSecondStepV2 = ({ setPreset, setOpenCreateModalSecondStep, refreshPresetName, setToasterImg, setGenerate, preset, setOpenModal }: CreatePresetSecondeStepProps) => {
    const dispatch = useDispatch();

    //#region useState
    const [atomAvailable, setAtomAvailable] = useState(false);
    const [, setModel3dObject] = useState<Asset3D | undefined>(undefined);
    const [, setOpenPanel] = useState(true);
    const [, setOpenEditPanel] = useState(false);
    const [currentConfiguration, setCurrentConfiguration] = useState<ModelTree[]>([]);
    const [currentMappingConfiguration, setCurrentMappingConfiguration] = useState<ModelTree[]>([]);
    const [currentZoneConfiguration] = useState<ModelTree[]>([]);
    const [currentMaterialConfiguration] = useState<ModelTree[]>([]);
    const [currentFinishConfiguration] = useState<ModelTree[]>([]);
    const [currentColoriConfiguration] = useState<ModelTree[]>([]);
    const [onclickModifed, setOnclickModified] = useState(false);
    const [step, setStep] = useState(1);
    const [imgUrl, setImgUrl] = useState<string>('');
    const [productObject, setProductObject] = useState<any | undefined>(undefined);
    const [clickAtom, setClickAtom] = useState<IClickAtom | undefined>(undefined);
    const [productObjectData, setProductData] = useState<ObjectDatas | undefined>(undefined);
    const [selectedPart, setSelectedPart] = useState<PartTree>({
        name: '',
        characteristics: [],
        options: [],
        reference: '',
    });

    const [partConfiguration, setPartConfiguration] = useState<ModelTree[]>([]);
    const [isMount, setIsMount] = useState(false);
    const [loading, setLoading] = useState(false);

    const [confList, setConfList] = useState<Conf[]>([]);
    const [tempConfList, setTempConfList] = useState<Conf[]>([]);
    const [currentMaterial, setCurrentMaterial] = useState<CurrentMaterial[]>([]);

    const [confAtomRefList, setConfAtomRefList] = useState<string[]>([]);
    const [productIsFilled, setProductIsFilled] = useState(false);

    const { productStudioApp } = useSelector<RootState, ProductStudioState>((state) => state.productStudio);
    const getCellValuesTreeRes = useSelector<RootState, ProductCellValueTreeState>((state) => state.getCellValueTree);
    const productStudio = useSelector<RootState, ProductStudioState>((state) => state.productStudio);
    const addMultipleAtomesRes = useSelector<RootState, ConfiguredProductState>((state) => state.addMultipleAtomsToConfiguredProduct);
    const uploadThumbnailUrlRes = useSelector<RootState, ConfiguredProductState>((state) => state.uploadThumbnailUrlToConfiguredProduct);

    const [mappingTree, setMappingTree] = useState<MappingDTO[]>([]);

    //#region useEffect
    useEffect(() => {
        displayDefaultPreset();
        if (preset) {
            dispatch(getCellValuesTree(preset.product.parentProductReference));
            dispatch(getDefaultProductConfiguration(preset.product.parentProductReference));
            if (preset.configuration.length !== 0) {
                const config = preset.configuration.filter((atom) => !atom.atom.reference.includes('>'));
                const mappingConfig = preset.mappingConfiguration.filter((atom) => atom.atom.reference.includes('mapping'));
                const zoneConfig = preset.mappingConfiguration.filter((atom) => atom.atom.reference.includes('zone'));
                const materialConfig = preset.mappingConfiguration.filter((atom) => atom.atom.reference.includes('material'));
                const finishConfig = preset.mappingConfiguration.filter((atom) => atom.atom.reference.includes('finish'));
                const coloriConfig = preset.mappingConfiguration.filter((atom) => atom.atom.reference.includes('colori'));
                setCurrentMappingConfiguration(mappingConfig);
                setCurrentConfiguration(config);
            }
        }
    }, []);

    useEffect(() => {
        setPartConfiguration([]);
    }, [selectedPart]);

    useEffect(() => {
        dispatch(
            executeRules({
                productReference: preset?.product.parentProductReference ?? '',
                configuration: [
                    ...currentConfiguration.map((el) => el.atom.reference),
                    ...currentMappingConfiguration.map((el) => el.atom.reference),
                    ...currentZoneConfiguration.map((el) => el.atom.reference),
                    ...currentMaterialConfiguration.map((el) => el.atom.reference),
                    ...currentFinishConfiguration.map((el) => el.atom.reference),
                    ...currentColoriConfiguration.map((el) => el.atom.reference),
                ],
            }),
        );

        let buildConfList: Conf[] = [];
        currentConfiguration.forEach((M: ModelTree) => {
            const f = buildConfList.find((c) => c.partItemRef === M.atom.partItemReference);
            if (!f) {
                buildConfList.push({
                    partItemRef: M.atom.partItemReference,
                    atomRef: M.atom.reference,
                });
            }
        });
        !isMount && setConfList(buildConfList);
    }, [currentConfiguration, currentMappingConfiguration]);

    useEffect(() => {
        isMount && setTempConfList(confList);
        return () => setIsMount(true);
    }, [confList]);

    useEffect(() => {
        let t: string[] = [];
        tempConfList.forEach((te) => t.push(te.atomRef));
        setConfAtomRefList(t);
    }, [tempConfList]);

    useEffect(() => {
        let model3dBuf =
            productObject &&
            Object.assign(new Asset3D(), {
                ...productObject,
                type: 'Model',
                datas: [productObject.modelUrl],
            });
        setModel3dObject(model3dBuf);
    }, [productObject]);

    useEffect(() => {
        imgUrl.length !== 0 &&
        preset &&
        dispatch(
            uploadThumbnailUrlToConfiguredProduct({
                productReference: preset.product.reference,
                product: { ...preset.product, thumbnailUrl: imgUrl },
            }),
        );
    }, [imgUrl]);

    useEffect(() => {
        if (uploadThumbnailUrlRes.payload.errors.length === 0 && uploadThumbnailUrlRes.payload.content !== null) {
            // preset && dispatch(getPresetByProductRef(preset.product.parentProductReference));
            imgUrl.length > 0 && setOpenModal(false);
            imgUrl.length > 0 && setOpenCreateModalSecondStep(false);
            setGenerate(true);
            setToasterImg(imgUrl);

            preset &&
            configuredProductService.setDefaultConfiguredProduct(preset.product.parentProductReference, preset.id).then((res) => {
                preset && dispatch(getConfiguredProductTreeList({ coreProductReference: preset.product.parentProductReference }));
            });
        }
    }, [uploadThumbnailUrlRes]);

    useEffect(() => {
        if (addMultipleAtomesRes.payload.content && addMultipleAtomesRes.payload.content.id > 0 && addMultipleAtomesRes.payload.errors.length === 0) {
            setStep(2);
            dispatch(resetAddMultipleAtomsToConfiguredProductSlice());
        }
    }, [addMultipleAtomesRes]);

    useEffect(() => {
        productStudioApp?.EngineService.Resize();
    }, [step]);

    useEffect(() => {
        const buildCurrentMaterial: CurrentMaterial[] = [];
        productObject &&
        productObject.composition.partElements.map((PE: any, i: number) => {
            const findPart = buildCurrentMaterial.find((p) => p.part.partRef === PE.refOfPart && PE.type === 'MaterialAssignment');
            if (findPart === undefined) {
                PE.refOfMapping &&
                buildCurrentMaterial.push({
                    part: {
                        partRef: PE.refOfPart,
                        mappingId: PE.refOfMapping,
                        zones:
                            PE.type === 'MaterialAssignment' &&
                            PE.datas.map((z: any) => {
                                return { zoneRef: z.refOfZoning, defaultColorisUrl: z.urls[0] };
                            }),
                    },
                });
            }
        });
        setCurrentMaterial(buildCurrentMaterial);
        if (productObject) {
            let test = new ObjectDatas();
            test.SetDatas([], [ObjectParser.DeepParseObject(productObject, BasicObject)]);
            setProductData(test);
        }
    }, [productObject]);

    useEffect(() => {
        productObject &&
        productIsFilled &&
        preset &&
        cells3dValuesService.get3dCellValuesTree(preset.product.parentProductReference).then((r: MdfApiResponse<ProductTree>) => {
            const partTreeList = r.content.partTreeList;
            displayDefaultColoris(partTreeList);
            setProductIsFilled(false);
        });
    }, [productObject, productIsFilled]);
    //#endregion

    //region methods
    const savePresetPointOfView = () => {
        if (productStudioApp) {
            if (!productStudioApp.Services.Cameras.CurrentCamera) throw new Error('main camera is null');
            let camera = productStudioApp.Services.Cameras.CurrentCamera.GetObject();
            let camRelativePosFromTarget = productStudioApp.Services.Cameras.GetRelativePosFromTarget(camera);
            return { ...productObject, pointOfView: camRelativePosFromTarget };
            // setProductObject((prevState: any) => {
            //     return {
            //         ...prevState,
            //         pointOfView: camRelativePosFromTarget,
            //     };
            // });
        }
    };
    const displayDefaultColoris = (partTreeList: PartTree[]) => {
        let objectToClick: { partTree: PartTree; mappingDTO: MappingDTO }[] = [];

        preset &&
        partTreeList.map((ptl) => {
            preset.configuration.map((c: ModelTree) => {
                const PartInObject = objectToClick.find((exiPartTree) => exiPartTree.partTree.reference === ptl.reference);
                PartInObject === undefined &&
                c.atom.partReference === ptl.reference &&
                objectToClick.push({
                    partTree: ptl,
                    mappingDTO: c.model3d[0].mappings[0],
                });
            });
            const isEmpty = Object.keys(preset.metadata).length === 0;
            isEmpty &&
            objectToClick.forEach((obj) => {
                onMappingClick(obj.partTree, obj.mappingDTO);
            });
        });
    };
    const displayDefaultPreset = () => {
        preset &&
        buildRoomObjectFromExistingPreset(preset, (res) => {
            setProductIsFilled(true);
            setProductObject(res);
        }, setMappingTree);
    };
    const addToCurrentMappingConfiguration = (mapping: MappingDTO) => {
        const oldMappingConfig = currentMappingConfiguration.filter((el) => el.atom.partReference !== selectedPart.reference);
        const newMappingConfig = [
            ...oldMappingConfig,
            {
                atom: {
                    reference: `>${selectedPart.reference}-mapping-${mapping.id}`,
                    value: mapping.name,
                    label: mapping.name,
                    partReference: selectedPart.reference,
                    partName: selectedPart.name,
                    partItemReference: `${selectedPart.reference}-mapping`,
                    partItemName: 'Mappings',
                    id: 0,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    thumbnailUrl: '',
                    version: 0,
                },
                model3d: [],
            },
        ];
        setCurrentMappingConfiguration(newMappingConfig);
    };
    const addToCurrentConfiguration = (value: ModelTree) => {
        const newConfig = currentConfiguration.map((el) => (el.atom.partItemReference === value.atom.partItemReference ? value : el));

        setCurrentConfiguration(newConfig);
    };
    const configurationSuggestion = async () => {
        if (clickAtom && preset) {
            setLoading(true);
            const res = await productsService.getConfigurationByModel3dCellReference(preset.product.parentProductReference, clickAtom.atom.atom.reference);
            let newConfig: ModelTree[] = res.content.filter((atom: ModelTree) => !atom.atom.reference.includes('>'));

            let newList: ModelTree[][] = newConfig.reduce((acc: ModelTree[][], curr: ModelTree) => {
                // on cherche une liste existante qui a la même partReference que l'élément courant
                const matchingList = acc.find((list) => list[0].atom.partReference === curr.atom.partReference);
                if (matchingList) {
                    // si on trouve une liste existante, on ajoute l'élément courant à cette liste
                    matchingList.push(curr);
                } else {
                    // sinon, on crée une nouvelle liste avec l'élément courant et on l'ajoute à la liste de liste
                    acc.push([curr]);
                }
                return acc;
            }, []);

            const model3dPromise = newList.map(async (partialConf) => {
                const result = await cells3dValuesService.getModel3dUrlForCombo(partialConf, setMappingTree);
                return result;
            });

            Promise.all(model3dPromise).then((promiseAll) => {
                const productModel3dList: Model3dTree[] = promiseAll.map((response) => response.content[0]);

                setProductObject((prevState: any) => {
                    if (prevState === undefined) {
                        alert('UNDEFINED');
                    }

                    const partElements = prevState.composition.partElements.filter((asset: any) => asset.type !== 'Model3d');
                    const assets = productModel3dList.map((model: Model3dTree) => {
                        return {
                            dataModelVersion: '1.0.0',
                            datas: {
                                dataModelVersion: '1.0.0',
                                urls: [model.modelUrl],
                                hooks: clickAtom.atom.model3d[0].anchorPoints.length === 0 ? undefined : clickAtom.atom.model3d[0].anchorPoints,
                            },
                            options: [],
                            refOfPartItem: '',
                            refOfPart: model.partReference,
                            type: 'Model3d',
                        };
                    });

                    const state = {
                        ...prevState,
                        composition: {
                            ...prevState.composition,
                            partElements: [...partElements, ...assets],
                        },
                    };
                    return state;
                });
            });
            updateConfigurations(newConfig);
            let T: Conf[] = newConfig.map((m) => {
                return {
                    partItemRef: m.atom.partItemReference,
                    atomRef: m.atom.reference,
                };
            });
            setTempConfList(T);
        }
        closePopup();
        setLoading(false);
    };
    const updateConfigurations = (newConfigurations: ModelTree[]) => {
        const newConfig = currentConfiguration.map((el) => {
            const updatedConfig = newConfigurations.find((conf) => conf.atom.partItemReference === el.atom.partItemReference);
            return updatedConfig || el;
        });
        setCurrentConfiguration(newConfig);
    };
    const onAtomClick = async (value: ModelTree): Promise<ModelTree> => {
        try {
            //addToCurrentConfiguration(value);
            const newConfig = currentConfiguration.map((el) => (el.atom.partItemReference === value.atom.partItemReference ? value : el));
            const partialConf = newConfig.filter((m) => m.atom.partReference === value.atom.partReference);

            let conf = partConfiguration;
            let T: Conf[] = [...tempConfList];

            const foundIndex = conf.findIndex((el) => value.atom.partItemReference === el.atom.partItemReference);

            if (foundIndex === -1) {
                conf.push(value);
            } else {
                const newarr = conf.map((item) => {
                    return item.atom.partItemReference === value.atom.partItemReference ? value : item;
                });
                conf = newarr;
            }
            setPartConfiguration(conf);

            await cells3dValuesService.getModel3dUrlForCombo(partialConf, setMappingTree)
                .then((modelUrl) => {
                    setProductObject((prevState: any) => {
                        if (prevState === undefined) {
                            alert('UNDEFINED');
                        }
                        const urlList = modelUrl.content.map((model: any) => model.modelUrl);
                        const state = {
                            ...prevState,
                            composition: {
                                ...prevState.composition,
                                partElements: prevState.composition.partElements.map((asset: any) => {
                                    if (asset.type === 'Model3d') {
                                        if (asset.refOfPart === value.atom.partReference) {
                                            asset = {
                                                dataModelVersion: asset.dataModelVersion,
                                                datas: {
                                                    urls: urlList.length === 0 ? [value.model3d[0].modelUrl] : urlList,
                                                    hooks: value.model3d[0].anchorPoints.length === 0 ? undefined : value.model3d[0].anchorPoints,
                                                },
                                                options: asset.options,
                                                refOfPartItem: value.atom.partItemReference,
                                                refOfPart: asset.refOfPart,
                                                type: asset.type,
                                            };
                                        }
                                    }
                                    return asset;
                                }),
                            },
                        };
                        return state;
                    });
                    addToCurrentConfiguration(value);
                });
            T.forEach((e) => {
                if (e.partItemRef === value.atom.partItemReference) {
                    e.atomRef = value.atom.reference;
                }
            });
            setTempConfList(T);
        } catch (error) {
            console.error(error);
        }
        return value;
    };
    const onMappingClick = (sectionSelected: PartTree, mappingSelected: MappingDTO) => {
        if (mappingSelected) {
            setProductObject((prevState: any) => {
                let state;
                if (prevState.composition.partElements.find((asset: any) => asset.type === 'MaterialAssignment' && asset.refOfPart === sectionSelected.reference) === undefined) {
                    state = {
                        ...prevState,
                        composition: {
                            ...prevState.composition,
                            partElements: [
                                ...prevState.composition.partElements,
                                {
                                    dataModelVersion: '1.0.0',
                                    datas: mappingSelected.zoneList.map((el) => {
                                        const zone = {
                                            dataModelVersion: '1.0.0',
                                            refOfZoning: el.uuid,
                                            urls: [el.defaultColori !== null && el.defaultColori.modelUrl],
                                            refs: [...el.availableIds],
                                        };
                                        return zone;
                                    }),
                                    refOfMapping: mappingSelected.id,
                                    options: [],
                                    refOfPart: sectionSelected.reference,
                                    type: 'MaterialAssignment',
                                },
                            ],
                        },
                    };
                } else {
                    state = {
                        ...prevState,
                        composition: {
                            ...prevState.composition,
                            partElements: prevState.composition.partElements.map((asset: any) => {
                                if (asset.refOfPart === sectionSelected.reference && asset.type === 'MaterialAssignment') {
                                    asset = {
                                        dataModelVersion: asset.dataModelVersion,
                                        datas: mappingSelected.zoneList.map((el) => {
                                            const zone = {
                                                dataModelVersion: '1.0.0',
                                                refOfZoning: el.uuid,
                                                urls: [el.defaultColori !== null && el.defaultColori.modelUrl],
                                                refs: [...el.availableIds],
                                            };
                                            return zone;
                                        }),
                                        refOfMapping: mappingSelected.id,
                                        options: asset.options,
                                        refOfPart: asset.refOfPart,
                                        type: asset.type,
                                    };
                                }
                                return asset;
                            }),
                        },
                    };
                }
                return state;
            });
            addToCurrentMappingConfiguration(mappingSelected);
        }
    };
    const onColoriClick = (sectionSelected: PartTree, m: MappingDTO, z: ZoneDTO, c: Colori) => {
        setProductObject((prevState: any) => {
            // if (prevState.composition.partElements.find(el => el.type === "MaterialAssignment" && asset.refOfMapping === m.id))
            const state = {
                ...prevState,
                composition: {
                    ...prevState.composition,
                    partElements: prevState.composition.partElements.map((asset: any) => {
                        if (asset.refOfPart === sectionSelected.reference && asset.type === 'MaterialAssignment' && asset.refOfMapping === m.id) {
                            asset = {
                                dataModelVersion: asset.dataModelVersion,
                                datas: asset.datas.map((zone: any) => {
                                    if (zone.refOfZoning === z.uuid) {
                                        return {
                                            ...zone,
                                            urls: [c.modelUrl],
                                        };
                                    }
                                    return zone;
                                }),
                                refOfMapping: asset.refOfMapping,
                                options: asset.options,
                                refOfPart: asset.refOfPart,
                                type: asset.type,
                            };
                        }
                        return asset;
                    }),
                },
            };
            return state;
        });
    };
    const onSubmit = () => {
        if (step === 1) {
            const productObjWithPov = savePresetPointOfView();
            preset &&
            configuredProductService.addMetadataToConfiguredProduct(preset.product.reference, productObjWithPov).then(() => {
                dispatch(
                    addMultipleAtomsToConfiguredProduct({
                        productReference: preset!.product.reference,
                        body: {
                            atomReferences: currentConfiguration.map((el) => el.atom.reference),
                            mappingConfiguration: [
                                ...currentMappingConfiguration.map((a) => a.atom),
                                ...currentZoneConfiguration.map((a) => a.atom),
                                ...currentMaterialConfiguration.map((a) => a.atom),
                                ...currentFinishConfiguration.map((a) => a.atom),
                                ...currentColoriConfiguration.map((a) => a.atom),
                            ],
                        },
                    }),
                );
            });
        } else {
            productStudioApp?.Services.Screenshot.Take3DSnapshot(new Vector2(570, 390))
                .then((blob: Blob) => {
                    let formData = new FormData();
                    formData.append('file', blob);
                    axios
                        .post(`${getConfig().urlHostApi}/v1/documents/upload`, formData, {
                            headers: { Authorization: `Bearer ${localStorage.getItem('mdf-jwt')}` },
                        })
                        .then((res) => {
                            setImgUrl(res.data.content);
                            // setOpenCreateModalSecondStep(false);
                        })
                        .catch((e) => {
                            console.error('erreur', e);
                        });
                })
                .catch((e) => console.error(e));
        }
    };
    const onClickModifier = (Part: PartTree) => {
        setOnclickModified(!onclickModifed);
        setSelectedPart(Part);
    };
    const closePopup = () => {
        setAtomAvailable(false);
    };
    //endregion

    return (
        <div className='gen-main'>
            {atomAvailable && <PopupInconsistentChoice actionOnclick={configurationSuggestion} closeOnclick={closePopup} />}
            <div className='gen-paper'>
                <div className='gen-top'>
                    <div>
                        <img alt='premier etape' src={step === 1 ? one : two} />
                        <span>{step === 1 ? 'DEFINIR LA CONFIGURATION DU PRESET' : 'DÉFINIR LE POINT DE VUE DE LA VIGNETTE DU PRESET'}</span>
                        {loading && <Loader color='#2C3E4F' size={10} />}
                    </div>
                    <img
                        alt='fermer la modal'
                        onClick={() => {
                            setOpenCreateModalSecondStep(false);
                            setPreset(undefined);
                            setOnclickModified(!onclickModifed);
                            setStep(1);
                        }}
                        src={close}
                    />
                </div>
                <div className={step === 1 ? 'gen-bod' : 'gen-bod-step2'}>
                    {step === 1 ? (
                        <>
                            {productObject && (
                                <PresetEditor
                                    object={productObjectData}
                                    setOpenPanel={setOpenPanel}
                                    onLoadingFail={onLoadingFail}
                                    openPanel={true}
                                    setOpenEditPanel={setOpenEditPanel}
                                    preset={preset}
                                    refreshPresetName={refreshPresetName}
                                />
                            )}
                            {!onclickModifed ? (
                                <ProductConfigurator onClickModifier={onClickModifier} preset={preset} selectedConfiguration={currentConfiguration} />
                            ) : (
                                <ConfigurateurDetail
                                    mappingTree={mappingTree}
                                    setMappingTree={setMappingTree}
                                    currentMaterial={currentMaterial}
                                    confAtomRefList={confAtomRefList}
                                    setIsMount={setIsMount}
                                    isMount={isMount}
                                    getCellValuesTreeRes={getCellValuesTreeRes}
                                    onAtomClick={onAtomClick}
                                    onColoriClick={(sectionSelected, m, z, C) => {
                                        onColoriClick(sectionSelected, m, z, C);
                                    }}
                                    onMappingClick={onMappingClick}
                                    onclickModifed={onclickModifed}
                                    preset={preset}
                                    currentConfiguration={currentConfiguration}
                                    sectionSelected={selectedPart}
                                    setOnclickModified={setOnclickModified}
                                    atomAvailable={atomAvailable}
                                    setAtomAvailable={setAtomAvailable}
                                    clickAtom={clickAtom}
                                    setClickAtom={setClickAtom}
                                />
                            )}
                        </>
                    ) : (
                        productObject &&
                        preset && (
                            <PresetEditor
                                object={productObjectData}
                                onLoadingFail={onLoadingFail}
                                setOpenPanel={setOpenPanel}
                                openPanel={true}
                                setOpenEditPanel={setOpenEditPanel}
                                preset={preset}
                                refreshPresetName={refreshPresetName}
                            />
                        )
                    )}
                </div>
                <div className='gen-btn-box'>
                    {step === 2 ? (
                        <button className='btn-back' onClick={() => setStep(1)}>
                            <img alt='retour' src={backArrow} />
                            <span>Précédent</span>
                        </button>
                    ) : (
                        <div />
                    )}
                    <div className='right'>
                        <button
                            onClick={() => {
                                if (productStudio) {
                                    productStudio.productStudioApp?.EngineService.Resize();
                                }
                                setOpenCreateModalSecondStep(false);
                                setStep(1);
                                setOnclickModified(!onclickModifed);
                            }}
                            className='btn-close'
                        >
                            Annuler
                        </button>
                        <button className='btn-action' onClick={onSubmit}>
                            {step === 1 ? 'Suivant' : 'Générer'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatePresetSecondStepV2;
