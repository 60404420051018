export function Set(value) {
    document.ImageUpdateCallback = value;
}

export function SetTypeOfCell(value){
    document.NameOfImageCell = value;
}

export function SetCurrentMatName(value){
    document.NameOfCurrentMat = value;
}
