import { useEffect, useState } from 'react';
import '../style.scss';
import { useDispatch, useSelector } from 'react-redux';
import {Cache, Material, Mesh, MeshLambertMaterial, DoubleSide} from 'three';
import { RootState } from '../../../services/root-reducer';
import { ColorState, SetMaterial, ToggleGLTFDirty } from '../../../services/reducers/material-editor/ColorReducer';
import { SetEditorDirty, SetExitStatus, SetSavedMat } from '../../../services/reducers/material-editor/EditorReducer';
import { ExitType } from '../Scripts/Inspector/ExitType';
import {MaterialLoader} from '@lutithree/build/Modules/WebGL/Resources/Load/MaterialLoader';
import { LoadingManager } from '@lutithree/build/Modules/WebGL/Resources/Load/LoadingManager';
import {TexturesLoadingError} from "@lutithree/build/Modules/WebGL/Resources/Load/TexturesLoadingError";
import ResourceErrorData
    from "../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/ResourceErrorData";
import TextureErrorData
    from "../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/TextureErrorData";

export type MaterialLoaderProps = {
    onLoadingFail?: (p_resourceErrorData: ResourceErrorData) => void;
};

const CurrentMaterialLoader = (props: MaterialLoaderProps) => {
    const dispatch = useDispatch();
    const {url} = useSelector<RootState, ColorState>((state) => state.ColorReducer);
    const [currentUrl, setCurrentUrl] = useState<string | undefined>(undefined);
    const [currentMaterial, setCurrentMaterial] = useState<{ material: Material, path: string | undefined } | undefined>(undefined);

    const loadMaterialFromURL = (p_url: string): void => {
        let loader = new MaterialLoader([p_url]);

        LoadingManager.Instance.ObjectLoader.setRequestHeader({"Cache-Control": "no-cache"});
        LoadingManager.Instance.GltfLoader.setRequestHeader({"Cache-Control": "no-cache"});
        setCurrentUrl(p_url);

        loader.LoadAsync().then((obj) => {
                let material = obj.material as MeshLambertMaterial;
                (material as any).attenuationDistance = Infinity;
                (material as any).side = DoubleSide;
                setCurrentMaterial(obj);
            })
            .catch((error) => {
                if(error instanceof TexturesLoadingError){
                    let material = error.Material as MeshLambertMaterial;
                    error.Textures.forEach((url,slot)=>{
                        // @ts-ignore
                        material[slot]?.dispose();
                        // @ts-ignore
                        material[slot] = null;
                        material.needsUpdate = true;

                        if(props.onLoadingFail) props.onLoadingFail(new TextureErrorData(url,error.Path, slot));
                    });
                    setCurrentMaterial({ material: error.Material, path: error.Path });
                 }
            }); 
        };

        useEffect(() => {
            if (currentMaterial && currentUrl === currentMaterial.path) {
                dispatch(SetEditorDirty(true));
                dispatch(SetMaterial(currentMaterial.material));
                dispatch(SetSavedMat(currentMaterial.material));
            }
        }, [currentMaterial]);

        useEffect(() => {
            Cache.clear();
            Cache.enabled = false;
        });

        useEffect(() => {
            if (url) {
                loadMaterialFromURL(url);
                dispatch(SetExitStatus(ExitType.NoSave));
                dispatch(ToggleGLTFDirty(false));
            }
        }, [url]);

        return <></>;
    };

export default CurrentMaterialLoader;
