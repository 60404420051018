import Inspector from "../Inspector";
import {MeshPhysicalMaterial} from "three";
import * as THREE from "three";
import {MapType} from "../MapType";

export function TransformBind(this: Inspector) {
    const self: any = this;

    this.m_transformSlide?.m_tiling.BindType( function () {
        if (self.m_materialUpdatedCallback && self.m_currentEditedMaterial) self.m_materialUpdatedCallback(self.m_currentEditedMaterial.clone(), true);
        self.ToggleSlotDirty();
    });
    this.m_transformSlide?.m_offset.BindType(function () {
        if (self.m_materialUpdatedCallback && self.m_currentEditedMaterial) self.m_materialUpdatedCallback(self.m_currentEditedMaterial.clone(), true);
        self.ToggleSlotDirty();
    });
    this.m_transformSlide?.m_wrapMode.BindType(function () {
        if (self.m_materialUpdatedCallback && self.m_currentEditedMaterial) {
            self.m_materialEditor.UpdateAllSlotsTransform(self.GetTransformData());
            self.m_materialUpdatedCallback(self.m_currentEditedMaterial.clone(), true);
        }
        self.ToggleSlotDirty();
    });
    this.m_transformSlide?.m_rotation.BindType(function () {
        if (self.m_materialUpdatedCallback && self.m_currentEditedMaterial) {
            self.m_materialEditor.UpdateAllSlotsTransform(self.GetTransformData());
            self.m_materialUpdatedCallback(self.m_currentEditedMaterial.clone(), true);
        }
        self.ToggleSlotDirty();
    });
    this.m_transformSlide?.m_rotationCenter.BindType(function () {
        if (self.m_materialUpdatedCallback && self.m_currentEditedMaterial) self.m_materialUpdatedCallback(self.m_currentEditedMaterial.clone(), true);
        self.ToggleSlotDirty();
    });
}