import { useEffect, useState } from 'react';
import '../style.scss';
import { Viewer3D } from '../Scripts/Viewer3D/Viewer3D';
import { useDispatch, useSelector } from 'react-redux';
import { Engine } from '@lutithree/build/Engine';
import {
    ClearCurrentTexture,
    ClearGame,
    EditorState,
    SetGame,
} from '../../../services/reducers/material-editor/EditorReducer';
import CurrentSupport from './CurrentSupport';
import CurrentMaterial from './CurrentMaterial';
import SupportBuilder from './SupportBuilder';
import {
    AddEntityID,
    ClearCurrentTargetEntity,
    ClearEntitiesIDs,
    RemoveEntityWithRef,
} from '../../../services/reducers/material-editor/EntitiesReducer';
import { SimpleDropzone } from 'simple-dropzone';
import { RootState } from '../../../services/root-reducer';
import {
    AddTypeCameraPosition,
    ClearImage,
    ClearTypeCameraPositions,
    SetCurrentType,
} from '../../../services/reducers/material-editor/ThumbnailReducer';
import { Vector3 } from 'three';

const Viewer3DContainer = () => {
    const dispatch = useDispatch();
    const [domNode, setDomNode] = useState<HTMLDivElement | null>(null);
    const [dropControl, setDropControl] = useState<any>(null);
    const {game} = useSelector<RootState, EditorState>((state) => state.EditorReducer);

    const buildApplication3d = (p_dom: HTMLDivElement) => {
        let engine = new Engine(p_dom);
        let enginePromise = engine.Initialize();
        enginePromise.then(() => {
            let game = new Viewer3D(engine);
            game.LinkToEngine(engine);
            engine.Start();
            dispatch(SetGame(game));
        });
    };
    
    useEffect(()=>{
        if(!dropControl || !game)
            return;
        dropControl.on('drop', (files: { files: Map<string, File> }) =>
        {
            files.files.forEach((file) =>
            {
                game.TargetMeshService.RemoveCustomObjectFromScene();
                dispatch(RemoveEntityWithRef("Custom"));
                let entityID = game.TargetMeshService.LoadFileFromURL(file, ()=>{
                    dispatch(AddEntityID({entityId: entityID, thumbnailRef: "Custom"}));
                    dispatch(SetCurrentType("Box"));
                    dispatch(SetCurrentType("Custom"));
                    game.TargetMeshService.CustomObjectID = entityID;
                });
            });
        });
    }, [game]);

    useEffect(() =>
    {
        if (!domNode)
            return;
        let el = domNode;
        let dropEl = el.querySelector('.dropzone');
        let inputEl = el.querySelector('#file-input');
        if (dropEl && inputEl)
        {
            const dropCtrl = new SimpleDropzone(dropEl, inputEl);
            setDropControl(dropCtrl);
            let viewerEl = document.createElement('div');
            dropEl.innerHTML = '';
            viewerEl.id = "editor";
            dropEl.appendChild(viewerEl);
            buildApplication3d(viewerEl);
        }
    }, [domNode]);
    
    useEffect(()=>{
        dispatch(AddTypeCameraPosition({thumbnailType: "Box", cameraPosition: new Vector3(-0.7, 0.35, 0)}));
        dispatch(AddTypeCameraPosition({thumbnailType: "Sphere", cameraPosition: new Vector3(1, 0.25, 0)}));
        dispatch(AddTypeCameraPosition({thumbnailType: "Fabric", cameraPosition: new Vector3(0.25, 0.35, 0.22  )}));
        dispatch(AddTypeCameraPosition({thumbnailType: "Sofa", cameraPosition: new Vector3(1, 1, 0)}));
        return () =>
        {
            dispatch(ClearGame());
            dispatch(ClearEntitiesIDs());
            dispatch(ClearCurrentTargetEntity());
            dispatch(ClearCurrentTexture());
            dispatch(ClearTypeCameraPositions());
            dispatch(ClearImage());
        };
    }, []);
    
    return (
        <>
            <div id="webplanner" ref={(ref) => setDomNode(ref)}>
                <div className="dropzone">
                    <input type="file" name="file-input[]" id="file-input"/>
            </div>
            </div>
            <CurrentSupport/>
            <CurrentMaterial/>
            <SupportBuilder/>
        </>
    );
};

export default Viewer3DContainer;
