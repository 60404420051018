import { formErrors } from '../../../../utils/form-errors';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    resetUpdateFinish,
    updateFinish,
    UpdateFinishState,
} from '../../../../services/reducers/suppliers/finish/finishes.slice';
import { Tag, VignetteEnum } from '../../../../domain/domain';
import './update-finish-form.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    createTag,
    CreateTagState,
    getFinishTag,
    GetFinishTagState,
} from '../../../../services/reducers/tag/tag.slice';
import { RootState } from '../../../../services/root-reducer';
import {
    findTagsByFinishId,
    FindTagsByFinishIdState,
    resetFindTagsByFinishId,
} from '../../../../services/reducers/suppliers/suppliers.slice';
import { useHistory } from 'react-router-dom';
import TagSelector from '../../../tag-selector/tag-selector';
import { returnadvice } from '../../../modals/mapping-zoning/mapping/create-mapping';


type UpdateFinishFormProps = {
    finishId: number;
    payload: any;
};
type Inputs = {
    name: string;
    reference: string;
    typeVignette: VignetteEnum;
    technicalDescription: string;
    commercialDescription: string;
    tagIdList: number[];
};

const UpdateFinishForm = (props: UpdateFinishFormProps) => {
    const dispatch = useDispatch();
    const [tagList, setTagList] = useState<number[]>([]);
    const [, setTagListValuePrice] = useState<number[]>([]);
    const [tagListMatiere, setTagListMatiere] = useState<Tag[]>([]);
    const [, setTagListPrice] = useState<Tag[]>([]);
    const getFinishTagRes = useSelector<RootState, GetFinishTagState>((state) => state.getFinishTag);
    const createTags = useSelector<RootState, CreateTagState>((state) => state.createTag);
    const tagsByFinishIdRes = useSelector<RootState, FindTagsByFinishIdState>((state) => state.findTagsByFinishId);
    const updateFinishRes = useSelector<RootState, UpdateFinishState>((state) => state.updateFinish);
    const [tagListEmpty, setTagListEmpty] = useState(true);

    const [, setServerErrCode] = useState('');
    const [taglistErr, setTagListErr] = useState<string | undefined>();

    const history = useHistory();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        tagList.length < 1
            ? setTagListEmpty(true)
            : props.finishId &&
            dispatch(
                updateFinish({
                    finishId: props.finishId,
                    finish: {
                        name: data.name,
                        typeVignette: data.typeVignette,
                        reference: data.reference,
                        tagIdList: tagList,
                        tagIdListPrix: [],
                        technicalDescription: data.technicalDescription,
                        businessDescription: data.commercialDescription,
                        logoUrl: '',
                    },
                }),
            );
    };

    useEffect(() => {
        dispatch(getFinishTag('FINISH'));
        dispatch(getFinishTag('PRICE'));
        dispatch(findTagsByFinishId(props.finishId));
    }, []);
    useEffect(() => {
        dispatch(getFinishTag('FINISH'));
        dispatch(getFinishTag('PRICE'));
        if (createTags.payload.content !== undefined && createTags.payload.content !== null && createTags.payload.content.entityType === 'FINISH') {
            setTagList((prevState) => {
                // @ts-ignore
                return [...prevState, createTags.payload.content.id];
            });
        }

        if (createTags.payload.content !== undefined && createTags.payload.content !== null && createTags.payload.content.entityType === 'PRICE') {
            setTagListValuePrice((prevState) => {
                // @ts-ignore
                return [...prevState, createTags.payload.content.id];
            });
        }
    }, [createTags]);
    useEffect(() => {
        if (tagsByFinishIdRes.payload.content && tagsByFinishIdRes.payload.content.length > 0) {
            setTagListValuePrice(tagsByFinishIdRes.payload.content.map((tag) => tag.id));
            setTagList(tagsByFinishIdRes.payload.content.map((tag) => tag.id));
        }
    }, [tagsByFinishIdRes]);
    useEffect(() => {
        if (getFinishTagRes.payload.content && getFinishTagRes.payload.content.length > 0 && getFinishTagRes.payload.content[0].entityType === 'FINISH') {
            setTagListMatiere((prevState) => {
                return getFinishTagRes.payload.content.filter((value) => value.entityType === 'FINISH');
            });
        }

        if (getFinishTagRes.payload.content && getFinishTagRes.payload.content.length > 0 && getFinishTagRes.payload.content[0].entityType === 'PRICE') {
            setTagListPrice((prevState) => {
                return getFinishTagRes.payload.content.filter((value) => value.entityType === 'PRICE');
            });
        }
    }, [getFinishTagRes]);
    useEffect(() => {
        if (updateFinishRes.payload.content !== undefined && updateFinishRes.payload.errors.length === 0) {
            const finish = updateFinishRes.payload.content;
            history.push(`/supplier/${finish.supplierReference}/finitions`);
            dispatch(resetFindTagsByFinishId());
            dispatch(resetUpdateFinish());
        } else if (updateFinishRes.payload.errors.length > 0) {
            setServerErrCode(updateFinishRes.payload.errors[0].code);
        }
    }, [updateFinishRes]);
    useEffect(() => {
        if (props.payload.content) {
            props.payload && setValue('name', props.payload.content.name, {});
            props.payload && setValue('reference', props.payload.content.reference, {});
            props.payload && setValue('typeVignette', props.payload.content.typeVignette, {});
            props.payload && setValue('tagIdList', props.payload.content.tagIdList, {});
            props.payload && setValue('commercialDescription', props.payload.content.businessDescription, {});
            props.payload && setValue('technicalDescription', props.payload.content.technicalDescription, {});
        }
    }, [props.payload]);

    const createFinishTag = (tagName: string) => {
        dispatch(createTag({ tagEntityType: 'FINISH', tagName: tagName }));
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className='update-finition-form'>
            <div className='form-left'>
                <h6>INFORMATIONS GÉNÉRALES</h6>
                <label htmlFor='name'>Nom</label>
                <input
                    placeholder='Nom de la finition'
                    {...register('name', {
                        required: true,
                    })}
                />
                <div style={{ marginBottom: 12 }}> {returnadvice(watch('name'), 24)}</div>
                {formErrors(errors.name)}

                <select
                    defaultValue={props.payload.content && props.payload.content.typeVignette} {...register('typeVignette')}>
                    <option value='A_PLAT'>Aplat</option>
                    <option value='TISSUS'>Tissus</option>
                    <option value='SPHERE'>Sphère</option>
                </select>
                {errors && formErrors(errors.typeVignette)}

                <label htmlFor='Tags'>Tags Matière</label>

                <TagSelector
                    placeholder='Ajouter votre tag matière'
                    tagListEmpty={tagListEmpty}
                    onChange={(newTagIdList) => {
                        setTagList(newTagIdList);
                    }}
                    selectedTags={tagList}
                    onCreate={(tagName) => {
                        createFinishTag(tagName);
                    }}
                    tagList={tagListMatiere}
                />
                <span className='tag-err'>{taglistErr}</span>
            </div>

            <div className='form-right'>
                <h6>DESCRIPTIONS</h6>
                <>
                    <label htmlFor='technicalDescription'>Description technique</label>
                    <textarea
                        onKeyDown={(e) => e.key === 'Enter' && console.log('')}
                        defaultValue={props.payload.content && props.payload.content.technicalDescription}
                        placeholder='Description technique' {...register('technicalDescription')} />
                    {errors && formErrors(errors.technicalDescription)}
                </>
                <div className='btn-box'>
                    <button
                        onClick={() => {
                            tagList.length === 0 && setTagListErr('Ce champ est requis');
                        }}
                        className='btn-submit' type='submit'>
                        Sauvegarder
                    </button>
                </div>
            </div>
        </form>
    );
};
export default UpdateFinishForm;
