import React, { useEffect, useState } from 'react';
import './suppliers-tables.scss';
import logo from '../../../asset/logo-mdf.jpeg';
import trashRed from '../../../asset/poubelle-red.svg';
import edit from '../../../asset/Editer.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteSupplier,
    DeleteSupplierState,
    getSuppliers,
    GetSuppliersState,
    resetUpdateSupplier,
    UpdateSupplierState,
} from '../../../services/reducers/suppliers/suppliers.slice';
import { RootState } from '../../../services/root-reducer';
import { useHistory } from 'react-router-dom';
import { Supplier } from '../../../domain/domain';
import SuplierModalDelete from '../../modals/supplier-delete-modal/suplier-modal-delete';


const SuppliersTable = () => {
    const headers = ['NOM', 'RÉFERENCE', 'ACTIONS'];
    const dispatch = useDispatch();
    const history = useHistory();
    const [openM, setOpenM] = useState<boolean>(false);
    const [supplierReference, setSupplierReference] = useState<string>('');
    const [supplierName, setSupplierName] = useState('');
    const [hovId, setHovId] = useState(0);

    const suppliers = useSelector<RootState, GetSuppliersState>((state) => state.getSuppliers);
    const deleteRes = useSelector<RootState, DeleteSupplierState>((state) => state.deleteSupplier);
    const updateRes = useSelector<RootState, UpdateSupplierState>((state) => state.updateSupplier);

    useEffect(() => {
        dispatch(getSuppliers());
    }, []);
    useEffect(() => {
        if (deleteRes.payload.content.length !== 0 && deleteRes.payload.errors.length === 0) {
            setOpenM(false);
            dispatch(getSuppliers());
        }
    }, [deleteRes]);
    useEffect(() => {
        dispatch(resetUpdateSupplier());
        dispatch(getSuppliers());
    }, [updateRes]);

    const supplierLink = (supplier: Supplier) => {
        return history.push(`/supplier/${ supplier.reference }/collections`);
    };

    return (
        <>
            { openM && <SuplierModalDelete onclickMethod={ () => dispatch(deleteSupplier(supplierReference)) }
                                           setOpenModal={ setOpenM } supplier={ supplierName } /> }
            <table className='supplier-table-main'>
                <thead className='table-head'>
                <tr>
                    { headers.map((header, i) => {
                        return <th key={ i }>{ header }</th>;
                    }) }
                </tr>
                </thead>
                <tbody>
                { suppliers.payload.content &&
                    suppliers.payload.content &&
                    suppliers.payload.content.map((supplier) => {
                        return (
                            <tr key={ supplier.id }>
                                <td

                                    onClick={ () => supplierLink(supplier) }
                                    className='supplier-table-name'>
                                    { supplier.logo
                                        ? <img
                                            onMouseEnter={ () => setHovId(supplier.id) }
                                            onMouseLeave={ () => setHovId(0) }
                                            alt='logo fournisseur' src={ supplier.logo } />
                                        : <img
                                            onMouseEnter={ () => setHovId(supplier.id) }
                                            onMouseLeave={ () => setHovId(0) }
                                            alt='logo fournisseur' src={ logo } /> }
                                    { supplier.name }
                                    { hovId === supplier.id &&
                                        <td className='prevOnHoov '><img alt='preview' src={ supplier.logo } /></td> }
                                </td>
                                <td onClick={ () => supplierLink(supplier) } className='supplier-table-ref'>
                                    { supplier.reference }
                                </td>

                                { supplier.name == 'COMMUN' ? (
                                    <td className='supplier-table-action'></td>
                                ) : (
                                    <td className='supplier-table-action'>
                                        <img onClick={ () => history.push(`/suppliers/${ supplier.reference }`) }
                                             alt='logo editer' src={ edit } />
                                        <img
                                            onClick={ () => {
                                                setSupplierName(supplier.name);
                                                setOpenM(true);
                                                setSupplierReference(supplier.reference);
                                            } }
                                            alt='logo poubelle'
                                            src={ trashRed }
                                        />
                                    </td>
                                ) }
                            </tr>
                        );
                    }) }
                </tbody>
            </table>
        </>
    );
};
export default SuppliersTable;
