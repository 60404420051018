import { useEffect, useState } from 'react';
import Textfield from '../textfield/textfield';
import MainPaper from '../paper/main-paper';
import Layout from '../layout/layout';
import ColorCombo from '../color-combo/color-combo';
import {
    createNewsCategory,
    NewsCategoryCreationState,
    resetCreateNews,
} from '../../services/reducers/news/news-category-creation.slice';
import { setToasterError, setToasterVisible } from '../../services/reducers/toaster-reducer/toaster.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import { useHistory } from 'react-router-dom';
import { Button } from '../button/button';
import './create-category-form.scss';

const CreateCategoryForm = () => {
    const dispatch = useDispatch();

    const history = useHistory();
    const createNewsCategoryResponse = useSelector<RootState, NewsCategoryCreationState>((state) => state.createCategory);

    const [color, setColor] = useState<string>('');
    const [newsCategory, setNewsCategory] = useState<string>('');
    const [disable, setDisable] = useState(false);
    const [validate, setValidate] = useState(true);

    useEffect(() => {
        if (color.length < 1 || newsCategory.length < 1) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [color, newsCategory]);
    useEffect(() => {
        const { payload } = createNewsCategoryResponse;
        const error = payload.errors;
        const content = payload.content;
        if (error.length > 0) {
            setValidate(false);
            dispatch(setToasterError({ message: payload.errors[0].message, title: payload.errors[0].code }));
        } else if (content !== null && error.length === 0) {
            setNewsCategory('');
            dispatch(resetCreateNews());
            history.push('/home/news-categories');
        }
    }, [createNewsCategoryResponse]);

    const handleCreateCategory = () => {
        dispatch(createNewsCategory({ color: color, name: newsCategory }));
    };
    return (
        <div className="main-cat">
            <Layout validate={validate} title="Créer une catégorie" labelBack="Quitter la création d'une catégorie ?">
                <MainPaper>
                    <div className="main-create-category-form">
                        <div className="create-category-form">
                            <Textfield name={newsCategory} value={newsCategory} onChange={setNewsCategory} label="NOM DE LA CATÉGORIE" />
                            <div className="select-color-container">
                                <label>COULEUR DE LA CATÉGORIE</label>
                                <ColorCombo setCombo={setColor} />
                            </div>
                        </div>
                        <div className="create-category-button">
                            {disable ? <Button backgroundColor="grey" label="Créer une catégorie" /> : <Button label="Créer une catégorie" onClick={handleCreateCategory} />}
                        </div>
                    </div>
                </MainPaper>
            </Layout>
        </div>
    );
};

export default CreateCategoryForm;

/*

 */
