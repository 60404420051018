import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import langageService from '../../../api/services/langage/langage.service';

// ==== get Langage by key ===
export type GetLangageState = {
    isLoading: boolean;
    payload: { lang: string; jsonUrl: string; warnings: []; errors: [] };
    error: any;
};

const initialGetLangageState: GetLangageState = {
    isLoading: false,
    payload: { lang: '', jsonUrl: '', warnings: [], errors: [] },
    error: {},
};

export const getLangage = createAsyncThunk('/langage', async () => {
    const result = await langageService.getLangage();
    return result;
});

export const getLangageSlice = createSlice({
    name: 'langageSlice',
    initialState: initialGetLangageState,
    reducers: {
        resetLangage: (state) => {
            state = initialGetLangageState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLangage.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getLangage.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, error: '' };
            return state;
        });
        builder.addCase(getLangage.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// ==== save Langage with key ===

export type SaveLangageState = {
    isLoading: boolean;
    isCreated: boolean;
    payload: { lang: string; jsonUrl: string; warnings: []; errors: [] };
};

const initialSaveLangageState: SaveLangageState = {
    isLoading: false,
    isCreated: false,
    payload: { lang: '', jsonUrl: '', warnings: [], errors: [] },
};

export const saveLangage = createAsyncThunk('save/langage', async ({ lang, url }: { lang: string; url: string }) => {
    const result = await langageService.saveLangage(lang, url);
    return result;
});

export const saveLangageSlice = createSlice({
    name: 'createRoleSlice',
    initialState: initialSaveLangageState,
    reducers: {
        resetSaveLangage: (state) => {
            state = initialSaveLangageState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(saveLangage.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(saveLangage.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, isCreated: true };
            return state;
        });
        builder.addCase(saveLangage.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
