import { Vector2, Wrapping } from 'three';

export default class TransformData {
    private m_txOffset: Vector2;
    private m_txTiling: Vector2;
    private m_txWrapMode: Wrapping;
    private m_txRotation: number;
    private m_txRotationCenter: Vector2;

    constructor(p_txOffset: Vector2, p_txTiling: Vector2, p_txWrapMode: Wrapping, p_txRotation: number, p_txRotationCenter: Vector2) {
        this.m_txOffset = p_txOffset;
        this.m_txTiling = p_txTiling;
        this.m_txWrapMode = p_txWrapMode;
        this.m_txRotation = p_txRotation;
        this.m_txRotationCenter = p_txRotationCenter;
    }

    get TxOffset(): Vector2 {
        return this.m_txOffset;
    }

    set TxOffset(value: Vector2) {
        this.m_txOffset = value;
    }

    get TxTiling(): Vector2 {
        return this.m_txTiling;
    }

    set TxTiling(value: Vector2) {
        this.m_txTiling = value;
    }

    get TxWrapMode(): Wrapping {
        return this.m_txWrapMode;
    }

    set TxWrapMode(value: Wrapping) {
        this.m_txWrapMode = value;
    }

    get TxRotation(): number {
        return this.m_txRotation;
    }

    set TxRotation(value: number) {
        this.m_txRotation = value;
    }

    get TxRotationCenter(): Vector2 {
        return this.m_txRotationCenter;
    }

    set TxRotationCenter(value: Vector2) {
        this.m_txRotationCenter = value;
    }
}
