import React, { useEffect, useState } from 'react';
import './simple-product-3d-table.scss';
import {
    deleteProductModels3d,
    getProductModels3d,
    Model3dListState,
    Model3dState,
    resetCreateProductModels3d,
    resetUpdateProductModels3dMappings,
    updateProductModels3dMappings,
} from '../../../../../services/reducers/product/3DModels/3DModels.reducers';
import { Mapping, Part, Product, ProductModel3d } from '../../../../../domain/domain';
import replace from '../../../../../asset/remplacer.svg';
import trashRed from '../../../../../asset/poubelle-red.svg';
import ArrowDown from '../../../../../asset/Arrow-down.svg';
import SimpleModal from '../../../../../uicomponents/modal/simple-modal/simple-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../domain/Params/params-interface';
import { getAllGroups, GroupListState } from '../../../../../services/reducers/mapping-zoning/group-mapping.reducer';
import { RootState } from '../../../../../services/root-reducer';
import { initialMapping } from '../../../../../pages/supplier/modeles/mappings-zoning/mappings';
import CreateTexture from '../../../../modals/texture/create/create-texture';


type SimpleProduct3dTableProps = {
    product: Product;
    productModel3d: Model3dListState;
    selectedPart: Part | undefined
};
const SimpleProduct3dTable = (props: SimpleProduct3dTableProps) => {
    const dispatch = useDispatch();
    const { collectionReference } = useParams<Params>();

    const SimpleProductTableHead = ['MODÈLES 3D', 'MAPPING', 'ACTIONS'];
    const [openDeleteM, setOpenDeleteM] = useState(false);
    const [selectedModel3d, setSelectedModel3d] = useState<ProductModel3d>();
    const [mappingList, setMappingList] = useState<Mapping[]>([]);
    const [selectedMapping, setSelectedMapping] = useState<{ mId: number[], m3dRef: string } | undefined>(undefined);
    const groups = useSelector<RootState, GroupListState>(state => state.getAllGroups);
    const [assoMapping, setAssoMapping] = useState<Mapping>(initialMapping);
    const [openCrushModel3D, setOpenCrushModel3D] = useState(false);
    const [s, setS] = useState(false);

    const updateMappingIdsRes = useSelector<RootState, Model3dState>((state) => state.updateProduct3dMappings);
    const delete3dRes = useSelector<RootState, Model3dState>((state) => state.deleteProduct3dModel);
    const updateUrlRes = useSelector<RootState, Model3dState>(state => state.updateProduct3dUrl);

    useEffect(() => {
        const m3dList = props.productModel3d.payload.content;
        collectionReference && dispatch(getAllGroups(collectionReference));
        if (m3dList[0]) {
            m3dList[0].mappings.length > 0 && setAssoMapping(m3dList[0].mappings[0]);
        }
    }, [props.productModel3d]);
    useEffect(() => {
        const gr = groups.payload.content;
        let msList: Mapping[] = [];
        if (gr && groups.payload.errors.length === 0) {
            gr.forEach((g) =>
                g.mappings.forEach((m) => msList.push(m)));
        }
        setMappingList(msList);
    }, [groups]);
    useEffect(() => {
        selectedMapping && dispatch(updateProductModels3dMappings({
            modelRef: selectedMapping.m3dRef,
            form: {
                mappingIdSet: selectedMapping.mId,
                modelUrl: '',
                partReference: '',
                anchorPointReference: '',
            },
        }));
    }, [selectedMapping]);
    useEffect(() => {
        if (updateMappingIdsRes.payload.content !== null && updateMappingIdsRes.payload.content.name.length > 0 && updateMappingIdsRes.payload.errors.length === 0) {
            setS(false);
            props.selectedPart && dispatch(getProductModels3d(props.selectedPart.reference));
            dispatch(resetUpdateProductModels3dMappings());
        }
    }, [updateMappingIdsRes]);
    useEffect(() => {
        if (delete3dRes.payload.content && delete3dRes.payload.errors.length === 0) {
            props.selectedPart && dispatch(getProductModels3d(props.selectedPart.reference));
            setOpenDeleteM(false);
            dispatch(resetCreateProductModels3d());
        }
    }, [delete3dRes]);
    useEffect(() => {
        if (updateUrlRes.payload.content.id > 0 && updateUrlRes.payload.errors.length === 0) {
            props.selectedPart && dispatch(getProductModels3d(props.selectedPart.reference));
        }
    }, [updateUrlRes]);

    return (
        <table className='simple-product-3d-table'>
            { openDeleteM && (
                <SimpleModal
                    icon={ trashRed }
                    title='Supprimer un modèle 3D'
                    info='Cette action supprimera le modèle 3d ainsi que toutes ces dépendences'
                    closeOnclick={ () => setOpenDeleteM(false) }
                    actionOnclick={ () => selectedModel3d && dispatch(deleteProductModels3d(selectedModel3d.reference)) }
                    deleteOrValide={ true }
                    closeLabel='Annuler'
                    actionLabel='Supprimer'
                />
            ) }
            {
                openCrushModel3D &&
                <CreateTexture
                    setOpenTextureModal={ setOpenCrushModel3D }
                    setUploadPercent={() => {}}
                    model3d={ true }
                    part={ props.selectedPart }
                    selectedModel3DRef={ selectedModel3d && selectedModel3d.reference }
                    isCrushModel3D={ openCrushModel3D } />
            }
            <thead onClick={ () => setS(false) }>
            <tr className='simple'>
                { SimpleProductTableHead.map((el: string, i: number) => {
                    return <th key={ i }>{ el } </th>;
                }) }
            </tr>
            </thead>
            <tbody>
            { props.productModel3d.payload.content.map((model3d: ProductModel3d) => {
                return (
                    <tr className='simple' key={ model3d.id }>
                        <td onClick={ () => setS(false) }>{ model3d.name.substring(model3d.name.indexOf('-') + 1) }</td>
                        <td className='combo'>
                            { s ?
                                <select
                                    defaultValue={ assoMapping.id }
                                    onChange={ (m) => setSelectedMapping({
                                        mId: [parseInt(m.target.value)],
                                        m3dRef: model3d.reference,
                                    }) }>
                                    <option>Mappings</option>
                                    {
                                        mappingList.map((m) => {
                                            return (
                                                <option value={ m.id } key={ m.id }>{ m.name }</option>
                                            );
                                        })
                                    }
                                </select>
                                : <div className='cb-name' onClick={ () => setS(true) }>
                                    { props.productModel3d.payload.content[0].mappings.length === 0
                                        ? <>Associer un mapping <img alt='ouvrir' src={ ArrowDown } /></>
                                        : <>{ assoMapping.name } < img alt='ouvrir' src={ ArrowDown } /></>
                                    }
                                </div>
                            }
                        </td>
                        <td onClick={ () => setS(false) } className='simple-action'>
                            <img
                                onClick={ () => {
                                    setOpenCrushModel3D(true);
                                    setSelectedModel3d(model3d);
                                } }
                                alt='logo poubelle'
                                src={ replace }
                            />
                            <img
                                onClick={ () => {
                                    setOpenDeleteM(true);
                                    setSelectedModel3d(model3d);
                                } }
                                alt='logo poubelle'
                                src={ trashRed }
                            />
                        </td>
                    </tr>
                );
            }) }
            </tbody>
        </table>
    );
};

export default SimpleProduct3dTable;
