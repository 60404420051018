import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";
import { Engine } from "@lutithree/build/Engine";
import { NewFrameEvent } from "@lutithree/build/Modules/Core/GameLoop/Events/NewFrameEvent";
import { LastFrameEvent } from "@lutithree/build/Modules/Core/GameLoop/Events/LastFrameEvent";
import { CanvasDomHandler } from "@lutithree/build/Handlers/CanvasDomHandler";
import { FrameHandler } from "@lutithree/build/Handlers/FrameHandler";
import { BeforeRenderingEvent } from "@lutithree/build/Modules/WebGL/Rendering/Events/BeforeRenderingEvent";
import { ResourcesHandler } from "@lutithree/build/Handlers/ResourcesHandler";
import { RemoveResouceEvent } from "@lutithree/build/Modules/WebGL/Resources/Events/RemoveResouceEvent";
import { WindowResizeEvent } from "@lutithree/build/Modules/WebGL/Rendering/Events/WindowResizeEvent";
import { WindowResizeHandler } from "@lutithree/build/Handlers/WindowResizeHandler";
import { CanvasDomChangedEvent } from "@lutithree/build/Modules/WebGL/Rendering/Events/CanvasDomChangedEvent";
import { RenderHandler } from "@lutithree/build/Handlers/RenderHandler";
import { RenderMode } from "@lutithree/build/Modules/WebGL/Rendering/RenderingStrategies/RenderMode";
import {LoadingFailHandler} from "./Objects/AssetAssembly/Handlers/LoadingFailHandler";
import ResourceLoadingFailEvent from "./Objects/AssetAssembly/Events/ResourceLoadingFailEvent";
import TSBasicNotifierService from "./Notifier/TSBasicNotifierService";

export default class BasicStudioHandlers {

    private readonly m_engine: Engine;
    
    private readonly m_handlers:  Map<string, IHandler<IEvent>>;

    private readonly m_notifier: TSBasicNotifierService;

    public constructor(p_engine: Engine, p_notifier: TSBasicNotifierService) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_notifier == null) throw new Error('NullReferenceException : p_notifier is null or undefined');

        this.m_engine = p_engine;
        this.m_notifier = p_notifier;
        this.m_handlers = new Map<string, IHandler<IEvent>>();
        this.BuildHandlersMap(this.m_engine);
    }

    public get Handlers(): ReadonlyMap<string, IHandler<IEvent>>{
        return this.m_handlers;
    }

    public InitEventManagerWithHandlers(): void {
        let domHandler = this.m_handlers.get('domHandler');
        if(domHandler){
            this.m_engine.Modules.EventManager.Suscribe(CanvasDomChangedEvent, domHandler);
        } else console.warn('There is no domHandler key in RoomStudioMapHandlers');

        let resizeHandler = this.m_handlers.get('resizeHandler');
        if(resizeHandler){
            this.m_engine.Modules.EventManager.Suscribe(WindowResizeEvent, resizeHandler);
        } else console.warn('There is no resizeHandler key in RoomStudioMapHandlers');

        let resourcesRemovedHandler = this.m_handlers.get('resourcesRemovedHandler');
        if(resourcesRemovedHandler){
            this.m_engine.Modules.EventManager.Suscribe(RemoveResouceEvent, resourcesRemovedHandler);
        } else console.warn('There is no resourcesRemovedHandler key in RoomStudioMapHandlers');

        let renderHandler = this.m_handlers.get('renderHandler');
        if(renderHandler){
            this.m_engine.Modules.EventManager.Suscribe(BeforeRenderingEvent, renderHandler);
        } else console.warn('There is no renderHandler key in RoomStudioMapHandlers');

        let frameHandler = this.m_handlers.get('frameHandler');
        if(frameHandler){
            this.m_engine.Modules.EventManager.Suscribe(NewFrameEvent, frameHandler);
            this.m_engine.Modules.EventManager.Suscribe(LastFrameEvent, frameHandler);
        } else console.warn('There is no frameHandler key in RoomStudioMapHandlers');

        let assetLoadingFailHandler = this.m_handlers.get('assetLoadingFailHandler');
        if (assetLoadingFailHandler) {
            this.m_engine.Modules.EventManager.Suscribe(ResourceLoadingFailEvent, assetLoadingFailHandler);
        } else console.warn('There is no assetLoadingFailHandler key in RoomStudioMapHandlers');
    }

    private BuildHandlersMap(p_engine: Engine): void {

        // ENGINE Handlers --------------------------------------------
        let domHandler = new CanvasDomHandler(p_engine);
        this.m_handlers.set('domHandler',domHandler);
        let resizeHandler = new WindowResizeHandler(p_engine);
        this.m_handlers.set('resizeHandler',resizeHandler);
        let resourcesRemovedHandler = new ResourcesHandler(p_engine);
        this.m_handlers.set('resourcesRemovedHandler',resourcesRemovedHandler);
        let renderHandler = new RenderHandler(p_engine);
        this.m_handlers.set('renderHandler',renderHandler);
        let frameHandler = new FrameHandler(p_engine, RenderMode.Default, RenderMode.HighQuality);
        this.m_handlers.set('frameHandler',frameHandler);
        let assetLoadingFailHandler = new LoadingFailHandler(p_engine, this.m_notifier);
        this.m_handlers.set('assetLoadingFailHandler', assetLoadingFailHandler);
    }
}