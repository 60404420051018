const CREATE_COLORIS = (finishId: number) => `v1/suppliers/finishes/${ finishId }/coloris/create`;
const GET_COLORIS = (coloryId: number) => `v1/suppliers/coloris/${ coloryId }`;
const GET_ALL_COLORIS = (finishId: number) => `v1/suppliers/finishes/${ finishId }/coloris`;
const UPDATE_COLORIS = (colorisId: number) => `v1/suppliers/finishes/coloris/update/${ colorisId }`;
const UPDATE_COLORIS_DTO = `v1/suppliers/finishes/coloris/update/`;
const DELETE_COLORIS = (finishId: number, colorisId: number) => `v1/suppliers/finishes/${ finishId }/coloris/delete/${ colorisId }`;
const DUPLICATE_COLORIS = (finishId: number, colorisId: number) => `v1/suppliers/finishes/${ finishId }/coloris/duplicate/${ colorisId }`;
const COLORIS_FROM_COMMON_SUPPLIER_ID = (supplierReference: string) => `v1/suppliers/coloris/common/${ supplierReference }`;

export {
    CREATE_COLORIS,
    GET_ALL_COLORIS,
    GET_COLORIS,
    DELETE_COLORIS,
    DUPLICATE_COLORIS,
    UPDATE_COLORIS,
    UPDATE_COLORIS_DTO,
    COLORIS_FROM_COMMON_SUPPLIER_ID,
};