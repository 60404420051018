import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {ColorType} from "../ParamTypes/ColorType";
import {TextureType} from "../ParamTypes/TextureType";
import {BooleanType} from "../ParamTypes/BooleanType";
import {ButtonType} from "../ParamTypes/ButtonType";
import {InspectorType} from "../ParamTypes/InspectorType";

export class Albedo {

    public m_color: InspectorType;
    public m_mapShow: InspectorType;
    public m_albedoMap: InspectorType;
    public m_albedoFlipY: InspectorType;
    public m_albedoClear: InspectorType;
    
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        //Albedo
        this.m_uiFolder = this.m_uiFolder = m_tab.pages[0].addFolder({title: 'Albedo (RGB) [no texture]', expanded: true});
        this.m_color = new ColorType(m_matParams, "color", this.m_uiFolder);
        this.m_mapShow = new BooleanType("Show map on mesh", m_matParams, "mapShowOnMesh", this.m_uiFolder);
        this.m_albedoMap = new TextureType(MapType.albedoMap, m_matParams, "map", this.m_uiFolder);
        this.m_albedoFlipY = new BooleanType("Flip Y", m_matParams, "mapFlipY", this.m_uiFolder);
        this.m_albedoClear = new ButtonType("Clear map", m_matParams, "", this.m_uiFolder);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_color.InitType();
        this.m_mapShow.InitType();
        this.m_albedoMap.InitType();
        this.m_albedoFlipY.InitType();
        this.m_albedoClear.InitType();
    }
}