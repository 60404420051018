import CreateUserForm from '../../uicomponents/create-user-form/create-user';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../services/root-reducer';
import MdfApiResponse from '../../domain/common/generic';
import { User } from '../../domain/domain';
import { createUser } from '../../services/reducers/create-user/create-user.slice';
import React, { useEffect, useState } from 'react';
import Layout from '../../uicomponents/layout/layout';
import Loader from '../../uicomponents/loader/loader';
import Perm from '../../components/perm/perm';


type UserSelector = {
    isLoading: boolean;
    isRejected: boolean;
    payload: MdfApiResponse<User | null>;
};

function CreateUser() {
    const dispatch = useDispatch();
    const history = useHistory();
    const createdUser = useSelector<RootState, UserSelector>((state) => state.createUser);
    const [email, setEmail] = useState('');
    const [isEmailError, setIsEmailError] = useState<boolean>(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');

    const [serverErr, setServerErr] = useState('');

    useEffect(() => {
        if (createdUser.payload.content !== null && createdUser.payload.errors.length === 0 && !createdUser.isLoading) {
            history.push('/home/users');
        } else if (createdUser.payload.errors.length !== 0) {
            const emailErr = () => {
                if (createdUser.payload.errors[0].code === 'U0003') {
                    return 'Cette adresse email est invalide ';
                } else return '';
            };
            setIsEmailError(true);
            setEmailErrorMessage(emailErr);
            setServerErr('Une erreur inconnue est survenue');
        }
    }, [createdUser]);

    return (
        <Perm permission='ADMINISTRATION'>
            <Layout title='Créer un utilisateur' labelBack="Quitter la création d'utilisateur ? "
                    info='Les modifications ne seront pas sauvegardées '>
                <Loader isLoading={ createdUser.isLoading } />
                <CreateUserForm
                    error={ serverErr }
                    setError={ setServerErr }
                    email={ email }
                    setEmail={ setEmail }
                    isEmailError={ isEmailError }
                    setIsEmailError={ setIsEmailError }
                    setEmailErrorMessage={ setEmailErrorMessage }
                    emailErrorMessage={ emailErrorMessage }
                    onSubmit={ (form) => {
                        dispatch(createUser(form));
                    } }
                />
            </Layout>
        </Perm>
    );
}

export default CreateUser;
