import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from '../../../api/services/user/user.service';
import { CreateUserType } from '../../../uicomponents/create-user-form/create-user.type';

const initialState = {
    isLoading: false,
    isRejected: false,
    errorMessage: '',
    successMessage: '',
    payload: { content: null, errors: [], warnings: [] },
};

export const createUser = createAsyncThunk('news/create', async (createUserForm: CreateUserType, thunkApi) => {
    const response = await UserService.createUser(createUserForm);
    return response;
});

const createUserSlice = createSlice({
    name: 'createUserSlice',
    initialState: initialState,
    reducers: {
        resetCreateUser: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createUser.fulfilled, (state, { payload }) => {
            state = {
                payload,
                isLoading: false,
                isRejected: false,
                errorMessage: '',
                successMessage: 'requête réussie avec succès',
            };
            return state;
        });
        builder.addCase(createUser.rejected, (state) => {
            state.isLoading = false;
            state.isRejected = true;
            state.successMessage = '';
            state.errorMessage = 'Erreur lors de la requête';
            state.isLoading = false;
        });
    },
});

export const { resetCreateUser } = createUserSlice.actions;
export default createUserSlice.reducer;
