import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import React, { useEffect, useState } from 'react';
import Loader from '../../uicomponents/loader/loader';
import { Button } from '../../uicomponents/button/button';
import { getNewsCategoryList, NewsCategoryState } from '../../services/reducers/news/news-cateogory-list.slice';
import NewsCategoriesTable from '../../uicomponents/news-category-table/news-categories-table';
import { addModal, removeModal } from '../../services/reducers/modal/modal.slice';
import Modal, { ModalSize } from '../../uicomponents/modal/modal';
import { NewsCategoryCreationState } from '../../services/reducers/news/news-category-creation.slice';
import {
    deleteNewsCategory,
    DeleteNewsCategoryState, resetNewsCategoryDeletion,
} from '../../services/reducers/delete-news-category/delete-news-category.slice';
import { setToasterError, setToasterVisible } from '../../services/reducers/toaster-reducer/toaster.slice';
import MainPaper from '../../uicomponents/paper/main-paper';
import Layout from '../../uicomponents/layout/layout';
import { useHistory } from 'react-router-dom';
import trash from '../../asset/trash-popup.png';


const NewsCategoryList = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const confirmationModal = 'confirmation-modal-open';
    const [categoryToBeDeleted, setCategoryToBeDeleted] = useState(-1);
    const {
        payload: { content },
        isLoading,
    } = useSelector<RootState, NewsCategoryState>((state) => state.categoryList);

    const createNewsCategoryResponse = useSelector<RootState, NewsCategoryCreationState>((state) => state.createCategory);
    const deleteNewsCategoryResponse = useSelector<RootState, DeleteNewsCategoryState>((state) => state.categoryDeletion);

    useEffect(() => {
        dispatch(getNewsCategoryList());
    }, []);

    useEffect(() => {
        const { payload } = createNewsCategoryResponse;
        if (payload.errors.length === 0 && payload.content === null) {
        } else if (payload.errors.length > 0) {
            dispatch(setToasterError({ message: payload.errors[0].message, title: payload.errors[0].code }));
        }
    }, [createNewsCategoryResponse]);

    useEffect(() => {
        const { successMessage, isRejected, errorMessage } = deleteNewsCategoryResponse;
        if (isRejected) {
            dispatch(setToasterError({ message: errorMessage, title: 'Erreur de suppression' }));
        } else if (successMessage) {
            dispatch(setToasterVisible({ message: successMessage, title: 'Catégorie supprimée' }));
            dispatch(getNewsCategoryList());
            dispatch(resetNewsCategoryDeletion());
        }
    }, [deleteNewsCategoryResponse]);

    const onDeleteNewsCategories = () => {
        dispatch(addModal(confirmationModal));
    };

    const handleDeleteCategory = (newsCategoryId: number) => {
        dispatch(deleteNewsCategory({ newsCategoryId: newsCategoryId }));
        dispatch(removeModal(confirmationModal));
    };

    return (
        <Layout
            title='Catégories'
            labelBack=''
            option={
                <Button
                    label='+ Créer une catégorie'
                    primary
                    onClick={ () => {
                        history.push('/create-news-category');
                    } }
                />
            }
        >
            <MainPaper>
                <Modal
                    validateLabel='Confirmer'
                    onClick={ () => {
                        handleDeleteCategory(categoryToBeDeleted);
                    } }
                    id={ confirmationModal }
                    title='Supprimer cette catégorie ?'
                    closeLabel='Annuler'
                    size={ ModalSize.LARGE }
                    colorValidateButton='#E95D5D'
                    icon={ trash }
                >
                    <p>Vous êtes sur le point de supprimer une catégorie. Si des news sont liées à cette catégorie alors
                        ces news seront catégorisées dans la catégorie Général</p>
                </Modal>

                <Loader isLoading={ isLoading } />
                <div />
                <div>
                    <NewsCategoriesTable newsCategories={ content } onDeleteNewsCategory={ onDeleteNewsCategories }
                                         setCategoryToBeDeleted={ setCategoryToBeDeleted } />
                </div>
            </MainPaper>
        </Layout>
    );
};

export default NewsCategoryList;
