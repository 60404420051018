import apiClient from '../../../api-client';
import {
    CREATE_3D_CELL,
    DELETE_3D_CELL,
    GET_ALL_3D_CELL,
    GET_ALL_CELLS_BY_PART_REFERENCE,
    GET_ANCHOR_POINT,
    GET_CELL_MODEL_3D_INFO,
} from '../../../endpoints/product/3DModelsCell/3DModelsCell';
import { CellCreationForm } from '../../../../domain/domain';


const cells3dService = {
    get3dCellInfo: async (modelRef: string, partItemRef: string) => apiClient(GET_CELL_MODEL_3D_INFO(modelRef, partItemRef), undefined, undefined, 'GET'),
    delete3sCell: async (modelRef: string, partItemRef: string, tagId: number) => apiClient(DELETE_3D_CELL(modelRef, partItemRef, tagId), undefined, undefined, 'DELETE'),
    create3dCell: async (modelRef: string, partItemRef: string, tagIdList: CellCreationForm) => apiClient(CREATE_3D_CELL(modelRef, partItemRef), tagIdList, undefined, 'POST'),
    getAll3dCell: async () => apiClient(GET_ALL_3D_CELL(), undefined, undefined, 'GET'),
    getAllCellsByPartReference: async (partReference: string) => apiClient(GET_ALL_CELLS_BY_PART_REFERENCE(partReference), undefined, undefined, 'GET'),
    getAnchorPoint: async (model3dCellReference: string) => apiClient(GET_ANCHOR_POINT(model3dCellReference), undefined, undefined, 'GET'),

};

export default cells3dService;