import React, { useEffect, useState } from 'react';
import './supplier-table.scss';
import fakeLogo from '../../../asset/logo-mdf.jpeg';
import update from '../../../asset/Editer.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/root-reducer';
import { CreateFinishState, DeleteFinishState, getFinishes, GetFinishesState, UpdateFinishState } from '../../../services/reducers/suppliers/finish/finishes.slice';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Collection, Finish } from '../../../domain/domain';
import { Params } from '../../../domain/Params/params-interface';
import { CollectionListState, CollectionState, deleteCollection, getCollections, resetDeleteCollection } from '../../../services/reducers/suppliers/collection/collection.slice';
import SimpleModal from '../../../uicomponents/modal/simple-modal/simple-modal';

import trash from '../../../asset/poubelle.svg';
import trashRed from '../../../asset/poubelle-red.svg';

type SupplierTableProps = {
    tabName: string;
    setOpenDeleteFinitionModal: (openDeleteFinitionModal: boolean) => void;
    setFinishId: (finishId: number) => void;
};

const SupplierTable = (props: SupplierTableProps) => {
    const { supplierReference } = useParams<Params>();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const collectionHeaders = ['NOM', 'RÉFÉRENCE', 'ACTIONS'];
    const finitionHeaders = ['NOM', 'MATIÈRE', 'DESCRIPTION TECHNIQUE', 'ACTIONS'];
    const finishes = useSelector<RootState, GetFinishesState>((state) => state.getFinishes);
    const collections = useSelector<RootState, CollectionListState>((state) => state.getCollections);

    const createFinishRes = useSelector<RootState, CreateFinishState>((state) => state.createFinish);
    const deletFinishRes = useSelector<RootState, DeleteFinishState>((state) => state.deleteFinish);
    const updateFinishRes = useSelector<RootState, UpdateFinishState>((state) => state.updateFinish);

    const deleteCollectionRes = useSelector<RootState, CollectionState>((state) => state.deleteCollection);

    const [techHooverId, setTechHooverId] = useState(0);
    const [collectionReference, setCollectionReference] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [hovId, setHovId] = useState(0);
    const [collectionName, setCollectionName] = useState<string>('');

    useEffect(() => {
        supplierReference && dispatch(getCollections(supplierReference));
    }, []);
    useEffect(() => {
        if (createFinishRes.payload.content !== undefined && createFinishRes.payload.errors.length < 1) {
            supplierReference && dispatch(getFinishes(supplierReference));
        }
    }, [createFinishRes]);
    useEffect(() => {
        if (deletFinishRes.payload.content !== undefined && deletFinishRes.payload.errors.length < 1) {
            supplierReference && dispatch(getFinishes(supplierReference));
        }
    }, [deletFinishRes]);
    useEffect(() => {
        if (updateFinishRes.payload.content !== undefined && updateFinishRes.payload.errors.length < 1) {
            supplierReference && dispatch(getFinishes(supplierReference));
        }
    }, [updateFinishRes]);
    useEffect(() => {
        if (deleteCollectionRes.payload.content !== undefined && deleteCollectionRes.payload.errors.length < 1) {
            supplierReference && dispatch(getCollections(supplierReference));
        }
    }, [deleteCollectionRes]);

    const updateNavigate = (el: Finish) => {
        return history.push(`/supplier/${supplierReference}/finishes/${el.id}/textures`);
    };
    const returnHoover = (el: any, att: string, descId: number) => {
        if (el.id === descId && att.length > 20) {
            return <div className="buiss-hoover">{att}</div>;
        }
    };
    const checkTechNull = (el: any, att: string | null | undefined) => {
        if (att) {
            return (
                <div>
                    {returnHoover(el, att, techHooverId)}
                    {att.length > 20 ? `${att.substring(0, 20)}...` : att}
                </div>
            );
        } else {
            return <div />;
        }
    };

    return (
        <table className="supplier-table-main">
            {deleteModal && (
                <SimpleModal
                    confirmation={true}
                    confirmationName={collectionName}
                    icon={trash}
                    deleteOrValide={true}
                    closeLabel="Annuler"
                    actionLabel="Supprimer"
                    closeOnclick={() => setDeleteModal(false)}
                    actionOnclick={() => {
                        dispatch(deleteCollection(collectionReference));
                        setDeleteModal(false);
                        dispatch(resetDeleteCollection());
                    }}
                    title="Supprimer une Collection"
                    info="Cette action supprimera cette collection"
                />
            )}
            {location.pathname.includes('collections') && (
                <>
                    <thead className="collection-table-head">
                        <tr>
                            {collectionHeaders.map((header, i) => {
                                return <th key={i}>{header}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody className="collection-table-body">
                        {collections.payload.content &&
                            collections.payload.content.map((el: Collection, i) => {
                                return (
                                    <tr key={i}>
                                        <td
                                            className="ho"
                                            onMouseEnter={() => setHovId(el.id)}
                                            onMouseLeave={() => setHovId(0)}
                                            onClick={() => history.push(`/supplier/${supplierReference}/collections/${el.reference}/products`)}
                                        >
                                            <img alt="logo collection" src={el.logo.length < 1 ? fakeLogo : el.logo} />
                                            <span> {el.name}</span>
                                        </td>
                                        {hovId === el.id && (
                                            <td className="prevOnHoov ">
                                                <img alt="preview" src={el.logo.length < 1 ? fakeLogo : el.logo} />
                                            </td>
                                        )}
                                        <td onClick={() => history.push(`/supplier/${supplierReference}/collections/${el.reference}/products`)}>{el.reference}</td>
                                        <td>
                                            {!el.isSystem && <img onClick={() => history.push(`/supplier/${supplierReference}/collections/${el.reference}/update`)} alt="editer" src={update} />}
                                            {!el.isSystem && (
                                                <img
                                                    onClick={() => {
                                                        setCollectionReference(el.reference);
                                                        setDeleteModal(true);
                                                        setCollectionName(el.name);
                                                    }}
                                                    alt="poubelle"
                                                    src={trashRed}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </>
            )}
            {location.pathname.includes('finitions') && (
                <>
                    <thead className="finition-table-head">
                        <tr>
                            {finitionHeaders.map((header, i) => {
                                return <th key={i}>{header}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody className="finition-table-body">
                        {finishes.payload.content.map((el: Finish, i: number) => {
                            return (
                                <tr key={i}>
                                    <td onClick={() => updateNavigate(el)} className="finition-name">
                                        {el.name}
                                    </td>
                                    <td onClick={() => updateNavigate(el)} className="finition-tags">
                                        {el.tags.length > 0 &&
                                            el.tags.map((tag, idx) => {
                                                if (idx === 3) {
                                                    return (
                                                        <td key={tag.id}>
                                                            <div className="finition-tag">+{el.tags.length - 3}</div>
                                                        </td>
                                                    );
                                                } else if (idx > 3) {
                                                    return;
                                                }
                                                return (
                                                    <div key={tag.id} className="finition-tag">
                                                        {tag.name}
                                                    </div>
                                                );
                                            })}
                                    </td>
                                    <td
                                        onClick={() => updateNavigate(el)}
                                        className="finition-desc"
                                        onMouseEnter={() => {
                                            setTechHooverId(el.id);
                                        }}
                                        onMouseLeave={() => {
                                            setTechHooverId(0);
                                        }}
                                    >
                                        <pre> {checkTechNull(el, el.technicalDescription)}</pre>
                                    </td>
                                    <td>
                                        <>
                                            {el.name !== 'Parquet' && el.name !== 'Peinture' && (
                                                <img
                                                    onClick={() => {
                                                        props.setFinishId(el.id);
                                                        history.push(`/supplier/${supplierReference}/finishes/${el.id}/update`);
                                                    }}
                                                    alt="editer"
                                                    src={update}
                                                />
                                            )}
                                            {el.name !== 'Parquet' && el.name !== 'Peinture' && (
                                                <img
                                                    onClick={() => {
                                                        props.setOpenDeleteFinitionModal(true);
                                                        props.setFinishId(el.id);
                                                    }}
                                                    alt="poubelle"
                                                    src={trash}
                                                />
                                            )}
                                        </>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </>
            )}
        </table>
    );
};
export default SupplierTable;
