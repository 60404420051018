const CREATE_PRODUCT_3D_MODELS = () => `v1/part/3d-models`;
const GET_ALL_3D_MODELS_BY_PART = (partRef: string) => `v1/part/3d-models/${partRef}`;
const GET_ALL_3D_MODELS_BY_COLLECTION = (collectionReference: string) => `v1/part/3d-models/collection/${collectionReference}`;
const GET_ALL_3D_MODELS_BY_TAG = (partItemRef: string, tagRef: string) => `v1/model-3d-cell/part-item-reference/${partItemRef}/model-3d-cell-value-reference/${tagRef}/get-associated-3d-models`;
const GET_PRODUCT_3D_MODELS = (partRef: string) => `v1/part/3d-models/${partRef}`;
const DELETE_PRODUCT_3D_MODELS = (modelRef: string) => `v1/part/3d-models/${modelRef}/delete`;
const UPDATE_PRODUCT_3D_MAPPINGS = (modelRef: string) => `v1/part/3d-models/${modelRef}/update-mappings`;
const UPDATE_PRODUCT_3D_URL = (modelRef: string) => `v1/part/3d-models/${modelRef}/update-url`;
const SAVE_PRODUCT_3D = (productRef: string) => `v1/product/${productRef}/save-product-model-3d`;

export {
    CREATE_PRODUCT_3D_MODELS,
    GET_ALL_3D_MODELS_BY_PART,
    GET_ALL_3D_MODELS_BY_COLLECTION,
    GET_ALL_3D_MODELS_BY_TAG,
    GET_PRODUCT_3D_MODELS,
    DELETE_PRODUCT_3D_MODELS,
    UPDATE_PRODUCT_3D_MAPPINGS,
    SAVE_PRODUCT_3D,
    UPDATE_PRODUCT_3D_URL,
};
