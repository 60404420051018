import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MdfApiResponse from '../../../../domain/common/generic';
import { Model3DCellValue, PartItemThumbnailDto, Product } from '../../../../domain/domain';
import product3DThumbsService from '../../../../api/services/products/3DThumbnails/3dThumbnails.service';


export type PartItemThumbsDtoState = {
    isLoading: false;
    payload: MdfApiResponse<PartItemThumbnailDto>;
};
const initialPartItemDtoState: PartItemThumbsDtoState = {
    isLoading: false,
    payload: {
        content: {
            partName: '',
            partItemName: '',
            partReference: '',
            partItemReference: '',
            cellList: [],
            atomDisplayType: 'TEXT',
            option: false,
        },
        errors: [],
        warnings: [],
    },
};

export type PartItemThumbsDtoListState = {
    isLoading: false;
    payload: MdfApiResponse<PartItemThumbnailDto[]>;
};
const initialPartItemDtoListState: PartItemThumbsDtoListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export type Model3dCellValueState = {
    isLoading: false;
    payload: MdfApiResponse<Model3DCellValue>;
};
const initialModel3dCellValueState: Model3dCellValueState = {
    isLoading: false,
    payload: {
        content: {
            id: -1,
            version: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
            value: '',
            label: '',
            thumbnailUrl: '',
            partItemReference: '',
            partReference:'',
            partItemName: '',
            partName: '',
            reference: '',
        },
        errors: [],
        warnings: [],
    },
};

export type Model3dProductThumb = {
    isLoading: false;
    payload: MdfApiResponse<any>;
};

const initialModel3dProductThumb: Model3dProductThumb = {
    isLoading: false,
    payload: {
        content: {},
        errors: [],
        warnings: [],
    },
};

//<editor-fold desc="get-product-3DModels">
export const getProduct3DThumbsbyPart = createAsyncThunk('get/3D Thumbs by Part', async (partRef: string) => {
    const res = await product3DThumbsService.getCellThumbsByParts(partRef);
    return res;
});

export const getProduct3DThumbsbyPartSlice = createSlice({
    name: 'getProduct3DThumbsbyPart',
    initialState: initialPartItemDtoListState,
    reducers: {
        resetGetProduct3dThumbsbyPart: (state) => {
            state = initialPartItemDtoListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProduct3DThumbsbyPart.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProduct3DThumbsbyPart.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getProduct3DThumbsbyPart.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetProduct3dThumbsbyPart } = getProduct3DThumbsbyPartSlice.actions;
//</editor-fold>

//<editor-fold desc="update-product-3DModels">
export const updateProduct3DThumbs = createAsyncThunk('update/productThumb', async (data: { productRef: string; product: Product }) => {
    const res = await product3DThumbsService.updateThumbnail(data.productRef, data.product);
    return res;
});

export const updateProduct3DThumbSlice = createSlice({
    name: 'updateProduct3DThumbs',
    initialState: initialModel3dProductThumb,
    reducers: {
        resetUpdateProductThumb: (state) => {
            state = initialModel3dProductThumb;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateProduct3DThumbs.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateProduct3DThumbs.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateProduct3DThumbs.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateProductThumb } = updateProduct3DThumbSlice.actions;
//</editor-fold>

//<editor-fold desc="update-cell-thumb">
export const uploadCellThumb = createAsyncThunk('upload/cell-thumb', async (data: { cellId: number; file: FormData }) => {
    const res = await product3DThumbsService.uploadCellThumbs(data.cellId, data.file);
    return res;
});

export const uploadCellThumbSlice = createSlice({
    name: 'uploadCellThumb',
    initialState: initialModel3dCellValueState,
    reducers: {
        resetUploadCellThumb: (state) => {
            state = initialModel3dCellValueState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProduct3DThumbsbyPart.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProduct3DThumbsbyPart.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getProduct3DThumbsbyPart.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUploadCellThumb } = uploadCellThumbSlice.actions;
//</editor-fold>
