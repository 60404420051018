import MdfApiResponse from '../../../domain/common/generic';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Mapping, Zone } from '../../../domain/domain';
import zoningService from '../../../api/services/mapping-zoning/zoning.service';


export type ZoningState = {
    isLoading: boolean;
    payload: MdfApiResponse<Mapping | undefined>;
};
const initialZoningState: ZoningState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

export type ZoningState2 = {
    isLoading: boolean;
    payload: MdfApiResponse<Zone | undefined>;
};
const initialZoningState2: ZoningState2 = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

export type ZoningState3 = {
    isLoading: boolean;
    payload: Zone | undefined;
    selectedIds: string[];
};
const initialZoningState3: ZoningState3 = {
    isLoading: false,
    payload: undefined,
    selectedIds: [],
};


//<editor-fold desc="create-zoning">
export const createZoning = createAsyncThunk('create/zoning', async (data: { mappingId: number, zoneName: string }) => {
    const res = await zoningService.createZoning(data.mappingId, data.zoneName);
    return res;
});

export const createZoningSlice = createSlice({
    name: 'createZoning',
    initialState: initialZoningState,
    reducers: {
        resetCreateZoning: (state) => {
            state = initialZoningState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createZoning.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createZoning.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createZoning.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateZoning } = createZoningSlice.actions;
//</editor-fold>

//<editor-fold desc="update-zoning">
export const updateZoning = createAsyncThunk('update/zoning', async (data: { mappingId: number, zoneUuid: string, zone: Zone }) => {
    const res = await zoningService.updateZoning(data.mappingId, data.zoneUuid, data.zone);
    return res;
});

export const updateZoning2Slice = createSlice({
    name: 'update-zoning2',
    initialState: initialZoningState3,
    reducers: {
        UpdateZoningApp: (state, action: PayloadAction<Zone | undefined>) => {
            state.payload = action.payload;
        },
        UpdateZoningIDs: (state, action: PayloadAction<string[]>) => {
            state.selectedIds = action.payload;
        },
    },
});
export const { UpdateZoningApp, UpdateZoningIDs } = updateZoning2Slice.actions;

export const updateZoningSlice = createSlice({
    name: 'update-zoning',
    initialState: initialZoningState2,
    reducers: {
        resetUpdateZoning: (state) => {
            state = initialZoningState2;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateZoning.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateZoning.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateZoning.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateZoning } = updateZoningSlice.actions;
//</editor-fold>

//<editor-fold desc="delete-zoning">
export const deleteZoning = createAsyncThunk('delete/zoning', async (data: { groupId: number, mappingId: number, zoneUuid: string }) => {
    const res = await zoningService.deleteZoning(data.groupId, data.mappingId, data.zoneUuid);
    return res;
});

export const deleteZoningSlice = createSlice({
    name: 'delete-zoning',
    initialState: initialZoningState,
    reducers: {
        resetDeleteZoning: (state) => {
            state = initialZoningState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteZoning.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteZoning.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteZoning.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteZoning } = deleteZoningSlice.actions;
//</editor-fold>