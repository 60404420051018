import './ToggleSwitch.scss';

export type ToogleProps = {
    title: string;
    setIsToggled: (isToogled: boolean) => void;
    isToggled: boolean;
};

const ToggleSwitch = ({ title, setIsToggled, isToggled }: ToogleProps) => {
    const onToggle = () => setIsToggled(!isToggled);

    return (
        <div className="toogle-main">
            <label className="toggle-switch">
                <input type="checkbox" checked={isToggled} onChange={onToggle} />
                <span className="switch" />
            </label>
            <div className={isToggled ? 'title-focus' : 'title'}>{title}</div>
        </div>
    );
};

export default ToggleSwitch;
