import apiClient from '../../api-client';
import {
    ARCHIVE_NEWS,
    CREATE_CATEGORY,
    CREATE_NEWS,
    DELETE_NEWS,
    DELETE_NEWS_CATEGORY,
    GET_ALL_NEWS,
    GET_FILTER_NEWS,
    GET_NEWS,
    GET_NEWS_CATEGORIES,
    GET_NEWS_STATUS_COUNT,
    UPDATE_NEWS,
} from '../../endpoints/news/news.endpoints';
import { CreateNewsType } from '../../../uicomponents/create-news-form/create-news.type';
import { NewsCriticality, NewsStatus } from '../../../domain/domain';

const NewsService = {
    getAllNews: async (criticality: NewsCriticality | undefined, status: NewsStatus | undefined, page: number | undefined) => {
        const response = await apiClient(GET_ALL_NEWS, undefined, { criticality, status, page }, 'GET');
        return response;
    },
    createNews: async (createNewsForm: CreateNewsType) => apiClient(CREATE_NEWS, createNewsForm, undefined, 'POST'),
    getNewsCategories: async () => apiClient(GET_NEWS_CATEGORIES, undefined, undefined, 'GET'),
    getFilterNews: async (searchValue: string) => apiClient(`${GET_FILTER_NEWS}${searchValue}`, undefined, undefined, 'GET'),
    updateNewsStatus: async (newsId: number, status: NewsStatus) => apiClient(`${ARCHIVE_NEWS}${newsId}`, undefined, { status }, 'PUT'),
    createNewsCategory: async (categoryName: string, color: string) => apiClient(CREATE_CATEGORY(categoryName, color), undefined, undefined, 'POST'),
    deleteNewsCategory: async (newsCategoryId: number) => apiClient(DELETE_NEWS_CATEGORY(newsCategoryId), undefined, undefined, 'DELETE'),
    getNewsStatusCount: async () => apiClient(GET_NEWS_STATUS_COUNT, undefined, undefined, 'GET'),
    getNewsToUpdate: async (newsId: number) => apiClient(GET_NEWS(newsId), undefined, undefined, 'GET'),
    updateNews: async (newsId: number, updateNewsForm: CreateNewsType) => apiClient(UPDATE_NEWS(newsId), updateNewsForm, undefined, 'PUT'),
    deleteNews: async (newsId: number) => apiClient(DELETE_NEWS(newsId), undefined, undefined, 'DELETE'),
};

export default NewsService;
