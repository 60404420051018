import './product-simple-thumb.scss';
import { Colori, Mapping, Product, Zone } from '../../../../../../../domain/domain';
import { useEffect, useState } from 'react';
import { getAllParts, PartListState } from '../../../../../../../services/reducers/product/part/part.reducers';
import {
    getProductModels3d,
    Model3dListState,
} from '../../../../../../../services/reducers/product/3DModels/3DModels.reducers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../services/root-reducer';
import ProductSimpleStudio from './product-simple-studio/product-simple-studio';
import axios from 'axios';
import {
    getProductById,
    ProductState,
    resetSaveProductThumbInfo,
    saveProductThumbInfo,
} from '../../../../../../../services/reducers/product/product.reducers';
import CreateProductToast from '../../../../../../../uicomponents/toaster/create-product-toast/create-product-toast';
import { getConfig } from '../../../../../../../api/config';
import { Model3dProductThumb } from '../../../../../../../services/reducers/product/3DThumbnails/3dThumbnails.reducers';
import configuredProductService
    from '../../../../../../../api/services/products/configured-product/configured-product.service';
import cells3dValuesService from '../../../../../../../api/services/products/3DCells/3DCellsValues.service';
import { buildRoomObjectFromProductTree } from '../../../../../../../utils/room-object-factory-v2';
import Asset3D
    from "../../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D";
import Model3dData
    from "../../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Model3dData";
import AssignmentData
    from "../../../../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/AssignmentData";


type ProductSimpleThumbProps = {
    product: Product;
};

const ProductSimpleThumb = ({ product }: ProductSimpleThumbProps) => {
    const dispatch = useDispatch();
    const parts = useSelector<RootState, PartListState>((state) => state.getAllParts);
    const model3d = useSelector<RootState, Model3dListState>((state) => state.getProduct3dModel);
    const saveProductThumbRes = useSelector<RootState, ProductState>((state) => state.saveProductThumb);
    const updateProduct3DThumbRes = useSelector<RootState, Model3dProductThumb>((state) => state.updateProduct3DThumb);

    const [model3dObject, setModel3dObject] = useState<Asset3D>(new Asset3D());
    const [openGen, setOpenGen] = useState(false);
    const [thumbUrl, setThumbUrl] = useState('');
    const [colorisList, setColorisList] = useState<Colori[]>([]);
    const [selectMapping, setSelectedMapping] = useState<Mapping | undefined>(undefined);
    const [mappingObject, setMappingObject] = useState<Asset3D>(new Asset3D());
    const [created, setCreated] = useState(false);

    //#region useffect
    useEffect(() => {
        dispatch(getAllParts(product.reference));
    }, [product]);
    useEffect(() => {
        if (parts.payload.content.length > 0 && parts.payload.errors.length === 0) {
            dispatch(getProductModels3d(parts.payload.content[0].reference));
        }
    }, [parts]);
    useEffect(() => {
        if (model3d.payload.content[0]) {
            if (model3d.payload.content[0].modelUrl !== undefined) {
                let datas = Object.assign(new Model3dData(), {
                    urls:
                        [model3d.payload.content[0].modelUrl],
                });
                let model3dBuf = Object.assign(new Asset3D(), {
                    ...model3d.payload.content[0],
                    dataModelVersion: '1.0.0',
                    refOfPart: model3d.payload.content[0].partReference,
                    type: 'Model3d',
                    datas,
                });
                setModel3dObject(model3dBuf);
            }
        }

        if (model3d.payload.content[0]) {
            if (model3d.payload.content[0].mappings) {
                model3d.payload.content[0].mappings[0] && model3d.payload.content[0].mappings[0].zoningMetadata.zoneList.map((zone: Zone) => {
                    setSelectedMapping(model3d.payload.content[0].mappings[0]);
                    axios
                        .get(`${ getConfig().urlHostApi }/v1/suppliers/coloris/${ zone.defaultColoriId }`, {
                            headers: {
                                Authorization: `Bearer ${ localStorage.getItem('mdf-jwt') }`,
                                'Content-Type': 'multipart/form-data',
                            },
                        })
                        .then((res) => {
                            setColorisList((prevState) => [...prevState, res.data.content]);
                        });
                });
            }
        }
    }, [model3d]);
    useEffect(() => {
        const findColorUrl = (zone: Zone) => {
            let colorUrl = '';
            colorisList.forEach((color: Colori) => {
                if (color.id === zone.defaultColoriId) {
                    colorUrl = color.modelUrl;
                }
            });
            return colorUrl;
        };
        if (colorisList.length === selectMapping?.zoningMetadata.zoneList.length) {
            let mapping3dBuf = Object.assign(new Asset3D(), {
                refOfPart: 'ref of part',
                type: 'MaterialAssignment',
                datas: selectMapping.zoningMetadata.zoneList.map((el: Zone) => {
                    const newAssignmentData = new AssignmentData();
                    newAssignmentData.LoadData(el.availableIds, [findColorUrl(el)]);
                    return newAssignmentData;
                }),
            });
            setMappingObject(mapping3dBuf);
        }
    }, [colorisList]);


    const addMetadata = () => {
        cells3dValuesService.get3dCellValuesTree(product.reference)
            .then((res) => {
                const roomObject = buildRoomObjectFromProductTree(res.content, () => {
                });
                configuredProductService.addMetadataToConfiguredProduct(product.reference, roomObject);
            });
    };

    useEffect(() => {
        if (updateProduct3DThumbRes.payload.content && updateProduct3DThumbRes.payload.errors.length === 0) {
            setOpenGen(false);
            dispatch(getProductById(product.reference));
        }

    }, [updateProduct3DThumbRes]);
    useEffect(() => {
        if (saveProductThumbRes.payload.content && saveProductThumbRes.payload.errors.length === 0) {
            setCreated(true);
            setTimeout(() => {
                setCreated(false);
            }, 3000);
            dispatch(resetSaveProductThumbInfo());

        }
    }, [saveProductThumbRes]);
    //#endregion

    return (
        <div className='products-simple-thumb'>
            { product && openGen && (
                <ProductSimpleStudio
                    product={ product }
                    url={ thumbUrl }
                    mappingObject={ mappingObject }
                    setOpenModal={ setOpenGen }
                    model3dObject={ model3dObject } />
            ) }
            { created && <CreateProductToast /> }
            <div className='top'>
                <span>VIGNETTE DU PRODUIT DANS LE CATALOGUE</span>
            </div>
            <div className='body'>
                <div className='thumb-prev'>
                    { product.thumbnailUrl && product.thumbnailUrl
                        ? <img className='prev-image' alt='vignette' src={ product.thumbnailUrl } />
                        : <div className='prev'>Aucune vignette</div> }
                </div>
            </div>
            <div className='bottom'>
                <button className='btn-gen' onClick={ () => setOpenGen(true) }>Générer la vignette</button>

                { product.thumbnailUrl.length > 0 ? (
                    <button
                        className='enable'
                        onClick={ () => {
                            dispatch(saveProductThumbInfo(product.reference));
                            addMetadata();
                        } }
                    >
                        Sauvegarder
                    </button>
                ) : (
                    <button className='disable'>Sauvegarder</button>
                ) }
            </div>
        </div>
    );
};
export default ProductSimpleThumb;

//
