import React from 'react';
import { NewsStatus, StatusType } from '../../domain/domain';
import { Button } from '../button/button';

import './filter-block.scss';
import FilterButton from '../filter-button/filter-button';
import FilterUserButton from '../filter-button/filter-user-button';
import { useTranslation } from 'react-i18next';

export type FilterBlockProps = {
    pubOnclick?: () => void;
    proOnclick?: () => void;
    drafOnclick?: () => void;
    archOnclick?: () => void;
    onClickfunc: () => void;
    clearFilter: () => void;
    itemsTotalCount?: number;
    selectedTab: string | undefined;
    findCountByStatus?: (status: NewsStatus) => number;
    findUserCountByStatus?: (status: StatusType) => number;
    display?: false;
    news: boolean;
    label: string;
};
const FilterBlock = ({
    label,
    display,
    news,
    drafOnclick,
    clearFilter,
    pubOnclick,
    proOnclick,
    archOnclick,
    selectedTab,
    onClickfunc,
    itemsTotalCount,
    findCountByStatus,
    findUserCountByStatus,
}: FilterBlockProps) => {
    const { t } = useTranslation();
    return (
        <div className="filter-block-container">
            {news ? (
                <div className="filter-block-main">
                    <FilterButton
                        clearFilter={clearFilter}
                        label="Publiées"
                        selectedTab={selectedTab}
                        tab="PUBLISHED"
                        status="PUBLISHED"
                        findCountByStatus={findCountByStatus}
                        filterOnClick={pubOnclick}
                    />

                    <FilterButton
                        clearFilter={clearFilter}
                        label="Programmées"
                        tab="SCHEDULED"
                        selectedTab={selectedTab}
                        status="SCHEDULED"
                        findCountByStatus={findCountByStatus}
                        filterOnClick={proOnclick}
                    />

                    <FilterButton label="Brouillons" tab="DRAFT" selectedTab={selectedTab} clearFilter={clearFilter} status="DRAFT" findCountByStatus={findCountByStatus} filterOnClick={drafOnclick} />

                    <FilterButton
                        label="Archivées"
                        clearFilter={clearFilter}
                        tab="ARCHIVED"
                        selectedTab={selectedTab}
                        status="ARCHIVED"
                        findCountByStatus={findCountByStatus}
                        filterOnClick={archOnclick}
                    />
                </div>
            ) : (
                <div className="filter-block-main">
                    {display !== false && (
                        <>
                            <FilterUserButton
                                label={t('UI.filterblock.L.active')}
                                findUserCountByStatus={findUserCountByStatus}
                                status="ACTIVE"
                                clearFilter={clearFilter}
                                selectedTab={selectedTab}
                                tab="ACTIVE"
                                filterOnClick={pubOnclick}
                            />
                            <FilterUserButton
                                label={t('UI.filterblock.L.never-connected')}
                                findUserCountByStatus={findUserCountByStatus}
                                status="NEVER_CONNECTED"
                                clearFilter={clearFilter}
                                selectedTab={selectedTab}
                                tab="NEVER_CONNECTED"
                                filterOnClick={proOnclick}
                            />
                        </>
                    )}
                </div>
            )}
            <Button label={label} primary onClick={onClickfunc} />
        </div>
    );
};

export default FilterBlock;
