import './toogle.scss';


type ToogleProps = {
    setBool: (bool: boolean) => void
    checked: boolean
};
const Toogle = ({ setBool, checked }: ToogleProps) => {
    return (
        <label className='switchi'>
            <input checked={ checked } onChange={ (e) => setBool(e.target.checked) } type='checkbox' />
            <span className='slider' />
        </label>
    );
};
export default Toogle;