import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../services/root-reducer';
import MdfApiResponse from '../../domain/common/generic';
import { useEffect, useState } from 'react';
import { News, NewsCategory } from '../../domain/domain';
import { getNewsCategoryList } from '../../services/reducers/news/news-cateogory-list.slice';
import { setToasterError } from '../../services/reducers/toaster-reducer/toaster.slice';
import MdfPreconditions from '../../utils/MdfPreconditions';
import UpdateNewsForm from '../../uicomponents/update-news-form/update-news-form';
import { getNewsToUpdate, NewsUpdateState, resetUpdateNews, updateNews } from '../../services/reducers/news/news.slice';

type NewsSelector = {
    isRejected: boolean;
    isLoading: boolean;
    payload: MdfApiResponse<News | null>;
};

type CategorySelector = {
    isLoading: boolean;
    payload: MdfApiResponse<NewsCategory[]>;
};

function UpdateNews() {
    const dispatch = useDispatch();
    const history = useHistory();
    const categoryListResponse = useSelector<RootState, CategorySelector>((state) => state.categoryList);
    const [categoryList, setCategoryList] = useState<NewsCategory[]>([]);
    const param = useParams<{ id: string }>();
    const newsToUpdate = useSelector<RootState, NewsUpdateState>((state) => state.getNewsToUpdate);
    const updatedNews = useSelector<RootState, NewsSelector>((state) => state.updateNews);

    useEffect(() => {
        dispatch(getNewsCategoryList());
        dispatch(getNewsToUpdate(parseInt(param.id, 10)));
    }, []);

    useEffect(() => {
        MdfPreconditions.continueIfNoErrors(categoryListResponse.payload, [], dispatch, () => {
            setCategoryList(categoryListResponse.payload.content);
        });
    }, [categoryListResponse]);

    useEffect(() => {
        if (updatedNews.payload.content !== null && updatedNews.payload.errors.length === 0 && !updatedNews.isLoading) {
            dispatch(resetUpdateNews());
            history.push('/home/news');
        } else if (updatedNews.payload.errors.length !== 0) {
            dispatch(setToasterError({
                message: updatedNews.payload.errors[0].message,
                title: updatedNews.payload.errors[0].code,
            }));
        }
    }, [updatedNews]);

    return (
        <div>
            {newsToUpdate.payload.content && newsToUpdate.payload.content.id === parseInt(param.id, 10) &&
            (<UpdateNewsForm
                onSubmit={(form) => {
                    dispatch(updateNews({ newsId: parseInt(param.id, 10), updateNewsForm: form }));
                }}
                reloadCategories={() => {
                    dispatch(getNewsCategoryList());
                }}
                categoryList={categoryList}
                newsData={newsToUpdate.payload.content}
            />)}
            {updatedNews.payload.errors.length > 0 && (
                <div className='mt-3'>
                    {updatedNews.payload.errors.map((err) => (
                        <h4 key={err.code}>{err.message}</h4>
                    ))}
                </div>
            )}
        </div>
    );
}

export default UpdateNews;
