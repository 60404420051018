const CREATE_SUPPLIER = 'v1/suppliers/create';
const GET_SUPPLIERS = 'v1/suppliers/';
const GET_SUPPLIER = (reference: string) => `v1/suppliers/${ reference }`;
const GET_SUPPLIER_TEXTURES_AND_COLORIS = (supplierReference: string) => `v1/suppliers/${supplierReference}/texture-and-coloris`;
const UPDATE_SUPPLIER = (reference: string) => `v1/suppliers/update/${ reference }`;
const DELETE_SUPPLIER = (reference: string) => `v1/suppliers/delete/${ reference }`;
const GET_COMMON_SUPPLIER = 'v1/suppliers/common';
const FIND_TAGS_BY_FINISH_ID = (id: number) => `v1/suppliers/finishes/${ id }/tags`;

export {
    GET_SUPPLIERS,
    CREATE_SUPPLIER,
    GET_SUPPLIER,
    UPDATE_SUPPLIER,
    DELETE_SUPPLIER,
    GET_COMMON_SUPPLIER,
    GET_SUPPLIER_TEXTURES_AND_COLORIS,
    FIND_TAGS_BY_FINISH_ID,
};
