import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import React, { useState } from 'react';
import { Tag } from '../../domain/domain';

import './style.scss';

type TagSelectorProps = {
    onChange: (tagIdList: number[]) => void;
    onCreate: (tagName: string) => void;
    tagList: Tag[];
    selectedTags: number[];
    placeholder: string;
    tagListEmpty?: boolean;
    scrollOption?: boolean;
};

function TagSelector({
                         onChange,
                         tagList,
                         selectedTags,
                         onCreate,
                         placeholder,
                         tagListEmpty,
                         scrollOption,
                     }: TagSelectorProps) {
    const [errorMessage, setErrorMessage] = useState<string[]>([]);

    const checkTagFormat = (newValue: OnChangeValue<TagOption, true>) => {
        setErrorMessage([]);
        let isError = false;
        newValue.map((v) => {
            if (!(v.label.toUpperCase().match('^[A-Za-z0-9_]+$') && v.label.length >= 2)) {
                isError = true;
                setErrorMessage(['Un nom de tag doit comporter uniquement des caractères alphanumériques ou "_".', 'Nombre de caractères minimum : 2.']);
            }
        });
        return isError;
    };

    const handleChange = (newValue: OnChangeValue<TagOption, true>, actionMeta: ActionMeta<TagOption>) => {
        const formatIsIncorrect = checkTagFormat(newValue);
        if (actionMeta.action === 'select-option' || actionMeta.action === 'remove-value' || actionMeta.action === 'clear') {
            const idList = newValue.map((element) => element.value);
            onChange(idList);
        } else if (actionMeta.action === 'create-option') {
            if (!formatIsIncorrect) {
                onCreate(newValue[newValue.length - 1].label);
            }
        }
    };

    type TagOption = {
        readonly label: string;
        readonly value: number;
    };

    return (
        <div className='in'>
            <div>
                <CreatableSelect
                    styles={scrollOption ?
                        {
                            valueContainer: (base) => ({
                                ...base,
                                overflowX: 'auto',
                                flexWrap: 'unset',
                                maxWidth: 'fit-content',
                                outline: 'outline-width 0',
                            }),
                            multiValue: (base) => ({
                                ...base,
                                flex: '2 0 auto',
                            }),
                        }
                        : {}}
                    isMulti
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={tagList
                        .filter((tagItem) => selectedTags.find((toFind) => toFind === tagItem.id) !== undefined)
                        .map((tag) => {
                            return { label: tag.name, value: tag.id };
                        })}
                    options={tagList.map((tag) => {
                        return { label: tag.name, value: tag.id };
                    })}
                />
                {errorMessage.length !== 0 &&
                    errorMessage.map((msg, index) => {
                        return (
                            <div key={index}>
                                {msg}
                                <br />
                            </div>
                        );
                    })}
            </div>
            {/*{tagListEmpty && <span> Ce champ est obligatoire </span>}*/}
        </div>
    );
}

export default TagSelector;
