import './sub-section.scss';
import { Catalog, Section, SectionWithSubSections, SubSection, TreeCatalog } from '../../../../domain/domain';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteSubSection,
    getAllSubSections, resetCreateSubSection,
    resetDeleteSubSection, resetUpdateSubSection,
    SubSectionState,
} from '../../../../services/reducers/catalog/sub-section.slice';
import { RootState } from '../../../../services/root-reducer';
import edit from '../../../../asset/Editer.svg';
import trash from '../../../../asset/poubelle-red.svg';
import plus from '../../../../asset/plus-circle.svg';
import CreateSubSection from '../../../../components/modals/catalog/create-sub-section/create-sub-section';
import SimpleModal from '../../../../uicomponents/modal/simple-modal/simple-modal';
import { getTreeCatalog } from '../../../../services/reducers/catalog/catalog.slice';


type SubSectionProps = {
    section: SectionWithSubSections | undefined
    treeCalalog: TreeCatalog
    catalog: Catalog
};
const SubSectionPanel = ({ section, treeCalalog, catalog }: SubSectionProps) => {
    const dispatch = useDispatch();
    const [createModal, setCreateModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [upSubSection, setUpSubSection] = useState<SubSection | undefined>(undefined);
    const [delSubSection, setDelSubSection] = useState<SubSection | undefined>(undefined);

    const createSubSectionRes = useSelector<RootState, SubSectionState>((state) => state.createSubSection);
    const updateSubSectionRes = useSelector<RootState, SubSectionState>((state) => state.updateSubSection);
    const deleteSubSectionRes = useSelector<RootState, SubSectionState>(state => state.deleteSubSectionn);

    useEffect(() => {
        if (createSubSectionRes.payload.content && createSubSectionRes.payload.content.id !== 0 && createSubSectionRes.payload.errors.length === 0) {
            catalog.id !== 0 && dispatch(getTreeCatalog(catalog.id));
            dispatch(resetCreateSubSection());
        } else if (createSubSectionRes.payload.errors.length > 0) {
        }
    }, [createSubSectionRes]);
    useEffect(() => {
        if (updateSubSectionRes.payload.content && updateSubSectionRes.payload.content.id !== 0 && updateSubSectionRes.payload.errors.length === 0) {
            catalog.id !== 0 && dispatch(getTreeCatalog(catalog.id));
            dispatch(resetUpdateSubSection());
        }
    }, [updateSubSectionRes]);
    useEffect(() => {
        if (deleteSubSectionRes.success && deleteSubSectionRes.payload.errors.length === 0) {
            catalog.id !== 0 && dispatch(getTreeCatalog(catalog.id));
            dispatch(resetDeleteSubSection());
            setDeleteModal(false);
        }
    }, [deleteSubSectionRes]);

    return (
        <div className='sub-section-main'>
            { createModal && <CreateSubSection
                treeCalalog={ treeCalalog }
                section={ section }
                setSubSection={ setUpSubSection }
                subSection={ upSubSection }
                setOpenModal={ setCreateModal } /> }
            { deleteModal &&
                <SimpleModal
                    icon={ trash }
                    title='Supprimer une sous rubrique ?'
                    info='Cette action supprimera la sous rubrique'
                    closeOnclick={ () => setDeleteModal(false) }
                    actionOnclick={ () => {
                        delSubSection && dispatch(deleteSubSection(delSubSection.id));
                    } }
                    deleteOrValide={ true }
                    closeLabel='Annuler'
                    actionLabel='Supprimer' /> }
            <div className='sub-section-title'>
                <div>SOUS RUBRIQUES</div>
                <div onClick={ () => {
                    setCreateModal(true);
                } } className='create-sub-section'>
                    <img alt='ajouter une rubrique' src={ plus } />
                    <span>Créer une sous rubrique</span>
                </div>
            </div>
            <div className='sub-section-body'>
                { section?.subSections.map((subSection: SubSection) => {
                    return (
                        <div key={ subSection.id }
                             className='sub-section'>
                            <div className='sub-section-left'>
                                <span>{ subSection.name }</span>
                            </div>
                            <div className='sub-section-right'>
                                { section?.id !== null &&
                                    <>
                                        <img
                                            onClick={ () => {
                                                setUpSubSection(subSection);
                                                setCreateModal(true);
                                            } }
                                            alt='editer' src={ edit } />
                                        <img onClick={ () => {
                                            setDelSubSection(subSection);
                                            setDeleteModal(true);
                                        } } alt='supprimer rubrique' src={ trash } />
                                    </> }
                            </div>
                        </div>
                    );
                }) }
            </div>
            <div className='sub-section-foo' />
        </div>
    );
};
export default SubSectionPanel;