import './create-composable-preset.scss';
import close from '../../../../../../../../asset/CloseWindow.svg';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CompoundedModule } from '../../../../../../../../domain/domain';
import { useDispatch, useSelector } from 'react-redux';
import {
    CompoundedPresetState,
    createCompoundedPreset,
    findCompoundedPresetsByCompositionId,
    resetCreateCompoundedPreset,
} from '../../../../../../../../services/reducers/product/compounded-preset-reducer/compounded.reducer';
import { RootState } from '../../../../../../../../services/root-reducer';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../../../../domain/Params/params-interface';
import { returnadvice } from '../../../../../../../../components/modals/mapping-zoning/mapping/create-mapping';

type CreateComposablePresetProps = {
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    setOpenPresetDetail: Dispatch<SetStateAction<boolean>>;
    setCurrentPreset: Dispatch<SetStateAction<CompoundedModule | undefined>>;
};
const CreateComposablePreset = (props: CreateComposablePresetProps) => {
    const dispatch = useDispatch();
    const { productReference } = useParams<Params>();

    const [presetName, setPresetName] = useState('');

    const createCompoundedPresetRes = useSelector<RootState, CompoundedPresetState>((state) => state.createCompoundedPreset);

    useEffect(() => {
        if (createCompoundedPresetRes.payload.content.id != 0 && createCompoundedPresetRes.payload.errors.length === 0) {
            props.setCurrentPreset(createCompoundedPresetRes.payload.content);
            props.setOpenModal(false);
            props.setOpenPresetDetail(true);
            productReference && dispatch(findCompoundedPresetsByCompositionId(productReference));
            dispatch(resetCreateCompoundedPreset());
        }
    }, [createCompoundedPresetRes]);

    const onCreatePreset = () => {
        productReference &&
        dispatch(
            createCompoundedPreset({
                comositionRef: productReference,
                compoundedPresetName: presetName,
            }),
        );
    };
    return (
        <div className='create-compo-preset-main'>
            <div className='create-compo-preset-paper'>
                <div className='create-compo-preset-top'>
                    <div>Nommer votre preset</div>
                    <img onClick={() => props.setOpenModal(false)} alt='fermer la modale' src={close} />
                </div>
                <div className='create-compo-preset-body'>
                    <label>Nom</label>
                    <input onChange={(e) => setPresetName(e.target.value)} type='text' />
                    <div style={{ marginTop: 10, marginBottom: 10 }}> {returnadvice(presetName, 24)}</div>
                </div>
                <div className='create-compo-preset-btn'>
                    <button onClick={() => props.setOpenModal(false)}>Annuler</button>
                    <button onClick={onCreatePreset}>Sauvegarder</button>
                </div>
            </div>
        </div>
    );
};
export default CreateComposablePreset;
