export const enum MapType {
    albedoMap,
    specularMap,
    aoMap,
    roughnessMap,
    metalnessMap,
    normalMap,
    emissiveMap,
    clearCoatMap,
    clearCoatNormalMap,
    clearCoatRoughnessMap,
    sheenColorMap,
    transmissionMap,
    thicknessMap,
}
