import MdfApiResponse from '../../../domain/common/generic';
import { Mapping, ZoningMetadata } from '../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import mappingService from '../../../api/services/mapping-zoning/mapping.services';


export type MappingState = {
    isLoading: boolean;
    payload: MdfApiResponse<Mapping | undefined>;
};

const initialMappingState: MappingState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

export type MappingListState = {
    isLoading: boolean;
    payload: MdfApiResponse<Mapping[]>;
};

const initialMappingListState: MappingListState | undefined = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

//<editor-fold desc="create-mapping">
export const createMapping = createAsyncThunk('create/mapping', async (data: { groupId: number, data: { name: string, zoningMetadata: ZoningMetadata } }) => {
    const res = await mappingService.createMapping(data.groupId, data.data);
    return res;
});

export const createMappingSlice = createSlice({
    name: 'createMapping',
    initialState: initialMappingState,
    reducers: {
        resetCreateMapping: (state) => {
            state = initialMappingState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createMapping.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createMapping.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createMapping.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateMapping } = createMappingSlice.actions;
//</editor-fold>

//<editor-fold desc="update-mapping">
export const updateMapping = createAsyncThunk('update/mapping', async (data: { mappingId: number, groupId: number, data: { name: string, zoningMetadata: ZoningMetadata } }) => {
    const res = await mappingService.updateMapping(data.groupId, data.mappingId, data.data);
    return res;
});

export const updateMappingSlice = createSlice({
    name: 'updateMapping',
    initialState: initialMappingState,
    reducers: {
        resetUpdateMapping: (state) => {
            state = initialMappingState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateMapping.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateMapping.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateMapping.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateMapping } = updateMappingSlice.actions;
//</editor-fold>

//<editor-fold desc="delete-mapping">
export const deleteMapping = createAsyncThunk('delete/mapping', async (data: { groupId: number, mappingId: number }) => {
    const res = await mappingService.deleteMapping(data.groupId, data.mappingId);
    return res;
});

export const deleteMappingSlice = createSlice({
    name: 'deleteMapping',
    initialState: initialMappingState,
    reducers: {
        resetDeleteMapping: (state) => {
            state = initialMappingState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteMapping.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteMapping.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteMapping.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteMapping } = deleteMappingSlice.actions;

//</editor-fold>

//<editor-fold desc="get-all-mappings">
export const getAllMappings = createAsyncThunk('get/all-mapping', async () => {
    const res = await mappingService.getAllMappings();
    return res;
});

export const getAllMappingsSlice = createSlice({
    name: 'getAllMappings',
    initialState: initialMappingListState,
    reducers: {
        resetGetAllMappings: (state) => {
            state = initialMappingListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteMapping.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteMapping.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteMapping.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetAllMappings } = getAllMappingsSlice.actions;

//</editor-fold>


//<editor-fold desc="get-all-mappings">
export const getMapping = createAsyncThunk('get/mapping', async (mappingId: number) => {
    const res = await mappingService.getMapping(mappingId);
    return res;
});

export const getMappingSlice = createSlice({
    name: 'getMapping',
    initialState: initialMappingState,
    reducers: {
        resetGetMapping: (state) => {
            state = initialMappingState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMapping.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getMapping.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getMapping.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetMapping } = getMappingSlice.actions;

//</editor-fold>