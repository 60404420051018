import React from 'react';
import { StatusType } from '../../domain/domain';
import './filter-button.scss';

export type FilterUserButtonProps = {
    filterOnClick?: () => void;
    findUserCountByStatus?: (status: StatusType) => number;
    status: StatusType;
    label: string;
    selectedTab: string | undefined;
    clearFilter: () => void;
    tab: string;
};

const FilterUserButton = ({ tab, label, clearFilter, filterOnClick, findUserCountByStatus, status, selectedTab }: FilterUserButtonProps) => {
    return (
        <>
            {findUserCountByStatus && findUserCountByStatus(status) > 0 ? (
                <div className="filter-button-container">
                    <div className={selectedTab === tab ? 'selected' : 'unselected'} onClick={filterOnClick}>
                        {label}({findUserCountByStatus?.(status)})
                        {selectedTab === tab && (
                            <div
                                className="clear-filter"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    clearFilter();
                                }}
                            >
                                ×
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="filter-button-empty">
                    <p>
                        {label}({findUserCountByStatus?.(status)})
                    </p>
                </div>
            )}
        </>
    );
};
export default FilterUserButton;
