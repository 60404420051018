import apiClient from '../../api-client';
import ProductData from '../../../domain/common/product';
import { Room } from '../../../domain/domain';
import {
    CREATE_PROJECT, DELETE_PROJECT, GET_PROJECT_BY_CODE, GET_PROJECTS, GET_USER_PROJECTS,
    IMPORT_PROJECT,
    UPDATE_PROJECT_METADATA,
    UPDATE_PROJECT_NAME, UPDATE_PROJECT_ROOM, UPDATE_USER_PROJECTS,
} from '../../endpoints/projet/projet';

const projectsService = {
    createProject: async (projectName: string) => apiClient(CREATE_PROJECT, { projectName }, undefined, 'POST'),
    importProject: async (userId: number, code: string) => apiClient(IMPORT_PROJECT(userId, code), undefined, undefined, 'POST'),
    getProjects: async () => apiClient(GET_PROJECTS, undefined, undefined, 'GET'),
    getUserProjects: async (userId: number) => apiClient(GET_USER_PROJECTS(userId), undefined, undefined, 'GET'),
    getProjectsByCode: async (code: string | undefined) => apiClient(GET_PROJECT_BY_CODE(code), undefined, undefined, 'GET'),
    updateProject: async (projectId: number, project: ProductData) => apiClient(UPDATE_USER_PROJECTS(projectId), project, undefined, 'PUT'),
    updateProjectName: async (projectId: number | undefined, projectName: string) => apiClient(UPDATE_PROJECT_NAME(projectId, projectName), undefined, undefined, 'PUT'),
    deleteUserProjects: async (projectId: number, userId: number) => apiClient(DELETE_PROJECT(projectId, userId), undefined, undefined, 'POST'),
    saveProjectRoom: async (projectId: number, roomUuid: string, room: Room) => apiClient(UPDATE_PROJECT_ROOM(projectId, roomUuid), room, undefined, 'POST'),
    saveProjectMetadata: async (projectId: number, roomUuid: string, data: String) => apiClient(UPDATE_PROJECT_METADATA(projectId, roomUuid), data, undefined, 'POST'),
};
export default projectsService;
