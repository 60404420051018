import apiClient from '../../api-client';
import {
    CREATE_ROLE,
    DELETE_ROLE,
    EDIT_ROLE,
    GET_ROLE,
    GET_ROLES,
    GET_USED_ROLES,
} from '../../endpoints/role/role.endpoint';
import { CreateRoleForm } from '../../../domain/domain';

const RolesService = {
    getALLRoles: async () => apiClient(GET_ROLES, undefined, undefined, 'GET'),
    getALLUsedAndUnusedRoles: async () => apiClient(GET_USED_ROLES, undefined, undefined, 'GET'),
    createRole: async (form: CreateRoleForm) => apiClient(CREATE_ROLE, form, undefined, 'POST'),
    deleteRole: async (roleId: number) => apiClient(DELETE_ROLE(roleId), undefined, undefined, 'DELETE'),
    editRole: async (roleId: number, form: CreateRoleForm) => apiClient(EDIT_ROLE(roleId), form, undefined, 'PUT'),
    getRole: async (roleId: number) => apiClient(GET_ROLE(roleId), undefined, undefined, 'GET'),
};

export default RolesService;