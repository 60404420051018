import apiClient from '../../../api-client';
import {
    CREATE_PRODUCT_3D_MODELS,
    DELETE_PRODUCT_3D_MODELS,
    GET_ALL_3D_MODELS_BY_COLLECTION,
    GET_ALL_3D_MODELS_BY_PART,
    GET_ALL_3D_MODELS_BY_TAG,
    GET_PRODUCT_3D_MODELS,
    SAVE_PRODUCT_3D,
    UPDATE_PRODUCT_3D_MAPPINGS, UPDATE_PRODUCT_3D_URL,
} from '../../../endpoints/product/3DModels/3DModels';
import { ProductModel3dCreationForm } from '../../../../domain/domain';

const models3dService = {
    createProduct3dModels: async (form: ProductModel3dCreationForm) => apiClient(CREATE_PRODUCT_3D_MODELS(), form, undefined, 'POST'),
    getAllModels3dByParts: async (partRef: string) => apiClient(GET_ALL_3D_MODELS_BY_PART(partRef), undefined, undefined, 'GET'),
    getAllModels3dByCollection: async (collectionReference: string) => apiClient(GET_ALL_3D_MODELS_BY_COLLECTION(collectionReference), undefined, undefined, 'GET'),
    getAllModels3dByTag: async (partItemRef: string, tagRef: string) => apiClient(GET_ALL_3D_MODELS_BY_TAG(partItemRef, tagRef), undefined, undefined, 'GET'),
    getProduct3DModels: async (partRef: string) => apiClient(GET_PRODUCT_3D_MODELS(partRef), undefined, undefined, 'GET'),
    deleteProduct3DModels: async (modelRef: string) => apiClient(DELETE_PRODUCT_3D_MODELS(modelRef), undefined, undefined, 'DELETE'),
    updateProduct3DModelMappings: async (modelRef: string, form: ProductModel3dCreationForm) => apiClient(UPDATE_PRODUCT_3D_MAPPINGS(modelRef), form, undefined, 'PUT'),
    updateProduct3DModelUrl: async (modelRef: string, url: string) => apiClient(UPDATE_PRODUCT_3D_URL(modelRef), url, undefined, 'PUT'),
    saveProduct3DModels: async (productRef: string) => apiClient(SAVE_PRODUCT_3D(productRef), undefined, undefined, 'post'),
};

export default models3dService;
