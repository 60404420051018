import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {ColorType} from "../ParamTypes/ColorType";
import {TextureType} from "../ParamTypes/TextureType";
import {BooleanType} from "../ParamTypes/BooleanType";
import {ButtonType} from "../ParamTypes/ButtonType";
import {InspectorType} from "../ParamTypes/InspectorType";
import {SliderData, SliderType} from "../ParamTypes/SliderType";

export class Iridescence {

    public m_iridescence: InspectorType;
    public m_iridescenceIOR: InspectorType;
    
    public m_uiFolder: any;

    constructor(m_matParams: MaterialEditorParameters, m_uiFolders: any, m_tab: any) {
        this.m_uiFolder = this.m_uiFolder = m_tab.pages[1].addFolder({title: 'Iridescence', expanded: false});
        this.m_iridescence = new SliderType(new SliderData(0.0,1.0,0.01), m_matParams, "iridescence", this.m_uiFolder);
        this.m_iridescenceIOR = new SliderType(new SliderData(1.0,2.14,0.01), m_matParams, "iridescenceIOR", this.m_uiFolder);

        this.InitTypes();
    }

    private InitTypes() {
        this.m_iridescence.InitType();
        this.m_iridescenceIOR.InitType();
    }
}