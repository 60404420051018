import apiClient from '../../api-client';
import { Configuration } from '../../../domain/domain';
import {
    GET_ALL_COLLECTION, GET_DEFAULT_CONFIGURATION,
    GET_REFERENTIAL_SET_BY_CONFIGURATION,
    GET_REFERENTIEL_SET,
} from '../../endpoints/configurateur';

const ConfigurateurService = {
    getConfigurateurCollection: async () => apiClient(GET_ALL_COLLECTION, undefined, undefined, 'GET'),
    getReferentielSet: async () => apiClient(GET_REFERENTIEL_SET, undefined, undefined, 'GET'),
    getReferentialSetByConfiguration: async (form: Configuration) => apiClient(GET_REFERENTIAL_SET_BY_CONFIGURATION, form, undefined, 'POST'),
    getDefaultConfiguration: async () => apiClient(GET_DEFAULT_CONFIGURATION, undefined, undefined, 'GET'),
};
export default ConfigurateurService;
