import './product-presets-detail.scss';
import React, { useEffect, useState } from 'react';
import { PresetTree, Product } from '../../../../../../domain/domain';
import { RootState } from '../../../../../../services/root-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getProductById, ProductState, resetSaveProductThumbInfo, saveProductThumbInfo } from '../../../../../../services/reducers/product/product.reducers';
import CreatePresetElement from '../../../../../../components/modals/product/preset/create-preset/create-preset';
import ProductPresetTable from '../../../../../../components/table/products-table/product-presset/product-preset-table';
import CreatePresetSecondStepV2 from '../../../../../../components/modals/product/preset/create-preset-second-step/create-preset/create-preset-second-step-V2';
import CreateProductToast from '../../../../../../uicomponents/toaster/create-product-toast/create-product-toast';
import plus from '../../../../../../asset/plus-circle.svg';
import close from '../../../../../../asset/CloseWindow.svg';
import {
    ConfiguredProductState,
    getConfiguredProductTreeList,
    resetConfiguredProductTreeSlice,
    resetCreateConfiguredProductSlice,
} from '../../../../../../services/reducers/product/configured-product/configured-product.reducers';
import configuredProductService from '../../../../../../api/services/products/configured-product/configured-product.service';

type ProductPreset = {
    product: Product;
};

const PresetsDetail3d = (props: ProductPreset) => {
    const dispatch = useDispatch();
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openCreateModalSecondStep, setOpenCreateModalSecondStep] = useState(false);
    const [presetToEdit, setPresetToEdit] = useState<PresetTree | undefined>(undefined);

    const [presetRef, setPresetRef] = useState('');
    const [created, setCreated] = useState(false);
    const [presetReferenceSet, setPresetReferenceSet] = useState(false);
    const [toasterImg, setToasterImg] = useState('');
    const [generateDone, setGenerateDone] = useState(false);
    const createConfiguredProductRes = useSelector<RootState, ConfiguredProductState>((state) => state.createConfiguredProduct);
    const saveProductThumbRes = useSelector<RootState, ProductState>((state) => state.saveProductThumb);
    const [presetReference, setPresetReference] = useState('');

    useEffect(() => {
        setPresetToEdit(undefined);
        return () => {
            dispatch(resetCreateConfiguredProductSlice());
            setPresetToEdit(undefined);
        };
    }, []);
    useEffect(() => {
        generateDone &&
            setTimeout(() => {
                setToasterImg('');
                setGenerateDone(false);
                // setOpenCreateModalSecondStep(false);
            }, 5000);
    }, [generateDone]);
    useEffect(() => {
        if (saveProductThumbRes.payload.content && saveProductThumbRes.payload.errors.length === 0) {
            setCreated(true);
            props.product.reference && dispatch(getProductById(props.product.reference));
            setTimeout(() => {
                setCreated(false);
            }, 3000);
            dispatch(resetSaveProductThumbInfo());
        }
    }, [saveProductThumbRes]);
    useEffect(() => {
        props.product.reference && dispatch(getConfiguredProductTreeList({ coreProductReference: props.product.reference }));
    }, [presetToEdit]);
    useEffect(() => {
        getPreset();
    }, [presetReferenceSet]);
    useEffect(() => {
        if (createConfiguredProductRes.payload.content.id !== 0 && createConfiguredProductRes.payload.errors.length === 0) {
            setPresetReference && setPresetReference(createConfiguredProductRes.payload.content.productReference);
            setPresetReferenceSet(true);
            props.product && dispatch(getConfiguredProductTreeList({ coreProductReference: props.product.reference }));
            // props.product.reference && dispatch(getPresetByProductRef(props.product.reference));
            setOpenCreateModal(false);
            dispatch(resetCreateConfiguredProductSlice());
            dispatch(resetConfiguredProductTreeSlice());
        }
    }, [createConfiguredProductRes]);
    const getPreset = () => {
        if (presetReferenceSet) {
            configuredProductService.getConfiguredProductTreeByProductReference(presetReference).then((res) => {
                if (res && res.content) {
                    setPresetToEdit(res.content);
                    props.product.reference && dispatch(getConfiguredProductTreeList({ coreProductReference: props.product.reference }));
                }
            });
        }
    };

    return (
        <div className="presset-main">
            <div className="partTabList">
                {created && <CreateProductToast />}
                {generateDone && toasterImg.length > 0 && (
                    <div className="popin-generate-preset">
                        <div className="popin-top-preset">
                            <span>Vignette Sauvegardée</span>
                            <img
                                onClick={() => {
                                    setToasterImg('');
                                    setGenerateDone(false);
                                }}
                                alt="femer la modal"
                                src={close}
                            />
                        </div>
                        <img alt="vignette" className="generate-img-preset" src={toasterImg} />
                    </div>
                )}
                {openCreateModal && (
                    <CreatePresetElement
                        productRef={props.product.reference}
                        setOpenModal={setOpenCreateModal}
                        setOpenCreateModalSecondStep={setOpenCreateModalSecondStep}
                        setPresetReference={setPresetReference}
                    />
                )}
            </div>
            {presetToEdit !== undefined
                ? openCreateModalSecondStep && (
                      <CreatePresetSecondStepV2
                          setOpenModal={setOpenCreateModal}
                          preset={presetToEdit}
                          setPreset={setPresetToEdit}
                          setGenerate={setGenerateDone}
                          setToasterImg={setToasterImg}
                          refreshPresetName={(newValue) => {
                              setPresetToEdit((prevState) => {
                                  if (prevState) {
                                      return { ...prevState, name: newValue };
                                  } else {
                                      return prevState;
                                  }
                              });
                          }}
                          setOpenCreateModalSecondStep={setOpenCreateModalSecondStep}
                          setPresetRef={setPresetRef}
                      />
                  )
                : getPreset()}

            <ProductPresetTable
                product={props.product}
                presetRef={presetRef}
                setPresetRef={setPresetRef}
                setPresetToEdit={setPresetToEdit}
                setOpenCreateModalSecondStep={setOpenCreateModalSecondStep}
                refreshPresetName={(newValue) => {
                    setPresetToEdit((prevState) => {
                        if (prevState) {
                            return { ...prevState, name: newValue };
                        } else {
                            return prevState;
                        }
                    });
                }}
                preset={presetToEdit}
            />
            <div className="presset-3d-btn-box">
                <button
                    className="import"
                    onClick={() => {
                        setPresetReference('');
                        setPresetToEdit(undefined);
                        setOpenCreateModal(true);
                    }}
                >
                    <img alt="importer un modele 3d" src={plus} />
                    Créer un preset
                </button>
                <button
                    className="save"
                    onClick={() => {
                        dispatch(saveProductThumbInfo(props.product.reference));
                    }}
                >
                    Sauvegarder
                </button>
            </div>
        </div>
    );
};

export default PresetsDetail3d;
