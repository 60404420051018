import {InspectorType} from "./InspectorType";
import {SetTypeOfCell} from "../../../Libraries/TweakPaneUtilities";
import {MapType} from "../MapType";
import MaterialEditorParameters from "../MaterialEditorParameters";

export class TextureType extends InspectorType {
    private m_mapType: MapType;
    constructor(mapType: MapType, matParam: MaterialEditorParameters, paramName: string, uiFolder: any) {
        super(matParam, paramName, uiFolder);
        this.m_mapType = mapType;
    }
    public InitType(){
        SetTypeOfCell(this.m_mapType);
        this.m_uiFolder[this.m_paramName] = this.m_uiFolder.addInput(this.m_materialEditorParam, this.m_paramName, {view: 'input-image'});
    }
}
