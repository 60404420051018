import './title.scss';

type CardProps = {
    title: string;
};

export const Title = ({ title }: CardProps) => {
    return (
        <div className="title">
            <h2>{title}</h2>
        </div>
    );
};

export default Title;
