import './product-rules-table.scss';
import { Rule, RuleType } from '../../../domain/domain';
import duplicate from '../../../asset/dupliquer.svg';
import trash from '../../../asset/poubelle-red.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/root-reducer';
import { ProductRulesState } from '../../../services/reducers/product/rules/rules.reducers';
import React, { useState } from 'react';
import { displayAtomsInCurrentRule } from '../../../utils/buildPresetConf';
import ConfigurationRuleRow from './configuration-rule-row';
import { useParams } from 'react-router-dom';
import { Params } from '../../../domain/Params/params-interface';
import { OnDuplicateRule } from './onDuplicateRule';
import edit from '../../../asset/Editer.svg';


export type ConfigurationRulesProps = {
    confRulesList: any
    setRuleConfType: (ruleConfType: RuleType) => void
    setRuleRef: (ruleRef: string) => void
    setOpenDeleteModal: (openDeleteModal: boolean) => void
    setOpenUpdateModal: (openUpdateModal: boolean) => void
    setCurrentRule: (currentRule: Rule) => void
};
const ConfigurationRules = (props: ConfigurationRulesProps) => {
    const headers = ['CONDITIONS', 'ACTIONS'];
    const rulesRes = useSelector<RootState, ProductRulesState>(state => state.getRules);
    const [ruleHoverId, setRuleHoverId] = useState('');
    const dispatch = useDispatch();
    const { productReference } = useParams<Params>();


    return (
        <table className='products-rules-table'>
            {props.confRulesList.length === 0
                ?
                <div className='empty-rules-list'>
                    Aucune règle de configuration liée à ce produit
                </div>
                : <>
                    <thead>
                    <tr>
                        {headers.map((el: string, index: number) => {
                            return (
                                <th className='table-head' key={index}>{el}</th>
                            );
                        })}
                    </tr>
                    </thead>
                    <div className='products-rules-scroll'>
                        {
                            rulesRes.payload.content.ruleSet.rules.rules.map((rule: Rule) => {
                                if (rule.type !== 'PRICE' && rule.type !== 'DIMENSION') {
                                    props.setRuleConfType(rule.type);
                                    return (
                                        <tbody key={rule.ruleRef}>
                                        <ConfigurationRuleRow conf={displayAtomsInCurrentRule(rulesRes, rule)} rule={rule}
                                                              setRuleHoverId={setRuleHoverId}
                                                              ruleHoverId={ruleHoverId} />
                                        <tr>
                                            <td>
                                                <img onClick={() => {
                                                    props.setCurrentRule(rule);
                                                    props.setOpenUpdateModal(true);
                                                }} alt='editer regle' src={edit} />
                                                <img onClick={() => {
                                                    productReference && OnDuplicateRule(productReference, dispatch, rule);
                                                }} alt='dupliquer' src={duplicate} />


                                                <img onClick={() => {
                                                    props.setRuleRef(rule.ruleRef);
                                                    props.setOpenDeleteModal(true);
                                                }} alt='poubelle'
                                                     src={trash} />
                                            </td>
                                        </tr>
                                        </tbody>
                                    );
                                }
                            })
                        }
                    </div>
                </>
            }

        </table>
    );
};

export default ConfigurationRules;
