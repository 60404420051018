import React, { useEffect, useState } from "react";
import DatePicker, { getDefaultLocale, registerLocale, setDefaultLocale } from "react-datepicker";
import { useHistory } from "react-router-dom";
import { NewsCategory, Role } from "../../domain/domain";
import { CreateNewsType } from "./create-news.type";

import apiClient from "../../api/api-client";
import Textfield from "../textfield/textfield";
import SelectDropdown from "../select-dropdown";
import ImageUpload from "../image-upload/image-upload";
import RichEditor from "../rich-editor/rich-editor";
import Paper from "../paper/paper";
import LayoutSplit from "../layout/layout-split";
import LayoutSidebar from "../layout/Layout-sidebar/layout-sidebar";
import ToggleSwitch from "../toogleSwitch/toggle-switch";

import { useDispatch } from "react-redux";
import { resetCreateNews } from "../../services/reducers/news/news-category-creation.slice";

import { GET_ROLES } from "../../api/endpoints/role/role.endpoint";
import MdfPreconditions from "../../utils/MdfPreconditions";
import MdfApiResponse from "../../domain/common/generic";

import apercu from "../../asset/Apercu.svg";
import send from "../../asset/Envoyer blanc.svg";
import programmer from "../../asset/Programmer blanc.svg";

import { Upload } from "../../utils/upload-file-method";
import SimpleModal from "../modal/simple-modal/simple-modal";
import Modal, { ModalSize } from "../modal/modal";
import { addModal, removeModal } from "../../services/reducers/modal/modal.slice";
import TimePickerUi from "../timepicker/timepicker";
import { setToasterVisible } from "../../services/reducers/toaster-reducer/toaster.slice";
import calendar from "../../asset/calendar.png";

import fr from "date-fns/locale/fr";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

type CreateNewsFormProps = {
  onSubmit: (form: CreateNewsType) => void;
  categoryList: NewsCategory[];
  reloadCategories: () => void;
};

const CreateNewsForm = ({ onSubmit, categoryList, reloadCategories }: CreateNewsFormProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [recipient, setRecipient] = useState("-100");
  const [criticality, setCriticality] = useState("");
  const [category, setCategoryId] = useState<number | null>(null);
  const [, setValidate] = useState(true);
  const [status, setStatus] = useState("");

  const [textErrorMessage, settextErrorMessage] = useState<string>("");
  const [contentErrorMessage, setContentErrorMessage] = useState<string>("");
  const [categoryErrorMessage, setCategoryErrorMessage] = useState<string>("");

  const [isTextError, setIsTextError] = useState<boolean>(false);
  const [isRichError, setIsRichError] = useState<boolean>(false);

  const [imageFile, setImageFile] = useState([]);
  const [imageUrl, setImageUrl] = useState<string[]>([]);

  const [preview, setPreview] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [roleList, setRoleList] = useState<Role[]>([]);

  const [scheduledDate, setScheduledDate] = useState<Date | null>(null);
  const [expiredDate, setExpiredDate] = useState<Date | null>(null);
  const [expiredDateErrorMessage, setExpiredDateErrorMessage] = useState<string>("");
  registerLocale("french", fr);
  setDefaultLocale("french");

  // @ts-ignore
  const data = {
    title: title,
    content: message,
    criticality: criticality,
    createdAt: Date.now(),
    documentsUrl: imageUrl,
    newsCategoryId: category,
    publicationDate: Date.now(),
    archivingDate: Date.now(),
    roleRecipientId: roleList
  };
  const scheduledDateModal = "confirmation-scheduled-date-open";
  const checkErrors = () => {
    if (title.length === 0 || message.length === 0 || category === null) {
      if (title.length === 0) {
        settextErrorMessage("Le titre doit être specifié");
        setIsTextError(true);
      }
      if (message.length === 0) {
        setContentErrorMessage("Le message doit être specifié");
        setIsRichError(true);
      }
      if (category === null) {
        setCategoryErrorMessage("La categorie doit être specifié");
      }
      return false;
    }
    return true;
  };
  const getRoleList = async () => {
    const res = await apiClient(GET_ROLES, undefined, undefined, "GET");
    MdfPreconditions.continueIfNoErrors(res as MdfApiResponse<any>, [], dispatch, () => {
      setRoleList(res.content);
    });
  };
  const openModalFunc = () => {
    const errors = checkErrors();
    if (errors) {
      setOpenModal(true);
    }
  };
  const handleNewsSubmit = (isDraft?: boolean) => {
    if (checkErrors()) {
      if (imageFile.length > 0) {
        const data1 = new FormData();
        for (let i = 0; i < imageFile.length; i++) {
          data1.append("files", imageFile[i]);
        }
        Upload(data1, setImageUrl);
      }
      if (imageFile.length === 0 || (imageFile.length > 0 && imageUrl.length > 0)) {
        onSubmit({
          title,
          content: message,
          criticality,
          newsStatus: status,
          newsCategoryId: category,
          documentsUrl: imageUrl,
          recipientId: recipient,
          scheduledDate: scheduledDate,
          expiredDate: expiredDate
        });
        if (status !== "SCHEDULED") {
          dispatch(
            setToasterVisible({
              message: `La news a été créée`,
              title: "News créée"
            })
          );
        }
        if (status === "SCHEDULED") {
          // @ts-ignore
          let jourMois;
          let scheduledMinutes = scheduledDate?.getMinutes();
          let scheduledHours = scheduledDate?.getHours();
          // @ts-ignore
          let scheduledMonth = scheduledDate?.getMonth() + 1;
          let min;
          if (scheduledMonth < 10) {
            jourMois = scheduledDate?.getDate() + "/0" + scheduledMonth.toString(10);
          } else {
            jourMois = scheduledDate?.getDate() + "/" + scheduledMonth.toString(10);
          }
          if (scheduledMinutes == 0) {
            min = scheduledMinutes.toString(10) + "0";
          } else {
            min = scheduledMinutes;
          }
          let heure;
          if (scheduledHours == 0) {
            heure = scheduledHours.toString(10) + "0";
          } else {
            // @ts-ignore
            if (scheduledHours < 10) {
              // @ts-ignore
              heure = "0" + scheduledHours.toString(10);
            } else {
              heure = scheduledDate?.getHours();
            }
          }
          let heuresMin = heure + ":" + min;
          dispatch(
            setToasterVisible({
              message: "Elle sera programmée le " + jourMois + " à " + heuresMin,
              title: "News bien programmée"
            })
          );
        }
        history.push("/home/news");
      }
    }
  };
  const handleChooseScheduledDate = () => {
    dispatch(removeModal(scheduledDateModal));
    handleNewsSubmit();
  };

  useEffect(() => {
    if (status === "DRAFT") {
      handleNewsSubmit();
    }
  }, [status]);
  useEffect(() => {
    dispatch(resetCreateNews());
    getRoleList();
  }, []);
  useEffect(() => {
    reloadCategories();
  }, [imageFile]);

  return (
    <LayoutSplit
      labelBack="Quitter la création de news ?"
      info="Les modifications ne seront pas sauvegardées"
      // @ts-ignore
      imageFile={imageFile}
      setPreview={setPreview}
      preview={preview}
      // @ts-ignore
      data={data && data}
      title="Créer une Publication"
      sideBar={
        <LayoutSidebar>
          <Paper>
            <ToggleSwitch title="IMPORTANCE DE LA PUBLICATION" setValue={setCriticality} />
          </Paper>
          <Paper>
            <SelectDropdown
              color="#F8A12D"
              error={categoryErrorMessage}
              setError={setCategoryErrorMessage}
              defaultItem={{ id: -1, label: " * Choisir une catégorie" }}
              items={categoryList.map((el) => {
                return {
                  id: el.id,
                  label: el.name
                };
              })}
              title="CATEGORIE DE LA PUBLICATION"
              onChange={(el) => {
                setCategoryId(parseInt(el));
              }}
            />
          </Paper>
          <Paper>
            <div className="calendar">
              <span>DATE DE VALIDITE</span>
              <DatePicker
                onChange={(date) => {
                  // @ts-ignore
                  if (date < Date.now()) {
                    setExpiredDate(null);
                    setExpiredDateErrorMessage("Entrer une date et une heure supérieur à la date et l'heure du jour");
                  } else {
                    // @ts-ignore
                    setExpiredDate(date);
                    setExpiredDateErrorMessage("");
                  }
                }}
                shouldCloseOnSelect={true}
                isClearable
                showTimeSelect
                locale={getDefaultLocale()}
                selected={expiredDate}
                dateFormat="dd/MM/yyyy HH:mm"
                timeCaption="time"
                timeFormat="HH:mm"
                minDate={new Date()}
                placeholderText={moment(Date.now()).format("DD MM YYYY")}
              />
              {expiredDateErrorMessage && <div className="errorMess">{expiredDateErrorMessage}</div>}
            </div>
          </Paper>
        </LayoutSidebar>
      }
    >
      {openModal && (
        <SimpleModal
          icon={send}
          title="Finaliser cette publication ?"
          info="Cette publication sera disponible pour tous les collaborateurs"
          closeOnclick={() => setOpenModal(false)}
          actionOnclick={handleNewsSubmit}
          deleteOrValide={false}
          closeLabel="Annuler"
          actionLabel="Publier"
        />
      )}
      <div className="create-news-main">
        <Modal
          validateLabel="Programmer"
          closeLabel="Annuler"
          onClick={() => {
            handleChooseScheduledDate();
          }}
          id={scheduledDateModal}
          title="Programmer la publication"
          setScheduledDate={setScheduledDate}
          size={ModalSize.XLARGE}
          colorValidateButton="#2252CD"
          icon={calendar}
        >
          <TimePickerUi name="scheduledDateProgramming" title="Date et heure de programmation"
                        setScheduledDate={setScheduledDate} />
        </Modal>
        <Paper>
          <div>
            <SelectDropdown
              title="DESTINATAIRE"
              items={roleList
                // .filter((role) => role.label === 'Vendeur' || role.label === 'Responsable magasin')
                .map((el) => {
                  return {
                    id: el.id,
                    label: el.label
                  };
                })}
              onChange={(value) => setRecipient(value)}
              defaultItem={{ id: "-100", label: "Tous" }}
            />

            <div>
              <Textfield
                label="TITRE"
                isError={isTextError}
                errorMessage={textErrorMessage}
                validation="min:4"
                name={title}
                value={title}
                placeholder="Titre de votre publication"
                onChange={(value) => {
                  setTitle(value);
                  settextErrorMessage("");
                  setIsTextError(false);
                }}
              />
            </div>
            <RichEditor label="MESSAGE" text={message} setText={setMessage} isError={isRichError}
                        errorMessage={contentErrorMessage} setErrorMessage={setContentErrorMessage} />
            <ImageUpload setValidate={setValidate} imageUrl={imageUrl} setImageUrl={setImageUrl} imageFile={imageFile}
                         setImageFile={setImageFile} />
            <div className="btns">
              <div
                className="preview-container"
                onClick={() => {
                  setPreview(true);
                }}
              >
                <img alt="logo apercu" src={apercu} />
                <span>Aperçu</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="draft"
                  onClick={() => {
                    setStatus("DRAFT");
                  }}
                >
                  Enregistrer en brouillon
                </div>
                <div className="send-btn">
                  <button
                    onClick={() => {
                      setStatus("PUBLISHED");
                      openModalFunc();
                    }}
                  >
                    <img alt="logo publier" src={send} />
                    Publier
                  </button>
                </div>
                <div className="send-btn">
                  <button
                    onClick={() => {
                      setStatus("SCHEDULED");
                      dispatch(addModal(scheduledDateModal));
                    }}
                  >
                    <img alt="logo publier" src={programmer} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </LayoutSplit>
  );
};

export default CreateNewsForm;
