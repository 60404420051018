import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../services/root-reducer';
import { GroupState } from '../../../../services/reducers/mapping-zoning/group-mapping.reducer';
import { SubmitHandler, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import close from '../../../../asset/CloseWindow.svg';
import { formErrors } from '../../../../utils/form-errors';
import { updateMapping } from '../../../../services/reducers/mapping-zoning/mapping.reducer';
import { Mapping } from '../../../../domain/domain';
import { returnadvice } from './create-mapping';


type UpdateMappingProps = {
    groupId: number
    currentMapping: Mapping
    name: string | undefined
    setOpenModal: (openModal: boolean) => void;
};

type UpdateMappingInput = {
    name: string;
};

const UpdateMapping = (props: UpdateMappingProps) => {
    const dispatch = useDispatch();
    const group = useSelector<RootState, GroupState>(state => state.getGroup);

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<UpdateMappingInput>({});


    const onSubmit: SubmitHandler<UpdateMappingInput> = (data) => {
        props.groupId && dispatch(updateMapping({
            groupId: props.groupId,
            mappingId: props.currentMapping.id,
            data: {
                name: data.name,
                zoningMetadata: {
                    zoneList: props.currentMapping.zoningMetadata.zoneList,
                },
            },
        }));
    };

    const keyDownHandler = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    };

    useEffect(() => {
        props.name && setValue('name', props.name);
    }, [props.name]);

    return (
        <div className='create-group-main'>
            <div className='create-group-paper'>
                <div className='create-group-top'>
                    <img onClick={ () => {
                        props.setOpenModal(false);
                    } } alt='fermer la modal ' src={ close } />
                    <div
                        className='create-group-title'>
                        Renommer un mapping
                    </div>
                </div>
                <div className='create-group-body'>
                    <div className='create-group-form'>
                        <div className='input-container'>
                            <label htmlFor='name'>Nom</label>
                            <input
                                defaultValue={ group.payload.content?.name }
                                placeholder='Nom du groupe'
                                { ...register('name', {
                                    required: true,
                                }) } />
                            <> {returnadvice(watch('name'), 24)}</>
                            <div className='err-box'>{ errors && formErrors(errors.name) }</div>
                        </div>
                        <div className='btn-box'>
                            <button onClick={ () => {
                                props.setOpenModal(false);
                            } } className='btn-close'>
                                Annuler
                            </button>
                            <button
                                onClick={ () =>
                                    dispatch(updateMapping({
                                        groupId: props.groupId,
                                        mappingId: props.currentMapping.id,
                                        data: {
                                            name: watch('name'),
                                            zoningMetadata: {
                                                zoneList: props.currentMapping.zoningMetadata.zoneList,
                                            },
                                        },
                                    })) }
                                onKeyDown={ keyDownHandler }
                                className='btn-action'>Modifier
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UpdateMapping;