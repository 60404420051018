import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Material } from 'three';

export type ColorState = {
    url: string | undefined; //URL du coloris 1er
    material: Material | undefined; //Objet physique du coloris, joza useless
    gltf: JSON | undefined; //JSON du coloris que LS export
    isGltfDirty: boolean; //Permet de savoir si on peut save == true; si il passe de true a false ca signal a l'editeur d'exporter le JSON du coloris
};

const ColorInitialState: ColorState = {
    url: undefined,
    material: undefined,
    gltf: undefined,
    isGltfDirty: false,
};

const ColorSlice = createSlice({
    name: 'Color',
    initialState: ColorInitialState,
    //reducers : define how the state can be updated
    reducers: {
        SetUrl: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload !== null) state.url = action.payload;
        },
        SetMaterial: (state, action: PayloadAction<Material | undefined>) => {
            if (action.payload !== null) state.material = action.payload;
        },

        SetGLTF: (state, action: PayloadAction<JSON | undefined>) => {
            if (action.payload !== null)
                state.gltf = action.payload;
        },
        ToggleGLTFDirty: (state, action: PayloadAction<boolean>) => {
            if (action.payload !== null) state.isGltfDirty = action.payload;
        },
    },
});

export const { SetUrl, SetMaterial, SetGLTF, ToggleGLTFDirty } = ColorSlice.actions;
export default ColorSlice;
