import './select-dropdown.scss';

export type SelectDropdownItemType = {
    id: number | string;
    label: string;
    name?: string;
};

export type SelectDropdownType = {
    items: SelectDropdownItemType[];
    onChange: (selected: string) => void;
    title?: string;
    defaultItem: SelectDropdownItemType;
    error?: string;
    setError?: (error: string) => void;
    isError?: boolean;
    color?: string;
};

const SelectDropdown = ({ color, items, onChange, title, defaultItem, error, setError, isError }: SelectDropdownType) => {
    return (
        <div className={error ? 'error-dropdown' : 'main-select'}>
            {title && <label className="form-label">{title}</label>}
            <select
                style={{ color: `${color}`, fontWeight: 'bold' }}
                className="form-control"
                onChange={(e) => {
                    onChange(e.target.value);
                    setError && setError('');
                }}
            >
                <option value={defaultItem.id}>{defaultItem.label}</option>
                {items
                    .filter((element) => element.label !== defaultItem.label)
                    .map((el) => (
                        <option key={el.id} value={el.id}>
                            {el.name ? el.name : el.label}
                        </option>
                    ))}
            </select>
            {error && <div className="errorMess">{error}</div>}
        </div>
    );
};

export default SelectDropdown;
