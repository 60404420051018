import apiClient from '../../api-client';
import {
    CREATE_TAG, GET_ASSOCIATE_MODULE_BY_TAG,
    GET_FINISH_AND_COLORS_BY_TAGS,
    GET_FINISH_TAG,
    GET_MODEL_ANCHOR_POINT_TAG,
    GET_MODEL_PART_ITEM_TAG,
    GET_PRODUCT_TAG,
    GET_TEXTURE_TAG,
    GET_ASSOCIATE_PRODUCT_BY_TAG
} from '../../endpoints/tag/tag.endpoints';
import { EntityType, MdfApiResponse, Tag } from '../../../domain/domain';


const TagService = {
    getFinishTag: async (entityType: string) => apiClient(GET_FINISH_TAG(entityType), undefined, undefined, 'GET'),
    getModelAnchorTag: async () => apiClient(GET_MODEL_ANCHOR_POINT_TAG(), undefined, undefined, 'GET'),
    getPartItemTag: async () => apiClient(GET_MODEL_PART_ITEM_TAG(), undefined, undefined, 'GET'),
    getProductTag: async () => apiClient(GET_PRODUCT_TAG(), undefined, undefined, 'GET'),
    getFinishAndColorsByTag: async (tagName: string) => apiClient(GET_FINISH_AND_COLORS_BY_TAGS(tagName), undefined, undefined, 'GET'),
    getTextureTag: async () => apiClient(GET_TEXTURE_TAG(), undefined, undefined, 'GET'),
    getAssociatedModulesByTag: async (collectionReference: string) => apiClient(GET_ASSOCIATE_MODULE_BY_TAG(collectionReference), undefined, undefined, 'GET'),
    getAssociatedProductsByTag: async (collectionReference: string) => apiClient(GET_ASSOCIATE_PRODUCT_BY_TAG(collectionReference), undefined, undefined, 'GET'),
    createTag: async (tagEntityType: EntityType, tagName: string): Promise<MdfApiResponse<Tag>> => apiClient(CREATE_TAG(tagEntityType, tagName), undefined, undefined, 'POST'),
};
export default TagService;
