import '../finishes-table.scss';
import validation from '../../../../asset/Validation-vert.svg';
import { Colori } from '../../../../domain/domain';
import { Vector3 } from 'three';
import { postJson } from '../../../../utils/upload-file-method';
import { ColorState, SetUrl, ToggleGLTFDirty } from '../../../../services/reducers/material-editor/ColorReducer';
import { EditorState, SetExitStatus } from '../../../../services/reducers/material-editor/EditorReducer';
import { ExitType } from '../../../material-editor/Scripts/Inspector/ExitType';
import update from '../../../../asset/Editer.svg';
import duplicate from '../../../../asset/dupliquer.svg';
import trash from '../../../../asset/poubelle-red.svg';
import MaterialEditor from '../../../material-editor/React/MaterialEditor';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllColorisState } from '../../../../services/reducers/suppliers/finish/coloris/coloris.slice';
import axios from 'axios';
import { RootState } from '../../../../services/root-reducer';
import { onLoadingFail } from '../../../../application-3d/application3D-common/Utils/loading-fail';

type ColorsListProps = {
    listColoris: GetAllColorisState;
    colorSave: boolean;
    selectedColoris: Colori | undefined;
    dupIsClicked: number[];
    gltfFromRal: any;
    updateContent: any | undefined;
    imgHooverUrl: string | undefined;

    setColorisId: (colorisId: number) => void;
    setColoris: (coloris: any | undefined) => void;
    setColorSave: (colorSave: boolean) => void;
    setOpenModal: (openModal: boolean) => void;
    setOpenDeleteColorisModal: (openDeleteColorisModal: boolean) => void;
    setSelectColoris: (selectedColoris: Colori | undefined) => void;
    setOpenDuplicateColorisModal: (openDuplicateColorisModal: boolean) => void;
    setOpenUpdateModal: (openUpdateColorisModal: boolean) => void;
    setImgHooverUrl: (imgHooverUrl: string | undefined) => void;
    setUpdateContent: (updateContent: any | undefined) => void;
    setGltfFromRal: (gltfFromRal: any) => void;
    setImgHooverId: (imgHooverId: number) => void;
    setDupIsClicked: (dupIsClicked: number[]) => void;
};
const ColorsList = (props: ColorsListProps) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const HeaderList = ['NOM', 'ACTIONS'];

    //#region useSelector
    const { game } = useSelector<RootState, EditorState>((state) => state.EditorReducer);
    const { gltf, isGltfDirty } = useSelector<RootState, ColorState>((state) => state.ColorReducer);
    //endregion

    //#region methods
    const fetchMaterial = async (url: string) => {
        const res = await axios.get(url);
        return res;
    };
    const checkModif = (el: any) => {
        props.setColoris(el);
        props.setOpenModal(true);
    };
    const returnNameAndCode = (code: string, name: string) => {
        const length = code.length + name.length + 1;
        const splitNumber = length - 13;

        const concat = (
            <>
                <span className='color-code'>{code}</span>
                <div className='color-name'>{length > 24 ? `${name.substring(splitNumber, 0)}...` : name}</div>
            </>
        );
        return <div className='color-first-child'>{concat}</div>;
    };
    //endregion
    return (
        <React.Fragment>
            {location.pathname.includes('colors') && (
                <div className='color-table-split'>
                    {props.colorSave && (
                        <div className='toaster-main'>
                            <div className='toaster-content'>
                                <img alt='logo validation' src={validation} />
                                <div className='toaster-message'>Coloris sauvegardé</div>
                            </div>
                        </div>
                    )}
                    <div className='color-table-list'>
                        <thead className='color-table-head'>
                        <tr>
                            {HeaderList.map((header, i) => {
                                return <th key={i}>{header}</th>;
                            })}
                        </tr>
                        </thead>
                        <tbody className='color-table-body'>
                        <>
                            {props.imgHooverUrl && (
                                <div className='relative'>
                                    <img className='image-zoom' alt='texture-zoom' src={props.imgHooverUrl} />
                                </div>
                            )}
                        </>
                        <div className='coloris-list'>
                            {props.listColoris.payload.content !== null &&
                                props.listColoris.payload.content.map((el: Colori, i: number) => {
                                    return (
                                        <tr
                                            key={i}
                                            onClick={() => {
                                                if (el.coloriParent !== null) {
                                                    props.setSelectColoris(el);
                                                    if (!props.dupIsClicked.includes(el.id)) {
                                                        // @ts-ignore
                                                        props.setDupIsClicked((prev) => [...prev, el.id]);
                                                        const colorVector = new Vector3(el.colorR, el.colorG, el.colorB);
                                                        fetchMaterial(el.modelUrl).then((r) => {
                                                            const t = game?.TargetMeshService.GenerateDuplicateOfMatWithRAL(r.data.materials[0], colorVector);
                                                            const jsonToUpload = { ...r.data, materials: [t] };
                                                            const data = new FormData();
                                                            data.append('file', JSON.stringify(jsonToUpload));
                                                            postJson(
                                                                `v1/documents/upload/json/${el.name}`,
                                                                {
                                                                    coloriName: '',
                                                                    coloriCode: '',
                                                                    colorisThumbnailUrl: '',
                                                                    colorisContent: JSON.stringify(jsonToUpload),
                                                                    uploadedThumbnailUrl: '',
                                                                },
                                                                () => {
                                                                },
                                                                (res) => {
                                                                    dispatch(SetUrl(res.data.content));
                                                                    dispatch(ToggleGLTFDirty(true));
                                                                    props.setGltfFromRal(jsonToUpload);
                                                                    dispatch(SetExitStatus(ExitType.Save));
                                                                    dispatch(ToggleGLTFDirty(false));
                                                                },
                                                            );
                                                        });
                                                    }
                                                }
                                                {
                                                    isGltfDirty ? checkModif(el) : props.setSelectColoris(el);
                                                }
                                            }}
                                            className={props.selectedColoris?.id === el.id ? 'coloris-focus' : ''}>
                                            <td
                                                onMouseEnter={() => {
                                                    props.setImgHooverId(el.id);
                                                    props.setImgHooverUrl(el.uploadedThumbnailUrl.length === 0 ? el.thumbnailUrl : el.uploadedThumbnailUrl);
                                                }}
                                                onMouseLeave={() => {
                                                    props.setImgHooverId(0);
                                                    props.setImgHooverUrl(undefined);
                                                }}
                                            >
                                                <img alt='logo' src={el.uploadedThumbnailUrl.length === 0 ? el.thumbnailUrl : el.uploadedThumbnailUrl} />
                                                {returnNameAndCode(el.name, el.code)}
                                            </td>

                                            <td className='color-action'>
                                                {el.name !== 'Défaut' && (
                                                    <img
                                                        onClick={() => {
                                                            fetchMaterial(el.modelUrl).then((r) => {
                                                                props.setUpdateContent(r.data);
                                                            });
                                                            props.setColoris(el);
                                                            props.setOpenUpdateModal(true);
                                                        }}
                                                        src={update}
                                                        alt='modifier'
                                                    />
                                                )}
                                                <img
                                                    onClick={() => {
                                                        props.setColorisId(el.id);
                                                        props.setColoris(el);
                                                        props.setOpenDuplicateColorisModal(true);
                                                    }}
                                                    alt='dupliquer'
                                                    src={duplicate}
                                                />
                                                {!el.nonDeletable && (
                                                    <img
                                                        onClick={() => {
                                                            props.setColorisId(el.id);
                                                            props.setOpenDeleteColorisModal(true);
                                                        }}
                                                        alt='poubelle'
                                                        src={trash}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </div>
                        <div className='editor-btn'>
                            <button
                                onClick={() => {
                                    dispatch(SetExitStatus(ExitType.Save));
                                    dispatch(ToggleGLTFDirty(false));
                                }}
                            >
                                Sauvegarder
                            </button>
                        </div>
                        </tbody>
                    </div>
                    <div className='color-table-editor'>
                        {props.listColoris.payload.content !== null && props.listColoris.payload.content.length > 0 ? (
                            <div className='editor'>
                                <MaterialEditor onLoadingFail={onLoadingFail} />
                            </div>
                        ) : (
                            <div className='color-table-empty'> Créer un coloris pour commencer </div>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default ColorsList;
