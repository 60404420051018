import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Engine } from '@lutithree/build/Engine';
import {
    Box,
    LightComponent,
    LightTypes,
} from '@lutithree/build/Modules/WebGL/Scene/Components/Rendering/LightComponent';
import {
    Camera,
    ColorRepresentation, Mesh, MeshPhysicalMaterial,
    MeshStandardMaterial,
    MOUSE,
    PerspectiveCamera, PlaneGeometry, ShadowMaterial,
    Spherical,
    TOUCH, Vector2,
    Vector3
} from 'three';
import { TargetMeshService } from './TargetMeshService';
import { EnvironmentComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Rendering/EnvironmentComponent';
import { DomElementComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Rendering/DomElementComponent';
import { CameraComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Rendering/CameraComponent';
import { OrbitControlComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Controls/OrbitControlComponent';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { CanvasDomHandler } from '@lutithree/build/Handlers/CanvasDomHandler';
import { CanvasDomChangedEvent } from '@lutithree/build/Modules/WebGL/Rendering/Events/CanvasDomChangedEvent';
import { WindowResizeHandler } from '@lutithree/build/Handlers/WindowResizeHandler';
import { WindowResizeEvent } from '@lutithree/build/Modules/WebGL/Rendering/Events/WindowResizeEvent';
import { ResourcesHandler } from '@lutithree/build/Handlers/ResourcesHandler';
import { RemoveResouceEvent } from '@lutithree/build/Modules/WebGL/Resources/Events/RemoveResouceEvent';
import { RenderHandler } from '@lutithree/build/Handlers/RenderHandler';
import { BeforeRenderingEvent } from '@lutithree/build/Modules/WebGL/Rendering/Events/BeforeRenderingEvent';
import { FrameHandler } from '@lutithree/build/Handlers/FrameHandler';
import { NewFrameEvent } from '@lutithree/build/Modules/Core/GameLoop/Events/NewFrameEvent';
import { LastFrameEvent } from '@lutithree/build/Modules/Core/GameLoop/Events/LastFrameEvent';
import { DataTextureLoader } from '@lutithree/build/Modules/WebGL/Resources/Load/DataTextureLoader';
import { BackgroundEnvmapDecorator } from '../EntityDecorators/BackgroundEnvmapDecorator';
import { IRendering } from '@lutithree/build/Modules/WebGL/Rendering/IRendering';
import { LutithreeToneMappingMode } from '@lutithree/build/Modules/WebGL/Rendering/LutithreeToneMappingMode';
import { Guid } from 'guid-typescript';
import { IEntityManager } from '@lutithree/build/Modules/Core/Entity/IEntityManager';
import {RenderMode} from "@lutithree/build/Modules/WebGL/Rendering/RenderingStrategies/RenderMode";
import {
    ContactShadowsComponent
} from "@lutithree/build/Modules/WebGL/Scene/Components/Rendering/ContactShadowsComponent";
import {MeshFilterComponent} from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";
import {MaterialLoader} from "@lutithree/build/Modules/WebGL/Resources/Load/MaterialLoader";
import CamerasService
    from "../../../../application-3d/application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/CamerasService";
import BackgroundModeService
    from "../../../../application-3d/application3D-common/Librairies/Studios/ProductStudio/GameLogic/Studio/BackgroundModeService";
import {
    ScreenshotEnvDecorator
} from "../../../../application-3d/application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Environment/EntityDecorators/ScreenshotEnvDecorator";
import {BackgroundService} from "./BackgroundService";

export class Viewer3D {
    private m_rootEntities: Map<string, SceneEntity>;
    private m_targetMeshService: TargetMeshService;
    private m_renderingService: IRendering;
    private m_camService: CamerasService;
    private m_backgroundModeService: BackgroundModeService;
    private m_backgroundService: BackgroundService;
    private studioEnvEntity: SceneEntity | undefined;
    private m_mainCam: CameraComponent | undefined;
    private m_scene: IEntityManager<SceneEntity> | undefined;

    private m_prevValue: number = 0;

    public constructor(p_engine: Engine) {
        this.m_rootEntities = new Map<string, SceneEntity>();
        this.m_renderingService = p_engine.Modules.Rendering;
        this.m_scene = p_engine.Modules.Scene;
        this.m_camService = new CamerasService(p_engine);
        this.m_backgroundModeService = new BackgroundModeService(p_engine);
        this.m_backgroundService = new BackgroundService(p_engine);
        this.m_targetMeshService = new TargetMeshService(p_engine, this.BackgroundService);
    }

    public get RootEntities(): Map<string, SceneEntity> {
        // TODO return readonly
        return this.m_rootEntities;
    }

    public get TargetMeshService(): TargetMeshService {
        return this.m_targetMeshService;
    }
    
    public get BackgroundService(): BackgroundService {
        return this.m_backgroundService;
    }

    public get StudioEnvEntity(): SceneEntity | undefined {
        return this.studioEnvEntity;
    }

    public get MaxAnisotropy(): number {
        return this.m_renderingService.Renderer.capabilities.getMaxAnisotropy()
    }
    
    public Clear(): void {
        this.m_rootEntities.clear();
    }

    public LinkToEngine(p_engine: Engine) {
        this.AddDefaultEntities(p_engine);
        this.AddEventHandlers(p_engine);
    }

    public SetEnvmapRotation(p_meshesToApply: Guid[], p_value: number) {
        this.m_targetMeshService.RotateAllObjects(p_meshesToApply, p_value);
        if(!this.m_camService.CurrentCamera) throw new Error("main camera is null");
        let camera = this.m_camService.CurrentCamera.GetObject();
        let spherical = this.m_camService.GetRelativePosFromTarget(camera);
        spherical.theta = p_value + (spherical.theta - this.m_prevValue);
        this.m_camService.ApplyRelativeSphericalPosFromTarget(spherical);
        this.m_prevValue = p_value;
    }

    public AddDefaultEntities(p_engine: Engine): void {
        this.BuildRootEntities(p_engine);
        this.AddLightning(p_engine);
        this.AddCameras(p_engine);
        this.LoadEnvironnementsAsync(
            p_engine,
            'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/envmaps/brown_photostudio_04_1k.hdr',
            'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/envmaps/studio_visible.hdr');

        this.m_targetMeshService = new TargetMeshService(p_engine, this.m_backgroundService);

        let contactShadowEntity: SceneEntity = p_engine.Modules.Scene.CreateEntity('ContactShadows');
        contactShadowEntity.Enable(false);
        let component = contactShadowEntity.AddComponentOfType(ContactShadowsComponent, [0, 3]);
        contactShadowEntity.Enable(true);
        component.GetObject().scale.set(0.3333,0.3333,0.3333);
        if (this.m_rootEntities.has('Lighting')) this.m_rootEntities.get('Lighting')!.Transform.AddChild(contactShadowEntity.Transform);

        let shadowEntity: SceneEntity = p_engine.Modules.Scene.CreateEntity('ground');
        let geometry = new PlaneGeometry(5, 5);
        geometry.rotateX( - Math.PI / 2 );

        let loader = new MaterialLoader("https://mdfst3dprdt.blob.core.windows.net/public/materials/Defaults/Défaut_29f5743c-d7e3-4186-91e2-ac378617db0c.json");
        let promise = loader.LoadAsync();
        promise.then((mat)=>{
                this.ResetTiling(geometry);
                let plane = new Mesh(geometry, mat.material);
                plane.receiveShadow = true;
                shadowEntity.AddComponentOfType(MeshFilterComponent, plane);
        }, (reason)=>{
            console.error('Catch Error', reason);
        });
    }

    protected AddEventHandlers(p_engine: Engine): void {
        let domHandler = new CanvasDomHandler(p_engine);
        p_engine.Modules.EventManager.Suscribe(CanvasDomChangedEvent, domHandler);

        let resizeHandler = new WindowResizeHandler(p_engine);
        p_engine.Modules.EventManager.Suscribe(WindowResizeEvent, resizeHandler);

        let resourcesRemovedHandler = new ResourcesHandler(p_engine);
        p_engine.Modules.EventManager.Suscribe(RemoveResouceEvent, resourcesRemovedHandler);

        let renderHandler = new RenderHandler(p_engine);
        p_engine.Modules.EventManager.Suscribe(BeforeRenderingEvent, renderHandler);

        let frameHandler = new FrameHandler(p_engine, RenderMode.Default, RenderMode.HighQuality);
        p_engine.Modules.EventManager.Suscribe(NewFrameEvent, frameHandler);
        p_engine.Modules.EventManager.Suscribe(LastFrameEvent, frameHandler);
    }

    private BuildRootEntities(p_engine: Engine): void {
        this.m_rootEntities.set('Helpers', p_engine.Modules.Scene.CreateEntity('Helpers'));
        this.m_rootEntities.set('Environement_Lighting', p_engine.Modules.Scene.CreateEntity('Environement_Lighting'));
    }

    private AddLightning(p_engine: Engine): void {
        let environementEntity: SceneEntity | undefined;
        if (this.m_rootEntities.has('Lighting')) environementEntity = this.m_rootEntities.get('Lighting');

        let cameraBox: Box = {
            near: 5,
            far: 25,
            left: -8,
            right: 8,
            top: 8,
            bottom: -8,
            bias: 0.0,
            normalBias: 0.03,
        };

        // Add light
        let lightEntity1 = p_engine.Modules.Scene.CreateEntity('Directional_Light1');
        if (environementEntity) environementEntity.Transform.AddChild(lightEntity1.Transform);
        lightEntity1.AddComponentOfType(LightComponent, LightTypes.Directional, new Vector3(-3.9, 9, 2.8), true, 0.33, cameraBox);

        let lightEntity2 = p_engine.Modules.Scene.CreateEntity('Directional_Light2');
        if (environementEntity) environementEntity.Transform.AddChild(lightEntity2.Transform);
        lightEntity2.AddComponentOfType(LightComponent, LightTypes.Directional, new Vector3(-3.7, 8, 2.8), true, 0.33, cameraBox);

        let lightEntity3 = p_engine.Modules.Scene.CreateEntity('Directional_Light3');
        if (environementEntity) environementEntity.Transform.AddChild(lightEntity3.Transform);
        lightEntity3.AddComponentOfType(LightComponent, LightTypes.Directional, new Vector3(-3.5, 9, 2.8), true, 0.33, cameraBox);
    }

    private AddCameras(p_engine: Engine): void {
        let cameraEntity: SceneEntity = p_engine.Modules.Scene.CreateEntity('PerspectiveCamera');

        let domEntity = p_engine.Modules.Scene.CreateEntity('DomEntity');
        let domComponent = domEntity.AddComponentOfType(DomElementComponent, p_engine.Modules.Rendering.Renderer.domElement);

        let aspect = window.innerWidth / window.innerHeight;
        let camera = new PerspectiveCamera(60, aspect, 0.1, 50);
        camera.layers.enableAll();
        camera.layers.disable(1);

        let cameraComponent = cameraEntity.AddComponentOfType(CameraComponent, camera, true);
        this.m_mainCam = cameraComponent;
        p_engine.Modules.Rendering.ChangeCamera(cameraComponent.GetObject());

        this.m_camService.ApplyRelativeSphericalPosFromTarget((new Spherical(0, 3.38, 0).makeSafe()));

        // Orbit control
        let orbitControl = cameraEntity.AddComponentOfType(OrbitControlComponent, this.CreatePerspectiveOrbitControl(cameraComponent.GetObject(), p_engine.Modules.Rendering.Renderer.domElement));
        orbitControl.SetOnChangecallback(() => {
            p_engine.Modules.LoopStrategy.RequestRender(false);
        });
        orbitControl.Enable(true);
    }

    private async LoadEnvironnementsAsync(p_engine: Engine, p_pathLightingDHR: string, p_pathBackgroundDHR: string | ColorRepresentation): Promise<void> {
        // Create Environement_Light
        let textureAsset: DataTextureLoader = new DataTextureLoader(p_pathLightingDHR);
        let entityBuffer = p_engine.Modules.Scene.CreateEntity('Environement');
        if (this.m_rootEntities.has('Environement_Lighting')) this.m_rootEntities.get('Environement_Lighting')!.Transform.AddChild(entityBuffer.Transform);
        textureAsset.LoadAsync().then((texture) => {
            entityBuffer.AddComponentOfType(EnvironmentComponent, p_engine.Modules.Rendering.Renderer, p_engine.Modules.Scene.ThreeScene, texture);
            p_engine.Modules.LoopStrategy.RequestRender(true);
        });

        let backgEntity = this.m_backgroundService.CreateBackgoundEntity();
        new BackgroundEnvmapDecorator(p_pathBackgroundDHR, p_engine.Modules.Rendering.Renderer).DecorateAsync(backgEntity);
        if (this.m_rootEntities.has('Environement_Lighting')) this.m_rootEntities.get('Environement_Lighting')!.Transform.AddChild(backgEntity.Transform);
        
        // Create Environement_Background
        this.studioEnvEntity = p_engine.Modules.Scene.CreateEntity('Screen_Background');
        new ScreenshotEnvDecorator(p_engine.Modules.Rendering.Renderer).Decorate(this.studioEnvEntity);
        if (this.m_rootEntities.has('Environement')) this.m_rootEntities.get('Environement')!.Transform.AddChild(this.studioEnvEntity.Transform);
    }

    private CreatePerspectiveOrbitControl(p_camera: Camera, p_renderDom: HTMLCanvasElement): OrbitControls {
        let control = new OrbitControls(p_camera, p_renderDom);

        control.rotateSpeed = 0;
        control.panSpeed = 0.4;
        control.screenSpacePanning = false;
        control.maxPolarAngle = 3.14 / 2;
        control.maxDistance = 8;
        control.minDistance = 0.5;
        control.enableDamping = true;
        control.dampingFactor = 0.05;
        control.target.set(0, 0, 0);

        control.mouseButtons = {
            LEFT: MOUSE.ROTATE,
            MIDDLE: MOUSE.PAN,
            RIGHT: MOUSE.PAN,
        };
        control.touches = {
            ONE: TOUCH.ROTATE,
            TWO: TOUCH.DOLLY_ROTATE,
        };

        control.enablePan = false;
        control.enableRotate = true;
        control.enableZoom = true;
        return control;
    }
    
    private ResetTiling(p_mat: PlaneGeometry){
        var uv = p_mat.attributes.uv;
        
        for (let i = 0; i < uv.count; i++)
        {
            let newUVx = uv.getX(i);
            let newUVy = uv.getY(i);
            uv.setXY(i,newUVx * 4, newUVy*4);
        }
    }
}
