import './create-catalog.scss';
import close from '../../../../asset/CloseWindow.svg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createAndUpdateCatalog } from '../../../../services/reducers/catalog/catalog.slice';


type CreateCatalogProps = {
    setModal: (modal: boolean) => void

};
const CreateCatalog = (props: CreateCatalogProps) => {
    const dispatch = useDispatch();

    //region state
    const [nameErr, setNameErr] = useState('');
    const [name, setName] = useState<string>('');
    //endregion

    const onSubmit = () => {
        name.length === 0
            ? setNameErr('Le nom doit etre renseigné')
            : dispatch(createAndUpdateCatalog({
                catalog: {
                    name: name,
                },catalogUserList:[]
            }));
    };
    return (
        <div className='create-catalog-main'>
            <div className='create-catalog-paper'>
                <div className='create-catalog-top'>
                    <span>Créer un catalogue</span>
                    <img onClick={() => props.setModal(false)} alt='fermer la modal' src={close} />
                </div>
                <div className='create-catalog-body'>
                    <label>Nom</label>
                    <input
                        type='text'
                        placeholder='Nom du catalogue'
                        onChange={(e) => {
                            setNameErr('');
                            setName(e.target.value);
                        }}
                    />
                    <span>{nameErr}</span>
                </div>
                <div className='create-catalog-btn-box'>
                    <button onClick={() => props.setModal(false)} className='btn-close'>
                        Annuler
                    </button>
                    <button
                        onClick={onSubmit}
                        className='btn-action'>
                        Créer
                    </button>
                </div>
            </div>

        </div>
    );
};
export default CreateCatalog;