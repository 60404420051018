import * as THREE from 'three';
import { Vector2 } from 'three';

export default class MaterialEditorParameters {
    
    public constructor(p_urlValue: string) {
        this.m_matURL = p_urlValue;
    }
    m_matName: string = '';
    
    m_matURL: string = 'matURL';

    matType: string = "MeshPhysicalMaterial";
    
    side: THREE.Side = THREE.FrontSide;

    txTiling: Vector2 = new Vector2(1, 1);
    txOffset: Vector2 = new Vector2(0, 0);
    txRotation: number = 0;
    txRotationDeg: number = 0;
    txRotationCenter: Vector2 = new Vector2(0.5, 0.5);
    txWrapMode: THREE.Wrapping = THREE.RepeatWrapping;

    color: { r: number; g: number; b: number } = { r: 0, g: 0, b: 0 };
    map: HTMLImageElement = new Image();
    mapFlipY: boolean = false;
    mapShowOnMesh: boolean = false;
    mapEncodingDefault: boolean = true;

    backgroundStudio: boolean = false;

    backgroundRoughness: number = 0;
    
    EnvmapRotation: number = 4.7;

    envMapIntensity: number = 1.0;
    
    emissiveIntensity: number = 0.0;
    emissive: { r: number; g: number; b: number } = { r: 0, g: 0, b: 0 };
    emissiveMap: HTMLImageElement = new Image();
    emissiveMapFlipY: boolean = false;
    emissiveMapShowOnMesh: boolean = false;
    emissiveMapEncodingDefault: boolean = true;

    specularIntensity: number = 0.0;
    specularReflectivity: number = 0.0;
    specularColor: { r: number; g: number; b: number } = {r: 0, g: 0, b: 0};
    specularMap: HTMLImageElement = new Image();
    specularMapFlipY: boolean = false;
    specularMapShowOnMesh: boolean = false;
    specularMapEncodingDefault: boolean = true;

    aoMapIntensity:number = 1.0;
    aoMap: HTMLImageElement = new Image();
    aoMapFlipY: boolean = false;
    aoMapShowOnMesh: boolean = false;
    aoMapEncodingDefault: boolean = false;

    iridescence: number = 0.0;
    iridescenceIOR: number = 0.0;

    anisotropy: number = 0.0;
    anisotropyRotation: number = 0.0;
    
    roughness: number = 0.0;
    roughnessMap: HTMLImageElement = new Image();
    roughnessMapFlipY: boolean = false;
    roughnessMapShowOnMesh: boolean = false;
    rougnessMapEncodingDefault: boolean = false;

    metalness: number = 0.0;
    metalnessMap: HTMLImageElement = new Image();
    metalnessMapFlipY: boolean = false;
    metalnessMapShowOnMesh: boolean = false;
    metalnessMapEncodingDefault: boolean = false;

    normalMap: HTMLImageElement = new Image();
    normalMapFlipY: boolean = false;
    normalMapShowOnMesh: boolean = false;
    normalScale: Vector2 = new Vector2(1, 1);
    normalMapEncodingDefault: boolean = false;

    clearcoat: number = 0.0;
    clearcoatMap: HTMLImageElement = new Image();
    clearcoatMapFlipY: boolean = false;
    clearcoatMapShowOnMesh: boolean = false;
    clearcoatMapEncodingDefault: boolean = false;

    clearcoatNormalMap: HTMLImageElement = new Image();
    clearcoatNormalMapFlipY: boolean = false;
    clearcoatNormalMapShowOnMesh: boolean = false;
    clearcoatNormalScale: Vector2 = new Vector2(1, 1);
    clearcoatNormalMapEncodingDefault: boolean = false;

    clearcoatRoughness: number = 0.0;
    clearcoatRoughnessMap: HTMLImageElement = new Image();
    clearcoatRoughnessMapFlipY: boolean = false;
    clearcoatRoughnessMapShowOnMesh: boolean = false;
    clearcoatRoughnessMapEncodingDefault: boolean = false;

    sheen: number = 0.0;
    sheenRoughness: number = 0.0;
    sheenColor: { r: number; g: number; b: number } = { r: 1, g: 0, b: 0 };
    sheenColorMap: HTMLImageElement = new Image();
    sheenColorMapFlipY: boolean = false;
    sheenmapShowOnMesh: boolean = false;
    sheenColorMapEncodingDefault: boolean = false;

    ior: number = 0.0;
    thickness: number = 0.01;
    reflectivity: number = 0.5;

    transmission: number = 0.0;
    transmissionMap: HTMLImageElement = new Image();
    transmissionMapFlipY: boolean = false;
    transmissionMapShowOnMesh: boolean = false;
    transmissionMapEncodingDefault: boolean = false;
    
    thicknessMap: HTMLImageElement = new Image();
    thicknessMapFlipY: boolean = false;
    thicknessMapShowOnMesh: boolean = false;
    thicknessMapEncodingDefault: boolean = false;

    wireframe: boolean = false;
    autorotate: boolean = false;
}
