import update from '../../../../asset/Editer.svg';
import trash from '../../../../asset/poubelle-red.svg';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../services/root-reducer';
import { GetTexturesState } from '../../../../services/reducers/suppliers/finish/texture/texture.slice';
import { useLocation } from 'react-router-dom';


type TexturesListProps = {
    setColorisId: (colorisId: number) => void;
    setImgHooverId: (imgHooverId: number) => void;
    setTextureId: (textureId: number) => void;
    setTextureName: (textureName: string) => void;
    setTextureUrl: (textureUrl: string) => void;
    setTextureThumbnailUrl: (textureThumbnailUrl: string) => void;
    setOpenDeleteColorisModal: (openDeleteColorisModal: boolean) => void;
    setOpenCreateColorisModal: (openCreateColorisModal: boolean) => void;
    setOpenDeleteTextureModal: (openDeleteTextureModal: boolean) => void;
    setOpenUpdateTextureModal: (openUpdateTextureModal: boolean) => void;
    imgHooverId: number
};
const TexturesList = (props: TexturesListProps) => {
    const location = useLocation();
    const textureRes = useSelector<RootState, GetTexturesState>((state) => state.getAllTextures);
    const HeaderList = ['NOM', 'ACTIONS'];


    return (
        <React.Fragment>
            { location.pathname.includes('textures') && (
                <>
                    <thead className='texture-table-head'>
                    <tr>
                        { HeaderList.map((header, i) => {
                            return <th key={ i }>{ header }</th>;
                        }) }
                    </tr>
                    </thead>
                    <tbody className='texture-table-body'>
                    { textureRes.payload !== undefined &&
                        textureRes.payload.content.map((el, i) => {
                            return (
                                <tr key={ i }>
                                    <td
                                        onMouseEnter={ () => {
                                            props.setImgHooverId(el.id);
                                        } }
                                        onMouseLeave={ () => {
                                            props.setImgHooverId(0);
                                        } }>
                                        <img alt='texture' src={ el.thumbnailUrl } />
                                        { el.name }
                                        { el.id === props.imgHooverId &&
                                            <img className='image-zoom' alt='texture-zoom'
                                                 src={ el.thumbnailUrl } /> }
                                    </td>
                                    <td>
                                        <img
                                            onClick={ () => {
                                                props.setTextureId(el.id);
                                                props.setTextureName(el.name);
                                                props.setTextureUrl(el.contentUrl);
                                                props.setTextureThumbnailUrl(el.thumbnailUrl);
                                                props.setOpenUpdateTextureModal(true);
                                            } }
                                            alt='editer'
                                            src={ update }
                                        />
                                        <img
                                            onClick={ () => {
                                                props.setTextureId(el.id);
                                                props.setTextureUrl(el.contentUrl);
                                                props.setTextureThumbnailUrl(el.thumbnailUrl);
                                                props.setOpenDeleteTextureModal(true);
                                            } }
                                            alt='poubelle'
                                            src={ trash }
                                        />
                                    </td>
                                </tr>
                            );
                        }) }
                    </tbody>
                </>
            ) }
        </React.Fragment>
    );
};
export default TexturesList;