import { useDispatch } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formErrors } from '../../../../utils/form-errors';
import { addPartItem, createPart, updatePartName } from '../../../../services/reducers/product/part/part.reducers';
import { useEffect } from 'react';
import close from '../../../../asset/CloseWindow.svg';
import { Part } from '../../../../domain/domain';
import { returnadvice } from '../../mapping-zoning/mapping/create-mapping';


type CreatePartProps = {
    setOpenPartModal: (openPartModal: boolean) => void;
    setOpenUpdateModal?: (openUpdateModal: boolean) => void;
    name?: string;
    part?: Part;
    cara?: boolean;
    option?: boolean;
    update?: boolean;
    setUpdate?: (update: boolean) => void;
    subCaraCategory?: boolean;
    subOptionCategory?: boolean;
    productRef?: string;
    partRef?: string;
    partIndex?: number;
};

type CreatePartInput = {
    name: string;
};

const CreatePartElement = (props: CreatePartProps) => {
    const dispatch = useDispatch();

    const {
        register,
        watch,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<CreatePartInput>({});
    const Cname = watch('name');

    useEffect(() => {
        props.name && setValue('name', props.name);
    }, []);

    const onSubmit: SubmitHandler<CreatePartInput> = (data) => {
        if (props.name) {
            props.partRef && dispatch(updatePartName({ partRef: props.partRef, name: data.name }));
            props.setOpenUpdateModal && props.setOpenUpdateModal(false);
            props.setUpdate && props.setUpdate(false);
        } else if (props.cara && props.part) {
            dispatch(
                addPartItem({
                    partRef: props.part.reference,
                    partItem: {
                        name: data.name,
                        type: 'CHARACTERISTIC',
                        itemIndex: 0,
                        newRow: true,
                    },
                }),
            );
        } else if (props.subCaraCategory) {
            dispatch(
                addPartItem({
                    // @ts-ignore
                    partRef: props.part.reference,
                    partItem: {
                        name: data.name,
                        type: 'CHARACTERISTIC',
                        // @ts-ignore
                        itemIndex: props.partIndex,
                        newRow: false,
                    },
                }),
            );
        } else if (props.option && props.part) {
            dispatch(
                addPartItem({
                    partRef: props.part.reference,
                    partItem: {
                        name: data.name,
                        type: 'OPTION',
                        itemIndex: 0,
                        newRow: true,
                    },
                }),
            );
        } else if (props.subOptionCategory) {
            dispatch(
                addPartItem({
                    // @ts-ignore
                    partRef: props.part.reference,
                    partItem: {
                        name: data.name,
                        type: 'OPTION',
                        // @ts-ignore
                        itemIndex: props.partIndex,
                        newRow: false,
                    },
                }),
            );
        } else {
            props.setOpenPartModal(false);
            props.productRef && dispatch(createPart({ productRef: props.productRef, name: data.name }));
        }
        props.setOpenPartModal(false);
    };
    const keyDownHandler = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    };
    const returnTitle = () => {
        let title: string = 'Créer une part';
        if (props.cara) {
            return ' Créer une caractéristique';
        } else if (props.option) {
            return ' Créer une option';
        } else if (props.subCaraCategory || props.subOptionCategory) {
            return ' Créer une caractéristique liée';
        } else if (props.update) {
            return 'Renommer la part';
        }
        return title;
    };

    return (
        <div className='create-color-main'>
            <div className='create-color-paper'>
                <div className='create-color-top'>
                    <div className='color-name'> {returnTitle()}</div>
                    <img alt='FERMUTURE DE LA MODALE' src={close}
                         onClick={() => {
                             props.setOpenPartModal(false);
                             props.setOpenUpdateModal && props.setOpenUpdateModal(false);
                             props.setUpdate && props.setUpdate(false);
                         }}
                         className='color-close'
                    />
                </div>
                <div className='create-color-body'>
                    <form className='create-color-form' onSubmit={handleSubmit(onSubmit)}
                          onKeyDown={keyDownHandler}>
                        <div className='input-container'>
                            <label htmlFor='name'>Nom</label>
                            <input
                                defaultValue={props.update ? props.name : undefined}
                                {...register('name', {
                                    required: true,
                                })}
                            />
                            {returnadvice(Cname, 24)}
                            {errors && formErrors(errors.name)}
                        </div>
                        <div className='btn-box'>
                            <button
                                onClick={() => {
                                    props.setOpenPartModal(false);
                                    props.setOpenUpdateModal && props.setOpenUpdateModal(false);
                                    props.setUpdate && props.setUpdate(false);
                                }}
                                className='btn-close'
                            >
                                Annuler
                            </button>
                            <button className='btn-action'>{props.update ? `Modifier` : `Créer`}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default CreatePartElement;
