import MdfApiResponse from '../../../domain/common/generic';
import { Catalog, CatalogResponse, TreeCatalog } from '../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CatalogServices, { CreateForm } from '../../../api/services/catalog/catalog.services';
import subSectionService from '../../../api/services/catalog/sub-section.service';

//region state
export type CatalogName = {
    name: string;
};
const initialCatalogName: CatalogName = {
    name: '',
};

export type CatalogResponseState = {
    isLoading: boolean;
    fullfiled: boolean
    payload: MdfApiResponse<CatalogResponse>;
};

const initialCatalogResponse: CatalogResponseState = {
    isLoading: false,
    fullfiled: false,
    payload: {
        content: {
            catalog: {
                id: 0,
                version: 0,
                reference: '',
                numberOfPreset: 0,
                name: '',
                createdAt: new Date(),
                updatedAt: new Date(),
            },
            catalogUserList: [],

        },
        errors: [],
        warnings: [],
    },
};


export type CatalogState = {
    isLoading: boolean;
    fullfiled: boolean
    payload: MdfApiResponse<Catalog>;
};
export type CatalogListState = {
    isLoading: boolean;
    payload: MdfApiResponse<Catalog[]>;
};
export type TreeCatalogState = {
    isLoading: boolean;
    fullfiled: boolean
    payload: MdfApiResponse<TreeCatalog>;
};

const initialTreeCatalogState: TreeCatalogState = {
    isLoading: false,
    fullfiled: false,
    payload: {
        content: {
            sections: [],
            catalog: {
                id: 0,
                version: 0,
                reference: '',
                numberOfPreset: 0,
                name: '',
                createdAt: new Date(),
                updatedAt: new Date(),
            },
        },
        errors: [],
        warnings: [],
    },

};
const initialCatalogState: CatalogState = {
    isLoading: false,
    fullfiled: false,
    payload: {
        content: {
            id: 0,
            reference: '',
            numberOfPreset: 0,
            version: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
            name: '',
        },
        errors: [],
        warnings: [],
    },
};
const initialCatalogListState: CatalogListState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};
//endregion

//#region Create And update Catalog

export const createAndUpdateCatalog = createAsyncThunk('create/catalog', async (createForm: CreateForm) => {
    const res = await CatalogServices.createAndUpdateCatalog(createForm);
    return res;
});

export const createAndUpdateCatalogSlice = createSlice({
    name: 'createCatalog',
    initialState: initialCatalogResponse,
    reducers: {
        resetCreateCatalog: (state) => {
            state = initialCatalogResponse;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createAndUpdateCatalog.pending, (state) => {
            state.isLoading = false;
            state.fullfiled = false;
        });
        builder.addCase(createAndUpdateCatalog.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, fullfiled: true };
            return state;
        });
        builder.addCase(createAndUpdateCatalog.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetCreateCatalog } = createAndUpdateCatalogSlice.actions;
//#endregion

//#region get All Catalogs
export const getCatalogs = createAsyncThunk('get/catalogs', async () => {
    const res = await CatalogServices.getAllCatalog();
    return res;
});

export const getCatalogsSlice = createSlice({
    name: 'getCatalogs',
    initialState: initialCatalogListState,
    reducers: {
        resetGetCatalogs: (state) => {
            state = initialCatalogListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCatalogs.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getCatalogs.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getCatalogs.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetCatalogs } = getCatalogsSlice.actions;
//#endregion

//#region get Catalog by Id
export const getCatalogById = createAsyncThunk('get/catalogById', async (catalogId: number) => {
    const res = await CatalogServices.getCatalogById(catalogId);
    return res;
});

export const getCatalogByIdSlice = createSlice({
    name: 'getCatalogById',
    initialState: initialCatalogState,
    reducers: {
        resetGetCatalogById: (state) => {
            state = initialCatalogState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCatalogById.pending, (state) => {
            state.isLoading = false;
            state.fullfiled = false;
        });
        builder.addCase(getCatalogById.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, fullfiled: true };
            return state;
        });
        builder.addCase(getCatalogById.rejected, (state) => {
            state.fullfiled = false;
            state.isLoading = false;
        });
    },
});

export const { resetGetCatalogById } = getCatalogByIdSlice.actions;
//#endregion

//#region get Catalog by Product Id
export const getCatalogsByProductId = createAsyncThunk('get/catalogsByProductId', async (productId: number) => {
    const res = await CatalogServices.getCatalogsByProductId(productId);
    return res;
});

export const getCatalogsByProductIdSlice = createSlice({
    name: 'getCatalogsByProductId',
    initialState: initialCatalogListState,
    reducers: {
        resetGetCatalogsByProductId: (state) => {
            state = initialCatalogListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCatalogsByProductId.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getCatalogsByProductId.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getCatalogsByProductId.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetCatalogsByProductId } = getCatalogsByProductIdSlice.actions;
//#endregion

//#region get Catalog by Ref
export const getCatalogByRef = createAsyncThunk('get/catalogByRef', async (catalogRef: string) => {
    const res = await CatalogServices.getCatalogByRef(catalogRef);
    return res;
});

export const getCatalogByRefSlice = createSlice({
    name: 'getCatalogByRef',
    initialState: initialCatalogResponse,
    reducers: {
        resetGetCatalogByRef: (state) => {
            state = initialCatalogResponse;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCatalogByRef.pending, (state) => {
            state.isLoading = false;
            state.fullfiled = false;
        });
        builder.addCase(getCatalogByRef.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, fullfiled: true };
            return state;
        });
        builder.addCase(getCatalogByRef.rejected, (state) => {
            state.fullfiled = false;
            state.isLoading = false;
        });
    },
});

export const { resetGetCatalogByRef } = getCatalogByRefSlice.actions;
//#endregion

//#region delete Catalog
export const deleteCatalog = createAsyncThunk('delete/catalog', async (catalogId: number) => {
    const res = await CatalogServices.deleteCatalog(catalogId);
    return res;
});

export const deleteCatalogSlice = createSlice({
    name: 'deleteCatalog',
    initialState: initialCatalogState,
    reducers: {
        resetDeleteCatalog: (state) => {
            state = initialCatalogState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteCatalog.pending, (state) => {
            state.isLoading = false;
            state.fullfiled = false;
        });
        builder.addCase(deleteCatalog.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, fullfiled: true };
            return state;
        });
        builder.addCase(deleteCatalog.rejected, (state) => {
            state.fullfiled = false;
            state.isLoading = false;
        });
    },
});

export const { resetDeleteCatalog } = deleteCatalogSlice.actions;
//#endregion

//#region get tree catalog
export const getTreeCatalog = createAsyncThunk('get/treeCatalog', async (catalogId: number) => {
    const res = await CatalogServices.treeCatalog(catalogId);
    return res;
});

export const getTreeCatalogSlice = createSlice({
    name: 'getTreeCatalog',
    initialState: initialTreeCatalogState,
    reducers: {
        resetGetTreeCatalog: (state) => {
            state = initialTreeCatalogState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTreeCatalog.pending, (state) => {
            state.isLoading = false;
            state.fullfiled = false;
        });
        builder.addCase(getTreeCatalog.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false, fullfiled: true };
            return state;
        });
        builder.addCase(getTreeCatalog.rejected, (state) => {
            state.fullfiled = false;
            state.isLoading = false;
        });
    },
});

export const { resetGetTreeCatalog } = getTreeCatalogSlice.actions;
//#endregion

//#region link product to catalog
export type LinkToCatalogState = { isLoading: boolean; success: boolean };
const initialLinkToCatalogState: LinkToCatalogState = { isLoading: false, success: false };
export const linkProductToCatalog = createAsyncThunk('link product to/catalog', async (data: {
    catalogId: number,
    productId: number
}) => {
    const res = await CatalogServices.linkPresetToSubSection(data.catalogId, data.productId);
    return res;
});

export const linkProductToCatalogSlice = createSlice({
    name: 'linkProductToSubCatalog',
    initialState: initialLinkToCatalogState,
    reducers: {
        resetLinkProductToCatalog: (state) => {
            state = initialLinkToCatalogState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(linkProductToCatalog.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(linkProductToCatalog.fulfilled, (state, { payload }) => {
            state = { isLoading: false, success: true };
            return state;
        });
        builder.addCase(linkProductToCatalog.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetLinkProductToCatalog } = linkProductToCatalogSlice.actions;
//#endregion

//#region unLink product to catalog
export type UnlinkToCatalogState = { isLoading: boolean; success: boolean };
const initialUnlinkToCatalogState: UnlinkToCatalogState = { isLoading: false, success: false };
export const unlinkProductToSubCatalog = createAsyncThunk('unlink product to/catalog', async (data: {
    catalogId: number,
    productId: number
}) => {
    const res = await CatalogServices.unLinkPresetToSubSection(data.catalogId, data.productId);
    return res;
});

export const unlinkProductToCatalogSlice = createSlice({
    name: 'linkProductToSubCatalog',
    initialState: initialUnlinkToCatalogState,
    reducers: {
        resetUnlinkProductToCatalog: (state) => {
            state = initialUnlinkToCatalogState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(unlinkProductToSubCatalog.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(unlinkProductToSubCatalog.fulfilled, (state, { payload }) => {
            state = { isLoading: false, success: true };
            return state;
        });
        builder.addCase(unlinkProductToSubCatalog.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUnlinkProductToCatalog } = unlinkProductToCatalogSlice.actions;
//#endregion

//region catalog-name
export const catalogSlice = createSlice({
    name: 'catalogName',
    initialState: initialCatalogName,
    reducers: {
        SetCatalogName: (state, action) => {
            state.name = action.payload;
        },
    },
});
export const { SetCatalogName } = catalogSlice.actions;
//endregion
