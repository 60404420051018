import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthenticationResponse, AuthSigninForm, ResetPasswordForm, User } from '../../../domain/domain';
import AuthService from '../../../api/services/authentication/auth.service';
import MdfApiResponse from '../../../domain/common/generic';
import { CreateUserType } from '../../../uicomponents/create-user-form/create-user.type';

export type AuthState = {
    isLoading: boolean;
    payload: MdfApiResponse<AuthenticationResponse | null>;
};

const initialState: AuthState = {
    isLoading: false,
    payload: { content: null, errors: [], warnings: [] },
};

export const logIn = createAsyncThunk('login', async (loginForm: AuthSigninForm, thunkAPI) => {
    const response = await AuthService.loginUser(loginForm);
    return response;
});

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        resetAuth: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logIn.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(logIn.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(logIn.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// ........ CREATE ACCOUNT...........

export type CreateFirstUserState = {
    isLoading: boolean;
    payload: MdfApiResponse<User | null>;
};

const createUserInitialState: CreateFirstUserState = {
    isLoading: false,
    payload: { content: null, errors: [], warnings: [] },
};

export const createFirstUser = createAsyncThunk('create-first-user', async (form: CreateUserType, thunkAPI) => {
    const response = await AuthService.createFirstUser(form);
    return response;
});

export const createFirstUserSlice = createSlice({
    name: 'createFirstUserSlice',
    initialState: createUserInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createFirstUser.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createFirstUser.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createFirstUser.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export type CreatePasswordState = {
    isLoading: boolean;
    payload: MdfApiResponse<User | null>;
};

const createPasswordInitialState: CreatePasswordState = {
    isLoading: false,
    payload: { content: null, errors: [], warnings: [] },
};
export const createPassword = createAsyncThunk('create-password', async (form: ResetPasswordForm, thunkAPI) => {
    const response = await AuthService.createPassword(form);
    return response;
});

export const createPasswordSlice = createSlice({
    name: 'createPasswordSlice',
    initialState: createPasswordInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createPassword.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createPassword.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createPassword.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

// FORGOT PASSWORD

export type ForgotPasswordState = {
    isLoading: boolean;
    payload: MdfApiResponse<boolean | null>;
};

const forgotPasswordInitialState: ForgotPasswordState = {
    isLoading: false,
    payload: { content: null, errors: [], warnings: [] },
};

export const forgotPassword = createAsyncThunk('forgot-password', async (form: AuthSigninForm, thunkAPI) => {
    const response = await AuthService.forgotPassword(form);
    return response;
});

export const forgotPasswordSlice = createSlice({
    name: 'forgotPasswordSlice',
    initialState: forgotPasswordInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(forgotPassword.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(forgotPassword.fulfilled, (state, payload) => {
            state = { payload: payload.payload, isLoading: false };
            return state;
        });
        builder.addCase(forgotPassword.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetAuth } = authSlice.actions;
