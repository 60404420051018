import { Collection, CollectionCreationForm } from '../../../../domain/domain';
import MdfApiResponse from '../../../../domain/common/generic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CollectionsService from '../../../../api/services/suppliers/collection/collections.service';

//<editor-fold desc="get-collection">
export type CollectionState = {
    isLoading: boolean;
    payload: MdfApiResponse<Collection | undefined>;
};

export type CollectionListState = {
    isLoading: boolean;
    payload: MdfApiResponse<Collection[] | undefined>;
};

const initialCollectionState: CollectionState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

const initialCollectionListState: CollectionListState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

export const getCollection = createAsyncThunk('get/collection', async (collectionReference: string) => {
    const res = await CollectionsService.getCollection(collectionReference);
    return res;
});

export const getCollectionSlice = createSlice({
    name: 'getCollection',
    initialState: initialCollectionState,
    reducers: {
        resetGetCollection: (state) => {
            state = initialCollectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCollection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getCollection.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getCollection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetCollection } = getCollectionSlice.actions;
//</editor-fold>

//<editor-fold desc="get-collections">
export const getCollections = createAsyncThunk('get/collections', async (supplierReference: string) => {
    const res = await CollectionsService.getCollections(supplierReference);
    return res;
});

export const getCollectionsSlice = createSlice({
    name: 'getCollections',
    initialState: initialCollectionListState,
    reducers: {
        resetGetCollections: (state) => {
            state = initialCollectionListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCollections.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getCollections.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getCollections.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetCollections } = getCollectionsSlice.actions;
//</editor-fold>

//<editor-fold desc="create-collection">
export const createCollection = createAsyncThunk('create/collection', async (data: { data: CollectionCreationForm; supplierReference: string }) => {
    const res = await CollectionsService.createCollection(data.supplierReference, data.data);
    return res;
});

export const createCollectionSlice = createSlice({
    name: 'createCollections',
    initialState: initialCollectionState,
    reducers: {
        resetCreateCollection: (state) => {
            state = initialCollectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createCollection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createCollection.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createCollection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateCollection } = createCollectionSlice.actions;
//</editor-fold>

//<editor-fold desc="update-collection">
export const updateCollection = createAsyncThunk('update/collection', async (data: { data: CollectionCreationForm; collectionReference: string }) => {
    const res = await CollectionsService.updateCollection(data.collectionReference, data.data);
    return res;
});

export const updateCollectionSlice = createSlice({
    name: 'updateCollection',
    initialState: initialCollectionState,
    reducers: {
        resetUpdateCollection: (state) => {
            state = initialCollectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateCollection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateCollection.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateCollection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateCollection } = updateCollectionSlice.actions;
//</editor-fold>

//<editor-fold desc="delete-collection">
export const deleteCollection = createAsyncThunk('get/delete', async (collectionReference: string) => {
    const res = await CollectionsService.deleteCollection(collectionReference);
    return res;
});

export const deleteCollectionSlice = createSlice({
    name: 'deleteCollection',
    initialState: initialCollectionState,
    reducers: {
        resetDeleteCollection: (state) => {
            state = initialCollectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteCollection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteCollection.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteCollection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteCollection } = deleteCollectionSlice.actions;
//</editor-fold>
