import { AuthSigninForm, ResetPasswordForm } from '../../../domain/domain';
import apiClient from '../../api-client';
import {
    CREATE_FIRST_USER,
    CREATE_PASSWORD,
    FORGOT_PASSWORD,
    LOGIN,
} from '../../endpoints/authentication/auth.endpoints';
import { CreateUserType } from '../../../uicomponents/create-user-form/create-user.type';

const AuthService = {
    loginUser: async (form: AuthSigninForm) => apiClient(LOGIN, form, undefined, 'POST'),
    createFirstUser: async (form: CreateUserType) => apiClient(CREATE_FIRST_USER, form, undefined, 'POST'),
    createPassword: async (form: ResetPasswordForm) => apiClient(CREATE_PASSWORD, form, undefined, 'POST'),
    forgotPassword: async (form: AuthSigninForm) => apiClient(FORGOT_PASSWORD, form, undefined, 'POST'),
};

export default AuthService;
