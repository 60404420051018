import {Engine} from "@lutithree/build/Engine";
import {MeshRendererComponent} from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent";
import {MeshStandardMaterial} from "three";
import {SceneEntity} from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";

export class BackgroundService {
    private m_engine: Engine;

    private bckgdEnvEntity: SceneEntity | undefined;


    public constructor(p_engine: Engine) {
        if (p_engine == null) throw new Error('NullReference Exception: p_engine is null or undefined');
        this.m_engine = p_engine;
    }

    public GetBackgroundRoughness(): number {
        if (this.bckgdEnvEntity) {
            if (this.bckgdEnvEntity.HasComponentOfType(MeshRendererComponent)) {
                return (this.bckgdEnvEntity.GetComponentOfType(MeshRendererComponent).Material as MeshStandardMaterial).roughness;
            }
        }
        return -1;
    }
    
    public SetBackgroundRougness(p_value: number) {
        if (this.bckgdEnvEntity) {
            if (this.bckgdEnvEntity.HasComponentOfType(MeshRendererComponent)) {
                (this.bckgdEnvEntity.GetComponentOfType(MeshRendererComponent).Material as MeshStandardMaterial).roughness = p_value;
            }
        }
    }

    public SetBackgroundStudio(p_value: boolean) {
        if (this.bckgdEnvEntity) {
            this.bckgdEnvEntity.Enable(!p_value);
        }
    }
    
    public CreateBackgoundEntity() : SceneEntity {
        this.bckgdEnvEntity = this.m_engine.Modules.Scene.CreateEntity('Environement_Background');
        return this.bckgdEnvEntity;
    }
}