import moment from 'moment';

const DateFormatter = {
    stringTimestampToDateTime: (value: number) => {
        return moment.unix(value).format('DD/MM/YYYY');
    },

    dateTimeStampToDateTime: (date: Date) => {
        if (date === null || date === undefined) {
            return '--/--/--';
        }
        const numberDate = Number(date);
        if (isNaN(numberDate)) {
            return '--/--/--';
        }
        return DateFormatter.stringTimestampToDateTime(numberDate);
    },

    stringTimestampToDateTimeWithHours: (value: number) => {
        return moment.unix(value).format('DD/MM/YYYY HH:mm');
    },
    dateTimeStampToDateTimeWithHours: (date: Date | null) => {
        if (date === null || date === undefined) {
            return '--/--/-- --:--';
        }
        const numberDate = Number(date);
        if (isNaN(numberDate)) {
            return '--/--/-- --:--';
        }
        return DateFormatter.stringTimestampToDateTimeWithHours(numberDate);
    },
};

export default DateFormatter;
