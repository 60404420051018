import './PresetEditor.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import editer from '../../../../asset/Editer.svg';
import send from '../../../../asset/Envoyer.svg';
import blackandwhite from "../../../../asset/blackWhite.svg";
import BasicObject
    from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import {PresetTree} from "../../../../domain/domain";
import ResourceErrorData
    from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ResourceErrorData";
import {RootState} from "../../../../services/root-reducer";
import {
    ProductStudioState,
} from "../../../application3D-common/Redux/Reducers/product-studio/ProductStudioReducer";
import {CharacteristicStudioState} from "../../Redux/Reducers/characteristic-studio/CharacteristicStudioReducer";
import {ConfigData} from "../../../application3D-common/Librairies/Studios/Application3D/Domain/ConfigData";
import configuredProductService from "../../../../api/services/products/configured-product/configured-product.service";
import {DebugManager} from "../../../application3D-common/Components/Debug/DebugManager";
import ProductStudio from "../../../application3D-common/Components/ProductStudio/ProductStudio";
import ObjectDatas from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";


export type PresetEditorProps = {
    object: ObjectDatas | undefined;
    setOpenPanel: (openPanel: boolean) => void;
    openPanel: boolean;
    setOpenEditPanel: (openEditPanel: boolean) => void;
    refreshPresetName: (newValue: string) => void;
    preset: PresetTree | undefined;
    onLoadingFail?: (p_resourceErrorData: ResourceErrorData) => void;
};

const PresetEditor = (props: PresetEditorProps) => {

    const dispatch = useDispatch();
    const {productStudioApp} = useSelector<RootState, ProductStudioState>((state) => state.productStudio);
    const {
        pointOfViewPlacement,
    } = useSelector<RootState, CharacteristicStudioState>((state) => state.characteristicStudioReducer);
    
    
    const [config] = useState<ConfigData | undefined>();
    const [usingBrightMode, setBrightMode] = useState<boolean>(true);
    const [openUpdateM, setOpenUpdateM] = useState('');
    const [newName, setNewName] = useState('');
    const [selectedPreset, setSelectedPreset] = useState<PresetTree>();
    const [data, SetData] = useState<ObjectDatas>(new ObjectDatas());
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        props.preset && setNewName(props.preset.product.name);
    }, []);

    const keyDownHandler = (event: any) => {
        if (event.key === 'Enter') {
            register(newName);
            event.preventDefault();
            handleSubmit(onSubmitRenamePreset)();
        }
    };
    const removeDash = (string: string) => {
        const l = string.split('-');
        return l.length > 1 ? l.shift() : string;
    };
    const onSubmitRenamePreset = (p_data: any) => {
        setOpenUpdateM('');
        if (selectedPreset) {
            props.preset && configuredProductService.renameConfiguredProduct(props.preset.product.reference, p_data.name)
                .then(() => {
                    props.refreshPresetName(p_data.name);
                    setNewName(p_data.name);
                    setOpenUpdateM('');
                });
        }
    };

    return (
        <div className='application3d-container'>
            { config && <DebugManager config={ config } /> }
            <div className='thumb-preview-cont-preset'>
                <div className='relative-bloc-preseti'>
                    <span className='span1-preset'>Nom</span>
                    <div className='span2-preset'>
                        { props.preset && openUpdateM === props.preset.product.reference ? (
                            <form onSubmit={ handleSubmit(onSubmitRenamePreset) } onKeyDown={ keyDownHandler }>
                                <input className='product3dnaming' { ...register('name') } />
                                <button type='submit'>
                                    <img alt='envoyé'
                                         onClick={ () => handleSubmit(onSubmitRenamePreset) }
                                         src={ send } />
                                </button>
                            </form>
                        ) : (
                            <div className='b'>
                                <span
                                    onClick={ () => {
                                        props.preset && setOpenUpdateM(props.preset.product.reference);
                                        setSelectedPreset(props.preset);
                                        props.preset && setValue('name', removeDash(props.preset.product.name));
                                    } }
                                >{ props.preset && removeDash(newName) }</span>
                                <img
                                    onClick={ () => {
                                        props.preset && setOpenUpdateM(props.preset.product.reference);
                                        setSelectedPreset(props.preset);
                                        props.preset && setValue('name', removeDash(props.preset.product.name));
                                    } }
                                    alt='logo editer'
                                    src={ editer }
                                />
                            </div>

                        ) }
                    </div>
                </div>

                <div className='switch-to-dark'
                     onClick={ () => {
                         productStudioApp?.Services.BackgroundService.SwitchColorMode(!usingBrightMode);
                         setBrightMode(!usingBrightMode);
                     } }>
                    <img alt='switch to dark mode' src={ blackandwhite } />
                    <span>Fond foncé</span>
                </div>
                
                <ProductStudio
                    datas={props.object}
                    product={ props.object?.FirstRootObject! }
                    openPanel={ props.openPanel }
                    setOpenPanel={ props.setOpenPanel }
                    setOpenEditPanel={ props.setOpenEditPanel }
                    isVisible={ true }
                    isPovIhmVisible={true}
                    onLoadingFail={ props.onLoadingFail }
                />
            </div>
        </div>
    );
};

export default PresetEditor;
