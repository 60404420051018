const GET_CATALOGS = `v1/catalogs/`;
const CREATE_UPDATE_CATALOG = `v1/catalogs/`;
const GET_CATALOG_BY_ID = (catalogId: number) => `v1/catalogs/catalogById/${ catalogId }`;
const GET_CATALOG_BY_REF = (catalogRef: string) => `v1/catalogs/reference/${ catalogRef }`;
const FIND_CATALOGS_BY_PRODUCT_ID = (productId: number) => `v1/products-catalogs/product/${ productId }/catalogs/`;
const DELETE_CATALOG = (catalogId: number) => `v1/catalogs/${ catalogId }`;
const TREE_CATALOG = (catalogId: number) => `v1/products-catalogs/${ catalogId }/tree-catalog/`;
const LINK_PRODUCT_TO_CATALOG = (catalogId: number, productId: number) => `v1/products-catalogs/${ catalogId }/product/${ productId }`;
const UNLINK_PRODUCT_TO_CATALOG = (catalogId: number, productId: number) => `v1/products-catalogs/${ catalogId }/product/${ productId }`;
const LINK_ALL_PRODUCT_FROM_COLLECTION_TO_CATALOG = (catalogId: number, collectionId: number) => `v1/products-catalogs/${catalogId}/collection/${collectionId}`;

const REMOVE_ALL_PRODUCT_FROM_COLLECTION_TO_CATALOG = (catalogId: number, collectionId: number) => `v1/products-catalogs/${catalogId}/collection/${collectionId}`;

export {
    GET_CATALOGS,
    GET_CATALOG_BY_ID,
    FIND_CATALOGS_BY_PRODUCT_ID,
    DELETE_CATALOG,
    CREATE_UPDATE_CATALOG,
    GET_CATALOG_BY_REF,
    LINK_ALL_PRODUCT_FROM_COLLECTION_TO_CATALOG,
    REMOVE_ALL_PRODUCT_FROM_COLLECTION_TO_CATALOG,
    TREE_CATALOG,
    LINK_PRODUCT_TO_CATALOG,
    UNLINK_PRODUCT_TO_CATALOG,
};
