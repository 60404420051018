import DatePicker, { getDefaultLocale, registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useEffect, useState } from "react";
import fr from "date-fns/locale/fr";

registerLocale("french", fr);
setDefaultLocale("french");

type TimePickerProps = {
  name: string;
  title: string;
  setScheduledDate: (chooseScheduledDate: Date | null) => void;
};

const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
  <input type="text" value={value} onClick={onClick} ref={ref} readOnly />
));

const TimePickerUi = ({ name, title, setScheduledDate }: TimePickerProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [textErrorMessage, setTextErrorMessage] = useState<string>("");

  useEffect(() => {
    setScheduledDate(new Date());
  }, []);

  return (
    <div className="timePickerContainer">
      <p>{title}</p>
      <DatePicker
        customInput={<CustomInput />}
        selected={selectedDate}
        onChange={(date) => {
          if (date instanceof Date) {
            setSelectedDate(date);
            if (date < new Date()) {
              setScheduledDate(null);
              setTextErrorMessage("Entrer une date et une heure supérieur à la date et l'heure du jour");
            } else {
              setScheduledDate(date);
              setTextErrorMessage("");
            }
          }
        }}
        showTimeSelect
        isClearable
        locale={getDefaultLocale()}
        name={name}
        title={title}
        dateFormat="dd/MM/yyyy HH:mm"
        minDate={new Date()}
        timeCaption="time"
        timeFormat="HH:mm"
        placeholderText="Choose a date"
      />

      <div>
        {textErrorMessage.length > 0 && <p className="errorMess"> {textErrorMessage}</p>}
        {textErrorMessage.length === 0 && <p className="errorMess"> {textErrorMessage}</p>}
      </div>
    </div>
  );
};

export default TimePickerUi;
