import { createSlice } from '@reduxjs/toolkit';

export type CanReturnState = {
    canReturn: boolean
};

const initialCanReturnState: CanReturnState = {
    canReturn: true,
};

export const canReturnSlice = createSlice({
    name: 'canReturn',
    initialState: initialCanReturnState,
    reducers: {
        SetCanReturn: (state, action) => {
            state.canReturn = action.payload;
        },
        ResetCanReturn: (state) => {
            state.canReturn = initialCanReturnState.canReturn;
        },
    },
});

export const { SetCanReturn, ResetCanReturn } = canReturnSlice.actions;