import './atom.scss';
import { ModelTree, PresetTree } from '../../../../../../domain/domain';

type AtomProps = {
    preset: PresetTree;
    itemWithImg: ModelTree;
    available: boolean;
    onChangeValue: (itemWithImg: ModelTree, checked: boolean) => void;
    thumb: boolean;
    confAtomRefList: string[]
};

const AtomV2 = (props: AtomProps) => {
    const returnFocus = (label: string) => {
        return props.confAtomRefList.includes(props.itemWithImg.atom.reference) ? `${ label } focus` : label;
    };

    const returnFocusi = (label: string) => {
        return props.confAtomRefList.includes(props.itemWithImg.atom.reference) ? `${ label }-focus` : label;
    };

    return (
        <div className='main-atom'>
            { props.thumb ? (
                <div
                    className={ props.available ? returnFocus('atom-thumb') : 'atom-thumb-disabled' }
                    onClick={ () => {
                        props.onChangeValue(props.itemWithImg, true);
                    } }
                >
                    <div className='img'>
                        <img alt='vignette' src={ props.itemWithImg.atom.thumbnailUrl } />
                    </div>
                    <div>{ props.itemWithImg.atom.label }</div>
                </div>
            ) : (
                <div
                    onClick={ () => {
                        props.onChangeValue(props.itemWithImg, true);
                    } }
                    className={ props.available ? returnFocusi('atom-text') : 'atom-text-disabled' }
                >
                    { props.itemWithImg.atom.label }
                </div>
            ) }
        </div>
    );
};

export default AtomV2;
