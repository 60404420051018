import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from '../../../api/services/user/user.service';
import { CreateUserType } from '../../../uicomponents/create-user-form/create-user.type';

const initialState = {
    isLoading: false,
    isRejected: false,
    errorMessage: '',
    successMessage: '',
    payload: { content: null, errors: [], warnings: [] },
};

type GetUserData = {
    userId: number,
    createUserForm: CreateUserType,
};

export const updateUser = createAsyncThunk('update/create', async (data: GetUserData, thunkApi) => {
    const response = await UserService.updateUser(data.userId,data.createUserForm);
    return response;
});

const updateUserSlice = createSlice({
    name: 'updateUserSlice',
    initialState: initialState,
    reducers: {
        resetUpdateUser: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateUser.fulfilled, (state, { payload }) => {
            state = {
                payload,
                isLoading: false,
                isRejected: false,
                errorMessage: '',
                successMessage: 'requête resussi avec succes',
            };
            return state;
        });
        builder.addCase(updateUser.rejected, (state) => {
            state.isLoading = false;
            state.isRejected = true;
            state.successMessage = '';
            state.errorMessage = 'Erreur lors de la requête';
            state.isLoading = false;
        });
    },
});

export const { resetUpdateUser } = updateUserSlice.actions;
export default updateUserSlice.reducer;
