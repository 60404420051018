import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from '../../../api/services/user/user.service';

export type DeleteUserState = {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string;
    isRejected: boolean;
};

const initialState: DeleteUserState = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    isRejected: false,
};

type GetUsersParameters = {
    userId: number;
};

export const deleteUser = createAsyncThunk('user/delete', async (data: GetUsersParameters, thunkApi) => {
    const response = await UserService.deleteUser(data.userId);
    return response;
});

export const deleteUsersSlice = createSlice({
    name: 'deleteUserSlice',
    initialState,
    reducers: {
        resetUserDeletion: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteUser.fulfilled, (state, { meta }) => {
            state = {
                isLoading: false,
                isRejected: false,
                errorMessage: '',
                successMessage: `l'utilisateur ${meta.arg.userId} est supprimé`,
            };
            return state;
        });
        builder.addCase(deleteUser.rejected, (state) => {
            state.isLoading = false;
            state.successMessage = '';
            // @ts-ignore
            state.errorMessage = error.message;
            state.isRejected = true;
        });
    },
});

export type ResetPasswordUserState = {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string;
    isRejected: boolean;
};

const initialResetPasswordState: ResetPasswordUserState = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    isRejected: false,
};

export const resetPasswordUserByAdmin = createAsyncThunk('user/resetPassword', async (data: GetUsersParameters, thunkApi) => {
    const response = await UserService.resetPasswordUserByAdmin(data.userId);
    return response;
});

export const resetPasswordUserSlice = createSlice({
    name: 'resetPasswordUserSlice',
    initialState: initialResetPasswordState,
    reducers: {
        resetUserDeletion: (state) => {
            state = initialResetPasswordState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetPasswordUserByAdmin.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(resetPasswordUserByAdmin.fulfilled, (state, { meta }) => {
            state = {
                isLoading: false,
                isRejected: false,
                errorMessage: '',
                successMessage: `la réinitialisation du mot de passe de ${meta.arg.userId} est bien faite`,
            };
            return state;
        });
        builder.addCase(resetPasswordUserByAdmin.rejected, (state) => {
            state.isLoading = false;
            state.successMessage = '';
            state.errorMessage = 'Echec de la réinitialisation du mot de passe';
            state.isRejected = true;
        });
    },
});

export const { resetUserDeletion } = deleteUsersSlice.actions;
//export default deleteUsersSlice.reducer;
