import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MdfApiResponse from '../../../domain/common/generic';
import { NewsCategory } from '../../../domain/domain';
import NewsService from '../../../api/services/news/news.service';

export type NewsCategoryCreationState = {
    isLoading: boolean;
    payload: MdfApiResponse<NewsCategory | null>;
};

const initialState: NewsCategoryCreationState = {
    isLoading: false,
    payload: { content: null, errors: [], warnings: [] },
};
type CategoryTheme = {
    name: string
    color: string,
};

export const createNewsCategory = createAsyncThunk(
    'news/category/create',
    async (data: CategoryTheme,
           thunkAPI): Promise<MdfApiResponse<NewsCategory>> => {
        const response = await NewsService.createNewsCategory(data.name, data.color);
        return response;
    });

const createNewsCategorySlice = createSlice({
    name: 'createNewsCategorySlice',
    initialState: initialState,
    reducers: {
        resetCreateNews: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createNewsCategory.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createNewsCategory.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createNewsCategory.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

export const { resetCreateNews } = createNewsCategorySlice.actions;
export default createNewsCategorySlice.reducer;
