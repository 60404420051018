import './section.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
    deleteSections,
    resetCreateSection,
    resetDeleteSection,
    resetUpdateSection,
    SectionState,
} from '../../../../services/reducers/catalog/section.slice';
import { RootState } from '../../../../services/root-reducer';
import { Catalog, Section, SectionWithSubSections } from '../../../../domain/domain';
import trash from '../../../../asset/poubelle-red.svg';
import edit from '../../../../asset/Editer.svg';
import plus from '../../../../asset/plus-circle.svg';
import SimpleModal from '../../../../uicomponents/modal/simple-modal/simple-modal';
import CreateSection from '../../../../components/modals/catalog/create-section/create-section';
import { useLocation } from 'react-router-dom';
import { getTreeCatalog } from '../../../../services/reducers/catalog/catalog.slice';
import logoMdf from '../../../../asset/logo-mdf.jpeg';


type SectionPanelProps = {
    sectionFocus: SectionWithSubSections | undefined
    setSectionFocus: (sectionFocus: SectionWithSubSections | undefined) => void
    catalog: Catalog
    sections: SectionWithSubSections[]
};
const SectionPanel = ({ sectionFocus, setSectionFocus, catalog, sections }: SectionPanelProps) => {
        const dispatch = useDispatch();
        const location = useLocation();

        const [isMount, setIsMount] = useState(false);
        const [upSection, setUpSection] = useState<Section | undefined>(undefined);
        const [deleteModal, setDeleteModal] = useState(false);
        const [createModal, setCreateModal] = useState(false);
        const [hovImg, setHovImg] = useState({ enter: false, id: 0 });
        const [sectionId, setSectionId] = useState<number>(0);

        const deleteSectionRes = useSelector<RootState, SectionState>(state => state.deleteSection);
        const createSectionRes = useSelector<RootState, SectionState>(state => state.createSection);
        const updateSectionRes = useSelector<RootState, SectionState>(state => state.updateSection);

        const setComboFocus = (s: SectionWithSubSections, id: number) => {
            setSectionFocus(s);
            setSectionId(id);
        };
        useEffect(() => {
            const findSectionWithSubSection = sections.find((s) => s.id === sectionId);
            sectionId > 0 && setSectionFocus(findSectionWithSubSection);
        }, [sections]);
        useEffect(() => {
            if (createSectionRes.payload.content.id !== 0 && deleteSectionRes.payload.errors.length === 0) {
                catalog.id !== 0 && dispatch(getTreeCatalog(catalog.id));
                setSectionId(createSectionRes.payload.content.id);
                dispatch(resetCreateSection());
            }
        }, [createSectionRes]);
        useEffect(() => {
            if (deleteSectionRes.success && deleteSectionRes.payload.errors.length === 0) {
                catalog.id !== 0 && dispatch(getTreeCatalog(catalog.id));
                sections.length > 0 && setComboFocus(sections[1], sections[1].id);
                setDeleteModal(false);
                dispatch(resetDeleteSection());
            }
        }, [deleteSectionRes]);
        useEffect(() => {
            if (updateSectionRes.payload.content.id !== 0 && updateSectionRes.payload.errors.length === 0) {
                dispatch(getTreeCatalog(catalog.id));
                dispatch(resetUpdateSection());
            }
        }, [updateSectionRes]);

        useEffect(() => {
            const findSectionWithSubSection = sections.find((s) => s.id === sectionId);
            setSectionFocus(findSectionWithSubSection);
        }, [sectionId]);

        return (
            <div className='section-main'>
                { deleteModal &&
                    <SimpleModal
                        icon={ trash }
                        title='Supprimer une rubrique ?'
                        info='Cette action supprimera la rubrique'
                        closeOnclick={ () => setDeleteModal(false) }
                        actionOnclick={ () => sectionFocus && dispatch(deleteSections(sectionFocus.id)) }
                        deleteOrValide={ true }
                        closeLabel='Annuler'
                        actionLabel='Supprimer' /> }
                {
                    createModal &&
                    <CreateSection
                        catalog={ catalog }
                        setSection={ setUpSection }
                        section={ upSection }
                        setOpenModal={ setCreateModal } />
                }
                <div className='section-title'>
                    <div> RUBRIQUES</div>
                    <div onClick={ () => {
                        setCreateModal(true);
                    } } className='create-section'>
                        <img alt='ajouter une rubrique' src={ plus } />
                        <span>Créer une rubrique</span>
                    </div>
                </div>
                <div className='section-body'>
                    { sections.map((section: SectionWithSubSections) => {
                        return (
                            <div
                                key={ section.id }
                                className={ sectionId === section.id ? 'section focus' : 'section' }
                                onClick={ () => setSectionId(section.id) }>
                                <div className='section-left'>
                                    <img className='section-img'
                                         onMouseEnter={ () => setHovImg({ enter: true, id: section.id }) }
                                         onMouseLeave={ () => setHovImg({ enter: false, id: 0 }) }
                                         alt='vignette rubrique' src={ section.logo !== null ? section.logo : logoMdf } />
                                    <span>{ section.name }</span>
                                    { hovImg.enter && section.id === hovImg.id &&
                                        <img className='img-hov' alt='zoom'
                                             src={ section.logo !== null ? section.logo : logoMdf } /> }
                                </div>
                                <div className='section-right'>
                                    { section.id !== null &&
                                        <>
                                            <img
                                                onClick={ () => {
                                                    setUpSection(section);
                                                    setCreateModal(true);
                                                } }
                                                alt='editer' src={ edit } />
                                            <img onClick={ () => {
                                                setSectionFocus(section);
                                                setDeleteModal(true);
                                            } } alt='supprimer rubrique' src={ trash } />
                                        </>
                                    }
                                </div>
                            </div>
                        );
                    }) }
                </div>
                <div className='foo' />
            </div>
        );
    }
;
export default SectionPanel;
