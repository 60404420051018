import React from 'react';
import { MainRouter } from './routes';
import { Provider } from 'react-redux';
import store from '../services/store';

function App() {
    return (
        <Provider store={store}>
            <MainRouter />
        </Provider>
    );
}

export default App;
