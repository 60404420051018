const GET_PRODUCTS = () => `v1/product/`;
const GET_PRODUCTS_BY_COLLECTION = (collectionReference: string) => `v1/product/collection/${ collectionReference }`;
const GET_PRESETS_BY_COLLECTION = (collectionReference: string) => `v1/product/collection/${ collectionReference }/products`;
const GET_CHILDREN_PRODUCTS_BY_COLLECTION = (collectionReference: string) => `v1/product/product/${ collectionReference }/children-products`;
const GET_COMPOSITION_LINKABLE = (collectionReference: string) => `v1/product/product/${ collectionReference }/composition/linkable`;

const PRODUCT_CATALOG_LINES = (collectionReference: string, catalogId: number) => `v1/product/collection/${ collectionReference }/catalog/${ catalogId }/product-catalog-lines`;
const GET_PRODUCTS_BY_ID = (productReference: string) => `v1/product/${ productReference }`;
const CREATE_PRODUCT = () => `v1/product/`;
const CREATE_PRODUCT_INFO = () => `v1/product/save-product-information`;
const SAVE_PRODUCT_CHARACTERISTICS = (productRef: string) => `v1/product/${ productRef }/save-product-characteristics`;
const SAVE_THUMBNAIL_INFORMATION = (productRef: string) => `v1/product/${ productRef }/save-thumbnail-information`;
const UPDATE_MODEL_3D_NAME = (model3d_ref: string) => `v1/part/3d-models/${ model3d_ref }/update`;
const GET_TABLE_PRESETS = '/v2/tableconcept/presets';

const GET_PRODUCT_DEFAULT_PRESET = (productRef: string) => `v1/preset/product-ref/${ productRef }/default-preset/data`;

const GET_CONFIGURATION_POSSIBLE_FROM_MODEL3D_REFERENCE = (coreProductReference: string, model3dCellReference: string) => `v1/product/${ coreProductReference }/default-configuration/${ model3dCellReference }/model3dCellReference`;

export {
    CREATE_PRODUCT,
    GET_COMPOSITION_LINKABLE,
    GET_PRODUCTS,
    GET_PRODUCTS_BY_ID,
    GET_PRODUCTS_BY_COLLECTION,
    GET_PRESETS_BY_COLLECTION,
    GET_CHILDREN_PRODUCTS_BY_COLLECTION,
    CREATE_PRODUCT_INFO,
    SAVE_PRODUCT_CHARACTERISTICS,
    UPDATE_MODEL_3D_NAME,
    SAVE_THUMBNAIL_INFORMATION,
    GET_PRODUCT_DEFAULT_PRESET,
    PRODUCT_CATALOG_LINES,
    GET_TABLE_PRESETS,
    GET_CONFIGURATION_POSSIBLE_FROM_MODEL3D_REFERENCE,
};
