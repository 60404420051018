import {InspectorType} from "./InspectorType";
import MaterialEditorParameters from "../MaterialEditorParameters";
import {Vector2} from "three";

export class EnumData {
    get Label(): string {
        return this.m_label;
    }
    get Options(): any {
        return this.m_options;
    }
    
    private m_label: string;
    private m_options: any;
    constructor(p_label: string, p_options: any) {
        this.m_label = p_label;
        this.m_options = p_options;
    }
}

export class EnumType extends InspectorType {
    
    private m_enumData: EnumData;
    
    constructor(enumParam: EnumData, matParam: MaterialEditorParameters, paramName: string, uiFolder: any) {
        super(matParam, paramName, uiFolder);
        this.m_enumData = enumParam;
    }
    public InitType(){
        this.m_uiFolder[this.m_paramName] = this.m_uiFolder.addInput(this.m_materialEditorParam, this.m_paramName, {
            label: this.m_enumData.Label,
            options: this.m_enumData.Options,
        });
    }

}
