import './upload-vignette.scss';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { getProduct3DThumbsbyPart } from '../../../../services/reducers/product/3DThumbnails/3dThumbnails.reducers';
import axios from 'axios';
import { getConfig } from '../../../../api/config';

export type UploadVignetteProps = {
    setOpenTextureModal: (openTextureModal: boolean) => void;
    file: File;
    setFile: (file: File | null) => void;
    err: string;
    setErr: (err: string) => void;
    cellId: number;
    reference: string;
};
const UploadVignette = (props: UploadVignetteProps) => {
    const dispatch = useDispatch();
    const [urlPrev, setUrlPrev] = useState();

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.length > 0 ? props.setFile(acceptedFiles) : props.setErr('Seul les formats .jpeg et .png sont acceptés ');
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: '.png, .jpeg, .jpg',
        onDrop,
    });
    useEffect(() => {
        // @ts-ignore
        props.file !== null ? setUrlPrev(props.file[0]) : setUrlPrev('');
        props.setErr('');
    }, [props.file]);

    const upload = async () => {
        let formData = new FormData();
        // @ts-ignore
        formData.append('file', props.file[0]);
        await axios
            .post(`${getConfig().urlHostApi}/v1/model-3d-cell-thumbnails/${props.cellId}/upload-thumbnail-image`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('mdf-jwt')}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(() => {
                props.setFile(null);
                props.setOpenTextureModal(false);
                dispatch(getProduct3DThumbsbyPart(props.reference));
            })
            .catch((e) => console.log(e));
    };

    return (
        <div className='upload-vignette-main'>
            <div className='upload-vignette-paper'>
                <div className='create-vignette-top'>
                    <div className='vignette-name'>
                        <div className='name'> Importer une image pour la vignette</div>
                        <div className='info'>Format attendu : PNG, JPEG</div>
                        <div className='info'>Dimensions recommandées : 120x120</div>
                    </div>
                    <div onClick={() => props.setOpenTextureModal(false)} className='vignette-close'>
                        X
                    </div>
                </div>
                <div className='create-vignette-body'>
                    {props.file === null ? (
                        <div className='drop-box' {...getRootProps()}>
                            <>
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Déposer le fichier ici ... </p>
                                ) : (
                                    <div className='drop-box-spans'>
                                        <span className='drop-box-first'>Glisser et déposer vos fichiers ici</span>
                                        <br />
                                        ou
                                        <br />
                                        <span className='drop-box-second'>sélectionner un fichier depuis votre ordinateur</span>
                                        <div className={props.err ? 'input-err' : ''}>{props.err && <span>{props.err}</span>}</div>
                                    </div>
                                )}
                            </>
                        </div>
                    ) : (
                        <div className='vignette-prev'>
                            <div className='x' onClick={() => props.setFile(null)}>
                                x
                            </div>
                            <img alt='vignette' src={urlPrev && URL.createObjectURL(urlPrev)} />
                        </div>
                    )}
                </div>
                <div className='btn-box'>
                    <button className='close' onClick={() => props.setOpenTextureModal(false)}>
                        Annuler
                    </button>
                    <button
                        className='action'
                        onClick={() => {
                            upload().then(() => console.log(''));
                        }}
                    >
                        {' '}
                        Importer
                    </button>
                </div>
            </div>
        </div>
    );
};
export default UploadVignette;
