import apiClient from '../../api-client';

import {
    CREATE_SUPPLIER,
    DELETE_SUPPLIER,
    FIND_TAGS_BY_FINISH_ID,
    GET_COMMON_SUPPLIER,
    GET_SUPPLIER,
    GET_SUPPLIER_TEXTURES_AND_COLORIS,
    GET_SUPPLIERS,
    UPDATE_SUPPLIER,
} from '../../endpoints/suppliers/suppliers.endpoints';

const SuppliersServive = {
    getSuppliers: async () => apiClient(GET_SUPPLIERS, undefined, undefined, 'GET'),
    createSuppliers: async (data: { name: string; logo: string }) => apiClient(CREATE_SUPPLIER, data, undefined, 'POST'),
    getSupplier: async (reference: string) => apiClient(GET_SUPPLIER(reference), undefined, undefined, 'GET'),
    getSuppliersTexturesAndColoris: async (supplierReference: string) => apiClient(GET_SUPPLIER_TEXTURES_AND_COLORIS(supplierReference), undefined, undefined, 'GET'),
    updateSupplier: async (reference: string, data: { name: string; reference: string; logo: string | null }) => apiClient(UPDATE_SUPPLIER(reference), data, undefined, 'PUT'),
    deleteSupplier: async (reference: string) => apiClient(DELETE_SUPPLIER(reference), undefined, undefined, 'DELETE'),
    getCommon: async () => apiClient(GET_COMMON_SUPPLIER, undefined, undefined, 'GET'),
    findTagsByFinishId: async (finishId: number) => apiClient(FIND_TAGS_BY_FINISH_ID(finishId), undefined, undefined, 'GET'),
};
export default SuppliersServive;
