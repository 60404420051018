import MaterialEditorParameters from "../MaterialEditorParameters";

export abstract class InspectorType {
    protected m_uiFolder: any;
    protected m_materialEditorParam: MaterialEditorParameters;
    protected m_paramName: string;
    
    constructor(matParam: MaterialEditorParameters, paramName: string, uiFolder: any) {
        this.m_uiFolder = uiFolder;
        this.m_materialEditorParam = matParam;
        this.m_paramName = paramName;
    }

    public abstract InitType(): void;
    public BindType(p_callback: (ev:any)=>void): void{
        this.m_uiFolder[this.m_paramName].on('change', p_callback);
    };
}