import apiClient from '../../api-client';
import {
    ADD_USER_TO_STORE,
    CREATE_STORES,
    DELETE_STORES,
    GET_STORE_BY_MANAGER_ID,
    GET_STORE_BY_REF,
    GET_STORES,
    REMOVE_USER_TO_STORE,
    STORE_USER_MAP,
    UPDATE_STORE_NAME,
} from '../../endpoints/store/store';
import { StoreUserList } from '../../../domain/domain';

export type StoreInput = {
    id?: number;
    version?: number;
    name?: string;
    sellerIds: number[];
    responsableIds: number[];
};

const storeServices = {
    getStores: async () => apiClient(GET_STORES, undefined, undefined, 'GET'),
    getStoreByRef: async (storeRef: string) => apiClient(GET_STORE_BY_REF(storeRef), undefined, undefined, 'GET'),
    getStoreByManagerId: async (managerId: number) => apiClient(GET_STORE_BY_MANAGER_ID(managerId), undefined, undefined, 'GET'),
    createStore: async (name: string) => apiClient(CREATE_STORES(name), undefined, undefined, 'POST'),
    updateStoreName: async (storeRef: string, name: string) => apiClient(UPDATE_STORE_NAME(storeRef, name), undefined, undefined, 'PUT'),
    addUserToStore: async (storeRef: string, roleName: string, userId: number) => apiClient(ADD_USER_TO_STORE(storeRef, roleName, userId), undefined, undefined, 'POST'),
    removeUserToStore: async (storeRef: string, userId: number) => apiClient(REMOVE_USER_TO_STORE(storeRef, userId), undefined, undefined, 'DELETE'),
    deleteStore: async (storeRef: string) => apiClient(DELETE_STORES(storeRef), undefined, undefined, 'DELETE'),
    getStoreUserMap: async (storeUserList: StoreUserList) => apiClient(STORE_USER_MAP, storeUserList, undefined, 'POST'),
};
export default storeServices;
