import apiClient from '../../api-client';
import {
    CREATE_UPDATE_CATALOG,
    DELETE_CATALOG,
    FIND_CATALOGS_BY_PRODUCT_ID,
    GET_CATALOG_BY_ID,
    GET_CATALOG_BY_REF,
    GET_CATALOGS,
    LINK_ALL_PRODUCT_FROM_COLLECTION_TO_CATALOG,
    LINK_PRODUCT_TO_CATALOG,
    REMOVE_ALL_PRODUCT_FROM_COLLECTION_TO_CATALOG,
    TREE_CATALOG,
    UNLINK_PRODUCT_TO_CATALOG,
} from '../../endpoints/catalog/catalog.endpoint';
import { User } from '../../../domain/domain';


export type CreateForm = {
    catalog: {
        id?: number,
        version?: number,
        reference?: string
        name: String,
        createdAt?: Date;
        updatedAt?: Date;
    }
    catalogUserList: User[]
};

const catalogServices = {
    getAllCatalog: async () => apiClient(GET_CATALOGS, undefined, undefined, 'GET'),
    getCatalogById: async (catalogId: number) => apiClient(GET_CATALOG_BY_ID(catalogId), undefined, undefined, 'GET'),
    getCatalogsByProductId: async (productId: number) => apiClient(FIND_CATALOGS_BY_PRODUCT_ID(productId), undefined, undefined, 'GET'),
    getCatalogByRef: async (catalogRef: string) => apiClient(GET_CATALOG_BY_REF(catalogRef), undefined, undefined, 'GET'),
    createAndUpdateCatalog: async (createForm: CreateForm) => apiClient(CREATE_UPDATE_CATALOG, createForm, undefined, 'POST'),
    deleteCatalog: async (catalogId: number) => apiClient(DELETE_CATALOG(catalogId), undefined, undefined, 'DELETE'),
    treeCatalog: async (catalogId: number) => apiClient(TREE_CATALOG(catalogId), undefined, undefined, 'GET'),
    linkPresetToSubSection: async (catalogId: number, productId: number) => apiClient(LINK_PRODUCT_TO_CATALOG(catalogId, productId), undefined, undefined, 'POST'),
    unLinkPresetToSubSection: async (catalogId: number, productId: number) => apiClient(UNLINK_PRODUCT_TO_CATALOG(catalogId, productId), undefined, undefined, 'DELETE'),
    linkAllProductsFromCollectionToCatalog: async (catalogId: number, collectionId: number) =>
        apiClient(LINK_ALL_PRODUCT_FROM_COLLECTION_TO_CATALOG(catalogId, collectionId), undefined, undefined, 'POST'),
    removeAllProductsFromCollectionToCatalog: async (catalogId: number, collectionId: number) =>
        apiClient(REMOVE_ALL_PRODUCT_FROM_COLLECTION_TO_CATALOG(catalogId, collectionId), undefined, undefined, 'DELETE'),
};
export default catalogServices;
