import MdfApiResponse from '../../../../domain/common/generic';
import { ConfiguredProduct, PresetTree, Product, SaveConfiguredProductBody } from '../../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import configuredProductService from '../../../../api/services/products/configured-product/configured-product.service';

export type ConfiguredProductTreeListState = {
    isLoading: false;
    payload: MdfApiResponse<PresetTree[]>;
};
const initialConfiguredProductTreeListState: ConfiguredProductTreeListState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};

export type ConfiguredProductState = {
    isLoading: false;
    payload: MdfApiResponse<ConfiguredProduct>;
};
const initialConfiguredProductState: ConfiguredProductState = {
    isLoading: false,
    payload: {
        content: {
            id: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
            version: 0,
            isDefault: false,
            mappingConfiguration: {
                mappingConfiguration: [],
            },
            configuration: [],
            productReference: '',
            metadata: {},
        },
        errors: [],
        warnings: [],
    },
};

//<editor-fold desc="get configured product tree list">
export const getConfiguredProductTreeList = createAsyncThunk('get/configured-product/list/tree', async (data: { coreProductReference: string }) => {
    const res = await configuredProductService.getConfiguredProductTreeList(data.coreProductReference);
    return res;
});

export const getConfiguredProductTreeListSlice = createSlice({
    name: 'getConfiguredProductTreeListSlice',
    initialState: initialConfiguredProductTreeListState,
    reducers: {
        resetGetConfiguredProductTreeList: (state) => {
            state = initialConfiguredProductTreeListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getConfiguredProductTreeList.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getConfiguredProductTreeList.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getConfiguredProductTreeList.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetConfiguredProductTreeList } = getConfiguredProductTreeListSlice.actions;
//</editor-fold>

//<editor-fold desc="create configured product">
export const createConfiguredProduct = createAsyncThunk('create/configured-product/', async (data: { name: string; coreProductReference: string }) => {
    const res = await configuredProductService.createConfiguredProduct(data.name, data.coreProductReference);
    return res;
});

export const createConfiguredProductSlice = createSlice({
    name: 'createConfiguredProductSlice',
    initialState: initialConfiguredProductState,
    reducers: {
        resetCreateConfiguredProductSlice: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createConfiguredProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createConfiguredProduct.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createConfiguredProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateConfiguredProductSlice } = createConfiguredProductSlice.actions;
//</editor-fold>

//<editor-fold desc="get configured product tree">
export const getConfiguredProductTree = createAsyncThunk('get/configured-product/tree', async (data: { productReference: string }) => {
    const res = await configuredProductService.getConfiguredProductTreeByProductReference(data.productReference);
    return res;
});

export const getConfiguredProductTreeSlice = createSlice({
    name: 'getConfiguredProductTreeSlice',
    initialState: initialConfiguredProductState,
    reducers: {
        resetConfiguredProductTreeSlice: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getConfiguredProductTree.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getConfiguredProductTree.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getConfiguredProductTree.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetConfiguredProductTreeSlice } = getConfiguredProductTreeSlice.actions;
//</editor-fold>

//<editor-fold desc="get configured product tree">
export const addMultipleAtomsToConfiguredProduct = createAsyncThunk('add/configured-product/tree', async (data: { productReference: string; body: SaveConfiguredProductBody }) => {
    const res = await configuredProductService.addMultipleAtomsToConfiguredProduct(data.productReference, data.body);
    return res;
});

export const addMultipleAtomsToConfiguredProductSlice = createSlice({
    name: 'addMultipleAtomsToConfiguredProductSlice',
    initialState: initialConfiguredProductState,
    reducers: {
        resetAddMultipleAtomsToConfiguredProductSlice: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addMultipleAtomsToConfiguredProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(addMultipleAtomsToConfiguredProduct.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(addMultipleAtomsToConfiguredProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetAddMultipleAtomsToConfiguredProductSlice } = addMultipleAtomsToConfiguredProductSlice.actions;
//</editor-fold>

//<editor-fold desc="get configured product tree">
export const uploadThumbnailUrlToConfiguredProduct = createAsyncThunk('upload-thumbnail/configured-product', async (data: { productReference: string; product: Product }) => {
    const res = await configuredProductService.uploadThumbnailToConfiguredProduct(data.productReference, data.product);
    return res;
});

export const uploadThumbnailUrlToConfiguredProductSlice = createSlice({
    name: 'uploadThumbnailUrlToConfiguredProductSlice',
    initialState: initialConfiguredProductState,
    reducers: {
        resetUploadThumbnailUrlToConfiguredProduct: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(uploadThumbnailUrlToConfiguredProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(uploadThumbnailUrlToConfiguredProduct.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(uploadThumbnailUrlToConfiguredProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUploadThumbnailUrlToConfiguredProduct } = uploadThumbnailUrlToConfiguredProductSlice.actions;
//</editor-fold>

//<editor-fold desc="delete configured product">
export const deleteConfiguredProduct = createAsyncThunk('delete/configured-product', async (data: { productReference: string }) => {
    const res = await configuredProductService.deleteConfiguredProduct(data.productReference);
    return res;
});

export const deleteConfiguredProductSlice = createSlice({
    name: 'deleteConfiguredProductSlice',
    initialState: initialConfiguredProductState,
    reducers: {
        resetDeleteConfiguredProduct: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteConfiguredProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteConfiguredProduct.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteConfiguredProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteConfiguredProduct } = deleteConfiguredProductSlice.actions;
//</editor-fold>

//<editor-fold desc="set Default Configured Product">
export const setDefaultConfiguredProduct = createAsyncThunk('set/default/configured-product/', async (data: { coreProductReference: string; newDefaultId: number }) => {
    const res = await configuredProductService.setDefaultConfiguredProduct(data.coreProductReference, data.newDefaultId);
    return res;
});

export const setDefaultConfiguredProductSlice = createSlice({
    name: 'setDefaultConfiguredProduct',
    initialState: initialConfiguredProductState,
    reducers: {
        resetSetDefaultConfiguredProductSlice: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setDefaultConfiguredProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(setDefaultConfiguredProduct.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(setDefaultConfiguredProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetSetDefaultConfiguredProductSlice } = setDefaultConfiguredProductSlice.actions;
//</editor-fold>

//<editor-fold desc="create configured product">
export const duplicateConfiguredProduct = createAsyncThunk('duplicate/configured-product/', async (data: { productReference: string; newName: string }) => {
    const res = await configuredProductService.duplicateConfiguredProduct(data.productReference, data.newName);
    return res;
});

export const duplicateConfiguredProductSlice = createSlice({
    name: 'duplicateConfiguredProduct',
    initialState: initialConfiguredProductState,
    reducers: {
        resetDuplicateConfiguredProductSlice: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(duplicateConfiguredProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(duplicateConfiguredProduct.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(duplicateConfiguredProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDuplicateConfiguredProductSlice } = duplicateConfiguredProductSlice.actions;
//</editor-fold>

//<editor-fold desc="get configured product tree">
export const getDefaultConfiguredProductByCoreProductRef = createAsyncThunk('get/default/configured-product/tree', async (data: { coreProductReference: string }) => {
    const res = await configuredProductService.getDefaultConfiguredProductByCoreProductRef(data.coreProductReference);
    return res;
});

export const getDefaultConfiguredProductByCoreProductRefSlice = createSlice({
    name: 'getDefaultConfiguredProductByCoreProductRefSlice',
    initialState: initialConfiguredProductState,
    reducers: {
        resetGetDefaultConfiguredProductByCoreProductRefSlice: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDefaultConfiguredProductByCoreProductRef.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getDefaultConfiguredProductByCoreProductRef.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getDefaultConfiguredProductByCoreProductRef.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetDefaultConfiguredProductByCoreProductRefSlice } = getDefaultConfiguredProductByCoreProductRefSlice.actions;
//</editor-fold>
