import React from 'react';
import './main-paper.scss';

export type MainPaperProps = {
    size?: number;
    height?: number;
    top?: number;
    bottom?: number;
    children: React.ReactNode;
    className?: string;
};
const MainPaper = ({ children, className = '' }: MainPaperProps) => {
    return <div className={`main-Paper${className}`}>{children}</div>;
};
export default MainPaper;
