import './associate-tags-modal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
    createTag,
    CreateTagState,
    getFinishTag,
    GetFinishTagState, resetCreateTag,
} from '../../../../../services/reducers/tag/tag.slice';
import { RootState } from '../../../../../services/root-reducer';
import { CompositionModule, CompositionModuleTag, Product, ProductTagOfCollectionResponse, Tag } from '../../../../../domain/domain';
import plus from '../../../../../asset/plus-circle.svg';
import tagService from '../../../../../api/services/tag/tag.service';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../domain/Params/params-interface';
import blueDot from '../../../../../asset/dot-svgrepo-com.svg';


type AssociateTagsModalProps = {
    tagList: Tag[]
    setTagList: (tagList: Tag[]) => void
    setOpenModal: (openModal: boolean) => void
};

const AssociateTagsModal = (props: AssociateTagsModalProps) => {
    const dispatch = useDispatch();
    const { collectionReference } = useParams<Params>();
    const [isMount, setIsmount] = useState(false);

    const tags = useSelector<RootState, GetFinishTagState>(state => state.getFinishTag);
    const createTagRes = useSelector<RootState, CreateTagState>(state => state.createTag);

    const [initialTagList, setInitialTagList] = useState<Tag[]>([]);
    const [tagName, setTagName] = useState<string>('');
    const [tagNameErr, setTagNameErr] = useState<string>('');
    const [currentTag, setCurrentTag] = useState(0);
    const [associatedProducts, setAssociatedProducts] = useState<Product[]>([]);

    const [ptoc, setPtoc] = useState<ProductTagOfCollectionResponse | undefined>(undefined);

    useEffect(() => {
        !isMount && setInitialTagList(props.tagList);
        !isMount && dispatch(getFinishTag('CONSTRUCTION_RULE'));
        collectionReference && tagService.getAssociatedModulesByTag(collectionReference).then((r) => {
            setPtoc(r.content);
        });
        return () => setIsmount(true);
    }, []);
    useEffect(() => {
        if (createTagRes.payload.content && createTagRes.payload.errors.length === 0) {
            setTagName('');
            dispatch(getFinishTag('CONSTRUCTION_RULE'));
            dispatch(resetCreateTag());
        }
    }, [createTagRes]);
    useEffect(() => {
        if (ptoc) {
            const compositionModulesTagsList = ptoc.compositionModuleTags.filter((c) => c.tagId === currentTag);
            if (compositionModulesTagsList) {
                let cma: CompositionModule[] = [];
                let pa: Product[] = [];
                buildCompositionModulesTagList(compositionModulesTagsList, cma, () => buildCompositionModulesList(cma, pa, () => setAssociatedProducts(pa)));
            }
        }
    }, [currentTag, ptoc]);

    const buildCompositionModulesTagList = (CompositionModulesTagList: CompositionModuleTag[], cma: CompositionModule[], callback: () => void) => {
        CompositionModulesTagList.forEach((cmt) => {
            const findCompositionModuleAssociated: CompositionModule | undefined = ptoc && ptoc.compositionModules.find((p) => p.associationReference === cmt.compositionModuleReference);
            findCompositionModuleAssociated && cma.push(findCompositionModuleAssociated);
        });
        callback();
    };
    const buildCompositionModulesList = (cma: CompositionModule[], pa: Product[], callback: () => void) => {
        cma.forEach((c) => {
            const productAssociated = ptoc && ptoc.products.find((p) => p.id === c.productModuleId);
            productAssociated && pa.push(productAssociated);
            callback();
        });
    };
    const addTagToCompo = (tag: Tag) => {
        // @ts-ignore
        props.setTagList(prev => [...prev, tag]);
        setTagNameErr('');
    };
    const removeTagFromCompo = (index: number) => {
        const updateList = [...props.tagList];
        updateList.splice(index, 1);
        props.setTagList(updateList);
        setTagNameErr('');
    };

    return (
        <div className='asso-maini'>
            <div className='asso-paper'>
                <div>
                    <label>Créer un Tag</label>
                    <div className='asso-create'>
                        <input value={tagName} onChange={(e) => {
                            setTagNameErr('');
                            setTagName(e.target.value);
                        }} placeholder='Créer un tag' />
                        <img alt='ajouter un tag'
                             src={plus}
                             onClick={() =>
                                 tagName.length === 0
                                     ? setTagNameErr('Le nom doit être renseigné')
                                     : dispatch(createTag({
                                         tagEntityType: 'CONSTRUCTION_RULE',
                                         tagName: tagName,
                                     }))}
                        />
                        {
                            tagNameErr.length > 0 && <span>{tagNameErr}</span>
                        }
                    </div>
                </div>
                <div>
                    <label>Liste des Tags</label>
                    <div className='tag-list'>
                        {tags.payload.content && tags.payload.content.map((t: Tag) => {
                            console.log(ptoc);
                            return (
                                <div
                                    onMouseEnter={() => setCurrentTag(t.id)}
                                    onMouseLeave={() => {
                                        setAssociatedProducts([]);
                                        setCurrentTag(0);
                                    }}
                                    onClick={() => addTagToCompo(t)}
                                    className='tagi'
                                    key={t.id}>
                                    {t.name.length > 15 ? `${t.name.toUpperCase().substring(0, 14)}...` : t.name.toUpperCase()}
                                    {ptoc && ptoc.compositionModuleTags.some(e => e.tagId === t.id) && <img alt="blue dot " src={blueDot}/>}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div>
                    <label>Règle</label>
                    <div className='tag-list'>
                        {props.tagList.map((tl: Tag, i: number) => {
                            return (
                                <div className='tagi'
                                     onClick={() => removeTagFromCompo(i)}
                                     key={i}>
                                    {tl.name.toUpperCase()}
                                </div>
                            );
                        })
                        }
                    </div>
                </div>
                <div className='btn-box'>
                    <button onClick={() => {
                        props.setTagList(initialTagList);
                        props.setOpenModal(false);
                    }} className='btn-canceled '>Annuler
                    </button>
                    <button
                        onClick={() => props.setOpenModal(false)}
                        className='btn-submit'>Associer
                    </button>
                </div>
            </div>
            <div className='asso-paper-right'>
                <div className='inception'>
                    <div className='inception-paper'>
                        <div className='inception-close'>
                            <span> Liste des modules associés </span>
                        </div>
                        <div className='inception-body'>
                            {associatedProducts.length === 0
                                ? <div className='inception-empty'> Ce tag est associé à aucun module</div>
                                : <div className='inception-list'>
                                    {associatedProducts.map((m, i) => {
                                        return (
                                            <ul key={i}>
                                                <li>
                                                    {m.name}
                                                </li>
                                            </ul>
                                        );
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AssociateTagsModal;