import apiClient from '../../../../api-client';
import {
    COLORIS_FROM_COMMON_SUPPLIER_ID,
    CREATE_COLORIS,
    DELETE_COLORIS,
    DUPLICATE_COLORIS,
    GET_ALL_COLORIS,
    GET_COLORIS,
    UPDATE_COLORIS,
    UPDATE_COLORIS_DTO,
} from '../../../../endpoints/suppliers/finish/color/coloris.enpoint';
import { Colori, ColoriCreateForm, ColorisUpdateForm } from '../../../../../domain/domain';


const ColorisService = {
    getAllColoris: async (finishId: number) => apiClient(GET_ALL_COLORIS(finishId), undefined, undefined, 'GET'),
    getColori: async (colorisId: number) => apiClient(GET_COLORIS(colorisId), undefined, undefined, 'GET'),
    createColoris: async (finishId: number, colorForm: ColoriCreateForm) => apiClient(CREATE_COLORIS(finishId), colorForm, undefined, 'POST'),
    updateColoris: async (colorisId: number, updateColorisForm: ColorisUpdateForm) => apiClient(UPDATE_COLORIS(colorisId), updateColorisForm, undefined, 'POST'),
    updateColorisDto: async (data: Colori) => apiClient(UPDATE_COLORIS_DTO, data, undefined, 'POST'),
    deleteColoris: async (finishId: number, colorisId: number) => apiClient(DELETE_COLORIS(finishId, colorisId), undefined, undefined, 'DELETE'),
    duplicateColoris: async (finishId: number, colorisId: number, coloriCreateForm: ColoriCreateForm) => apiClient(DUPLICATE_COLORIS(finishId, colorisId), coloriCreateForm, undefined, 'POST'),
    getColorisFromCommondAndSupplierId: async (supplierReference: string) => apiClient(COLORIS_FROM_COMMON_SUPPLIER_ID(supplierReference), undefined, undefined, 'GET'),
};
export default ColorisService;