import './create-rules.scss';
import { Fragment, useEffect, useState } from 'react';
import { ActionType, Colori, FinishDTO, MappingDTO, Matching, ModelTree, PartItemTree, PartTree, Product, ProductTree, Rule, RuleType, TagDTO, ZoneDTO } from '../../../domain/domain';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/root-reducer';
import { createProductRules, getProductRules, SwitchRulesState } from '../../../services/reducers/product/rules/rules.reducers';
import minus from '../../../asset/minus.png';
import check from '../../../asset/Validation.svg';
import down from '../../../asset/Arrow-down.svg';
import cells3dValuesService from '../../../api/services/products/3DCells/3DCellsValues.service';

export type CreateRulesProps = {
    setOpenModal: (openModal: boolean) => void;
    openModal: boolean;
    confType: ActionType;
    rules: { type: number; label: string };
    product: Product;
    confRuleType: RuleType;
    emptyStart: boolean;
    setEmptyStart: (emptyStart: boolean) => void;
    rule: Rule | undefined;
    setRule: (rule: Rule | undefined) => void;
};
export type RuleRefs = {
    partItemRef: string;
    atomRefList: string[];
};

const CreateRules = ({ setOpenModal, openModal, rules, product, confType, emptyStart, rule, setRule }: CreateRulesProps) => {
    const dispatch = useDispatch();
    const switchRuleRes = useSelector<RootState, SwitchRulesState>((state) => state.switchRuleType);

    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    const [priceType, setPriceType] = useState<number>(-1);
    const [price, setPrice] = useState<number>(-1);
    const [increaseType, setIncreaseType] = useState<string>('euros');
    const [ruleRefs, setRuleRefs] = useState<RuleRefs[]>([]);
    const [currentAtom, setCurrentAtom] = useState<ModelTree>();
    const [refList, setRefList] = useState<string[]>([]);
    const [matching, setMatching] = useState<Matching>({ model3DCellValueRefByPartItemRef: {} });
    const [mappingMap, setMappingMap] = useState();
    const [availableType, setAvailableType] = useState<number[]>([]);

    const [highValue, setHighValue] = useState<number>(-1);
    const [addToHighValue, setAddToHighValue] = useState<number>(-1);

    const [lengthValue, setLengthValue] = useState<number>(-1);
    const [addToLengthValue, setAddToLengthValue] = useState<number>(-1);

    const [widthValue, setWidthValue] = useState<number>(-1);
    const [addToWidthValue, setAddToWidthValue] = useState<number>(-1);

    const [showMappings, setShowMappings] = useState(false);
    const [productCellValueTree, setProductCellValueTree] = useState<ProductTree>({
        reference: '',
        name: '',
        partTreeList: [],
    });

    //#region useEffect
    useEffect(() => {
        cells3dValuesService.get3dCellValuesTree(product.reference).then((res) => {
            setProductCellValueTree(res.content);
        });
    }, []);
    useEffect(() => {
        if (openModal) {
            setRuleRefs([]);
            setRefList([]);
        }
    }, [openModal]);
    useEffect(() => {
        currentAtom && updateRulesList(currentAtom.atom.partItemReference, currentAtom.atom.reference);
    }, [currentAtom]);
    useEffect(() => {
        const matchingObject: Matching = { model3DCellValueRefByPartItemRef: {} };
        ruleRefs.forEach((el: RuleRefs) => {
            Object.assign(matchingObject.model3DCellValueRefByPartItemRef, { [el.partItemRef]: el.atomRefList });
        });
        setMatching(matchingObject);
    }, [ruleRefs, refList]);
    useEffect(() => {
        switchRuleRes.payload.errors.length === 0 && dispatch(getProductRules(product.reference));
    }, [switchRuleRes]);
    useEffect(() => {
        if (rule) {
            for (const [key, value] of Object.entries(rule.matching.model3DCellValueRefByPartItemRef)) {
                setRefList((prevState) => [...prevState, ...value]);
                setRuleRefs((prevState) => [...prevState, { partItemRef: key, atomRefList: [...value] }]);
            }
            switch (rule.action.type) {
                case 'PRICE_SET_BASE':
                    setPriceType(0);
                    setPrice(rule.action.params.value);
                    return;
                case 'PRICE_APPLY_RATIO':
                    setPriceType(1);
                    setPrice(rule.action.params.value);
                    rule.action.params.percent ? setIncreaseType('euros') : setIncreaseType('percent');
                    return;
                case 'SET_DIMENSION':
                    setHighValue(rule.action.params.definedMaxHeight);
                    rule.action.params.definedMaxHeight > 0 && setAvailableType((prevState) => [...prevState, 1]);

                    setAddToHighValue(rule.action.params.addToHeight);
                    rule.action.params.addToHeight > 0 && setAvailableType((prevState) => [...prevState, 4]);

                    setWidthValue(rule.action.params.definedMaxWidth);
                    rule.action.params.definedMaxWidth > 0 && setAvailableType((prevState) => [...prevState, 3]);

                    setAddToWidthValue(rule.action.params.addToWidth);
                    rule.action.params.addToWidth > 0 && setAvailableType((prevState) => [...prevState, 6]);

                    setLengthValue(rule.action.params.definedMaxLength);
                    rule.action.params.definedMaxLength > 0 && setAvailableType((prevState) => [...prevState, 5]);

                    setAddToLengthValue(rule.action.params.addToLength);
                    rule.action.params.addToLength > 0 && setAvailableType((prevState) => [...prevState, 2]);
            }
        }
    }, [rule]);
    //#endregion

    const ruleOption: { i: number; label: string }[] = [
        { i: 1, label: 'Définir la hauteur' },
        { i: 2, label: 'Définir la longueur' },
        { i: 3, label: 'Définir la largeur' },
        { i: 4, label: 'Ajouter à la hauteur' },
        { i: 5, label: 'Ajouter à la longueur' },
        { i: 6, label: 'Ajouter à la largeur' },
    ];

    const removeTypeFromList = (type: number) => {
        const newList = availableType.filter((n) => n !== type);
        setAvailableType(newList);
        switch (type) {
            case 1:
                setHighValue(-1);
                return;
            case 2:
                setLengthValue(-1);
                return;
            case 3:
                setWidthValue(-1);
                return;
            case 4:
                setAddToHighValue(-1);
                return;
            case 5:
                setAddToLengthValue(-1);
                return;
            case 6:
                setAddToWidthValue(-1);
                return;
        }
    };

    //#region method
    const updateRulesList = (pItemRef: string, atomRef: string) => {
        const foundPartItemRefIndex = ruleRefs.findIndex((partItem) => partItem.partItemRef === pItemRef);
        if (foundPartItemRefIndex < 0) {
            setRuleRefs((prevState) => [
                ...prevState,
                {
                    partItemRef: pItemRef,
                    atomRefList: [atomRef],
                },
            ]);
            setCurrentAtom(undefined);
        } else {
            const foundAtomRef = ruleRefs[foundPartItemRefIndex].atomRefList.find((atom) => atom === atomRef);
            if (!foundAtomRef) {
                setRuleRefs((prevState) => {
                    const toReturn = prevState;
                    !toReturn[foundPartItemRefIndex].atomRefList.includes(atomRef) && toReturn[foundPartItemRefIndex].atomRefList.push(atomRef);
                    return toReturn;
                });
                setCurrentAtom(undefined);
            } else {
                setRuleRefs((prevState) => {
                    const toReturn = prevState;
                    toReturn[foundPartItemRefIndex].atomRefList = toReturn[foundPartItemRefIndex].atomRefList.filter((el) => el !== atomRef);
                    if (toReturn[foundPartItemRefIndex].atomRefList.length === 0) {
                        return toReturn.filter((el) => el.partItemRef !== pItemRef);
                    } else {
                        return toReturn;
                    }
                });
                setCurrentAtom(undefined);
            }
        }
    };
    const updateRefList = (atomRef: string) => {
        const existRef = refList.includes(atomRef);
        if (existRef) {
            const newRefList = refList.filter((ref) => ref !== atomRef);
            setRefList(newRefList);
        } else {
            setRefList([...refList, atomRef]);
        }
    };
    const atomIsSelected = (atom: string) => {
        return refList.includes(atom) ? 'atomFocus' : 'atom';
    };
    const displayMappings = (partReference: string) => {
        if (!mappingMap) {
            return [];
        }
        // @ts-ignore
        return mappingMap && mappingMap[partReference].mappings.sort((a: MappingDTO, b: MappingDTO) => a.name.toUpperCase() < b.name.toUpperCase());
    };
    const displayZones = (partReference: string) => {
        if (!mappingMap) {
            return [];
        }
        // @ts-ignore
        return mappingMap[partReference].zones.sort((a: ZoneDTO, b: ZoneDTO) => a.name.toUpperCase() < b.name.toUpperCase());
    };
    const displayMaterials = (partReference: string) => {
        if (!mappingMap) {
            return [];
        }
        // @ts-ignore
        return mappingMap[partReference].materials.sort((a: TagDTO, b: TagDTO) => a.tagName.toUpperCase() < b.tagName.toUpperCase());
    };
    const displayFinishes = (partReference: string) => {
        if (!mappingMap) {
            return [];
        }
        // @ts-ignore
        return mappingMap[partReference].finishes.sort((a: FinishDTO, b: FinishDTO) => a.name.toUpperCase() < b.name.toUpperCase());
    };
    const displayColoris = (partReference: string) => {
        if (!mappingMap) {
            return [];
        }
        // @ts-ignore
        return mappingMap[partReference].coloris.sort((a: Colori, b: Colori) => a.name.toUpperCase() < b.name.toUpperCase());
    };
    const returnTree = (key: string) => {
        return (
            <div className="body-list">
                {productCellValueTree.partTreeList.map((partTree: PartTree) => {
                    return (
                        <div className="partItem-block" key={partTree.reference}>
                            <>
                                {
                                    //@ts-ignore
                                    partTree[key].map((partItemTree: PartItemTree) => {
                                        if (partItemTree.name === 'UNIQUE') {
                                            return null;
                                        }
                                        return (
                                            <Fragment key={partItemTree.reference}>
                                                <div className="blk">
                                                    <div className="partName">
                                                        <span>{partTree.name.toUpperCase()}</span>
                                                        <span>{partItemTree.name}</span>
                                                    </div>
                                                </div>
                                                <div className="atom-container">
                                                    {partItemTree.modelTrees.map((atom: ModelTree, index: number) => {
                                                        return (
                                                            <div
                                                                onClick={() => {
                                                                    updateRefList(atom.atom.reference);
                                                                    setCurrentAtom(atom);
                                                                }}
                                                                className={atomIsSelected(atom.atom.reference)}
                                                                key={index}
                                                            >
                                                                {atom.atom.value}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Fragment>
                                        );
                                    })
                                }
                            </>
                            <>
                                {
                                    //@ts-ignore
                                    partTree.options.map((partItemTree: PartItemTree) => {
                                        if (partItemTree.name === 'UNIQUE') {
                                            return null;
                                        }
                                        return (
                                            <Fragment key={partItemTree.reference}>
                                                <div className="blk">
                                                    <div className="partName">
                                                        <span>{partTree.name.toUpperCase()}</span>
                                                        <span>{partItemTree.name}</span>
                                                    </div>
                                                </div>
                                                <div className="atom-container">
                                                    {partItemTree.modelTrees.map((atom: ModelTree, index: number) => {
                                                        return (
                                                            <div
                                                                onClick={() => {
                                                                    updateRefList(atom.atom.reference);
                                                                    setCurrentAtom(atom);
                                                                }}
                                                                className={atomIsSelected(atom.atom.reference)}
                                                                key={index}
                                                            >
                                                                {atom.atom.value}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Fragment>
                                        );
                                    })
                                }
                            </>
                            {showMappings && (
                                <>
                                    <>
                                        <Fragment>
                                            <div className="blk">
                                                <div className="partName">
                                                    <span>{partTree.name.toUpperCase()}</span>
                                                    <span>Mappings</span>
                                                </div>
                                            </div>
                                            <div className="atom-container">
                                                {mappingMap &&
                                                    displayMappings(partTree.reference).map((mapping: MappingDTO, index: number) => {
                                                        return (
                                                            <div
                                                                onClick={() => {
                                                                    updateRefList(`>${partTree.reference}-mapping-${mapping.id}`);

                                                                    setCurrentAtom({
                                                                        // @ts-ignore
                                                                        atom: {
                                                                            partItemReference: `${partTree.reference}-mapping`,
                                                                            reference: `>${partTree.reference}-mapping-${mapping.id}`,
                                                                        },
                                                                    });

                                                                    // setCurrentAtom(atom);
                                                                }}
                                                                className={atomIsSelected(`>${partTree.reference}-mapping-${mapping.id}`)}
                                                                key={index}
                                                            >
                                                                {mapping.name}
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </Fragment>
                                    </>
                                    <>
                                        <Fragment>
                                            <div className="blk">
                                                <div className="partName">
                                                    <span>{partTree.name.toUpperCase()}</span>
                                                    <span>Zones</span>
                                                </div>
                                            </div>
                                            <div className="atom-container">
                                                {
                                                    // @ts-ignore
                                                    mappingMap &&
                                                        displayZones(partTree.reference).map((zone: ZoneDTO, index: number) => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        updateRefList(`>${partTree.reference}-zone-${zone.uuid}`);

                                                                        setCurrentAtom({
                                                                            // @ts-ignore
                                                                            atom: {
                                                                                partItemReference: `${partTree.reference}-zone`,
                                                                                reference: `>${partTree.reference}-zone-${zone.uuid}`,
                                                                            },
                                                                        });

                                                                        // setCurrentAtom(atom);
                                                                    }}
                                                                    className={atomIsSelected(`>${partTree.reference}-zone-${zone.uuid}`)}
                                                                    key={index}
                                                                >
                                                                    {zone.name}
                                                                </div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </Fragment>
                                    </>
                                    <>
                                        <Fragment>
                                            <div className="blk">
                                                <div className="partName">
                                                    <span>{partTree.name.toUpperCase()}</span>
                                                    <span>Matériaux</span>
                                                </div>
                                            </div>
                                            <div className="atom-container">
                                                {
                                                    // @ts-ignore
                                                    mappingMap &&
                                                        displayMaterials(partTree.reference).map((material: TagDTO, index: number) => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        updateRefList(`>${partTree.reference}-material-${material.tagName}`);

                                                                        setCurrentAtom({
                                                                            // @ts-ignore
                                                                            atom: {
                                                                                partItemReference: `${partTree.reference}-material`,
                                                                                reference: `>${partTree.reference}-material-${material.tagName}`,
                                                                            },
                                                                        });

                                                                        // setCurrentAtom(atom);
                                                                    }}
                                                                    className={atomIsSelected(`>${partTree.reference}-material-${material.tagName}`)}
                                                                    key={index}
                                                                >
                                                                    {material.tagName}
                                                                </div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </Fragment>
                                    </>
                                    <>
                                        <Fragment>
                                            <div className="blk">
                                                <div className="partName">
                                                    <span>{partTree.name.toUpperCase()}</span>
                                                    <span>Finitions</span>
                                                </div>
                                            </div>
                                            <div className="atom-container">
                                                {
                                                    // @ts-ignore
                                                    mappingMap &&
                                                        displayFinishes(partTree.reference).map((finish: FinishDTO, index: number) => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        updateRefList(`>${partTree.reference}-finish-${finish.id}`);

                                                                        setCurrentAtom({
                                                                            // @ts-ignore
                                                                            atom: {
                                                                                partItemReference: `${partTree.reference}-finish`,
                                                                                reference: `>${partTree.reference}-finish-${finish.id}`,
                                                                            },
                                                                        });

                                                                        // setCurrentAtom(atom);
                                                                    }}
                                                                    className={atomIsSelected(`>${partTree.reference}-finish-${finish.id}`)}
                                                                    key={index}
                                                                >
                                                                    {finish.name}
                                                                </div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </Fragment>
                                    </>
                                    <>
                                        <Fragment>
                                            <div className="blk">
                                                <div className="partName">
                                                    <span>{partTree.name.toUpperCase()}</span>
                                                    <span>Coloris</span>
                                                </div>
                                            </div>
                                            <div className="atom-container">
                                                {
                                                    // @ts-ignore
                                                    mappingMap &&
                                                        displayColoris(partTree.reference).map((colori: Colori, index: number) => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        updateRefList(`>${partTree.reference}-colori-${colori.id}`);

                                                                        setCurrentAtom({
                                                                            // @ts-ignore
                                                                            atom: {
                                                                                partItemReference: `${partTree.reference}-colori`,
                                                                                reference: `>${partTree.reference}-colori-${colori.id}`,
                                                                            },
                                                                        });

                                                                        // setCurrentAtom(atom);
                                                                    }}
                                                                    className={atomIsSelected(`>${partTree.reference}-colori-${colori.id}`)}
                                                                    key={index}
                                                                >
                                                                    {colori.name}
                                                                </div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </Fragment>
                                    </>
                                </>
                            )}
                        </div>
                    );
                })}

                <button
                    className="btn-mappings"
                    onClick={() => {
                        setShowMappings((prevState) => !prevState);
                        !mappingMap &&
                            cells3dValuesService.buildMappingTree(product.reference, productCellValueTree).then((resMapping) => {
                                setMappingMap(resMapping.content);
                            });
                    }}
                >
                    {showMappings ? 'Masquer' : 'Afficher'} les habillages
                </button>
            </div>
        );
    };
    const ReturnBodyByType = () => {
        return (
            <div className="body">
                <div className="body-content">{returnTree('characteristics')}</div>
            </div>
        );
        /*switch (rules.type) {
            case 0:
                return (
                    <div className='body'>
                        <div className='body-content'>
                            {returnTree('characteristics')}
                        </div>
                    </div>
                );
            case  1:
                return (
                    <div className='body'>
                        <div className='body-content'>
                            {returnTree('characteristics')}
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className='body'>
                        <div className='body'>
                            {returnTree('characteristics')}

                        </div>
                    </div>
                );
        }*/
    };
    const returnRulesType = () => {
        switch (rules.type) {
            case 0: {
                return 'PRICE';
            }
            case 1: {
                return emptyStart ? 'IMPOSSIBLE_CONF' : 'POSSIBLE_CONF';
            }
            case 2: {
                return 'DIMENSION';
            }
            default: {
                return 'DIMENSION';
            }
        }
    };
    const returnPriceBody = () => {
        if (priceType === -1) {
            return <></>;
        } else if (priceType === 0) {
            return (
                <div className="add-price">
                    <span className="price-title">Définir un prix</span>
                    <div className="add-price-body">
                        <span>Prix en euros</span>
                        <div className="add-price-input">
                            <input defaultValue={price !== -1 ? price : 0} onChange={(e) => setPrice(parseInt(e.target.value))} type="number" />€
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="increase-price">
                    <span className="price-title">Définir la variation de prix </span>
                    <div className="increase-input">
                        <input onChange={(e) => setIncreaseType(e.target.value)} checked={increaseType === 'euros'} name="increase-type" value="euros" type="radio" />
                        <span>Faire varier en euros €</span>
                    </div>
                    <div className="increase-input">
                        <input checked={increaseType === 'percent'} onChange={(e) => setIncreaseType(e.target.value)} name="increase-type" value="percent" type="radio" />
                        <span>Faire varier en pourcentage %</span>
                    </div>
                    <div className="increase-price-input">
                        <input
                            defaultValue={price !== -1 ? price : 0}
                            placeholder={increaseType === 'euros' ? 'Variation en euros' : 'Variation en pourcent'}
                            onChange={(e) => setPrice(parseInt(e.target.value))}
                            type="number"
                        />
                        {increaseType === 'euros' ? '€' : '%'}
                    </div>
                </div>
            );
        }
    };
    const OnsubmitPrice = () => {
        if (!rule) {
            price > 0 &&
                price !== undefined &&
                dispatch(
                    createProductRules({
                        productRef: product.reference,
                        // @ts-ignore
                        rule: {
                            type: returnRulesType(),
                            matching: matching,
                            action: {
                                type: priceType === 0 ? 'PRICE_SET_BASE' : 'PRICE_APPLY_RATIO',
                                params: {
                                    percent: increaseType !== 'euros',
                                    value: price * 100,
                                },
                            },
                        },
                    })
                );
        } else {
            price > 0 &&
                price !== undefined &&
                dispatch(
                    createProductRules({
                        productRef: product.reference,
                        // @ts-ignore
                        rule: {
                            ruleRef: rule.ruleRef,
                            type: returnRulesType(),
                            matching: matching,
                            action: {
                                type: priceType === 0 ? 'PRICE_SET_BASE' : 'PRICE_APPLY_RATIO',
                                params: {
                                    percent: increaseType !== 'euros',
                                    value: price * 100,
                                },
                            },
                        },
                    })
                );
        }
    };
    const OnsubmitConf = () => {
        if (!rule) {
            dispatch(
                createProductRules({
                    productRef: product.reference,
                    // @ts-ignore
                    rule: {
                        type: returnRulesType(),
                        matching: matching,
                        action: { type: confType, params: {} },
                    },
                })
            );
        } else {
            dispatch(
                createProductRules({
                    productRef: product.reference,
                    // @ts-ignore
                    rule: {
                        ruleRef: rule.ruleRef,
                        type: returnRulesType(),
                        matching: matching,
                        action: { type: confType, params: {} },
                    },
                })
            );
        }
    };
    const OnsubmitDesc = () => {
        if (!rule) {
            dispatch(
                createProductRules({
                    productRef: product.reference,
                    // @ts-ignore
                    rule: {
                        type: returnRulesType(),
                        matching: matching,
                        action: {
                            type: confType,
                            params: {
                                definedMaxHeight: highValue,
                                definedMaxLength: lengthValue,
                                definedMaxWidth: widthValue,
                                addToHeight: addToHighValue,
                                addToLength: addToLengthValue,
                                addToWidth: addToWidthValue,
                            },
                        },
                    },
                })
            );
        } else {
            dispatch(
                createProductRules({
                    productRef: product.reference,
                    // @ts-ignore
                    rule: {
                        ruleRef: rule.ruleRef,
                        type: returnRulesType(),
                        matching: matching,
                        action: {
                            type: confType,
                            params: {
                                definedMaxHeight: highValue,
                                definedMaxLength: lengthValue,
                                definedMaxWidth: widthValue,
                                addToHeight: addToHighValue,
                                addToLength: addToLengthValue,
                                addToWidth: addToWidthValue,
                            },
                        },
                    },
                })
            );
        }
    };
    const addTypeToList = (type: number) => {
        !availableType.includes(type) && setAvailableType([...availableType, type]);
    };

    const inList = (type: number) => availableType.includes(type);
    //#endregion

    const returnDefaultPriceOption = () => {
        if (rule) {
            return rule.action.type === 'PRICE_SET_BASE' ? 0 : 1;
        }
        return -1;
    };

    return (
        <div className="create-rules-main">
            <div className="create-rule-paper">
                <div className="create-rules-top">
                    <div className="create-rules-left">
                        <div className="section-title">RÈGLE MÉTIER</div>
                        <div className="body-title">Création de règle {rules.label.toLowerCase()}</div>
                        <div className="section-body">{ReturnBodyByType()}</div>
                    </div>
                    {rules.type === 0 && (
                        <div className="create-rules-right">
                            <div className="section-title">ACTION</div>
                            <div className="price-input-box">
                                <div className="price-input-title">Type de règle de prix</div>
                                <select defaultValue={returnDefaultPriceOption()} onChange={(e) => setPriceType(parseInt(e.target.value))} name="price-type" id="price-rule-select">
                                    <option value={-1}>--Choissisez une option--</option>
                                    <option value={0}>Définir un prix</option>
                                    <option value={1}> Faire varier un prix</option>
                                </select>
                            </div>
                            <div className="price-action">{returnPriceBody()}</div>
                        </div>
                    )}
                    {rules.type === 2 && (
                        <div className="create-rules-right">
                            <div className="descr-rule">
                                <div className="relative">
                                    <label>Choisir</label>
                                    <div onClick={() => setOpenDropdown(!openDropdown)} className="desc-dropdown">
                                        <span>{openDropdown ? '' : 'Ajouter un type de règle'}</span>
                                        <img alt="ouvrir dropdown" src={down} />
                                    </div>
                                    {openDropdown && (
                                        <div className="desc-dropdown-option">
                                            {ruleOption.map((t: { i: number; label: string }) => (
                                                <span className={inList(t.i) ? 'disable' : ''} key={t.i} onClick={() => addTypeToList(t.i)}>
                                                    {t.label}
                                                    {inList(t.i) && <img alt="check" src={check} />}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="r-bloc">
                                    {inList(1) && (
                                        <div className="r">
                                            <span className="r-title">Définir la hauteur à</span>
                                            <input onChange={(e) => setHighValue(parseInt(e.target.value))} type="number" min={0} defaultValue={highValue === -1 ? 0 : highValue} />
                                            <span>( cm )</span>
                                            <img onClick={() => removeTypeFromList(1)} src={minus} alt="supprimer" />
                                        </div>
                                    )}
                                    {inList(2) && (
                                        <div className="r">
                                            <span className="r-title">Définir la Longueur à</span>
                                            <input onChange={(e) => setLengthValue(parseInt(e.target.value))} type="number" min={0} defaultValue={lengthValue === -1 ? 0 : lengthValue} />
                                            <span>( cm )</span>
                                            <img onClick={() => removeTypeFromList(2)} src={minus} alt="supprimer" />
                                        </div>
                                    )}
                                    {inList(3) && (
                                        <div className="r">
                                            <span className="r-title">Définir la Largeur à</span>
                                            <input onChange={(e) => setWidthValue(parseInt(e.target.value))} type="number" min={0} defaultValue={widthValue === -1 ? 0 : widthValue} />
                                            <span>( cm )</span>
                                            <img onClick={() => removeTypeFromList(3)} src={minus} alt="supprimer" />
                                        </div>
                                    )}
                                    {inList(4) && (
                                        <div className="r">
                                            <span className="r-title">Ajouter la hauteur de</span>
                                            <input onChange={(e) => setAddToHighValue(parseInt(e.target.value))} type="number" min={0} defaultValue={addToHighValue === -1 ? 0 : addToHighValue} />
                                            <span>( cm )</span>
                                            <img onClick={() => removeTypeFromList(4)} src={minus} alt="supprimer" />
                                        </div>
                                    )}
                                    {inList(5) && (
                                        <div className="r">
                                            <span className="r-title">Ajouter la longueur de</span>
                                            <input
                                                onChange={(e) => setAddToLengthValue(parseInt(e.target.value))}
                                                type="number"
                                                min={0}
                                                defaultValue={addToLengthValue === -1 ? 0 : addToLengthValue}
                                            />
                                            <span>{addToLengthValue}( cm )</span>
                                            <img onClick={() => removeTypeFromList(5)} src={minus} alt="supprimer" />
                                        </div>
                                    )}
                                    {inList(6) && (
                                        <div className="r">
                                            <span className="r-title">Ajouter la largeur de </span>
                                            <input onChange={(e) => setAddToWidthValue(parseInt(e.target.value))} type="number" min={0} defaultValue={addToWidthValue === -1 ? 0 : addToWidthValue} />
                                            <span>( cm )</span>
                                            <img onClick={() => removeTypeFromList(6)} src={minus} alt="supprimer" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="btn-box">
                    <button
                        onClick={() => {
                            setRule(undefined);
                            setOpenModal(false);
                        }}
                        className="btn-close"
                    >
                        Annuler
                    </button>
                    {rules.type === 0 && (
                        <button onClick={OnsubmitPrice} className={price < 1 ? 'disable' : 'btn-action'}>
                            Sauvegarder
                        </button>
                    )}
                    {rules.type === 1 && (
                        <button onClick={OnsubmitConf} className={ruleRefs.length === 0 ? 'disable' : 'btn-action'}>
                            Sauvegarder
                        </button>
                    )}
                    {rules.type === 2 && (
                        <button onClick={OnsubmitDesc} className="btn-action">
                            Sauvegarder
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
export default CreateRules;
