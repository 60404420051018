import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Engine } from '@lutithree/build/Engine';
import {CameraSystem} from "@lutithree/build/Modules/WebGL/Scene/Systems/CameraSystem";
import {Studio} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Studio";

export class MappingStudio extends Studio {

    public constructor(p_rootEntities: Map<string, SceneEntity>) {
        super(p_rootEntities);
    }

    protected AddCameras(p_engine: Engine): void {
        super.AddCameras(p_engine);

        let cameraSystem = new CameraSystem(p_engine.Modules.Scene);
        let mainCamData = cameraSystem.GetMainCameraDatas();
        if(mainCamData.control){
            mainCamData.control.minDistance = 0.5;
            mainCamData.control.maxPolarAngle = Math.PI;
        }
    }
}