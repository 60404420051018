import apiClient from '../../api-client';
import { CREATE_ZONING, DELETE_ZONING, UPDATING_ZONING } from '../../endpoints/mapping-zoning/zoning';
import { Zone } from '../../../domain/domain';


const zoningService = {
    createZoning: async (mappingId: number, zoneName: string) => apiClient(CREATE_ZONING(mappingId, zoneName), undefined, undefined, 'POST'),
    updateZoning: async (mappingId: number, zoneUuid: string, zone: Zone) => apiClient(UPDATING_ZONING(mappingId, zoneUuid), zone, undefined, 'PUT'),
    deleteZoning: async (groupId: number, mappingId: number, zoneUuid: string) => apiClient(DELETE_ZONING(groupId, mappingId, zoneUuid), undefined, undefined, 'DELETE'),
};
export default zoningService;