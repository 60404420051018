import Layout from '../../uicomponents/layout/layout';
import MainPaper from '../../uicomponents/paper/main-paper';
import { useEffect, useState } from 'react';
import CreateStoreModal from '../../components/modals/stores/create-store';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/root-reducer';
import {
    deleteStore,
    getStores,
    resetCreateStores,
    resetDeleteStore, resetUpdateStoreName, StoreState,
} from '../../services/reducers/store/store.slice';
import StoresTable from '../../components/table/stores/stores-table';
import SimpleModal from '../../uicomponents/modal/simple-modal/simple-modal';
import trash from '../../asset/poubelle-red.svg';
import { useHistory } from 'react-router-dom';
import { RolePermissionType, Store, User } from '../../domain/domain';

const Stores = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    // @ts-ignore
    const currentUser: User = JSON.parse(localStorage.getItem('user'));

    //region state
    const [createModal, setCreateModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [update, setUpdate] = useState(false);
    const [storeRef, setStoreRef] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [currentStore, setCurrentStore] = useState<Store>({
        id: 0,
        name: '',
        reference: '',
        sellerSet: [],
        responsibleSet: [],
        version: 0,
        updatedAt: new Date(),
        createdAt: new Date(),
    });
    const [currentPermission, setCurrentPermission] = useState<RolePermissionType[]>([]);
    //endregion
    //region useSelector
    const createStoreRes = useSelector<RootState, StoreState>(state => state.createStore);
    const updateStoreNameRes = useSelector<RootState, StoreState>(state => state.updateStoreName);
    const deleteStoreRes = useSelector<RootState, StoreState>(state => state.deleteStore);
    // endregion
    //region useEffect
    useEffect(() => {
        const currentPermissionList = currentUser.roles.map((r) => r.permissions.map((p) => p.name)).flatMap(el => el);
        setCurrentPermission(currentPermissionList);
    }, []);
    useEffect(() => {
        if (createStoreRes.payload.content === null && createStoreRes.payload.errors.length > 0) {
            setNameErr('Ce nom de magasin existe deja ');
        } else {
            // @ts-ignore
            if (createStoreRes.payload.content.id !== 0 && createStoreRes.payload.errors.length === 0) {
                setCreateModal(false);
                dispatch(getStores());
                history.push(`/store/${ createStoreRes.payload.content.reference }/${ createStoreRes.payload.content.name }`);
                dispatch(resetCreateStores());
                setUpdate(false);
            }
        }
    }, [createStoreRes]);
    useEffect(() => {
        if (updateStoreNameRes.payload.content === null && updateStoreNameRes.payload.errors.length > 0) {
            setNameErr('Ce nom de magasin existe deja ');
        } else {
            // @ts-ignore
            if (updateStoreNameRes.payload.content.id !== 0 && updateStoreNameRes.payload.errors.length === 0) {
                setCreateModal(false);
                dispatch(getStores());
                history.push(`/store/${ updateStoreNameRes.payload.content.reference }/${ updateStoreNameRes.payload.content.name }`);
                dispatch(resetUpdateStoreName());
                setUpdate(false);
            }
        }
    }, [updateStoreNameRes]);
    useEffect(() => {
        if (deleteStoreRes.payload.content.id !== 0 && deleteStoreRes.payload.errors.length === 0) {
            setDeleteModal(false);
            dispatch(getStores());
            dispatch(resetDeleteStore());
        }
    }, [deleteStoreRes]);


    //endregion

    return (
        <Layout title='Magasins' visible>
            { createModal &&
                <CreateStoreModal nameErr={ nameErr }
                                  setNameErr={ setNameErr }
                                  store={ currentStore }
                                  setUpdate={ setUpdate }
                                  update={ update }
                                  setModal={ setCreateModal } /> }
            { deleteModal && <SimpleModal
                icon={ trash }
                title='Supprimer un magasin'
                info='Cette action supprimera le magasin ainsi que ces dépendence'
                closeOnclick={ () => setDeleteModal(false) }
                actionOnclick={ () => dispatch(deleteStore(storeRef)) }
                deleteOrValide={ true }
                closeLabel='Annuler'
                actionLabel='Supprimer' /> }
            <div className='create-catalog'>
                <div>{ currentPermission.includes('ADMINISTRATION') }</div>
                { currentPermission.includes('ADMINISTRATION') &&
                    <button onClick={ () => setCreateModal(true) }> + Créer un magasin</button> }
            </div>
            <MainPaper>
                <StoresTable setUpdate={ setUpdate }
                             setCurrentStore={ setCurrentStore }
                             setUpdateModal={ setCreateModal }
                             setStoreRef={ setStoreRef }
                             setModal={ setDeleteModal } />
            </MainPaper>
        </Layout>
    );
};
export default Stores;