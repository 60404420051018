import apiClient from '../../api-client';
import { CREATE_GROUP, DELETE_GROUP, GET_ALL_GROUPS, GET_GROUP, GET_GROUPS, UPDATE_GROUP } from '../../endpoints/mapping-zoning/group-mapping.endponit';
import { GroupMappingCreationForm } from '../../../domain/domain';

const groupMappingService = {
    getAllGroups: async (collectionReference: string) => apiClient(GET_ALL_GROUPS(collectionReference), undefined, undefined, 'GET'),
    getGroup: async (groupId: number) => apiClient(GET_GROUP(groupId), undefined, undefined, 'GET'),
    getGroups: async () => apiClient(GET_GROUPS(), undefined, undefined, 'GET'),
    createGroup: async (collectionReference: string, data: GroupMappingCreationForm) => apiClient(CREATE_GROUP(collectionReference), data, undefined, 'POST'),
    updateGroup: async (groupId: number, data: GroupMappingCreationForm) => apiClient(UPDATE_GROUP(groupId), data, undefined, 'PUT'),
    deleteGroup: async (groupId: number) => apiClient(DELETE_GROUP(groupId), undefined, undefined, 'DELETE'),
};

export default groupMappingService;
