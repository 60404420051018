import './duplicate-modal.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

type SimpleModalProps = {
    icon: string;
    title: string;
    info: string;
    closeOnclick: () => void;
    actionOnclick: () => void;
    deleteOrValide: boolean;
    closeLabel: string;
    actionLabel: string;
    setRefreshPresetName: (newValue: string) => void;
};

const DuplicateModal = ({
                            setRefreshPresetName,
                            icon,
                            title,
                            closeOnclick,
                            actionOnclick,
                            deleteOrValide,
                            actionLabel,
                            closeLabel,
                        }: SimpleModalProps) => {


    const ref = useRef(null);
    const {
        formState: { errors },
    } = useForm();

    const [value, setValue] = useState('');

    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        setRefreshPresetName(value);
    }, [value]);

    useEffect(() => {
        // @ts-ignore
        ref.current !== null && ref.current.focus();
    }, []);

    return (
        <div className='duplicate-modal-main'>
            <div ref={ ref } tabIndex={ 0 } onKeyDown={ (e) => e.key === 'Enter' && actionOnclick() }
                 className='duplicate-modal-dialog-box'>
                <div className='modal-head'>
                    <div className={ deleteOrValide ? ' ext ext-red' : 'ext ext-blue' }>
                        <div className={ deleteOrValide ? 'int int-red' : 'int-blue' }>
                            <img alt='icone' src={ icon } />
                        </div>
                    </div>

                    <div className='modal-title-block'>
                        <div className='title'>{ title }</div>
                        <br />
                        <div className='relative-bloc-preset'>
                            <span className='span1-preset'>Nom</span>
                            <div className='span2-preset'>
                                <td>
                                    <input type='text' onChange={ handleChange } value={ value }
                                           className='product3dnaming-duplicate-preset'
                                    />
                                </td>
                            </div>
                        </div>
                        <br />
                        <div className='modal-button'>
                            <div className='button-container'>
                                <button onClick={ closeOnclick } className='button-cancel'>
                                    { closeLabel }
                                </button>
                                <button className={ deleteOrValide ? 'button-action red' : 'button-action blue' }
                                        onClick={ actionOnclick }>
                                    { actionLabel }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DuplicateModal;
