import MdfApiResponse from '../../../../domain/common/generic';
import {
    Model3DCellValue,
    Model3dCellValueByPartItemReference,
    ModelTree,
    ProductTree,
} from '../../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cells3dValuesService from '../../../../api/services/products/3DCells/3DCellsValues.service';


export type Cell3dValueState = {
    isLoading: false;
    payload: MdfApiResponse<Model3DCellValue>;
};

const initialModel3dState: Cell3dValueState = {
    isLoading: false,
    payload: {
        content: {
            id: 0,
            value: '',
            label: '',
            thumbnailUrl: '',
            createdAt: new Date(),
            updatedAt: new Date(),
            version: 0,
            partItemName: '',
            partItemReference: '',
            partName: '',
            partReference:"",
            reference: '',
        },
        errors: [],
        warnings: [],
    },
};

export type Cell3dValueListState = {
    isLoading: false;
    payload: MdfApiResponse<Model3DCellValue[]>;
};

export type ModelTreeListState = {
    isLoading: false;
    payload: MdfApiResponse<ModelTree[]>;
};

const initial3dCellValueList: Cell3dValueListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

const initialDefaultConfigurationState: ModelTreeListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

//<editor-fold desc="get-cell-info-3DCell">
export const get3dCellValues = createAsyncThunk('get/3d-cell-values', async (data: Model3dCellValueByPartItemReference) => {
    const res = await cells3dValuesService.get3dCellValues({ partItemReferenceList: data.partItemReferenceList });
    return res;
});

export const get3dCellValueSlice = createSlice({
    name: 'get3dCellValue',
    initialState: initial3dCellValueList,
    reducers: {
        resetGet3dCellValues: (state) => {
            state = initial3dCellValueList;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(get3dCellValues.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(get3dCellValues.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(get3dCellValues.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGet3dCellValues } = get3dCellValueSlice.actions;

//</editor-fold>

//<editor-fold desc="get-cell-value-tree">

export type ProductCellValueTreeState = {
    isLoading: boolean;
    payload: MdfApiResponse<ProductTree>;
};

const initialProductCellValueTreeState: ProductCellValueTreeState = {
    isLoading: false,
    payload: {
        content: {
            reference: '',
            name: '',
            partTreeList: [
                {
                    reference: '',
                    name: '',
                    characteristics: [],
                    options: [],

                },
            ],
        },
        errors: [],
        warnings: [],
    },
};

export const getCellValuesTree = createAsyncThunk('get/3d-cell-values-tree', async (productRef: string) => {
    const res = await cells3dValuesService.get3dCellValuesTree(productRef);
    return res;
});

export const get3dCellValueTreeSlice = createSlice({
    name: 'get3dCellValueTree',
    initialState: initialProductCellValueTreeState,
    reducers: {
        resetGet3dCellValuesTree: (state) => {
            state = initialProductCellValueTreeState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCellValuesTree.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCellValuesTree.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getCellValuesTree.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGet3dCellValuesTree } = get3dCellValueTreeSlice.actions;


export const getDefaultProductConfiguration = createAsyncThunk('get/default-configuration', async (productRef: string) => {
    const res = await cells3dValuesService.getDefaultConfiguration(productRef);
    return res;
});

export const getDefaultProductConfigurationSlice = createSlice({
    name: 'getDefaultConfiguration',
    initialState: initialDefaultConfigurationState,
    reducers: {
        resetGetDefaultConfiguration: (state) => {
            state = initialDefaultConfigurationState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDefaultProductConfiguration.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getDefaultProductConfiguration.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getDefaultProductConfiguration.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetDefaultConfiguration } = getDefaultProductConfigurationSlice.actions;

//</editor-fold>

//<editor-fold desc="create-cell-info-3DCell">
export const create3dCellValue = createAsyncThunk('create/3d-cell-value/', async (data: {  model3dReference: string, model3dCellValue: Model3DCellValue }) => {
    const res = await cells3dValuesService.create3dCellValue(data.model3dReference, data.model3dCellValue);
    return res;
});

export const create3dCellValueSlice = createSlice({
    name: 'create3dCellValue',
    initialState: initialModel3dState,
    reducers: {
        resetCreateCellValue: (state) => {
            state = initialModel3dState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(create3dCellValue.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(create3dCellValue.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(create3dCellValue.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateCellValue } = create3dCellValueSlice.actions;
//</editor-fold>

//<editor-fold desc="get-cell-info-3DCell">
export const changeAtomVisibility = createAsyncThunk('post/change-atom-visibility', async (data: { partReference: string; partItemReference: string; atomVisibility: string }) => {
    const res = await cells3dValuesService.change3dCellVisibility(data.partReference, data.partItemReference, data.atomVisibility);
    return res;
});

export const changeAtomVisibilitySlice = createSlice({
    name: 'changeAtomVisibilitySlice',
    initialState: initial3dCellValueList,
    reducers: {
        resetChangeAtomVisibilitySlice: (state) => {
            state = initial3dCellValueList;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(changeAtomVisibility.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(changeAtomVisibility.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(changeAtomVisibility.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetChangeAtomVisibilitySlice } = changeAtomVisibilitySlice.actions;

//</editor-fold>

//<editor-fold desc="update-cell-info-3DCell">
export const rename3dCellValueLabel = createAsyncThunk('update/3d-cell-value/', async (data: { atomref: string; label: string }) => {
    const res = await cells3dValuesService.rename3dCellValuesLabel(data.atomref, data.label);
    return res;
});

export const rename3dCellValueLabelSlice = createSlice({
    name: 'rename3dCellValueLabel',
    initialState: initialModel3dState,
    reducers: {
        resetRename3dCellValueLabel: (state) => {
            state = initialModel3dState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(rename3dCellValueLabel.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(rename3dCellValueLabel.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(rename3dCellValueLabel.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetRename3dCellValueLabel } = rename3dCellValueLabelSlice.actions;
//</editor-fold>
