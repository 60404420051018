import MdfApiResponse from '../../../../../domain/common/generic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Colori, ColoriCreateForm, ColorisUpdateForm } from '../../../../../domain/domain';
import ColorisServive from '../../../../../api/services/suppliers/finish/color/coloris.servive';

//<editor-fold desc="get-all-coloris">
export type GetAllColorisState = {
    isLoading: boolean;
    payload: MdfApiResponse<Colori[]>;
};

const initialGetAllColorisState: GetAllColorisState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};
export const getAllColoris = createAsyncThunk('getAll/coloris', async (finishId: number) => {
    const res = await ColorisServive.getAllColoris(finishId);
    return res;
});
export const getAllColorisSlice = createSlice({
    name: 'getAllColorisSlice',
    initialState: initialGetAllColorisState,
    reducers: {
        resetGetAllColoris: (state) => {
            state = initialGetAllColorisState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllColoris.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllColoris.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllColoris.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetAllColoris } = getAllColorisSlice.actions;
//</editor-fold>

//<editor-fold desc="Create-coloris">
export type CreateColorisState = {
    isLoading: boolean;
    payload: MdfApiResponse<Colori | undefined>;
};

const initialCreateColorisState: CreateColorisState = {
    isLoading: false,
    payload: {
        content: {
            id: 0,
            name: '',
            version: 0,
            code: '',
            modelUrl: '',
            colorR: 0,
            colorG: 0,
            colorB: 0,
            weight: 0,
            thumbnailUrl: '',
            createdAt: new Date(),
            updatedAt: new Date(),
            duplicateNumber: 0,
            nonDeletable: false,
            nonEditable: false,
            uploadedThumbnailUrl: '',
            finish: {
                id: 0,
                version: 0,
                createdAt: new Date(),
                updatedAt: new Date(),
                name: '',
                supplierReference: '',
                reference: '',
                logoUrl: '',
                nonDeletable: false,
                nonEditable: false,
                tags: [],
                businessDescription: '',
                technicalDescription: '',
                typeVignette: 'A_PLAT',
            },
            coloriParent: 0,
            fromRal: false,
            finishId: 0,
        },
        errors: [],
        warnings: [],
    },
};
export const createColoris = createAsyncThunk('create/coloris', async (data: { finishId: number; colorForm: ColoriCreateForm }) => {
    const res = await ColorisServive.createColoris(data.finishId, data.colorForm);
    return res;
});
export const createColorisSlice = createSlice({
    name: 'createColorisSlice',
    initialState: initialCreateColorisState,
    reducers: {
        resetCreateColoris: (state) => {
            state = initialCreateColorisState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createColoris.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createColoris.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createColoris.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateColoris } = createColorisSlice.actions;
//</editor-fold>

//<editor-fold desc="delete-coloris">

export type DeleteColorisState = {
    isLoading: boolean;
    payload: MdfApiResponse<Colori | undefined>;
};

const initialDeleteColorisState: DeleteColorisState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};
export const deleteColoris = createAsyncThunk('delete/coloris', async (data: { finishId: number; colorisId: number }) => {
    const res = await ColorisServive.deleteColoris(data.finishId, data.colorisId);
    return res;
});
export const deleteColorisSlice = createSlice({
    name: 'deleteColorisSlice',
    initialState: initialDeleteColorisState,
    reducers: {
        resetDeleteColoris: (state) => {
            state = initialDeleteColorisState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteColoris.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteColoris.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteColoris.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteColoris } = deleteColorisSlice.actions;
//</editor-fold>

//<editor-fold desc="duplicate-coloris">

export type DuplicateColorisState = {
    isLoading: boolean;
    payload: MdfApiResponse<Colori | undefined>;
};

const initialDuplicateColorisState: DuplicateColorisState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};
export const duplicateColoris = createAsyncThunk('duplicate/coloris', async (data: { finishId: number; colorisId: number; coloriCreateForm: ColoriCreateForm }) => {
    const res = await ColorisServive.duplicateColoris(data.finishId, data.colorisId, data.coloriCreateForm);
    return res;
});
export const duplicateColorisSlice = createSlice({
    name: 'duplicateColorisSlice',
    initialState: initialDuplicateColorisState,
    reducers: {
        resetDuplicateColoris: (state) => {
            state = initialDuplicateColorisState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(duplicateColoris.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(duplicateColoris.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(duplicateColoris.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDuplicateColoris } = duplicateColorisSlice.actions;
//</editor-fold>

//<editor-fold desc="get-coloris">

export type GetColorisState = {
    isLoading: boolean;
    payload: MdfApiResponse<Colori | undefined>;
};

const initialGetColorisState: GetColorisState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};
export const getColoris = createAsyncThunk('get/coloris', async (colorisId: number) => {
    const res = await ColorisServive.getColori(colorisId);
    return res;
});
export const getColorisSlice = createSlice({
    name: 'getColoris',
    initialState: initialGetColorisState,
    reducers: {
        resetGetColoris: (state) => {
            state = initialGetColorisState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getColoris.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getColoris.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getColoris.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetColoris } = getColorisSlice.actions;
//</editor-fold>

//<editor-fold desc="update-coloris">

export type UpdateColorisState = {
    isLoading: boolean;
    payload: MdfApiResponse<Colori | undefined>;
};

const initialUpdateColorisState: UpdateColorisState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};
export const updateColoris = createAsyncThunk('update/coloris', async (data: { colorisId: number; updateColorisForm: ColorisUpdateForm }) => {
    const res = await ColorisServive.updateColoris(data.colorisId, data.updateColorisForm);
    return res;
});
export const updateColorisSlice = createSlice({
    name: 'updateColorisSlice',
    initialState: initialUpdateColorisState,
    reducers: {
        resetUpdateColoris: (state) => {
            state = initialUpdateColorisState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateColoris.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateColoris.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateColoris.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateColoris } = updateColorisSlice.actions;
//</editor-fold>

//<editor-fold desc="update-coloris-dto">

export type UpdateColorisDTOState = {
    isLoading: boolean;
    payload: MdfApiResponse<Colori | undefined>;
};

const initialUpdateColorisDTOState: UpdateColorisDTOState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};
export const updateColorisDTO = createAsyncThunk('update/coloris', async (data: any) => {
    const res = await ColorisServive.updateColorisDto(data);
    return res;
});
export const updateColorisDTOSlice = createSlice({
    name: 'updateColorisDTOSlice',
    initialState: initialUpdateColorisState,
    reducers: {
        resetUpdateColorisDTO: (state) => {
            state = initialUpdateColorisDTOState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateColorisDTO.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateColorisDTO.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateColorisDTO.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateColorisDTO } = updateColorisDTOSlice.actions;

export type ColorisFromCommonAndSupplierIdState = {
    isLoading: boolean;
    payload: MdfApiResponse<Colori[]>;
};

const initialColoriFromCommonAndSupplierState: ColorisFromCommonAndSupplierIdState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};
export const getColoriFromSupplierAndCommon = createAsyncThunk('fetch/colorisFromSupplierAndCommon', async (supplierReference: string) => {
    const res = await ColorisServive.getColorisFromCommondAndSupplierId(supplierReference);
    return res;
});
export const fetchAllColorisFromSupplierAndCommon = createSlice({
    name: 'fetchAllColorisFromSupplierAndCommon',
    initialState: initialColoriFromCommonAndSupplierState,
    reducers: {
        resetColoriFromSupplierAndCommon: (state) => {
            state = initialColoriFromCommonAndSupplierState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getColoriFromSupplierAndCommon.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getColoriFromSupplierAndCommon.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getColoriFromSupplierAndCommon.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetColoriFromSupplierAndCommon } = fetchAllColorisFromSupplierAndCommon.actions;
//</editor-fold>
