import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ConfigurationResponseV2 } from '../../../domain/domain';
import MdfApiResponse from '../../../domain/common/generic';
import productService from '../../../api/services/product/product';

export type GetTablePresetsState = {
    isLoading: boolean;
    payload: MdfApiResponse<ConfigurationResponseV2[] | undefined>;
};

const initialTablePreset: GetTablePresetsState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

export const getTablePresets = createAsyncThunk('preset', async () => {
    const res = await productService.getTablePresets();
    return res;
});

export const getTablePresetsSlice = createSlice({
    name: 'getTablePresetsSlice',
    initialState: initialTablePreset,
    reducers: {
        resetGetTablePresets: (state) => {
            state = initialTablePreset;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTablePresets.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getTablePresets.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getTablePresets.rejected, (state) => {
            state.isLoading = false;
        });
    },

});

export default getTablePresetsSlice.reducer;