import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NewsService from '../../../api/services/news/news.service';

export type DeleteNewsCategoryState = {
    isLoading: boolean;
    errorMessage: string;
    successMessage: string;
    isRejected: boolean;
};

const initialState: DeleteNewsCategoryState = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    isRejected: false,
};

type GetNewsCategoryParameters = {
    newsCategoryId: number;
};

export const deleteNewsCategory = createAsyncThunk('news-category/delete', async (data: GetNewsCategoryParameters, thunkApi) => {
    const response = await NewsService.deleteNewsCategory(data.newsCategoryId);
    return response;
});

const deleteNewsCategorySlice = createSlice({
    name: 'deleteNewsCategorySlice',
    initialState,
    reducers: {
        resetNewsCategoryDeletion: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteNewsCategory.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteNewsCategory.fulfilled, (state, { meta }) => {
            state = {
                isLoading: false,
                isRejected: false,
                errorMessage: '',
                successMessage: ` `,
            };
            return state;
        });
        builder.addCase(deleteNewsCategory.rejected, (state) => {
            state.isLoading = false;
            state.successMessage = '';
            // @ts-ignore
            state.errorMessage = error.message;
            state.isRejected = true;
        });
    },
});

export const { resetNewsCategoryDeletion } = deleteNewsCategorySlice.actions;
export default deleteNewsCategorySlice.reducer;
