import { Part, Product } from '../../../../../../domain/domain';
import plus from '../../../../../../asset/plus-circle.svg';
import './product-detail-3d.scss';

import ProductModel3dTable
    from '../../../../../../components/table/products-table/product-model-3d-table/product-model-3d-table';
import { Fragment, useEffect, useState } from 'react';
import CreateTexture from '../../../../../../components/modals/texture/create/create-texture';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../services/root-reducer';
import { getAllParts, PartListState } from '../../../../../../services/reducers/product/part/part.reducers';
import {
    getProductModels3d,
    Model3dListState,
    Model3dState,
    resetGetProductModels3d,
    saveProductModels3d,
} from '../../../../../../services/reducers/product/3DModels/3DModels.reducers';
import Loader from '../../../../../../uicomponents/loader/loader';
import SimpleProduct3dTable
    from '../../../../../../components/table/products-table/product-model-3d-table/simple-product-3d-table/simple-product-3d-table';


type ProductDetail3dProps = {
    product: Product;
    setStep: (step: number) => void;
    setFocus: (focus: number) => void;
};

const ProductDetail3d = (props: ProductDetail3dProps) => {
    const dispatch = useDispatch();

    const parts = useSelector<RootState, PartListState>((state) => state.getAllParts);
    const create3dModelRes = useSelector<RootState, Model3dState>((state) => state.createProduct3dModel);
    // const updateModel3d = useSelector<RootState, ProductState>((state) => state.updateProductModel3DName);

    const productModel3d = useSelector<RootState, Model3dListState>((state) => state.getProduct3dModel);

    const [open3dModal, setOpen3dModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openAssociateMapping, setAssociateOpenMappping] = useState(false);

    const [selectedPart, setSelectedPart] = useState<Part>();

    useEffect(() => {
        dispatch(getAllParts(props.product.reference));
    }, []);
    useEffect(() => {
        selectedPart && dispatch(getProductModels3d(selectedPart.reference));
    }, [selectedPart]);
    useEffect(() => {
        if (parts.payload.content.length === 0) {
            setSelectedPart(undefined);
            dispatch(resetGetProductModels3d());
        }
        if (parts.payload.content.length > 0 && parts.payload.errors.length === 0) {
            setSelectedPart(parts.payload.content[0]);
        }
    }, [parts]);
    useEffect(() => {
        if (create3dModelRes.payload.content && create3dModelRes.payload.errors.length === 0) {
            selectedPart && dispatch(getProductModels3d(selectedPart.reference));
            setOpen3dModal(false);
        }
    }, [create3dModelRes]);

    const genClass = () => {
        if (parts.payload.content.length > 0) {
            return parts.payload.content[0].name === 'UNIQUE' ? 'product-3d-table-main emptyTab' : 'product-3d-table-main ';
        }
    };
    const conditionalButton = () => {
        if (parts.payload.content.length > 0) {
            return parts.payload.content && parts.payload.content[0].name !== 'UNIQUE' ? (
                <button
                    className='import'
                    onClick={ () => {
                        setOpen3dModal(true);
                    } }
                >
                    <img alt='Importer un modèle 3d' src={ plus } />
                    Importer des modèles 3D
                </button>
            ) : productModel3d.payload.content.length === 0 ? (
                <button
                    className='import'
                    onClick={ () => {
                        setOpen3dModal(true);
                    } }
                >
                    <img alt='Importer un modèle 3D' src={ plus } />
                    Importer des modèles 3D
                </button>
            ) : (
                <></>
            );
        }
    };

    return (
        <div className='product-3d-main'>
            <>
                { loading && <Loader isLoading={ loading } /> }
                { open3dModal &&
                    <CreateTexture
                        setUploadPercent={ () => {
                        }
                        }
                        name={ selectedPart?.name }
                        setIsLoading={ setLoading }
                        part={ selectedPart } model3d={ true }
                        setOpenTextureModal={ setOpen3dModal } /> }
                { parts.payload.content ? (
                        <div className='partTabList'>
                            { parts.payload.content.map((part: Part) => {
                                return (
                                    <Fragment key={ part.id }>
                                        { part.name === 'UNIQUE' ? (
                                            <></>
                                        ) : (
                                            <div onClick={ () => setSelectedPart(part) }
                                                 className={ selectedPart?.name === part.name ? 'partTab focus' : 'partTab' }
                                                 key={ part.id }>
                                                <span>{ part.name.toUpperCase() }</span>
                                            </div>
                                        ) }
                                    </Fragment>
                                );
                            }) }
                        </div>
                    )
                    :
                    (
                        <div className='emptyPart' />
                    )
                }
            </>
            <div className={ genClass() }>
                {
                    props.product.productType === 'SIMPLE' &&
                    <SimpleProduct3dTable
                        selectedPart={ selectedPart }
                        productModel3d={ productModel3d }
                        product={ props.product }
                    />
                }
                {
                    props.product.productType === 'CONFIGURABLE' && <ProductModel3dTable
                        productModel3d={ productModel3d }
                        openAssociateMapping={ openAssociateMapping }
                        setAssociateOpenMappping={ setAssociateOpenMappping }
                        part={ selectedPart }
                        product={ props.product }
                    />
                }
                {
                    props.product.productType === 'COMPOSITION' &&
                    <ProductModel3dTable
                        productModel3d={ productModel3d }
                        openAssociateMapping={ openAssociateMapping }
                        setAssociateOpenMappping={ setAssociateOpenMappping }
                        part={ selectedPart }
                        product={ props.product }
                    />
                }
            </div>
            <div className='product-3d-btn-box'>
                { conditionalButton() }
                <button
                    onClick={ () => {
                        if (props.product.productType === 'SIMPLE') {
                            props.setStep(2);
                            props.setFocus(2);
                        } else {
                            props.setStep(3);
                            props.setFocus(3);
                        }
                        productModel3d.payload.content.length && dispatch(saveProductModels3d(props.product.reference));
                    } }
                    className={
                        props.product.productType === 'COMPOSITION'
                            ? 'save'
                            : productModel3d.payload.content.length ? 'save' : 'empty' }>
                    Sauvegarder
                </button>
            </div>
        </div>
    )
        ;
};

export default ProductDetail3d;
