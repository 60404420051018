import './compsable-info.scss';
import Toogle from '../../../../../../../uicomponents/toogleSwitch/toogle/toogle';
import React, { useEffect, useState } from 'react';
import { Pair, Product, Product3DInfo, Tag } from '../../../../../../../domain/domain';
import TagInput from '../../../../../../../uicomponents/tag/tag-input';
import {
    AssociatedTagListState, createProductInfo,
    getProductAssociatedTagsByReference, getProductById, ProductInfoState, resetCreateProductInfo,
} from '../../../../../../../services/reducers/product/product.reducers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../services/root-reducer';
import {
    CreateTagState,
    getProductTag,
    GetProductTagState,
} from '../../../../../../../services/reducers/tag/tag.slice';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../../../../domain/Params/params-interface';
import { returnadvice } from '../../../../../../../components/modals/mapping-zoning/mapping/create-mapping';


type ComposableInfoProps = {
    currentProduct: Pair<Product, Product3DInfo>
    setStep: (step: number) => void
    setFocusi: (focusi: number) => void
    focusi: number
};
const ComposableInfo = ({ currentProduct, setFocusi, focusi, setStep }: ComposableInfoProps) => {
    const dispatch = useDispatch();
    const { productReference } = useParams<Params>();

    const getProductTagRes = useSelector<RootState, GetProductTagState>((state) => state.getProductTags);
    const createTagsRes = useSelector<RootState, CreateTagState>((state) => state.createTag);
    const getProductAssociatedTagsRes = useSelector<RootState, AssociatedTagListState>((state) => state.getProductAssociatedTagsByReference);
    const createInfoRes = useSelector<RootState, ProductInfoState>((state) => state.createProductInfo);

    const [focus, setFocus] = useState(false);
    const [tagIdList, setTagIdList] = useState<number[]>([]);
    const [allTagList, setAllTagList] = useState<Tag[]>([]);
    const [tagList, setTagList] = useState<Tag[]>([]);
    const [name, setName] = useState<string>('');
    const [reference, setReference] = useState<string>('');
    const [MappingUnique, setMappingUnique] = useState(false);

    const [constraint, setConstraint] = useState(false);
    const [height, setHeight] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const [productHeight, setProductHeight] = useState<number>(0);
    const [productLenght, setProductLenght] = useState<number>(0);
    const [productDeft, setProductDeft] = useState<number>(0);


    useEffect(() => {
        if (focusi === 0) {
            setTagIdList([]);
            setTagList([]);
            dispatch(getProductAssociatedTagsByReference(currentProduct.first.reference));
            setAllTagList(getProductTagRes.payload.content);
            setPrice(currentProduct.second.data.basicPrice);
            setName(currentProduct.first.name);
            setMappingUnique(currentProduct.second.data.mappingUnique);
            setConstraint(currentProduct.second.data.constraint);
            setReference(currentProduct.first.reference);
            setHeight(currentProduct.second.data.height);
            setProductHeight(currentProduct.second.data.product_height);
            setProductLenght(currentProduct.second.data.product_lenght);
            setProductDeft(currentProduct.second.data.product_deft);

        }
    }, []);
    useEffect(() => {
        if (createTagsRes.payload.content && createTagsRes.payload.errors.length < 1) {
            dispatch(getProductTag());
        }
    }, [createTagsRes]);
    useEffect(() => {
        if (getProductAssociatedTagsRes.payload.content && getProductAssociatedTagsRes.payload.errors.length === 0) {
            setTagIdList(getProductAssociatedTagsRes.payload.content.map((el) => el.id));
            setTagList(getProductAssociatedTagsRes.payload.content);
        }
    }, [getProductAssociatedTagsRes]);
    useEffect(() => {
        if (createInfoRes.payload.content && createInfoRes.payload.errors.length === 0) {
            setFocusi(1);
            setStep(1);
            productReference && dispatch(getProductById(productReference));
            dispatch(resetCreateProductInfo());
        }
    }, [createInfoRes]);
    const onSaveProduct = () => {
        dispatch(
            createProductInfo({
                productReference: currentProduct.first.reference,
                tagIdList: tagIdList,
                name: name,
                data: {
                    constraint: constraint,
                    height: height,
                    basicPrice: price,
                    product_lenght: productLenght,
                    product_height: productHeight,
                    product_deft: productDeft,
                    mappingUnique: MappingUnique,
                },
            }),
        );

    };

    return (
        <div className='composable-info-main'>
            <div className='composable-info-top'>
                <div className='composable-info-left'>
                    <div className='b'>
                        <h2>INFORMATION GÉNÉRALES</h2>
                        <div className='form-bloc'>
                            <label>Nom du produit</label>
                            <input onChange={(e) => setName(e.target.value)} value={name} />
                            <div style={{ marginTop: 10 }}> {returnadvice(name, 24)}</div>
                        </div>
                        <div className='form-bloc'>
                            <label>Réference</label>
                            <input disabled={true} value={reference} />
                        </div>
                    </div>
                    <div className='c' onClick={() => setFocus(false)}>
                        <h2>COMPOSITION</h2>
                        <label> Mapping unique </label>
                        <Toogle checked={MappingUnique} setBool={setMappingUnique} />
                    </div>
                    <div className='a'>
                        <div onClick={() => setFocus(!focus)} className='bloc-input'>
                            <TagInput
                                focus={focus}
                                setFocus={setFocus}
                                tagIdList={tagIdList}
                                setTagIdList={setTagIdList}
                                allProductTag={allTagList}
                                setTagList={setTagList}
                                taglist={tagList} />
                        </div>
                    </div>
                </div>
                <div onClick={() => setFocus(false)} className='composable-info-right'>
                    <h2>COMPORTEMENT 3D</h2>
                    <div className='form-bloc'>
                        <label> Contrainte murale </label>
                        <Toogle checked={constraint} setBool={setConstraint} />
                    </div>

                    <div className='form-bloc'>
                        <label>Hauteur par rapport au sol du produit ( cm )</label>
                        <input value={height} min={0} type='number'
                               onChange={(e) => setHeight(parseInt(e.target.value))} />
                    </div>
                    <div className='form-bloc'>
                        <label>Prix( € )</label>
                        <input
                            value={price}
                            min={0} type='number'
                            onChange={(e) => setPrice(parseInt(e.target.value))} />
                    </div>
                    <div className='form-bloc'>
                        <label>Longeur( cm )</label>
                        <input
                            value={productLenght}
                            min={0}
                            type='number'
                            onChange={(e) => setProductLenght(parseInt(e.target.value))} />

                    </div>
                    <div className='form-bloc'>
                        <label>Hauteur( cm )</label>
                        <input
                            value={productHeight}
                            min={0}
                            type='number'
                            onChange={(e) => setProductHeight(parseInt(e.target.value))} />

                    </div>
                    <div className='form-bloc'>
                        <label>Profondeur( cm )</label>
                        <input
                            value={productDeft}
                            min={0} type='number' onChange={(e) => setProductDeft(parseInt(e.target.value))} />
                    </div>
                </div>
            </div>
            <div onClick={() => setFocus(false)} className='composable-info-bottom'>
                <button
                    onClick={onSaveProduct}
                >Sauvegarder
                </button>
            </div>
        </div>
    );
};
export default ComposableInfo;
