import './catalog-section.scss';
import { useEffect, useState } from 'react';
import { Section, Catalog, SectionWithSubSections } from '../../../domain/domain';
import SectionPanel from './section/section';
import SubSectionPanel from './sub-section/sub-section';
import { useParams } from 'react-router-dom';
import { Params } from '../../../domain/Params/params-interface';
import { useDispatch, useSelector } from 'react-redux';
import {
    CatalogResponseState,
    CatalogState,
    getCatalogByRef,
    getTreeCatalog,
    TreeCatalogState,
} from '../../../services/reducers/catalog/catalog.slice';
import { RootState } from '../../../services/root-reducer';


export const InitialCatalog: Catalog = {
    id: 0,
    reference: '',
    numberOfPreset: 0,
    version: 0,
    name: '', updatedAt: new Date(),
    createdAt: new Date(),
};

const CatalogSection = () => {
    const dispatch = useDispatch();
    const { catalogRef } = useParams<Params>();

    const [sectionFocus, setSectionFocus] = useState<SectionWithSubSections | undefined>(undefined);
    const catalog = useSelector<RootState, CatalogResponseState>(state => state.getCatalogByRef);
    const treeCatalog = useSelector<RootState, TreeCatalogState>(state => state.getTreeCatalog);
    const [sections, setSections] = useState<SectionWithSubSections[]>([]);
    const [currentCatalog, setCurrentCatalog] = useState<Catalog>(InitialCatalog);


    useEffect(() => {
        dispatch(getCatalogByRef(catalogRef));
    }, [catalogRef]);
    useEffect(() => {
        if (catalog.payload.content !== null) {
            if (catalog.payload.content.catalog.id !== 0 && catalog.payload.errors.length === 0) {
                dispatch(getTreeCatalog(catalog.payload.content.catalog.id));
                setCurrentCatalog(catalog.payload.content.catalog);
            }
        }
    }, [catalog]);
    useEffect(() => {
        if (treeCatalog.payload.content !== null) {
            setSections(treeCatalog.payload.content.sections);
        }
    }, [treeCatalog]);

    return (
        <div className='catalog-main'>
            <SectionPanel
                catalog={ currentCatalog }
                sections={ sections }
                sectionFocus={ sectionFocus }
                setSectionFocus={ setSectionFocus } />
            <SubSectionPanel
                catalog={ catalog.payload.content.catalog }
                treeCalalog={ treeCatalog.payload.content }
                section={ sectionFocus }
            />
        </div>
    );
};
export default CatalogSection;