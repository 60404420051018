const ADD_CONSTRUCTION_RULES = `v1/construction-rule/`;
const FIND_CONSTRUCTION_RULES = (collectionRef: string) => `v1/construction-rule/collection/${ collectionRef }`;
const FIND_CONSTRUCTION_RULES_BY_COMPOSITION_REFERENCE = (compositionRef: string) => `v1/construction-rule/composition/${ compositionRef }`;
const FIND_CONSTRUCTION_RULES_BY_ID = (ruleId: number) => `v1/construction-rule/${ ruleId }`;
const DELETE_CONSTRUCTION_RULES = (ruleId: number) => `v1/construction-rule/${ ruleId }`;
const ASSOCIATE_CONSTRUCTION_RULES = (compositionRef: string) => `v1/construction-rule/associate/product/${ compositionRef }`;
const DISSOCIATE_CONSTRUCTION_RULES = (compositionRef: string, ruleId: number) => `v1/construction-rule/dissociate/product/${ compositionRef }/rule/${ ruleId }`;

export {
    ADD_CONSTRUCTION_RULES,
    FIND_CONSTRUCTION_RULES,
    FIND_CONSTRUCTION_RULES_BY_ID,
    FIND_CONSTRUCTION_RULES_BY_COMPOSITION_REFERENCE,
    DELETE_CONSTRUCTION_RULES,
    ASSOCIATE_CONSTRUCTION_RULES,
    DISSOCIATE_CONSTRUCTION_RULES,
};