import MdfApiResponse from '../../../../domain/common/generic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Finish, FinishCreationForm } from '../../../../domain/domain';
import FinishesService from '../../../../api/services/suppliers/finish/finishes.service';

//<editor-fold desc="Create-finishes">
export type CreateFinishState = {
    isLoading: boolean;
    payload: MdfApiResponse<Finish | undefined>;
};

const initialCreateFinishState: CreateFinishState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

export const createFinish = createAsyncThunk('create/finishes', async (data: { data: FinishCreationForm; supplierReference: string }) => {
    const res = await FinishesService.createFinish(data.supplierReference, data.data);
    return res;
});

export const createFinishSlice = createSlice({
    name: 'createFinishSlice',
    initialState: initialCreateFinishState,
    reducers: {
        resetCreateFinish: (state) => {
            state = initialCreateFinishState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createFinish.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createFinish.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createFinish.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetCreateFinish } = createFinishSlice.actions;
//</editor-fold>
//<editor-fold desc="get-finishes">
export type GetFinishesState = {
    isLoading: boolean;
    payload: MdfApiResponse<Finish[]>;
};

const initialGetFinishesState: GetFinishesState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const getFinishes = createAsyncThunk('get/finishes', async (supplierReference: string) => {
    const res = await FinishesService.getFinishes(supplierReference);
    return res;
});

export const getFinishesSlice = createSlice({
    name: 'getFinishesSlice',
    initialState: initialGetFinishesState,
    reducers: {
        resetGetFinishes: (state) => {
            state = initialGetFinishesState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFinishes.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getFinishes.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getFinishes.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetFinishes } = getFinishesSlice.actions;
//</editor-fold>
//<editor-fold desc="get-finishes">

export type GetFinishState = {
    isLoading: boolean;
    payload: MdfApiResponse<Finish | undefined>;
};
const initialGetFinishState: GetFinishState = {
    isLoading: false,
    payload: {
        content: undefined,
        errors: [],
        warnings: [],
    },
};
export const getFinish = createAsyncThunk('get/finish', async (finishId: number) => {
    const res = await FinishesService.getFinish(finishId);
    return res;
});
export const getFinishlice = createSlice({
    name: 'getFinishSlice',
    initialState: initialGetFinishState,
    reducers: {
        resetGetFinish: (state) => {
            state = initialGetFinishState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFinish.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getFinish.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getFinish.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetFinish } = getFinishlice.actions;
//</editor-fold>
//<editor-fold desc="get-finishes-from-a-supplier-and-supplier-commun">

export type GetFinishesFromSupplierAndCommunState = {
    isLoading: boolean;
    payload: MdfApiResponse<Finish[] | any>;
};
const initialGetFinishesFromSupplierAndCommunState: GetFinishesFromSupplierAndCommunState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};
export const getFinishesFromSupplierAndCommun = createAsyncThunk('get/finish/commun', async (supplierReference: string) => {
    const res = await FinishesService.getFinishesFromSupplierAndCommun(supplierReference);
    return res;
});
export const getFinishesFromSupplierAndCommunSlice = createSlice({
    name: 'getFinishesFromSupplierAndCommun',
    initialState: initialGetFinishesFromSupplierAndCommunState,
    reducers: {
        resetGetFinishesFromSupplierAndCommun: (state) => {
            state = initialGetFinishesFromSupplierAndCommunState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFinishesFromSupplierAndCommun.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getFinishesFromSupplierAndCommun.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getFinishesFromSupplierAndCommun.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetFinishesFromSupplierAndCommun } = getFinishesFromSupplierAndCommunSlice.actions;
//</editor-fold>
//<editor-fold desc="update-finishes">

export type UpdateFinishState = {
    isLoading: boolean;
    payload: MdfApiResponse<Finish | undefined>;
};

const initialUpdateFinishState: UpdateFinishState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};
type FinishData = {
    finishId: number;
    finish: FinishCreationForm;
};
export const updateFinish = createAsyncThunk('update/finishes', async (data: FinishData) => {
    const res = await FinishesService.updateFinish(data.finishId, data.finish);
    return res;
});

export const updateFinishSlice = createSlice({
    name: 'updateFinishSlice',
    initialState: initialUpdateFinishState,
    reducers: {
        resetUpdateFinish: (state) => {
            state = initialUpdateFinishState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateFinish.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateFinish.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updateFinish.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdateFinish } = updateFinishSlice.actions;
//</editor-fold>
//<editor-fold desc="Delete-finishes">
export type DeleteFinishState = {
    isLoading: boolean;
    payload: MdfApiResponse<Finish[]>;
};

const initialDeleteFinishState: DeleteFinishState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const deleteFinish = createAsyncThunk('delete/finishes', async (finishId: number) => {
    const res = await FinishesService.deleteFinish(finishId);
    return res;
});

export const deleteFinishSlice = createSlice({
    name: 'deleteFinishSlice',
    initialState: initialDeleteFinishState,
    reducers: {
        resetDeleteFinish: (state) => {
            state = initialDeleteFinishState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteFinish.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteFinish.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteFinish.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteFinish } = deleteFinishSlice.actions;
//</editor-fold>
